
import React from 'react'
import classes from './RadioButton.module.css'

export const RadioButton = (props) => {
    const { changed, id, isSelected, label, value } = props;
    return (
        <div className={classes.RadioButton}>
            {/* <input
                id={id}
                onChange={changed}
                value={value}
                type="radio"
                checked={isSelected}
            />
            <label htmlFor={id}>{label}</label> */}


            <label className={classes.container}>{label}
                <input
                    type="radio"
                    onChange={changed}
                    value={value}
                    checked={isSelected}
                />
                <span className={classes.checkmark}></span>
            </label>
        </div>
    );
};