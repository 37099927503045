import React, { useEffect, useRef, useState } from "react"
import style from "./VerifyOtp.module.css"
import useApiCall from '../../../../hooks/useApiCall'
import config from "../../../../../../app.json"

import Button from "../../../../../../ui/Button/Button"
import { addScroll, getUserToken } from "../../../../../../utils/utils"
import Image from "../../../../../../ui/Image/Image"
import timerIcon from "../../../../assets/common/Clock.svg"

export const VerifyOtp = ({email, setViewOtpModal, handleAcceptApi}) => {

    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [ loader, setLoader ] = useState(false)

    const { success: sendOtpSuccess, error: sendOtpError, sendRequest: sendOtp } = useApiCall();
    const { isLoading: isVerifyOtpLoading, success: verifyOtpSuccess, error: verifyOtpError, sendRequest: otpVerification } = useApiCall();

    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    const [totalSeconds, setTotalSeconds] = useState(30); // 1 minute and 30 seconds

    useEffect(()=> {
        resendOTP()
    }, [])
  
    useEffect(() => {
      const interval = setInterval(() => {
        if (totalSeconds > 0) {
          setTotalSeconds(totalSeconds - 1);
        } else {
          clearInterval(interval);
        }
      }, 1000);
  
      return () => {
        clearInterval(interval);
      };
    }, [totalSeconds]);

    const handleChange = (index, value) => {
      if (!isNaN(value) && value !== '') {
        otp[index] = value;
        setOtp([...otp]);
  
        if (index < 3 && value !== '') {
          inputRefs[index + 1].current.focus();
        }
        checkOtpValid() && setOtpError('');
      } else if (value === '' && index > 0) {
        otp[index] = '';
        setOtp([...otp]);
        inputRefs[index - 1].current.focus();
      } else if (value === '' && index === 0) {
        otp[index] = '';
        setOtp([...otp]);
      }
    };

    const checkOtpValid = () => {
      return otp?.every((digit) => !isNaN(digit) && digit !== '');
    }
  
    const handleKeyPress = (e, index) => {
      if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
        inputRefs[index - 1].current.focus();
      }
    };

    useEffect(() => {
      if (verifyOtpSuccess) {
        handleAcceptApi()
      }
      if (verifyOtpError) {
        setOtpError(verifyOtpError)
      }
    }, [verifyOtpSuccess, verifyOtpError]);

    const verifyOtp = async() => {
        const otpValue = otp.join("")
        if (checkOtpValid()) {
            setLoader(true)
            otpVerification({
              url: "auth/verify-email",
              method: 'POST',
              body: {
                "otp": String(otpValue)
              },
              headers: {
                  Authorization: `Bearer ${getUserToken()}`
              }
            })
        } else {
          setOtpError('Please fill all the fields.'); // Set error message if OTP is not valid
        }
    };

    useEffect(() => {
      if (sendOtpSuccess) {
        setOtp(['', '', '', ''])
        setTotalSeconds(30); // Reset to 1 minute and 30 seconds
      }
      if (sendOtpError) {
        setOtp(['', '', '', ''])
        setTotalSeconds(30); // Reset to 1 minute and 30 seconds
      }
    }, [sendOtpSuccess, sendOtpError]);

    const resendOTP = () => {
        sendOtp({
            url: `auth/email-otp`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        });
    };

    return(
        <div className={style.verifyOtpContainer}>
            <p className={style.title}>Verify OTP</p>
            <p className={style.description}>Enter the OTP that we have sent to your email address {email}.</p>
            <div className={style.otpErrorContainer}>
                <div className={style.otpInputContainer}>
                {otp.map((digit, index) => (
                    <input
                    key={index}
                    type="text"
                    placeholder='0'
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e, index)}
                    maxLength={1}
                    ref={inputRefs[index]}
                    />
                ))}
                </div>
                {otpError && <p className={`${style.errorMessage} error`}>{otpError}</p>}
            </div>
            <div className={style.timerContainer}>
                {totalSeconds > 0 ? (
                <div className={style.timer}>
                    <Image
                    src={timerIcon}
                    />
                    <p className={style.resendText}>
                    {totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds} sec
                    </p>
                </div>
                ) : (
                <p
                    onClick={resendOTP}
                    className={style.resendText}
                >
                    Resend OTP
                </p>
                )}
            </div>
            <div className={style.btn_group}>
                <Button className={`primaryButton ${style.cancel}`} onClick={()=> {
                  addScroll()
                  setViewOtpModal(false)
                }}>Cancel</Button>
                <Button className={`primaryButton ${style.submitButton}`} onClick={verifyOtp}>Continue</Button>
            </div>
        </div>
    )
}