import React, { useContext, useEffect, useState } from 'react';
import style from './FamilyDetails.module.css';
import useApiCall from '../../../../hooks/useApiCall';
import config from '../../../../../../app.json';
import FloatingLabelDropdown from '../../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import PopoutInput from '../../../../../../ui/PopoutInput/PopoutInput';
import Button from '../../../../../../ui/Button/Button';
import ProgressBar from '../../../../../../ui/ProgressBar/ProgressBar';
import { getUserToken, isStringValid } from '../../../../../../utils/utils';

import Success from '../Modal/Success';
import ModalBox from '../../../../../../ui/Modal/ModalBox';
import { ToastContainer, toast } from 'react-toastify';


export default function FamilyDetails({ familyDetailsError, Cancel, data, setViewDetailsModal, refresh }) {

    // const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    const { sendRequest: getUserData } = useApiCall();
    const { sendRequest: getFamilyData } = useApiCall();
    const [modal, setModal] = useState(false)
    const [familyType, setFamilyType] = useState([]);
    const [familyStatus, setFamilyStatus] = useState([]);
    const [numberOfBrothers, setNumberOfBrothers] = useState('');
    const [numberOfSisters, setNumberOfSisters] = useState('');
    const [brothersMarried, setBrothersMarried] = useState('');
    const [sistersMarried, setSistersMarried] = useState('');
    // const [selectedHobbies, setSelectedHobbies] = useState([]);
    const [fathersOccupation, setFathersOccupation] = useState([]);
    const [mothersOccupation, setMothersOccupation] = useState([]);


    // const [hobbiesData, setHobbiesData] = useState([]);
    const [occupationData, setOccupationData] = useState([]);
    const [numberOfBrothersData, setNumberOfBrothersData] = useState([]);
    const [numberOfSistersData, setNumberOfSistersData] = useState([]);
    const [familyTypeData, setFamilyTypeData] = useState([]);
    const [familyStatusData, setFamilyStatusData] = useState([]);


    const [userData, setUserData] = useState([])
    const [validationError, setValidationError] = useState("")

    useEffect(() => {
        setFamilyType(data.family_type)
        setFamilyStatus(data.family_status)
        setNumberOfBrothers(data.brothers)
        setNumberOfSisters(data.sisters)
        setFathersOccupation(data.father_occupation)
        setMothersOccupation(data.mother_occupation)
        setBrothersMarried(data.married_brother)
        setSistersMarried(data.married_sister)
    }, [data])

    useEffect(() => {
        window.scrollTo(0, 0);
        getUserData({
            url: "retrieve-json-data",
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (data) => {
            if (data.data.family_type) {
                // setActiveStep(5)
            } else {
                setUserData(data.data);
            }
        });
    }, []);

    useEffect(() => {
        getFamilyData(
            {
                url: 'onboard/read-meta-data',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                const { parent_occupation, hobbies, no_of_sister, no_of_brother, family_type, family_status } = data.data;
                setFamilyStatusData(family_status)
                setFamilyTypeData(family_type)
                setNumberOfSistersData(no_of_sister)
                setNumberOfBrothersData(no_of_brother)
                setOccupationData(parent_occupation);
                // setHobbiesData(hobbies);
            }
        );
    }, []);

    const {  error: getUpdateFamilyerror, sendRequest: getUpdateFamily } = useApiCall();

    useEffect(()=> {
        if(getUpdateFamilyerror) {
            familyDetailsError(getUpdateFamilyerror)
        }
    }, [getUpdateFamilyerror])

    const saveChanges = () => {

        if (familyType && familyStatus && numberOfBrothers && numberOfSisters && fathersOccupation && mothersOccupation && brothersMarried && sistersMarried) {
            // Collect all the form data and radio button selections
            const dataToSend = {
                "family_type": familyType,
                "family_status": familyStatus,
                "father_occupation": fathersOccupation,
                "mother_occupation": mothersOccupation,
                "no_of_sister": numberOfSisters,
                "no_of_brother": numberOfBrothers,
                "married_brother": brothersMarried,
                "married_sister": sistersMarried

            };

            getUpdateFamily({
                url: "my-account/my-profile/update/family-details",
                method: 'POST',
                body: dataToSend,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        } else {
            setValidationError("Some required fields are missing.")
        }

    }



    return (
        <div className={style.workDetailsContainer}>
            <div className={style.formContainer}>

                <div className={style.fieldContainer}>
                    <FloatingLabelDropdown
                        label="Family Type"
                        name="country"
                        responseType="token"
                        options={familyTypeData}
                        value={familyType}
                        onChange={(e) => setFamilyType(e.target.value)}
                    />
                    <FloatingLabelDropdown
                        label="Family Status"
                        name="state"
                        responseType="token"
                        options={familyStatusData} // Use native state data here
                        value={familyStatus}
                        onChange={(e) => setFamilyStatus(e.target.value)}
                    />
                </div>
                <div className={style.fieldContainer}>
                    <FloatingLabelDropdown
                        label="Father's Occupation"
                        name="country"
                        responseType="name"
                        options={occupationData}
                        value={fathersOccupation}
                        onChange={(e) => setFathersOccupation(e.target.value)}
                    />
                    <FloatingLabelDropdown
                        label="Mother's Occupation"
                        name="state"
                        responseType="name"
                        options={occupationData} // Use native state data here
                        value={mothersOccupation}
                        onChange={(e) => setMothersOccupation(e.target.value)}
                    />
                </div>


                <div className={style.fieldContainer}>
                    <FloatingLabelDropdown
                        label="No of Brothers"
                        name="number_of_brothers"
                        responseType="token"
                        options={numberOfBrothersData}
                        value={numberOfBrothers}
                        onChange={(e) => setNumberOfBrothers(e.target.value)}
                    />
                    <FloatingLabelDropdown
                        label="No of Sisters"
                        name="number_of_sisters"
                        responseType="token"
                        options={numberOfSistersData}
                        value={numberOfSisters}
                        onChange={(e) => setNumberOfSisters(e.target.value)}
                    />
                </div>
                <div className={style.fieldContainer}>
                    <FloatingLabelDropdown
                        label="Brothers Married"
                        name="brothers_married"
                        responseType="token"
                        options={numberOfBrothersData}
                        value={brothersMarried}
                        onChange={(e) => setBrothersMarried(e.target.value)}
                    />
                    <FloatingLabelDropdown
                        label="Sisters Married"
                        name="sisters_married"
                        responseType="token"
                        options={numberOfSistersData}
                        value={sistersMarried}
                        onChange={(e) => setSistersMarried(e.target.value)}
                    />
                </div>


                {/* 
                <div className={style.fieldContainer}>
                    <div className={style.multipleFieldContainer}>
                        <FloatingLabelDropdown
                            label="No of Brothers"
                            name="number_of_brothers"
                            responseType="token"
                            options={numberOfBrothersData}
                            value={numberOfBrothers}
                            onChange={(e) => setNumberOfBrothers(e.target.value)}
                        />
                        <FloatingLabelDropdown
                            label="Brothers Married"
                            name="brothers_married"
                            responseType="token"
                            options={numberOfBrothersData}
                            value={brothersMarried}
                            onChange={(e) => setBrothersMarried(e.target.value)}
                        />
                    </div>
                    <div className={style.multipleFieldContainer}>
                        <FloatingLabelDropdown
                            label="No of Sisters"
                            name="number_of_sisters"
                            responseType="token"
                            options={numberOfSistersData}
                            value={numberOfSisters}
                            onChange={(e) => setNumberOfSisters(e.target.value)}
                        />
                        <FloatingLabelDropdown
                            label="Sisters Married"
                            name="sisters_married"
                            responseType="token"
                            options={numberOfSistersData}
                            value={sistersMarried}
                            onChange={(e) => setSistersMarried(e.target.value)}
                        />
                    </div>
                </div> */}
            </div>

            {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

            <div className={style.btn_group}>
                <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                <Button className={`primaryButton ${style.submitButton}`} onClick={saveChanges}> Save Changes</Button>
            </div>


            <ModalBox
                open={modal}
                width={"598px"}
                modalContent={
                    <Success
                        onClick={() => {
                            setModal(false)
                            setViewDetailsModal(false)
                            refresh()
                        }}
                    />
                }
            />
        </div >
    );
}
