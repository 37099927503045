import React from 'react';

import classes from './Search.module.css'
import Image from '../../UI/Img/Img.jsx'
import Searchicon from '../../assets/SVG/Manage Users/Search.svg'


const Search = ({ type, placeholder, onChange, value, style }) => {

    return (
        <div className={classes['search-container']} style={style}>
            <input type={type} placeholder={placeholder} value={value} onChange={(e) => onChange(e.target.value)} />
            <Image src={Searchicon} alt="Search" className={classes.Search_icon} />
        </div>
    )
}

export default Search;
