import React, { useEffect, useState } from "react"
import style from "./CoupleStory.module.css"
import Stories from "../../features/CoupleStory/Stories/Stories"
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';
import { getUserToken } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";

export const CoupleStory = () => {

    const navigation = useNavigate("")
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    useEffect(() => {
        if (lastPage) {
            return;
        }
        getDetails({
            url: `success-story/read-all?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setLastPage(currentPage >= responseData.data.last_page);
            setData(responseData.data.data);
            setLoadingMore(false);
        });
    }, [currentPage, lastPage]);

    return(
        <div className={style.coupleStoryContainer}>
            <Stories title={true} label={false} data={data}/>
        </div>
    )
}