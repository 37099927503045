import React, { useEffect, useState } from 'react'
import style from "./ShortlistedBasic.module.css"
import Carousel from 'react-grid-carousel'
import Image from '../../../../../ui/Image/Image'
import carousalRightIcon from "../../../assets/svg/Home/carousalRightIcon.svg"
import carousalleftIcon from "../../../assets/svg/Home/carousalleftIcon.svg"
import shortlistedStarIcon from "../../../assets/svg/Home/shortlistedStarIcon.svg"
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import { useNavigate } from 'react-router-dom'
import { getUserData, getUserToken } from '../../../../../utils/utils'

export default function ShortlistedBasic({details, refresh, setRefresh}) {

    const navigation = useNavigate("")

    const [data, setData] = useState(null);

    const { isLoading: sendShortListLoading, success: sendShortListSuccess, error: sendShortListError, sendRequest: shortlist } = useApiCall();
    
    useEffect(() => {
        setData(details)
    }, [details])

    const handleShortlist = (token) => {
        shortlist({
            url: `profile/toggle-shortlist`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const updatedData = data.filter((dataItem) => dataItem.token !== token);
            setData(updatedData);
            setRefresh(!refresh)
        });
    }

    const corousalLength = getUserData()?.membership_type === "Basic" ? 5 : 4

  return (
    <>
        {
            data?.length ?
                <div className={style.newlyJoinedContainer}>
                    <div className={style.newlyJoinedTitleContainer}>
                        <p className={style.titleText}>My Shortlists</p>
                        <p onClick={()=> {
                            localStorage.removeItem("matchesTab")
                            navigation("/matches", {
                                state: {
                                    selectedMatch: {
                                        api: "shortlisted",
                                        title: "Shortlisted",
                                        total_title: "Shortlisted profiles"
                                      }
                                }
                            } )}} 
                            className={style.viewAllText}
                        >
                            View All
                        </p>
                    </div>
                    <div className={`${style.dataContainer} ${ data?.length > corousalLength ? "" : style.notCarousal }`}>
                        {
                            data?.length > corousalLength ?
                                <Carousel
                                    cols={corousalLength}
                                    rows={1}
                                    gap={40}
                                    loop
                                    showDots={false}
                                    arrowLeft={<Image src={carousalleftIcon} className={style.arrowLeft} />}
                                    arrowRight={<Image src={carousalRightIcon} className={style.arrowRight} />}
                                >
                                    {
                                        data?.map((item, index) => (
                                            <Carousel.Item key={`newlyJoined-${index}`}>
                                                <div className={style.carousalItem} >
                                                    <div className={style.profileImageContainer}>
                                                        <Image
                                                            src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+item.image}
                                                            borderRadius={"10px"}
                                                            className={style.image}
                                                            onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}
                                                        />
                                                        <Image
                                                            onClick={()=> handleShortlist(item.token)}
                                                            src={shortlistedStarIcon}
                                                            className={style.shortlistedStarIcon} 
                                                        />
                                                    </div>
                                                    <p className={style.id} onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}>{item?.user_id}</p>
                                                    <p className={style.name} onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}>{item.name.slice(0, 10)+`${item.name?.length > 10 ? "..." : ""}`}</p>
                                                    <p className={style.details}>{item?.age} yrs, {item?.height}, {item?.mother_tongue}, {item?.occupation}</p>
                                                </div>
                                            </Carousel.Item>
                                        ))
                                    }
                                </Carousel>
                            :
                                data?.map((item, index) => (
                                    <div className={style.carousalItem}>
                                        <div className={style.profileImageContainer}>
                                            <Image
                                                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+item.image}
                                                borderRadius={"10px"}
                                                className={style.image}
                                                onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}
                                            />
                                            <Image 
                                                onClick={()=> handleShortlist(item.token)} 
                                                src={shortlistedStarIcon} 
                                                className={style.shortlistedStarIcon} 
                                            />
                                        </div>
                                        <p className={style.id} onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}>{item?.user_id}</p>
                                        <p className={style.name} onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}>{item.name.slice(0, 10)+`${item.name?.length > 10 ? "..." : ""}`}</p>
                                        <p className={style.details}>{item?.age} yrs, {item?.height}, {item?.mother_tongue}, {item?.occupation}</p>
                                        
                                    </div>
                                ))
                        }
                    </div>
                </div>
            :
            <></>
        }
    </>
  )
}
