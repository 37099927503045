import React, { useState } from 'react';
import style from "./Suggetions.module.css";
import Image from '../../../../ui/Image/Image';

import activeSortlistedIcon from "../../assets/svg/Matches/Shortlistd.svg";
import inactiveSortlistedIcon from "../../assets/svg/Matches/inactive/Shortlistd.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import SuggetionsData from '../../features/Suggetions/SuggetionsData/SuggetionsData';

export default function Suggetions() {
    const navigate = useNavigate(); // Initialize useHistory
    const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState(state?.selectedMatch ? state?.selectedMatch : "shortlisted");

  const menuItems = [
    {
      title: "Suggetions",
      icon: currentPage === "shortlisted" ? activeSortlistedIcon : inactiveSortlistedIcon,
    }
  ];

  // const replaceNavigationState = () => {
  //   navigate("/matches")
  // }

  // Define a mapping object for page components
  const pageComponents = {
    shortlisted: <SuggetionsData />,
    // Add other page components here
  };

  return (
    <div className={`container ${style.matchesContainer}`}>
      <div className={style.sideBarContainer}>
        {/* <p className={style.title}>Matches</p> */}
        <div className={style.contentContainer}>
          {menuItems.map((menuItem, index) => {
            return(
                <MatchMenuItem
                    key={index}
                    title={menuItem.title}
                    icon={menuItem.icon}
                    isActive={currentPage === menuItem.title.toLowerCase().replaceAll(" ", "_")}
                    onClick={menuItem.onClick}
                />
            )
        })}
        </div>
      </div>
      {/* Render the component based on the currentPage */}
      {pageComponents[currentPage]}
    </div>
  );
}

export function MatchMenuItem({ title, isActive, icon, onClick }) {
    return (
        <div className={`${style.content} ${isActive ? style.active : ""}`} >
            <Image src={icon} />
            <p className={style.menuTitle}>{title}</p>
        </div>
    );
}
