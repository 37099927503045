import React, { useContext } from 'react'
import style from './QualificationDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function QualificationDetails() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.profile_details?.user_profile_details?.qualification_details
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Qualification Details</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            {
              details?.map((item, index)=> (
                <div className={style.personalsDetail}>
                  <p className={style.label}>{item?.college_name}</p>
                  <p className={style.title}>{item?.qualification_name}</p>
                </div>
              ))
            }
          </div>
        </div>
    </div>
  )
}
