import React, { useState, useEffect } from "react"
import ThreeCulome from "../../UI/Culome/ThreeCulome"
import ViewDocument from "../../UI/ViewDocument/ViewDocument"
import ImrDocument from "../../UI/ViewDocument/ImrDocument"
import ImageView from "../../UI/ViewDocument/ImageViewOnly"
import ViewPage from "../ViewPage/ViewPage"
import classes from "./VerifiViewPage.module.css"
import { useNavigate } from "react-router-dom"
import { RadioButton } from "../../UI/RadioButton/RadioButton"
import CustomButton from "../../UI/Button/Button"
import TextField from "../../UI/Textarea/Textarea"
import { useParams } from "react-router-dom"
import useApiHttp from "../../hooks/ues-http"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { BASE_URL } from "../../utils/baseUrl"
import SelectBox from "../../UI/SelectFiled/SlectboxRejectResan"
import { AnimatePresence, motion } from "framer-motion"

const VerifiViewPage = ({ setVerifyUserToke, verifyUserToke, datas, identifiLable }) => {
  const [charsLeft, setCharsLeft] = useState(0)
  const [storiesUser, setStoriesUser] = useState("1")
  const [verifidUser, setVerifidUser] = useState("1")
  const [verifidViewUser, setVerifidViewUser] = useState([])
  const [errorMsg, setErrorMsg] = useState(false)
  const [photoErrorMsg, setPhotoErrorMsg] = useState(false)
  const [formData, setFormData] = useState({
    resane: "",
  })

  const [designation, setDesignation] = useState([])
  const [desigName, setDesigName] = useState("")

  const [photoDesignation, setPhotoDesignation] = useState([])
  const [photoDesigName, setPhotoDesigName] = useState("")

  const handleChange = event => {
    const { name, value } = event.target
    setFormData({ ...formData, [name]: value })

    const charCount = value.length
    const maxChar = 255
    // Limit the character count to 200 characters
    const truncatedValue = value.slice(0, maxChar)
    setFormData({ ...formData, [name]: truncatedValue })
    const charLength = Math.min(maxChar, charCount)
    setCharsLeft(charLength)
  }

  const radioChangeHandler = e => {
    setVerifidUser(e.target.value)
    setErrorMsg(false)
    setDesigName("")
  }
  const radioChangeHandlerPhoto = e => {
    setStoriesUser(e.target.value)
    setErrorMsg(false)
    setPhotoDesigName("")
  }

  const {
    isLoading,
    success,
    error,
    sendRequest: VerifiViewRequest,
  } = useApiHttp()

  const { token } = useParams()

  const verifiData = data => {
    setVerifidViewUser(data.data)
  }

  const verifiUser = () => {
    const requestData = {
      token: `${verifyUserToke}`,
    }
    VerifiViewRequest(
      {
        url: `${BASE_URL}view-user`,
        method: "POST",
        body: requestData,
      },
      verifiData
    )
  }

  useEffect(() => {
    verifiUser()
  }, [])

  const {
    isLoading: pendingLoding,
    success: pendingSucces,
    error: pendingErroe,
    sendRequest: pendingRequest,
  } = useApiHttp()

  useEffect(() => {
    if (pendingSucces !== "") {
      toast.success(pendingSucces, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (pendingErroe !== "") {
      toast.error(pendingErroe, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [pendingSucces, pendingErroe])

  const pendingUser = () => {
    const timeout = setTimeout(() => {
      setVerifyUserToke("")
      datas("pending")
    }, 1000)
    setErrorMsg(false)
    setDesigName("")
    setPhotoDesigName("")
  }

  const hadleSubmit = () => {
    // console.log("desigName && photoDesigName", desigName , photoDesigName);
    // if( desigName || photoDesigName ) {
      if( (verifidUser === "1" &&  storiesUser === "1") ||  (verifidUser === "1" && storiesUser === "0" && photoDesigName !== "" ) || ( storiesUser === "1" && verifidUser === "0" && desigName !== "" ) || ( verifidUser === "0" && desigName !== "" && storiesUser === "0" &&  photoDesigName !== "")  ) {
        const requestData = {
          token: `${verifyUserToke}`,
          status: verifidUser,
          reason: desigName,
          image_status: storiesUser,
          image_reject_reason: photoDesigName,
        }
        pendingRequest(
          {
            url: `${BASE_URL}verify-user`,
            method: "POST",
            body: requestData,
          },
          pendingUser
        )
      } else {
        if( verifidUser === "0" && desigName === "" ) {
          toast.error( "Please select NMC/SMC certificate reject reason", {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
        if( storiesUser === "0" && photoDesigName === "" ) {
          toast.error( "Profile Image reject reason", {
            position: toast.POSITION.TOP_RIGHT,
          })
        }
      }
    // } else {
    //   if( !desigName ) {
    //     setErrorMsg(true)
    //   } 
    //   if( !photoDesigName ) {
    //     setPhotoErrorMsg(true)
    //   }
    // }
  }
  const hadleCancel = () => {
    // navigate("/admin/dashboard/manageusers")
    setErrorMsg(false)
    setVerifyUserToke("")
    setDesigName("")
    setPhotoDesigName("")
  }
  const handleReasonChange = event => {
    setDesigName(event.target.value)
  }
  const handleImageReasonChange = event => {
    setPhotoDesigName(event.target.value)
  }

  const { sendRequest: designationRequest } = useApiHttp()
  const designationRequestData = () => {
    designationRequest(
      {
        url: `${BASE_URL}reject-reason-dropdown`,
        method: "POST",
      },
      data => setDesignation(data.data)
    )
  }
  useEffect(() => {
    designationRequestData()
  }, [])

  const { sendRequest: imageRejectReasonRequest } = useApiHttp()
  const imageRejectReasonRequestData = () => {
    imageRejectReasonRequest(
      {
        url: `${BASE_URL}image-reject-reason-dropdown`,
        method: "POST",
      },
      data => setPhotoDesignation(data.data)
    )
  }
  useEffect(() => {
    imageRejectReasonRequestData()
  }, [])

  const tabAnimation = {
    initial: {
      y: -200,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      y: -200,
      opacity: 0,
    },
  }

  return (
    <AnimatePresence mode="wait">
      <motion.dev
        variants={tabAnimation}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <ViewPage
          identifiLable={identifiLable}
          setTokenUser={setVerifyUserToke}
          userToken={verifyUserToke}
          path="/admin/dashboard/manageusers"
          ids={verifidViewUser.user_id}
          title={`${verifidViewUser.name}`}
          children={
            <>
              <div className={classes.view_page_box}>
                <ThreeCulome
                  title="Registration Details"
                  data={verifidViewUser}
                />
                <div className="line"></div>
                <>
                  <div className={classes.nms_box}>
                    <p className={classes.contant_box_titel}>NMC/SMC certificate</p>
                    <div className={classes.certificate}>
                      {verifidViewUser.status === "Rejected" && (
                        <span className="dcument_lable rejected">
                          {verifidViewUser.status}
                        </span>
                      )}
                      <ImrDocument isLoading={isLoading} data={verifidViewUser} />
                      <div className={classes.reject__reson}>
                        {verifidViewUser.reason === null ? (" ") : (
                          <>
                           <p className={classes.contant_box_titel}>Reject Reason</p>
                           <p className={classes.action_btn_reason}>{verifidViewUser.reason}</p>
                          </>
                         )}
                      </div>
                    </div>
                  </div>
                  {verifidViewUser.status !== "Rejected" && (
                    <div className={classes.action_box}>
                      <h3 className={classes.action_btn_titel}>
                        NMC/SMC Registration
                      </h3>
                      <p className={classes.action_btn_cont}>
                        Verify NMC/SMC Number.
                      </p>
                      <div className={classes.radio_btn_set}>
                        <RadioButton
                          changed={radioChangeHandler}
                          id="1"
                          isSelected={verifidUser === "1"}
                          label="Approve"
                          value="1"
                        />

                        <RadioButton
                          changed={radioChangeHandler}
                          id="2"
                          isSelected={verifidUser === "0"}
                          label="Reject"
                          value="0"
                        />
                      </div>
                      {verifidUser === "0" && (
                        <div className={classes.textarea_contoner}>
                          {errorMsg && (
                            <p
                              className={`${classes.errorMessage} text_danger`}
                            >
                              Please Slect Reason
                            </p>
                          )}
                          <SelectBox
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={desigName}
                            label="Reason"
                            onChange={handleReasonChange}
                            labelname="Reason"
                            nonename="Reason"
                            data={designation}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </>
                
                <div className="line"></div>
                <>
                  {verifidViewUser.profile_image !== null ? (
                    <>
                      <div className={classes.nms_box}>
                        <p className={classes.action_btn_titel}>Profile Image</p>
                        <div className={classes.certificate}>
                         {verifidViewUser?.profile_image?.status === "Rejected" && (
                            <span className="dcument_lable rejected">
                              {verifidViewUser?.profile_image?.status}
                            </span>
                          )}
                         {verifidViewUser?.profile_image?.status === "Approved" && (
                            <span className="dcument_lable approved">
                              {verifidViewUser?.profile_image?.status}
                            </span>
                          )}
                            <ImageView
                              isLoading={isLoading}
                              data={verifidViewUser}
                            />
                        </div>
                        <div className={classes.reject__reson}>
                          {verifidViewUser?.profile_image?.reject_reason=== null ? (" ") : (
                            <>
                            <p className={classes.contant_box_titel}>Reject Reason</p>
                            <p className={classes.action_btn_reason}>{verifidViewUser?.profile_image?.reject_reason}</p>
                            </>
                          )}
                        </div>
                      </div>
                      {verifidViewUser.status !== "Rejected" && (
                        <div className={classes.action_box}>
                          <h3 className={classes.action_btn_titel}>
                            Verify Profile Image
                          </h3>
                          <p className={classes.action_btn_cont}>
                            Verify the identity of the user profile picture.
                          </p>
                          <div className={classes.radio_btn_set}>
                            <RadioButton
                              changed={radioChangeHandlerPhoto}
                              id="1"
                              isSelected={storiesUser === "1"}
                              label="Approve"
                              value="1"
                            />

                            <RadioButton
                              changed={radioChangeHandlerPhoto}
                              id="2"
                              isSelected={storiesUser === "0"}
                              label="Reject"
                              value="0"
                            />
                          </div>
                          {storiesUser === "0" && (
                            <div className={classes.textarea_contoner}>
                              {photoErrorMsg && (
                                <p
                                  className={`${classes.errorMessage} text_danger`}
                                >
                                  Please Slect Reason
                                </p>
                              )}
                              <SelectBox
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={photoDesigName}
                                label="Reason"
                                onChange={handleImageReasonChange}
                                labelname="Reason"
                                nonename="Reason"
                                data={photoDesignation}
                              />
                            </div>
                          )}

                          <>
                            <p className={classes.contant_box_sub}>Note:</p>
                            <p className={classes.contant_box_titel_video}>
                              Photos should be clear and well-lit, ensuring the
                              person’s face is visible. Avoid photos with
                              excessive filters or heavy editing that may
                              distort the person's appearance.
                            </p>
                          </>
                        </div>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </>


               {verifidViewUser.status === "Pending" && (
                  <>
                    <div className={classes.button_group}>
                      <CustomButton
                        children="Submit"
                        className="w_200 primary__btn"
                        disabled={isLoading}
                        onClick={hadleSubmit}
                      />
                      {/* <CustomButton
                        children="Cancel"
                        className="w_200 secondary__btn"
                        onClick={hadleCancel}
                      /> */}
                    </div>
                  </>
                )}
              </div>
            </>
          }
        />
        <ToastContainer />
      </motion.dev>
    </AnimatePresence>
  )
}

export default VerifiViewPage
