import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './Habitspreferences.module.css';
import useApiCall from '../../../../../hooks/useApiCall';
import config from '../../../../../../../app.json';
import Button from '../../../../../../../ui/Button/Button';
import ProgressBar from '../../../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../../../ui/Image/Image';
import FloatingLabelDropdown from '../../../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserToken, isStringValid, uploadFile } from '../../../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import checkedIcon from "../../../../../assets/svg/Signup/checked.svg";
import uncheckedIcon from "../../../../../assets/svg/Signup/uncheck.svg";
import Success from '../Modal/Success';
import ModalBox from '../../../../../../../ui/Modal/ModalBox';
import { useNavigate } from 'react-router-dom';

export default function Habitspreferences({familyDetailsError, handleShowSuccessModal, setViewDetailsModal}) {

  const navigation = useNavigate("")

  const { sendRequest: getMetaData } = useApiCall();

  const [ loader, setLoader ] = useState(false)

  const [ validationError, setValidationError ] = useState("")
  const [ viewSuccessDetailsModal, setViewSuccessDetailsModal ] = useState(false)

  const [ userMetaData, setUserMetaData ] = useState(null)

  const [formData, setFormData] = useState({
    eatingHabit: '',
    drinkingHabit: '',
    smokingHabit: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'preference/elite/read-meta',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        setUserMetaData(data.data);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'my-account/preference/read',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const { drinking_habit, eating_habit, smoking_habit } = data.data.habit_detail
        setFormData({
          eatingHabit: eating_habit,
          drinkingHabit: drinking_habit,
          smokingHabit: smoking_habit
        })
      }
    );
  }, []);

  const handleInputChange = (event, inputType) => {
    console.log(event, inputType);
    if( event.target ) {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [inputType]: event,
      });
    }
  };

  const extractToken = (data, isToken) => {
    let token = []
    data?.map(item=> {
      token.push( isToken ? item.token : item.value)
    })
    return token;
  }

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    useEffect(()=> {
      if (success) {
        handleShowSuccessModal()
      }
      if(error) {
        familyDetailsError(error)
      }
      setLoader(false)
    }, [success, error])

  const submitForm = async () => {
  
    // if (
    //   formData.eatingHabit.length &&
    //   formData.smokingHabit.length &&
    //   formData.drinkingHabit.length
    // ) {
      setLoader(true)
      setValidationError("")

      const dataToSend = {
        "eating_habit": formData.eatingHabit,
        "smoking_habit": formData.smokingHabit,
        "drinking_habit": formData.drinkingHabit
      };

      uploadUserDetails({
        url: "my-account/preference/update/habit-detail",
        method: 'POST',
        body: dataToSend,
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
    });
      setLoader(false)
    // } else {
    //   setValidationError("Some required fields are missing.")
    // }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (name, value) => {
    setFormData((prevFilterData) => {
      const currentFilter = prevFilterData[name] || [];
      const updatedFilter = currentFilter.includes(value)
        ? currentFilter.filter((item) => item !== value)
        : [...currentFilter, value];
      return {
        ...prevFilterData,
        [name]: updatedFilter,
      };
    });
  };

  const isCheckboxChecked = (name, value) => {
    const dataArray = formData[name];
    return dataArray && dataArray.indexOf(value) !== -1;
  };

  return (
    <>
      <ModalBox
        open={viewSuccessDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success
            title={"Basic Details updated successfully"} 
            description={""} 
            buttonText={"Okay"}
            onClick={()=>{
              addScroll()
              setViewSuccessDetailsModal(false)
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Habits preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={`${style.field} ${style.marital}`}>
                <p className={style.fieldTitle}>Eating Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('eatingHabit', userMetaData?.eating_habit)}
                </div>
              </div>
              <div style={{ minWidth: "342px", marginTop: "26px" }} className={style.field}>
                <p className={style.fieldTitle}>Drinking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('drinkingHabit', userMetaData?.drinking_habit)}
                </div>
              </div>
            </div>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Smoking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('smokingHabit', userMetaData?.smoking_habit)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
        <div className={style.footer}>
          <Button
            className={`${style.submitButton} primaryButton cancelButton`}
            onClick={() => {
              addScroll();
              setViewDetailsModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={() => {
              addScroll();
              submitForm()
            }}
            loading={isLoading}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </>
  );
}
