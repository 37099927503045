import React from "react"
import style from "./Viewer.module.css"
import { checkPdf } from "../../../../../../utils/utils"
import Image from "../../../../../../ui/Image/Image"
import closeIcon from "../../../../assets/svg/Signup/Close.svg"

export const Viewer = ({doc, onClick}) => {
    return(
        <div className={style.documentViewer}>
            <Image src={closeIcon} onClick={onClick} className={style.closeIcon}/>
            <video width="734" height="400" controls controlsList="nodownload">
              <source src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + doc?.video} type="video/mp4"/>
            </video>
            <p className={style.description}>{ doc?.description }</p>

              {/* <iframe src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + doc?.video} width={535} height={535}></iframe> */}
           {/* {
              checkPdf(doc) ?
                <iframe src={doc} width={535} height={535}></iframe>
              :
                <Image src={doc} className={style.image}/>
           }  */}
        </div>
    )
}