import React from "react"
import style from "./Search.module.css"
import Button from "../../../../../../ui/Button/Button"
import { addScroll } from "../../../../../../utils/utils"

export const Search = ({onClick, setViewSuccessModal}) => {
    return(
        <div className={style.contentContainer}>
            <p className={style.title}>Please enter matrimony id</p>
            <div className={style.btn_group}>
                <Button className={`primaryButton ${style.submitButton}`} onClick={onClick}>Okay</Button>
            </div>
        </div>
    )
}