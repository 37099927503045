import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './WorkLocationPreferences.module.css';
import useApiCall from '../../../../../hooks/useApiCall';
import config from '../../../../../../../app.json';
import Button from '../../../../../../../ui/Button/Button';
import ProgressBar from '../../../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../../../ui/Image/Image';
import FloatingLabelDropdown from '../../../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserToken, isStringValid, uploadFile } from '../../../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import checkedIcon from "../../../../../assets/svg/Signup/checked.svg";
import uncheckedIcon from "../../../../../assets/svg/Signup/uncheck.svg";
import Success from '../Modal/Success';
import ModalBox from '../../../../../../../ui/Modal/ModalBox';
import { useNavigate } from 'react-router-dom';

export default function WorkLocationPreferences({familyDetailsError, handleShowSuccessModal, setViewDetailsModal}) {

  const navigation = useNavigate("")

  const { sendRequest: getMetaData } = useApiCall();

  const [ loader, setLoader ] = useState(false)

  const [ validationError, setValidationError ] = useState("")
  const [ viewSuccessDetailsModal, setViewSuccessDetailsModal ] = useState(false)
 
  const [ countryData, setCountryData ] = useState([])
  const [ stateData, setStateData ] = useState([])
  const [ cityData, setCityData ] = useState([])

  const { sendRequest: getUserData } = useApiCall();
  const { sendRequest: getState } = useApiCall();
  const { sendRequest: getCity } = useApiCall();


  const [formData, setFormData] = useState({
    country: {
      token: "",
      value: ""
    },
    state: [],
    city: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'preference/elite/read-meta',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const {country} = data.data;
        setCountryData(country);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'my-account/preference/read',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const { country_detail, state_detail, city_detail } = data.data.work_location_detail
        setFormData({
          country: { value: country_detail?.name, token: country_detail?.id },
          state: state_detail,
          city: city_detail,
        })
      }
    );
  }, []);

  useEffect(() => {
      getState({
          url: "state",
          method: 'POST',
          body: {
              "country_id": formData?.country.token
          }
      }, (data) => {
          setStateData(data.data); // Set work state data
      });
  }, [formData?.country]);

  useEffect(() => {
      getCity({
          url: "city-for-multiple-states",
          method: 'POST',
          body: {
              "state_id": extractId(formData?.state)
          }
      }, (data) => {
          setCityData(data.data); // Set native city data
      });
  }, [formData?.state]);

  const handleInputChange = (event, inputType) => {
    if( event.target ) {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      if( inputType === "state" ) {
        setFormData({
          ...formData,
          [inputType]: event,
          city: []
        });
      } else {
        setFormData({
          ...formData,
          [inputType]: event,
        });
      }
    }
  };

  const extractToken = (data, isToken) => {
    let token = []
    data?.map(item=> {
      token.push( isToken ? String(item.token) : item.name)
    })
    return token;
  }

  const extractId = (data) => {
    let token = []
    if( data[0]?.id === undefined ) {
      data?.map(item=> {
        token.push( item?.token )
      })
    } else {
      data?.map(item=> {
        token.push( item?.id )
      })
    }
    return token;
  }

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    useEffect(()=> {
      if (success) {
        handleShowSuccessModal()
      }
      if(error) {
        familyDetailsError(error)
      }
      setLoader(false)
    }, [success, error])

  const submitForm = async () => {
  
    // if (
    //   formData.country.value.length &&
    //   formData.state.length &&
    //   formData.city.length
    // ) {
      setLoader(true)
      setValidationError("")

      const dataToSend = {
        "country":formData.country.value,
        "state":extractToken(formData.state),
        "city":extractToken(formData.city),
      };

      uploadUserDetails({
        url: "my-account/preference/update/work-location-detail",
        method: 'POST',
        body: dataToSend,
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
    });
      setLoader(false)
    // } else {
    //   setValidationError("Some required fields are missing.")
    // }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <ModalBox
        open={viewSuccessDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success
            title={"Work Location Details updated successfully"} 
            description={""} 
            buttonText={"Okay"}
            onClick={()=>{
              addScroll()
              setViewSuccessDetailsModal(false)
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Work Location preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <FloatingLabelDropdown
                label="Country"
                name="country"
                responseType="id"
                options={countryData} // Add options
                value={formData.country.token}
                onChange={(e, value) => {
                  setFormData({ ...formData, country: { token: e.target.value, value: value }, state: [], city: []  })
                }}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="State"
                name="state"
                responseType="id"
                options={stateData} // Add options
                value={formData.state}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
            </div>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="District/City"
                name="city"
                responseType="id"
                options={cityData} // Add options
                value={formData.city}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
            </div>
          </div>
        </div>
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
        <div className={style.footer}>
          <Button
            className={`${style.submitButton} primaryButton cancelButton`}
            onClick={() => {
              addScroll();
              setViewDetailsModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={() => {
              addScroll();
              submitForm()
            }}
            loading={isLoading}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </>
  );
}
