import React from 'react'
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles({
    Select: {
        height: '52px',
        fontFamily: 'Regular',
        fontSize: '14px',
        color: '#000000',
        '&:before': {
            borderColor: 'white',
        },
        '&:after': {
            borderColor: 'white',
        },
        '&:not(.Mui-disabled):hover::before': {
            borderColor: 'white',
        },
    },
    InputLabel: {
        fontSize: '14px',
    },
    MenuItem: {
        fontFamily: 'Regular',
        fontSize: '14px',
        color: '#000000',
    },
    icon: {
        fill: '#c4c4c4',
    },
    root: {
        color: 'white',
    },

});


const SelectBox = ({ labelname, data, nonename, value, label, onChange, labelid, id, defaultValue }) => {

    const classes = useStyles(); // Use the custom styles
    return (
        <>
            <FormControl fullWidth>
                <InputLabel id={labelid} className={classes.InputLabel}>{labelname}</InputLabel>
                <Select
                    labelId={labelid}
                    id={id}
                    value={value}
                    label={label}
                    onChange={onChange}
                    className={classes.Select}
                    defaultValue={defaultValue}
                    inputProps={{
                        classes: {
                            icon: classes.icon,
                            root: classes.root,
                        },
                    }}
                >
                    <MenuItem value="" className={classes.MenuItem}>
                        <em>{nonename}</em>
                    </MenuItem>
                    {data.map((data, index) => (
                        <MenuItem key={index} value={data.value} className={classes.MenuItem}>{data.name}</MenuItem>

                    ))}

                </Select>
            </FormControl>
        </>
    )
}

export default SelectBox
