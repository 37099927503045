import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../../UI/Loader/Loader';

const ColumnChart = ({ datas }) => {

  if (!datas || !datas.lable || !datas.series) {
    return <div style={{ width: '100 %', textAlign: 'center', display: 'flex', justifyContent: 'center', }}><Loader /></div>;
  }


  const options = {
    chart: {
      type: 'bar', // Specify the chart type as 'bar' for a column chart
      toolbar: {
        show: false, // Set this to false to hide the toolbar
      }
    },

    plotOptions: {
      bar: {
        columnWidth: '20%', // Adjust the width of the columns here
      },
      dataLabels: {
        enabled: false, // Hide the values inside the columns
      },
    },
    dataLabels: {
      enabled: false, // Hide the values inside the columns
    },
    xaxis: {
      categories: datas.lable, // X-axis categories
    },
    colors: ['#EFC82B'],
    toolbar: {
      show: false, // Hide header menu icons
    },
    grid: {
      show: true, // Show grid lines
      strokeDashArray: 2, // Set stroke dash array for dotted lines
    },
  };

  const series = [
    {
      name: 'Users',
      data: datas.series, // Data for the columns
    },
  ];

  return (
    <ReactApexChart options={options} series={series} type="bar" height={300} />
  );
};

export default ColumnChart;
