import React from 'react'
import classes from './PopPuoCulome.module.css'

const CulomeData = ({ title, subTitle }) => {
    return (
        <>
            <div className={classes.contant_box}>
                <p className={classes.contant_box_titel}>{title}</p>
                <p className={classes.contant_box_sub}>{subTitle}</p>
            </div>
        </>
    )
}

export default CulomeData