import React, { useState } from 'react'
import style from "./Privatemodul.module.css"
import Image from '../../../../../../ui/Image/Image'
import Button from '../../../../../../ui/Button/Button'
import PrivateProfile from '../../../../assets/MyAccount/Private Profile.svg'


export default function Privatemodul({ setViewDetailsModal, onClick, setPrivateAccount }) {

  const cancel = () => {
    setViewDetailsModal(false)
    setPrivateAccount(false)
  }

  return (
    <div className={style.successModalContainer}>
      <Image
        src={PrivateProfile}
      />
      <p className={style.title}>Make Profile Private</p>
      <div className={style.description}>
        <p>Do you want to make your profile private?</p>
      </div>
      <div className={style.btn_group}>
        <Button
          className={`primaryButton ${style.cancel}`}
          onClick={cancel}
        >
          Cancel
        </Button>

        <Button
          className={`primaryButton ${style.submitButton}`}
          onClick={onClick}
        >
          Okay
        </Button>
      </div>
    </div>
  )
}
