import React, { useEffect, useState } from "react"
import style from "./SubscribePageNew.module.css"
import Button from "../../../../ui/Button/Button"
import useApiCall from '../../hooks/useApiCall';
import { avoidScroll, getUserToken } from "../../../../../src/utils/utils";
import Image from "../../../../ui/Image/Image";
import CheckOff from "../../assets/common/Close.svg";
import CheckOn from "../../assets/svg/Premium/CheckOn.svg";
import ModalBox from "../../../../ui/Modal/ModalBox";
import Preference from "../../features/SubscribePage/SubscribeCard/ModalPreference/Preference";
import Success from "../../features/SubscribePage/Modal/Success";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

export const SubscribePageNew = () => {

    const navigation = useNavigate();

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const [tabData, setTabData] = useState(null)
    const [selectedPlanType, setSelectedPlanType] = useState(null)
    const [selectedPlanToken, setSelectedPlanToken] = useState(null)

    const [viewDetailsModal, setViewDetailsModal] = useState(false)
    const [viewVideoUploadModal, setViewVideoUploadModal] = useState(false)
    const { isLoading: paymentLoading, success: paymentSuccess, error: paymentError, sendRequest: postDetails } = useApiCall();
    const { isLoading: isSkipLoading, success: successSkip, error: errorSkip, sendRequest: skipPreference } = useApiCall();

    useEffect(() => {
        window.scrollTo(0, 0);
        getDetails({
            url: `membership-plan/read-plan-list`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (data) => {
            console.log("data", data.data);
            setTabData(data.data)
        });
    }, [])

    //Function to load razorpay script for the display of razorpay payment SDK.
    function loadRazorpayScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    useEffect(() => {
        if (paymentSuccess) {
            avoidScroll()
            setViewDetailsModal(true)
        }
    }, [paymentSuccess])

    //function will get called when clicked on the pay button.
    async function displayRazorpayPaymentSdk(name, token) {
        const res = await loadRazorpayScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        getDetails({
            url: `payment/create-order`,
            method: 'POST',
            body: {
                "plan_token": token
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            // Getting the order details back
            const { order_id, key } = responseData.data;
            const options = {
                key: key, // Enter the Key ID generated from the Dashboard
                order_id: order_id,
                handler: async function (response) {
                    postDetails({
                        url: `payment/success`,
                        method: 'POST',
                        body: {
                            "order_id": order_id,
                            "payment_id": response.razorpay_payment_id,
                            "payment_signature": response.razorpay_signature,
                        },
                        headers: {
                            Authorization: `Bearer ${getUserToken()}`
                        }
                    })
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        });
    }

    useEffect(() => {
        if (successSkip) {
            localStorage.setItem('subscriptionFromHome', 0);
            navigation("/home")
        }
        if (errorSkip !== '') {
            toast.error(errorSkip, {
                autoClose: 2500,
            })
        }
    }, [successSkip, errorSkip]);

    const handleSkip = () => {
        skipPreference({
            url: "payment/skip-as-basic-user",
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        });
    }

    return (
        <div className={style.subscribeContainer}>
            <ToastContainer />
            <ModalBox
                open={viewVideoUploadModal}
                width={"880px"}
                modalContent={
                    <Preference from={"subscribe"}/>
                }
            />
            <ModalBox
                open={viewDetailsModal}
                width={"598px"}
                modalContent={
                    <Success
                        type={selectedPlanType}
                        setViewVideoUploadModal={setViewVideoUploadModal}
                        setViewDetailsModal={setViewDetailsModal}
                    />
                }
            />
            <table className={style.tableContainer}>
                {/* <thead>
                    <tr>
                    {columns.map((column, index) => (
                        <th key={index}>{column.label}</th>
                    ))}
                    </tr>
                </thead> */}
                <tbody>
                    <tr className={`${style.tableHeader} ${style.rowContainer}`}>
                        <td className={style.titleHeaderTop}>Features</td>
                        {tabData?.membership_type.map((row, rowIndex) => (
                            row?.plan_types?.slice(0, 1).map((plan, index) => (
                                <td colspan={row?.plan_types.length}>
                                    <p className={style.titleHeader}>{row["name"]}</p>
                                </td>
                            ))
                        ))}
                    </tr>
                    <div className={style.gap}></div>
                    <tr className={`${style.rowContainer} ${style.secondaryHeader}`}>
                        <td className={style.featuresHeader}>Price Duration</td>
                        {tabData?.membership_type.map((row, rowIndex) => (
                            row?.plan_types?.map((plan, index) => (
                                <td>
                                    <div className={style.priceDiscountContainer}>
                                        <p className={style.price}>Rs.{plan?.discount_amount}</p>
                                        <p className={style.offerPrice}>Rs.{plan?.amount}</p>
                                    </div>
                                    <p className={style.duration}>{plan?.duration} Months</p>
                                </td>
                            ))
                        ))}
                    </tr>
                    {tabData?.benefit_list.map((rows, rowIndex) => (
                        <tr className={style.rowContainer} key={rowIndex}>
                            <td className={style.features}>{rows?.benefit_name}</td>
                            {rows?.benefit_data.map((row, rowIndex) => (
                                <td>
                                    {
                                        row.value === "0" || row.value === "1" ?
                                            <Image
                                                src={row.value === "1" ? CheckOn : CheckOff}
                                                alt={"Check"}
                                                className={style.available}
                                            />
                                            :
                                            <p>{row.value}</p>
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                    <tr className={`${style.rowContainer}`}>
                        <td className={style.titleHeader}></td>
                        {tabData?.membership_type.map((row, rowIndex) => (
                            row?.plan_types?.map((plan, index) => (
                                <td>
                                    {row["name"] === "Basic" ? 
                                        <Button
                                            style={{ minWidth: 120, cursor: "not-allowed", opacity: 0.5 }}
                                            className={`primaryButton ${style.subscribeButton}`}
                                        >
                                            {row["name"] === "Basic" ? "Free User" : "Subscribe Now"}
                                        </Button>
                                    :
                                        <Button
                                            style={{ minWidth: 120 }}
                                            className={`primaryButton ${style.subscribeButton}`}
                                            onClick={() => {
                                                if (row["name"] === "Basic") {
                                                    console.log("if");
                                                    handleSkip()
                                                } else {
                                                    console.log("else");
                                                    displayRazorpayPaymentSdk(row?.name, plan?.token)
                                                    setSelectedPlanType(row?.name)
                                                    setSelectedPlanToken(plan?.token)
                                                }
                                            }}
                                        >
                                            {row["name"] === "Basic" ? "Free User" : "Subscribe Now"}
                                        </Button>
                                    }
                                    {
                                        plan?.is_current && (
                                            <p className={style.currentPlan}>Current Plan</p> 
                                        )
                                    }
                                    {
                                        plan?.is_active && (
                                            <p className={style.activePlan}>Active</p> 
                                        )
                                    }
                                </td>
                            ))
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    )
}