import React, { useState, useRef, useEffect } from 'react';
import classes from './Video.module.css';

const VideoPlayer = ({ src, clear, thumbnail, playBtn, onCloseFunction }) => {
    const [showThumbnail, setShowThumbnail] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        if (!showThumbnail && videoRef.current) {
            videoRef.current.play().catch(error => {
                console.error('Video playback error:', error);
            });
        }
    }, [showThumbnail]);

    useEffect(() => {
        if (clear && videoRef.current) {
            setShowThumbnail(true);
            videoRef.current.pause(); // Pause the video
            videoRef.current.currentTime = 0;
            onCloseFunction(); // Call onCloseFunction when cleared
        }
    }, [clear, onCloseFunction]);

    const handlePlayButtonClick = () => {
        setShowThumbnail(false);
    };

    const handleVideoEnded = () => {
        setShowThumbnail(true);
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            onCloseFunction(); // Call onCloseFunction when the video ends
        }
    };

    return (
        <div>
            {showThumbnail ? (
                <div className={classes['video-container']} style={{
                    backgroundImage: `url(${thumbnail})`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain'
                }}>
                    <img
                        className={classes.Thumbnail_img}
                        src={thumbnail}
                        alt="Thumbnail"
                    />
                    <img
                        className={classes.PlayBtn}
                        src={playBtn}
                        alt="PlayBtn"
                        onClick={handlePlayButtonClick}
                    />
                </div>
            ) : (
                <div className={classes['video-container']}>
                    <video
                        ref={videoRef}
                        controls
                        onEnded={handleVideoEnded}
                        style={{ display: 'block', maxWidth: '100%' }}
                    >
                        <source src={src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
