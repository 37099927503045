import React from "react"
import classes from "./ThreeCulome.module.css"
const ThreeCulome = ({ title, data, style }) => {
  return (
    <div className={classes.three_culome_set}>
      <h3 className={classes.titel}>{title}</h3>
      <div className={style ? style : classes.three_culome}>
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Name</p>
          <p className={classes.contant_box_sub}>{data.name}</p>
        </div>
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Date of Birth</p>
          <p className={classes.contant_box_sub}>{data.dob}</p>
        </div>
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Gender</p>
          <p className={classes.contant_box_sub}>{data.gender}</p>
        </div>
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Mobile Number</p>
          <p className={classes.contant_box_sub}>{data.mobile_number}</p>
        </div>
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Email Address</p>
          <p className={classes.contant_box_sub}>{data.email}</p>
        </div>
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Medical Council Name</p>
          <p className={classes.contant_box_sub}>{data.council_name}</p>
        </div>
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>NMC/SMC Number</p>
          <p className={classes.contant_box_sub}>{data.imr_number}</p>
        </div>
        {/* <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Studied College</p>
          <p className={classes.contant_box_sub}>{data.annual_income}</p>
        </div> */}
        <div className={classes.contant_box}>
          <p className={classes.contant_box_titel}>Registration Year</p>
          <p className={classes.contant_box_sub}>{data.year_of_reg}</p>
        </div>
      </div>
    </div>
  )
}

export default ThreeCulome
