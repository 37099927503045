import React, { useState } from 'react';
import style from "./Search.module.css";
import Image from '../../../../ui/Image/Image';

import activeSortlistedIcon from "../../assets/svg/Matches/Shortlistd.svg";
import inactiveSortlistedIcon from "../../assets/svg/Matches/inactive/Shortlistd.svg";
import activeBestMatchesIcon from "../../assets/svg/Matches/Best Matches.svg";
import inactiveBestMatchesIcon from "../../assets/svg/Matches/inactive/Best Matches.svg";
import activeNewlyJoinedIcon from "../../assets/svg/Matches/Newly Joined.svg";
import inactiveNewlyJoinedIcon from "../../assets/svg/Matches/inactive/Newly Joined.svg";
import activeAllMatchesIcon from "../../assets/svg/Matches/All Matches.svg";
import inactiveAllMatchesIcon from "../../assets/svg/Matches/inactive/All Matches.svg";
import activeViewedByMeIcon from "../../assets/svg/Matches/Viewed by me.svg";
import inactiveViewedByMeIcon from "../../assets/svg/Matches/inactive/Viewed by me.svg";
import activeViewedMyProfileIcon from "../../assets/svg/Matches/Viewed my profile.svg";
import inactiveViewedMyProfileIcon from "../../assets/svg/Matches/inactive/Viewed my profile.svg";

import Shortlisted from '../../features/Matches/Shortlisted/Shortlisted';
import BestMatches from '../../features/Matches/BestMatches/BestMatches';
import NewlyJoined from '../../features/Matches/NewlyJoined/NewlyJoined';
import AllMatches from '../../features/Matches/AllMatches/AllMatches';
import ViewByMe from '../../features/Matches/ViewByMe/ViewByMe';
import ViewedMyProfile from '../../features/Matches/ViewedMyProfile/ViewedMyProfile';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchResult from '../../features/Search/SearchResult/SearchResult';
import { BecameMemberModal } from '../../features/BecameMemberModal/BecameMemberModal';

export default function Search() {
    const navigate = useNavigate(); // Initialize useHistory
    const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState(state?.selectedMatch ? state?.selectedMatch : "shortlisted");

  const menuItems = [
    {
      title: "Search",
      icon: currentPage === "shortlisted" ? activeSortlistedIcon : inactiveSortlistedIcon,
    }
  ];

  // const replaceNavigationState = () => {
  //   navigate("/matches")
  // }

  // Define a mapping object for page components
  const pageComponents = {
    shortlisted: <SearchResult term={state.term}/>,
    // Add other page components here
  };

  return (
    <>
      <div className={`container ${style.matchesContainer}`}>
        <div className={style.sideBarContainer}>
          {/* <p className={style.title}>Matches</p> */}
          <div className={style.contentContainer}>
            {menuItems.map((menuItem, index) => {
              return(
                  <MatchMenuItem
                      key={index}
                      title={menuItem.title}
                      icon={menuItem.icon}
                      isActive={currentPage === menuItem.title.toLowerCase().replaceAll(" ", "_")}
                      onClick={menuItem.onClick}
                  />
              )
          })}
          </div>
        </div>
        {/* Render the component based on the currentPage */}
        {pageComponents[currentPage]}
      </div>
    </>
  );
}

export function MatchMenuItem({ title, isActive, icon, onClick }) {
    return (
        <div className={`${style.content} ${isActive ? style.active : ""}`} >
            <Image src={icon} />
            <p className={style.menuTitle}>{title}</p>
        </div>
    );
}
