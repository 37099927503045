import React, { useEffect } from 'react'
import style from "./Success.module.css"
import successIcon from "../../../assets/svg/Premium/Purchase Completed.svg"
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import { useNavigate } from 'react-router-dom'
import { addScroll, getUserData } from '../../../../../utils/utils'

export default function Success({setViewDetailsModal, setViewVideoUploadModal, type}) {

  const navigate = useNavigate();

  const nextStep = () => {
    const storedData = JSON.parse(localStorage.getItem('user'));
    storedData.membership_type = type;
    localStorage.setItem('user', JSON.stringify(storedData));
    addScroll()
    if( localStorage.getItem('subscriptionFromHome') == 0 ) {
      setViewDetailsModal(false)
      navigate("/build_profile")
    } else {
      localStorage.setItem('subscriptionFromHome', 0);
      navigate("/home")
    }
  }
  
  return (
    <div className={style.successModalContainer}>
        <Image
            src={successIcon}
        />
        <p className={style.title}>Hurray! Purchase Completed.</p>
        <div className={style.description}> 
          <p>Payment is successful. You're now one step closer to</p><p> finding your perfect match with Doctor</p><p> Matrimony.</p>
        </div>
        <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={nextStep}
        >
          Okay
        </Button>
    </div>
  )
}
