import React, { useState } from 'react';
import styles from './FloatingLabelDropdown.module.css';
import Dropdown from '../Dropdown/Dropdown';
import { phoneCode } from '../../Data/data';
import dropdownIcon from "../assets/dropdown.svg"


function FloatingLabelDropdown({ label, placeholder, options, value, onChange, style, name, responseType, disabled }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleDropdownSelect = (event) => {
    const selectedValue = event.target.value; // Get the selected value from the event
    const selectedName = event.target.options[event.target.selectedIndex].text; // Get the selected option's text (option.name)
    onChange(event, selectedName); // Pass both the selected value and the option name to the onChange function
  };

  return (
    <div style={style} className={`${styles.floatingLabel} ${styles['popout-input']} ${styles.focused}`}>
      <label className={styles['input-label']}>{label}</label>
      <select
        value={value ? value : ''}
        name={name}
        onChange={handleDropdownSelect}
        disabled={options?.length > 0 || !disabled ? false : true }
      >
        <option>
          Select an option
        </option>
        { options && options?.map((option) => (
          <option key={option.token} value={option[responseType]}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FloatingLabelDropdown;
