import React from 'react';
import ReactApexChart from 'react-apexcharts';
import classes from "./PieChart.module.css"
import Loader from '../../../UI/Loader/Loader';
const PieChart = ({ datas }) => {

  if (!datas || !datas.labels || !datas.series) {
    return <div style={{ width: '100 %', textAlign: 'center', display: 'flex', justifyContent: 'center', }}><Loader /></div>;
  }


  const options = {
    chart: {
      width: 380,
      type: 'pie',
      toolbar: {
        show: false,
      },
    },
    labels: datas.labels, // Labels for the pie chart sectors
    colors: ['#30A1E8', '#E430E8'],
    legend: {
      show: false, // Show the legend
      position: 'right', // You can adjust the position if needed
    },
    plotOptions: {
      pie: {
        dataLabels: {
          offset: -45, // Set the offset to 0 for centering the labels
          textAnchor: 'start', // Align text to the start of the label
          style: {
            fontSize: '50px', // Set the font size of the data labels
            colors: ['#000'], // Set the color of the data labels (black in this case)
          },
        },
      },

    },

  };

  const series = datas.series; // Data for the pie chart sectors


  return (
    <div className={classes.chartContainer}>
      <ReactApexChart options={options} series={series} type="pie" height={300} />
      <div className={classes.pieChartLegend}>
        <div>
          <div className={classes.legend}>
            <div className={`${classes.legendSquare} ${classes.male}`}></div>
            <p className={classes.legendText}>Male</p>
          </div>
          <p className={classes.legendValue}>Active Users - <span className={classes.legendSubValue}>{`${datas.data_count[0].activeMaleCount}`}</span></p>
          <p className={classes.legendValue}>Inactive - <span className={classes.legendSubValue}>{`${datas.data_count[0].inactiveMaleCount}`}</span></p>
        </div>
        <div>
          <div className={`${classes.legend}`}>
            <div className={`${classes.legendSquare} ${classes.female}`}></div>
            <p className={classes.legendText}>Female</p>
          </div>
          <p className={classes.legendValue}>Active Users - <span className={classes.legendSubValue}>{`${datas.data_count[0].activeFemaleCount}`}</span></p>
          <p className={classes.legendValue}>Inactive - <span className={classes.legendSubValue}>{`${datas.data_count[0].inactiveFemaleCount}`}</span></p>
        </div>

      </div>
    </div>
  );
};

export default PieChart;
