// NumberContext.js
import { createContext, useState, useContext, useEffect } from 'react';
import useApiHttp from '../hooks/ues-http';
import { BASE_URL } from '../utils/baseUrl';

const NumberContext = createContext();

const NumberProvider = ({ children }) => {
    const [count, setCount] = useState(0);
    const { sendRequest: widgetCount } = useApiHttp();
    const widgetCountRequestData = () => {
        widgetCount({
            url: `${BASE_URL}widget-count`,
            method: 'POST',
        }, (data) => {
            setCount(data.data.widget_count)
        });
    };
    useEffect(() => {
        widgetCountRequestData()
    }, [])

    return (
        <NumberContext.Provider value={{ count, widgetCountRequestData }}>
            {children}
        </NumberContext.Provider>
    );
};

const useNumber = () => {
    const context = useContext(NumberContext);
    if (!context) {
        throw new Error('useNumber must be used within a NumberProvider');
    }
    return context;
};

export { NumberProvider, useNumber };
