import React from "react"
import style from "./MembershipTypeCard.module.css"
import Image from "../../../../ui/Image/Image"
import premiumIcon from "./assets/premium.svg"
import eliteIcon from "./assets/elite.svg"

export const MembershipTypeCard = ({type}) => {
    return(
        <>
            {
                type !== "Basic" && (
                    <div className={style.membershipTypeCard}>
                        <Image src={ type === "Premium" ? premiumIcon : eliteIcon }/>
                        <p className={ type === "Premium" ? style.premiumText : style.eliteText}>{type}</p>
                    </div>
            )}
        </>
    )
}