import React, { useContext } from 'react'
import style from "./ProfileMatchesDetails.module.css"
import Image from '../../../../../ui/Image/Image'

import degreeImage from "../../../assets/svg/ProfileView/Degree.svg"
import matchScoreImage from "../../../assets/svg/ProfileView/Match Score.svg"

import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

import cityImage from "./assets/city.svg"
import communityImage from "./assets/community.svg"
import eatingHabitImage from "./assets/eating_habit.svg"
import educationImage from "./assets/education.svg"
import familyTypeImage from "./assets/family_type.svg"
import hobbyImage from "./assets/hobby.svg"
import starImage from "./assets/star.svg"

import raasi_air from "./assets/raasi_air.svg"
import raasi_earth from "./assets/raasi_earth.svg"
import raasi_fire from "./assets/raasi_fire.svg"
import raasi_water from "./assets/raasi_water.svg"

export default function ProfileMatchesDetails() {
    const { data } = useContext(ProfileDetailsContext)
    const details = data?.profile_details?.user_matches_calculation
  return (
    <div className={style.profileMatchesContainer}>
        <div className={style.profileMatches}>
            <div className={style.imagesContainer}>
                <Image 
                    src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+details?.user_image}
                    className={style.match1}
                />
                <Image 
                    src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+details?.partner_image}
                    className={style.match2}
                />
                <Image 
                    src={matchScoreImage}
                    className={style.heart}
                />
                <div className={style.matchText}>
                    <p className={style.matchCount}>{details?.percentage}%</p>
                    <p className={style.matchLabel}>Match Score</p>
                </div>
            </div>
            <div className={style.detailsContainer}>
                <p className={style.title}>Congratulations</p>
                <p className={style.subTitle}>Your profile matches her {details?.match_count}/{details?.total_matches}</p>
                <ul className={style.lists}>
                    {
                        details?.matches?.map((item) => (
                            <>
                                {
                                    item?.type === "education" && (
                                        <li className={style.list}>
                                            <Image 
                                                src={educationImage}
                                            />
                                            <p className={style.text}>You both have a <span>{item?.value} degree</span></p>
                                        </li>
                                    )
                                }
                                {
                                    item?.type === "community" && (
                                        <li className={style.list}>
                                            <Image 
                                                src={communityImage}
                                            />
                                            <p className={style.text}>You both are from <span>{item?.value} community</span></p>
                                        </li>
                                    )
                                }
                                {
                                    item?.type === "eating_habit" && (
                                        <li className={style.list}>
                                            <Image 
                                                src={eatingHabitImage}
                                            />
                                            <p className={style.text}>You both enjoy <span>{item?.value}</span> food</p>
                                        </li>
                                    )
                                }
                                {
                                    item?.type === "star" && (
                                        <li className={style.list}>
                                            <Image 
                                                src={starImage}
                                            />
                                            <p className={style.text}>You both are <span>{item?.value}</span> star</p>
                                        </li>
                                    )
                                }
                                {
                                    item?.type === "raasi" && (
                                        <li className={style.list}>
                                            <Image 
                                                src={
                                                    item?.element === "Air" ?
                                                        raasi_air
                                                    :
                                                        item?.element === "Water" ?
                                                            raasi_water
                                                        :
                                                            item?.element === "Fire" ?
                                                                raasi_fire
                                                            :
                                                                raasi_earth
                                                        
                                                }
                                            />
                                            <p className={style.text}>She is a  <span>{item?.value}</span> - you both share the <span>“{item?.element}” Zodiac</span> element</p>
                                        </li>
                                    )
                                }
                                {
                                    item?.type === "location" && (
                                        <li className={style.list}>
                                            <Image 
                                                src={cityImage}
                                            />
                                            <p className={style.text}>You both live in <span>{item?.value}</span></p>
                                        </li>
                                    )
                                }
                                {
                                    item?.type === "hobbies" && (
                                        <li className={style.list}>
                                            <Image 
                                                src={hobbyImage}
                                            />
                                            <p className={style.text}>You both like <span>{item?.value}</span></p>
                                        </li>
                                    )
                                }
                            </>
                        ))
                    }
                </ul>
            </div>
        </div>
    </div>
  )
}
