import React, { useEffect } from 'react';
import MembershipBanner from '../../features/SubscribePage/MembershipBanner/MembershipBanner';
import { useNavigate } from 'react-router-dom';
import useAuthentication from '../../hooks/useAuthentication';
import { getUserData, getUserToken } from '../../../../utils/utils';
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';
import style from "./SubscribePage.module.css"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SubscribePageNew } from '../SubscribePageNew/SubscribePageNew';

const SubscribePage = () => {

    const navigation = useNavigate();

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const { isLoading: isSkipLoading, success: successSkip, error: errorSkip, sendRequest: skipPreference } = useApiCall();

    useEffect(() => {
        if( getUserToken() === null ) {
            navigation("/")
        } else {
            if( localStorage.getItem('subscriptionFromHome') == 0 ) {
                getDetails({
                    url: "auth/check-user",
                    method: 'POST',
                    headers: {
                    Authorization: `Bearer ${getUserToken()}`
                    }
                }, (data) => {
                    const storedData = JSON.parse(localStorage.getItem('user'));
                    if( storedData.status === "Verified" ) {
                        return
                    }
                    storedData.status = data.data?.status;
                    localStorage.setItem('user', JSON.stringify(storedData));
    
                    let { status } = data.data
       
                    switch (status) {
                        case "Onboarding":
                            navigation("/build_profile")
                            break;
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        if (successSkip) {
            if( localStorage.getItem('subscriptionFromHome') == 0 ) {
                navigation("/build_profile")
            } else {
                localStorage.setItem('subscriptionFromHome', 0);
                navigation("/home")
            }
        }
        if (errorSkip !== '') {
            toast.error(errorSkip, {
              autoClose: 2500,
            })
        }
    }, [successSkip, errorSkip]);

    const handleSkip = () => {
        skipPreference({
          url: "payment/skip-as-basic-user",
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        });
    }

    return (
        <div>
            <ToastContainer />
            <MembershipBanner/>
            {/*<div className={style.skipContainer}>
                <p className={style.skipTitle} onClick={handleSkip}>Continue as free user</p>
            </div>*/}
        </div>
    );
};

export default SubscribePage;