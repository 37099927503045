import React, { useEffect, useState } from "react"
import style from "./DeclineModal.module.css"
import FloatingLabelDropdown from "../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown"
import PopoutInput from "../../../../../ui/PopoutInput/PopoutInput"
import PopoutTextarea from "../../../../../ui/PopoutTextarea/PopoutTextarea"
import Button from "../../../../../ui/Button/Button"
import { addScroll, getUserToken, isStringValid } from "../../../../../utils/utils"
import config from '../../../../../app.json';
import useApiCall from '../../../hooks/useApiCall';
import Image from "../../../../../ui/Image/Image"
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"

export const DeclineModal = ({setShowRejectReasonModal, setRejectReason, onClick}) => {


    const [ reasonData, setReasonData ] = useState(null);
    const [ selectedReason, setSelectedReason ] = useState(null);

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    const { isLoading: sendReportLoading, success: sendReportSuccess, error: sendReportError, sendRequest: submitReport } = useApiCall();

    const [ formData, setFormData ] = useState({
        matrimony_id: "",
        reason: "",
        comment: ""
    })

    const [ formError, setFormError ] = useState({
        matrimony_id: "",
        reason: "",
        comment: ""
    })

    useEffect(()=> {
      getDetails({
        url: `profile/read-reject-reason`,
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
      }, (responseData) => {
        setReasonData(responseData?.data)
      });
    }, [])

    const handleSubmit = () => {
        addScroll()
        onClick(selectedReason)
        setShowRejectReasonModal(false)
    }

    return(
        <div className={style.reportModalContainer}>
            <p className={style.title}>Choose Reason to cancel the Request</p>
            <div className={style.formContainer}>
                {
                    reasonData?.map((item, index) => (
                        <div className={style.condition} key={index}>
                            <Image src={item.name === selectedReason ? radioSelected : radioUnselected} onClick={() => {
                                setSelectedReason(item?.name)
                            }} />
                            <p>{item?.name}</p>
                        </div>
                    ))
                }
            </div>
            {
                formError.reason && 
                <p className="error">{formError.reason}</p>
            }
            {
                formError.comment && 
                <p className="error">{formError.comment}</p>
            }
            <div className={style.buttonProgressContainer}>
                <div className={style.buttonContainer}>
                    <Button 
                        className={`${style.button} ${style.declineButton} primaryButton cancelButton`}
                        onClick={()=> {
                            addScroll()
                            setShowRejectReasonModal(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={`${style.button} primaryButton`}
                        onClick={handleSubmit}
                        loading={sendReportLoading}
                    >
                        Okay
                    </Button>
                </div>
            </div>
        </div>
    )
}