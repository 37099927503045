import React from 'react'
import classes from './Loder.module.css'
const Loader = () => {
    return (
        <>
            <div className={classes["custom-loader"]}></div>

        </>
    )
}

export default Loader