import AWS from 'aws-sdk';
import axios from 'axios';

export const dateFormator = (date) => {
    const inputDate = new Date(date);
    const outputDate = new Date(inputDate.getTime() + (24 * 60 * 60 * 1000)); // Add 1 day in milliseconds

    const month = String(outputDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(outputDate.getDate()).padStart(2, '0');
    const year = outputDate.getFullYear();

    return `${month}/${day}/${year}`;
}

export const checkPdf = (fileName) => {
  console.log("fileName", fileName);
  if( fileName?.includes(".pdf") ) {
    return true
  }
  return false
}



export const formatTimeAgo = (timestamp) => {
  const milliseconds = timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);
  const messageTime = new Date(milliseconds);
  const currentTime = new Date();

  const timeDifference = currentTime - messageTime;
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);

  if (daysDifference > 1) {
      return `${daysDifference} days ago`;
  } else if (daysDifference === 1 || hoursDifference >= 24) {
      return 'Yesterday';
  } else if (hoursDifference >= 1) {
      return `${hoursDifference} hrs ago`;
  } else if (minutesDifference >= 1) {
      return `${minutesDifference} mins ago`;
  } else {
      return 'Just now';
  }
}

export const isAlphaNumeric = (inputString) => {
  // Define a regular expression pattern for alpha-numeric characters
  var pattern = /^[a-zA-Z0-9]+$/;

  // Check if the input string matches the pattern and does not contain spaces or special characters
  if (pattern.test(inputString) && !/\s/.test(inputString)) {
    return true;
  } else {
    return false;
  }
}

export const currentDateForFilename = () => {
  // Get the current date and time
  var currentDate = new Date();

  // Extract individual components
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
  var day = currentDate.getDate();
  var hours = currentDate.getHours();
  var minutes = currentDate.getMinutes();
  var seconds = currentDate.getSeconds();
  var milliseconds = currentDate.getMilliseconds();

  // Display the result
  return  year +
      '-' +
      padNumber(month) +
      '-' +
      padNumber(day) +
      ' ' +
      padNumber(hours) +
      ':' +
      padNumber(minutes) +
      ':' +
      padNumber(seconds) +
      '.' +
      padNumber(milliseconds, 3)

}

// Helper function to pad single-digit numbers with leading zeros
function padNumber(number, length) {
  length = length || 2;
  return ('0' + number).slice(-length);
}

export function formatMuiDate(inputDateString) {
  // Parse the original date string
  var originalDate = new Date(inputDateString);

  // Extract the date components
  var day = originalDate.getDate();
  var month = originalDate.getMonth() + 1; // Months are zero-based, so we add 1
  var year = originalDate.getFullYear();

  // Create the formatted date string with zero-padded month
  var formattedDateString = `${day}-${month.toString().padStart(2, '0')}-${year}`;

  return formattedDateString;
}

function formatDateValue(date) {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  return `${year}-${day}-${month}`;
}

export const formatDMYDate = (inputDate) => {
  const dateParts = inputDate?.split('-');
  return `${dateParts[1]}-${dateParts[2]}-${dateParts[0]}`
}

export const formatYMDDate = (inputDate) => {
  const dateParts = inputDate?.split('-');
  return `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
}

const formatDate = (inputDate) => {
  const dateParts = inputDate.split('-');
  const year = parseInt(dateParts[0]);
  const month = parseInt(dateParts[1]) - 1;
  const day = parseInt(dateParts[2]);

  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'long',
    timeZone: 'Asia/Kolkata', // Set the time zone to India Standard Time
  };

  const formattedDate = new Date(year, month, day).toLocaleString('en-US', options);

  return formattedDate;
};

export function defaultDate() {
  // Get the current date
  const currentDate = new Date();

  // Subtract 22 years from the current date
  const twentyTwoYearsAgo = new Date();
  twentyTwoYearsAgo.setFullYear(currentDate.getFullYear() - 22);

  return formatDateValue(twentyTwoYearsAgo)

}

export const avoidScroll = () => {
    document.querySelector("html").classList.add('removeScroll')
}

export const addScroll = () => {
    setTimeout(()=> {
        document.querySelector("html").classList.remove('removeScroll')
    }, 100)
}

export const getUserData = () => {
  const userData = localStorage.getItem("user");
  try {
    return JSON.parse(userData) || {}; // Handle potential null or invalid JSON data
  } catch (error) {
    console.error("Error parsing JSON data:", error);
    return {}; // Return an empty object or handle the error as needed
  }
}

export const slugify = (str) => {
  str = str?.replace(/^\s+|\s+$/g, ''); // Trim leading and trailing whitespaces
  str = str?.toLowerCase(); // Convert to lowercase
  str = str?.replace(/\s+/g, '-'); // Replace spaces with hyphens
  return str;
}

export const getUserToken = () => {
  const token = localStorage.getItem("token")
  return token
}

export const logout = () => {
  localStorage.clear();
  window.location.url = "/"
}

export function isStringValid(input) {
  return input !== "" && input !== "Select an option" && input?.length > 0;
}

const uploadToPresignedUrl = async (presignedUrl, selectedFile, fileSlug) => {
  console.log("data", presignedUrl, selectedFile);
  // Upload file to pre-signed URL
  const uploadResponse = await axios.put(presignedUrl, selectedFile, {
    headers: {
      'Content-Type': selectedFile.type,
    },
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
    },
  });
  if( uploadResponse.status === 200 ) {
    return fileSlug
  }
};

export const uploadFile = async (file) => {
  const fileSlug = slugify(currentDateForFilename()+"_"+file.name) 
  const token = localStorage.getItem("token")
  let fileName = ""
  await axios({
      url: process.env.REACT_APP_BASE_URL+"users/generate-presigned-url", 
      method: "post",
      data: {
        "path": fileSlug,
      },
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      }
    })
    .then((response) => {
      fileName = uploadToPresignedUrl(response.data.presigned_url, file, fileSlug)
    })

    return fileName


  // console.log("file", file);
  // const token = localStorage.getItem("token")
  // let fileName = ""
  // await axios({
  //   url: process.env.REACT_APP_BASE_URL+"users/s3-file-upload", 
  //   method: "post",
  //   data: {
  //     "file": file
  //   },
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //     'Content-Type': 'multipart/form-data',
  //   }
  // })
  // .then((response) => {
  //   fileName = response.data.data
  //   console.log("fileName", fileName);
  // })
  // return fileName
}

// export const uploadFile = async (file) => {
//     const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
//     const REGION = process.env.REACT_APP_S3_REGION;

//     AWS.config.update({
//       accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
//       secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
//     });
//     const s3 = new AWS.S3({
//       params: { Bucket: S3_BUCKET },
//       region: REGION,
//     });

//     const params = {
//       Bucket: S3_BUCKET,
//       Key: slugify(currentDateForFilename()+"_"+file.name),
//       Body: file,
//       ContentType: file.type, // Set the appropriate content type
//     };


//     try {
//         const uploadResponse = await s3.upload(params).promise();
//         console.log(uploadResponse);

//         // Return the URL of the uploaded file
//         return uploadResponse.Key;
//     } catch (error) {
//         console.error("Error uploading file:", error);
//         throw error; // You can handle the error as needed in your calling code.
//     }
//   };