import React, { useEffect, useState } from 'react';
import style from "./Matches.module.css";
import Image from '../../../../ui/Image/Image';

import activeSortlistedIcon from "../../assets/svg/Matches/Shortlistd.svg";
import inactiveSortlistedIcon from "../../assets/svg/Matches/inactive/Shortlistd.svg";
import activeBestMatchesIcon from "../../assets/svg/Matches/Best Matches.svg";
import inactiveBestMatchesIcon from "../../assets/svg/Matches/inactive/Best Matches.svg";
import activeNewlyJoinedIcon from "../../assets/svg/Matches/Newly Joined.svg";
import inactiveNewlyJoinedIcon from "../../assets/svg/Matches/inactive/Newly Joined.svg";
import activeAllMatchesIcon from "../../assets/svg/Matches/All Matches.svg";
import inactiveAllMatchesIcon from "../../assets/svg/Matches/inactive/All Matches.svg";
import activeViewedByMeIcon from "../../assets/svg/Matches/Viewed by me.svg";
import inactiveViewedByMeIcon from "../../assets/svg/Matches/inactive/Viewed by me.svg";
import activeViewedMyProfileIcon from "../../assets/svg/Matches/Viewed my profile.svg";
import inactiveViewedMyProfileIcon from "../../assets/svg/Matches/inactive/Viewed my profile.svg";

import Shortlisted from '../../features/Matches/Shortlisted/Shortlisted';
import BestMatches from '../../features/Matches/BestMatches/BestMatches';
import NewlyJoined from '../../features/Matches/NewlyJoined/NewlyJoined';
import AllMatches from '../../features/Matches/AllMatches/AllMatches';
import ViewByMe from '../../features/Matches/ViewByMe/ViewByMe';
import ViewedMyProfile from '../../features/Matches/ViewedMyProfile/ViewedMyProfile';
import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../features/Chat/Sidebar/Sidebar';
import View from '../../features/Matches/View/View';
import { getUserToken } from '../../../../utils/utils';

export default function Matches() {
    const navigate = useNavigate(); // Initialize useHistory
    const { state } = useLocation();
  const [currentPage, setCurrentPage] = useState( localStorage.getItem("matchesTab") ? localStorage.getItem("matchesTab") : state?.selectedMatch ? state?.selectedMatch?.title : "Shortlisted");
  const [ pageCredentials, setPageCredentials ] = useState( state?.selectedMatch ? state?.selectedMatch : {
    api: "shortlisted",
    title: "Shortlisted",
    total_title: "Shortlisted profiles"
  } )

  useEffect(()=> {
    if( localStorage.getItem("matchesTab") ) {
      localStorage.getItem("matchesTab") && setCurrentPage(localStorage.getItem("matchesTab"))
      switch(localStorage.getItem("matchesTab")) {
        case "Shortlisted": 
          setPageCredentials({
            api: "shortlisted",
            title: "Shortlisted",
            total_title: "Shortlisted profiles"
          })
          break
        case "Best Matches": 
          setPageCredentials({
            api: "best-matches",
            title: "Best Matches",
            total_title: "Best Matches profiles"
          })
          break
        case "Newly Joined": 
          setPageCredentials({
            api: "newly-joined",
            title: "Newly Joined",
            total_title: "Newly Joined profiles"
          })
          break
        case "All Matches": 
          setPageCredentials({
            api: "all-matches",
            title: "All Matches",
            total_title: "All Matches profiles"
          })
          break
        case "Profile viewed by me": 
          setPageCredentials({
            api: "best-matches",
            title: "Best Matches",
            total_title: "Best Matches profiles"
          })
          break
        case "Viewed my profile": 
          setPageCredentials({
            api: "viewed-my-profile",
            title: "Viewed my profile",
            total_title: "Viewed my profile"
          })
          break
      }
    }
  }, [localStorage.getItem("matchesTab")])

  const menuItems = [
    {
      title: "Shortlisted",
      icon: currentPage === "Shortlisted" ? activeSortlistedIcon : inactiveSortlistedIcon,
      onClick: () => {
        localStorage.removeItem("matchesTab")
        replaceNavigationState()
        setCurrentPage("Shortlisted")
        setPageCredentials({
          api: "shortlisted",
          title: "Shortlisted",
          total_title: "Shortlisted profiles"
        })
      },
    },
    {
      title: "Best Matches",
      icon: currentPage === "Best Matches" ? activeBestMatchesIcon : inactiveBestMatchesIcon,
      onClick: () => {
        localStorage.removeItem("matchesTab")
        replaceNavigationState()
        setCurrentPage("Best Matches")
        setPageCredentials({
          api: "best-matches",
          title: "Best Matches",
          total_title: "Best Matches profiles"
        })
        },
    },
    {
      title: "Newly Joined",
      icon: currentPage === "Newly Joined" ? activeNewlyJoinedIcon : inactiveNewlyJoinedIcon,
      onClick: () => {
        localStorage.removeItem("matchesTab")
        replaceNavigationState()
        setCurrentPage("Newly Joined")
        setPageCredentials({
          api: "newly-joined",
          title: "Newly Joined",
          total_title: "Newly Joined profiles"
        })
    },
    },
    {
      title: "All Matches",
      icon: currentPage === "All Matches" ? activeAllMatchesIcon : inactiveAllMatchesIcon,
      onClick: () => {
        localStorage.removeItem("matchesTab")
        replaceNavigationState()
        setCurrentPage("All Matches")
        setPageCredentials({
          api: "all-matches",
          title: "All Matches",
          total_title: "All Matches profiles"
        })
    },
    },
    {
      title: "Profile viewed by me",
      icon: currentPage === "Profile viewed by me" ? activeViewedByMeIcon : inactiveViewedByMeIcon,
      onClick: () => {
        localStorage.removeItem("matchesTab")
        replaceNavigationState()
        setCurrentPage("Profile viewed by me")
        setPageCredentials({
          api: "recently-viewed",
          title: "Profile viewed by me",
          total_title: "Profile viewed by me"
        })
    },
    },
    {
      title: "Viewed my profile",
      icon: currentPage === "Viewed my profile" ? activeViewedMyProfileIcon : inactiveViewedMyProfileIcon,
      onClick: () => {
        localStorage.removeItem("matchesTab")
        replaceNavigationState()
        setCurrentPage("Viewed my profile")
        setPageCredentials({
          api: "viewed-my-profile",
          title: "Viewed my profile",
          total_title: "Viewed my profile"
        })
    },
    },
  ];

  const replaceNavigationState = () => {
    navigate("/matches")
  }

  // Define a mapping object for page components
  const pageComponents = {
    shortlisted: <Shortlisted />,
    best_matches: <BestMatches />,
    newly_joined: <NewlyJoined />,
    all_matches: <AllMatches />,
    profile_viewed_by_me: <ViewByMe />,
    viewed_my_profile: <ViewedMyProfile />,
    // Add other page components here
  };

  return (
    <div className={`container ${style.matchesContainer}`}>
      <div className={style.sideBarContainer}>
        <p className={style.title}>Matches</p>
        <div className={style.contentContainer}>
          {menuItems.map((menuItem, index) => {
            return(
                <MatchMenuItem
                    key={index}
                    title={menuItem.title}
                    icon={menuItem.icon}
                    isActive={currentPage === menuItem.title}
                    onClick={menuItem.onClick}
                />
            )
        })}
        </div>
      </div>
      {/* Render the component based on the currentPage */}
      <View type={currentPage} pageCredentials={pageCredentials}/>
      {/* {pageComponents[currentPage]} */}
      {
        getUserToken() && ( <Sidebar />)
      }
    </div>
  );
}

export function MatchMenuItem({ title, isActive, icon, onClick }) {
    return (
        <div className={`${style.content} ${isActive ? style.active : ""}`} onClick={onClick}>
            <Image src={icon} />
            <p className={style.menuTitle}>{title}</p>
        </div>
    );
}
