import React, { useContext } from 'react'
import style from './ProfessionalDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function ProfessionalDetails() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.profile_details?.user_profile_details?.professional_details[0]
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Professional Details</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            <div className={style.personalsDetail}>
              <p className={style.label}>Institution Name</p>
              <p className={style.title}>{details?.institution_working}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Employed in</p>
              <p className={style.title}>{details?.employed_in}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Occupation</p>
              <p className={style.title}>{details?.occupation}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Annual Income</p>
              <p className={style.title}>{details?.annual_income}</p>
            </div>
          </div>
        </div>
    </div>
  )
}
