import React, { useEffect, useState } from 'react'
import style from './DeleteProfile.module.css'
import Image from '../../../../../ui/Image/Image';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import Button from '../../../../../ui/Button/Button';
import ModalBox from "../../../../../ui/Modal/ModalBox";
import Privatemodul from './Modal/Privatemodul';
import Success from './Modal/Success';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import { getUserData, getUserToken } from '../../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import initializeFirebase from '../../../pages/Chat/Db/FirebaseDb'; 
import { collection,doc,setDoc,getDoc,updateDoc,getDocs,query,where} from "firebase/firestore";

const DeleteProfile = () => {
    const { isLoading: isLoading, success: success, code: responseCode, error: error, sendRequest: getDelete } = useApiCall();

    const {db} = initializeFirebase();
    const [viewDetailsModal, setViewDetailsModal] = useState(false)
    const [reasons, setReasons] = useState('');
    const [validationError, setValidationError] = useState("")

    const navigation = useNavigate()

    console.log("reasons", reasons);

    const handleSubmit = () => {
        if (reasons === "") {
            setValidationError("Please Select the Reason")
            setViewDetailsModal(false)
        } else {
            setViewDetailsModal(true)
        }
    }

    useEffect(()=> {
        if( responseCode === 401 ) {
            localStorage.removeItem("token");
            localStorage.removeItem("subscriptionFromHome");
            localStorage.removeItem("user");
            navigation("/")
        }
    }, [responseCode])

    const Delete = () => {
        if (reasons === "") {
            setValidationError("Please Select the Reason")
            setViewDetailsModal(false)
        } else {
            getDelete(
                {
                    url: "my-account/delete-profile",
                    method: 'POST',
                    body: { reason: reasons },
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`
                    }
                },
                (data) => {
                    const { user_token } = getUserData()
                    const checkUserQuery = doc(db, "Users", user_token);   
                    getDoc(checkUserQuery).then((userData)=>{
                        if(userData.exists()){
                            updateDoc(checkUserQuery,{
                                isOnline:false,
                                isDeleted: true
                            }).then(()=>{
                                setValidationError(" ")
                                setViewDetailsModal(false)
                                localStorage.removeItem("token");
                                localStorage.removeItem("subscriptionFromHome");
                                localStorage.removeItem("user");
                                navigation("/")
                            })
                        }
                    })
                }
            );
        }



    }

    useEffect(()=> {
        setValidationError("")
    }, [reasons])

    return (
        <>
            <div className={style.pageContentContainer} >
                <div className={style.edit_cont}>
                    <p className={style.title}>Delete Profile</p>
                    <p className={style.sub__title}>You’re about to start the process of deleting your Doctor’s Matrimony account. You can restore your Doctor’s Matrimony account up to 30 days after deletion.</p>
                </div>
                <div className={style.edit_cont}>
                    <p className={style.title}>Let us know why you wish to delete your Profile?</p>
                    <div className={style.rodio_btn_set}>

                        <div className={style.answers}>
                            <div className={style.answer} onClick={() => setReasons('Marriage Fixed')}>
                                <Image src={reasons === 'Marriage Fixed' ? radioSelected : radioUnselected} />
                                <p>Marriage Fixed</p>
                            </div>
                            <div className={style.answer} onClick={() => setReasons('Married')}>
                                <Image src={reasons === 'Married' ? radioSelected : radioUnselected} />
                                <p>Married</p>
                            </div>
                            <div className={style.answer} onClick={() => setReasons('Other Reasons')}>
                                <Image src={reasons === 'Other Reasons' ? radioSelected : radioUnselected} />
                                <p>Other Reasons</p>
                            </div>
                        </div>

                        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

                    </div>
                        {/* <p className={style.terms}>You’re about to start the process of deleting your Doctor’s Matrimony account. You can restore your Doctor’s Matrimony account up to 30 days after deletion.</p> */}
                        <Button
                            className={`primaryButton ${style.submitButton}`}
                            loading={isLoading}
                            onClick={handleSubmit}
                        >
                            Continue
                        </Button>
                </div>
            </div>

            <ModalBox
                open={viewDetailsModal}
                width={"400px"}
                modalContent={
                    <>
                        <Privatemodul setViewDetailsModal={setViewDetailsModal} onClick={Delete} />

                    </>
                } />
        </>
    )
}

export default DeleteProfile