import React, { useContext, useEffect, useRef, useState } from 'react';
import style from "./Otp.module.css";
import Button from '../../../../../ui/Button/Button';
import Image from '../../../../../ui/Image/Image';
import timerIcon from "../../../assets/common/Clock.svg"
import { LoginContext } from '../Context/LoginContext';
import useApiCall from '../../../hooks/useApiCall'
import config from "../../../../../app.json"
import { useNavigate } from 'react-router-dom';
import { addScroll, getUserData, getUserToken } from '../../../../../utils/utils';
import { getFirebaseToken, onForegroundMessage } from '../../../../../utils/firebase';
import { collection,doc,setDoc,getDoc,updateDoc,getDocs,query,where} from "firebase/firestore"; 
import initializeFirebase from '../../../pages/Chat/Db/FirebaseDb';

export default function Otp({signUp}) {

  const navigation = useNavigate();
  const {db} = initializeFirebase();    

  const { setActiveStep, id, setId, handleCloseDrawer } = useContext(LoginContext);

  const { success: sendOtpSuccess, error: sendOtpError, sendRequest: sendOtp } = useApiCall();
  const { sendRequest: sendDeviceToken } = useApiCall();
  const { isLoading: isVerifyOtpLoading, success: verifyOtpSuccess, error: verifyOtpError, sendRequest: otpVerification } = useApiCall();
  const { isLoading: getUserLoading, success: getUserSuccess, error: getUserError, sendRequest: getUserDetails } = useApiCall();

  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpError, setOtpError] = useState('');
  const [token, setToken] = useState('');

  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const [totalSeconds, setTotalSeconds] = useState(30); // 1 minute and 30 seconds

  useEffect(() => {
    const interval = setInterval(() => {
      if (totalSeconds > 0) {
        setTotalSeconds(totalSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [totalSeconds]);

  useEffect(() => {
    if (sendOtpSuccess) {
      setOtpError("")
      setOtp(['', '', '', ''])
      setTotalSeconds(30); // Reset to 1 minute and 30 seconds
    }
    if (sendOtpError) {
      setOtpError(sendOtpError)
    }
  }, [sendOtpSuccess, sendOtpError]);

  const resendOTP = () => {
    sendOtp({
      url: "auth/login/resend-otp",
      method: 'POST',
      body: {
        "user_id": id,
      },
    });
  };

  const handleChange = (index, value) => {
    if (!isNaN(value) && value !== '') {
      otp[index] = value;
      setOtp([...otp]);

      if (index < 3 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
      checkOtpValid() && setOtpError('');
    } else if (value === '' && index > 0) {
      otp[index] = '';
      setOtp([...otp]);
      inputRefs[index - 1].current.focus();
    } else if (value === '' && index === 0) {
      otp[index] = '';
      setOtp([...otp]);
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (getUserSuccess) {
    }
    if (getUserError) {
      setOtpError(verifyOtpError)
    }
  }, [getUserSuccess, getUserError]);

  useEffect(() => {
    if (verifyOtpSuccess) {
      getFirebaseToken()
      .then((firebaseToken) => {
        if (firebaseToken) {
          sendDeviceToken({
            url: "auth/update-fcm",
            method: 'POST',
            body: {
              "device_type": 'Web',
              "device_token": firebaseToken
            },
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            }
          });
        }
      })
      .catch((err) => {
        sendDeviceToken({
          url: "auth/update-fcm",
          method: 'POST',
          body: {
            "device_type": null,
            "device_token": null
          },
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          }
        });
        console.error('An error occured while retrieving firebase token. ', err)
      })
      addScroll()
      handleCloseDrawer()
    }
    if (verifyOtpError !== '') {
      setOtpError(verifyOtpError)
    }
  }, [verifyOtpSuccess, verifyOtpError]);

  const verifyOtp = () => {
    const otpValue = otp.join("")
    if (checkOtpValid()) {
      otpVerification({
        url: "auth/login/verify-otp",
        method: 'POST',
        body: {
          "user_id": id,
          "otp": otpValue,
          "device_type": null,
          "device_token": null
        },
      }, verificationData)
    } else {
      setOtpError('Please fill all the fields.'); // Set error message if OTP is not valid
    }
  };

  const verificationData = (data) => {
    let { status, gender, onboard_status, token, user_token , name, user_id,} = data.data
    localStorage.setItem("token", token)
    setToken(token)
    localStorage.setItem("user", JSON.stringify(data.data))
    if( status === "Expired" ) {
      localStorage.setItem('subscriptionFromHome', 3);
    }
    const userToken = data.data.user_token;
    const userName = data.data.name;
    const userProfileId = data.data.user_id;
    const userImage = data.data.image;
    const checkUserQuery = doc(db, "Users", userToken);      

  
    switch (status) {
      case "Registering":
        setActiveStep("certificate");
        break;
      case "Pending":
        navigation("/status")
        break;
      case "Rejected":
        navigation("/application_rejected")
        break;
      case "Blocked":
        navigation("/status")
        break;
      case "Expired":
        navigation("/expired")
        break;
      case "Verified":
        localStorage.setItem('subscriptionFromHome', 0);
        navigation("/subscribe")
        break;
      case "Onboarding":
        navigation("/build_profile")
        break;
      case "Preferencing":
        navigation("/partner_preference")
        break;
      case "Active":
        getDoc(checkUserQuery).then((userData)=>{
            if(userData.exists()){
                updateDoc(checkUserQuery,{
                  name: userName,
                  gender: gender,
                  isDeleted: false,
                  isOnline:true
                })
            }
            navigation("/home")
        })
        break;
    }
  }

  const checkOtpValid = () => {
    return otp?.every((digit) => !isNaN(digit) && digit !== '');
  }

  return (
    <div className={style.contentContainer}>
      <p className={style.title}>Enter OTP</p>
      <p className={style.description}>Enter the OTP that we have sent to your mobile number {id}</p>
      <p onClick={()=> {
        setId("")
        setActiveStep("form")
      }} className={style.changeNumber}>Change Mobile Number</p>
      <div className={style.otpErrorContainer}> 
        <div className={style.otpInputContainer}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              placeholder='0'
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, index)}
              maxLength={1}
              ref={inputRefs[index]}
            />
          ))}
        </div>
        {otpError && <p className={`${style.errorMessage} error`}>{otpError}</p>}
      </div>
      <div className={style.timerContainer}>
        {totalSeconds > 0 ? (
          <div className={style.timer}>
            <Image 
              src={timerIcon}
            />
            <p className={style.resendText}>
              {totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds} sec
            </p>
          </div>
        ) : (
          <p
            onClick={resendOTP}
            className={style.resendText}
          >
            Resend OTP
          </p>
        )}
      </div>
      <Button
        style={{ width: "100%" }}
        className={`primaryButton`}
        onClick={verifyOtp}
      >
        Verify OTP
      </Button>
    </div>
  );
}
