import React, { useState, useId } from "react";
import classes from './Slecters.module.css'

export default function Form({ name, options, selectedValue, handleChange }) {
    const vegetableSelectId = useId();

    return (
        <>
            <select id={vegetableSelectId} name={name} value={selectedValue} onChange={handleChange} className={classes.slecter}>
                {options && Array.isArray(options) && options.map((option, index) => (
                    <option key={index} value={option.value}>{option.year}</option>
                ))}
            </select>
        </>
    );
}
