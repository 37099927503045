import React, { useEffect, useState } from "react"
import style from "./AboutUs.module.css"
import Image from "../../../../ui/Image/Image"
import backArrowIcon from "../../assets/svg/Home/Left Arrow.svg"
import { useNavigate } from "react-router-dom"
import config from "../../../../app.json"
import useApiCall from '../../hooks/useApiCall';
import ReactHtmlParser from 'react-html-parser'

export const AboutUs = () => {
    const navigation = useNavigate()
    window.scrollTo(0, 0)

    const [ data, setData ] = useState(null)
    const { isLoading: isLoading, success: success, error: error, sendRequest: getMyprofile } = useApiCall();

    useEffect(()=> {
        getMyprofile(
            {
                url: "footer/about-us",
                method: 'GET',
            },
            (data) => {
                setData(data)
            }
        );
    },[])

    return(
        <div className={`${style.contentContainer} container`}>
            <div className={style.arrowAndTitleContainer}>
                {/* <Image onClick={()=> navigation(-1) } src={backArrowIcon} className={style.arrowIcon}/> */}
                <p className={style.title}>
                    { data?.message }
                </p>
            </div>
            <p className={style.description}>
                {ReactHtmlParser(data?.data?.detail)}
            </p>
        </div>
    )
}