import React from "react";
import Carousel from "react-grid-carousel";
import Image from "../../Image/Image";

const Gallery = ({ cols, rows, ImageUrl, gap, className, autoplay, }) => {

    const MyDot = ({ isActive }) => (
        <span
            style={{
                display: "inline-block",
                height: isActive ?  "3px" : "3px",
                width: isActive ?  "55px" : '55px',
                background: isActive ? "#fff" : "#FFFFFF60",
            }}
        ></span>
    );
    return (
        <Carousel hideArrow showDots dot={MyDot} cols={cols} rows={rows} autoplay={autoplay} gap={gap} loop>
            {ImageUrl?.map((data, index) => (
                <Carousel.Item key={index}>
                    <Image
                        src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+data}
                        alt={"Feedback"}
                        className={className}
                    />
                </Carousel.Item>
            ))}
        </Carousel>
    );
};

export default Gallery;
