import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel styles
import { Carousel } from 'react-responsive-carousel';
import Image from '../Img/Img';
import next from '../../assets/SVG/Manage Users/ImageLeftArrow.svg'
import prev from '../../assets/SVG/Manage Users/ImageRightArrow.svg'
const ImageCarousel = ({ images }) => {
    return (

        <>
            {images && images.length > 0 ? (
                <Carousel
                    axis="horizontal"
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    stopOnHover={true}
                    showThumbs={false} // Set showThumbs to false
                    className="relative"
                    renderArrowPrev={(clickHandler, hasPrev) => (
                        <div
                            className={`arrow-position ${hasPrev ? 'absolute icon_position next' : 'icon_position next hidden'}`}
                            onClick={clickHandler}
                        >
                           <Image src={next} alt="next" className="w-9 h-9 text-white" />
                        </div>
                    )}
                    renderArrowNext={(clickHandler, hasNext) => (
                        <div
                            className={`arrow-position ${hasNext ? 'absolute icon_position prev' : 'icon_position prev hidden'}`}
                            onClick={clickHandler}
                        >
                            <Image src={prev} alt="prev" className="w-9 h-9 text-white" />
                        </div>
                    )}
                    
                    
                >
                    {images.map((image, index) => (
                        <div key={index} style={{ height: 450 }} className="carousel_img_box">
                            <Image src={image} alt="icone" className="carousel_img" />
                        </div>
                    ))}
                </Carousel>
            ) : (
                <p className='text_align_center'>No images available</p>
            )}</>

    );
};

export default ImageCarousel;
