import React, { useEffect, useState } from 'react'
import style from "./Testimonials.module.css"
import Carousel from 'react-grid-carousel'
import Image from '../../../../../ui/Image/Image'

import carousalImage1 from "../../../assets/png/LandingPage/Dr.Aditya & Sowmiya@2x.png"
import carousalImage2 from "../../../assets/png/LandingPage/Dr.Arul Raj & Mahalakshmi@2x.png"
import carousalImage3 from "../../../assets/png/LandingPage/Dr.Harish & Catherina@2x.png"

import useApiCall from "../../../hooks/useApiCall"
import config from '../../../../../app.json';
import { getUserToken } from '../../../../../utils/utils'

export default function Testimonials() {

  const [data, setData] = useState([]);

  const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

  useEffect(() => {
      getDetails({
          url: `success-story/read-all`,
          method: 'GET',
      }, (responseData) => {
          setData(responseData.data.data);
      });
  }, []);

    const carouselData = [
        {
          id: 1,
          imageSrc: carousalImage1, // Replace with actual image source
          title: 'Dr. Arul Raj & Dr. Mahalakshmi',
          description: 'We met at Doctors Matrimony.com. As we started talking, we felt that spark. Our vibe matched, and we are getting engaged next month. Thank you.',
        },
        {
          id: 2,
          imageSrc: carousalImage2, // Replace with actual image source
          title: 'Another Couple',
          description: 'Our journey together started when we swiped right. Now, we are happily married and couldn\'t be more grateful.',
        },
        {
          id: 3,
          imageSrc: carousalImage3, // Replace with actual image source
          title: 'Dr. John & Dr. Jane',
          description: 'Doctors Matrimony.com helped us find each other. We are excited to begin this beautiful journey of life together.',
        },{
            id: 1,
            imageSrc: carousalImage1, // Replace with actual image source
            title: 'Dr. Arul Raj & Dr. Mahalakshmi',
            description: 'We met at Doctors Matrimony.com. As we started talking, we felt that spark. Our vibe matched, and we are getting engaged next month. Thank you.',
          },
          {
            id: 2,
            imageSrc: carousalImage2, // Replace with actual image source
            title: 'Another Couple',
            description: 'Our journey together started when we swiped right. Now, we are happily married and couldn\'t be more grateful.',
          },
          {
            id: 3,
            imageSrc: carousalImage3, // Replace with actual image source
            title: 'Dr. John & Dr. Jane',
            description: 'Doctors Matrimony.com helped us find each other. We are excited to begin this beautiful journey of life together.',
          },
        // Add more objects as needed
      ];
  return (
    <>
      {
        data?.length > 0 ?
          <div className={`${style.testimonialContainer} container`}>
              <h1 className={style.sectionTitle}>Thousands of Doctors have found</h1>
              <h1 className={style.sectionTitle}>their life partner at Doctors Matrimony!</h1>
              <p className={style.sectionDescription}>Join Doctors Matrimony and discover the perfect match among thousands of doctors who have found love 
              and happiness with their life partners. Start your journey to a fulfilling relationship today!</p>
              <div className={style.carousalContainer}>
                  <Carousel 
                      cols={3} 
                      rows={1} 
                      gap={30} 
                      loop 
                      showDots={true}
                      dotColorInactive={"#ECECEC"}
                      dotColorActive={"#EF7435"}
                  >
                      {data?.map((item) => (
                          <Carousel.Item key={item.id}>
                              <Image
                                  src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+item?.images[0]}
                                  borderRadius={"10px"}
                                  className={style.image}
                              />
                              <p className={style.title}>Dr.{item?.bride_name} & Dr.{item?.groom_name}</p>
                              <p className={style.description}>{item.success_story}</p>
                          </Carousel.Item>
                      ))}
                  </Carousel>
              </div>
          </div>
        :
        <></>
      }
    </>
  )
}
