import React, { useEffect, useRef, useState } from "react"
import style from "./Notifications.module.css"
import Image from "../../../../ui/Image/Image"
import drImage from "./assets/Dr. Vidhya@2x.png"
import backArrow from "../../assets/common/Back Arrow.svg"
import messageSentImage from "./assets/Message Sent.svg"
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';
import { getUserData, getUserToken } from '../../../../utils/utils'
import { useNavigate } from "react-router-dom"
import { NoData } from "../../features/NoData/NoData"

export const Notifications = () => {

    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: getDetails } = useApiCall();
    const { isLoading: sendUnreadSingleLoading, success: sendUnreadSingleSuccess, error: sendUnreadSingleError, sendRequest: unreadSingle } = useApiCall();
    const navigation = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)
    const [ clickedItem, setClickedItem ] = useState(null)
    const pageContentContainerRef = useRef(null);

    useEffect(() => {
        if (lastPage) {
            return;
        }
        getDetails({
            url: `notification/read-all?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setLastPage(currentPage >= responseData.data.last_page);
            setTotal(responseData.data.total)
            setData((prevData) => {
                if (!prevData) {
                    return prevData;
                }
                return [...prevData, ...responseData.data.data]
            });
            setLoadingMore(false);
        });
    }, [currentPage, lastPage]);

    const handleScroll = () => {
        const container = pageContentContainerRef.current;
        if (!container || loadingMore || lastPage) return; // Do nothing if loading, or last page reached.
    
        // Check if the user has scrolled to the bottom of the container
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            // Load more data when the user reaches the bottom
            setLoadingMore(true);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const container = pageContentContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
      if (sendUnreadSingleSuccess) {
        navigation("/profile_details", { state: { token: clickedItem} })
      }
    }, [sendUnreadSingleSuccess]);

    const handleProfileClick = (token, profile_token) => {
        setClickedItem(profile_token)
        unreadSingle({
            url: "notification/mark-as-read",
            method: 'POST',
            body: {
                "token": token
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        });
    }

    return(
        <div className={`${style.notificationsContainer} container`}>
            <div className={style.notificationContainer}>
                <div className={style.notificationSidebarContainer} ref={pageContentContainerRef}>
                    <p className={style.title}><Image onClick={()=> navigation("/home")} src={backArrow} className={style.backArrow}/>Notifications ({total})</p>
                    <div className={style.notificationListsContainer}>
                        {
                            data?.length > 0 ?
                                data?.map((item, index) => (
                                    <div key={item?.token} onClick={()=> item?.profile_token !== null && handleProfileClick(item?.token, item?.profile_token)} className={`${style.notificationListContainer} ${ !item?.is_seen && style.notSeen }`}>
                                        <div className={style.notificationImageNameContainer}>
                                            <div className={`${style.notSeenDot} ${ !item?.is_seen ? style.show : "" }`}></div>
                                            <Image 
                                                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+item.image}
                                                width={70}
                                                height={70}
                                                className={style.profileImage}
                                            />
                                            <div className={style.notificationName}>
                                                <p className={style.name}>{item?.title}</p>
                                                <p className={style.shortMessage}>{item?.description}</p>
                                            </div>
                                        </div>
                                        <div className={style.notificationTime}>{item?.created_at}</div>
                                    </div>
                                ))
                            :
                                <NoData isChat={false} title={`No notifications found`}/>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}