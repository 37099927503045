
import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  customInputLabel: {
    background: '#fff',
    padding: '3px',
    top: '-3px',
    fontFamily: 'Regular',
    fontSize: '14px',
    // Add any other custom CSS properties here
  },
  customOutlinedInput: {
    height: '52px',
    fontSize: '14px',
    // Add custom styles for the OutlinedInput here
    outline: 'none', // Remove the focus border
  },
});

export default function ComposedTextField({ label, value, onChange, name, defaultValue }) {
  const classes = useStyles(); // Use the custom styles
  const customFormControl = {
    width: "100%",
  };
  return (
    <FormControl style={customFormControl}>
      <InputLabel
        htmlFor="component-outlined"
        className={classes.customInputLabel} // Apply custom label style
      >
        {label}
      </InputLabel>
      <OutlinedInput
        id="component-outlined"
        defaultValue={defaultValue}
        onChange={onChange}
        value={value}
        name={name}
        className={classes.customOutlinedInput} // Apply custom OutlinedInput style
      />
    </FormControl>
  );
}
