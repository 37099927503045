import { initializeApp } from 'firebase/app';
import initializeFirebase from "../modules/web/pages/Chat/Db/FirebaseDb"
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics'; // Import Firebase Analytics

export const isMessagingSupported = () => {
  return 'serviceWorker' in navigator && 'PushManager' in window;
};

let messaging = ""
const {app} = initializeFirebase();
const analytics = getAnalytics(app)

if( isMessagingSupported() ) {
  messaging = getMessaging(app);
}

export const getOrRegisterServiceWorker = () => {
    if ('serviceWorker' in navigator) {
      return window.navigator.serviceWorker
        .getRegistration('/firebase-push-notification-scope')
        .then((serviceWorker) => {
          if (serviceWorker) return serviceWorker;
          return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            scope: '/firebase-push-notification-scope',
          });
        });
    }
    throw new Error('The browser doesn`t support service worker.');
  };
  
  export const getFirebaseToken = () =>
    getOrRegisterServiceWorker()
      .then((serviceWorkerRegistration) =>
        getToken(messaging, { vapidKey: process.env.REACT_APP_FB_VAPID_KEY, serviceWorkerRegistration }));


    export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));
