import React, { useContext, useEffect, useRef, useState } from 'react'
import style from "./Form.module.css"
import { NavbarContext } from '../Context/NavbarContext'
import { avoidScroll, getUserData, getUserToken } from '../../../../../utils/utils'
import notificationImage from "../../../assets/common/bell.svg"
import supportImage from "../../../assets/svg/LandingPage/Support.svg"
import profileImage from "../../../assets/png/Profile/Profile Photo@2x.png"
import dropdownImage from "../../../../../ui/assets/dropdown.svg"
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import { useLocation, useNavigate } from 'react-router-dom'

export default function Form({ setDrawerType }) {

  let location = useLocation();

  const navigation = useNavigate()

  const { setActiveStep, id, setId } = useContext(NavbarContext);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Close the dropdown when clicking outside of it
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [pathName, setPathName] = useState("")

  useEffect(() => {
    setPathName(location.pathname)
  }, [location])

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const openDrawer = (type) => {
    avoidScroll()
    setDrawerType(type)
    setIsDrawerOpen(true);
  };

  const closeDrawer = (type) => {
    avoidScroll()
    setDrawerType(type)
    setIsDrawerOpen(false);
  };

  // Define your menu items and their paths
  const menuItems = [
    { label: "Home", path: "/" },
    { label: "Matches", path: "/matches" },
    { label: "Mail Box", path: "/mailbox" },
    { label: "Chat", path: "/chat" },
  ];

  if (pathName === "/build_profile" || pathName === "/partner_preference") return

  const logout = () => {

  }

  return (
    <div className={style.contentContainer}>
      <div className={style.menuContainer}>
        {
          !getUserToken() ? (
            <>
              <div className={style.callSupportContainer}>
                <Image
                  src={supportImage}
                />
                {/* <div className={style.number}>
                  <p className={style.label}>Call us for support</p>
                  <p className={style.value}>+91 846839362</p>
                </div> */}
              </div>
              <Button
                onClick={() => openDrawer("login")}
                style={{ minWidth: 120 }}
                className={style.loginButton}
              >
                Login Now
              </Button>
              <Button
                onClick={() => openDrawer("register")}
                style={{ minWidth: 120 }}
                className={style.loginButtonOutlined}
              >
                Register
              </Button>
            </>
          ) :
            <div className={style.menu}>
              <ul className={style.menuList}>
                {menuItems.map((item, index) => (
                  <li
                    key={index}
                    className={pathName === item.path ? style.activeMenuItem : ""}
                  >
                    {item.label}
                  </li>
                ))}
                <li>
                  <Image src={notificationImage} />
                </li>
                <li className={style.profileContainer}>
                  <Image
                    src={profileImage}
                    className={style.profileImage}
                  />
                  <p className={style.name}>{getUserData()?.name}</p>
                  <div className={`${style.dropdown}`}>
                    <div className={`${style.dropdown} ${isOpen ? style.active : ""}`} ref={dropdownRef}>
                      <Image
                        src={dropdownImage}
                        className={`${style.dropdownImage} ${style.dropdownTrigger}`}
                        onClick={toggleDropdown}
                      />
                      {isOpen && (
                        <div className={style.dropdownContent}>
                          <ul>
                            <li onClick={() => {
                              localStorage.clear();
                              navigation("/")
                            }}>Logout</li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
        }
      </div>
    </div>
  )
}
