import React, { useContext } from 'react'
import style from './WorkDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function WorkDetails() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.profile_details?.user_profile_details?.work_location[0]
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Work Location</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            <div className={style.personalsDetail}>
              <p className={style.label}>Country</p>
              <p className={style.title}>{details?.country}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>State</p>
              <p className={style.title}>{details?.state}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>District/City</p>
              <p className={style.title}>{details?.city}</p>
            </div>
          </div>
        </div>
    </div>
  )
}
