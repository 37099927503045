import React, { useEffect, useMemo,useRef, useState } from "react";
import style from "../../../pages/Chat/Chat.module.css"
import Image from "../../../../../ui/Image/Image"
import Button from "../../../../../ui/Button/Button"
import drImage from "../../../pages/Chat/assets/Dr. Vidhya@2x.png"
import moreIcon from "../../../assets/common/Menu.svg"
import maleAvatar from "../../../assets/common/profile_active.png"
import femaleAvatar from "../../../assets/common/female.svg"
import messageSentImage from "../../../pages/Chat/assets/Message Sent.svg"
import SingleMessage from "./SingleMessage";
import { collection,doc,setDoc,addDoc,getDoc,updateDoc,getDocs,Timestamp,onSnapshot,query,orderBy,where, Firestore } from "firebase/firestore"; 
import { BlockReport } from "../BlockReport/BlockReport";
import $ from "jquery";
import useApiCall from "../../../hooks/useApiCall"
import config from '../../../../../app.json';
import { addScroll, avoidScroll, getUserData, getUserToken } from "../../../../../utils/utils";
import ModalBox from "../../../../../ui/Modal/ModalBox";
import Success from "../../../pages/CeleberateMatch/Modal/Success";
import { SubscribeWidget } from "../../SubscribeWidget/SubscribeWidget";
import updgradeToEliteIcon from "../../../assets/common/upgradeToElite.svg"
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ChatHistory1 = (props) => {

    const {db, app, chatDetails, setRefresh, refresh, updateCount} = props;

    const navigation = useNavigate("")
    const [data, setData] = useState(null);
    const [message, setMessage] = useState("");
    const [ count, setCount ] = useState(0)
    const [ viewSubscribe, setViewSubscribe ] = useState(false)
    const [ viewSuccessModal, setViewSuccessModal ] = useState(false)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    const { sendRequest: notify } = useApiCall();

    const { isLoading: sendBlockLoading, success: sendBlockSuccess, error: sendBlockError, sendRequest: unblock } = useApiCall();

    const [ loadBlock, setLoadBlock ] = useState(false)

    const { error: errorChatCountCheck, success: successChatCountCheck, sendRequest: chatCountCheck } = useApiCall();

    useEffect(()=> {
        chatCountCheck({
            url: `chat-count-check`,
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        },(response) => {
            if( response?.status === 402 ) {
                setViewSubscribe(true)
            } else {
                setViewSubscribe(false)
            }
        })
    }, [chatDetails])

    useEffect(()=> {
        if(chatDetails?.participant_token){
            getDetails({
                url: `profile/check-block`,
                method: 'POST',
                body: {
                    "profile_token": chatDetails?.participant_token
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (responseData) => {
                setData(responseData)
            }); 
        }
        
    }, [loadBlock]) 

    let profile_image = chatDetails?.participant_image !== undefined ? chatDetails?.participant_image : '';
    let chatMessagesUnsub;
    const [chatData, setChatData] = useState([])
    const msgToBeSent = useRef();
    let unsub;
    let tempData = [];
    let groupDateValue = "";
    let date = new Date();
    const docRef = doc(db, "Chats", `${chatDetails?.doc_id}`);
    const chatHistoryQuery =  query(collection(db, `Chats/${chatDetails?.doc_id}/message`), orderBy("msg_time", "asc"), where("msg_time", "<", date));
    const ques = query(collection(db, `Chats/${chatDetails?.doc_id}/message`), orderBy("msg_time", "asc"), where("msg_time", ">=", date));

    const getSingleMsgTime = (incTime) => {
        let todayDateTime = new Date();
        let msgDateTime = incTime.toDate();
        let msgTime = incTime.toDate().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        let msgDate = incTime.toDate().toDateString()
        let todayDateTimeDateVal = todayDateTime.getDate();
        let todayDateTimeMonthVal = todayDateTime.getMonth();
        let todayDateTimeYearVal = todayDateTime.getFullYear();
        let msgDateVal = msgDateTime.getDate()
        let msgMonthVal = msgDateTime.getMonth();
        let msgYearVal = msgDateTime.getFullYear();
        if(todayDateTimeMonthVal === msgMonthVal && todayDateTimeYearVal === msgYearVal){
            
            if(msgDateVal === todayDateTimeDateVal){
                let timeDifference = todayDateTime.getTime() - msgDateTime.getTime()
                if(Math.floor(timeDifference/(1000 * 60 * 60)) > 0){
                    return `${Math.floor(timeDifference/(1000 * 60 * 60))} ${Math.floor(timeDifference/(1000 * 60 * 60)) > 1 ? 'hrs ago' : 'hr ago'}`;

                }else if(Math.floor(timeDifference/(1000 * 60)) > 0){
                    if(Math.floor(timeDifference/(1000 * 60)) % 5){
                        return `${Math.floor(timeDifference/(1000 * 60))} mins ago`
                    }else{
                        return `${Math.floor(timeDifference/(1000 * 60))} ${Math.floor(timeDifference/(1000 * 60)) > 1 ? 'mins' : 'min'} ago`;
                    }

                }else{
                    return `now`;
                }


            }else if((todayDateTimeDateVal - 1) === msgDateVal){

                return `Yesterday at ${msgTime}`

            }else{
                return `${msgDate} ${msgTime}`;
            }

        }else{
            return `${msgDate} ${msgTime}`;
        }
    }

    const startOfWeek = (date) => {
        const firstDayOfWeek = new Date(date);
        firstDayOfWeek.setDate(date.getDate() - date.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
        return firstDayOfWeek;
      }
      
    const endOfWeek = (date) => {
        const lastDayOfWeek = new Date(date);
        lastDayOfWeek.setDate(date.getDate() - date.getDay() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
        return lastDayOfWeek;
      }
    const getGroupMsgTime = (incTime) => {
        let todayDateTime = new Date();
        let msgDateTime = incTime.toDate();
        let msgTime = incTime.toDate().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        let msgDate = incTime.toDate().toDateString()
        let todayDateTimeDateVal = todayDateTime.getDate();
        let todayDateTimeMonthVal = todayDateTime.getMonth();
        let todayDateTimeYearVal = todayDateTime.getFullYear();
        let msgDateVal = msgDateTime.getDate()
        let msgMonthVal = msgDateTime.getMonth();
        let msgYearVal = msgDateTime.getFullYear();
        const daysOfCurrentWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        if(todayDateTimeMonthVal === msgMonthVal && todayDateTimeYearVal === msgYearVal){
            if(msgDateVal === todayDateTimeDateVal){
                if(groupDateValue !== "Today"){
                    groupDateValue = "Today";
                    return "Today";
                }else{
                    return '';
                }

            }else if((todayDateTimeDateVal - 1) === msgDateVal){
                if(groupDateValue !== "Yesterday"){
                    groupDateValue = "Yesterday";
                    return "Yesterday";
                }else{
                    return '';
                }

            }else if(msgDateTime >= startOfWeek(todayDateTime) && msgDateTime <= endOfWeek(todayDateTime)){
                const dayOfWeekIndex = msgDateTime.getDay();
                if (groupDateValue !== daysOfCurrentWeek[dayOfWeekIndex]) {
                    groupDateValue = daysOfCurrentWeek[dayOfWeekIndex]
                    return daysOfCurrentWeek[dayOfWeekIndex];
                  }else{
                    return '';
                  }
            }else {
                if(groupDateValue !== msgDate){
                    groupDateValue = msgDate
                    return msgDate

                }else{
                    return '';
                }
            }
        }else{
            if(groupDateValue !== msgDate){
                groupDateValue = msgDate
                return msgDate

            }else{
                return '';
            }
        }
        
    }

    const { error: errorChatCountUpdate, success: successChatCountUpdate, sendRequest: chatCountUpdate } = useApiCall();

    const sendMsgHandler = () =>{
        let msg = msgToBeSent.current.value;
        let sender_id = chatDetails.user_token;
        let msg_time = new Date();
        if(msg.trim() !== ""){
            handleNotify(msg)
            addDoc(collection(db, `Chats/${chatDetails.doc_id}/message`), {
                msg: msg,
                msg_time: Timestamp.fromDate(msg_time),
                sender_id: sender_id,
            });
              
            const updateLastSeenQuery = doc(db, "Chats", chatDetails.doc_id);
            getDoc(updateLastSeenQuery).then((response)=> {
                const countValue = response.data()[chatDetails.user_token+"_msg_seen"]
                updateDoc(docRef, {
                    lastMessageText: msg,
                    lastMessageTimestamp: Timestamp.fromDate(msg_time),
                    last_message_sender: sender_id,
                    [`${chatDetails?.user_token}_msg_seen`]: countValue + 1,
                    [`${chatDetails?.participant_token}_msg_seen`]: 0,
                });
            })
            setMessage("")

            chatCountUpdate({
                url: `chat-count-update`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },(response) => {
                if( response?.status === 402 ) {
                    setViewSubscribe(true)
                } else {
                    setViewSubscribe(false)
                }
            })
              msgToBeSent.current.value = '';
        }
    }


    const chatHistoryData = async() => {
        const querySnapshot = await getDocs(chatHistoryQuery);
        if(querySnapshot.size > 0){
            querySnapshot.forEach((doc) => {
                let docobj = doc.data();
                if(tempData.findIndex(x => x === docobj) < 0){
                    tempData.push(docobj);
                    setChatData((curr)=>([...curr,docobj]))
                }
                if(unsub === undefined){

                    unsub = onSnapshot(ques, (snap) => {
                        snap.docChanges().forEach(function (change) {
                            if (change.type === "added") {
                                if(tempData.findIndex(x => x === change.doc.data()) < 0){
                                        tempData.push(change.doc.data());

                                    setChatData((curr)=>([...curr,change.doc.data()]));

                                }
                                
                            }
                            });
                        // console.log("Current data: ", doc.data());
                    });
                }

            });
        } else {
            if(unsub === undefined){

                unsub = onSnapshot(ques, (snap) => {
                   console.log(chatDetails.doc_id, " => ", "canvas listener")
                    snap.docChanges().forEach(function (change) {
                        if (change.type === "added") {
                            if(tempData.findIndex(x => x === change.doc.data()) < 0){
                                    tempData.push(change.doc.data());

                                setChatData((curr)=>([...curr,change.doc.data()]));

                            }
                            
                        }
                        });
                    // console.log("Current data: ", doc.data());
                });
            }
        }
    }


    useEffect(() => {
      if(!chatDetails?.chatCountEnable){
        setChatData([]);
        tempData = [];
        if(unsub !== undefined){
            unsub();
        }
        unsub = undefined;
        chatHistoryData();
      }else{
        if(unsub !== undefined){
            unsub();
        }
      }
    
      return () => {
            if(unsub !== undefined){
                unsub();
            }
        }
    }, [chatDetails?.doc_id])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendMsgHandler();
        }
    };

    useEffect(()=> {
        scrollToChatBottom()
    }, [chatData])

    const scrollToChatBottom = () => {
        setTimeout(()=> {
            $('.chatMessagesContainer').scrollTop($('.chatMessagesContainer')[0]?.scrollHeight);
        }, 500)
    }

    const handleNotify = (message) => {
        notify({
            url: `notification/notify-message`,
            method: 'POST',
            body: {
              "profile_token": chatDetails?.participant_token,
              "message": message
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        });
    }

    useEffect(()=> {
      if( sendBlockSuccess ) {
        avoidScroll()
        setViewSuccessModal(true)
      }
    }, [sendBlockSuccess])
    
    const handleUnblock = () => {
        unblock({
            url: `my-account/blocked-profiles/unblock`,
            method: 'POST',
            body: {
              "profile_token": chatDetails?.participant_token
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        });
    }

    const handleRefresh = () => {
        setRefresh(!refresh)
    }

    return chatData.length >= 0 ? (
        <div className={style.notificationContentContainer}>
            <ToastContainer />
            <ModalBox
                open={viewSuccessModal} 
                width={"598px"} 
                modalContent={
                    <Success
                    title={"Profile Restored Successfully"} 
                    // description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
                    buttonText={"Okay"}
                    onClick={()=>{
                        addScroll()
                        setLoadBlock(!loadBlock)
                        setViewSuccessModal(false)
                    }}
                    />
                }
            />
                    <div className={style.notificationContentTitleHeaderContainer}>
                        <Image 
                            src={chatDetails?.isDeleted ? maleAvatar : process.env.REACT_APP_S3_CLOUD_FRONT_URL+ profile_image}
                            width={70}
                            height={70}
                            borderRadius={"50%"}
                        />
                        <div className={style.contentNameStatusContainer}>
                            { !chatDetails?.isDeleted && <p className={style.id}>{chatDetails.participant_profile_id}</p> }
                            <div className={style.nameMoreContainer}>
                                <p className={style.name}>{ chatDetails?.isDeleted ? "Unknown User" : chatDetails.participant_name }</p>
                                {
                                    ( !data?.data?.is_blocked && !chatDetails?.isDeleted ) && (
                                        <BlockReport 
                                            loadBlock={loadBlock}
                                            setLoadBlock={setLoadBlock}
                                            id={chatDetails?.participant_profile_id} 
                                            token={chatDetails?.participant_token}
                                        />
                                    )
                                }
                            </div>
                            {/* <p className={style.status}>{chatDetails.online_status ? 'Online' : `Offline . Last seen`}</p> */}
                            { !chatDetails?.isDeleted && (<p className={style.status}>{chatDetails.isOnline ? 'Online' : `Offline`}</p> ) }
                        </div>
                    </div>
                    <div className={`${style.chatMessagesListContainer} chatMessagesContainer`}>
                        { ( ( getUserData()?.membership_type === "Elite" || getUserData()?.membership_type === "Premium" ) && chatData && !viewSubscribe ) && (
                            chatData.map((item,index) => {
                            let msgDisplayTime = getSingleMsgTime(item.msg_time);
                            let groupMsgDateVal = getGroupMsgTime(item.msg_time);
                            {/* console.log(msgDisplayTime) */}
                                
                                if(item.sender_id === chatDetails?.participant_token){
                                    return(
                                        <SingleMessage key={index}
                                            msgSource=""
                                            dateGroup={groupMsgDateVal}
                                            time={msgDisplayTime}
                                            message={item.msg}

                                            
                                        />
                                    )
                                }else{
                                    return(
                                        <SingleMessage key={index}
                                            msgSource="sent"
                                            dateGroup={groupMsgDateVal}
                                            time={msgDisplayTime}
                                            message={item.msg}
                                        />
                                    )
                                }

                            
                            })
                        )}
                    </div>
                    { chatDetails?.isDeleted && (
                        <p className={style.deletedContent}>The user might have deleted their account from the platform.</p>
                    ) 
                    }
                    {
                       ( !viewSubscribe && !data?.data?.is_blocked && getUserData()?.membership_type !== "Basic" ) ? (
                            <div className={style.inputContainer}>
                                <input 
                                    className={style.messageField} 
                                    type="text" 
                                    placeholder="Type a message…" 
                                    ref={msgToBeSent}
                                    onKeyDown={handleKeyDown}
                                    // value={message}
                                    onChange={(e) => setMessage(e.target.value?.trim())}
                                />
                                {
                                    message !== "" && (
                                        <Image 
                                            onClick={sendMsgHandler}
                                            src={messageSentImage}
                                            className={style.messageSendIcon}
                                        />
                                    )
                                }
                            </div>
                        ) : (
                            ( (getUserData()?.membership_type === "Basic" ) || ( getUserData()?.membership_type === "Premium" && viewSubscribe ) ) && (
                                <div className={style.updgradeToEliteContainer}>
                                    <div className={style.updgradeToEliteContentsContainer}>
                                        <Image src={updgradeToEliteIcon}/>
                                        <p className={style.titleText}>Upgrade to { getUserData()?.membership_type === "Basic" ? "Premium/" : "" }Elite to unlock and enjoy exclusive chat previleges</p>
                                        <Button onClick={()=> {
                                            localStorage.setItem('subscriptionFromHome', 3);
                                            navigation("/subscribe")
                                        }} className={`primaryButton ${style.subscribeButton}`}>Subscribe Now</Button>
                                    </div>
                                </div>
                            )
                        )
                    }
                    {
                        data?.data?.is_blocked && (
                            <div className={style.blockedStatusContainer}>
                                <p className={style.statusMessage}>{ data?.data?.block_text }</p>
                                {
                                    data?.data?.can_unblock && (
                                        <p onClick={handleUnblock} className={style.unblockButton}>Show Profile</p>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
    ) : (
        <div className={style.notificationContentContainer} style={{boxShadow:"unset"}}></div>
    )
}

export default ChatHistory1;