import React, { useState } from 'react'
import style from "./Footer.module.css"
import Image from '../../../../ui/Image/Image'
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import { Link, useNavigate } from 'react-router-dom'
import ModalBox from '../../../../ui/Modal/ModalBox'
import { addScroll, avoidScroll } from '../../../../utils/utils'
import { Feedback } from './Feedback/Feedback'
import { ContactUs } from './ContactUs/ContactUs'
import Success from './Modal/Success'

export default function Footer() {
    const navigation = useNavigate()
    const [ viewDetailsModal, setViewDetailsModal ] = useState(false)
    const [ viewSuccessModal, setViewSuccessModal ] = useState(false)
    const [ successMessage, setSuccessMessage] = useState("")
    const [ modaltype, setModaltype ] = useState(null)

    // Get the current date
    var currentDate = new Date();

    // Get the current year
    var currentYear = currentDate.getFullYear();

  return (
    <div className={style.footer}>
        <ModalBox
          open={viewSuccessModal} 
          width={"468px"} 
          modalContent={
            <Success
              title={"Submitted Successfully"} 
              description={ modaltype === "feedback" ? "Your input is valuable, thank you for your insightful feedback." : "Thank you for contacting us, Our team will reach back to you shortly."} 
              buttonText={"Okay"}
              onClick={()=>{
                addScroll()
                setViewSuccessModal(false)
              }}
            />
          } 
        />
        <ModalBox
            open={viewDetailsModal} 
            width={"542px"} 
            modalContent={
                modaltype === "feedback" ?
                    <Feedback
                        setSuccessMessage={setSuccessMessage}
                        setViewSuccessModal = { setViewSuccessModal }
                        setViewDetailsModal = {setViewDetailsModal} 
                    />
                :
                    <ContactUs
                        setSuccessMessage={setSuccessMessage}
                        setViewSuccessModal = { setViewSuccessModal }
                        setViewDetailsModal = {setViewDetailsModal} 
                    />
                    
            } 
        />
        <div className={`${style.footerContainer} container`}>
            <div className={style.logoAndMenuContainer}>
                <div className={style.logoContainer}>
                    <Image src={logoImage}/>
                    <p className={style.logoDescription}>Choose Doctors Matrimony today and take the first step towards a fulfilling and harmonious life with your ideal partner. Let us help you find the love and companionship you deserve while balancing the demands of your medical career.</p>
                </div>
                <div className={style.menuContainer}>
                    <div className={style.aboutContainer}>
                        <p className={style.title}>About</p>
                        <p className={style.menu} onClick={()=> navigation("/about_us")}>About Us</p>
                        {/* <p className={style.menu}>24/7 Live help</p> */}
                        <p className={style.menu} onClick={()=> {
                            setModaltype("contact_us")
                            avoidScroll()
                            setViewDetailsModal(true)
                        }}>Contact Us</p>
                        <p className={style.menu} onClick={()=> {
                            setModaltype("feedback")
                            avoidScroll()
                            setViewDetailsModal(true)
                        }}>Feedback</p>
                    </div>
                    {/* <div className={style.otherContainer}>
                        <p className={style.title}>Our Other Services</p>
                        <Link to="https://www.speedlearningapp.com/" target="_blank"><p className={style.menu}>Speed Learning</p></Link>
                        <p className={style.menu}>Med Connect</p>
                    </div> */}
                    <div className={style.legalContainer}>
                        <p className={style.title} >Legal</p>
                        <p className={style.menu} onClick={()=> navigation("/terms_and_conditions")}>Terms and Conditions</p>
                        <p className={style.menu} onClick={()=> navigation("/privacy_policy")}>Privacy Policy</p>
                    </div>
                </div>
            </div>
            <div className={style.copyRightContainer}>
                <p>This website is strictly for matrimonial purpose only and not a dating website.</p>
                <p>Copyright © {currentYear}. All rights reserved.</p>
            </div>
        </div>
    </div>
  )
}
