import React, { useState, useRef, useEffect } from 'react';
import classes from './Video.module.css'

const VideoPlayer = ({ src, clear, thumbnail, playBtn }) => {
    const [showThumbnail, setShowThumbnail] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {
        if (!showThumbnail && videoRef.current) {
            // Play the video when showThumbnail is false and videoRef is available
            videoRef.current.play()
                .catch(error => {
                    // Handle any potential play() errors
                    console.error('Video playback error:', error);
                });
        }
    }, [showThumbnail]);

    useEffect(() => {
        if (clear && videoRef.current) {
            setShowThumbnail(true); // Show thumbnail image
            videoRef.current.currentTime = 0; // Reset video to beginning
        }
    }, [clear]);

    const handlePayButtonClick = () => {
        setShowThumbnail(false);
    };

    const handleVideoEnded = () => {
        setShowThumbnail(true);
        if (videoRef.current) {
            // Reset the video to the beginning when it ends
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <div>
            {showThumbnail ? (
                // Display the thumbnail image
                <div className={classes['video-container']} style={{
                    backgroundImage: `url(${thumbnail})`, backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain'
                }}>
                    <video
                        controls={0}
                        disablePictureInPicture
                        className={classes.Thumbnail_img}
                    >
                        <source src={src} type="video/mp4" />
                    </video>
                    <img
                        className={classes.PlayBtn}
                        src={playBtn}
                        alt="PlayBtn"
                        onClick={handlePayButtonClick}
                    />
                </div>
            ) : (
                // Display the video player
                <div className={classes['video-container']}>
                    <video
                        ref={videoRef}
                        controls
                        onEnded={handleVideoEnded}
                        style={{ display: 'block', maxWidth: '100%' }}
                    >
                        <source src={src} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
