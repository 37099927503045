import React, { useContext, useEffect, useState } from 'react';
import style from './Tabs.module.css';
import Image from '../../../../../ui/Image/Image';
import Button from '../../../../../ui/Button/Button';
import PersonalDetails from '../PersonalDetails/PersonalDetails';
import NativeDetails from '../NativeDetails/NativeDetails';
import ProfessionalDetails from '../ProfessionalDetails/ProfessionalDetails';
import WorkDetails from '../WorkDetails/WorkDetails';
import FamilyDetails from '../FamilyDetails/FamilyDetails';
import HabbitDetails from '../HabbitDetails/HabbitDetails';
import MedicalHistory from '../MedicalHistory/MedicalHistory';
import BasicPreference from '../BasicPreference/BasicPreference';
import ProfessionalPreference from '../ProfessionalPreference/ProfessionalPreference';
import HabitsPreference from '../HabitsPreference/HabitsPreference';
import ProfileMatchesDetails from '../ProfileMatchesDetails/ProfileMatchesDetails';
import Carousel from 'react-grid-carousel';
import carousalRightIcon from "../../../assets/svg/Home/carousalRightIcon.svg"
import carousalleftIcon from "../../../assets/svg/Home/carousalleftIcon.svg"
import ReligiousDetails from '../ReligiousDetails/ReligiousDetails';
import LocationPreference from '../LocationPreference/LocationPreference';
import ReligiousPreference from '../ReligiousPreference/ReligiousPreference';
import { getUserData } from '../../../../../utils/utils';
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext';
import updgradeToEliteIcon from "../../../assets/common/upgradeToElite.svg"
import { useNavigate } from 'react-router-dom'
import QualificationDetails from '../QualificationDetails/QualificationDetails';

export default function Tabs({details}) {

  const navigation = useNavigate("")

  const { data } = useContext(ProfileDetailsContext)
  const matchesArray = data?.matches_array

  const [ tabEliteData, setTabEliteData ] = useState(null)

  const [ tabData, setTabData ] = useState(null)

  const [activeTab, setActiveTab] = useState("Personal Details");

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    if (sectionElement) {
      sectionElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleTabClick = (tabTitle) => {
    setActiveTab(tabTitle);
    scrollToSection(tabTitle);
  };

  const tabBasicData = [
    {
      id: "Personal Details",
      component: <PersonalDetails />,
    },
  ]

  const tabPremiumData = [
    {
      id: "Personal Details",
      component: <PersonalDetails />,
    },
    {
      id: "Native Details",
      component: <NativeDetails />,
    },
    {
      id: "Qualification Details",
      component: <QualificationDetails />,
    },
    {
      id: "Professional Details",
      component: <ProfessionalDetails />,
    },
    {
      id: "Work Location",
      component: <WorkDetails />,
    },
    {
      id: "Religious Details",
      component: <ReligiousDetails />,
    },
    {
      id: "Family Details",
      component: <FamilyDetails />,
    },
    {
      id: "Habits Details",
      component: <HabbitDetails />,
    },
    {
      id: "Profile Matches",
      component: <ProfileMatchesDetails />,
    },
    {
      id: "Basic Preferences",
      component: <BasicPreference />,
    },
    {
      id: "Habits preferences",
      component: <HabitsPreference />,
    },
    {
      id: "Professional Preferences",
      component: <ProfessionalPreference />,
    } 
    // You can add more tab objects as needed
  ];

  useEffect(()=> {
    let tabElite = [
      {
        id: "Personal Details",
        component: <PersonalDetails />,
      },
      {
        id: "Native Details",
        component: <NativeDetails />,
      },
      {
        id: "Qualification Details",
        component: <QualificationDetails />,
      },
      {
        id: "Professional Details",
        component: <ProfessionalDetails />,
      },
      {
        id: "Work Location",
        component: <WorkDetails />,
      },
      {
        id: "Religious Details",
        component: <ReligiousDetails />,
      },
      {
        id: "Family Details",
        component: <FamilyDetails />,
      },
      {
        id: "Habits Details",
        component: <HabbitDetails />,
      },
      {
        id: "Medical History",
        component: <MedicalHistory />,
      },
      {
        id: "Profile Matches",
        component: <ProfileMatchesDetails />,
      }
    ];
  
    matchesArray?.map((item)=> {
      if( item?.title === "Basic Preferences" ) {
        tabElite.push({
          id: "Basic Preferences",
          component: (
            <>
              <BasicPreference />
            </>
          ),
        })
      } else if( item?.title === "Professional Preferences" ) {
        tabElite.push({
          id: "Professional Preferences",
          component: (
            <>
              <ProfessionalPreference />
            </>
          ),
        })
      } else if( item?.title === "Work Location Preferences" ) {
        tabElite.push({
          id: "Work Location Preferences",
          component: (
            <>
              <LocationPreference />
            </>
          ),
        })
      } else if( item?.title === "Religious Preferences" ) {
        tabElite.push({
          id: "Religious Preferences",
          component: (
            <>
              <ReligiousPreference />
            </>
          ),
        })
      } else if( item?.title === "Habit Preferences" ) {
        tabElite.push({
          id: "Habit Preferences",
          component: (
            <>
              <HabitsPreference />
            </>
          ), 
        })
      }
    })
    setTabEliteData(tabElite)
  }, [data])

  useEffect(()=> {
    setTabData( getUserData().membership_type === "Elite" ? tabEliteData : getUserData().membership_type === "Premium" ? tabPremiumData : tabBasicData )
  }, [tabEliteData])

  return (
    <div className={style.tabsContainer}>
      <div className={style.tabs}>
        <Carousel
            cols={6}
            rows={1}
            gap={16}
            loop
            showDots={false}
            arrowLeft={<Image src={carousalleftIcon} className={style.arrowLeft} />}
            arrowRight={<Image src={carousalRightIcon} className={style.arrowRight} />}
            hideArrow={ getUserData()?.membership_type === "Basic" ? true : false }
        >
            {tabData?.map((item, index) => (
                <Carousel.Item key={index}> {/* Make sure to provide a unique key */}
                {/* Your existing tab content */}
                <div className={`${style.tab} ${activeTab === item.id ? style.active : ''}`} onClick={() => getUserData()?.membership_type !== "Basic" && handleTabClick(item.id)}>
                    <p className={style.tabTitle}>{item.id}</p>
                </div>
                {/* Your existing tab content */}
                </Carousel.Item>
            ))}
        </Carousel>
      </div>
        <div className={style.tabsContentContainer}>
            {tabData?.map((tabItem) => (
                <div id={tabItem.id} key={tabItem.id}>
                {tabItem.component}
                </div>
            ))}
            {
              getUserData().membership_type !== "Elite" && (
                <div className={style.updgradeToEliteContainer}>
                    <div className={style.updgradeToEliteContentsContainer}>
                        <Image src={updgradeToEliteIcon}/>
                        <p className={style.titleText}>Upgrade your membership to view all the partner preferences</p>
                        <Button onClick={()=> {
                          localStorage.setItem('subscriptionFromHome', 1);
                          navigation("/subscribe")
                        }} className={`primaryButton ${style.subscribeButton}`}>Upgrade to Elite</Button>
                    </div>
                </div>
              )
            }
        </div>
      {/* <div className={style.navigationContainer}>
        <p className={style.navigation}>
          <Image src={previousArrow} />
          Previous
        </p>
        <p className={style.navigation}>
          Next
          <Image src={nextArrow} />
        </p>
      </div> */}
    </div>
  );
}
