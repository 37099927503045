import React from 'react'
import style from "./FindSoulMate.module.css"
import Image from '../../../../../ui/Image/Image'

import registerImage from "../../../assets/svg/LandingPage/register.svg"
import searchImage from "../../../assets/svg/LandingPage/Search.svg"
import intrestImage from "../../../assets/svg/LandingPage/intrest.svg"
import interactImage from "../../../assets/svg/LandingPage/Interact.svg"

export default function FindSoulMate() {
  const steps = [
    {
      imageSrc: registerImage,
      stepText: "STEP 1",
      title: "Register Profile",
      description: "Register for free and upload your details",
    },
    {
      imageSrc: searchImage,
      stepText: "STEP 2",
      title: "Search",
      description: "Search for your right partner in world wide",
    },
    {
      imageSrc: intrestImage,
      stepText: "STEP 3",
      title: "Send Interest",
      description: "Select & send interest with matches you like",
    },
    {
      imageSrc: interactImage,
      stepText: "STEP 4",
      title: "Interact",
      description: "Become a premium member and start conversation",
    },
  ];

  const soulmateSteps = steps.map((step, index) => (
    <div className={`${style.content} m-t-40`}>
      <Image src={step.imageSrc} width={"120px"} height={"120px"} />
      <p className={`${style.step} m-t-16 m-b-12`}>{step.stepText}</p>
      <p className={`${style.title} m-b-4`}>{step.title}</p>
      <p className={style.description}>{step.description}</p>
    </div>
  ));

  return (
    <div className={`${style.findSoulmateContainer} container`}>
      <div className={style.headerContainer}>
        <h1>Find Your Soul Mate</h1>
        <p>Unveiling a World of Love and Connection Where Hearts Align, Bonds Strengthen,</p>
        <p>and Souls Discover Everlasting Bliss</p>
      </div>
      <div className={style.stepsContainer}>
        {soulmateSteps}
      </div>
    </div>
  )
}
