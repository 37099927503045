import React from "react";
import style from "./Status.module.css";
import Image from "../../../../../ui/Image/Image";
import { getUserData } from "../../../../../utils/utils";
import { useNavigate } from "react-router-dom";

const Status = ({title, image, description}) => {
  const navigation = useNavigate()

  const handleNavigation = () => {
    navigation("/")
  }

    return (
      <div className={style.VerificationContainer}>
        <div className={`${style.bannerSectionContainer} container`}>
          <div className={style.textContainer}>
            <h2>{title}</h2>
            <Image
              src={image}
              alt={"Verification Status"}
              className={style.StatusImage}
            />
            <p className={style.description}>
             Your profile is under Verification and will be completed within 7 to 10 working days. Your Matrimony ID is { getUserData() !== null ? getUserData().user_id : ""}. You can login with your Matrimony ID and Mobile Number to view your application status. We appreciate your patience and trust in our service, ensuring 100% verified doctor profiles for authenticity.
            </p>
            {title === "Verification in Process!" ? (
              <p className={style.descriptionContainer}>
                Enjoy Elite membership at no cost. Access all features and
                explore the benefits available to you.
              </p>
            ) : (
              <></>
            )}
          </div>
          <p onClick={handleNavigation} className={style.exploreMore}>Explore more</p>
        </div>
      </div>
    );
};

export default Status;
