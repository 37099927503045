import React, { useEffect, useRef, useState } from 'react'
import style from './PartnerPreferences.module.css'
import Image from '../../../../../ui/Image/Image';
import edit from '../../../../web/assets/MyAccount/Edit Profile.svg'
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import { addScroll, avoidScroll, getUserData, getUserToken, uploadFile } from '../../../../../utils/utils';
import ModalBox from '../../../../../ui/Modal/ModalBox';
import BasicPreference from './Premium/BasicPreference/BasicPreference';
import Habitspreferences from './Premium/Habitspreferences/Habitspreferences';
import ProfessionalPreferences from './Ellite/ProfessionalPreferences/ProfessionalPreferences';
import WorkLocationPreferences from './Ellite/WorkLocationPreferences/WorkLocationPreferences';
import Religiouspreferences from './Ellite/ReligiousPreferences/Religiouspreferences';
import Success from './Premium/Modal/Success';
import deleteIcon from '../../../assets/common/Bin.svg';
import partnerPreferenceForBasicIcon from '../../../assets/common/partnerPreferenceForBasic.svg';
import notesIcon from "../../../assets/common/notes.svg"
import Button from '../../../../../ui/Button/Button'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const PartnerPreferences = ({ refreshSideBar, setRefreshSideBar }) => {
    const navigation = useNavigate()
    const { isLoading: isLoading, success: success, error: error, sendRequest: getMetaData } = useApiCall();

    const [ preferenceData, setPreferenceData ] = useState(null);
    const [ viewDetailsModal, setViewDetailsModal ] = useState(false)
    const [ viewSuccessModal, setViewSuccessModal ] = useState(false)

    const [uploadedVideoDisplay, setUploadedVideoDisplay] = useState(null);
    const [ refresh, setRefresh ] = useState(false)
    const [ modalType, setModalType ] = useState(null)
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [videoFile, setVideoFile] = useState("test.mp4");
    const [uploadPersonalVideo, setUploadPersonalVideo] = useState("");
    const [videoIsLoading, setVideoIsLoading] = useState(false);
    const [ editMyVideoStatus, setEditMyVideoStatus ] = useState("")

    const uploadVideoInput = useRef(null);

    const getMyprofileApi = () => {
        window.scrollTo(0, 0);
        getMetaData(
            {
            url: 'my-account/preference/read',
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
            },
            (data) => {
                setEditMyVideoStatus(data.data.video_status)
                setVideoFile(data.data.video)
                setUploadedVideo(data.data.video)
                setUploadPersonalVideo(data.data.video)
                setPreferenceData(data.data);
                setUploadedVideoDisplay(data.data?.video)
                setRefreshSideBar(!refreshSideBar)
            }
        );
    };

    useEffect(() => {
        getMyprofileApi()
    }, [refresh]);


    const DetailsEdit = (data) => {
        if( data === "Videos" ) {
            setUploadPersonalVideo(videoFile)
        }
        avoidScroll()
        setModalType(data)
        setViewDetailsModal(true)
    }

    const handleShowSuccessModal = () => {
        setViewDetailsModal(false)
        setViewSuccessModal(true)
    }
    
    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
          // Assuming you have a supported video file format like mp4
          if (file.type.startsWith('video/')) {
            // Create a video element to get the duration
            const videoElement = document.createElement('video');
      
            // Listen for the 'loadedmetadata' event to ensure the duration is available
            videoElement.addEventListener('loadedmetadata', () => {
              // Check if the duration is less than 3 minutes (180 seconds)
              if (videoElement.duration <= 180) {
                setUploadedVideo(URL.createObjectURL(file)); // Store the data URL for preview
                setUploadPersonalVideo(URL.createObjectURL(file))
                setVideoFile(file); // Store the File object
                setEditMyVideoStatus("Pending")
                generateVideoThumbnail(file);
              } else {
                toast.error(`Video duration exceeds 3 minutes. Please select a shorter video.`, {
                  autoClose: 2500,
                })
              }
            });
      
            // Set the video element's source to the file URL
            videoElement.src = URL.createObjectURL(file);
          } else {
            // Handle invalid file type (not a video)
            console.error('Invalid file type. Please select a video file.');
          }
        }
      };
    
      const generateVideoThumbnail = (videoFile) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
          // Capture a frame from the video as a thumbnail
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    
          // Convert the canvas image to a data URL
          const thumbnailURL = canvas.toDataURL('image/jpeg'); // You can change the format if needed
    
          // setVideoThumbnail(thumbnailURL); // Store the thumbnail URL in state
        };
    
        video.src = URL.createObjectURL(videoFile);
      };

      const { isLoading: isVideoLoading, success: videoSuccess, error: videoError, sendRequest: saveVideo } = useApiCall();
  
      useEffect(()=> {
          if(videoError) {
              toast.error(videoError, {
                  autoClose: 2500,
              })
          }
      }, [videoError])
  
      const handleVideoSubmit = async() => {
        console.log("uploadPersonalVideo", uploadPersonalVideo);
        if( uploadPersonalVideo && uploadPersonalVideo?.length > 0 ) {
          setVideoIsLoading(true)
          // Upload the video file to S3
          let videoUrl = videoFile;
          if ( videoFile  && typeof videoFile === "object") {
              videoUrl = await uploadFile(videoUrl);
          }
          saveVideo({
              url: "my-account/preference/update/video",
              method: 'POST',
              body: {
                  "video": videoUrl,
              },
              headers: {
                  Authorization: `Bearer ${getUserToken()}`
              }
          }, (data) => {
                setUploadedVideoDisplay(uploadedVideo)
              setVideoIsLoading(false)
              setViewDetailsModal(false)
              setViewSuccessModal(true)
          });
        } else {
            toast.error("Please select video", {
                autoClose: 2500,
            })
        }
      }


      const Cancel = () => {
        setVideoIsLoading(false)
          setViewDetailsModal(false)
      }

      const familyDetailsError = (error) => {
        console.log("error", error);
        toast.error(error, {
            autoClose: 2500,
        })
      }

    console.log("uploadedVideoDisplay", uploadedVideoDisplay)

    return (
        <>
            {
                getUserData()?.membership_type === "Basic" ?
                    <div className={style.basicPartnerPreferenceContainer}>
                        <Image src={partnerPreferenceForBasicIcon} />
                        <p className={style.title}>Partner Preferences</p>
                        <p className={style.titleDescription}>Upgrade now to access partner preferences and unlock a world of tailored matches, exclusive events, and priority placement. Elevate your matchmaking journey today!</p>
                        <div className={style.upgradeButtonContainer}>
                            <Button
                                style={{ width: "80%",  }}
                                className={`primaryButton`}
                                onClick={()=> {
                                    navigation("/subscribe")
                                }}
                                // loading={signUpLoading}
                            >
                                Upgrade Now
                            </Button>
                        </div>
                    </div>
                :
                    <>
                        <ToastContainer />
                        <ModalBox
                            open={viewSuccessModal}
                            width={"598px"} 
                            modalContent={
                                <Success
                                    title={"Updated Successfully"} 
                                    buttonText={"Okay"}
                                    onClick={()=>{
                                        addScroll()
                                        setRefresh(!refresh)
                                        setViewSuccessModal(false)
                                    }}
                                />
                            }
                            />
                        <ModalBox
                            open={viewDetailsModal}
                            width={  modalType === "Videos" ? "500px" : "1000px" }
                            modalContent={ 
                                modalType === "Videos" ?
                                <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Manage Video</p>
                                    <div>
                                        <div>
                                            { uploadPersonalVideo?.length > 0 ? (
                                                <div
                                                    className={`${style.uploadedVideo} ${style.videoThumbnailContainer}`}
                                                    // style={{
                                                    // backgroundImage: `url(${videoThumbnail})`,
                                                    // }}
                                                >
                                                    <video width={200} controls={0}>
                                                        <source src={`${ uploadPersonalVideo?.includes("blob:") ? uploadPersonalVideo : process.env.REACT_APP_S3_CLOUD_FRONT_URL+uploadPersonalVideo }`+"#t=0.1"}/>
                                                    </video>
                                                   
                                                    {
                                                        editMyVideoStatus !== "Approved" && (
                                                            <div className={style.deleteContainer}>
                                                                <Image src={deleteIcon} onClick={() => {
                                                                    setUploadPersonalVideo("")
                                                                    setUploadedVideo(null)
                                                                }} />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        editMyVideoStatus !== "Approved" && (
                                                            <div
                                                                className={style.reuploadContainer}
                                                                onClick={() => uploadVideoInput.current.click()}
                                                            >
                                                                <p className={style.reuploadLabeTextVideo}> { editMyVideoStatus === "Pending" ? "Waiting for verification" : editMyVideoStatus } </p>
                                                            </div>
                                                    )}
                                                </div>
                                            ) : (
                                            <>
                                                <div className={style.videoUploadContainer}>
                                                    {/* <p className={style.text}>{ getUserData()?.membership_type === "Elite" ? "Capture your true self with a genuine smile and share your story through our self-video upload feature." : "Take your profile to the next level with an elite membership and showcase your uniqueness by uploading a captivating video." }</p> */}
                                                    <p className={style.text}>Capture your true self with a genuine smile and share your story through our self-video upload feature.</p>
                                                    <Button
                                                        className={`primaryButton ${style.videoButton}`}
                                                        onClick={() => {
                                                        // if( getUserData()?.membership_type === "Elite" ) {
                                                            if (uploadVideoInput.current) {
                                                            uploadVideoInput.current.click();
                                                            }
                                                        // } else {
                                                        //     navigation("/subscribe")
                                                        // }
                                                        }}
                                                    >
                                                        {/* { getUserData()?.membership_type === "Elite" ? "Upload Video" : "Subscribe"  }  */}
                                                        Upload Video
                                                    </Button>
                                                </div>
                                                {/* {
                                                getUserData()?.membership_type === "Elite" ? */}
                                                    <div className={style.eliteVideoInstructionContainer}>
                                                    <Image src={notesIcon} className={style.notesIcon}/>
                                                    <p className={style.instructionTitle}>Craft a compelling, desire-filled video under 3 minutes of maximum length</p>
                                                    </div>
                                                {/* :
                                                    <></>
                                                } */}
                                            </>
                                            )}
                                        </div>
                                        <input
                                        type="file"
                                        accept="video/*"
                                        onChange={handleVideoUpload}
                                        className={style.uploadInput}
                                        ref={(el) => (uploadVideoInput.current = el)} // Assign the ref to the input element
                                        />
                                    </div>
                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={() => {
                                        setUploadPersonalVideo("")
                                        Cancel()
                                    }}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={handleVideoSubmit} loading={videoIsLoading}> Save Changes</Button>
                                </div>
                            </>
                                : 
                                modalType === "BasicDetails" ?
                                    <BasicPreference
                                        familyDetailsError={familyDetailsError}
                                        setViewDetailsModal={setViewDetailsModal} 
                                        handleShowSuccessModal={handleShowSuccessModal}
                                    />
                                : 
                                    modalType === "Habitspreferences" ?
                                        <Habitspreferences
                                            familyDetailsError={familyDetailsError}
                                            setViewDetailsModal={setViewDetailsModal} 
                                            handleShowSuccessModal={handleShowSuccessModal}
                                        />
                                    :
                                        modalType === "Professionalpreferences" ?
                                            <ProfessionalPreferences 
                                                familyDetailsError={familyDetailsError}
                                                setViewDetailsModal={setViewDetailsModal} 
                                                handleShowSuccessModal={handleShowSuccessModal}
                                            />
                                        :
                                            modalType === "Worklocationpreferences" ?
                                                <WorkLocationPreferences 
                                                    familyDetailsError={familyDetailsError}
                                                    setViewDetailsModal={setViewDetailsModal} 
                                                    handleShowSuccessModal={handleShowSuccessModal}
                                                />
                                            :
                                                modalType === "Religiouspreferences" ?
                                                    <Religiouspreferences 
                                                        familyDetailsError={familyDetailsError}
                                                        setViewDetailsModal={setViewDetailsModal} 
                                                        handleShowSuccessModal={handleShowSuccessModal}
                                                    />
                                                :
                                                    <></>
                            }
                        />
                        <div className={style.pageContentContainer} >
                            <div className={style.edit_cont}>
                                <p className={style.title}>Partner Preferences</p>
                                <p>Matches Recommended by us are based on Acceptable match criteria and at times might go slightly beyond your preferences.</p>
                            </div>
                            
                            {
                                getUserData()?.membership_type === "Elite" && (
                                    <ContentBox title="Manage Videos" onClick={() => DetailsEdit("Videos")}>
                                    <div className={style.uploadDataContainer}>
                                        <div className={style.form}>
                                            {
                                                ( uploadedVideoDisplay === null || uploadedVideoDisplay?.length === 0 ) ?
                                                    <div className={`${style.uploadImageIconContainer} ${style.uploadImageModalTitleContainer}`}>
                                                        <div className={style.videoUploadContainer}>
                                                            <p className={style.text}>Capture your true self with a genuine smile and share your story through our self-video upload feature.</p>
                                                            {/* <Button
                                                                className={`primaryButton ${style.videoButton}`}
                                                                onClick={() => {
                                                                    if (uploadVideoInput.current) {
                                                                    uploadVideoInput.current.click();
                                                                    }
                                                                }}
                                                            >
                                                            Upload Video
                                                            </Button> */}
                                                        </div>
                                                        {/* {
                                                        getUserData()?.membership_type === "Elite" ? */}
                                                            <div className={style.eliteVideoInstructionContainer}>
                                                            <Image src={notesIcon} className={style.notesIcon}/>
                                                            <p className={style.instructionTitle}>Craft a compelling, desire-filled video under 3 minutes of maximum length</p>
                                                            </div>
                                                        {/* :
                                                            <></>
                                                        } */}
                                                    </div>
                                                :
                                                    uploadedVideoDisplay && (
                                                        <div className={style.displayManageVideos}> 
                                                            <video key={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${uploadedVideoDisplay}#t=0.1`} controls={0}>
                                                            <source src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${uploadedVideoDisplay}#t=0.1`}/>
                                                            </video> 
                                                        </div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                    </ContentBox>
                                )
                            }

                            <ContentBox title="Basic Details" onClick={() => DetailsEdit("BasicDetails")}>
                                <div className={style.details}>
                                    <div className={style.details_box}>
                                        <Details title={"Age"} data={preferenceData?.basic_detail?.age_name} />
                                        <Details title={"Height"} data={preferenceData?.basic_detail?.height_name} />
                                        <Details title={"Mother Tongue"} data={preferenceData?.basic_detail?.mother_tongue_name} />
                                    </div>
                                    <div className={style.details_box} >
                                        <Details title={"Marital Status"} data={preferenceData?.basic_detail?.marital_status} />
                                        <Details title={"Citizenship"} data={preferenceData?.basic_detail?.citizenship_name} />
                                        <Details title={"Physical Status"} data={preferenceData?.basic_detail?.physical_status} />
                                    </div>
                                </div>
                            </ContentBox>
                            <ContentBox title="Habits preferences" onClick={() => DetailsEdit("Habitspreferences")}>
                                <div className={style.details}>
                                    <div className={style.details_box}>
                                        <Details title={"Eating Habit"} data={preferenceData?.habit_detail?.eating_habit} />
                                        <Details title={"Drinking Habit"} data={preferenceData?.habit_detail?.drinking_habit} />
                                        <Details title={"Smoking Habit"} data={preferenceData?.habit_detail?.smoking_habit} />
                                    </div>
                                </div>
                            </ContentBox>
                                    <>
                                        <ContentBox title="professional Preferences" onClick={() => DetailsEdit("Professionalpreferences")}>
                                            <div className={style.details}>
                                                <div className={style.details_box}>
                                                    <Details title={"Annual Income"} data={preferenceData?.professional_detail?.annual_income_name} />
                                                    <Details title={"Employed In"} data={preferenceData?.professional_detail?.employed_in_name} />
                                                    <Details title={"Occupation"} data={preferenceData?.professional_detail?.occupation_name} />
                                                </div>
                                            </div>
                                        </ContentBox>
                                        <ContentBox title="Work Location Preferences" onClick={() => DetailsEdit("Worklocationpreferences")}>
                                            <div className={style.details}>
                                                <div className={style.details_box}>
                                                    <Details title={"Country"} data={preferenceData?.work_location_detail?.country} />
                                                    <Details title={"State"} data={preferenceData?.work_location_detail?.state_name} />
                                                    <Details title={"City"} data={preferenceData?.work_location_detail?.city_name} />
                                                </div>
                                            </div>
                                        </ContentBox>
                                        <ContentBox title="Religious Preferences" onClick={() => DetailsEdit("Religiouspreferences")}>
                                            <div className={style.details}>
                                                <div className={style.details_box}>
                                                    <Details title={"Chevvai Dosham"} data={preferenceData?.religious_detail?.chevvai_dosam} />
                                                    <Details title={"Community"} data={preferenceData?.religious_detail?.community_name} />
                                                    <Details title={"Gothram"} data={preferenceData?.religious_detail?.gothram_name} />
                                                    <Details title={"Rasi"} data={preferenceData?.religious_detail?.raasi_name} />
                                                    <Details title={"Religion"} data={preferenceData?.religious_detail?.religion_name} />
                                                    <Details title={"Star"} data={preferenceData?.religious_detail?.star_name} />
                                                </div>
                                            </div>
                                        </ContentBox>
                                    </>
                        </div>
                    </>
            }
        </>
    )
}

export default PartnerPreferences


export function ContentBox({ title, children, onClick }) {
    return (
        <div className={style.edit_cont}>
            <div className={style.titel_header}>
                <p className={style.title}>{title}</p>
                <Image src={edit} onClick={onClick} className={style.edit_curuser_point} />
            </div>
            <div className={style.box_shadow_container}>
                {children}
            </div>
        </div>
    );
}
export function Details({ title, data }) {

    return (
        <div className={style.details_text}>
            <p className={style.details_title}>{title}</p>
            <p className={style.details_sub_title}>{ typeof data === "object" ? data?.join(', ') : data }</p>
        </div>
    );
}