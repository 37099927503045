import React, { useEffect, useState } from 'react'
import style from "./Counts.module.css"
import Image from '../../../../../ui/Image/Image'
import rightArrowImage from "../../../assets/svg/Home/arrowRight.svg"
import { useNavigate } from 'react-router-dom';

export default function Counts({details}) {

    const [data, setData] = useState(null);

    const navigation = useNavigate()
    
    useEffect(() => {
        setData(details)
    }, [])

    const redirect = (page) => {
        if( page === "Viewed your profile" ) {
            navigation("/matches", {
                state: {
                    selectedMatch: {
                        api: "viewed-my-profile",
                        title: "Viewed my profile",
                        total_title: "Viewed my profile"
                    }
                }
            } )
        } else if( page === "Profile viewed by me" ) {
            navigation("/matches", {
                state: {
                    selectedMatch: {
                        api: "recently-viewed",
                        title: "Profile viewed by me",
                        total_title: "Profile viewed by me"
                      }
                }
            } )
        } else if( page === "Shown interest" ) {
            navigation("/mailbox", {
                state: {
                    activeTab: "recieved",
                    tabContent: "pending"
                }
            } )
        } else if( page === "Accepted your interest" ) {
            navigation("/mailbox", {
                state: {
                    activeTab: "sent",
                    tabContent: "accepted"
                }
            } )
        }
    }

  return (
    <div className={style.countsContainer}>
        {
            data?.map((item, index)=> (
                <div key={`matchCount-${index}`} className={style.countContainer}>
                    <div className={style.countNewContainer}>
                        <p className={style.count}>{item?.value}</p>
                        {
                            item?.tag !== "" && (
                                <div className={style.newValue}>{item?.tag}</div>
                            )
                        }
                    </div>
                    <div className={style.viewCountsDetails}>
                        <p className={style.viewLabel}>{item?.name}</p>
                        <Image
                            className={style.rightArrowImage}
                            onClick={()=> redirect(item?.name)}
                            src={rightArrowImage}
                        />
                    </div>
                </div>
            ))
        }
    </div>
  )
}
