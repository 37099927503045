import { useState } from 'react';
import initializeFirebase from '../pages/Chat/Db/FirebaseDb';
import { collection,doc,setDoc,getDoc,getDocs,query,where,addDoc} from "firebase/firestore"; 
import { getUserData } from '../../../utils/utils';

const useChatSetter = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const {db} = initializeFirebase();
    const userToken = getUserData().user_token;
    const userName = getUserData().name;
    const userProfileId = getUserData().user_id;
    const userImage = getUserData().image;
    

    const setUserChatEntry = async(participantToken,participant_name,participant_profile_id,participant_image="", applyData = null) => {
        console.log(participantToken,participant_name,participant_profile_id,participant_image);
        setLoading(true);
        const checkQuery = query(collection(db, "Chats"), where("participantTokens", "in", [[userToken,participantToken],[participantToken,userToken]]));
        let result = '';
        await getDocs(checkQuery).then((docsRef)=>{
            if(docsRef.size > 0){ 
                setSuccess(true)
                docsRef.forEach((docItem)=>{
                  result = docItem.id;

                })
            }else{
                let date = new Date();
                addDoc(collection(db, "Chats"), {
                    // [userToken]:{
                    //     image:userImage,
                    //     name:userName,
                    //     profile_id:userProfileId,
                    //     has_last_seen: 0
                    // },
                    // [participantToken]:{
                    //     image:participant_image,
                    //     name:participant_name,
                    //     profile_id:participant_profile_id,
                    //     has_last_seen: 0
                    // },
                    created_time: date,  
                    lastMessageText: "",  
                    last_message_sender: "",  
                    lastMessageTimestamp: date,  
                    participantTokens:[userToken,participantToken],
                    unreadMessages:0,
                    [`${userToken}_msg_seen`]:null,
                    [`${participantToken}_msg_seen`]:null
                    
                }).then((resp)=>{
                    setSuccess(true)
                    // result = `${userToken}_${participantToken}`;
                    result = resp.id;
                })
            }
        }).catch((error) => {
            setError(error);
            result = '';
        }) 
        setLoading(false);
        applyData && applyData(result);

    }

    return { loading, success, setUserChatEntry,error }

}

export default useChatSetter
