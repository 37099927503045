import React, { useRef, useEffect } from 'react';
import style from "./MaskImage.module.css"
import { getUserData } from '../../../../utils/utils';

const MaskImage = ({ imageUrl, watermarkText, width, height, side, x1=-80, y1=-50, x2=-80, y2=-70 }) => {

    const canvasRef = useRef(null); 

    console.log("watermarkText", watermarkText);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        const image = new Image();
        image.src = imageUrl;
        image.onload = () => {
            canvas.width = width;
            canvas.height = height;
            // Draw the image on the canvas
            ctx.drawImage(image, 0, 0, image.width, image.height, 0, 0, canvas.width, canvas.height);
            // Add watermark text
            ctx.font = '12px SourceSansPro-SemiBold';
            ctx.fillStyle = 'rgba(255, 255, 255, 0.5)'; // semi-transparent white
            ctx.textAlign = 'center';
            if( side ) {
                // Translate to the center of the canvas
                ctx.translate(canvas.width / 2, canvas.height - 20);
                // Rotate the context
                ctx.rotate((Math.PI / 180) * 90); // converting degrees to radians
            }
            if( side ) {
                // Draw the text
                ctx.fillText(`${getUserData()?.name.slice(0, 10) + `${getUserData()?.name?.length > 10 ? "..." : ""}`}(${getUserData()?.user_id})`, x1, y1);
                ctx.fillText("Doctor Matrimony", x2, y2);
            } else {
                // Draw the text
                ctx.fillText(`${getUserData()?.name.slice(0, 10) + `${getUserData()?.name?.length > 10 ? "..." : ""}`}(${getUserData()?.user_id})`, 90, 220);
                ctx.fillText("Doctor Matrimony", 90, 200);
            }
            if( side ) {
                // Restore the context to its original state
                ctx.rotate(-(Math.PI / 180) * 60); // restore the rotation
                ctx.translate(-canvas.width / 2, -(canvas.height - 20)); // restore the translation
            }
        };
    }, [imageUrl, watermarkText]);

    return <canvas className={style.canvasImage} ref={canvasRef} />;

};
export default MaskImage;