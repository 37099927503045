import React from "react"
import style from "./Viewer.module.css"
import { checkPdf } from "../../../../../utils/utils"
import Image from "../../../../../ui/Image/Image"
import closeIcon from "../../../assets/svg/Signup/Close.svg"
import Carousel from 'react-grid-carousel'
import MaskImage from "../../../features/MaskImage/MaskImage"

export const Viewer = ({doc, data, onClick}) => {
    return(
        <div className={style.documentViewer}>
          <Image src={closeIcon} onClick={onClick} className={style.closeIcon}/>
          <Carousel
              containerClassName={style.imagesCarouselContainer}
              cols={1}
              rows={1}
              gap={40}
              loop
              dotColorActive={"rgb(230, 70, 98)"}
              dotColorInactive={"rgb(68 68 68 / 49%)"}
              showDots={true}
          >
              {
                      doc?.map((itemImage, index)=> (
                          <Carousel.Item>
                              <div className={style.userProfileContainer}>
                                  {
                                      itemImage?.type === "image" ?  
                                        <MaskImage
                                            imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + itemImage?.content} 
                                            watermarkText={`${data?.profile_details?.user_profile_details?.name}(${data?.profile_details?.user_profile_details?.user_id})`}
                                            width={494}
                                            height={500}
                                            side={true}
                                            x1={-240}
                                            x2={-240}
                                            y1={-140}
                                            y2={-160}
                                        />
                                        //   <Image
                                        //       src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+itemImage?.content}
                                        //       className={style.doctorImage}
                                        //   />
                                      :
                                          <video width={500} height={420} controls>
                                              <source src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+itemImage?.content}/>
                                          </video>
                                  }
                              </div>
                          </Carousel.Item>
                      ))
              }
          </Carousel>
        </div>
    )
}