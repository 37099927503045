// SideDrawer.js
import React from 'react';
import style from "./SideDrawer.module.css" // You can create a separate CSS file for styling

const SideDrawer = ({ isOpen, closeDrawer, content }) => {
  return (
    <div>
      {isOpen && <div className={style.backdrop}></div>}
        <div className={`${style.sideDrawer} ${isOpen ? style.open : style.close}`}>
            {content}
        </div>
    </div>
  );
};

export default SideDrawer;
