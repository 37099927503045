import React, { useState,useEffect } from "react"
import style from "./Sidebar.module.css"
import Image from "../../../../../ui/Image/Image"
import drImage from "../../../pages/Chat/assets/Dr. Vidhya@2x.png"
import moreIcon from "../../../assets/common/Menu.svg"
import messageSentImage from "../../../pages/Chat/assets/Message Sent.svg"
// import db from "./Db/FirebaseDb"
import initializeFirebase from "../../../pages/Chat/Db/FirebaseDb"
import {getUserData} from '../../../../../utils/utils'
import ChatListData from "../chatData/ChatList"
import ChatHistory from "../chatData/ChatCanvas"
import { collection,doc,setDoc,getDoc,getDocs,Timestamp,onSnapshot,query,orderBy,where, documentId } from "firebase/firestore"; 
import { logDOM } from "@testing-library/react"
import { Circles } from "react-loader-spinner"

import chatMinimizeImage from "../assets/chatMinimize.svg"
import plusIconImage from "../../../assets/common/PlusIcon.svg"
import ChatSidebarList from "./ChatSidebarList"
import { useLocation } from "react-router-dom"


export default function Sidebar() {

  const [ refresh, setRefresh ] = useState(false)
  const { state } = useLocation();
  const [ onLoadDocId, setOnLoadDocId ] = useState(localStorage.getItem("redirectToken") ? localStorage.getItem("redirectToken") : state?.token)

    const [ message, setMessage ] = useState("")
    const {db} = initializeFirebase();
    const userData = getUserData();
    const userToken =  userData.user_token;
    let currentTimedate = new Date();
    const userAvailabilityQuery = query(collection(db, "room"), where("users", "array-contains", userToken));
    const allDocArrayQuery = query(collection(db, "room"),orderBy("created_time","desc"));
    const newListDataQuery = query(collection(db, "room"),orderBy("created_time","asc"),where("created_time", ">=", currentTimedate));
    const [chatListArray, setChatListArray] = useState([]);
    const [activeChatData, setActiveChatData] = useState({});
    const [chatListCount, setChatListCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [ minimize, setMinimize ] = useState(false)
    let changeState = true;
    let tempData = [];
    let chatListUnsub;

    // useEffect(()=> {
    //   window.scrollTo(0, 0);
    // })

    const [chats, setChats] = useState([]);
    // console.count('chats', chats);
    // console.log('chats', chats);

    const [users, setUsers] = useState([]);
    // console.log('users', users);

    const [userTokens, setUserTokens] = useState([]);
    const [tempUserTokens, setTempUserTokens] = useState([]);
    const [chatCanvasShow,setChatCanvasShow] = useState(false);
    let overAllChatArray = [];


    const chatListCallBack = (details,tok) => {
        localStorage.removeItem("redirectToken")

        if(activeChatData?.doc_id === undefined){
            let newChatIndex1 = chats.findIndex(x => x.doc_id === details.doc_id );
              
            let newListData1 = chats.map((item,index)=>{
               if(index === newChatIndex1){
                    return {...item,chatCountEnable:false }
                }else{
                    return item
                }
            })
            setChats(newListData1);
            setActiveChatData(newListData1[newChatIndex1]);
        }else if(activeChatData.participant_token !== tok){
              let oldChatIndex = chats.findIndex(x => x.doc_id  === activeChatData.doc_id );
              let newChatIndex = chats.findIndex(x => x.doc_id === details.doc_id );
            //   console.log("oldindex",oldChatIndex);
            //   console.log("newindex",newChatIndex);
              
              let newListData = chats.map((item,index)=>{
                  if(index === oldChatIndex){
                      return {...item,chatCountEnable:true}
                  }else if(index === newChatIndex){
                      return {...item,chatCountEnable:false }
                  }else{
                      return item
                  }
              })
            //   console.log("new list",newListData);
            //   console.log("new sctivelist",newListData[newChatIndex]);
              setChats(newListData);
              setActiveChatData(newListData[newChatIndex]);
          }

          if(!chatCanvasShow){
            setChatCanvasShow(true);
          }
  
      }

       const isDuplicate = (array, newItem) => {
        return array.some(item => (
          item.doc_id === newItem.doc_id &&
          item.participant_token === newItem.participant_token 
        ));
      };

    useEffect(() => {
        const chatListQuery = query(
            collection(db, 'Chats'),
            where('participantTokens', 'array-contains', userToken),
            orderBy('lastMessageTimestamp', 'desc')
        );
        
        const unsubscribeChats = onSnapshot(chatListQuery, (querySnapshot) => {
            // console.log('Reading chats',querySnapshot.docChanges());
            let date1 = new Date();
           
            const updatedChats = [];
            let participantTokens = [];
            let hasParticipantTokensUpdate = false;

            querySnapshot.docChanges().forEach((change)=>{
                // console.log( change.doc.data().created_time.toDate() > currentTimedate);
                // console.log( change.doc.data().created_time.toDate());
                if(change.type === "added" && change.doc.data().created_time > currentTimedate){
                    // console.log(change.doc.data().created_time > currentTimedate);
                    let newParticipantToken = change.doc.data().participantTokens.find((x) => x !== userToken);
                    let data = {
                        doc_id: change.doc.id,
                        participant_token: newParticipantToken,
                        user_token: userToken,
                        chatCountEnable: true,
                        ...change.doc.data()
                    }
                    let newChatArray = [data,...chats]
                    // setChats(newChatArray);
                    setChats((prev)=>[data,...prev]);
                    if(!tempUserTokens.includes(newParticipantToken)){
                        setTempUserTokens([...tempUserTokens,newParticipantToken]);

                    }
                    setChatListCount(newChatArray.length);

                }else if(change.type === "modified"){
                    let newParticipantToken = change.doc.data().participantTokens.find((x) => x !== userToken);

                    setChats((prev)=>{
                        return prev.map((item,index)=>{
                            if(item.doc_id === change.doc.id){
                                return {...item,
                                        [`${userToken}_msg_seen`]:change.doc.data()[`${userToken}_msg_seen`],
                                        [`${newParticipantToken}_msg_seen`]:change.doc.data()[`${newParticipantToken}_msg_seen`],
                                        lastMessageText:change.doc.data().lastMessageText,
                                        lastMessageTimestamp:change.doc.data().lastMessageTimestamp,
                                        last_message_sender:change.doc.data().last_message_sender
                                }
                            }else{
                                return item
                            }
                        })
                    })
                    
                    // updateAfterModify(change.doc.id,change.doc.data())
                    
                }else{
                    // console.log(change.doc.data().created_time > currentTimedate);
                    // console.log('check',change.type);
                    // console.log('updated chats',updatedChats);
                    let docIndex = 0;
                    querySnapshot.forEach(doc => {
                        let participantToken = doc.data().participantTokens.find((x) => x !== userToken);
                        // let chatCountEnableBool = docIndex === 0 ? false : true;
                        let chatCountEnableBool =  true;
                        let currentDocData = {
                            doc_id: doc.id,
                            participant_token: participantToken,
                            user_token: userToken,
                            chatCountEnable: chatCountEnableBool,
                          };
                        const chatData = {...currentDocData,...doc.data()};
                        if(!isDuplicate(updatedChats,chatData)){
                            updatedChats.push(chatData);
                            // console.count('push')
                        }
                       
        
                        chatData.participantTokens.forEach(participantToken => {
                            if (!participantTokens.includes(participantToken)) {
                                participantTokens.push(participantToken);
                                hasParticipantTokensUpdate = false;
                            }
                        });
                    //     if(docIndex === 0 && onLoadDocId !== '' && onLoadDocId !== undefined){
                    //         if(onLoadDocId === chatData.doc_id){
                    //               setActiveChatData(chatData);
                    //               docIndex += 1;
                    //          }
                    //     }else if (docIndex === 0) {
                    //      setActiveChatData(chatData);
                    //      docIndex += 1;
                    //    }
                    });
                    setChats(updatedChats);
                    setChatListCount(updatedChats.length);

                    setTempUserTokens(participantTokens);
                }
            })
            
            // querySnapshot.forEach(doc => {
            //     const chatData = doc.data();
            //     updatedChats.push({ id: doc.id, ...chatData });

            //     chatData.participantTokens.forEach(participantToken => {
            //         if (!participantTokens.includes(participantToken)) {
            //             participantTokens.push(participantToken);
            //             hasParticipantTokensUpdate = false;
            //         }
            //     });
            // });

            // setChats(updatedChats);
            // setTempUserTokens(participantTokens);
        }, (error) => {
            console.error("Error fetching chats:", error);
        });

        return () => unsubscribeChats();
    }, []);

    useEffect(() => {
        if ( !(arraysEqual(tempUserTokens, userTokens)) && Boolean(tempUserTokens.length) ) {
            // console.log('not equal', tempUserTokens, userTokens);
            setUserTokens(tempUserTokens);
        } else {
            console.log(' equal');
            // console.log(chats);
        }
    }, [chats]);

    useEffect(() => {
        if (Boolean(userTokens.length)) {
            const userListQuery = query(
                collection(db, "Users"),
                where(documentId(), 'in', userTokens),
            );
    
            const unsubscribeUsers = onSnapshot(userListQuery, (querySnapshot) => {
                console.count('Reading users');
    
                const newUsers = {};
                let newUserArray = chats;
                querySnapshot.forEach(doc => {
                    newUsers[doc.id] = doc.data();
                    if(doc.id !== userToken){
                        let dataIndex =  chats.findIndex(x=> x.participant_token === doc.id);
                        newUserArray = chats.map((chat, index) => {
                            if (index === dataIndex) {
                            return {
                                ...chat,
                                participant_profile_id: doc.data().profile_id,
                                participant_image: doc.data().image,
                                participant_name: doc.data().name,
                                isOnline:doc.data().isOnline,
                                isDeleted:doc.data().isDeleted
                            };
                            }
                        
                            return newUserArray[index];
                        })
                            
                    }
                   

                });
                setActiveChatData((curre)=>{
                    let fullActiveChatObj = newUserArray.find(x => x.doc_id === curre.doc_id)
                    if(curre?.doc_id === undefined){
                        return {}
                    }else{
                        return {...fullActiveChatObj}
                    }

                    
                });
                setChats(newUserArray)
                setUsers(prevUsers => ({ ...prevUsers, ...newUsers }));
            }, (error) => {
                console.error("Error fetching users:", error);
            });

            return () => unsubscribeUsers();
        }
    }, [userTokens]);

    const arraysEqual = (arr1, arr2) => {
        if (arr1.length !== arr2.length) {
            return false;
        }
    
        const sortedArr1 = [...arr1].sort();
        const sortedArr2 = [...arr2].sort();
    
        return sortedArr1.every((value, index) => value === sortedArr2[index]);
    }
    
    return(
        <>
            {
                // error ? (
                //     <div className={style.loaderContainer}>
                //         <p className={style.error}>{error}</p>
                //     </div>
                // ) : 
                    !loading ? (
                        <div className={style.chatContainer}>
                            <div className={style.chatListTitleContainer}>
                                <p className={style.chatContainerTitle}>Chat</p>
                                    <Image 
                                        onClick={()=> setMinimize(!minimize)}
                                        src={ minimize ? plusIconImage : chatMinimizeImage}
                                        className={style.minimizeIcon}
                                    />
                            </div>
                            <div className={style.chatListContainer} style={{ maxHeight: minimize ? "4px" : "100%", overflow: "hidden" }}>
                                {(chats.length > 0 && chats)
                                    ? chats.map((item, index) =>{
                                        return(
                                            <ChatSidebarList
                                                key={item.doc_id} 
                                                db={db}
                                                det={item}
                                                clickHandle={chatListCallBack}
                                            />
                                        )})
                                    : <div style={{fontSize:"20px",fontWeight:"bold",marginTop:"20px"}}>No Chats Available</div>}
                                    
                            </div>
                        </div>
                    ) : (
                        <div className={style.loaderContainer}>
                            <Circles
                                height="35"
                                width="35"
                                color="rgb(239, 116, 53)"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass={style.loader}
                                visible={true}
                            />
                        </div>
                    )
            }
        </>
    )
}