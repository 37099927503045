import React, { useEffect, useRef, useState } from "react";
import style from "./BlockReport.module.css";
import Image from "../../../../../ui/Image/Image";

import moreIcon from "../../../assets/common/Menu.svg";
import ModalBox from "../../../../../ui/Modal/ModalBox";
import { ReportModal } from "./ReportModal/ReportModal";
import { addScroll, avoidScroll, getUserToken } from "../../../../../utils/utils";
import config from '../../../../../app.json';
import useApiCall from '../../../hooks/useApiCall';
import Success from "../../../pages/CeleberateMatch/Modal/Success";
import { useNavigate } from "react-router-dom";

export const BlockReport = ({token, id, loadBlock, setLoadBlock }) => {
    const navigation = useNavigate(null)
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [ viewDetailsModal, setViewDetailsModal ] = useState("")
    const blockReportRef = useRef(null);
    const [ viewSuccessModal, setViewSuccessModal ] = useState(false)
    const [ viewReportSuccessModal, setViewReportSuccessModal ] = useState(false)

    const { isLoading: sendBlockLoading, success: sendBlockSuccess, error: sendBlockError, sendRequest: block } = useApiCall();
  
    const toggleTooltip = () => {
      setTooltipVisible(!tooltipVisible);
    };
  
    const closeTooltip = () => {
      setTooltipVisible(false);
    };

    // Function to close the tooltip when a click occurs outside
    const handleOutsideClick = (e) => {
      if (blockReportRef.current && !blockReportRef.current.contains(e.target)) {
        closeTooltip();
      }
    };

    useEffect(()=> {
      if( sendBlockSuccess ) {

        avoidScroll()
        setViewSuccessModal(true)
      }
    }, [sendBlockSuccess, sendBlockError])

    useEffect(() => {
      // Add the event listener when the component mounts
      document.addEventListener("click", handleOutsideClick);
  
      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, []);

  const handleBlock = () => {
    block({
        url: `profile/block`,
        method: 'POST',
        body: {
          "profile_token": token
        },
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
    });
  }

  return (
    <div className={style.container}>
        <ModalBox
          open={viewSuccessModal} 
          width={"598px"} 
          modalContent={
            <Success
              title={"Profile Hidden Successfully"} 
              // description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
              buttonText={"Okay"}
              onClick={()=>{
                addScroll()
                setLoadBlock(!loadBlock)
                setViewSuccessModal(false)
              }}
            />
          }
        />
        <ModalBox
          open={viewReportSuccessModal} 
          width={"598px"} 
          modalContent={
            <Success
              title={"Reported Successfully"} 
              // description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
              buttonText={"Okay"}
              onClick={()=>{
                addScroll()
                setLoadBlock(!loadBlock)
                setViewReportSuccessModal(false)
              }}
            />
          }
        />
        <ModalBox
            open={viewDetailsModal} 
            width={"796px"}
            modalContent={
                <ReportModal setViewReportSuccessModal={setViewReportSuccessModal} id={id} token={token} setViewDetailsModal={setViewDetailsModal}/>
            } 
        />
      {/* Image with a click event handler */}
      <Image src={moreIcon} className={style.moreIcon} onClick={toggleTooltip} />

      {/* Conditional rendering of the tooltip based on state */}
      {tooltipVisible && (
        <div className={style.tooltip}>
          <p className={style.menu} onClick={()=>{
            navigation("/profile_details", { state: { token: token } })
          }}>View Profile</p>
          <p className={style.menu} onClick={()=>{
            setTooltipVisible(false)
            handleBlock()
          }}>Do not show Profile</p>
          <p className={style.menu} onClick={()=> {
            avoidScroll()
            setTooltipVisible(false)
            setViewDetailsModal(true)
        }}>Report Profile</p>
        </div>
      )}
    </div>
  );
};
