import React, { useEffect, useState } from "react"
import style from "./ReportModal.module.css"
import FloatingLabelDropdown from "../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown"
import PopoutInput from "../../../../../ui/PopoutInput/PopoutInput"
import PopoutTextarea from "../../../../../ui/PopoutTextarea/PopoutTextarea"
import Button from "../../../../../ui/Button/Button"
import { addScroll, getUserToken, isStringValid } from "../../../../../utils/utils"
import config from '../../../../../app.json';
import useApiCall from '../../../hooks/useApiCall';

export const ReportModal = ({ setViewDetailsModal, setViewReportSuccessModal, token, id }) => {

    const [reasonData, setReasonData] = useState(null);

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    const { isLoading: sendReportLoading, success: sendReportSuccess, error: sendReportError, sendRequest: submitReport } = useApiCall();

    const [formData, setFormData] = useState({
        matrimony_id: "",
        reason: "",
        comment: ""
    })

    const [formError, setFormError] = useState({
        matrimony_id: "",
        reason: "",
        comment: ""
    })

    useEffect(() => {
        getDetails({
            url: `profile/report/get-reason`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setReasonData(responseData?.data)
        });
    }, [token])

    const handleFormData = (name, value) => {

        setFormData({
            ...formData,
            [name]: value
        })
    }

    useEffect(() => {
        if (sendReportSuccess) {
            setViewDetailsModal(false)
            setViewReportSuccessModal(true)
        }
        if (sendReportError) {
            console.log("loginError", sendReportError);
        }
    }, [sendReportSuccess, sendReportError]);

    const handleSubmit = () => {
        const newFormError = {};

        if (formData.reason === "Select an option" || !formData.reason) {
            newFormError.reason = "Please enter reason";
        } else {
            newFormError.reason = "";
        }
        if (!formData.comment) {
            newFormError.comment = "Please enter Comment";
        } else {
            newFormError.comment = "";
        }

        setFormError({
            ...formError,
            ...newFormError,
        });

        if (!newFormError.reason && !newFormError.comment) {
            submitReport({
                url: `profile/report/create`,
                method: 'POST',
                body: {
                    "profile_token": token,
                    "reason": formData.reason.trim(),
                    "comment": formData.comment.trim(),
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });
        }
    }

    return (
        <div className={style.reportModalContainer}>
            <p className={style.title}>Report Profile</p>
            <div className={style.formContainer}>
                <div className={style.formDropDownContainer}>
                    <PopoutInput
                        label="Matrimony ID"
                        name="matrimony_id"
                        disabled={true}
                        value={id}
                        onChange={(e) => handleFormData("matrimony_id", e.target.value)}
                        style={{
                            width: "100%",
                            paddingBottom: '26px',
                        }}
                    />
                    <FloatingLabelDropdown
                        label="Reason"
                        name="reason"
                        responseType="value"
                        options={reasonData} // Add options
                        value={formData.reason}
                        onChange={(e) => handleFormData("reason", e.target.value)}
                        style={{
                            paddingBottom: '26px',
                        }}
                    />
                </div>
                <PopoutTextarea
                    characterLimit={255}
                    label="Comment"
                    name="comment"
                    className={style.moreAbout}
                    value={formData.comment}
                    onChange={(e) => handleFormData("comment", e.target.value)}
                />
                {
                    formError.reason &&
                    <p className="error">{formError.reason}</p>
                }
                {
                    formError.comment &&
                    <p className="error">{formError.comment}</p>
                }
                <div className={style.buttonProgressContainer}>
                    <div className={style.buttonContainer}>
                        <Button
                            className={`${style.button} ${style.declineButton} primaryButton cancelButton`}
                            onClick={() => {
                                addScroll()
                                setViewDetailsModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={`${style.button} primaryButton`}
                            onClick={handleSubmit}
                            loading={sendReportLoading}
                        >
                            Okay
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}