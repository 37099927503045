import React, { useState, useEffect } from 'react';
import classes from './ViewSmallDocument.module.css';
import Image from '../Img/Img';
import PdfViewer from '../PDFViewer/PdfViewer';
import Loader from '../Loader/Loader'
import ReusableModal from '../../UI/Modal/ScrollingModal/ScrollingModal';

const ViewSmallDocument = ({ title, data }) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const handlePdfView = (documentUrl) => {
        setSelectedDocument(documentUrl);
        setOpenPopup(true);
    };

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, [data]);
    const isPdf = (url) => {
        return url && url.toLowerCase().endsWith('.pdf');
    };

    return (
        <div className={classes.document_viewer}>
            <p className={classes.document_title}>{title}</p>
            <div className={classes.split}>
                <div className={classes.document_container}>
                    <div className={classes.viewdocument_img}>
                        {isLoading ? (
                            <Loader />
                        ) : isPdf(data) ? (

                            <PdfViewer fileUrl={data} />
                        ) : (
                            <Image src={data} alt="document" />
                        )}
                    </div>
                </div>
                {isLoading ? (
                    <p className={classes.viewdocument_text}>Loading...</p>
                ) :
                    isPdf(data) ? (
                        <a
                            href="#"
                            className={classes.viewdocument_text}
                            onClick={() => handlePdfView(data)}
                        >
                            View Document
                        </a>
                    ) : (<a
                        href="#"
                        className={classes.viewdocument_text}
                        onClick={() => handlePdfView(data)}
                    >
                        View Document
                    </a>)}
            </div>
            <ReusableModal
                open={openPopup}
                setOpen={setOpenPopup}
                scrollType="body"
                size="sm"
                pdfViewer="pdf">
                {isPdf(selectedDocument) ? (

                    <PdfViewer fileUrl={selectedDocument} />
                ) : (
                    <Image src={selectedDocument} alt="document" className={classes.img__lightbox} />
                )}
            </ReusableModal>
        </div>
    );
};

export default ViewSmallDocument;
