import React, { useEffect, useState } from "react";
import style from "./CoupleInterviews.module.css";
import { Button } from "@mui/material";

import siteMap from "../../../assets/png/ApplicationStatus/Bitmap.png";
import siteMap1 from "../../../assets/png/ApplicationStatus/Bitmap1.png";
import couples from "../../../assets/png/ApplicationStatus/Couple1.png";
import VideoIcon from "../../../assets/svg/ApplicationStatus/Group.svg";
import CarouselItem from "../../../../../ui/Carousel/CarouselItem/CarouselItem";
import Image from "../../../../../ui/Image/Image";
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import { addScroll, avoidScroll, getUserToken } from "../../../../../utils/utils";
import { Link, useNavigate } from "react-router-dom";
import { Viewer } from "./Viewer/Viewer";
import ModalBox from "../../../../../ui/Modal/ModalBox";

const CoupleInterviews = () => {

    const navigation = useNavigate("")
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)
    const [ viewPreviewModal, setViewPreviewModal ] = useState(false)
    const [ seletedVideo, setSelectedVideo ] = useState("")

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    useEffect(() => {
        if (lastPage) {
            return;
        }
        getDetails({
            url: `interview/read?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setLastPage(currentPage >= responseData.data.last_page);
            setData(responseData.data.data);
            setLoadingMore(false);
        });
    }, [currentPage, lastPage]);

    const galleryLists = [
        {
            image: siteMap,
            title: "Dr.Santhosh Kumar & Dr.Catherine",
            descrip: `Santhosh Kumar and Catherine feel happiness in the air as this couple can't stop being in love.`,
        },
        {
            image: siteMap1,
            title: "Dr.Samson & Dr.Shiny",
            descrip: `Samson and Shiny , say they found love and not 'just' marriage.`,
        },
        {
            image: couples,
            title: "Dr.Samson & Dr.Shiny",
            descrip: `Samson and Shiny , say they found love and not 'just' marriage.`,
        },
        {
            image: siteMap,
            title: "Dr.Santhosh Kumar & Dr.Catherine",
            descrip: `Santhosh Kumar and Catherine feel happiness in the air as this couple can't stop being in love.`,
        },
        {
            image: siteMap1,
            title: "Dr.Samson & Dr.Shiny",
            descrip: `Samson and Shiny , say they found love and not 'just' marriage.`,
        },
        {
            image: couples,
            title: "Dr.Samson & Dr.Shiny",
            descrip: `Samson and Shiny , say they found love and not 'just' marriage.`,
        },
        {
            image: siteMap,
            title: "Dr.Santhosh Kumar & Dr.Catherine",
            descrip: `Santhosh Kumar and Catherine feel happiness in the air as this couple can't stop being in love.`,
        },
        {
            image: siteMap1,
            title: "Dr.Samson & Dr.Shiny",
            descrip: `Samson and Shiny , say they found love and not 'just' marriage.`,
        },
        {
            image: couples,
            title: "Dr.Samson & Dr.Shiny",
            descrip: `Samson and Shiny , say they found love and not 'just' marriage.`,
        },
    ];

    const handlePreviewVideo = () => {
        setViewPreviewModal(true)
    }
 
    const galleryVideo = data?.map((item, index) => (
        <div className={style.videoGallery} key={index} onClick={()=> {
            setSelectedVideo(item)
            avoidScroll()
            handlePreviewVideo()
        }}>
            <Image
                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.thumbnail}
                alt={"Couples Feedback"}
                className={style.videoImage}
            />
            <div className={style.videoContent}>
                <h2>{item?.groom_name} & {item?.bride_name}</h2>
                <p>{item.description.length > 120 ? `${item.description.substring(0, 120)}...` : item.description}</p>
            </div>
            <Button
                style={{
                    position: "absolute",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    top: 0,
                }}
                className={style.videoBtn}
                startIcon={<img src={VideoIcon} alt={"VideoBtn"} />}
            ></Button>
        </div>
    ));
    const MyDot = ({ isActive }) => (
        <span
            style={{
                display: "inline-block",
                height: isActive ? "12px" : "8px",
                width: isActive ? "12px" : "8px",
                background: isActive ? "#EF7435" : "#E1E1E1",
                borderRadius: "100%",
            }}
        ></span>
    );
    return (
        <>
            <ModalBox
                open={viewPreviewModal} 
                width={"800px"} 
                modalContent={
                    <Viewer 
                        buttonText={"Okay"}
                        doc={seletedVideo}
                        onClick={()=> {
                            addScroll()
                            setViewPreviewModal(false)
                        }}
                    />
                }
            />
            {
                data?.length ?
                    <div className={style.StoriesContainer}>
                        <div className={`${style.bannerSectionContainer} container`}>
                            <div className={style.textContainer}>
                                <h2> Couple Interviews </h2>
                                <p>
                                    "Couple Interviews" is an enchanting window into these
                                    unique journeys, where couples graciously open their
                                    hearts to share their experiences, wisdom, and the
                                    boundless love that blossomed through the app's
                                    carefully crafted connections.
                                </p>
                            </div>
                            <div className={style.CarouselOutline} id="arrowMark">
                                <CarouselItem
                                    cols={3}
                                    rows={1}
                                    gap={30}
                                    dot={MyDot}
                                    ItemUrl={galleryVideo}
                                    showDots={true}
                                    mobileBreakpoint={520}
                                    responsiveLayout={[
                                        { breakpoint: 1024, cols: 3 },
                                        { breakpoint: 750, cols: 2, rows: 1, gap: 5 },
                                        {
                                            breakpoint: 520,
                                            autoplay: 2000,
                                            cols: 1,
                                            loop: true,
                                        },
                                    ]}
                                />
                            </div>
                            <Link
                                to={"/couple_interviews"}
                                className={style.linkPage}
                                style={{ color: "#ef7435" }}
                            >
                                View All Couple Interviews
                            </Link>
                        </div>
                    </div>
                :
                <></>
            }
        </>
    );
};

export default CoupleInterviews;
