import React, { useEffect, useRef, useState } from 'react'
import style from './Header.module.css'
import Image from "../../../../ui/Image/Image"
import Button from "../../../../ui/Button/Button"
import Logo from "../../assets/svg/LandingPage/footerLogo.svg"
import supportImage from "../../assets/svg/LandingPage/Support.svg"
import SideDrawer from '../../../../ui/SideDrawer/SideDrawer'
import Signup from '../Signup/Signup'
import Login from '../Login/Login'
import { addScroll, avoidScroll, getUserData, getUserToken, logout } from '../../../../utils/utils'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import notificationImage from "../../assets/common/bell.svg"
import profileImage from "../../assets/png/Profile/Profile Photo@2x.png"
import dropdownImage from "../../../../ui/assets/dropdown.svg"
import navbarImage from "../../assets/common/mobileNavbar.svg"
import searchImage from "../../assets/common/Search.svg"
import elliteIcon from "../../assets/svg/Home/elliteIcon.svg"
import NavMenu from '../NavMenu/NavMenu'
import useApiCall from '../../hooks/useApiCall'

import config from "../../../../app.json"
import { Dropdown } from './Dropdown/Dropdown'
import ModalBox from '../../../../ui/Modal/ModalBox'
import { Content } from './Confirm/Content/Content'

import menuAccountIcon from "./Dropdown/assets/My Account.svg"
import logoutIcon from "./Dropdown/assets/Logout.svg"
import { Search } from './Confirm/Search/Search'
import initializeFirebase from '../../pages/Chat/Db/FirebaseDb';
import { doc,updateDoc, getDoc} from "firebase/firestore"; 
import ReactGA from 'react-ga4';
import { getAnalytics, logEvent } from "firebase/analytics";

export default function Header() {

    const analytics = getAnalytics();
    const [isOpen, setIsOpen] = useState(false);
    const {db} = initializeFirebase();
    const [isNotificationDropdownOpen, setIsNotificationDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const navigation = useNavigate()
    const locationChanges = useLocation();
    const [data, setData] = useState(null);
    const [viewSuccessModal, setViewSuccessModal] = useState(false)
    const [viewSearchAlertModal, setViewSearchAlertModal] = useState(false)
    const [ refresh, setRefresh ] = useState(false)

    const [searchValue, setSearchValue] = useState("")

    const { error: logoutError, code: responseCode, sendRequest: logout } = useApiCall();

    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: getDetails } = useApiCall();

    // Get the URL parameters
    const urlParams = window?.location?.pathname;

    const handleUnload = () =>{
        const userToken = getUserData()?.user_token
        if( userToken ) {
            const docRef = doc(db, "Users", `${getUserData()?.user_token}`);
            const checkUserQuery = doc(db, "Users", userToken);     
            getDoc(checkUserQuery).then((userData)=>{
                console.log("userData.exists()", userData.exists());
                if(userData.exists()){
                    updateDoc(docRef, {
                        isOnline: false,
                    });
                }
            })
        }
    }

    const toggleRefresh = () => {
        setRefresh(!refresh)
    }

    useEffect(() => {
        setIsNotificationDropdownOpen(false);
        if( 
            locationChanges?.pathname !== "/chat"
        ) {
            console.log("window.scrollTo(0,0)");
            window.scrollTo(0,0)
        }
        const userToken = getUserData()?.user_token
        if( userToken ) {
            const docRef = doc(db, "Users", `${getUserData()?.user_token}`);
            const checkUserQuery = doc(db, "Users", userToken);     
            getDoc(checkUserQuery).then((userData)=>{
                if(userData.exists()){
                    updateDoc(docRef, {
                        isOnline: true,
                    });
                    // window.removeEventListener('beforeunload',handleUnload);
                    console.log("mount",userToken)
                    window.addEventListener('beforeunload', handleUnload);
                }
            })
        }
        
        logEvent(analytics, 'Visited Pages', {
            page_location: window.location.href,
            page_path: window.location.pathname,
            page_title: document.title
        });
          
        return () => {
          if(userToken){
            //   console.log("un-mount",userToken,token);
          
          }
          window.removeEventListener('beforeunload',handleUnload);
        }
        // Send a custom event

    },[locationChanges])

    useEffect(() => {
        if (getUserToken() && getUserData()?.status === "Active") {
            getDetails({
                url: `notification/get-unread-count`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (responseData) => {
                setData(responseData)
            });
        }
    }, [isNotificationDropdownOpen, locationChanges, refresh]);

    useEffect(() => {
        if (getUserToken()) {
            getDetails({
                url: "auth/check-user",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                if( !getUserToken() ) {
                    navigation("/")
                }
                if (data?.data?.status === "Blocked" && !locationChanges?.pathname === "/status") {
                    navigation("/")
                } else {
                    switch (data?.data?.status) {
                        case "Pending":
                            locationChanges?.pathname !== "/" && locationChanges?.pathname !== "/status" && navigation("/status")
                            break;
                        case "Rejected":
                            locationChanges?.pathname !== "/application_rejected" && navigation("/application_rejected")
                            break;
                        case "Blocked":
                            locationChanges?.pathname !== "/status" && navigation("/status")
                            break;
                        case "Expired":
                            locationChanges?.pathname !== "/subscribe" && locationChanges?.pathname !== "/expired" && navigation("/expired")
                            break;
                        case "Verified":
                            localStorage.setItem('subscriptionFromHome', 0);
                            if (
                                locationChanges?.pathname !== "/" &&
                                locationChanges?.pathname !== "/terms_and_conditions" &&
                                locationChanges?.pathname !== "/privacy_policy" &&
                                locationChanges?.pathname !== "/about_us" &&
                                locationChanges?.pathname !== "/terms_and_conditions_app" &&
                                locationChanges?.pathname !== "/privacy_policy_app" &&
                                locationChanges?.pathname !== "/about_us_app" &&
                                locationChanges?.pathname !== "/faq_app" &&
                                locationChanges?.pathname !== "/faq"
                            ) {
                                locationChanges?.pathname !== "/subscribe" && navigation("/subscribe")
                            }
                            break;
                        case "Onboarding":
                            if (locationChanges?.pathname === "/subscribe") {
                                locationChanges?.pathname !== "/subscribe" && navigation("/subscribe")
                            } else {
                                if (
                                    locationChanges?.pathname !== "/" &&
                                    locationChanges?.pathname !== "/terms_and_conditions" &&
                                    locationChanges?.pathname !== "/privacy_policy" &&
                                    locationChanges?.pathname !== "/about_us" &&
                                    locationChanges?.pathname !== "/terms_and_conditions_app" &&
                                    locationChanges?.pathname !== "/privacy_policy_app" &&
                                    locationChanges?.pathname !== "/about_us_app" &&
                                    locationChanges?.pathname !== "/faq_app" &&
                                    locationChanges?.pathname !== "/faq"
                                ) {
                                    locationChanges?.pathname !== "/build_profile" && navigation("/build_profile")
                                }
                            }
                            break;
                        case "Preferencing":
                            if (locationChanges?.pathname === "/subscribe") {
                                locationChanges?.pathname !== "/subscribe" && navigation("/subscribe")
                            } else {
                                if (
                                    locationChanges?.pathname !== "/" &&
                                    locationChanges?.pathname !== "/terms_and_conditions" &&
                                    locationChanges?.pathname !== "/privacy_policy" &&
                                    locationChanges?.pathname !== "/about_us" &&
                                    locationChanges?.pathname !== "/terms_and_conditions_app" &&
                                    locationChanges?.pathname !== "/privacy_policy_app" &&
                                    locationChanges?.pathname !== "/about_us_app" &&
                                    locationChanges?.pathname !== "/faq_app" &&
                                    locationChanges?.pathname !== "/faq"
                                ) {
                                    locationChanges?.pathname !== "/partner_preference" && navigation("/partner_preference")
                                }
                            }
                            break;
                        case "Active":
                            if (
                                locationChanges?.pathname === "/partner_preference"
                                || locationChanges?.pathname === "/build_profile"
                                || locationChanges?.pathname === "/expired"
                            ) {
                                locationChanges?.pathname !== "/home" &&  navigation("/home")
                            } else if (locationChanges?.pathname === "/subscribe") {
                                locationChanges?.pathname !== "/subscribe" && navigation("/subscribe")
                            }
                            break;
                    }
                }
                localStorage.setItem('user', JSON.stringify(data.data));
            });
        } else {
            if (
                locationChanges?.pathname !== "/" &&
                locationChanges?.pathname !== "/terms_and_conditions" &&
                locationChanges?.pathname !== "/privacy_policy" &&
                locationChanges?.pathname !== "/about_us" &&
                locationChanges?.pathname !== "/terms_and_conditions_app" &&
                locationChanges?.pathname !== "/privacy_policy_app" &&
                locationChanges?.pathname !== "/about_us_app" &&
                locationChanges?.pathname !== "/faq_app" &&
                locationChanges?.pathname !== "/faq"
            ) {
                navigation("/")
            }
        }
    }, [locationChanges])

    // Close the dropdown when clicking outside of it
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        setIsNotificationDropdownOpen(false);
    };

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdownOpen(!isNotificationDropdownOpen);
    };

    let location = useLocation();

    const [pathName, setPathName] = useState("")

    useEffect(() => {
        setIsOpen(false)
        setPathName(location.pathname)
    }, [location])

    useEffect(()=> {
        if( responseCode === 401 ) {
            localStorage.removeItem("token");
            localStorage.removeItem("subscriptionFromHome");
            localStorage.removeItem("user");
            setViewSuccessModal(false)
            addScroll()
            navigation("/")
        }
    }, [responseCode])

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [drawerType, setDrawerType] = useState("");

    const openDrawer = (type) => {
        avoidScroll()
        setDrawerType(type)
        setIsDrawerOpen(true);
    };

    const closeDrawer = (type) => {
        avoidScroll()
        setDrawerType(type)
        setIsDrawerOpen(false);
    };

    // Define your menu items and their paths
    const menuItems = [
        { label: "Home", path: "/home" },
        { label: "Matches", path: "/matches" },
        { label: "Mail Box", path: "/mailbox" },
        { label: "Chat", path: "/chat" },
    ];

    if (
        pathName === "/build_profile" ||
        pathName === "/partner_preference" ||
        pathName === "/feedback" ||
        pathName === "/celeberate_match" ||
        pathName === "/share_your_story" ||
        pathName === "/premium_partner_preference"
    ) return

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && searchValue) {
            // Redirect to a new page or perform an action here
            // For redirection, you can use history.push
            // For action, you can call a function
            if( searchValue?.trim() === "" ) {
                setViewSearchAlertModal(true)
            } else {
                navigation("search", { state: { term: searchValue } }); // Example redirection to a search page
            }

            // Clear the input field
            setSearchValue('');
        }
    };

    const handleRedirect = () => {
        localStorage.setItem('subscriptionFromHome', 1);
        navigation("/subscribe")
    }

    const handleLogout = () => {
        setIsOpen(!isOpen);
        avoidScroll()
        setViewSuccessModal(true)
    }

    const handleLogoutData = () => {
        clearLoginData()
    }

    const clearLoginData = () => {
        logout({
            url: `${config.apiEndpoints.logout}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            // Make user offline for chat
            let userToken = getUserData()?.user_token;
            const {db} = initializeFirebase();
            console.log("userToken", userToken);
            const docRef = doc(db, "Users", `${userToken}`);
            updateDoc(docRef, {
                isOnline: false,
            });

            localStorage.removeItem("token");
            localStorage.removeItem("subscriptionFromHome");
            localStorage.removeItem("user");
            navigation("/")
            addScroll()
            setViewSuccessModal(false)
            // clearLoginData()
        });
    }

    return (
        <div className={`${style.header}`}>
            <ModalBox
                open={viewSearchAlertModal}
                width={"500px"}
                modalContent={
                    <Search
                        onClick={() => {
                            setViewSearchAlertModal(false)
                        }}
                    />
                }
            />
            <ModalBox
                open={viewSuccessModal}
                width={"500px"}
                modalContent={
                    <Content
                        setViewSuccessModal={setViewSuccessModal}
                        onClick={handleLogoutData}
                    />
                }
            />
            <SideDrawer
                isOpen={isDrawerOpen}
                closeDrawer={closeDrawer}
                content={
                    drawerType === "login" ?
                        <Login setDrawerType={setDrawerType} closeDrawer={closeDrawer} />
                        :
                        drawerType === "register" ?
                            <Signup setDrawerType={setDrawerType} closeDrawer={closeDrawer} />
                            :
                            <NavMenu setDrawerType={setDrawerType} closeDrawer={closeDrawer} />

                }
            />
            <div className={`${style.headerContainer} ${!getUserToken() ? style.notLoggedIn : ""} container p-t-10 p-b-11`}>
                <div className={style.logoContainer}>
                    <Image
                        onClick={() => navigation("/home")}
                        src={Logo}
                        className={style.logoImage}
                    />
                    {
                        getUserToken() && getUserData()?.status === "Active" ? (
                            <div className={style.searchContainer}>
                                <Image
                                    className={style.searchImage}
                                    src={searchImage}
                                />
                                <input
                                    value={searchValue}
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyPress={handleKeyPress}
                                    className={style.searchField}
                                    type={"text"}
                                    placeholder='Search Matrimony ID…'
                                />
                            </div>
                        ) : (<></>)
                    }
                </div>
                <div className={style.buttonContainer}>
                    {
                        !getUserToken() ? (
                            window.innerWidth > 600 ?
                                <>
                                    {/* <div className={style.callSupportContainer}>
                                        <Image
                                            src={supportImage}
                                        />
                                        <div className={style.number}>
                                            <p className={style.label}>Call us for support</p>
                                            <p className={style.value}>+91 846839362</p>
                                        </div>
                                    </div> */}
                                    {
                                        ( locationChanges?.pathname !== "/terms_and_conditions" && locationChanges?.pathname !== "/privacy_policy" ) && (
                                            <Button
                                                onClick={() => openDrawer("login")}
                                                style={{ minWidth: 120 }}
                                                className={style.loginButton}
                                            >
                                                Login Now
                                            </Button>
                                        )
                                    }
                                    {
                                        ( locationChanges?.pathname !== "/terms_and_conditions" && locationChanges?.pathname !== "/privacy_policy" ) && (
                                            <Button
                                                onClick={() => openDrawer("register")}
                                                style={{ minWidth: 120 }}
                                                className={style.loginButtonOutlined}
                                            >
                                                Register
                                            </Button>
                                        )
                                    }
                                </>
                                :
                                <div className={style.navbarContainer}>
                                    <Image
                                        onClick={() => setIsDrawerOpen(true)}
                                        src={navbarImage}
                                    />
                                </div>
                        ) :
                            <>
                                {
                                    window.innerWidth > 600 ?
                                        <div className={style.menuContainer}>
                                            <div className={style.menu}>
                                                <ul>
                                                    {getUserData().status === "Active" && menuItems.map((item, index) => (
                                                        <li
                                                            key={index}
                                                            className={pathName === item.path ? style.activeMenuItem : ""}
                                                            onClick={() => navigation(item.path)}
                                                        >
                                                            {item.label}
                                                        </li>
                                                    ))}
                                                    {
                                                        getUserData()?.status === "Active" &&
                                                        <li className={style.notificationIconContainer}>
                                                            <Image onClick={toggleNotificationDropdown} src={notificationImage} />
                                                            {
                                                                data?.data > 0 && <div className={style.newNotificationDot}></div>
                                                            }
                                                            {
                                                                isNotificationDropdownOpen && (
                                                                    <div className={style.dropdownContainer}>
                                                                        <Dropdown toggleRefresh={toggleRefresh} setIsNotificationDropdownOpen={setIsNotificationDropdownOpen} toggleNotificationDropdown={toggleNotificationDropdown} />
                                                                    </div>
                                                                )
                                                            }
                                                        </li>
                                                    }
                                                    {/* {
                                                        getUserData()?.membership_type === "Premium" &&
                                                            <li>
                                                                <div onClick={handleRedirect} className={`${style.button} ${style.elite}`}>
                                                                    <Image src={elliteIcon} />
                                                                    <p className={`${style.eliteText }`}>Upgrade Now</p>
                                                                </div>
                                                            </li>
                                                    } */}
                                                    <li className={style.profileContainer}>
                                                        <div className={style.divider}></div>
                                                        {
                                                            getUserData().image &&
                                                            <Image
                                                                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + getUserData().image}
                                                                className={style.profileImage}
                                                            />
                                                        }
                                                        <p className={style.name} onClick={toggleDropdown}>{getUserData().name.slice(0, 10)+`${getUserData().name?.length > 10 ? "..." : ""}`}</p>
                                                        <div className={`${style.dropdown} ${isOpen ? style.active : ""}`} ref={dropdownRef}>
                                                            <Image
                                                                src={dropdownImage}
                                                                className={`${style.dropdownImage} ${style.dropdownTrigger}`}
                                                                onClick={toggleDropdown}
                                                            />
                                                            {isOpen && (
                                                                <div className={style.dropdownContent} style={{ width: '184px' }}>
                                                                    {
                                                                        getUserToken() && getUserData()?.status === "Active" ? (
                                                                            <ul className={style.profileMenuContainer}>
                                                                                <li onClick={() => {
                                                                                    navigation("/myaccount")
                                                                                }}>
                                                                                    <Image src={menuAccountIcon} />
                                                                                    <p className={style.menuTitle}>My Account</p>
                                                                                </li>
                                                                            </ul>
                                                                        ) :
                                                                            <></>
                                                                    }
                                                                    <ul className={style.profileMenuContainer}>
                                                                        <li onClick={handleLogout}>
                                                                            <Image src={logoutIcon} />
                                                                            <p className={style.menuTitle}>Logout</p>
                                                                        </li>
                                                                    </ul>

                                                                </div>
                                                            )}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        <div className={style.navbarContainer}>
                                            <Image
                                                onClick={() => setIsDrawerOpen(true)}
                                                src={navbarImage}
                                            />
                                        </div>
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    )
}
