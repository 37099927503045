import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './Religiouspreferences.module.css';
import useApiCall from '../../../../../hooks/useApiCall';
import config from '../../../../../../../app.json';
import Button from '../../../../../../../ui/Button/Button';
import ProgressBar from '../../../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../../../ui/Image/Image';
import FloatingLabelDropdown from '../../../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserToken, isStringValid, uploadFile } from '../../../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import checkedIcon from "../../../../../assets/svg/Signup/checked.svg";
import uncheckedIcon from "../../../../../assets/svg/Signup/uncheck.svg";
import Success from '../Modal/Success';
import ModalBox from '../../../../../../../ui/Modal/ModalBox';
import { useNavigate } from 'react-router-dom';

export default function Religiouspreferences({familyDetailsError, handleShowSuccessModal, setViewDetailsModal}) {

  const navigation = useNavigate("")

  const { sendRequest: getMetaData } = useApiCall();
  const { sendRequest: getCommunityMeta } = useApiCall();

  const [ loader, setLoader ] = useState(false)

  const [ userData, setUserData ] = useState(null)
  const [ communityData, setCommunityData ] = useState(null)

  const [ validationError, setValidationError ] = useState("")
  const [ viewSuccessDetailsModal, setViewSuccessDetailsModal ] = useState(false)
 
  const [ countryData, setCountryData ] = useState([])
  const [ stateData, setStateData ] = useState([])
  const [ cityData, setCityData ] = useState([])

  const { sendRequest: getUserData } = useApiCall();
  const { sendRequest: getState } = useApiCall();
  const { sendRequest: getCity } = useApiCall();


  const [formData, setFormData] = useState({
    religion: '',
    community: [],
    raasi: [],
    star: [],
    gothram: [],
    chevvaiDosham: ""
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'preference/elite/read-meta',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        setUserData(data.data)
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'my-account/preference/read',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const { chevvai_dosam, community_detail, gothram_detail, raasi_detail, religion_detail, star_detail } = data.data.religious_detail

        setFormData({
          religion: religion_detail,
          community: community_detail,
          raasi: raasi_detail,
          star: star_detail,
          gothram: gothram_detail,
          chevvaiDosham: chevvai_dosam
        })
      }
    );
  }, []);

  useEffect(() => {
      getCommunityMeta({
          url: "communities-for-multiple-religion",
          method: 'POST',
          body: {
              "religion_token": extractToken( formData?.religion, "token"),
          }
      }, (data) => {
          setCommunityData(data.data); // Set work state data
      });
  }, [formData?.religion]);

  const handleInputChange = (event, inputType) => {
    if( event.target ) {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      if( inputType === "religion" ) {
        setFormData({
          ...formData,
          [inputType]: event,
          community: []
        });
      } else {
        setFormData({
          ...formData,
          [inputType]: event,
        });
      }
    }
  };

  const extractToken = (data, isToken) => {
    let token = []
    data?.length && data?.map(item=> {
      token.push( isToken ? item.token : item.value)
    })
    return token;
  }

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    useEffect(()=> {
      if (success) {
        handleShowSuccessModal()
      }
      if(error) {
        familyDetailsError(error)
      }
      setLoader(false)
    }, [success, error])

  const submitForm = async () => {
  
    // if (
    //   formData.religion.length &&
    //   formData.community.length &&
    //   formData.raasi.length &&
    //   formData.star.length &&
    //   formData.gothram.length &&
    //   formData.chevvaiDosham.length
    // ) {
      setLoader(true)
      setValidationError("")

      const dataToSend = {
        "religion": extractToken(formData.religion, "token"),
        "community": extractToken(formData.community, "token"),
        "raasi": extractToken(formData.raasi, "token"),
        "star": extractToken(formData.star, "token"),
        "gothram": extractToken(formData.gothram, "token"),
        "chevvai_dosam": formData.chevvaiDosham,
      };

      uploadUserDetails({
        url: "my-account/preference/update/religious-detail",
        method: 'POST',
        body: dataToSend,
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
    });
      setLoader(false)
    // } else {
    //   setValidationError("Some required fields are missing.")
    // }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <>
      <ModalBox
        open={viewSuccessDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success
            title={"Religion Details updated successfully"} 
            description={""} 
            buttonText={"Okay"}
            onClick={()=>{
              addScroll()
              setViewSuccessDetailsModal(false)
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Religion preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Religion"
                name="religion"
                responseType="token"
                options={userData?.religion} // Add options
                value={formData.religion}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Community"
                name="community"
                responseType="token"
                options={communityData} // Add options
                value={formData.community}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Raasi"
                name="raasi"
                responseType="token"
                options={userData?.rasi} // Add options
                value={formData.raasi}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
            </div>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Star"
                name="star"
                responseType="token"
                options={userData?.star} // Add options
                value={formData.star}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Gothram"
                name="gothram"
                responseType="token"
                options={userData?.gothram} // Add options
                value={formData.gothram}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <div className={style.hobbieContainer}>
                <div style={{ maxWidth: "342px" }} className={style.field}>
                  <p className={style.fieldTitle}>Chevvai Dosam</p>
                  <div className={style.fieldValue}>
                    {renderRadioButtons('chevvaiDosham', userData?.chevvai_dosam)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
        <div className={style.footer}>
          <Button
            className={`${style.submitButton} primaryButton cancelButton`}
            onClick={() => {
              addScroll();
              setViewDetailsModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={() => {
              addScroll();
              submitForm()
            }}
            loading={isLoading}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </>
  );
}
