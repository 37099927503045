import React, { useState, useEffect } from "react"
import classes from "./ViewDocument.module.css"
import Image from "../Img/Img"
import PdfViewer from "../PDFViewer/PdfViewer"
import Loader from "../../UI/Loader/Loader"
import ReusableModal from "../../UI/Modal/ScrollingModal/ScrollingModal"

const ViewDocument = ({ title, data, small }) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, [data])

  const handlePdfView = documentUrl => {
    setSelectedDocument(documentUrl)
    setOpenPopup(true)
  }

  const isImage = url => {
    return url && url.toLowerCase().endsWith(".pdf")
    // const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp']; // Add more image extensions if needed
    // const fileExtension = url.split('.').pop().toLowerCase();
    // return imageExtensions.includes(fileExtension);
  }

  if (!data || !data.qualifications || !Array.isArray(data.qualifications)) {
    return null // or return a message or component indicating no data
  }
  return (
    <div className={classes.qualification_set}>
      <h3 className={classes.titel}>{title}</h3>
      <div className={classes.three_culome_set}>
        {data.qualifications.map((item, index) => (
          <div key={index}>
            <p className={classes.qualification_titel}>Qualification</p>
            <div className={classes.three_culome}>
              <div className={classes.contant_box}>
                <p className={classes.contant_box_titel}>College Name</p>
                <p className={classes.contant_box_sub}>{item.college_name}</p>
              </div>
              <div className={classes.contant_box}>
                <p className={classes.contant_box_titel}>
                  Qualification Details
                </p>
                <p className={classes.contant_box_sub}>
                  {item.qualification_detail}
                </p>
              </div>
            </div>
            <div
              className={`${
                small ? classes.viewdocumentionsumall : classes.viewdocument_img
              }`}
            >
              {isLoading ? (
                <Loader />
              ) : isImage(item.certificate_url) ? (
                <PdfViewer fileUrl={item.certificate_url} />
              ) : (
                <Image src={item.certificate_url} alt="document" />
              )}
            </div>
            {isLoading ? (
              <p className={classes.viewdocument_text}>Loading...</p>
            ) : isImage(item.certificate_url) ? (
              <a
                href="#"
                className={classes.viewdocument_text}
                onClick={() => handlePdfView(item.certificate_url)}
              >
                View Document
              </a>
            ) : (
              <a
                href="#"
                className={classes.viewdocument_text}
                onClick={() => handlePdfView(item.certificate_url)}
              >
                View Document
              </a>
            )}
          </div>
        ))}
        <ReusableModal
          open={openPopup}
          setOpen={setOpenPopup}
          scrollType="body"
          size="sm"
          pdfViewer="pdf"
        >
          {selectedDocument &&
            (isLoading ? (
              <div className={classes.loader}>Loading...</div>
            ) : isImage(selectedDocument) ? (
              <PdfViewer fileUrl={selectedDocument} />
            ) : (
              <Image
                src={selectedDocument}
                alt="document"
                className={classes.img__lightbox}
              />
            ))}
        </ReusableModal>
      </div>
    </div>
  )
}

export default ViewDocument
