import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components';
import CustomButton from '../../Button/Button';
import { makeStyles } from '@mui/styles';

const CenteredTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  color: #090909;
  font-family: 'Semibold';
  padding: 1rem 0rem;
  margin-top: 0.8rem;
`;

export default function ReusableModal({
    title,
    children,
    open = false,
    setOpen,
    scrollType = "paper",
    onCloseFunction,
    handleSubmit = () => { },
    type,
    okay,
    size,
    pdfViewer
}) {
    const customAdornmentStyle = {
        borderTop: 'unset',
        borderBottom: 'unset',
        padding: '5px 40px',
    };

    const pdfCustomeStyle = {
        borderTop: 'unset',
        borderBottom: 'unset',
        // padding: '5px 10px',
    };

    const customDialogActionsStyle = {
        padding: '10px 40px',
    };

    const descriptionElementRef = React.useRef(null);

    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    const handleClose = () => {
        console.log("handleClose");
        setOpen(false); // Close the modal
        if (onCloseFunction) {
            onCloseFunction(); // Call the additional function passed through props
        }
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth={size}
                open={open}
                onClose={handleClose}
                scroll={scrollType}
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
            >
                {pdfViewer === "pdf" ? <p style={{ padding: "4px" }}></p> : <CenteredTitle id='scroll-dialog-title'>{title}</CenteredTitle>}

                <DialogContent dividers={scrollType === 'paper'} style={pdfViewer === "pdf" ? pdfCustomeStyle : customAdornmentStyle}>
                    <div id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
                        {children}
                    </div>
                </DialogContent>
                {pdfViewer === "pdf" ? <p style={{ padding: "4px" }}></p> : <DialogActions style={customDialogActionsStyle}>
                    <div className='dialogActions_btn'>
                        <CustomButton className={okay === 'ok' ? 'secondary_btn' : "primary_btn"} children={okay === 'ok' ? 'Okay' : "Cancel"} onClick={handleClose} />
                        {type === 'view' ? " " : <CustomButton className="secondary_btn" children="Submit" onClick={handleSubmit} />}
                    </div>
                </DialogActions>}

            </Dialog>
        </>
    );
}
