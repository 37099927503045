import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './Feedback.module.css';
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';
import Button from '../../../../ui/Button/Button';
import ProgressBar from '../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../ui/Image/Image';
import uploadIcon from '../../assets/common/Upload Image.svg';
import uploadWhiteIcon from '../../assets/common/upload-white.svg';
import deleteIcon from '../../assets/common/Bin.svg';
import PopoutInput from '../../../../ui/PopoutInput/PopoutInput';
import PopoutDatePicker from '../../../../ui/PopoutDatePicker/PopoutDatePicker';
import FloatingLabelDropdown from '../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../assets/common/Radiobutton_on.svg"
import PopoutTextarea from '../../../../ui/PopoutTextarea/PopoutTextarea';
import { getUserToken, uploadFile, getUserData, defaultDate, formatMuiDate, isStringValid } from '../../../../utils/utils';
import dayjs, { Dayjs } from 'dayjs';
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import backArrowIcon from "./assets/backarrow.svg"
import { useNavigate } from 'react-router-dom';

export default function Feedback() {

    const navigation = useNavigate();

  const { sendRequest: getMetaData } = useApiCall();
  const uploadVideoInput = useRef(null);

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();
  const [uploadedImages, setUploadedImages] = useState([]);
  const uploadInputs = useRef([]);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFile, setVideoFile] = useState("test.mp4");

  const [ motherTongueData, setMotherTongueData ] = useState([])
  const [ heightData, setHeightData ] = useState([])
  const [ citizenshipData, setCitizenshipData ] = useState([])
  const [ loader, setLoader ] = useState(false)

  const [ validationError, setValidationError ] = useState("")

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    category: "",
    priority: "",
    feedback: ""
  });

  const [ listingData, setListingData ] = useState(null)

  const { sendRequest: getUserDetails } = useApiCall();

  useEffect(() => {
    getMetaData(
      {
        url: 'footer/feedback/get-meta',
        method: 'GET',
      },
      (data) => {
        console.log("data", data);
        setListingData(data?.data)
      }
    );
  }, []);
  

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log("value", value);
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (success) {
      setLoader(false)
    }
    if (error !== '') {
    }
  }, [success, error]);

  const submitForm = async () => {
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    };

    const { name, email, mobile, category, priority, feedback } = formData;
  
    if (
      name.length &&
      email.length && // Check if email address is provided
      validateEmail(email) && // Validate email format
      mobile &&
      category &&
      priority &&
      feedback
    ) {
      setLoader(true)

      uploadUserDetails({
        url: "footer/feedback/create",
        method: 'POST',
        body: {
          "name": name.trim(),
          "email": email.trim(),
          "mobile_number": mobile.trim(),
          "category": category,
          "priority": priority,
          "feedback": feedback.trim()
        },
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      })
    } else {
      if( !validateEmail(formData.emailAddress) ) {
        setValidationError("Some required fields are missing. The email address is invalid")
      } else {
        setValidationError("Some required fields are missing.")
      }
    }
  };

  return (
    <div className={style.buildProfileContainer}>
        <div className={style.logoContainer}>
          <Image 
            src={logoImage}
            className={style.logo}
          />
        </div>
        <div className={`${style.workDetailsContainer}`}>
            <div className={style.formContainer}>
                <div className={style.titleBackArrowContainer}>
                    <Image 
                        onClick={()=> navigation(-1)}
                        src={backArrowIcon}
                        className={style.backArrow}
                    />
                    <p className={style.formTitle}>Feedback</p>
                </div>
                <p className={style.description}>Please feel free to post your questions, comments and suggestions. We are eager to assist you and serve you better.</p>
                <div className={style.hobbiesContainer}>
                <div className={style.hobbieContainer}>
                    <PopoutInput
                      label="Your Name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      style={{
                          paddingBottom: '26px',
                      }}
                    />
                    <PopoutInput
                      label="Phone Number"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      style={{
                          paddingBottom: '26px',
                      }}
                    />
                    <FloatingLabelDropdown
                      label="Priority"
                      name="priority"
                      responseType="value"
                      options={listingData?.priority} // Add options
                      value={formData.priority}
                      onChange={handleInputChange}
                      style={{
                          paddingBottom: '26px',
                      }}
                    />
                </div>
                <div className={style.hobbieContainer}>
                    <PopoutInput
                      label="Email Address"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      style={{
                          paddingBottom: '26px',
                      }}
                    />
                    <FloatingLabelDropdown
                      label="Category"
                      name="category"
                      responseType="value"
                      options={listingData?.category} // Add options
                      value={formData.category}
                      onChange={handleInputChange}
                      style={{
                          paddingBottom: '26px',
                      }}
                    />
                </div>
                </div>
            </div>
            <PopoutTextarea 
                characterLimit={255}
                label="Suggestions / Feedback"
                name="feedback"
                className={style.moreAbout}
                value={formData.feedback}
                onChange={handleInputChange}
            />
            {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
            <Button
                className={`primaryButton ${style.submitButton}`}
                onClick={submitForm}
                loading={isLoading || loader}
            >
                Submit
            </Button>
            </div>
      </div>
  );
}
