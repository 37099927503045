import React, { useEffect, useState } from 'react'
// import Masage from '../../UI/ErrorPage/Masage'
import classes from './ManageNotification.module.css'
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import Table from "../../UI/Table/TablePage";
import TableHeader from "../../UI/Table/TableHeader";
import Search from '../../UI/Search/Search';
import Button from '../../UI/Button/Button';
import useApiHttp from '../../hooks/ues-http';
import { BASE_URL } from '../../utils/baseUrl';
import Modal from '../../UI/Modal/Modal';
import TextField from './Textarea'
import TextInput from '../../UI/Textarea/Input';
import { RadioButton } from '../../UI/RadioButton/RadioButton'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ReusableModal from '../../UI/Modal/ScrollingModal/ScrollingModal';
import CheckBox from '../../UI/Checkbox/CheckBox';
import Swal from 'sweetalert2';
import { AnimatePresence , motion} from 'framer-motion';


const ManageNotification = () => {
    // Initialize error state
    const [errors, setErrors] = useState({
        verifidUser: false,
        title: false,
        description: false,
    });

    const [charsLeft, setCharsLeft] = useState(0);
    // const [verifidUser, setVerifidUser] = useState("");
    const [notificationData, setNotificationData] = useState([]);
    const [notificationView, setNotificationView] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [secondOpenPopup, setSecondOpenPopup] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);
    const [formData, setFormData] = useState({
        title: "",
        description: "",
    });

    const [notificationDataTotal, setNotificationDataTotal] = useState(0);
    const [iTotalDisplayRecords, setITotalDisplayRecords] = useState(0);
    const [notificationSearch, setNotificationSearch] = useState('');
    const [sortDirectionData, setSortDirectionData] = useState('DESC');
    const [column, setColumn] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);

    const [selectedItems, setSelectedItems] = useState([]);

    console.log("selectedItems", selectedItems);
    const { isLoading: notificationLoding, success: notificationSucces, error: notificationError, sendRequest: notificationRequest } = useApiHttp();

    const notificationDatas = (data) => {
        setNotificationData(data.aaData)
        setNotificationDataTotal(data.iTotalRecords)
        setITotalDisplayRecords(data.iTotalDisplayRecords)
    };

    const notificationRequestData = () => {
        const requestData = {
            search_value: notificationSearch,
            page: page,
            per_page: perPage,
            sort_column: column,
            sort_dir: sortDirectionData,

        };

        notificationRequest({
            url: `${BASE_URL}list-notification`,
            method: 'POST',
            body: requestData,
        }, notificationDatas);
    };
    useEffect(() => {
        notificationRequestData()
    }, [notificationSearch, page, perPage, column, sortDirectionData]);


    const handleSearchChange = (newValue) => {
        setNotificationSearch(newValue)
    };

    const columns = [
        {
            name: 'S.No',
            selector: (row) => row.slno,
            sortable: false,
            sortField: 0,
        },
        {
            name: 'Notification ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => addNewNotificationSecond(row.token)}>{row.token}</span></>),
            sortable: true,
            sortField: 1,
        },
        {
            name: 'Title',
            selector: (row) => (<p className={classes.text_wrop}>{row.title}</p>),
            sortable: true,
            sortField: 2,
        },
        {
            name: 'Description',
            selector: (row) => (<p className={classes.text_wrop}>{row.description}</p>),
            sortable: true,
            sortField: 3,
        },
        {
            name: 'To',
            selector: (row) => row.to,
            sortable: true,
            sortField: 4,
        },
        {
            name: 'Payment Date',
            selector: (row) => row.date,
            sortable: true,
            sortField: 5,
        },
        {
            name: "",
            cell: (row) => (<span className='action_text action_text_pointer' onClick={() => roleDelete(row.token)}>Delete</span>),
            sortable: false,
        },

    ];


    const addNewNotification = () => {
        setOpenPopup(true)
    }

    const addNewNotificationSecond = (data) => {
        setSecondOpenPopup(true)
        ViewNotificationRequestData(data)

    }

    // const radioChangeHandler = (e) => {
    //     setVerifidUser(e.target.value);
    // };


    const handleSort = async (column, sortDirection) => {
        setSortDirectionData(sortDirection || 'DESC');
        setColumn(column.sortField || 0);

    };
    const handlePageChange = (page) => {
        setPage(page || 1)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage || 10);
    };

    const { isLoading: viewNotificationLoding, success: ViewNotificationSucces, error: ViewNotificationError, sendRequest: ViewNotificationRequest } = useApiHttp();

    const ViewNotificationDatas = (data) => {
        setNotificationView(data.data)
    };

    const ViewNotificationRequestData = (token) => {
        const requestData = {
            token: token,
        };

        ViewNotificationRequest({
            url: `${BASE_URL}view-notification`,
            method: 'POST',
            body: requestData,
        }, ViewNotificationDatas);
    };


    const handleChange = (event) => {
        // const { name, value } = event.target;
        // setFormData({ ...formData, [name]: value });
        const { name, value } = event.target;
        const charCount = value.length;
        const maxChar = 255;
        // Limit the character count to 200 characters
        const truncatedValue = value.slice(0, maxChar);
        setFormData({ ...formData, [name]: truncatedValue });
        const charLength = Math.min(maxChar, charCount); // Ensures the count doesn't exceed 200
        setCharsLeft(charLength);

    };

    const { isLoading: createNotificationLoding, success: createNotificationSucces, error: createNotificationError, sendRequest: createNotificationRequest } = useApiHttp();
    useEffect(() => {
        if (createNotificationSucces !== '') {
            toast.success(createNotificationSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
            setOpenPopup(false)
        }
        if (createNotificationError !== '') {
            toast.error(createNotificationError, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [createNotificationSucces, createNotificationError]);

    const createNotificationDatas = (data) => {
        // setVerifidUser("")
        setFormData({
            ...formData,
            title: "",
            description: "",
        })
        setErrors({
            ...errors,
            verifidUser: false,
            title: false,
            description: false,
        })
        setCharsLeft(0)
        setSelectedItems([])
        notificationRequestData()
    };

    const createNotificationRequestData = () => {
        const requestData = {
            "title": formData.title,
            "description": formData.description,
            "type": selectedItems

        };

        createNotificationRequest({
            url: `${BASE_URL}create-notification`,
            method: 'POST',
            body: requestData,
        }, createNotificationDatas);
    };
    const addNewNotificationSubmit = () => {
        let hasError = false;
        const newErrors = {
            verifidUser: false,
            title: false,
            description: false,
        };

        if (formData.title === '') {
            newErrors.title = true;
            hasError = true;
        }

        if (formData.description === '') {
            newErrors.description = true;
            hasError = true;
        }

        if (selectedItems.length === 0) {
            newErrors.verifidUser = true;
            hasError = true;
        }

        if (!hasError) {
            createNotificationRequestData();
        } else {
            setErrors({ ...newErrors });
        }
    }

    const onCloseFunctionsecond = () => {
        // setVerifidUser("")
        setErrors({
            ...errors,
            verifidUser: false,
            title: false,
            description: false,
        })

        setFormData({
            ...formData,
            title: "",
            description: "",
        })
        setCharsLeft(0)
        setSelectedItems([])
    }

    // Function to handle checkbox toggle
    const handleCheckboxToggle = (item) => {
        if (selectedItems.includes(item)) {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem !== item));
        } else {
            setSelectedItems([...selectedItems, item]);
        }
    };

     // deleted add user 
   const { isLoading: deleteRoleLoding, success: deleteRoleSuccess, error: deleteRoleError, sendRequest: deleteRoleRequest } = useApiHttp();
   useEffect(() => {
       if (deleteRoleSuccess !== '') {
           toast.success(deleteRoleSuccess, {
               position: toast.POSITION.TOP_RIGHT,
           })

       }
       if (deleteRoleError !== '') {
           toast.error(deleteRoleError, {
               position: toast.POSITION.TOP_RIGHT,
           });
       }
   }, [deleteRoleSuccess, deleteRoleError]);
   const deleteRoleData = (data) => {
         notificationRequestData()
   };

   const deleteRoleRequestData = (token) => {
       const requestData = {
        token: token,
       };

       deleteRoleRequest({
           url: `${BASE_URL}delete-notification`,
           method: 'POST',
           body: requestData,
       }, deleteRoleData);
   };

    const roleDelete = (token) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#EF7435',
            cancelButtonColor: '#aeaeae',
            confirmButtonText: 'Delete it!',
            customClass: {
                title: 'your-custom-title-class',
                content: 'your-custom-content-class',
                confirmButton: 'your-custom-button-class',
                cancelButton: 'your-custom-button-class',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRoleRequestData(token)
            }
        })}
        const tabAnimation = {
            initial: {
              y: -200,
              opacity: 0,
            },
            animate: {
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.5,
                staggerChildren: 0.1,
              },
            },
            exit: {
                y: -200,
                opacity: 0,
            }
          };
    return (
        <AnimatePresence>
            <div>

                <PagesHeader title="Manage Notifications" />

                <section className='tablesection_heder'>
                    <div className="header_btn_gap">
                        <Button className={classes.login_btn} onClick={addNewNotification}>+ Add New</Button>
                    </div>

                    <motion.dev 
                        variants={tabAnimation}
                        initial="initial"
                        animate="animate"
                        exit="exit">
                      <Table data={notificationData} onSort={handleSort} columns={columns} loader={notificationLoding} paginationTotalRows={notificationDataTotal} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange}
                        children={
                            <div className="header_table">
                                <TableHeader tableheader="Notification List" totalusers={`${notificationDataTotal} total notifications`} />
                                <Search value={notificationSearch} onChange={(newValue) => handleSearchChange(newValue)} type='text' placeholder='Search' />
                            </div>
                        } />
                    </motion.dev>
                </section>
            </div>
            <>


                <Modal openPopup={openPopup} setOpenPopup={setOpenPopup} titel="Add New" size="lg" handleSubmit={addNewNotificationSubmit} onCloseFunction={onCloseFunctionsecond}>
                    <>
                        <div className="mrg_top_poppup">
                            <p className={classes.module_text}>Choose Module</p>

                            <div className={classes.radio_btn_set}>
                                <CheckBox label={"Elite"} checked={selectedItems.includes("elite")}
                                    onChange={() => handleCheckboxToggle("elite")} />
                                <CheckBox label={"Premium"} checked={selectedItems.includes("premium")}
                                    onChange={() => handleCheckboxToggle("premium")} />
                                <CheckBox label={"Basic"} checked={selectedItems.includes("basic")}
                                    onChange={() => handleCheckboxToggle("basic")} />
                            </div>
                            {errors.verifidUser && <span className={`${classes.absolute} text_danger marg_top_5rem`}>Please choose Module !</span>}
                            <div className={classes.marg_input_filed}>
                                <TextInput label="Title" name="title" value={formData.title} onChange={handleChange} />
                                {errors.title && <span className={`${classes.absolute} text_danger marg_top_5rem`}>Please Enter Title !</span>}
                            </div>
                            <div className={classes.marg_input_filed}>
                                <TextField
                                    maxLength="255"
                                    label="Description"
                                    title="Description"
                                    name="description"
                                    rows={4}
                                    value={formData.description}
                                    placeholder="Enter Description"
                                    showpertext={`${charsLeft}/255`}
                                    onChange={handleChange} />
                                {errors.description && <span className={`${classes.absolute} text_danger`}>Please Enter Description !</span>}
                            </div>

                        </div>

                    </>
                </Modal>


                <Modal openPopup={secondOpenPopup} setOpenPopup={setSecondOpenPopup} titel={notificationView.token} size="lg" handleSubmit={addNewNotification} type="view" okay="ok">
                    <>
                        <div className={classes.contaniner__box_contioner}>
                            <div className={classes.contaniner__box_set}>
                                <p className={classes.contant_box_titel}>User Type</p>
                                <p className={classes.contant_box_sub}>{notificationView.type}</p>
                            </div>
                            <div className={classes.contaniner__box_set}>
                                <p className={classes.contant_box_titel}>Title</p>
                                <p className={classes.contant_box_sub}>{notificationView.title}</p>
                            </div>
                        </div>

                        <div className={classes.contaniner__box_set}>
                            <p className={classes.contant_box_titel}>Plan Benefits</p>
                            <p className={classes.contant_box_sub}>{notificationView.description}</p>
                        </div>

                    </>
                </Modal>
                <ToastContainer />
            </>
        </AnimatePresence>
    )
}

export default ManageNotification