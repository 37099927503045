import { Route, BrowserRouter, Routes } from "react-router-dom";
import LandingPage from "../modules/web/pages/LandingPage/LandingPage";
import Header from "../modules/web/features/Header/Header";
import Footer from "../modules/web/features/Footer/Footer";
import ApplicationStatus from "../modules/web/pages/ApplicationStatus/ApplicationStatus";
import SubscribePage from "../modules/web/pages/SubscribePage/SubscribePage";
import BuildProfile from "../modules/web/pages/BuildProfile/BuildProfile";
import PartnerPreference from "../modules/web/pages/PartnerPreference/PartnerPreference";
import Homepage from "../modules/web/pages/Homepage/Homepage";
import ProfileDetails from "../modules/web/pages/ProfileDetails/ProfileDetails";
import Matches from "../modules/web/pages/Matches/Matches";
import PremiumPartnerPreference from "../modules/web/pages/PremiumPartnerPreference/PremiumPartnerPreference";
import Search from "../modules/web/pages/Search/Search";
import MailBox from "../modules/web/pages/MailBox/MailBox";
import Feedback from "../modules/web/pages/Feedback/Feedback";
import { Notifications } from "../modules/web/pages/Notifications/Notifications";
import MyAccount from "../modules/web/pages/MyAccount/MyAccount";
import { Chat } from "../modules/web/pages/Chat/Chat";
import ShareStorySubmit from "../modules/web/pages/ShareStorySubmit/ShareStorySubmit";
import CeleberateMatch from "../modules/web/pages/CeleberateMatch/CeleberateMatch";
import { CoupleStory } from "../modules/web/pages/CoupleStory/CoupleStory";
import { CoupleInterview } from "../modules/web/pages/CoupleInterviews/CoupleInterview";
import Suggetions from "../modules/web/pages/Suggetions/Suggetions";
import DiscoverMatches from "../modules/web/pages/DiscoverMatches/DiscoverMatches";
import { TermsAndConditions } from "../modules/web/pages/TermsAndConditions/TermsAndConditions";
import { PrivacyPolicy } from "../modules/web/pages/PrivacyPolicy/PrivacyPolicy";
import { AboutUs } from "../modules/web/pages/ContactUs/AboutUs";
import { Faq } from "../modules/web/pages/Faq/Faq";
import { BasicUserRedirection } from "../modules/web/pages/BasicUserRedirection/BasicUserRedirection";

import { Chat1 } from "../modules/web/pages/Chat/Chat1";
import { ApplicationRejected } from "../modules/web/pages/ApplicationRejected/ApplicationRejected";
import { useEffect } from "react";
import { ApplicationExpired } from "../modules/web/pages/ApplicationExpired/ApplicationExpired";
import { AccountDeletion } from "../modules/web/pages/AccountDeletion/AccountDeletion";

export default function Web() {

    // Get the URL parameters
    const urlParams = window?.location?.pathname;

    return (
        <BrowserRouter>
            {
                urlParams !== "/terms_and_conditions_app" &&
                urlParams !== "/privacy_policy_app" &&
                urlParams !== "/faq_app" &&
                urlParams !== "/about_us_app" &&
                urlParams !== "/account_deletion" ?
                    <Header />
                :
                    <></>
            }
            <main>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/status" element={<ApplicationStatus />} />
                    <Route path="/subscribe" element={<SubscribePage />} />
                    <Route path="/build_profile" element={<BuildProfile />} />
                    <Route path="/premium_partner_preference" element={<PremiumPartnerPreference />} />
                    <Route path="/partner_preference" element={<PartnerPreference />} />
                    <Route path="/home" element={<Homepage />} />
                    <Route path="/profile_details" element={<ProfileDetails />} />
                    <Route path="/matches" element={<Matches />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/mailbox" element={<MailBox />} />
                    <Route path="/feedback" element={<Feedback />} />
                    <Route path="/notifications" element={<Notifications />} />
                    <Route path="/myaccount" element={<MyAccount />} />
                    <Route path="/chat" element={<Chat1 />} />
                    <Route path="/share_your_story" element={<ShareStorySubmit />} />
                    <Route path="/celeberate_match" element={<CeleberateMatch />} />
                    <Route path="/success_stories" element={<CoupleStory />} />
                    <Route path="/couple_interviews" element={<CoupleInterview />} />
                    <Route path="/suggetions" element={<Suggetions />} />
                    <Route path="/discover_matches" element={<DiscoverMatches />} />
                    <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
                    <Route path="/privacy_policy" element={<PrivacyPolicy />} />
                    <Route path="/about_us" element={<AboutUs />} />
                    <Route path="/faq" element={<Faq />} />
                    <Route path="/terms_and_conditions_app" element={<TermsAndConditions />} />
                    <Route path="/privacy_policy_app" element={<PrivacyPolicy />} />
                    <Route path="/about_us_app" element={<AboutUs />} />
                    <Route path="/faq_app" element={<Faq />} />
                    <Route path="/403" element={<BasicUserRedirection />} />
                    <Route path="/application_rejected" element={<ApplicationRejected />} />
                    <Route path="/expired" element={<ApplicationExpired />} />
                    <Route path="/account_deletion" element={<AccountDeletion />} />
                </Routes>
            </main>
            {
                urlParams !== "/terms_and_conditions_app" &&
                urlParams !== "/privacy_policy_app" &&
                urlParams !== "/faq_app" &&
                urlParams !== "/about_us_app" &&
                urlParams !== "/status" &&
                urlParams !== "/account_deletion" ?
                    <Footer />
                :
                    <></>
            }
        </BrowserRouter>
    );
}