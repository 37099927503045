
import { useEffect, useState } from 'react';

const useAuthentication = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    
    // Simulate authentication (replace this with your actual authentication logic)
    const checkAuthentication = () => {
        const userLoggedIn = localStorage.getItem("token") ? true : false
        setIsAuthenticated(userLoggedIn);
    };

    // Call the authentication check function when the component mounts
    useEffect(() => {
        checkAuthentication();
    }, []);

    return {
        isAuthenticated
    };
};

export default useAuthentication;
