import React, { useState, useEffect } from 'react';
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from "react-router-dom";
import TextInput from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import logo from '../../assets/SVG/Login/Logo.svg';
import FromHeader from './FromHeader';
import Otp from './Otp/Otp';
import useHttp from '../../hooks/ues-http'
import { BASE_URL } from '../../utils/baseUrl';
import Classes from './Forgot.module.css';
import Image from '../../UI/Img/Img';
import successfully from '../../assets/SVG/common/successfully.svg'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { LoginContext } from '../Login/Context/CreatContext'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    FormControl: {
        fontFamily: 'Regular',
    },
    InputLabel: {
        fontSize: '14px',
        fontFamily: 'Regular',
    },
    OutlinedInput: {
        height: '52px',
        fontFamily: 'Regular',
        fontSize: '14px',
    },
});

const Forgot = () => {
    const classesinput = useStyles();

    const navigate = useNavigate()
    const forgotMailErrorState = {
        email: { required: false, invalidFormat: false },
    };

    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [forgotMail, setForgotMail] = useState(true)
    const [otpVerify, setOtpVerify] = useState(false)
    const [newPassword, setnewPassword] = useState(false)
    const [successMsg, setSuccessMsg] = useState(false)
    // input state 
    const [forgotMailError, setForgotMailError] = useState(forgotMailErrorState);
    const [enteredEmail, setEnteredEmail] = useState({ email: '' });
    // update Pasword state
    const [uptatePassword, setUptatePassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [confirmPasswordError, setConfirmPasswordError] = useState(false)


    const { isLoading: sendOtpLoding, success: sendOtpSuccess, error: sendOtpError, sendRequest: sendOtpRequest } = useHttp();
    useEffect(() => {
        if (sendOtpSuccess !== '') {
            toast.success(sendOtpSuccess, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (sendOtpError !== '') {
            toast.error(sendOtpError, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [sendOtpSuccess, sendOtpError]);

    let emailStore = enteredEmail.email


    const sendOtpSubimtHandling = (e) => {
        e.preventDefault();
        let error = forgotMailErrorState;
        let hasErrore = false;

        if (enteredEmail.email === '') {
            error.email.required = true
            hasErrore = true
        } else if (!/^\S+@\S+\.\S+$/.test(enteredEmail.email)) {
            error.email.invalidFormat = true
            hasErrore = true
        }

        if (!hasErrore) {
            const VerifyEmailData = (data) => {
                setForgotMail(false)
                setOtpVerify(true)
            }

            sendOtpRequest({
                url: `${BASE_URL}send-otp`,
                method: 'POST',
                body: {
                    email_id: enteredEmail.email,
                },
            }, VerifyEmailData);


        }

        setForgotMailError({ ...error })
    }
    const forgotMailHandler = (e) => {
        setEnteredEmail({ ...enteredEmail, [e.target.name]: e.target.value });
    };

    // Uptate Password 
    const { isLoading: passwordChangeLoding, success: passwordChangeSuccess, error: passwordChangeError, sendRequest: passwordChangedata } = useHttp();
    useEffect(() => {
        if (passwordChangeSuccess !== '') {
            toast.success(passwordChangeSuccess, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (passwordChangeError !== '') {
            toast.error(passwordChangeError, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [passwordChangeSuccess, passwordChangeError]);


    const passwordChangeHandle = (e) => {
        e.preventDefault();
        // Password validation criteria
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

        if (!passwordRegex.test(uptatePassword)) {
            setNewPasswordError(true);
        } else if (uptatePassword !== confirmPassword) {
            setNewPasswordError(false); // Clear any previous error
            setConfirmPasswordError(true)
        } else {
            setNewPasswordError(false); // Clear any previous error

            const VerifyEmailData = (data) => {
                setnewPassword(false);
                setSuccessMsg(true);

                setTimeout(() => {
                    navigate('/admin');
                    setSuccessMsg(false);
                }, 3000); // (3 seconds)
            }

            passwordChangedata({
                url: `${BASE_URL}change-forgot-password`,
                method: 'POST',
                body: {
                    email_id: emailStore,
                    password: uptatePassword,
                    confirm_password: confirmPassword,
                },
            }, VerifyEmailData);
        }
    }

    const goLogin = () => {
        navigate('/admin')
        setSuccessMsg(false)

    }
    const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    return (

        <LoginContext.Provider value={{ emailStore }}>
            <div className={Classes.login_page}>
                <div className={Classes.login_container}>
                    <div className={Classes.login_container_heder}>
                        <Image src={logo} alt='logo' className={Classes.login_logo} />
                    </div>
                    {forgotMail ?
                        <form id="Forgot" onSubmit={sendOtpSubimtHandling}>
                            <FromHeader className={Classes.login_titel} titel="Forgot Password" subtitel="Enter your registered email address. We’ll send a verification code to your registered email address" />

                            {forgotMailError.email.required ? (
                                <span className='text_danger'>Please enter valid email id !</span>
                            ) : forgotMailError.email.invalidFormat ? (
                                <span className='text_danger'>Please enter a valid email address!</span>
                            ) : null}

                            <div className={Classes["validate-input-forgot"]}>
                                <TextInput label="Email Address" name="email" onChange={forgotMailHandler}
                                />
                            </div>

                            <Button className={Classes.login_btn} disabled={sendOtpLoding}> {!sendOtpLoding ? "Send Otp" : (<span className={Classes.btn_spiner}><div className="spinner"></div>Send OTP</span>)}</Button>
                        </form>
                        : ''}

                    {otpVerify ? <Otp setOtpVerify={setOtpVerify} setnewPassword={setnewPassword} setForgotMail={setForgotMail} /> : ""}

                    {newPassword ?
                        <form id="PasswordChange" onSubmit={passwordChangeHandle}>
                            <FromHeader className={Classes.login_titel} titel="New Password" subtitel="Enter your new password" />

                            {newPasswordError ? <p className='text_danger'>Password must contain at least one uppercase letter, one lowercase letter, one digit, one special character, and be at least 8 characters long.</p> : ""}
                            {confirmPasswordError ? <p className='text_danger'>Passwords do not match</p> : ""}
                            <div className={Classes["validate-input"]}>

                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password" className={classesinput.InputLabel}>New Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-newpassword"
                                        type={showNewPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <span
                                                    className={Classes.visibility}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowNewPassword}
                                                    edge="end"
                                                >
                                                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                                </span>
                                            </InputAdornment>
                                        }
                                        label="New Password"
                                        value={uptatePassword}
                                        onChange={(e) => setUptatePassword(e.target.value)}
                                        required
                                        className={classesinput.OutlinedInput}
                                    />
                                </FormControl>
                            </div>

                            <div className={Classes["validate-input"]} style={{ padding: " 0px" }}>
                                <FormControl sx={{ m: 1, width: '100%' }} variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password" className={classesinput.InputLabel}>Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-confirmpassword"
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <span
                                                    className={Classes.visibility}
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </span>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                        className={classesinput.OutlinedInput}
                                    />
                                </FormControl>
                            </div>

                            <Button className={Classes.login_btn_two} type="submit">
                                {!passwordChangeLoding ? "Submit" : (<span className={Classes.btn_spiner}><div className="spinner"></div>Submit</span>)}
                            </Button>
                        </form>
                        : ""}

                    {successMsg ?
                        <div className={Classes.successfully}>
                            <div className={Classes.successfully_img}>
                                <Image src={successfully} alt="successfully" />
                            </div>
                            <FromHeader classe="sub_titel" className={Classes.login_titel} titel="Successfully Changed!" subtitel="Your password changed successfully," subcon="Login Now to continue to access your dashboard." />
                            <Button className={Classes.login_btn} onClick={() => goLogin()}>Login Now</Button>
                        </div> : ""}
                </div>

                <ToastContainer />
            </div>

        </LoginContext.Provider >


    );
}

export default Forgot;
