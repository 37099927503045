import React from 'react'
import classes from './Card.module.css'
import Image from '../Img/Img'
const Card = ({ src, title, subTitle, className }) => {
    return (
        <div className={className}>
            <div className={classes.card_box_icon}>
                <div className={classes.icon_circul}>
                    <Image src={src} alt="cardicon" />
                </div>
            </div>
            <p className={classes.title}>{title}</p>
            <p className={classes.Sub_title}>{subTitle}</p>
        </div>
    )
}

export default Card