import React, { useContext, useEffect, useRef, useState } from 'react';
import style from "./Otp.module.css";
import Button from '../../../../../ui/Button/Button';
import Image from '../../../../../ui/Image/Image';
import timerIcon from "../../../assets/common/Clock.svg"
import useApiCall from '../../../hooks/useApiCall'
import config from "../../../../../app.json"
import { SignUpContext } from '../Context/SignUpContext';
import { useNavigate } from 'react-router-dom';
import { addScroll, avoidScroll, formatMuiDate, formatYMDDate, getUserData, uploadFile } from '../../../../../utils/utils';
import { collection,doc,setDoc,getDoc,updateDoc,getDocs,query,where} from "firebase/firestore"; 
import ModalBox from '../../../../../ui/Modal/ModalBox';
import completedIcon from "../../../assets/common/Purchase Completed.svg"
import completedIconVector from "../../../assets/common/overallSuccessVector.svg"
import initializeFirebase from '../../../pages/Chat/Db/FirebaseDb'; 

export default function Otp() {

  const navigation = useNavigate();
  const {db} = initializeFirebase();
  const { setActiveStep, mobileNumber, handleCloseDrawer, signupData, setSignupData } = useContext(SignUpContext);
  const { name, email, gender, council_name, studied_year, user_image, country_code, dob, imr_number, certificate_url } = signupData;
  const [token, setToken] = useState('');
  const [viewOverallSuccessModal, setViewOverallSuccessModal] = useState(false);

  const { success: sendOtpSuccess, error: sendOtpError, sendRequest: sendOtp } = useApiCall();
  const { isLoading: isVerifyOtpLoading, success: verifyOtpSuccess, error: verifyOtpError, sendRequest: otpVerification } = useApiCall();
  const { isLoading: getUserLoading, success: getUserSuccess, error: getUserError, sendRequest: getUserDetails } = useApiCall();

  const [otp, setOtp] = useState(['', '', '', '']);
  const [otpError, setOtpError] = useState('');
  const [ loader, setLoader ] = useState(false)

  const inputRefs = [useRef(), useRef(), useRef(), useRef()];

  const [totalSeconds, setTotalSeconds] = useState(30); // 1 minute and 30 seconds

  useEffect(() => {
    const interval = setInterval(() => {
      if (totalSeconds > 0) {
        setTotalSeconds(totalSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [totalSeconds]);

  useEffect(() => {
    if (sendOtpSuccess) {
      setOtpError("")
      setLoader(false)
      setOtp(['', '', '', ''])
      setTotalSeconds(30); // Reset to 1 minute and 30 seconds
    }
    if (sendOtpError) {
      setLoader(false)
      setOtpError(sendOtpError)
    }
  }, [sendOtpSuccess, sendOtpError]);

  const resendOTP = async () => {
    sendOtp({
      url: "auth/register/send-otp",
      method: 'POST',
      body: {
        "country_code": country_code,
        "mobile_number": mobileNumber,
        "email": email.trim()
      },
    });
  };

  const handleChange = (index, value) => {
    if (!isNaN(value) && value !== '') {
      otp[index] = value;
      setOtp([...otp]);

      if (index < 3 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
      checkOtpValid() && setOtpError('');
    } else if (value === '' && index > 0) {
      otp[index] = '';
      setOtp([...otp]);
      inputRefs[index - 1].current.focus();
    } else if (value === '' && index === 0) {
      otp[index] = '';
      setOtp([...otp]);
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs[index - 1].current.focus();
    }
  };

  useEffect(() => {
    if (verifyOtpSuccess) {
      localStorage.setItem('subscriptionFromHome', 0)
      console.log("verifyOtpSuccess");
      // handleCloseDrawer()
      avoidScroll()
      // setSignupData("")
      // setActiveStep("form")
      setViewOverallSuccessModal(true)
    }
    if (verifyOtpError) {
      setOtpError(verifyOtpError)
    }
    setLoader(false)
  }, [verifyOtpSuccess, verifyOtpError, setActiveStep, token]);

  const verifyOtp = async() => {
    console.log("signupData", signupData);
    const otpValue = otp.join("")
    if (checkOtpValid()) {
      setLoader(true)
      let certificate = await uploadFile(certificate_url)
      let profileImage = await uploadFile(user_image[0])
      if( certificate && profileImage ) {
        otpVerification({
          url: "auth/register/verify-otp-and-register",
          method: 'POST',
          body: {
            "name": name.trim(),
            "dob": formatYMDDate(dob),
            "gender": gender,
            "country_code": country_code,
            "mobile_number": mobileNumber.trim(),
            "email": email.trim(),
            "council_name": council_name, 
            "year_of_reg": studied_year, 
            "user_image": [profileImage],
            "imr_number": imr_number.trim(),
            "certificate_url": certificate,
            "otp": otpValue,
            "device_type": null,
            "device_token": null
          },
        }, verificationData)
      }
    } else {
      console.log("else");
      setOtpError('Please fill all the fields.'); // Set error message if OTP is not valid
    }
  };

  const verificationData = (data) => {
    let { status, registeration_status, onboard_status, name, user_id, image, token, user_token } = data.data
    localStorage.setItem("token", token)
    setToken(token)
    localStorage.setItem('subscriptionFromHome', 0)
    localStorage.setItem("user", JSON.stringify(data.data))

    // switch (status) {
    //   case "Registering":
    //     setActiveStep("certificate");
    //     break;
    //   case "Pending":
    //     navigation("/status")
    //     break;
    //   case "Rejected":
    //     navigation("/status")
    //     break;
    //   case "Verified":
    //     navigation("/subscribe")
    //     break;
    //   case "Onboarding":
    //     navigation("/build_profile")
    //     break;
    //   case "Preferencing":
    //     navigation("/premium_partner_preference")
    //     break;
    //   case "Active":
    //     navigation("/home")
    //     break;
    // }
  }

  const checkOtpValid = () => {
    return otp?.every((digit) => !isNaN(digit) && digit !== '');
  }

  return (
    <div className={style.contentContainer}>
      <ModalBox
        open={viewOverallSuccessModal}
        width={"609px"}
        modalContent={
          <div className={style.overallRegisterContainer} style={{ backgroundImage: `url(${completedIconVector})` }}>
            <div className={`${style.registerSuccessContainer} ${style.overallSuccess}`}>
              <Image src={completedIcon} className={style.registerSuccessIcon}/>
              <p className={style.registerSuccessTitle}>Verified Successfully!</p>
              <p className={style.registerSuccessDescription}>Congratulations! Your details has been successfully verified. You're now ready to discover and connect with verified profiles. Start exploring now!</p>
              <div className={style.registerOkayButtonContainer}>
                <Button
                  style={{ width: "343px", cursor: "pointer", margin: "auto" }}
                  className={`primaryButton`}
                  onClick={()=> {
                    const { user_id, name, image, user_token, gender } = getUserData()
                    const checkUserQuery = doc(db, "Users", user_token);   
                    getDoc(checkUserQuery).then((userData)=>{
                      if(userData.exists()){
                          updateDoc(checkUserQuery,{
                            isOnline:true,
                          }).then(()=>{
                            addScroll()
                            setViewOverallSuccessModal(false)
                            handleCloseDrawer()
                            navigation("/home")
                          })
                      }else{
                          setDoc(checkUserQuery,{
                              image: image,
                              profile_id: user_id,
                              name: name,
                              blockList:[],
                              isOnline:true,
                              isDeleted: false
                          }).then(()=>{
                            addScroll()
                            setViewOverallSuccessModal(false)
                            handleCloseDrawer()
                            navigation("/home")
                          })
                      }
                    })
                  }}
                  // loading={signUpLoading}
                >
                  Okay
                </Button>
              </div>
            </div>
          </div>
        }
      />
      <p className={style.title}>Enter OTP</p>
      <p className={style.description}>Enter the OTP that we have sent to your mobile number {mobileNumber}</p>
      <p onClick={() => setActiveStep("form")} className={style.changeNumber}>Change Mobile Number</p>
      <div className={style.otpErrorContainer}>
        <div className={style.otpInputContainer}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              placeholder='0'
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, index)}
              maxLength={1}
              ref={inputRefs[index]}
            />
          ))}
        </div>
        {otpError && <p className={`${style.errorMessage} error`}>{otpError}</p>}
      </div>
      <div className={style.timerContainer}>
        {totalSeconds > 0 ? (
          <div className={style.timer}>
            <Image
              src={timerIcon}
            />
            <p className={style.resendText}>
              {totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds} sec
            </p>
          </div>
        ) : (
          <p
            onClick={resendOTP}
            className={style.resendText}
          >
            Resend OTP
          </p>
        )}
      </div>
      <Button
        style={{ width: "100%" }}
        className={`primaryButton`}
        onClick={verifyOtp}
        loading={ loader }
      >
        Verify OTP
      </Button>
    </div>
  );
}
