
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

// Initialize Firebase
const initializeFirebase = () => {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET, 
    messagingSenderId: process.env.REACT_APP_FB_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MESUREMENT_ID,
};
// const firebaseConfig = {

//   apiKey: "AIzaSyAW2SyR1HNWCaRPZ6KUhGc3s3IBmjWts7o",

//   authDomain: "friendlychat-dd31a.firebaseapp.com",

//   projectId: "friendlychat-dd31a",

//   storageBucket: "friendlychat-dd31a.appspot.com",

//   messagingSenderId: "486673268811",

//   appId: "1:486673268811:web:8e535dddfbceed29308dce",

//   measurementId: "G-RE5TX629Y4"

// };


  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const db = getFirestore(app);


  return { app,db, analytics };
};

export default initializeFirebase;


// import firebase from 'firebase';
 
// var firebaseConfig = {
//     apiKey: "AIzaSyAMXvelCiGTHSnJdDZWQ-IEEkuYGRDSXh0",
//     authDomain: "matrimony-d6a0f.firebaseapp.com",
//     projectId: "matrimony-d6a0f",
//     storageBucket: "matrimony-d6a0f.appspot.com",
//     messagingSenderId: "442854987686",
//     appId: "1:442854987686:web:02b68a326ea71963c16167",
//     measurementId: "G-DR33T7QQM5"
// };
 
// // Initialize Firebase
// firebase.initializeApp(firebaseConfig);
// var db = firebase.firestore();
 
// export default db;