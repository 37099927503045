import React, { useContext } from 'react'
import style from './HabbitDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function HabbitDetails() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.profile_details?.user_profile_details?.habbit_details[0]
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Habits Details</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            <div className={style.personalsDetail}>
              <p className={style.label}>Eating Habits</p>
              <p className={style.title}>{details?.eating_habit}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Drinking Habits</p>
              <p className={style.title}>{details?.drinking_habit}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Smoking Habits</p>
              <p className={style.title}>{details?.smoking_habit}</p>
            </div>
          </div>
        </div>
    </div>
  )
}
