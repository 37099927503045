import React from 'react'
import style from "./ProgressBar.module.css"

export default function ProgressBar({ width, current, total }) {

  const uploadElements = [];

  for (let i = 0; i < total; i++) {
    uploadElements.push(
      <>
        <div style={{ width: `${100/total}%`, backgroundColor: i <= current - 1 ? "#EF7435" : "#F3F3F3" }} className={style.progress}>
        </div>
      </>
    )
  }

  return (
    <div className={style.progressAndCountContainer}>
        <p>{current}/{total}</p>
        <div className={style.progressContainer}>
          {uploadElements}
        </div>
    </div>
  )
}
