import React, { useEffect, useState } from 'react'
import style from "./BuildPreference.module.css"
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import rightArrowImage from "../../../assets/svg/Home/arrowRight.svg"
import { addScroll, getUserData } from '../../../../../utils/utils'
import { useNavigate } from 'react-router-dom'

export default function BuildPreference() {

    const navigation = useNavigate(null)

  return (
    <div className={style.buildPreferenceContainer}>
        <p className={style.title}>Craft Your Perfect Connection: Fine-Tune Your Matches with Personalized Preferences</p>
        <p className={style.subtitle}>Unveiling a World of Love and Connection Where Hearts Align, Bonds Strengthen, and Souls Discover Everlasting Bliss</p>
        <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={()=> {
              addScroll()
              navigation("/myaccount", {  state: { selectedMatch: "partner_preferences" } })
            }}
        >
          Let's Build Partner Preference
        </Button>
    </div>
  )
}
