import React, { useState } from 'react'
import style from "./Signup.module.css"
import Image from '../../../../ui/Image/Image';
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import closeImage from "../../assets/svg/Signup/Close.svg"
import Form from './Form/Form';
import Certificate from './Certificate/Certificate';
import { addScroll } from '../../../../utils/utils';
import { SignUpContext } from './Context/SignUpContext';
import Otp from './Otp/Otp';
import ModalBox from '../../../../ui/Modal/ModalBox';

export default function Signup({closeDrawer, data, setDrawerType}) {

  const [ activeStep, setActiveStep ] = useState("form")
  const [id, setId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [signupData, setSignupData] = useState('');

  const handleCloseDrawer = () => {
    addScroll()
    setSignupData("")
    setActiveStep("form")
    closeDrawer(true)
  };

  return (
    <SignUpContext.Provider value={{setActiveStep, mobileNumber, id, setMobileNumber, setId, handleCloseDrawer, signupData, setSignupData, setDrawerType}}>

      <div className={style.signupContainer}>
        {
          activeStep === "form" || activeStep === "otp" ? 
            <Image
              onClick={handleCloseDrawer}
              src={closeImage}
              className={style.closeIcon}
            />
          :
          <></>
        }
        <div className={style.contentContainer}>
          <Image
            src={logoImage}
            className={style.logo}
          />
          {
            activeStep === "form" ? 
              <Form data={data} setDrawerType={setDrawerType}/>
            :
              activeStep === "otp" ?   
                <Otp />
              :
                <Certificate id={id}/>
          }
        </div>
      </div>
    </SignUpContext.Provider>
  );
}
