// Button.js
import React from "react";

const CustomButton = ({ onClick, children, className, style, disabled }) => {
  return (
    <button
      style={style}
      className={`${className}`}
      onClick={onClick}
      disabled={disabled}>
      {children}
    </button>
  );
};

export default CustomButton;
