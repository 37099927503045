import React from 'react'
import classes from './ViewPagesHeader.module.css'
import backarow from '../../assets/SVG/Manage Users/BackArrow.svg'
import { Link } from "react-router-dom";

const ViewPagesHeader = (props) => {
    const { title, children, path, ids, userToken, setTokenUser, identifiLable } = props
    const clearTopVlue = () => {
        localStorage.removeItem('userTabIndex');
        setTokenUser("")
    }

    return (
        <div className={classes.pagesheader}>
            <div className={classes.pagesheader_left}>
                <div className={classes.pagesheader_box}>
                    <div className={classes.backarow_box}>
                        <Link to={path} onClick={clearTopVlue}>
                            <img src={backarow} alt='backarow' />
                        </Link>
                        <h3>{`${title} (${ids})`}</h3>
                    </div>


                    <div className={classes.nave_box}>
                        <ol className={classes.nave_box_profile}>
                            <li><Link className={classes.profile_tag} to={path} onClick={clearTopVlue}>{identifiLable}</Link></li>
                            <li><Link>/</Link></li>
                            <li><Link>{`${title} (${ids})`}</Link></li>
                        </ol>

                    </div>
                </div>


            </div>
            <div className={classes.pagesheader_right}>
                {children}
            </div>
        </div>
    )
}

export default ViewPagesHeader