import React from 'react'
import style from "./WhyChoose.module.css"
import Image from '../../../../../ui/Image/Image'
import tickIcon from "../../../assets/svg/LandingPage/Tick.svg"
import coupleImage from "../../../assets/png/LandingPage/Why Choose.png"

export default function WhyChoose() {
    const listItems = [
        <p><span>No.1 & most trusted</span> matrimony service Exclusively for Doctors.</p>,
        <p><span>100% verified</span> IMR profiles.</p>,
        <div><p>Our extensive number of profiles increases your chances of</p><p> meeting the right person.</p></div>,
        <div><p>Advanced search filters, allowing you to customize your</p><p> partner preferences based on factors such as education,</p><p> location, interests, and more.</p></div>,
        <div><p>We prioritize your privacy and data security, providing a </p><p>safe environment for you to explore profiles and</p><p> communicate with potential partners.</p></div>,
        "Thousands of Doctors have found their life partners here."
    ];

    const lists = (
        <div className={style.lists}>
            {listItems.map((item, index) => (
                <div key={index} className={style.list}>
                    <Image src={tickIcon} />
                    <p>{item}</p>
                </div>
            ))}
        </div>
    );

    return (
        <div className={`${style.whyChooseContainer}`}>
            <div className={`${style.headerContainer} container`}>
                <h1>Why Choose Doctors Matrimony</h1>
                <p>Choose Doctors Matrimony today and take the first step towards a fulfilling
                </p><p>and harmonious life with your ideal partner.</p>
            </div>
            <div className={`${style.listContainer} container`}>
                {lists}
                <div className={style.images}>
                    <Image
                        className={style.image}
                        src={coupleImage}
                    />
                </div>
            </div>
        </div>
    )
}
