import React, { useEffect, useState } from 'react'
import classes from './TopLoyout.module.css'
import PieChart from '../PieChart/PieChart'
import ColumnChart from '../ColumnChart/ColumnChart'
import Slecter from '../../../UI/Dashboardui/Slecters'
import useApiHttp from "../../../hooks/ues-http";
import { BASE_URL } from "../../../utils/baseUrl";

const TopLoyout = () => {
    const [overView, setOverView] = useState([]);
    const [genderData, setGenderData] = useState([]);
    const [selectedYear, setSelectedYear] = useState("");
    const [selectedYearMonth, setSelectedYearMonth] = useState('');

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
    const handleYearMonthChange = (event) => {
        setSelectedYearMonth(event.target.value);
    };

    const { sendRequest: culumRequest } = useApiHttp();
    const culumData = (data) => {
        setOverView(data.data)
    }
    const fetchCulumData = () => {
        const requestData = {
            overview_year: `${selectedYear}`,
        };

        culumRequest({
            url: `${BASE_URL}user-overview`,
            method: 'POST',
            body: requestData,
        }, culumData);
    }

    const { sendRequest: pieRequest } = useApiHttp();
    const pieData = (data) => {
        setGenderData(data.data)
    }

    const fetchpieData = () => {
        const requestData = {
            gender_year: `${selectedYearMonth}`,
        };
        pieRequest({
            url: `${BASE_URL}gender-graph`,
            method: 'POST',
            body: requestData,
        }, pieData);
    }

    useEffect(() => {
        fetchCulumData()
    }, [selectedYear])
    useEffect(() => {
        fetchpieData()
    }, [selectedYearMonth])


    return (
        <div className={classes.toployout_container}>
            <div className={classes.columchart}>
                <div className={classes.titleContainer}>
                    <p className={classes.title}>Users Overview</p>

                    <div className={classes.dropdownContainer}>
                        <div className={classes.dropdown}>
                            <Slecter name="year" options={overView.dropdown} selectedValue={selectedYear} handleChange={handleYearChange} />
                        </div>
                    </div>

                </div>
                <ColumnChart datas={overView} />
            </div>
            <div className={classes.pieChart}>
                <div className={classes.titleContainer}>
                    <p className={classes.title}>Users Overview</p>
                    <div className={classes.dropdownContainer}>
                        <div className={classes.dropdown}>
                            <Slecter name="month" options={genderData.dropdown} selectedValue={selectedYearMonth} handleChange={handleYearMonthChange} />
                        </div>
                    </div>

                </div>
                <PieChart datas={genderData} />
            </div>
        </div>
    )
}

export default TopLoyout