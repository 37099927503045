import React, { useState } from "react";
import styled from "styled-components";


const TabContainer = styled.section`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 44px;
   border-radius: 1px;
  overflow-x: scroll;
  margin: 23.5px 0;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const TabButton = styled.button`
  width: 208px;
  min-width: 170px;
  height: 100%;
  padding: 10px;
  box-shadow: 0px 0px 1.8px #C4C5C6;
  outline: none;
  cursor: pointer;
  border:  ${props => (props.active ? "none" : "1px solid #C4C5C6")} ;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  align-items: center;
  position: relative;
  background: ${props => (props.active ? "#EF7435" : "transparent")};
  &:focus {
    outline: none;
  }
`;
const Title = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  font-family: ${props => (props.active ? "Bold" : "Medium")};
  font-size: 14px;
  color: ${props => (props.active ? "#FFFFFF" : "#7F8389")};
  transition: 0.2s;
  gap: 1rem;
`;
const MsgBox = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  font-family: ${props => (props.active ? "Bold" : "Medium")};
  font-size: 14px;
  color: ${props => (props.active ? "#EF7435" : "#FFFFFF")};
  transition: 0.2s;
  padding: 5px 8px;
  background: ${props => (props.active ? "#FFFFFF" : "#EF7435")};
  border-radius: 4px;
`;


export default function TabBarWithRender({ tabs, onTabClick, activeTab, msg, count }) {
  // const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    // setActiveTab(index);
    onTabClick(index); // Call the callback function with the clicked tab index
  };

  return (
    <>
      <TabContainer>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            active={activeTab === index}
            onClick={() => handleTabClick(index)}
          >
            <Title active={activeTab === index}>{tab.title}  {tab.msg === "true" && <MsgBox active={activeTab === index} >{tab.count}</MsgBox>}</Title>
          </TabButton>
        ))}
      </TabContainer>
      {tabs[activeTab].render()}
    </>
  );
}

