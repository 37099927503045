import React, { useState, useRef, useEffect } from 'react'
import style from './Register.module.css'
import CustomDatePicker from '../../../../../ui/DatePicker/DatePicker'
import Button from '../../../../../ui/Button/Button'
import InputWithLabel from '../../../../../ui/InputWithLabel/InputWithLabel'
import { phoneCode } from '../../../../../Data/data'
import SideDrawer from '../../../../../ui/SideDrawer/SideDrawer'
import Signup from '../../Signup/Signup'
import { avoidScroll, addScroll } from '../../../../../utils/utils'
import PopoutMobileInput from '../../../../../ui/PopoutMobileInput/PopoutMobileInput'
import { RegisterContext } from './Context/RegisterContext'
import MuiDatePicker from '../../../../../ui/MuiDatePicker/MuiDatePicker'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import ModalBox from '../../../../../ui/Modal/ModalBox'
import Image from '../../../../../ui/Image/Image'
import closeIcon from "../../../assets/svg/Signup/Close.svg"
import Iframe from 'react-iframe'

export default function Register() {

  const navigation = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRefDob = useRef(null);

  const [ name, setName ] = useState("") 
  const [ imr, setImr ] = useState("") 
  const [ dob, setDob ] = useState("") 
  const [ countryCode, setCountryCode ] = useState(`+${phoneCode[0].code}`) 
  const [ mobile, setMobile ] = useState("")
  const [viewTermsModal, setViewTermsModal] = useState(false);
  const [viewPrivacyModal, setViewPrivacyModal] = useState(false);

  // State for error messages
  const [nameError, setNameError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [mobileNumberError, setMobileNumberError] = useState(false);
  const [imrNumberError, setImrNumberError] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const closeDrawer = (type) => {
    avoidScroll()
    setIsDrawerOpen(false);
  };

  const handleName = (e) => {
    setIsOpen(false)
    setName(e.target.value)
  }
  const handleMobileNumeber = (e) => {
    setIsOpen(false)
    setMobile(e)
  }
  const handleImr = (e) => {
    setIsOpen(false)
    setImr(e.target.value)
  }
  const handleDob = (e) => {
    setDob(e)
  }

  const register = () => {
    let hasErrors = false;

    // Validation checks
    if (!name.trim()) {
      setNameError(true);
      hasErrors = true;
    } else {
      setNameError(false)
    }

    if (!dob) {
      setDobError(true);
      hasErrors = true;
    } else {
      setDobError(false)
    }

    if (!mobile.trim()) {
      setMobileNumberError(true);
      hasErrors = true;
    } else {
      setMobileNumberError(false)
    }

    if (!imr.trim()) {
      setImrNumberError(true);
      hasErrors = true;
    } else {
      setImrNumberError(false)
    }

    if (hasErrors) {
      // Stop processing if there are errors
      return;
    }

    setIsDrawerOpen(true)
    clearData()
  }

  const clearData = () => {
    setName("")
    setImr("")
    setDob("")
    setMobile("")
  }

  // Close the dropdown when clicking outside of it
  const handleClickOutside = (event) => {
      if (dropdownRefDob.current && !dropdownRefDob.current.contains(event.target)) {
        setIsOpen(false);
    }
  };

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  return (
    <>
      <ModalBox
          open={viewTermsModal}
          width={"1000px"}
          modalContent={
            <div className={style.termsPrivacyContainer}>
              <Image src={closeIcon} className={style.closeIcon} onClick={()=> {
                addScroll()
                setViewTermsModal(false)
              }}/>
              <Iframe url={"/terms_and_conditions_app"}
                width="970px"
                height="600px"
                id=""
                className=""
                display="block"
                position="relative"
                allowFullScreen={true}
              />
            </div>
          }
        />
        <ModalBox
          open={viewPrivacyModal}
          width={"1000px"}
          modalContent={
            <div className={style.termsPrivacyContainer}>
              <Image src={closeIcon} className={style.closeIcon} onClick={()=> {
                addScroll()
                setViewPrivacyModal(false)
              }}/>
              <Iframe url={"/privacy_policy_app"}
                width="970px"
                height="600px"
                id=""
                className=""
                display="block"
                position="relative"
                allowFullScreen={true}
              />
            </div>
          }
        />
      <RegisterContext.Provider value={{ clearData }}>
        <SideDrawer
          isOpen={isDrawerOpen}
          closeDrawer={closeDrawer}
          content={<Signup data={{
            name: name,
            dob: dob,
            mobile: mobile,
            countryCode: countryCode,
            imr: imr
          }}  closeDrawer={closeDrawer}/>}
        />
        <div className={`${style.registerContainer} container`}>
            <p className={style.title}>Let’s find your best match!</p>
            <div className={style.formContainer}>
              <InputWithLabel 
                label={"Name"} 
                placeholder={"Enter Name"}
                onChange={handleName}
                customStyle={ nameError ? style.inputError : ""}
                value={name}
              />
              <div className={style.datePicker} ref={dropdownRefDob}>
                <CustomDatePicker
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  error={dobError}
                  selectedDate={dob}
                  onChange={handleDob}
                  label={"Date of Birth"}
                />
              </div>
              <div className={style.mobile}>
                <PopoutMobileInput
                  error={mobileNumberError}
                  name={"mobile_number"}
                  label={"Mobile Number"}
                  value={mobile}
                  onChange={handleMobileNumeber}
                  selectedValue={countryCode}
                  setSelectedValue={setCountryCode}
                />
              </div>
              <InputWithLabel 
                label={"NMC/SMC Number"} 
                placeholder={"Enter NMC/SMC Number"}
                onChange={handleImr}
                value={imr}
                customStyle={ imrNumberError ? style.inputError : ""}
              />
              <Button
                style={{ minWidth: 150 }}
                className={"primaryButton"}
                onClick={register}
              >
                Register Now
              </Button>
            </div>
            <p className={`${style.footer} m-t-20 text-center`}>By clicking register now, I agree with the <span className={style.clickable} onClick={()=> { 
              avoidScroll()
              setViewTermsModal(true)
            }}>T&C</span> and <span className={style.clickable} onClick={()=> { 
              avoidScroll()
              setViewPrivacyModal(true)
            }}>Privacy Policy</span></p>
        </div>
      </RegisterContext.Provider>
    </>
  )
}
