import React, { useContext } from 'react'
import style from './MedicalHistory.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function MedicalHistory() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.profile_details?.user_profile_details?.medical_condition
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Medical History</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            {
              details?.map((item, index)=> (
                <div key={`MedicalHistory-${index}`} className={style.personalsDetail}>
                  <p className={style.label}>{item?.question}</p>
                  <p className={style.title}>{item?.answers}</p>
                </div>
              ))
            }
          </div>
        </div>
    </div>
  )
}
