import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./Stories.module.css";

import couples from "../../../assets/png/ApplicationStatus/Couple.png";
import CarouselItem from "../../../../../ui/Carousel/CarouselItem/CarouselItem";
import Gallery from "../../../../../ui/Carousel/Gallery/Gallery";

import useApiCall from "../../../hooks/useApiCall"
import config from '../../../../../app.json';
import { Circles } from 'react-loader-spinner'
import { getUserToken } from "../../../../../utils/utils";

const Stories = () => {

    const navigation = useNavigate("")
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    useEffect(() => {
        if (lastPage) {
            return;
        }
        getDetails({
            url: `success-story/read-all?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setLastPage(currentPage >= responseData.data.last_page);
            setData(responseData.data.data);
            setLoadingMore(false);
        });
    }, [currentPage, lastPage]);

    const galleryItems = data?.map((item, index) => (
        <div className={style.carouselCard} key={index} id="arrowRemove">
            <div className={style.ImageGallery}>
                <Gallery
                    cols={1}
                    rows={1}
                    gap={5}
                    autoplay={2000}
                    ImageUrl={item?.images}
                    className={style.couplesFeedBack}
                    mobileBreakpoint={520}
                    responsiveLayout={[
                        { breakpoint: 1024, cols: 1 },
                        { breakpoint: 750, cols: 1, rows: 1, gap: 10 },
                        {
                            breakpoint: 520,
                            autoplay: 3000,
                            cols: 1,
                            loop: true,
                            gap: 15,
                        },
                    ]}
                />
            </div>
            <div className={style.slideBox}>
                <h3>Dr.{item?.bride_name} & Dr.{item?.groom_name}</h3>
                <p>{item?.success_story}</p>
            </div>
        </div>
    ));

    return (
        <>
            {
                data?.length ?
                    <div className={style.StoriesContainer}>
                        <div className={`${style.bannerSectionContainer} container`}>
                            <div className={style.textContainer}>
                                <h2>
                                    Thousands of Doctors have found their</h2><h2> life partner at
                                    Doctors Matrimony!
                                </h2>
                                <p>
                                    Join Doctors Matrimony and discover the perfect match
                                    among thousands of doctors who have found love and
                                    happiness with their life partners. Start your journey
                                    to a fulfilling relationship today!"
                                </p>
                            </div>
                            <div className={style.CarouselOutline} id="arrowMark">
                                <CarouselItem
                                    cols={1}
                                    rows={1}
                                    gap={10}
                                    ItemUrl={galleryItems}
                                    mobileBreakpoint={520}
                                    responsiveLayout={[
                                        { breakpoint: 1024, cols: 1, rows: 1, gap: 5 },
                                        { breakpoint: 768, loop: true, cols: 1, gap: 15  },
                                    ]}
                                />
                            </div>
                            <Link
                                to={"/success_stories"}
                                className={style.linkPage}
                                style={{ color: "#ef7435" }}
                            >
                                View All Success stories
                            </Link>
                        </div>
                    </div>
                :
                <></>
            }
        </>
    );
};

export default Stories;
