import React, { useContext, useEffect, useState } from 'react'
import style from "./Form.module.css"
import Button from '../../../../../ui/Button/Button'
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput'
import useApiCall from '../../../hooks/useApiCall'
import config from "../../../../../app.json"
import { LoginContext } from '../Context/LoginContext'
import ModalBox from '../../../../../ui/Modal/ModalBox'
import Image from '../../../../../ui/Image/Image'
import registerSuccessIcon from "../../../assets/common/Profile Private@2x.svg"
import { addScroll, avoidScroll } from '../../../../../utils/utils'
import { useNavigate } from 'react-router-dom'

export default function Form({setDrawerType}) {

  const navigation = useNavigate()
  const { setActiveStep, id, setId } = useContext(LoginContext);
  const [viewSuccessModal, setViewSuccessModal] = useState(false);
  const [ loginData, setLoginData ] = useState(null)

  const { isLoading: isLoginLoading, success: loginSuccess, error: loginError, code: statusCode, errorData: errorData, sendRequest: sendOtp } = useApiCall();

  const [idError, setIdError] = useState('');

  const handleIdChange = (e) => {
    const inputValue = e.target.value;
    // Remove any characters that are not letters or numbers
    const alphanumericValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
  
    setId(alphanumericValue);
  
    if (alphanumericValue.length === 0) {
      setIdError('Please enter your Id/Mobile Number');
    } else {
      setIdError('');
    }
  };

  useEffect(() => {
    if( statusCode === 403 ) {
      avoidScroll()
      setViewSuccessModal(true)
    }
    if (loginSuccess) {
      setActiveStep("otp");
    }
    if (loginError) {
      setIdError(loginError)
    }
  }, [loginSuccess, loginError, statusCode]);

  const handleSubmit = () => {

    let hasErrors = false;

    // Validation checks
    if (!id.trim()) {
      setIdError('Please enter your ID/Mobile Number');
      hasErrors = true;
    }
    if (hasErrors) {
      // Stop processing if there are errors
      return;
    }

    sendOtp({
      url: "auth/login/send-otp",
      method: 'POST',
      body: {
        "user_id": id,
      },
    }, (response)=> {
      setLoginData(response)
      setId(response.data.mobile_number);
    });
  };

  const { isLoading: isReactivateLoading, success: reactivateSuccess, error: reactivateError, code: reactivateStatusCode, errorData: reactivateData, sendRequest: reactivate } = useApiCall();

  useEffect(() => {
    if (reactivateSuccess) {
      addScroll()
      setViewSuccessModal(false)
      setActiveStep("otp")
    }
    if (reactivateError) {
      setIdError(loginError)
    }
  }, [reactivateSuccess, reactivateError]);

  const handleReactivate = () => {
    reactivate({
      url: "auth/login/send-otp-reactivate-user",
      method: 'POST',
      body: {
        "user_id": id,
      },
    });
  }

  return (
    <div className={style.contentContainer}>
      <ModalBox
        open={viewSuccessModal}
        width={"609px"}
        modalContent={
          <div className={style.registerSuccessContainer}>
            <Image src={registerSuccessIcon} className={style.registerSuccessIcon}/>
            <p className={style.registerSuccessTitle}>Account Deleted</p>
            <p className={style.registerSuccessDescription}>Your account has been deleted on {errorData?.data?.deleted_date}. You can reinstate your account within {errorData?.data?.remove_on}. By clicking “Yes” you agree to reinstate your account.</p>
            <div className={style.registerOkayButtonContainer}>
              <Button
                style={{ width: "30%",  }}
                className={`cancelButton`}
                onClick={()=> {
                  setViewSuccessModal(false)
                }}
                // loading={signUpLoading}
              >
                No
              </Button>
              <Button
                style={{ width: "30%",  }}
                className={`primaryButton`}
                onClick={()=> {
                  handleReactivate()
                }}
                // loading={signUpLoading}
              >
                Yes
              </Button>
            </div>
          </div>
        }
      />
      <p className={style.title}>Login Now</p>
      <p className={style.description}>Please enter your mobile number to log in to your account</p>
      <PopoutInput
        label="Matrimony ID/Mobile No"
        name={"matrimony_id_mobile_no"}
        value={id}
        onChange={handleIdChange}
      />
      {idError && <p className={"error"}>{idError}</p>}

      <Button
        className={`${style.button} primaryButton`}
        onClick={handleSubmit}
        loading={isLoginLoading}
      >
        Get OTP
      </Button>
      <p className={style.loginNow}>Don’t have an account? <span onClick={()=> setDrawerType("register")}>Register Now</span></p>
    </div>
  )
}
