import React, { useContext } from 'react'
import style from './BasicPreference.module.css'
import Image from '../../../../../ui/Image/Image'
import matchImage from "../../../assets/svg/ProfileView/Match.svg"
import unmatchImage from "../../../assets/svg/ProfileView/Unmatch.svg"
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function BasicPreference() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.matches?.basic_detail
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Basic Preferences</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            {
              details?.map((item, index)=> (
                <div className={style.personalsDetail}>
                  <p className={style.label}>
                    <Image 
                      src={ item?.has_match ? matchImage : unmatchImage}
                    />
                    {item?.label}
                  </p>
                  <p className={style.title}>{item?.value}</p>
                </div>
              ))
            }
          </div>
        </div>
    </div>
  )
}
