import React, { useState } from 'react';
import styles from './PopoutTextarea.module.css';

function PopoutTextarea({ label, focus=false, placeholder, value, onChange, className, name, characterLimit = 200 }) {
  const [isFocused, setIsFocused] = useState(focus);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // setIsFocused(false);
  };

  const handleTextareaChange = (event) => {
    // Check if the input length exceeds the character limit
    if (event.target.value?.length <= characterLimit) {
      onChange(event);
    }
  };

  return (
    <div className={`${styles['popout-textarea']} ${isFocused || value ? styles.focused : ''}`}>
      <label className={styles['textarea-label']}>{label}</label>
      <textarea
        className={`${styles['textarea-field']} ${className}`}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={handleTextareaChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <div className={styles['character-limit']}>
        {value.length}/{characterLimit}
      </div>
    </div>
  );
}

export default PopoutTextarea;
