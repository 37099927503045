import React, { useEffect, useState } from "react";
import style from "./Filter.module.css";
import Image from "../../../../../ui/Image/Image";
import Button from "../../../../../ui/Button/Button";
import closeIcon from "../assets/Popup Close.svg";
import minimizeIcon from "../assets/Subtract.svg";
import plusIconImage from "../../../assets/common/PlusIcon.svg"
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import checkedIcon from "../../../assets/svg/Signup/checked.svg";
import uncheckedIcon from "../../../assets/svg/Signup/uncheck.svg";
import useApiCall from "../../../hooks/useApiCall";
import config from "../../../../../app.json";
import { addScroll, getUserToken } from "../../../../../utils/utils";
import FloatingLabelDropdown from "../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown";
import MultiSelectDropdown from "../../../../../ui/MultiSelectDropdown/MultiSelectDropdown";

export const Filter = ({ filterDataFromFilter, setIsScrollAction, setFilterDataFromFilter, setFilterHasValue, pageType, setRefresh, refresh, setReachedLastPage, setLoadingMore, setDataClear, closeDrawer, filterData, setFilterData, setFilterSubmitted, setToggleFilterApi, toggleFilterApi, setCurrentPage, setLastPageFilter }) => {
  const [data, setData] = useState(null);
  const [userState, setUserState] = useState({ name: "", token: "" });
  const [ showSubmitButton, setShowSubmitButton ] = useState(false)
  const [ minimizeProfileCreated, setMinimizeProfileCreated ] = useState(false)
  const [ minimizeBasicDetails, setMinimizeBasicDetails ] = useState(false)
  const [ minimizeReligiousDetails, setMinimizeReligiousDetails ] = useState(false)
  const [ minimizeProfessionalDetails, setMinimizeProfessionalDetails ] = useState(false)

  const { sendRequest: getMetaData } = useApiCall()

  useEffect(() => {
    const hasNonEmptyValue = Object.values(filterDataFromFilter)?.some(value => {
      if (Array.isArray(value)) {
          return value.length > 0;
      } else {
          return value && value !== "height" && value !== "age";
      }
    });
    setShowSubmitButton( hasNonEmptyValue ? true : false)
    getMetaData(
      {
        url: "profile-list/get-filters",
        method: "GET",
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      },
      (responseData) => {
        setData(responseData.data);
      }
    );
  }, []);

  const handleRadioChange = (name, value) => {
    setFilterDataFromFilter((prevFilterData) => {
      const currentFilter = prevFilterData[name] || [];
      const updatedFilter = currentFilter.includes(value)
        ? currentFilter.filter((item) => item !== value)
        : [...currentFilter, value];
      return {
        ...prevFilterData,
        [name]: updatedFilter,
      };
    });
  };

  const handleInputChange = (event, inputType) => {
    console.log("event, inputType", event, inputType);
    if( event.target ) {
      const { name, value } = event.target;
      setFilterDataFromFilter({
        ...filterDataFromFilter,
        [name]: value,
      });
    } else {
      setFilterDataFromFilter({
        ...filterDataFromFilter,
        [inputType]: event,
      });
    }
  };

  useEffect(()=> {
    const hasNonEmptyValue = Object.values(filterDataFromFilter).some(value => {
      if (Array.isArray(value)) {
          return value.length > 0;
      } else {
          return value && value !== "height" && value !== "age";
      }
    });
    setShowSubmitButton( hasNonEmptyValue ? true : false)
  }, [filterDataFromFilter])

  const isCheckboxChecked = (name, value) => {
    const dataArray = filterDataFromFilter[name];
    return dataArray && dataArray.indexOf(value) !== -1;
    };

  const handleFormSubmit = (event) => {
    const hasNonEmptyValue = Object.values(filterDataFromFilter).some(value => {
      if (Array.isArray(value)) {
          return value.length > 0;
      } else {
          return value && value !== "height" && value !== "age";
      }
    });
    if( hasNonEmptyValue ) {
      setFilterData(filterDataFromFilter)
      setFilterHasValue(true)
      addScroll()
      setDataClear([])
      setLoadingMore(false)
      setReachedLastPage(false)
      setLastPageFilter(false)
      setCurrentPage(1)
      event.preventDefault();
      setRefresh(!refresh)
      setIsScrollAction(false)
      closeDrawer();
    } else {
      setFilterHasValue(false)
    }
  };

  const handleFormData = (name, value) => {
    if( name === "height" || name === "age" ) {
        if( value !== "Select an option" ) {
          const heightData = data[name].find((item) => item.value === value )
          setFilterDataFromFilter({
              ...filterDataFromFilter,
              [name]: [heightData]
          })
        } else {
          setFilterDataFromFilter({
            ...filterDataFromFilter,
            [name]: ""
        })
        }
    } else {
        setFilterDataFromFilter({
            ...filterDataFromFilter, [name]: value
        })
    }
  }

  const handleAnswerChange = (index, answer) => {
    setFilterDataFromFilter({ ...filterDataFromFilter, profile_created_name: answer?.name, profile_created_value : answer?.value });
  };

  const handleReset = () => {
    setFilterData({
      height: [],
      age: [],
      profile_created_name: "",
      profile_created_value: "",
      show_profiles: [],
      dont_show_profiles: [],
      marital_status: [],
      mother_tongue: [],
      religious_details: [],
      religion: [],
      community: [],
      star: [],
      raasi: [],
      gothram: [],
      citizenship: [],
      employed_in: []
  })
    setFilterDataFromFilter({
        height: [],
        age: [],
        profile_created_name: "",
        profile_created_value: "",
        show_profiles: [],
        dont_show_profiles: [],
        marital_status: [],
        mother_tongue: [],
        religious_details: [],
        religion: [],
        community: [],
        star: [],
        raasi: [],
        gothram: [],
        citizenship: [],
        employed_in: []
    })
    setDataClear([])
    setLoadingMore(false)
    setReachedLastPage(false)
    setLastPageFilter(false)
    setCurrentPage(1)
    setRefresh(!refresh)
    setToggleFilterApi(!toggleFilterApi)
  }

  return (
    <div id={"filterContainer"} className={style.filterContainer}>
      <div className={style.filterHeader}>
        <p className={style.filterHeaderTitle}>Filter { showSubmitButton && ( <p onClick={handleReset} className={style.resetButton}>Reset</p> ) }</p>
        <Image onClick={closeDrawer} src={closeIcon} className={style.closeIcon} />
      </div>
      <div className={style.filterContentContainer}>
        <div className={style.filterHeaderContentContainer}  style={{ maxHeight: minimizeProfileCreated ? "44px" : "100%", overflow: "hidden" }}>
          <div className={style.sectionHeader}>
            <p className={style.sectionHeaderTitle}>Profile Type</p>
              <Image 
                onClick={()=> setMinimizeProfileCreated(!minimizeProfileCreated)}
                src={ minimizeProfileCreated ? plusIconImage : minimizeIcon}
                className={style.minimizeIcon}
              />
          </div>
          {
            pageType !== "Newly Joined" && (
              <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Profile Created</p>
                <div className={style.contents}>
                  {data &&
                    data.profile_created.map((item, index) => (
                        <div className={style.content}>
                          <Image src={item.value == filterDataFromFilter.profile_created_value ? radioSelected : radioUnselected} onClick={() => handleAnswerChange(index, item)} />
                          <p className={style.label}>{item.name}</p>
                        </div>
                    ))}
                </div>
              </div>
            )
          }
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Show Profiles</p>
                <div className={style.contents}>
                    {
                        data?.show_profiles?.map((item, index) => (
                            <div className={style.content}>
                                <Image
                                    onClick={() => handleRadioChange("show_profiles", item)}
                                    src={ isCheckboxChecked("show_profiles", item) ? checkedIcon : uncheckedIcon }
                                    className={style.checkIcon}
                                /> 
                                <p className={style.label}>{item?.name}</p>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Physical Status</p>
                <div className={style.contents}>
                    {
                        data?.physical_status?.map((item, index) => (
                            <div className={style.content}>
                                <Image
                                    onClick={() => handleRadioChange("physical_status", item)}
                                    src={ isCheckboxChecked("physical_status", item) ? checkedIcon : uncheckedIcon }
                                    className={style.checkIcon}
                                />
                                <p className={style.label}>{item?.name}</p>
                            </div>
                        )
                    )}
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Don’t Show Profiles</p>
                <div className={style.contents}>
                    {
                        data?.dont_show_profiles?.map((item, index) => (
                            <div className={style.content}>
                                <Image
                                    onClick={() => handleRadioChange("dont_show_profiles", item)}
                                    src={ isCheckboxChecked("dont_show_profiles", item) ? checkedIcon : uncheckedIcon }
                                    className={style.checkIcon}
                                />
                                <p className={style.label}>{item?.name}</p>
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
      </div>
      <div className={style.filterHeaderContentContainer}  style={{ maxHeight: minimizeBasicDetails ? "44px" : "100%", overflow: "hidden" }}>
            <div className={style.sectionHeader}>
                <p className={style.sectionHeaderTitle}>Basic Details</p>
                <Image 
                  onClick={()=> setMinimizeBasicDetails(!minimizeBasicDetails)}
                  src={ minimizeBasicDetails ? plusIconImage : minimizeIcon}
                  className={style.minimizeIcon}
              />
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Age</p>
                <div className={style.contents} style={{ marginTop: "20px" }}>
                    <FloatingLabelDropdown
                        label="Age"
                        name="age"
                        responseType="value"
                        options={data?.age}
                        value={filterDataFromFilter.age[0]?.value}
                        onChange={(e) => handleFormData("age", e.target.value)}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Height</p>
                <div className={`${style.inputContent} ${style.dropdown}`}>
                    <FloatingLabelDropdown
                        label="Height"
                        name="height"
                        responseType="value"
                        options={data?.height}
                        value={filterDataFromFilter.height[0]?.value}
                        onChange={(e) => handleFormData("height", e.target.value)}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Marital Status</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Marital Status"
                        name="marital_status"
                        responseType="value"
                        options={data?.marital_status}
                        value={filterDataFromFilter?.marital_status}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Mother Tongue</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Mother Tongue"
                        name="mother_tongue"
                        responseType="value"
                        options={data?.mother_tongue}
                        value={filterDataFromFilter?.mother_tongue}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
        </div>
        <div className={style.filterHeaderContentContainer}  style={{ maxHeight: minimizeReligiousDetails ? "44px" : "100%", overflow: "hidden" }}>
            <div className={style.sectionHeader}>
                <p className={style.sectionHeaderTitle}>Religious Details</p>
                <Image 
                  onClick={()=> setMinimizeReligiousDetails(!minimizeReligiousDetails)}
                  src={ minimizeReligiousDetails ? plusIconImage : minimizeIcon}
                  className={style.minimizeIcon}
              />
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Religion</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Religion"
                        name="religion"
                        responseType="value"
                        options={data?.religion}
                        value={filterDataFromFilter?.religion}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Community</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Community"
                        name="community"
                        responseType="value"
                        options={data?.community}
                        value={filterDataFromFilter?.community}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Citizenship</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Citizenship"
                        name="citizenship"
                        responseType="value"
                        options={data?.citizenship}
                        value={filterDataFromFilter?.citizenship}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Gothram</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Gothram"
                        name="gothram"
                        responseType="value"
                        options={data?.gothram}
                        value={filterDataFromFilter?.gothram}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Raasi</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Raasi"
                        name="raasi"
                        responseType="value"
                        options={data?.raasi}
                        value={filterDataFromFilter?.raasi}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Star</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Star"
                        name="star"
                        responseType="value"
                        options={data?.star}
                        value={filterDataFromFilter?.star}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
        </div>
        <div className={style.filterHeaderContentContainer}  style={{ maxHeight: minimizeProfessionalDetails ? "44px" : "100%", overflow: "hidden" }}>
            <div className={style.sectionHeader}>
                <p className={style.sectionHeaderTitle}>Professional Details</p>
                <Image 
                  onClick={()=> setMinimizeProfessionalDetails(!minimizeProfessionalDetails)}
                  src={ minimizeProfessionalDetails ? plusIconImage : minimizeIcon}
                  className={style.minimizeIcon}
              />
            </div>
            <div className={style.sectionContent}>
                <p className={style.sectionContentTitle}>Employed in</p>
                <div className={`${style.inputContent} ${style.multiDropdown} ${style.dropdown}`}>
                    <MultiSelectDropdown
                        label="Employed in"
                        name="employed_in"
                        responseType="value"
                        options={data?.employed_in}
                        value={filterDataFromFilter?.employed_in}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
        </div>
      <div className={style.footerButtonContainer}>
        <Button onClick={()=> { 
          closeDrawer()
          setFilterDataFromFilter({
            height: [],
            age: [],
            profile_created: "",
            show_profiles: [],
            dont_show_profiles: [],
            marital_status: [],
            mother_tongue: [],
            religious_details: [],
            religion: [],
            community: [],
            star: [],
            raasi: [],
            gothram: [],
            citizenship: [],
            employed_in: []
          })
          setRefresh(!refresh)
        }} className={`${style.cancelButton} primaryButton cancelButton`}>
          Cancel
        </Button>
        {
          showSubmitButton && (
            <Button onClick={handleFormSubmit} className="primaryButton">
              Apply
            </Button>
          )
        }
      </div>
    </div>
  );
};
