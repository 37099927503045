import React, { useContext, useEffect, useState } from 'react';
import style from './ReligiousDetails.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput';
import Button from '../../../../../ui/Button/Button';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import uploadIcon from '../../../assets/common/Upload.svg';
import pdfIcon from "../../../assets/common/pdf.svg"
import Image from '../../../../../ui/Image/Image';
import { BuildProfileContext } from '../Context/BuildProfileContext';
import { checkPdf, getUserToken, isStringValid, uploadFile } from '../../../../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ReligiousDetails() {

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

  const { setActiveStep } = useContext(BuildProfileContext);

  const { sendRequest: getUserData } = useApiCall();

  const { sendRequest: getCommunity } = useApiCall();

  const { sendRequest: getMetaData } = useApiCall();

  const [communityData, setCommunityData] = useState([]);

  const [ religion, setReligion ] = useState([]);
  const [ community, setCommunity ] = useState([])
  const [ gothram, setGothram ] = useState([]);
  const [ star, setStar ] = useState([]);
  const [ raasi, setRaasi ] = useState([]);
  const [userMeta, setUserMeta] = useState([]);

  const [ userData, setUserData ] = useState([])
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [certificateError, setCertificateError] = useState("");
  const [selectedCertificatePreview, setSelectedCertificatePreview] = useState(null);
  const [ selectedCertificateType, setSelectedCertificateType ] = useState("")

  const [chevvaiDosam, setChevvaiDosam] = useState('');
  const [eatingHabits, setEatingHabits] = useState('');
  const [drinkingHabits, setDrinkingHabits] = useState('');
  const [smokingHabits, setSmokingHabits] = useState('');
  const [ validationError, setValidationError ] = useState("")

  const [ loader, setLoader ] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData({
        url: "onboard/read-step-3",
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
    }, (data) => {
      if( data?.data ) {
        const { 
          religion,
          community,
          gothram,
          star,
          raasi,
          horoscope,
          chevvai_dosam,
          eating_habit,
          drinking_habit,
          smoking_habit
        } = data?.data
        setReligion(religion ? religion : [])
        setCommunity( community ? community : [])
        setGothram(gothram ? gothram : [])
        setStar(star ? star : [])
        setRaasi(raasi ? raasi : [])
        setSelectedCertificate(horoscope)
        setSelectedCertificatePreview(process.env.REACT_APP_S3_CLOUD_FRONT_URL + horoscope)
        setSelectedCertificateType( checkPdf(horoscope) )
        setChevvaiDosam(chevvai_dosam)
        setEatingHabits(eating_habit)
        setDrinkingHabits(drinking_habit)
        setSmokingHabits(smoking_habit)
      } else {
        setUserData(data.data);
      }
    });
  }, []);

  useEffect(() => {
    if( religion?.length > 0 ) {
        getCommunity({
            url: "community",
            method: 'POST',
            body: {
              "religion_token": religion
            }
        }, (data) => {
          setCommunityData(data.data);
        });
      }
  }, [religion]);

  useEffect(() => {
    getMetaData(
      {
        url: 'onboard/read-meta-data',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        setUserMeta(data.data);
      }
    );
  }, []);

  const handleCertificateUpload = (e) => {
    const selectedFile = e.target.files[0];
    if( selectedFile ) {
      // Check if the file size is greater than 6MB
      if (selectedFile && selectedFile.size > 6 * 1024 * 1024) {
        setCertificateError("File size must be less than 6MB.")
        return;
      }
      setCertificateError("")
      setSelectedCertificate(e.target.files[0]);
      if (selectedFile) {
        setSelectedCertificateType( selectedFile?.type === "application/pdf" ? true : false )
        const selectedFileURL = URL.createObjectURL(selectedFile);
        setSelectedCertificatePreview(selectedFileURL)
        // setCertificateError('');
      } else {
        setSelectedCertificate(null);
      }
    }
  };

  useEffect(() => {
    if (success) {
      // Optionally, you can navigate to the next step or perform any other actions here
      setActiveStep(4);
    }
    if (error !== '') {
      toast.error(error, {
          autoClose: 2500,
      })
    }
    setLoader(false)
  }, [success, error]);

  const [formDataTitle, setFormDataTitle] = useState({
      religion: "Religion",
      community: "Community",
      gothram: "Gothram",
      star: "Star",
      raasi: "Raasi",
      horoscope_url: "Horoscope",
      chevvai_dosam: "Chevvai Dosam",
      eating_habit: "Eating Habits",
      drinking_habit: "Drinking Habits",
      smoking_habit: "Smoking Habits"
  });

  const handleSubmit = async() => {
    if (
    //   isStringValid(religion) &&
    //   isStringValid(community) &&
    //   isStringValid(gothram) &&
    //   isStringValid(star) &&
    //   isStringValid(raasi) &&
    //   selectedCertificate &&
    //   chevvaiDosam &&
      eatingHabits &&
      drinkingHabits &&
      smokingHabits
    //   certificateError === ""
    ) {
      setLoader(true)
      let horoscopeUrl = selectedCertificate
      if (typeof selectedCertificate === "object" && selectedCertificate !== null) {
        horoscopeUrl = await uploadFile(selectedCertificate);
      }
      // Collect all the form data and radio button selections
      const dataToSend = {
        "religion": religion === "Select an option" ? "" : religion?.length ? religion : "",
        "community":  community === "Select an option" ? "" : community?.length ? community : "",
        "gothram": gothram === "Select an option" ? "" :  gothram?.length ? gothram : "",
        "star": star === "Select an option" ? "" :  star?.length ? star : "",
        "raasi": raasi === "Select an option" ? "" :  raasi?.length ? raasi : "",
        "horoscope": horoscopeUrl === "Select an option" ? "" :  horoscopeUrl,
        "chevvai_dosam": chevvaiDosam === "Select an option" ? "" :  chevvaiDosam, //Yes
        "eating_habit": eatingHabits, //Eggetarian,Non-Vegetarian
        "drinking_habit": drinkingHabits, //'Yes', 'No', 'Occasionally'
        "smoking_habit": smokingHabits, //Yes', 'No', 'Occasionally'
      };
      
      uploadUserDetails({
          url: "onboard/save-step-3",
          method: 'POST',
          body: dataToSend,
          headers: {
              Authorization: `Bearer ${getUserToken()}`
          }
      });
    } else {
        const dataArray = {
          // religion: religion,
          // community: community,
          // raasi: raasi,
          // star: star,
          // gothram: gothram,
          // chevvai_dosam: chevvaiDosam,
          // horoscope_url: selectedCertificate,
          eating_habit:  eatingHabits, 
          drinking_habit: drinkingHabits,
          smoking_habit: smokingHabits,
        }
        var result = Object.keys(dataArray).map((key) => [key, dataArray[key]]);
        for(const item of result || []) {
            if ( !item[1] || item[1].length == 0 || item[1] === "Select an option" ) {
                toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                    autoClose: 2500,
                })
                break; // This will exit the loop when the condition is met
            }
        }
    }
  }

  return (
    <div className={style.workDetailsContainer}>
      <ToastContainer />
      <ProgressBar width={`${16.66 * 3}%`} total={5} current={3} />
      <div className={style.formContainer}>
        <p className={style.formTitle}>Habits preferences</p>
        <div className={style.fieldContainer}>
          <div className={style.field}>
            <p className={style.fieldTitle}>Eating Habits</p>
            <div className={style.fieldValue}>
              <div className={style.answers}>
                {
                  userMeta?.eating_habit?.map((item, index) => (
                    <div className={style.answer} onClick={() => setEatingHabits(item?.value)}>
                      <Image src={eatingHabits === item?.value ? radioSelected : radioUnselected} />
                      <p>{item?.name}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className={style.field}>
            <p className={style.fieldTitle}>Drinking Habits</p>
            <div className={style.fieldValue}>
              <div className={style.answers}>
                {
                  userMeta?.drinking_habit?.map((item, index) => (
                    <div className={style.answer} onClick={() => setDrinkingHabits(item?.value)}>
                      <Image src={drinkingHabits === item?.value ? radioSelected : radioUnselected} />
                      <p>{item?.name}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className={style.fieldContainer}>
          <div className={style.field}>
            <p className={style.fieldTitle}>Smoking Habits</p>
            <div className={style.fieldValue}>
              <div className={style.answers}>
                {
                  userMeta?.smoking_habit?.map((item, index) => (
                    <div className={style.answer} onClick={() => setSmokingHabits(item?.value)}>
                      <Image src={smokingHabits === item?.value ? radioSelected : radioUnselected} />
                      <p>{item?.name}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.formContainer}>
        <p className={style.formTitle}>Religious Details</p>
        <div className={style.fieldContainer}>
          <FloatingLabelDropdown
            label="Religion"
            name="religion"
            responseType="token"
            options={userMeta.religion}
            value={religion}
            onChange={(e) => {
              setCommunity([])
              setReligion(e.target.value)
            }}
          />
          <FloatingLabelDropdown
            label="Community"
            name="community"
            responseType="token"
            options={communityData}
            value={community}
            onChange={(e) => setCommunity(e.target.value)}
          />
        </div>
        <div className={style.fieldContainer}>
          <FloatingLabelDropdown
            label="Raasi"
            name="raasi"
            responseType="token"
            options={userMeta.rasi}
            value={raasi}
            onChange={(e) => setRaasi(e.target.value)}
          />
          <FloatingLabelDropdown
            label="Star"
            name="star"
            responseType="token"
            options={userMeta.star}
            value={star}
            onChange={(e) => setStar(e.target.value)}
          />
        </div>
        <div className={style.fieldContainer}>
          <FloatingLabelDropdown
            label="Gothram"
            name="gothram"
            responseType="token"
            options={userMeta.gothram}
            value={gothram}
            onChange={(e) => setGothram(e.target.value)}
          />
          <div style={{ minWidth: "342px" }} className={style.field}>
            <p className={style.fieldTitle}>Chevvai Dosham</p>
            <div className={style.fieldValue}>
              <div className={style.answers}>
                {
                  userMeta?.chevvai_dosam?.map((item, index) => (
                    <div className={style.answer} onClick={() => setChevvaiDosam(item?.value)}>
                      <Image src={chevvaiDosam === item?.value ? radioSelected : radioUnselected} />
                      <p>{item?.name}</p>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className={style.fieldContainer}>
          <div className={style.field}>
            <p className={style.fieldTitle}>Upload Horoscope</p>
            <p className={style.fieldTitleLabel}>PDF, PNG, JPG with a Max file size of 6MB</p>
            <label htmlFor="certificateUpload" style={{ gap: selectedCertificate ? '12px' : '' }} className={style.uploadButton}>
              {selectedCertificate ? (
                <>
                  {
                    selectedCertificateType ?
                      <Image onClick={()=> {
                        // avoidScroll()
                        // setShowRejectReasonModal(true)
                      }} src={pdfIcon} width={"70px"} height={"50px"}/>
                    :
                    <Image src={selectedCertificatePreview} width="70px" height="50px" />
                  }
                </>
              ) : (
                <Image src={uploadIcon} />
              )}
              <p className={style.uploadButtonTitle}>{selectedCertificate ? 'Edit' : 'Upload Certificate'}</p>
            </label>
            <input
              type="file"
              id="certificateUpload"
              accept="application/pdf,image/png,image/jpeg"
              onChange={handleCertificateUpload}
              style={{ display: 'none' }}
            />
            { certificateError !== "" ? <p className={`${style.errorMessage} error`}>{certificateError}</p> : <></> }
          </div>
        </div>
      </div>
      {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

      <div className={style.buttonFooterContainer}>
          <Button
              className={`primaryButton ${style.cancelButton}`}
              onClick={()=> setActiveStep(2)}
          >
            Back
          </Button>
          <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={handleSubmit}
            loading={loader}
          >
            Continue
          </Button>
      </div>

    </div>
  );
  
}
