import React, { useContext } from 'react'
import style from './ProfessionalPreference.module.css'
import Image from '../../../../../ui/Image/Image'
import matchImage from "../../../assets/svg/ProfileView/Match.svg"
import unmatchImage from "../../../assets/svg/ProfileView/Unmatch.svg"
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'
import { getUserData } from '../../../../../utils/utils'

export default function ProfessionalPreference() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.matches?.professional_detail
  return (
    <div className={style.personalDetailsContainer} style={{ minHeight: getUserData()?.membership_type === "Premium" ? "250px" : "inherit" }}>
        <p className={style.title}>Professional preferences</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            {
              details?.map((item, index)=> (
                <div className={style.personalsDetail}>
                  <p className={style.label}>
                    <Image 
                      src={ item?.has_match ? matchImage : unmatchImage }
                    />
                    {item?.label}
                  </p>
                  <p className={style.title}>{item?.value}</p>
                </div>
              ))
            }
          </div>
        </div>
    </div>
  )
}
