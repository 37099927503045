import React, { useEffect, useState, useRef } from 'react';
import style from "./SearchResult.module.css";
import Image from '../../../../../ui/Image/Image';
import Button from '../../../../../ui/Button/Button';
import sortlistStarIcon from "../../../assets/svg/Matches/Shortlisted.svg";
import sortlistGrayStarIcon from "../../../assets/svg/Matches/ShortlistGray.svg";
import professionIcon from "../../../assets/svg/Matches/Profession Icon.svg";
import useApiCall from '../../../hooks/useApiCall';
import useChatSetter from '../../../hooks/useChatSetter';
import config from '../../../../../app.json';
import { Circles } from 'react-loader-spinner';
import { avoidScroll, getUserToken } from '../../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import chatIcon from "../../../features/MailBox/assets/Chat.svg"
import infoIcon from "../../MailBox/Declined/assets/Info.svg";
import ModalBox from '../../../../../ui/Modal/ModalBox';
import { DeclineModal } from '../../Matches/DeclineModal/DeclineModal';
import { NoData } from '../../NoData/NoData';
import { BecameMemberModal } from '../../BecameMemberModal/BecameMemberModal';

export default function SearchResult({ term }) {

    const navigation = useNavigate("")
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)
    const [clickedItem, setClickedItem] = useState(false)
    const [chatToken, setChatToken] = useState(null)
    const [rejectReason, setRejectReason] = useState(null)
    const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)
    const [rejectToken, setRejectToken] = useState(null)
    const [interestClicked, setInterestClicked] = useState(false)
    const [showBecameMemberModal, setShowBecameMemberModal] = useState(false)
    const [limitExceeded, setLimitExceeded] = useState(0)

    const [showSubscribeWidget, setShowSubscribeWidget] = useState(false)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: sendIntrest } = useApiCall();
    const { isLoading: sendShortListLoading, success: sendShortListSuccess, error: sendShortListError, sendRequest: shortlist } = useApiCall();
    const { loading: isChatSetterLoading, success: successChatSetter, error: errorChatSetter, setUserChatEntry: chatRoomSetup } = useChatSetter();

    const { sendRequest: acceptInterest } = useApiCall();
    const { sendRequest: declineInterest } = useApiCall();

    const pageContentContainerRef = useRef(null);

    useEffect(() => {
        setData([])
        setLastPage(false)
    }, [term])

    useEffect(() => {
        if (lastPage) {
            return;
        }
        getDetails({
            url: `profile-list/search/${term}?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setShowSubscribeWidget(responseData?.status === 402 ? true : false)
            if (responseData.data.data?.data?.length > 0) {
                setLimitExceeded(responseData.data.limit_exceeded)
                setShowBecameMemberModal(responseData.data.limit_exceeded)
                setLastPage(currentPage >= responseData?.data?.data?.last_page);
                setTotal(responseData?.data?.data?.total)
                setData((prevData) => {
                    if (!prevData) {
                        return prevData;
                    }
                    return [...prevData, ...responseData?.data?.data?.data]
                });
                setLoadingMore(false);
            } else {
                setData([])
                setTotal(0)
                setLoadingMore(false);
            }
        });
    }, [currentPage, lastPage, term]);

    useEffect(() => {
        if (sendIntrestSuccess) {
            console.log("sendIntrestSuccess", sendIntrestSuccess);
        }
        if (sendIntrestError) {
            console.log("loginError", sendIntrestError);
        }
    }, [sendIntrestSuccess, sendIntrestError]);

    const handleSendInterest = (token, status) => {
        setInterestClicked(true)
        if (!limitExceeded) {
            sendIntrest({
                url: `profile/toggle-interest`,
                method: 'POST',
                body: {
                    "profile_token": token,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (result) => {
                setLimitExceeded(result?.data?.limit_exceeded)
                const index = data.findIndex((item) => item.token === token);

                if (index !== -1) {
                    // Update the specific property of the found object
                    data[index].interest_sent_status = status;
                }
                setData(data)
            });
        }
    }

    const handleShortlist = (token, status) => {
        shortlist({
            url: `profile/toggle-shortlist`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].shortlisted = status;
            }
            setData(data)
        });
    }


    const handleAccept = (token) => {
        acceptInterest({
            url: `profile/accept-interest`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 2;
            }
            setData(data)
        });
    }
    const handleCancel = (reason) => {
        declineInterest({
            url: `profile/reject-interest`,
            method: 'POST',
            body: {
                "profile_token": rejectToken,
                "reject_reason": reason.trim()
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === rejectToken);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 3;
            }
            setData(data)
        });
    }

    const actionUi = (
        can_send_interest,
        interest_received_status,
        interest_sent_status,
        shortlisted,
        is_connected,
        token,
        name,
        image,
        user_id
    ) => {

        return (
            <>
                <div className={style.buttonProgressContainer}>
                    {/* <p className={style.likeProfileLabel}>Like her profile?</p> */}
                    <div className={style.buttonsContianer}>
                        {
                            interest_sent_status === 0 && interest_received_status === 0 && interest_sent_status === 0 ?
                                <Button
                                    className={`primaryButton`}
                                    onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                >
                                    Send Interest
                                </Button>
                                :
                                <></>
                        }
                        {
                            interest_sent_status === 1 && interest_received_status === 0 && interest_sent_status === 1 ?
                                <Button
                                    className={`primaryButton intrestSentStyle`}
                                    onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                >
                                    Interest Sent
                                </Button>
                                :
                                <></>
                        }
                        <Button
                            className={`${style.sortListedButton} ${shortlisted ? style.sortlisted : ""}`}
                            onClick={() => handleShortlist(token, shortlisted ? 0 : 1)}
                        >
                            <Image
                                src={shortlisted ? sortlistStarIcon : sortlistGrayStarIcon}
                            />
                            {shortlisted ? "Shortlisted" : "Shortlist"}
                        </Button>
                    </div>
                </div>
                {
                    interest_received_status === 2 ?
                        <div className={`${style.intrestSentContainer} ${style.accepted}`}>
                            <p className={style.declineInfoText}>You have accepted the interest request, now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    interest_received_status === 3 ?
                        <>
                            <p className={style.declineInfoText}>You declined the interest request.</p>
                            <div className={style.aboutDescription}>
                                <Image
                                    src={infoIcon}
                                />
                                <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                            </div>
                        </>
                        :
                        <></>
                }
                {
                    interest_sent_status === 2 ?
                        <div className={`${style.intrestSentContainer}`}>
                            <p className={style.declineInfoText}>{name} has accepted your interest.</p>
                            <p className={style.declineInfoText}> Now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        interest_sent_status === 3 ?
                            <>
                                <p className={style.declineInfoText}>Dr. {name} has declined your interest.</p>
                                <div className={style.aboutDescription}>
                                    <Image
                                        src={infoIcon}
                                    />
                                    <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                                </div>
                            </>
                            :
                            <></>
                }
                {
                    interest_received_status === 1 ?
                        <div className={style.buttonProgressContainer}>
                            <p className={style.declineInfoText}>Dr.{name} has expressed interest in you. Do you want to accept their request?</p>
                            <div className={style.buttonsContianer}>
                                <Button
                                    className={`${style.button} primaryButton`}
                                    onClick={() => handleAccept(token)}
                                >
                                    Accept
                                </Button>
                                <Button
                                    className={`${style.declineButton} primaryButton cancelButton`}
                                    onClick={() => {
                                        setRejectToken(token)
                                        avoidScroll()
                                        setShowRejectReasonModal(true)
                                        // handleCancel(token)
                                    }}
                                >
                                    Decline
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                }
            </>
        )
    }

    useEffect(() => {
        if (successChatSetter) {
            navigation("/chat", { state: { token: chatToken } })
        }
        if (errorChatSetter) {
            console.log("errorChatSetter", errorChatSetter);
        }
    }, [successChatSetter, errorChatSetter]);

    const handleChat = (token, name, user_id, image) => {
        chatRoomSetup(
            token,
            name,
            user_id,
            image
            , (data) => {
                setChatToken(data)
            })
    }

    const handleScroll = () => {
        const container = pageContentContainerRef.current;
        if (!container || loadingMore || lastPage) return; // Do nothing if loading, or last page reached.

        // Check if the user has scrolled to the bottom of the container
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            // Load more data when the user reaches the bottom
            setLoadingMore(true);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const container = pageContentContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <>
            {
                (interestClicked && showBecameMemberModal) ? (
                    <BecameMemberModal status={true} setInterestClicked={setInterestClicked} />
                ) : <></>
            }
            <ModalBox
                open={showRejectReasonModal}
                width={"598px"}
                modalContent={
                    <DeclineModal
                        buttonText={"Okay"}
                        setRejectReason={setRejectReason}
                        setShowRejectReasonModal={setShowRejectReasonModal}
                        onClick={handleCancel}
                    />
                }
            />
            {error ? (
                <div className={style.loaderContainer}>
                    <p className={style.error}>{error}</p>
                </div>
            ) : !isLoading ? (
                <div className={style.pageContentContainer} ref={pageContentContainerRef}>
                    <p className={style.title}>Search results for the term of "{term}"</p>
                    <div className={style.subHeadingContainer}>
                        <p className={style.heading}>{total} profiles found</p>
                    </div>
                    <div className={style.pageContent}>
                        {
                            data?.length > 0 ?
                                data?.map((item, index) => (
                                    <div key={item.user_id} className={style.content}>
                                        <div className={style.imageAndDetailsContainer}>
                                            <div className={style.detailsContainer}>
                                                <Image
                                                    onClick={() => navigation("/profile_details", { state: { token: item?.token } })}
                                                    src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                    className={style.profileImage}
                                                />
                                            </div>
                                            <div className={style.detailsContainer}>
                                                <div className={style.headerContainer}>
                                                    <div className={style.nameContainer}>
                                                        <p className={style.id} onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>{item.user_id}</p>
                                                    </div>
                                                </div>
                                                <h1 className={style.name} onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>{item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}<span>({item.age} yrs)</span></h1>
                                                {item?.is_onboard_completed === 1 ? <>
                                                    <div className={style.personalDetails}>
                                                        <p className={style.personaTtext}>{item.height}</p>
                                                        <p className={style.personaTtext}>{item.mother_tongue}</p>
                                                        <p className={style.personaTtext}>{item.community}</p>
                                                        <p className={style.personaTtext}>{item.cityname}</p>
                                                    </div>
                                                    <div className={style.personalDetails}>
                                                        <Image
                                                            src={professionIcon}
                                                        />
                                                        <p className={style.personaTtext}>{item.occupation}</p>
                                                        <p className={style.personaTtext}>{item.annual_income}</p>
                                                    </div>
                                                    <p className={style.aboutText}>About {item.name}</p>
                                                    <p className={style.aboutDescription}>{item.about}</p>
                                                </> : <div className='non-onboarded-user'> </div>}
                                                {
                                                    actionUi(
                                                        item?.can_send_interest,
                                                        item?.interest_received_status,
                                                        item?.interest_sent_status,
                                                        item?.shortlisted,
                                                        item?.is_connected,
                                                        item?.token,
                                                        item?.name,
                                                        item?.image,
                                                        item?.user_id,

                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                !isLoading && (
                                    <NoData isChat={false} title={"No Profiles Found"} />
                                )
                        }
                    </div>
                    {loadingMore && !lastPage && (
                        <div className={style.loaderContainer}>
                            <Circles
                                height="35"
                                width="35"
                                color="rgb(239, 116, 53)"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass={style.loader}
                                visible={true}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={style.loaderContainer}>
                    <Circles
                        height="35"
                        width="35"
                        color="rgb(239, 116, 53)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass={style.loader}
                        visible={true}
                    />
                </div>
            )}
        </>
    );
}
