import React, { useContext, useState, useRef, useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import style from './FloatingDatePicker.module.css';
import calenderIcon from "../../assets/SVG/Reports/DatePick.svg";
import Image from "../Img/Img";
// import { SignUpContext } from "../../modules/web/features/Signup/Context/SignUpContext";
import PopoutInput from "../PopoutInput/PopoutInput";

export default function FloatingDatePicker({ label, selectedDate, onChange, error, customStyle, clearVlaue }) {
  const [isOpen, setIsOpen] = useState(false);
  const calendarRef = useRef(null);

  const handleCalendarChange = (date) => {
    onChange(timestampToMMDDYYYY(date));
    setIsOpen(false);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  // const [isOpen, setIsOpen] = useState(false);

  // const handleCalendarChange = (date) => {
  //   onChange(timestampToMMDDYYYY(date));
  //   setIsOpen(false);
  // };

  function timestampToMMDDYYYY(timestamp) {
    const date = new Date(timestamp);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear().toString(); // Get year in 'yyyy' format
    return `${year}-${month}-${day}`;
  }



  function convertDateFormat(inputDate) {
    const currentDate = new Date();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString();
    return `${year}-${month}-${day}`;
  }

  return (
    <div style={customStyle} className={`datePickerContainer`}>
      <PopoutInput
        name={label}
        label={label}
        onClick={() => setIsOpen(!isOpen)}
        value={selectedDate}
        error={error}
        onChange={() => { }}
      />
      <div className={style.customIcon}>
        <Image
          onClick={() => setIsOpen(!isOpen)}
          src={calenderIcon}
        />
      </div>
      {isOpen && (
        <div ref={calendarRef}>
          <Calendar
            value={convertDateFormat(selectedDate)}
            onChange={handleCalendarChange}
          />
        </div>
      )}
    </div>
  );
}

