import React from 'react'
import style from "./CeleberateGift.module.css"
import Image from '../../../../../ui/Image/Image'
import coupleVector from "../../../assets/png/LandingPage/coupleVector.png"

export default function CeleberateGift() {
  return (
    <div className={`${style.celeberateGiftContainer} container`}>
        <div className={`${style.headerContainer}`}>
            <h1>Celebrate Your Match with Exclusive Gifts from Doctors Matrimony!</h1>
            <p>At Doctors Matrimony, we understand that finding a life partner is a significant milestone, and we want to make your journey even more special. That's why we're excited to introduce our exclusive Assured Gifts offer for couples whose marriages are arranged through our app.</p>
        </div>
        <div className={`${style.imageContainer}`}>
            <Image
                className={style.celebrateImage}
                src={coupleVector}
            />
        </div>
    </div>
  )
}
