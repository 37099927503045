import React, { useContext, useState } from 'react'
import style from './ReligiousDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'
import Image from '../../../../../ui/Image/Image'
import pdfIcon from "../../../assets/common/pdf.svg"
import ModalBox from '../../../../../ui/Modal/ModalBox'
import { DeclineModal } from '../../Matches/DeclineModal/DeclineModal'
import { Viewer } from './Viewer/Viewer'
import { addScroll, avoidScroll, checkPdf } from '../../../../../utils/utils'
import axios from 'axios'

export default function ReligiousDetails() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.profile_details?.user_profile_details?.religious_details[0]
  const [ showRejectReasonModal, setShowRejectReasonModal ] = useState(false)

  // function downloadImage(url, name){
  //   fetch(url)
  //     .then(resp => resp.blob())
  //     .then(blob => {
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.style.display = 'none';
  //         a.href = url;
  //         // the filename you want
  //         a.download = name;
  //         document.body.appendChild(a);
  //         a.click();
  //         window.URL.revokeObjectURL(url);
  //     })
  //     .catch(() => alert('An error sorry'));
  // }

  // Using fetch
  async function downloadImage(imageSrc, name) {
    console.log("imageSrc.includes", imageSrc.includes(".pdf"));
    if( imageSrc.includes(".pdf") ) {
      const proxyUrl = imageSrc;
      const image = await fetch(proxyUrl)
      const imageBlog = await image.blob()
      const imageURL = URL.createObjectURL(imageBlog)
  
      const link = document.createElement('a')
      link.href = imageURL
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else {
      window.open(imageSrc);
    }
  }

  return (
    <div className={style.personalDetailsContainer}>
        <ModalBox
            open={showRejectReasonModal} 
            width={"598px"} 
            modalContent={
                <Viewer 
                  buttonText={"Okay"}
                  doc={process.env.REACT_APP_S3_CLOUD_FRONT_URL + details?.horoscope}
                  onClick={()=> {
                    addScroll()
                    setShowRejectReasonModal(false)
                  }}
                />
            }
        />
        <p className={style.title}>Religious Details</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            <div className={style.personalsDetail}>
              <p className={style.label}>Religion</p>
              <p className={style.title}>{details?.religion}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Raasi</p>
              <p className={style.title}>{details?.raasi}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Gothram</p>
              <p className={style.title}>{details?.gothram}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Star</p>
              <p className={style.title}>{details?.star}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Community</p>
              <p className={style.title}>{details?.community}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Chevvai Dosham</p>
              <p className={style.title}>{details?.chevai_dosam}</p>
            </div>
            <div className={`${style.personalsDetail} ${style.horoscopeData}`}>
              <p className={style.label}>Horoscope</p>
              <div className={style.horoscopeDetailsContainer}>
                {
                  details?.horoscope !== "Not specified" ? (
                    <>
                      {
                        checkPdf(details?.horoscope) ?
                          <Image onClick={()=> {
                              avoidScroll()
                             setShowRejectReasonModal(true)
                          }} src={pdfIcon} width={"70px"} height={"50px"}/>
                        :
                        <Image onClick={()=> {
                          avoidScroll()
                         setShowRejectReasonModal(true)
                      }} src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+details?.horoscope} width={"70px"} height={"50px"}/>
                      }
                    </>
                  ) : (
                    <p>{details?.horoscope}</p>
                  )
                }
                {
                  details?.horoscope !== "Not specified" && (
                    <p className={style.downloadButton}
                      onClick={() =>
                        downloadImage(
                          process.env.REACT_APP_S3_CLOUD_FRONT_URL + details?.horoscope,
                          details?.horoscope
                        )
                      }>Download</p>
                  )
                }
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
