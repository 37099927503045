import React, { useEffect, useState } from "react"
import style from "./Feedback.module.css"
import PopoutInput from "../../../../../ui/PopoutInput/PopoutInput"
import Button from "../../../../../ui/Button/Button"
import PopoutTextarea from "../../../../../ui/PopoutTextarea/PopoutTextarea"
import { addScroll, getUserToken } from "../../../../../utils/utils"
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';

export const Feedback = ({setViewDetailsModal, setViewSuccessModal, setSuccessMessage}) => {

    const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    const [formData, setFormData] = useState({
        email: "",
        message: "",
    });
    const [formDataError, setFormDataError] = useState({
        email: "",
        message: "",
    });
    const [ loader, setLoader ] = useState(false)

    const [ validationError, setValidationError ] = useState("")

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      useEffect(() => {
        if (success) {
            setSuccessMessage(success)
            setViewDetailsModal(false)
            setViewSuccessModal(true)
        }
        if (error !== '') {
          setValidationError(error)
        }
        setLoader(false)
      }, [success, error]);
    

    const submit = () => {
        
          const { email, message } = formData;
        
          const newFormDataError = {}; // Create a new object to store error messages
        
          if (!email) {
            newFormDataError.email = "Please enter the Title";
          } else {
            newFormDataError.email = "";
          }
        
          if (!message) {
            newFormDataError.message = "Please enter the Feedback Message";
          } else {
            newFormDataError.message = "";
          }
  
          // Update the error messages for each input field
          setFormDataError({
            ...formDataError,
            ...newFormDataError,
          });
        
          // Check if there are any error messages
          const hasErrors = Object.values(newFormDataError).some((message) => message !== "");
        
          if (!hasErrors) {
            // If there are no errors, proceed to submit the form
            setLoader(true);
            
            uploadUserDetails({
              url: "footer/feedback/create",
              method: 'POST',
              body: {
                "title" : email,
                "message" : message.trim()
              },
              headers: {
                Authorization: `Bearer ${getUserToken()}`
              }
            });
          }
    }

    return(
        <div className={style.feedbackContainer}>
            <p className={style.heading}>Feedback</p>
            <p className={style.titleDescription}>Encourage users to provide feedback on their experience using the app. Include both positive feedback and constructive criticism to demonstrate transparency and improvement efforts.</p>
            <div className={style.formContainer}>
                <PopoutInput
                    label="Title"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    style={{
                        paddingBottom: formDataError.email ? "0px" : '26px',
                        marginBottom:  formDataError.email ? "10px" : '0px',
                    }}
                />
                { 
                    formDataError.email ? 
                        <p 
                            className="error"
                            style={{
                                paddingBottom: formDataError.email ? "10px" : '26px',
                                marginBottom:  formDataError.email ? "10px" : '0px',
                            }}
                        >
                            {formDataError.email}
                        </p> 
                    : 
                        <></> 
                }

                <PopoutTextarea
                    focus={true}
                    characterLimit={255}
                    label="Feedback Message"
                    name="message"
                    className={style.moreAbout}
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder={"What do you want to tell us about."}
                />
                { 
                    formDataError.message ? 
                    <p 
                        className="error"
                        style={{
                            paddingBottom: formDataError.message ? "10px" : '26px',
                            marginBottom:  formDataError.message ? "10px" : '0px',
                        }}
                    >
                        {formDataError.message}
                    </p> 
                    : 
                        <></> 
                }
            </div>
            {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
            <div className={style.buttonContainer}>
                <div className={style.buttonProgressContainer}>
                    <div className={style.buttonContainer}>
                        <Button 
                            className={`${style.button} ${style.declineButton} primaryButton cancelButton`}
                            onClick={()=> {
                                addScroll()
                                setViewDetailsModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={`${style.button} primaryButton`}
                            onClick={submit}
                            loading={isLoading}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}