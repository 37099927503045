import React, { useState, useEffect } from 'react'
import style from './ProfileSettings.module.css'
import SwitchComponts from '../SwitchComponts/SwitchComponts'
import useApiCall from '../../../hooks/useApiCall';
import { getUserData, getUserToken } from '../../../../../utils/utils';
import config from '../../../../../app.json';

const ProfileSettings = () => {
    const { sendRequest: getProfileSettings } = useApiCall();
    const { sendRequest: getPublicAccess } = useApiCall();
    const { sendRequest: getElite } = useApiCall();
    const { sendRequest: getPrivatePhoto } = useApiCall();

    const [showProfile, setShowProfile] = useState('');
    const [membersShipe, setMembersShipe] = useState('');
    const [acceptedPhoto, setAcceptedPhoto] = useState('');

    const [profileSettings, seProfileSettings] = useState([])

    console.log('profileSettings', profileSettings);

    console.log('showProfile', showProfile);
    console.log('membersShipe', membersShipe);
    console.log('acceptedPhoto', acceptedPhoto);


    const handleSwitchShowProfile = (event) => {
        const newChecked = event.target.checked;
        setShowProfile(newChecked);
        console.log('showProfile:', newChecked);
        getPublicAccessApi()
    };

    const handleSwitchMembersShipe = (event) => {
        const newChecked = event.target.checked;
        setMembersShipe(newChecked);
        console.log('membersShipe:', newChecked);
        getEliteApi()
    };

    const handleSwitchAcceptedPhoto = (event) => {
        const newChecked = event.target.checked;
        setAcceptedPhoto(newChecked);
        console.log('acceptedPhoto:', newChecked);
        getPrivatePhotoApi()
    };

    const getPublicAccessApi = () => {
        getPublicAccess(
            {
                url: "my-account/privacy-setting/toggle-public-access",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
            }
        );
    }
    const getEliteApi = () => {
        getElite(
            {
                url: "my-account/privacy-setting/toggle-only-elite",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
            }
        );
    }
    const getPrivatePhotoApi = () => {
        getPrivatePhoto(
            {
                url: "my-account/privacy-setting/toggle-private-photo",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
            }
        );
    }


    const getProfileSettingsApi = () => {
        getProfileSettings(
            {
                url: "my-account/privacy-setting/read",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                seProfileSettings(data.data)
            }
        );
    }

    useEffect(() => {
        getProfileSettingsApi()

    }, []);

    useEffect(() => {
        if (profileSettings.public_access === 1) {
            setShowProfile(true)
        } else if (profileSettings.public_access === 0) {
            setShowProfile(false)
        }
        if (profileSettings.only_elite === 1) {
            setMembersShipe(true)
        } else if (profileSettings.only_elite === 0) {
            setMembersShipe(false)
        }
        if (profileSettings.photo_lock === 1) {
            setAcceptedPhoto(true)
        } else if (profileSettings.photo_lock === 0) {
            setAcceptedPhoto(false)
        }
    }, [profileSettings])


    return (
        <div className={style.pageContentContainer} >
            <div className={style.edit_cont}>
                <p className={style.title}>Profile Settings</p>
                <p className={style.sub__title}>Your Profile Privacy has been set as "Show my Profile to all including visitors"</p>
            </div>
            {/* <div className={style.profile_settings}>
                <SwitchComponts title="Show my Profile to all including visitors" subTitle="This will help prospects share your profile with their family members, who are not registered members." checked={showProfile} onChange={handleSwitchShowProfile} />
            </div> */}
            {
                getUserData()?.membership_type !== "Basic" && (
                    <>
                        {
                            getUserData()?.membership_type === "Elite" && (
                                <div className={style.profile_settings}>
                                    <SwitchComponts title="Show my Profile to Elite members only" checked={membersShipe} onChange={handleSwitchMembersShipe} />
                                </div>
                            )
                        }
                        <div className={style.profile_settings}>
                            <SwitchComponts title="Show my Profile Photos to contacted/Accepted members only" checked={acceptedPhoto} onChange={handleSwitchAcceptedPhoto} />
                        </div>
                    </>
                )
            }
        </div>
    )
}

export default ProfileSettings