import React, { useState, useEffect } from "react";
import Tabs from "../../UI/Tabs/Tabs";
import Button from "../../UI/Button/Button";
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import DropdownButton from "../../UI/DropdownButton/DropdownButton";
import Table from "../../UI/Table/TablePage";
import TableHeader from "../../UI/Table/TableHeader";
import Search from "../../UI/Search/Search";
import useApiHttp from "../../hooks/ues-http";
import { BASE_URL } from "../../utils/baseUrl";
import classes from "./Reports.module.css";
import "react-toastify/dist/ReactToastify.css";
import SelectBox from "../../UI/SelectFiled/SelectBox";
import FloatingDatePicker from "./FloatingDatePicker/FloatingDatePicker";
import totalusers from "../../assets/SVG/Dashboard/TotalUsers.svg";
import activeusers from "../../assets/SVG/Dashboard/ActiveUsers.svg";
import totalrevenue from "../../assets/SVG/Dashboard/TotalRevenue.svg";
import Card from "../../UI/Card/Card";
import invoice from "../../assets/SVG/Manage Users/DownloadInvoice.svg";
import Image from "../../UI/Img/Img";
import Tooltip from "@material-ui/core/Tooltip";
import invoicedisble from "../../assets/SVG/Manage Users/DownloadInvoicegray.svg";
import ReusableModal from "../../UI/Modal/ScrollingModal/ScrollingModal";
import { AnimatePresence, motion } from "framer-motion";

const Reports = () => {
  const [refresh, setRefresh] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [downloadCsvs, setDownloadCsvs] = useState("");
  const [userIdModal, setUserIdModal] = useState(false);
  const [userIdSate, setUserIdSate] = useState([]);
  const [paymentsSate, setPaymentsSate] = useState([]);
  const [types, setTypes] = useState("");
  const [paymentsSearch, setPaymentsSearch] = useState("");
  const [paymentsTotalRecords, setPaymentsTotalRecords] = useState("");
  const [paymentsTotalDisplayRecords, setPaymentsTotalDisplayRecords] =
    useState("");
  const [sortPaymentsDirectionData, setSortPaymentsDirectionData] =
    useState("DESC");
  const [paymentsColumn, setPaymentsColumn] = useState(0);
  const [paymentsPerPage, setPaymentsPerPage] = useState(10);
  const [paymentsPage, setPaymentsPage] = useState(1);

  const [snapshot, setSnapshot] = useState([]);
  const [snapshotSearch, setSnapshotSearch] = useState("");
  const [snapshotTotalRecords, setSnapshotTotalRecords] = useState("");
  const [snapshotTotalDisplayRecords, setSnapshotTotalDisplayRecords] =
    useState("");
  const [sortSnapshotDirectionData, setSortSnapshotDirectionData] =
    useState("DESC");
  const [snapshotColumn, setSnapshotColumn] = useState(0);
  const [snapshotPerPage, setSnapshotPerPage] = useState(10);
  const [snapshotPage, setSnapshotPage] = useState(1);
  const [reports, setReports] = useState([]);
  const [datePickerValue, setDatePickerValue] = useState(true);
  const [matchdatePickerValue, setMatchdatePickerValue] = useState(true);
  const [paymentsDates, setPaymentsDates] = useState({
    from_date: "",
    to_date: "",
  });
  const [snapshotDates, setSnapshotDates] = useState({
    from_date: "",
    to_date: "",
  });
  const [validationError, setValidationError] = useState("");

  const { sendRequest: reportsRequest } = useApiHttp();
  const reportsData = (data) => {
    setReports(data.data);
  };
  const reportsRequestData = () => {
    reportsRequest(
      {
        url: `${BASE_URL}report-member-count`,
        method: "POST",
      },
      reportsData
    );
  };

  useEffect(() => {
    reportsRequestData();
  }, []);

  useEffect(() => {
    if (paymentsDates.from_date) {
      setDatePickerValue(false);
    } else {
      setDatePickerValue(true);
    }
    if (snapshotDates.from_date) {
      setMatchdatePickerValue(false);
    } else {
      setMatchdatePickerValue(true);
    }
  }, [paymentsDates, snapshotDates]);

  const {
    isLoading: paymentsLoding,
    success: paymentsSucces,
    error: paymentsError,
    sendRequest: paymentsRequest,
  } = useApiHttp();
  const paymentsData = (data) => {
    setPaymentsSate(data.data);
    setPaymentsTotalRecords(data.iTotalRecords);
    setPaymentsTotalDisplayRecords(data.iTotalDisplayRecords);
    setValidationError("");
  };

  const paymentsRequestData = () => {
    const requestData = {
      startDate: paymentsDates.from_date,
      endDate: paymentsDates.to_date,
      payment_type: types,
      search_value: paymentsSearch,
      page: paymentsPage,
      per_page: paymentsPerPage,
      sort_dir: sortPaymentsDirectionData,
      sort_column: paymentsColumn,
    };
    paymentsRequest(
      {
        url: `${BASE_URL}report-membership-payment`,
        method: "POST",
        body: requestData,
      },
      paymentsData
    );
  };
  const handleSearchChange = (newValue) => {
    setPaymentsSearch(newValue);
  };

  const handleSort = async (column, sortDirection) => {
    setSortPaymentsDirectionData(sortDirection || "desc");
    setPaymentsColumn(column.sortField || 0);
  };
  const handlePageChange = (page) => {
    setPaymentsPage(page || 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPaymentsPerPage(newPerPage || 10);
  };

  useEffect(() => {
    paymentsRequestData();
  }, [
    paymentsSearch,
    paymentsPage,
    paymentsPerPage,
    sortPaymentsDirectionData,
    paymentsColumn,
  ]);

  useEffect(() => {
    downlodFlagUserData();
  }, [paymentsSearch]);

  useEffect(() => {
    downlodFeedbacksRequestData();
  }, [snapshotSearch]);

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "User ID",
      selector: (row) => (
        <>
          <span
            className="action_text action_text_pointer"
            onClick={() => viewUserIdModal(row.user_token)}>
            {row.user_id}
          </span>
        </>
      ),
      sortable: true,
      sortField: 1,
    },
    {
      name: "User Name",
      selector: (row) => (
        <>
          <span>{row.name}</span>
        </>
      ),
      sortable: true,
      sortField: 2,
    },
    {
      name: "Contact Number",
      selector: (row) => row.mobile_number,
      sortable: true,
      sortField: 3,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      sortField: 4,
    },
    {
      name: "Plan",
      selector: (row) => (
        <>
          <span>{row.plan}</span>
        </>
      ),
      sortable: true,
      sortField: 5,
    },
    {
      name: "Plan Amount",
      selector: (row) => row.amount,
      sortable: true,
      sortField: 6,
    },
    {
      name: "Payment Date",
      selector: (row) => row.start_date,
      sortable: true,
      sortField: 7,
    },
    {
      name: "Next Payment",
      cell: (row) => row.end_date,
      sortable: true,
      sortField: 8,
    },
    {
      name: "Invoice",
      cell: (row) => (
        <>
          <Tooltip
            title={
              row.invoice === "Unavailable" ? row.invoice : "Download Invoice"
            }>
            <span
              onClick={() => {
                if (row.invoice !== "Unavailable") {
                  handleDownloadInvoice(row.invoice, row.invoice_name);
                }
              }}
              className={`${
                row.invoice === "Unavailable"
                  ? "unavailable"
                  : "action_text action_text_pointer w__300"
              }`}>
              {row.invoice === "Unavailable" ? (
                <Image src={invoicedisble} alt="Invoice" />
              ) : (
                <Image src={invoice} alt="Invoice" />
              )}{" "}
              Download
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const handleDownloadInvoice = (fileLink, fileName) => {
    if (fileLink) {
      fetch(fileLink)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const linkElement = document.createElement("a");
          linkElement.href = url;
          linkElement.setAttribute("download", fileName);
          document.body.appendChild(linkElement);
          linkElement.click();
          document.body.removeChild(linkElement);
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
        });
    }
  };

  const {
    isLoading: snapshotLoding,
    success: snapshotSucces,
    error: snapshotError,
    sendRequest: snapshotRequest,
  } = useApiHttp();
  const snapshotData = (data) => {
    setSnapshot(data.data);
    setSnapshotTotalRecords(data.iTotalRecords);
    setSnapshotTotalDisplayRecords(data.iTotalDisplayRecords);
    setValidationError("");
  };

  const snapshotRequestData = () => {
    const requestData = {
      startDate: snapshotDates.from_date,
      endDate: snapshotDates.to_date,
      search_value: snapshotSearch,
      page: snapshotPage,
      per_page: snapshotPerPage,
      sort_dir: sortSnapshotDirectionData,
      sort_column: snapshotColumn,
    };
    snapshotRequest(
      {
        url: `${BASE_URL}report-match-snaps`,
        method: "POST",
        body: requestData,
      },
      snapshotData
    );
  };
  const handleSnapshotSearchChange = (newValue) => {
    setSnapshotSearch(newValue);
  };

  const handleSnapshotSort = async (column, sortDirection) => {
    setSortSnapshotDirectionData(sortDirection || "desc");
    setSnapshotColumn(column.sortField || 0);
  };
  const handleSnapshotPageChange = (page) => {
    setSnapshotPage(page || 1);
  };

  const handleSnapshotPerRowsChange = async (newPerPage, page) => {
    setSnapshotPerPage(newPerPage || 10);
  };

  const snapshotColumns = [
    {
      name: "S.No",
      selector: (row) => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
      sortField: 1,
    },
    {
      name: "Total Profiles",
      selector: (row) => row.total_profile,
      sortable: true,
      sortField: 2,
    },

    {
      name: "Pending Requests",
      selector: (row) => row.pending_request,
      sortable: true,
      sortField: 3,
    },
    {
      name: "Accepted Requests",
      selector: (row) => row.accepted_request,
      sortable: true,
      sortField: 4,
    },
    {
      name: "Rejected Requests",
      selector: (row) => row.rejected_request,
      sortable: true,
      sortField: 5,
    },
  ];

  useEffect(() => {
    snapshotRequestData();
  }, [
    snapshotSearch,
    snapshotPage,
    snapshotPerPage,
    sortSnapshotDirectionData,
    snapshotColumn,
  ]);

  const handleChange = (event) => {
    setTypes(event.target.value);
  };

  const handleGenerate = (data) => {
    if (data === "GeneratePayments") {
      if (types === "") {
        if (paymentsDates.from_date === "") {
          setValidationError("Please Select From Date");
        } else {
          if (paymentsDates.to_date === "") {
            setValidationError("Please Select To Date");
          } else {
            paymentsRequestData();
          }
        }
      } else {
        paymentsRequestData();
      }
      downlodFlagUserData();
    } else if (data === "GenerateSnapshot") {
      if (snapshotDates.from_date === "") {
        setValidationError("Please Select From Date");
      } else {
        if (snapshotDates.to_date === "") {
          setValidationError("Please Select To Date");
        } else {
          snapshotRequestData();
        }
      }
      downlodFeedbacksRequestData();
    }
  };

  // Export CSV
  const { sendRequest: downlodFlagUserRequest } = useApiHttp();
  const downlodFlagUsertData = (data) => {
    setDownloadCsvs(data);
  };
  const downlodFlagUserData = () => {
    const requestData = {
      startDate: paymentsDates.from_date,
      endDate: paymentsDates.to_date,
      payment_type: types,
      search_value: paymentsSearch,
    };

    downlodFlagUserRequest(
      {
        url: `${BASE_URL}csv-membership-report`,
        method: "POST",
        body: requestData,
      },
      downlodFlagUsertData
    );
  };

  const { sendRequest: downlodFeedbacksRequest } = useApiHttp();
  const downlodFeedbacksData = (data) => {
    setDownloadCsvs(data);
  };
  const downlodFeedbacksRequestData = () => {
    downlodFeedbacksRequest(
      {
        url: `${BASE_URL}csv-match-snaps`,
        method: "POST",
        body: {
          startDate: snapshotDates.from_date,
          endDate: snapshotDates.to_date,
          search_value: snapshotSearch,
        },
      },
      downlodFeedbacksData
    );
  };

  const handleExportCSV = () => {
    if (activeTab === 0) {
      if (downloadCsvs) {
        const link = document.createElement("a");
        link.href = downloadCsvs.download_link;
        link.download = "downloaded.csv";
        link.click();
      }
    } else if (activeTab === 1) {
      if (downloadCsvs) {
        const link = document.createElement("a");
        link.href = downloadCsvs.download_link;
        link.download = "downloaded.csv";
        link.click();
      }
    }
  };

  const handleMenuItemClick = (item) => {
    // Handle the menu item click event here
    if (item.label === "CSV") {
      handleExportCSV();
    }
  };

  const { sendRequest: paymentsRequestR } = useApiHttp();
  const paymentsDataR = (data) => {
    setPaymentsSate(data.data);
    setPaymentsTotalRecords(data.iTotalRecords);
    setPaymentsTotalDisplayRecords(data.iTotalDisplayRecords);
  };

  const paymentsRequestDataR = () => {
    const requestData = {
      startDate: "",
      endDate: "",
      payment_type: "",
      search_value: "",
      page: 1,
      per_page: 10,
      sort_dir: "DESC",
      sort_column: 0,
    };
    paymentsRequestR(
      {
        url: `${BASE_URL}report-membership-payment`,
        method: "POST",
        body: requestData,
      },
      paymentsDataR
    );
  };

  const { sendRequest: snapshotRequestR } = useApiHttp();
  const snapshotDataR = (data) => {
    setSnapshot(data.data);
    setSnapshotTotalRecords(data.iTotalRecords);
    setSnapshotTotalDisplayRecords(data.iTotalDisplayRecords);
  };

  const snapshotRequestDataR = () => {
    const requestData = {
      startDate: "",
      endDate: "",
      payment_type: "",
      search_value: "",
      page: 1,
      per_page: 10,
      sort_dir: "DESC",
      sort_column: 0,
    };
    snapshotRequestR(
      {
        url: `${BASE_URL}report-match-snaps`,
        method: "POST",
        body: requestData,
      },
      snapshotDataR
    );
  };

  const toggleRefresh = () => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 200); // Refreshing after 1 second
  };

  const onTabClick = (index) => {
    setActiveTab(index); // Update the active tab index
    toggleRefresh();
    if (index === 0) {
      downlodFlagUserData();
      setPaymentsSearch("");
      setPaymentsDates({
        ...paymentsDates,
        from_date: "",
        to_date: "",
      });
      setTypes("");
      snapshotRequestDataR();
      paymentsRequestDataR();
      setValidationError("");
    } else if (index === 1) {
      downlodFeedbacksRequestData();
      setSnapshotSearch("");
      setSnapshotDates({
        ...snapshotDates,
        from_date: "",
        to_date: "",
      });
      setTypes("");
      paymentsRequestDataR();
      snapshotRequestDataR();
      setValidationError("");
    }
  };

  const { sendRequest: UserIdRequest } = useApiHttp();
  const UserIdData = (data) => {
    setUserIdSate(data.data);
  };
  const UserIdRequestData = (token) => {
    if (token === null) {
      setUserIdModal(false);
    } else {
      setUserIdModal(true);
    }
    UserIdRequest(
      {
        url: `${BASE_URL}basic-user-detail`,
        method: "POST",
        body: {
          token: token,
        },
      },
      UserIdData
    );
  };

  const viewUserIdModal = (data) => {
    UserIdRequestData(data);
  };
  const clearAll = () => {
    if (activeTab === 0) {
      setPaymentsSearch("");
      setPaymentsDates({
        ...paymentsDates,
        from_date: "",
        to_date: "",
      });
      setTypes("");
      paymentsRequestDataR();
      setValidationError("");
    } else if (activeTab === 1) {
      setSnapshotSearch("");
      setSnapshotDates({
        ...snapshotDates,
        from_date: "",
        to_date: "",
      });
      setTypes("");
      snapshotRequestDataR();
      setValidationError("");
    }
  };
  const staus = [
    { token: "Elite", designation: "Elite" },
    { token: "Premium", designation: "Premium" },
  ];

  const tabAnimation = {
    initial: {
      y: -200,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      y: -200,
      opacity: 0,
    },
  };
  // ======== Animation Function only end ========

  const cardData = [
    {
      src: totalusers,
      title: "Total Users",
      subTitle: reports.total_user,
      className: "card_box total_members",
    },
    {
      src: activeusers,
      title: "Active Users",
      subTitle: reports.active_user,
      className: "card_box active_members",
    },
    {
      src: totalrevenue,
      title: "Total Revenue",
      subTitle: reports.total_revenue,
      className: "card_box gren",
    },
  ];

  return (
    <AnimatePresence>
      <PagesHeader
        title="Report"
        children={
          <DropdownButton
            buttonLabel="Download as"
            menuItems={[
              {
                label: "CSV",
                render: () => <span onClick={handleExportCSV}>Export CSV</span>,
              },
            ]}
            onMenuItemClick={handleMenuItemClick}
          />
        }
      />

      <section className="tablesection_heder">
        <motion.div
          initial={{ opacity: 0 }} // Initial position off-screen to the left
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={classes.membership_payments_card}>
          {cardData.map((card, index) => (
            <motion.div
              initial={{ opacity: 0, x: -300 }} // Initial position off-screen to the left
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -300 }}
              transition={{
                duration: 0.8,
                delay: index * 0.2,
              }}
              key={index}>
              <Card
                src={card.src}
                title={card.title}
                subTitle={card.subTitle == undefined ? 0 : card.subTitle}
                className={card.className}
              />
            </motion.div>
          ))}
        </motion.div>
        <Tabs
          tabs={[
            {
              title: "Membership Payments",
              render: () => (
                <>
                  <div className={classes.top_report_section}>
                    <div style={{ width: "751px", position: "relative" }}>
                      {validationError === "Please Select From Date" && (
                        <p className={`${classes.controle_set} text_danger`}>
                          {validationError}
                        </p>
                      )}
                      <FloatingDatePicker
                        selectedDate={paymentsDates.from_date}
                        onChange={(e) =>
                          setPaymentsDates({ ...paymentsDates, from_date: e })
                        }
                        label={"From Date"}
                        customStyle={{
                          width: "100%",
                          minWidth: "150px",
                        }}
                      />
                    </div>
                    <div style={{ width: "751px", position: "relative" }}>
                      {validationError === "Please Select To Date" && (
                        <p className={`${classes.controle_set} text_danger`}>
                          {validationError}
                        </p>
                      )}
                      <FloatingDatePicker
                        selectedDate={paymentsDates.to_date}
                        onChange={(e) =>
                          setPaymentsDates({ ...paymentsDates, to_date: e })
                        }
                        label={"To Date"}
                        customStyle={{
                          width: "100%",
                          minWidth: "150px",
                        }}
                        minDate={paymentsDates.from_date}
                        disabled={datePickerValue}
                      />
                    </div>
                    <div style={{ width: "751px" }}>
                      <SelectBox
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={types}
                        label="Status"
                        onChange={handleChange}
                        labelname="Status"
                        nonename="Status"
                        data={staus}
                      />
                    </div>
                    <Button
                      className={classes.login_btn}
                      onClick={() => handleGenerate("GeneratePayments")}>
                      Generate
                    </Button>

                    {paymentsDates.from_date !== "" || types !== "" ? (
                      <p className={classes.clear} onClick={() => clearAll()}>
                        Clear
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  {refresh && (
                    <motion.dev
                      variants={tabAnimation}
                      initial="initial"
                      animate="animate"
                      exit="exit">
                      <Table
                        data={paymentsSate}
                        onSort={handleSort}
                        columns={columns}
                        loader={paymentsLoding}
                        paginationTotalRows={paymentsTotalRecords}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        children={
                          <div className="header_table">
                            <TableHeader
                              tableheader="Membership Payments"
                              totalusers={`${paymentsTotalRecords} total membership payments`}
                            />
                            <Search
                              value={paymentsSearch}
                              onChange={(newValue) =>
                                handleSearchChange(newValue)
                              }
                              type="text"
                              placeholder="Search"
                            />
                          </div>
                        }
                      />
                    </motion.dev>
                  )}
                </>
              ),
            },
            {
              title: "Match Snapshot",
              render: () => (
                <>
                  <div className={classes.top_snapshot_section}>
                    <div style={{ width: "751px", position: "relative" }}>
                      {validationError === "Please Select From Date" && (
                        <p className={`${classes.controle_set} text_danger`}>
                          {validationError}
                        </p>
                      )}
                      <FloatingDatePicker
                        selectedDate={snapshotDates.from_date}
                        onChange={(e) =>
                          setSnapshotDates({ ...snapshotDates, from_date: e })
                        }
                        label={"From Date"}
                        customStyle={{
                          width: "100%",
                          minWidth: "150px",
                        }}
                      />
                    </div>
                    <div style={{ width: "751px", position: "relative" }}>
                      {validationError === "Please Select To Date" && (
                        <p className={`${classes.controle_set} text_danger`}>
                          {validationError}
                        </p>
                      )}
                      <FloatingDatePicker
                        selectedDate={snapshotDates.to_date}
                        onChange={(e) =>
                          setSnapshotDates({ ...snapshotDates, to_date: e })
                        }
                        label={"To Date"}
                        minDate={snapshotDates.from_date}
                        customStyle={{
                          width: "100%",
                          minWidth: "150px",
                        }}
                        disabled={matchdatePickerValue}
                      />
                    </div>
                    <Button
                      className={classes.login_btn}
                      onClick={() => handleGenerate("GenerateSnapshot")}>
                      Generate
                    </Button>

                    {snapshotDates.from_date !== "" && (
                      <p className={classes.clear} onClick={() => clearAll()}>
                        Clear
                      </p>
                    )}
                  </div>

                  {refresh && (
                    <motion.dev
                      variants={tabAnimation}
                      initial="initial"
                      animate="animate"
                      exit="exit">
                      <Table
                        data={snapshot}
                        onSort={handleSnapshotSort}
                        columns={snapshotColumns}
                        loader={snapshotLoding}
                        paginationTotalRows={snapshotTotalRecords}
                        onChangeRowsPerPage={handleSnapshotPerRowsChange}
                        onChangePage={handleSnapshotPageChange}
                        children={
                          <div className="header_table">
                            <TableHeader
                              tableheader="Match Snapshot"
                              totalusers={`${snapshotTotalRecords} total match snapshot`}
                            />
                            <Search
                              value={snapshotSearch}
                              onChange={(newValue) =>
                                handleSnapshotSearchChange(newValue)
                              }
                              type="text"
                              placeholder="Search"
                            />
                          </div>
                        }
                      />
                    </motion.dev>
                  )}
                </>
              ),
            },
          ]}
          onTabClick={onTabClick}
          activeTab={activeTab}
        />
      </section>
      <>
        <ReusableModal
          open={userIdModal}
          setOpen={setUserIdModal}
          scrollType="body"
          title={`User ID- ${userIdSate.user_id} `}
          size="sm"
          type="view"
          okay="ok">
          <>
            <div className={classes.feedback_container}>
              <div className={classes.profile_image}>
                <img src={userIdSate.image} alt="userImage" />
              </div>
              <div className={classes.module__text_Container}>
                <div>
                  <p className={classes.contant_box_titel}>User ID</p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate.user_id}
                  </p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>User Name</p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate.user_name}
                  </p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>
                    NMC or SMC number Number
                  </p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate.imr_number}
                  </p>
                </div>
              </div>
              <div className={classes.module__text_Container}>
                <div>
                  <p className={classes.contant_box_titel}>Gender</p>
                  <p className={classes.contant_box_sub}>{userIdSate.gender}</p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>Mobile</p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate.mobile_number}
                  </p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>Email</p>
                  <p className={classes.contant_box_sub}>{userIdSate.email}</p>
                </div>
              </div>
              <div className={classes.module__text_Container}>
                <div>
                  <p className={classes.contant_box_titel}>Age</p>
                  <p className={classes.contant_box_sub}>{userIdSate.age}</p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>Education</p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate && userIdSate.education
                      ? userIdSate.education.replace(/,/g, ", ")
                      : ""}
                  </p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>Membership Type</p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate.membership_type}
                  </p>
                </div>
              </div>
              <div className={classes.module__text_Container}>
                <div>
                  <p className={classes.contant_box_titel}>
                    Institution Working
                  </p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate.institution_working}
                  </p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>City</p>
                  <p className={classes.contant_box_sub}>{userIdSate.city}</p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>State</p>
                  <p className={classes.contant_box_sub}>{userIdSate.state}</p>
                </div>
                <div>
                  <p className={classes.contant_box_titel}>Country</p>
                  <p className={classes.contant_box_sub}>
                    {userIdSate.country}
                  </p>
                </div>
              </div>

              <div>
                <p className={classes.contant_box_titel}>About</p>
                <p className={classes.contant_box_sub}>{userIdSate.abount}</p>
              </div>
            </div>
          </>
        </ReusableModal>
      </>
    </AnimatePresence>
  );
};

export default Reports;

