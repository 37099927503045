import React from 'react'
import style from "./InputWithLabel.module.css"

export default function InputWithLabel({label, placeholder, customStyle, onChange, value, name}) {
  return (
    <div>
        <p className={style.label}>{label}</p>
        <input onChange={onChange} name={name} value={value} className={customStyle} type="text" placeholder={placeholder}/>
    </div>
  )
}
