
import React, { useEffect, useState } from "react"
import style from "./ContactUs.module.css"
import PopoutInput from "../../../../../ui/PopoutInput/PopoutInput"
import Button from "../../../../../ui/Button/Button"
import PopoutTextarea from "../../../../../ui/PopoutTextarea/PopoutTextarea"
import { addScroll, getUserToken } from "../../../../../utils/utils"
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import PopoutMobileInput from "../../../../../ui/PopoutMobileInput/PopoutMobileInput"
import { phoneCode } from "../../../../../Data/data"

export const ContactUs =  ({setViewDetailsModal, setViewSuccessModal, setSuccessMessage}) => {

    const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    const [formData, setFormData] = useState({
        email: "",
        mobile: "",
        message: "",
    });
    const [formDataError, setFormDataError] = useState({
        email: "",
        mobile: "",
        message: "",
    });
    const [ loader, setLoader ] = useState(false)
    const [selectedValue, setSelectedValue] = useState(`+${phoneCode[0].code}`); // State to store the selected value

    const [ validationError, setValidationError ] = useState("")

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleMobileNumberChange = (mobileValue) => {
        setFormData({
          ...formData,
          mobile: mobileValue,
        });
      };

    useEffect(() => {
        if (success) {
            setSuccessMessage(success)
            setViewDetailsModal(false)
            setViewSuccessModal(true)
        }
        if (error !== '') {
          setValidationError(error)
        }
        setLoader(false)
    }, [success, error]);

    const submit = () => {
        const validateEmail = (email) => {
            const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            return regex.test(email);
        };
        
          const { email, mobile, message } = formData;
        
          const newFormDataError = {}; // Create a new object to store error messages
        
          if (!email?.trim()) {
            newFormDataError.email = "Please enter the Email";
          } else if (!validateEmail(email?.trim())) {
            newFormDataError.email = "Please enter a valid Email";
          } else {
            newFormDataError.email = "";
          }

          if (!mobile?.trim()) {
            newFormDataError.mobile = "Please enter the Mobile number";
          } else {
            if( mobile?.trim()?.length !== 10 ) {
                newFormDataError.mobile = "Please enter a valid Mobile number";
            } else {
                newFormDataError.mobile = "";
            }
          }
        
          if (!message?.trim()) {
            newFormDataError.message = "Please enter the Message";
          } else {
            newFormDataError.message = "";
          }
  
          // Update the error messages for each input field
          setFormDataError({
            ...formDataError,
            ...newFormDataError,
          });
        
          // Check if there are any error messages
          const hasErrors = Object.values(newFormDataError).some((message) => message !== "");
        
          if (!hasErrors) {
            // If there are no errors, proceed to submit the form
            setLoader(true);

            let dataForApi = {
                url: "contact-us/create",
                method: 'POST',
                body: {
                  "email" : email?.trim(),
                  "mobile_number": mobile?.trim(),
                  "message" : message.trim()
                }
            }
            if( getUserToken() ) {
                dataForApi.headers = {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }
            
            uploadUserDetails(dataForApi);
          }
    }

    return(
        <div className={style.feedbackContainer}>
            <p className={style.heading}>Contact Us</p>
            <p className={style.titleDescription}>Leave us a message, we will contact with you as soon as possible.</p>
            <div className={style.formContainer}>
                <PopoutInput
                    label="Email Address"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    style={{
                        paddingBottom: formDataError.email ? "0px" : '26px',
                        marginBottom:  formDataError.email ? "10px" : '0px',
                    }}
                />
                { 
                    formDataError.email ? 
                        <p 
                            className="error"
                            style={{
                                paddingBottom: formDataError.email ? "10px" : '26px',
                                marginBottom:  formDataError.email ? "10px" : '0px',
                            }}
                        >
                            {formDataError.email}
                        </p> 
                    : 
                        <></> 
                }
                <PopoutInput
                  name={"mobile"}
                  label={"Mobile Number"}
                  value={formData.mobile}
                  onChange={handleInputChange}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  style={{
                    paddingBottom: formDataError.mobile ? "0px" : '26px',
                    marginBottom:  formDataError.mobile ? "10px" : '0px',
                    }}
                />
                { 
                    formDataError.mobile ? 
                    <p 
                        className="error"
                        style={{
                            paddingBottom: formDataError.mobile ? "10px" : '26px',
                            marginBottom:  formDataError.mobile ? "10px" : '0px',
                        }}
                    >
                        {formDataError.mobile}
                    </p> 
                    : 
                        <></> 
                }

                <PopoutTextarea
                    focus={true}
                    characterLimit={255}
                    label="Leave Messgae"
                    name="message"
                    className={style.moreAbout}
                    value={formData.message}
                    onChange={handleInputChange}
                    placeholder={"What do you want to tell us about."}
                />
                { 
                    formDataError.message ? 
                    <p 
                        className="error"
                        // style={{
                        //     paddingBottom: formDataError.message ? "10px" : '26px',
                        //     marginBottom:  formDataError.message ? "10px" : '0px',
                        // }}
                    >
                        {formDataError.message}
                    </p> 
                    : 
                        <></> 
                }
            </div>
            {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
            <div className={style.buttonContainer}>
                <div className={style.buttonProgressContainer}>
                    <div className={style.buttonContainer}>
                        <Button 
                            className={`${style.button} ${style.declineButton} primaryButton cancelButton`}
                            onClick={()=> {
                                addScroll()
                                setViewDetailsModal(false)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className={`${style.button} primaryButton`}
                            onClick={submit}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}