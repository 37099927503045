import React, { useEffect, useState } from "react"
import style from "./TermsAndConditions.module.css"
import { useNavigate } from "react-router-dom"
import useApiCall from '../../hooks/useApiCall';
import ReactHtmlParser from 'react-html-parser'
import { Circles } from  'react-loader-spinner'

export const TermsAndConditions = () => {
    const navigation = useNavigate()
    window.scrollTo(0, 0)

    const [ data, setData ] = useState(null)
    const { isLoading: isLoading, success: success, error: error, sendRequest: getMyprofile } = useApiCall();

    const [ loading, setLoading ] = useState(false)

    useEffect(()=> {
        setLoading(true)
        console.log("isLoading", isLoading);
        getMyprofile(
            {
                url: "footer/terms-and-conditions",
                method: 'GET',
            },
            (data) => {
                setData(data)
                setLoading(false)
            }
        );
    },[])

    console.log("loading", loading);

    return(
        <div className={`${style.contentContainer} container`}>
            <div className={style.arrowAndTitleContainer}>
                {/* <Image onClick={()=> navigation(-1) } src={backArrowIcon} className={style.arrowIcon}/> */}
                <p className={style.title}>
                    { data?.message }
                </p>
            </div>
            <p className={style.description}>
                {
                    loading ?
                        <Circles
                            height="20"
                            width="20"
                            color="rgba(239,116,53, 1)"
                            ariaLabel="circles-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    :
                    ReactHtmlParser(data?.data?.detail)
                }
            </p>
        </div>
    )
}