import React, { useState, useEffect } from 'react'
import style from './MakeProfilePrivate.module.css'
import SwitchComponts from '../SwitchComponts/SwitchComponts'
import ModalBox from "../../../../../ui/Modal/ModalBox";
import Privatemodul from './Modal/Privatemodul';
import useApiCall from '../../../hooks/useApiCall';
import { getUserToken } from '../../../../../utils/utils';
import config from '../../../../../app.json';

const MakeProfilePrivate = () => {

    const { sendRequest: getMakeProfilePrivate } = useApiCall();
    const { sendRequest: getPrivateAcount } = useApiCall();

    const [makeProfilePrivate, setMakeProfilePrivate] = useState([])
    console.log("makeProfilePrivate", makeProfilePrivate);
    const [viewDetailsModal, setViewDetailsModal] = useState(false)

    const [privateAccount, setPrivateAccount] = useState('');


    const handlePrivateAccount = (event) => {
        const newChecked = event.target.checked;
        setPrivateAccount(newChecked)
        if (newChecked === true) {
            setViewDetailsModal(true)
        } else if (newChecked === false) {
            getPrivateAcountApi()
        }
    };


    const okay = () => {
        setViewDetailsModal(false)
        getPrivateAcountApi()
    }



    const getPrivateAcountApi = () => {
        getPrivateAcount(
            {
                url: "my-account/private-profile/toggle",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
        );
    }
    const getMakeProfilePrivateApi = () => {
        getMakeProfilePrivate(
            {
                url: "my-account/private-profile/read",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                setMakeProfilePrivate(data.data)
            }
        );
    }

    useEffect(() => {
        getMakeProfilePrivateApi()

    }, []);

    useEffect(() => {
        if (makeProfilePrivate.private_profile === 1) {
            setPrivateAccount(true)
        } else if (makeProfilePrivate.private_profile === 0) {
            setPrivateAccount(false)
        }
    }, [makeProfilePrivate])


    return (
        <>
            <div className={style.pageContentContainer} >
                <div className={style.edit_cont}>
                    <p className={style.title}>Make Profile Private</p>
                    <p className={style.sub__title}>Your profile status is currently <span className={style.active}>active</span>. If you would like to change your status, please select Deactivate Now.</p>
                </div>
                <div className={style.notification}>
                    <div className={style.profile_settings}>
                        <SwitchComponts title="Private Account" subTitle="Want to take a short break from your life partner search?  You will not be able to contact others but can still view their profile but other members will not be able to see and contact you." checked={privateAccount} onChange={handlePrivateAccount} />
                    </div>

                </div>

            </div>


            <ModalBox
                open={viewDetailsModal}
                width={"400px"}
                modalContent={
                    <Privatemodul setViewDetailsModal={setViewDetailsModal} setPrivateAccount={setPrivateAccount} onClick={okay} />
                } />

        </>
    )
}

export default MakeProfilePrivate
