import React, { useEffect } from "react"
import style from "./ApplicationExpired.module.css"
import Image from "../../../../ui/Image/Image"
import expiredIcon from "../../assets/common/expiredIcon.svg"
import { useNavigate } from "react-router-dom"
import useApiCall from "../../hooks/useApiCall"
import { getUserToken } from "../../../../utils/utils"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ApplicationExpired = () => {

    const navigation = useNavigate()
    const { isLoading: isSkipLoading, success: successSkip, error: errorSkip, sendRequest: skipPreference } = useApiCall();

    useEffect(() => {
        if (successSkip) {
            localStorage.setItem('subscriptionFromHome', 0);
            navigation("/home")
        }
        if (errorSkip !== '') {
            toast.error(errorSkip, {
                autoClose: 2500,
            })
        }
    }, [successSkip, errorSkip]);

    const handleSkip = () => {
        skipPreference({
            url: "payment/skip-as-basic-user",
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        });
    }

    return(
        <div className={`${style.ApplicationExpiredContainer}`}>
            <ToastContainer />
            <p className={style.title}>Plan Expired</p>
            <Image src={expiredIcon} />
            <p className={style.description}>To continue enjoying the benefits of our platform and increasing your chances of finding the ideal match, we encourage you to renew your subscription plan today.</p>
            <p className={style.subscribeButton} onClick={()=> navigation("/subscribe")}>Subscribe Now</p>
            <p className={style.freeUserText} onClick={handleSkip}>Continue as basic user</p>
        </div>
    )
}