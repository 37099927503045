import { useState, useCallback } from 'react';
import axios from 'axios'; // Import Axios

const useApiHttp = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [code, setCode] = useState(null);
    const [error, setError] = useState(null);
    const [errorData, setErrorData] = useState(null);

    const sendRequest = useCallback(async (requestConfig, applyData = null) => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);
        try {
            const response = await axios({
                method: requestConfig.method ? requestConfig.method : 'GET',
                url: process.env.REACT_APP_BASE_URL+ "users/" + requestConfig.url,
                headers: requestConfig.headers
                    ? requestConfig.headers
                    : {
                        'Content-Type': 'application/json',
                    },
                data: requestConfig.body ? requestConfig.body : null,
            });

            const data = response?.data;

            const currentPagePath = window.location.url

            if (response?.status === 200 || response?.status === 201) {
                setSuccess(data?.message);
            }
            if (response?.status !== 200 && response?.status !== 201) {
                throw new Error(data?.message);
            }

            setCode(response?.status)

            applyData && applyData(data);

        } catch (err) {

            setCode(err?.response?.status)
            setErrorData(err?.response?.data)
            if( err?.response?.status === 401 ) {
                localStorage.removeItem("token");
                localStorage.removeItem("subscriptionFromHome");
                localStorage.removeItem("user");
                window.location.href = '/';
            }
            if (err?.response?.status === 301) {
                const status = err?.response?.data?.data?.status
                if( status === "Expired" ) {
                    localStorage.setItem('subscriptionFromHome', 3);
                }
                const pathname = window.location.pathname
                // switch (status) {
                //     case "Pending":
                //         if( pathname !== "/status" ) {
                //             window.location.href = '/status';
                //         }
                //         break;
                //     case "Rejected":
                //         // if( pathname !== "/status" ) {
                //         //     window.location.href = '/status';
                //         // }
                //         break;
                //     case "Blocked":
                //         if( pathname !== "/status" ) {
                //             window.location.href = '/status';
                //         }
                //         break;
                //     case "Expired":
                //         if( pathname !== "/subscribe" ) {
                //             window.location.href = '/subscribe';
                //         }
                //         break;
                //     case "Verified":
                //         if( pathname !== "/subscribe" ) {
                //             localStorage.setItem('subscriptionFromHome', 0);
                //             window.location.href = '/subscribe';
                //         }
                //         break;
                //     case "Onboarding":
                //         if( pathname !== "/build_profile" ) {
                //             window.location.href = '/build_profile';
                //         }
                //         break;
                //     case "Preferencing":
                //         if( pathname !== "/partner_preference" ) {
                //             window.location.href = '/partner_preference';
                //         }
                //         break;
                //     case "Active":
                //         if( pathname !== "/home" ) {
                //             window.location.href = '/home';
                //         }
                //         break;
                // }
            }
            setError(err.response?.data?.message || 'Something went wrong!');
        }
        setIsLoading(false);
    }, []);

    return {
        isLoading,
        success,
        error,
        code,
        errorData,
        sendRequest,
    };
};

export default useApiHttp;
