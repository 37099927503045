import * as React from 'react';
import classes from './Chip.module.css'

export default function Chips({ label }) {
    return (

        <div className={classes.chips}>
            <span className={classes.chip_text}>{label}</span>
        </div>

    );
}