import React, { useEffect, useMemo,useRef, useState } from "react";
import style from "../../../pages/Chat/Chat.module.css"
import Image from "../../../../../ui/Image/Image"
import drImage from "../../../pages/Chat/assets/Dr. Vidhya@2x.png"
import moreIcon from "../../../assets/common/Menu.svg"
import messageSentImage from "../../../pages/Chat/assets/Message Sent.svg"
import { collection,doc,setDoc,getDoc,Timestamp,onSnapshot,query,orderBy,where } from "firebase/firestore"; 

const SingleMessage = (props) => {
    const {msgSource,dateGroup,message,time} = props;
    const senderClass = msgSource === 'sent' ? style.right : "";
    const currentDiv = useRef(null);

    useEffect(() => {
        // window.scrollTo(0,0)
        if (currentDiv.current) {
            // currentDiv.current.scrollIntoView({
            //   behavior: 'smooth',
            // });
          }
    }, [])
    return(
        <div ref={currentDiv} className={style.notificationMessagesContainer}>
            {dateGroup !== '' && (
                <div className={style.dateTime}>{dateGroup}</div>

            )}
        
            <div className={`${style.notificationMessageContainer} ${senderClass}`}>
                <p className={style.message}>{message}</p>
                <p className={style.time}>{time}</p>
            </div>
    </div>
    );
}

export default SingleMessage;