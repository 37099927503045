import React, { useState } from 'react';
import PropTypes from 'prop-types';
import style from './Dropdown.module.css';
import dropdownIcon from "../assets/dropdown.svg"
import { phoneCode } from '../../Data/data';

const Dropdown = ({ label, value, options = phoneCode, mobileDropdown, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (option) => {
    setIsOpen(false); // Close the dropdown
    onSelect(option); // Call the onSelect callback with the selected option
  };

  return (
    <>
      <div className={`${style.dropdownClasses} ${ mobileDropdown ? style.mobileDropdown : "" }`}>
        <div className={style.dropdown}>
          { !mobileDropdown && <p className={style.label}>{label}</p> }
          <div className={style.containerValue}>
            <p className={style.value} style={{ marginRight: mobileDropdown ? "10px" : "0px" }}>{value}</p>
            {
              !mobileDropdown && (
                <img
                  src={dropdownIcon}
                  width={"24px"}
                  height={"24px"}
                  className={style.logo}
                  alt="Dropdown Icon"
                  onClick={toggleDropdown}
                />
              )
            }
          </div>
        </div>
        { (isOpen && !mobileDropdown) && (
          <ul className={style.dropdownOptions}>
            {options?.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionSelect(mobileDropdown ? option.code : option)}
              >
                { mobileDropdown ? option.code : option}
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired, // Ensure that value is a required prop
  mobileDropdown: PropTypes.bool,
  onSelect: PropTypes.func.isRequired, // Callback to handle option selection
};

export default Dropdown;
