import React, { useState } from 'react';
import styles from './PopoutInput.module.css';

function PopoutInput({ label, placeholder, value, onChange, style, name, disabled, onClick, error }) {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // const handleInputChange = (e) => {
  //   if (name === 'mobile') {
  //     // If the name is "mobile," restrict the input to only numbers and 10 digits.
  //     const inputValue = e.target.value.replace(/\D/g, '').slice(0, 10);
  //     onChange({ target: { name, value: inputValue } });
  //   } else {
  //     // For other input fields, allow any input.
  //     onChange(e);
  //   }
  // };

  return (
    <div style={style} className={`${styles['popout-input']} ${isFocused || value ? styles.focused : ''}`}>
      <label className={styles['input-label']}>{label}</label>
      <input
        type="text"
        className={`${styles['input-field']} ${error ? "dataError" : ""}`}
        name={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled ? true : false}
        onChange={onChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={onClick}
        autocomplete="off"
      />
    </div>
  );
}

export default PopoutInput;
