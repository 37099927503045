import React, { useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import profileicon from '../../assets/SVG/common/profile.svg'
import logouticon from '../../assets/SVG/common/logout.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import classes from './profil.module.css'
import { logOut } from '../../hooks/Auth';
import { useNavigate } from "react-router-dom";
import useHttp from '../../hooks/ues-http'
import { BASE_URL } from '../../utils/baseUrl';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Swal from 'sweetalert2';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomizedMenus() {
    const { isLoading, error, success, sendRequest: LogOutRequest } = useHttp();
    useEffect(() => {
        if (success !== '') {
            toast.success(success, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (error !== '') {
            toast.error(error, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [success, error]);

    const navigate = useNavigate();

    const logoutUser = () => {
        const VerifyEmailData = (data) => {
        }
        LogOutRequest({
            url: `${BASE_URL}logout`,
            method: 'POST',
        }, VerifyEmailData);
        logOut();
        navigate("/admin");
    }

    const logOutSwal = () => {
        handleClose()
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                title: 'your-custom-title-class',
                content: 'your-custom-content-class',
                confirmButton: 'your-custom-button-class green__btn',
                cancelButton: 'your-custom-button-class red__btn',
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Are you sure?",
            text: "You want to logout this page?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes",
            cancelButtonText: "Cancel",
            reverseButtons: true,
            confirmButtonColor: '#E04848',
            cancelButtonColor: '#aeaeae',
        }).then((result) => {
            if (result.isConfirmed) {
                swalWithBootstrapButtons.fire({
                    title: "Logout!",
                    text: "Logout successfully!",
                    icon: "success"
                }).then((result) => {
                    if (result.isConfirmed) {
                        logoutUser()
                    }
                });
            } else if (
                result.dismiss === Swal.DismissReason.cancel
            ) {
                swalWithBootstrapButtons.fire({
                    title: "Cancelled",
                    text: "You've cancelled the logout",
                    icon: "error"
                });
            }
        });
    }

    const userName = localStorage.getItem('name');
    const userMail = localStorage.getItem('email');

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={classes.profile}>
            <Stack direction="row" spacing={2}>
                <Avatar alt="Remy Sharp" src="" />
            </Stack>
            <div className={classes.profile_name}>
                <p>{userName}</p>
                <Button
                    id="demo-positioned-button"
                    aria-controls={open ? 'demo-positioned-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                >
                    {userMail}
                </Button>
                <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    <MenuItem disableRipple className='profile_list' onClick={logOutSwal}>
                        <img src={logouticon} alt="Logout" className={classes.menuicon} />
                        Logout
                    </MenuItem>

                </StyledMenu>
            </div>

        </div>
    );
}