import React, { useState, useEffect, useRef } from 'react'
import Tabs from "../../UI/Tabs/Tabs";
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import Table from "../../UI/Table/TablePage";
import useApiHttp from '../../hooks/ues-http';
import TableHeader from "../../UI/Table/TableHeader";
import Search from '../../UI/Search/Search';
import Button from '../../UI/Button/Button';
import Modal from '../../UI/Modal/Modal';
import classes from './ManageGifts.module.css';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { RadioButton } from '../../UI/RadioButton/RadioButton'
import TextField from '../../UI/Textarea/Textarea'
import TextArea from './Textarea'
import uploadIcon from '../../assets/SVG/common/Upload Video.svg';
import uploadimge from '../../assets/SVG/common/Upload Image.svg';
import deleteIcon from '../../assets/SVG/common/Bin.svg';
import uploadWhiteIcon from '../../assets/SVG/common/upload-white.svg';
import { BASE_URL } from '../../utils/baseUrl';
import SelectBox from '../../UI/SelectFiled/SelectBoxSecond';
import { uploadFile } from '../../../../utils/utils';
import Image from '../../../../ui/Image/Image';
import FloatingDatePicker from '../../UI/FloatingDatePicker/FloatingDatePicker';
import VideoPlayers from '../../features/mangeGift/Video/video';
import PlayBtn from '../../assets/SVG/Manage Gifts/Play Video.svg'
import ReusableModal from '../../UI/Modal/ScrollingModal/ScrollingModal';
import ProgressBar from '../../UI/Progressbar/Progressbar';
import Swal from 'sweetalert2';
import { AnimatePresence, motion } from 'framer-motion';
import { style } from '@mui/system';
import checkedIcon from "../../../web/assets/svg/Signup/checked.svg"
import uncheckedIcon from "../../../web/assets/svg/Signup/uncheck.svg"

const ManageGifts = () => {
    const [errors, setErrors] = useState({
        videoFile: false,
        imageFile: false,
        groom_id: false,
        bride_id: false,
        marriage_date: false,
        description: false,
    });
    const [videoUplodingTime, setVideoUplodingTime] = useState(false);
    const [termsChecked, setTermsChecked] = useState(false);
    const [termsCheckedRevoke, setTermsCheckedRevoke] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [charsLeft, setCharsLeft] = useState(0);
    const uploadVideoInput = useRef(null);
    const [activeTab, setActiveTab] = useState(0);
    const [giftModal, setGiftModal] = useState(false);
    const [storiesModal, setStoriesModal] = useState(false);
    const [videoModal, setVideoModal] = useState(false);
    const [addVideoModal, setAddVideoModal] = useState(false);
    const [clear, setClear] = useState(false);
    const [verifidUser, setVerifidUser] = useState("1");
    const [storiesUser, setStoriesUser] = useState("1");
    const [formData, setFormData] = useState({
        gift_reason: '',
        stories_resane: '',
    });
    const [videoInterviews, setVideoInterviews] = useState({
        groom_id: '',
        bride_id: '',
        marriage_date: '',
        description: '',
    });
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [uploadedImage, setUploadedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    // ====================requested ==================
    const [requestedData, setRequestedData] = useState([]);
    const [requestedDataTotal, setrequestedDataTotal] = useState(0);
    const [iTotalDisplayRecordsRequest, setITotalDisplayRecordsRequest] = useState(0);
    const [requestedSearch, setRequestedSearch] = useState('');
    const [sortDirectionData, setSortDirectionData] = useState('DESC');
    const [column, setColumn] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    // =====================Success Store ==================
    const [successData, setSuccessData] = useState([]);
    const [successDataTotal, setSuccessDataTotal] = useState(0);
    const [iTotalDisplayRecordsSuccess, setITotalDisplayRecordsSuccess] = useState(0);
    const [successSearch, setSuccessSearch] = useState('');
    const [sortDirectionSuccess, setSortDirectionSuccess] = useState('DESC');
    const [columnSuccess, setColumnSuccess] = useState(0);
    const [perPageSuccess, setPerPageSuccess] = useState(10);
    const [pageSuccess, setPageSuccess] = useState(1);
    // =====================Video Interviews ==================
    const [videoData, setVideoData] = useState([]);
    const [videoDataTotal, setVideoDataTotal] = useState(0);
    const [iTotalDisplayRecordsVideo, setITotalDisplayRecordsVideo] = useState(0);
    const [videoSearch, setVideoSearch] = useState('');
    const [sortDirectionVideo, setSortDirectionVideo] = useState('DESC');
    const [columnVideo, setColumnVideo] = useState(0);
    const [perPageVideo, setPerPageVideo] = useState(10);
    const [pageVideo, setPageVideo] = useState(1);

    const [giftDatas, setGiftDatas] = useState([]);
    const [storiesDatas, setStoriesDatas] = useState([]);
    const [videoInterviewDatas, setVideoInterviewDatas] = useState([]);

    const [groomidDropdown, setGroomidDropdown] = useState([]);
    const [brideidDropdown, setBrideidDropdown] = useState([]);
    const [groomidImgDropdown, setGroomidImgDropdown] = useState([]);
    const [brideidImgDropdown, setBrideidImgDropdown] = useState([]);
    const [errorMsg, setErrorMsg] = useState(false);

    const { isLoading: videosLoding, sendRequest: videosRequest } = useApiHttp();
    const { isLoading: requestedLoding, success: requestedSucces, error: requestedError, sendRequest: requestedRequest } = useApiHttp();
    const requestedDatas = (data) => {
        setRequestedData(data.aaData)
        setrequestedDataTotal(data.iTotalRecords)
        setITotalDisplayRecordsRequest(data.iTotalDisplayRecords)
    };
    const requestedRequestData = () => {
        const requestData = {
            search_value: requestedSearch,
            page: page,
            per_page: perPage,
            sort_column: column,
            sort_dir: sortDirectionData,
        };
        requestedRequest({
            url: `${BASE_URL}gift-list`,
            method: 'POST',
            body: requestData,
        }, requestedDatas);
    };
    useEffect(() => {
        requestedRequestData()

    }, [requestedSearch, page, perPage, column, sortDirectionData]);

    const columns = [
        {
            name: 'S.No',
            selector: (row) => row.slno,
            sortable: false,
            sortField: 0,
        },
        {
            name: 'RID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => giftViewUPdate(row.gift_id)}>{row.gift_id}</span></>),
            sortable: true,
            sortField: 1,
        },
        {
            name: 'Groom Name',
            selector: (row) => row.groom_name,
            sortable: true,
            sortField: 2,
        },
        {
            name: 'Groom ID',
            selector: (row) => row.partner_token,
            sortable: true,
            sortField: 3,
        },
        {
            name: 'Bride Name',
            selector: (row) => row.bride_name,
            sortable: true,
            sortField: 4,
        },
        {
            name: 'Bride ID',
            selector: (row) => row.user_token,
            sortable: true,
            sortField: 5,
        },
        {
            name: 'Engaement Date',
            selector: (row) => row.engagement_date,
            sortable: true,
            sortField: 6,
        },
        {
            name: 'Marriage Date',
            selector: (row) => row.marriage_date,
            sortable: true,
            sortField: 7,
        },
        {
            name: 'Mobile Number',
            selector: (row) => row.mobile,
            sortable: true,
            sortField: 8,
        },
        {
            name: 'status',
            cell: (row) => (
                <>
                    {row.status === 'Pending' && (
                        <span className="wigite_lable_satus pending">{row.status}</span>
                    )}
                    {row.status === 'Approved' && (
                        <span className="wigite_lable_satus verified">{row.status}</span>
                    )}
                    {row.status === 'Declined' && (
                        <span className="wigite_lable_satus rejected">{row.status}</span>
                    )}
                    {row.status === 'Revoked' && (
                        <span className="wigite_lable_satus rejected">{row.status}</span>
                    )}
                </>
            ),
            sortable: true,
            sortField: 9,
        },

    ];
    const handleSort = async (column, sortDirection) => {
        setSortDirectionData(sortDirection || 'desc');
        setColumn(column.sortField || 0);

    };
    const handlePageChange = (page) => {
        setPage(page || 1)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage || 10);
    };
    const handleSearchChange = (newValue, type) => {
        if (type === 'gift') {
            setRequestedSearch(newValue)
        } else if (type === 'success') {
            setSuccessSearch(newValue)
        } else if (type === 'video') {
            setVideoSearch(newValue)
        }
    };

    const { isLoading: successLoding, success: successSucces, error: successError, sendRequest: successRequest } = useApiHttp();
    const successDatas = (data) => {
        setSuccessData(data.aaData)
        setSuccessDataTotal(data.iTotalRecords)
        setITotalDisplayRecordsSuccess(data.iTotalDisplayRecords)
    };
    const successRequestData = () => {
        const requestData = {
            search_value: successSearch,
            page: pageSuccess,
            per_page: perPageSuccess,
            sort_column: columnSuccess,
            sort_dir: sortDirectionSuccess,
        };
        successRequest({
            url: `${BASE_URL}success-stories`,
            method: 'POST',
            body: requestData,
        }, successDatas);
    };
    useEffect(() => {
        successRequestData()
    }, [successSearch, pageSuccess, perPageSuccess, columnSuccess, sortDirectionSuccess]);

    const columns2 = [
        {
            name: 'S.No',
            selector: (row) => row.slno,
            sortable: false,
            sortField: 0,
        },
        {
            name: 'RID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => storiesViewUPdate(row.r_id)}>{row.r_id}</span></>),
            sortable: true,
            sortField: 1,
        },
        {
            name: 'Groom Name',
            selector: (row) => row.groom_name,
            sortable: true,
            sortField: 2,
        },
        {
            name: 'Groom ID',
            selector: (row) => row.groom_id,
            sortable: true,
            sortField: 3,
        },
        {
            name: 'Bride Name',
            selector: (row) => row.bride_name,
            sortable: true,
            sortField: 4,
        },
        {
            name: 'Bride ID',
            selector: (row) => row.bride_id,
            sortable: true,
            sortField: 5,
        },
        {
            name: 'Engaement Date',
            selector: (row) => row.engagement_date,
            sortable: true,
            sortField: 6,
        },
        {
            name: 'Marriage Date',
            selector: (row) => row.marriage_date,
            sortable: true,
            sortField: 7,
        },
        {
            name: 'Mobile Number',
            selector: (row) => row.mobile,
            sortable: true,
            sortField: 8,
        },
        {
            name: 'Submission Count',
            selector: (row) => row.submission_count,
            sortable: true,
            sortField: 8,
        },
        {
            name: 'status',
            cell: (row) => (
                <>
                    {row.status === 'Pending' && (
                        <span className="wigite_lable_satus pending">{row.status}</span>
                    )}
                    {row.status === 'Published' && (
                        <span className="wigite_lable_satus verified">{row.status}</span>
                    )}
                    {row.status === 'Declined' && (
                        <span className="wigite_lable_satus rejected">{row.status}</span>
                    )}
                    {row.status === 'Approved' && (
                        <span className="wigite_lable_satus approved">{row.status}</span>
                    )}
                    {row.status === 'Revoked' && (
                        <span className="wigite_lable_satus rejected">{row.status}</span>
                    )}
                </>
            ),
            sortable: true,
            sortField: 9,
        },

    ];
    const handleSortSuccess = async (column, sortDirection) => {
        setSortDirectionSuccess(sortDirection || 'desc');
        setColumnSuccess(column.sortField || 0);
    };
    const handlePageChangeSuccess = (page) => {
        setPageSuccess(page || 1)
    };
    const handlePerRowsChangeSuccess = async (newPerPage, page) => {
        setPerPageSuccess(newPerPage || 10);
    };
    const videosDatas = (data) => {
        setVideoData(data.aaData)
        setVideoDataTotal(data.iTotalRecords)
        setITotalDisplayRecordsVideo(data.iTotalDisplayRecords)
    };

    const videosRequestData = () => {
        const requestData = {
            search_value: videoSearch,
            page: pageVideo,
            per_page: perPageVideo,
            sort_column: columnVideo,
            sort_dir: sortDirectionVideo,
        };
        videosRequest({
            url: `${BASE_URL}video-interview-list`,
            method: 'POST',
            body: requestData,
        }, videosDatas);
    };

    useEffect(() => {
        videosRequestData()
    }, [videoSearch, pageVideo, perPageVideo, columnVideo, sortDirectionVideo]);

    const columns3 = [
        {
            name: 'S.No',
            selector: (row) => row.slno,
            sortable: false,
            sortField: 0,
        },
        {
            name: 'Video ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => videoViewUPdate(row.video_id)}>{row.video_id}</span></>),
            sortable: true,
            sortField: 1,
        },
        {
            name: 'Groom Name',
            selector: (row) => row.groom_name,
            sortable: true,
            sortField: 2,
        },
        {
            name: 'Groom ID',
            selector: (row) => row.groom_id,
            sortable: true,
            sortField: 3,
        },

        {
            name: 'Bride Name',
            selector: (row) => row.bride_name,
            sortable: true,
            sortField: 4,
        },
        {
            name: 'Bride ID',
            selector: (row) => row.bride_id,
            sortable: true,
            sortField: 5,
        },
        {
            name: 'Marriage Date',
            selector: (row) => row.marriage_date,
            sortable: true,
            sortField: 6,
        },
        {
            name: 'Created Date',
            selector: (row) => row.created_date,
            sortable: true,
            sortField: 7,
        },
        {
            name: "",
            cell: (row) => (<span className='action_text action_text_pointer' onClick={() => roleDelete(row.video_id)}>Delete</span>),
            sortable: false,
        },
    ];

    const handleSortVideo = async (column, sortDirection) => {

        setSortDirectionVideo(sortDirection || 'desc');
        setColumnVideo(column.sortField || 0);

    };
    const handlePageChangeVideo = (page) => {
        setPageVideo(page || 1)
    };
    const handlePerRowsChangeVideo = async (newPerPage, page) => {
        setPerPageVideo(newPerPage || 10);
    };
    const { isLoading: giftViewLoding, sendRequest: giftViewRequest } = useApiHttp();
    const giftViewUPdate = (token) => {
        const requestData = {
            "token": token,
        };
        giftViewRequest({
            url: `${BASE_URL}gift-view`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setGiftDatas(data.data)
        });
        setGiftModal(true)
    }
    const { isLoading: storiesViewLoding, sendRequest: storiesViewRequest } = useApiHttp();
    const storiesViewUPdate = (token) => {
        const requestData = {
            "token": token,
        };
        storiesViewRequest({
            url: `${BASE_URL}success-story-view`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setStoriesDatas(data.data)
        });
        setTermsChecked(false)
        setTermsCheckedRevoke(false)
        setStoriesModal(true)
    }
    const { isLoading: videoLoding, sendRequest: videoRequest } = useApiHttp();
    const videoViewUPdate = (token) => {
        const requestData = {
            "token": token,
        };
        videoRequest({
            url: `${BASE_URL}interview-view`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setVideoInterviewDatas(data.data)
        });
        setVideoModal(true)
    }
    const videosAdd = () => {
        setAddVideoModal(true)
    }

    //================= Update Data Function =================
    const { isLoading: giftUpdateLoding, success: giftUpdateSucces, error: giftUpdateErroe, sendRequest: giftUpdateRequest } = useApiHttp();
    useEffect(() => {
        if (giftUpdateSucces !== '') {
            toast.success(giftUpdateSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (giftUpdateErroe !== '') {
            toast.error(giftUpdateErroe, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [giftUpdateSucces, giftUpdateErroe]);
    const giftUpdateRequestDatas = (gifiId) => {
        const requestData = {
            "token": gifiId,
            "reject_reason": formData.gift_reason,
            "status": verifidUser
        };
        giftUpdateRequest({
            url: `${BASE_URL}gift-update`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setGiftModal(false)
            requestedRequestData()
            setCharsLeft(0)
            setErrorMsg(false)
            setVerifidUser("1")
            setFormData({ ...formData, stories_resane: "", gift_reason: "", })
        });
    }

    const handleGiftUpdate = (gifiId) => {
        if (verifidUser === "0") {
            if (formData.gift_reason !== "") {
                giftUpdateRequestDatas(gifiId)
            } else {
                setErrorMsg(true)
            }
        }
        if (verifidUser === "1") {
            giftUpdateRequestDatas(gifiId)
        }
    }

    const { isLoading: storiesLoding, success: storiesSucces, error: storiesErroe, sendRequest: storiesRequest } = useApiHttp();
    useEffect(() => {
        if (storiesSucces !== '') {
            toast.success(storiesSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (storiesErroe !== '') {
            toast.error(storiesErroe, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [storiesSucces, storiesErroe]);

    const storiesRequestDatas = (rId, status) => {
        console.log("storiesRequestDatas");
        const requestData = {
            "token": rId,
            "reject_reason": formData.stories_resane,
            "status": status
        };
        storiesRequest({
            url: `${BASE_URL}success-story-update`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setStoriesModal(false)
            successRequestData()
            setCharsLeft(0)
            setTermsChecked(false)
            setTermsCheckedRevoke(false)
            setFormData({ ...formData, stories_resane: "", gift_reason: "", })
            toggleRefresh()
        });
    }

    const handleStoriesUpdate = (rId, status=false) => {

        if( ( storiesDatas.status ===  "Published" && !termsCheckedRevoke ) || 
            ( storiesDatas.status ===  "Revoked" && !termsChecked ) ||
            ( storiesDatas.status ===  "Approved" && !termsChecked )  
        ) {
            toast.error("Please fill the form and submit", {
                autoClose: 2500,
            })
            return
        }

        if( storiesDatas.status === "Approved" ) {
            storiesRequestDatas(rId, 2)
        } else if( storiesDatas.status === "Published" ) {
            storiesRequestDatas(rId, 3)
        } else if( storiesDatas.status === "Revoked" ) {
            storiesRequestDatas(rId, 2)
        } else {
            if( storiesDatas.status !== "Approved" && storiesDatas.status !== "Published" ) {
                if (storiesUser === "1") {
                    if (formData.stories_resane !== "") {
                        storiesRequestDatas(rId, storiesUser)
                    } else {
                        setErrorMsg(true)
                    }
                }
                if (storiesUser === "0") {
                    storiesRequestDatas(rId, storiesUser)
                }
            }
        }
    }

    const radioChangeHandler = (e) => {
        setVerifidUser(e.target.value);
        setErrorMsg(false)
        setFormData({ ...formData, stories_resane: "", gift_reason: "", })
    };
    const radioChangeHandlerStories = (e) => {
        setStoriesUser(e.target.value);
        setErrorMsg(false)
        setFormData({ ...formData, stories_resane: "", gift_reason: "", })
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        const charCount = value.length;
        const maxChar = 255;
        const truncatedValue = value.slice(0, maxChar);
        setFormData({ ...formData, [name]: truncatedValue });
        const charLength = Math.min(maxChar, charCount);
        setCharsLeft(charLength);
    };
    // ========================== Uplode Vedeo Uplode =========================
    const { sendRequest: groomidRequest } = useApiHttp();
    const groomIdRequstData = () => {
        groomidRequest({
            url: `${BASE_URL}groomid-dropdown`,
            method: 'POST',
        }, (data) => {
            setGroomidDropdown(data.data)
        });
    }
    useEffect(() => {
        groomIdRequstData()

    }, [])
    const { sendRequest: brideidRequest } = useApiHttp();
    const brideidRequestData = () => {
        brideidRequest({
            url: `${BASE_URL}brideid-dropdown`,
            method: 'POST',
        }, (data) => {
            setBrideidDropdown(data.data)
        });
    }
    useEffect(() => {
        brideidRequestData()
    }, [])

    const { sendRequest: groomidImgRequest } = useApiHttp();
    const groomIdImgRequstData = () => {
        groomidImgRequest({
            url: `${BASE_URL}get-groom-image`,
            method: 'POST',
            body: {
                "groom_token": videoInterviews.groom_id,
            }
        }, (data) => {
            setGroomidImgDropdown(data.data)
        });
    }
    useEffect(() => {
        groomIdImgRequstData()
    }, [videoInterviews])

    const { sendRequest: brideidImgRequest } = useApiHttp();
    const brideidImgRequestData = () => {
        brideidImgRequest({
            url: `${BASE_URL}get-bride-image`,
            method: 'POST',
            body: {
                "bride_token": videoInterviews.bride_id,
            }
        }, (data) => {
            setBrideidImgDropdown(data.data)
        });
    }
    useEffect(() => {
        brideidImgRequestData()
    }, [videoInterviews])


    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const newImages = URL.createObjectURL(file)
            setUploadedImage(newImages)
            setImageFile(file)
        } else {
            console.error('Invalid file type. Please select a video file.');
        }
    }

    const handleVideoUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type.startsWith('video/')) {
                setUploadedVideo(URL.createObjectURL(file));
                setVideoFile(file);
                generateVideoThumbnail(file);
            } else {
                console.error('Invalid file type. Please select a video file.');
            }
        }
    };

    const generateVideoThumbnail = (videoFile) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.onloadedmetadata = () => {
            const canvas = document.createElement('canvas');
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

            const thumbnailURL = canvas.toDataURL('image/jpeg');
            setVideoThumbnail(thumbnailURL); // Store the thumbnail URL in state
        };
        video.src = URL.createObjectURL(videoFile);
    };

    const { success: addVideoInteviewSucces, error: addVideoInteviewErroe, sendRequest: addVideoInteviewRequest } = useApiHttp();
    useEffect(() => {
        if (addVideoInteviewSucces !== '') {
            toast.success(addVideoInteviewSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (addVideoInteviewErroe !== '') {
            toast.error(addVideoInteviewErroe, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [addVideoInteviewSucces, addVideoInteviewErroe]);


    const handleAddVideo = async () => {
        let hasError = false;
        const newErrors = {
            videoFile: false,
            imageFile: false,
            groom_id: false,
            bride_id: false,
            marriage_date: false,
            description: false,
        };

        if (videoFile === null) {
            newErrors.videoFile = true;
            hasError = true;
        }

        if (imageFile === null) {
            newErrors.imageFile = true;
            hasError = true;
        }

        if (videoInterviews.groom_id === '') {
            newErrors.groom_id = true;
            hasError = true;
        }
        if (videoInterviews.bride_id === '') {
            newErrors.bride_id = true;
            hasError = true;
        }
        if (videoInterviews.marriage_date === '') {
            newErrors.marriage_date = true;
            hasError = true;
        }
        if (videoInterviews.description === '') {
            newErrors.description = true;
            hasError = true;
        }

        if (!hasError) {
            let videoPath;
            try {
                if (videoFile) {
                    setVideoUplodingTime(true)
                    const videoUrl = await uploadFile(videoFile); // Upload to S3
                    setVideoUplodingTime(false)
                    videoPath = videoUrl;
                } else {
                    console.error("No video file found.");
                }

                const imageUrl = await uploadFile(imageFile);
                addVideoInteviewRequest({
                    url: `${BASE_URL}create-video-interview`,
                    method: 'POST',
                    body: {
                        "groom_id": videoInterviews.groom_id,
                        "bride_id": videoInterviews.bride_id,
                        "marriage_date": videoInterviews.marriage_date,
                        "description": videoInterviews.description,
                        "video": videoPath,
                        "thumbnail": imageUrl,
                    }
                }, (data) => {
                    setAddVideoModal(false)
                    videosRequestData()
                    setVideoInterviews({
                        ...videoInterviews,
                        groom_id: '',
                        bride_id: '',
                        marriage_date: '',
                        description: '',
                    });
                    setUploadedVideo(null)
                    setVideoFile(null)
                    setVideoThumbnail(null)
                    setUploadedImage(null)
                    setErrors({
                        ...errors, videoFile: false,
                        imageFile: false,
                        groom_id: false,
                        bride_id: false,
                        marriage_date: false,
                        description: false,
                    })
                });

            } catch (error) {
                console.error('Error uploading video:', error);
            }
        } else {
            setErrors({ ...newErrors });
        }

    };

    const onCloseFunction = () => {
        setClear(true)
    };
    const onCloseFunctiongift = () => {
        console.log("onCloseFunctiongift");
        setErrorMsg(false)
        setVerifidUser("1")
        setStoriesUser("1")
        setFormData({ ...formData, stories_resane: "", gift_reason: "", })
        setCharsLeft(0)
    };
    const onCloseFunctionStory = () => {
        setErrorMsg(false)
        setVerifidUser("1")
        setStoriesUser("1")
        setFormData({ ...formData, stories_resane: "", gift_reason: "", })
        setCharsLeft(0)
    };

    const onCloseFunctionsecond = () => {
        setErrors({
            ...errors, videoFile: false,
            imageFile: false,
            groom_id: false,
            bride_id: false,
            marriage_date: false,
            description: false,
        })
        setVideoInterviews({
            ...videoInterviews,
            groom_id: '',
            bride_id: '',
            marriage_date: '',
            description: '',
        }
        )
        setUploadedVideo(null)
        setVideoFile(null)
        setVideoThumbnail(null)
        setUploadedImage(null)
        setCharsLeft(0)
        setVideoUplodingTime(false)
    }
    const toggleRefresh = () => {
        setRefresh(false);
        setTimeout(() => {
            setRefresh(true);
        }, 200); // Refreshing after 1 second
    };
    const refreshTable = () => {
        setSortDirectionData('DESC')
        setColumn(0)
        setPerPage(10)
        setPage(1)

        setSortDirectionSuccess('DESC')
        setColumnSuccess(0)
        setPerPageSuccess(10)
        setPageSuccess(1)

        setSortDirectionVideo('DESC')
        setColumnVideo(0)
        setPerPageVideo(10)
        setPageVideo(1)
    }

    const onTabClick = (index) => {
        toggleRefresh()
        setActiveTab(index); // Update the active tab index
        refreshTable()
        if (index === 0) {
            setRequestedSearch("")
        } else if (index === 1) {
            setSuccessSearch("")
        }
        else if (index === 2) {
            setVideoSearch('')
        }
    };

    const handleWordCount = (event) => {
        const inputValue = event.target.value;
        const charCount = inputValue.length;
        const maxChar = 255;
        const truncatedValue = inputValue.slice(0, maxChar);
        setVideoInterviews({ ...videoInterviews, description: truncatedValue });
        const charLength = Math.min(maxChar, charCount); // Ensures the count doesn't exceed 200
        setCharsLeft(charLength);
    };

    // deleted add user 
    const { isLoading: deleteRoleLoding, success: deleteRoleSuccess, error: deleteRoleError, sendRequest: deleteRoleRequest } = useApiHttp();
    useEffect(() => {
        if (deleteRoleSuccess !== '') {
            toast.success(deleteRoleSuccess, {
                position: toast.POSITION.TOP_RIGHT,
            })

        }
        if (deleteRoleError !== '') {
            toast.error(deleteRoleError, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [deleteRoleSuccess, deleteRoleError]);
    const deleteRoleData = (data) => {
        videosRequestData()
    };

    const deleteRoleRequestData = (token) => {
        const requestData = {
            video_token: token,
        };

        deleteRoleRequest({
            url: `${BASE_URL}delete-video-interview`,
            method: 'POST',
            body: requestData,
        }, deleteRoleData);
    };

    const roleDelete = (token) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#EF7435',
            cancelButtonColor: '#aeaeae',
            confirmButtonText: 'Delete it!',
            customClass: {
                title: 'your-custom-title-class',
                content: 'your-custom-content-class',
                confirmButton: 'your-custom-button-class',
                cancelButton: 'your-custom-button-class',
            }
        }).then((result) => {
            if (result.isConfirmed) {
                deleteRoleRequestData(token)
            }
        })
    }
    const tabAnimation = {
        initial: {
            y: -200,
            opacity: 0,
        },
        animate: {
            y: 0,
            opacity: 1,
            transition: {
                duration: 0.5,
                staggerChildren: 0.1,
            },
        },
        exit: {
            y: -200,
            opacity: 0,
        }
    };

    console.log("useApiCall()");
    return (
        <AnimatePresence>
            <ToastContainer />
            <PagesHeader title="Manage Gifts and Testimonies" />
            <section className='tablesection_heder'>
                <div className={classes.buttonsContainer}>
                    {activeTab === 2 && <Button className={classes.login_btn} onClick={videosAdd}>+ Add New</Button>}
                </div>
                <Tabs
                    tabs={[
                        {
                            title: "Requested Gift",
                            render: () => (
                                refresh &&
                                <motion.dev
                                    variants={tabAnimation}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit">
                                    <Table data={requestedData} onSort={handleSort} columns={columns} loader={requestedLoding} paginationTotalRows={iTotalDisplayRecordsRequest} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange}
                                        children={
                                            <div className="header_table">
                                                <TableHeader tableheader="Requested Gift" totalusers={`${requestedDataTotal} total Requested gift notifications`} />
                                                <Search value={requestedSearch} onChange={(newValue) => handleSearchChange(newValue, 'gift')} type='text' placeholder='Search' />
                                            </div>
                                        } />
                                </motion.dev>
                            ),
                        },
                        {
                            title: "Success Stories",
                            render: () => (

                                refresh &&
                                <motion.dev
                                    variants={tabAnimation}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit">
                                    <Table data={successData} onSort={handleSortSuccess} columns={columns2} loader={successLoding} paginationTotalRows={successDataTotal} onChangeRowsPerPage={handlePerRowsChangeSuccess} onChangePage={handlePageChangeSuccess}
                                        children={
                                            <div className="header_table">
                                                <TableHeader tableheader="Success Stories" totalusers={`${successDataTotal} total Success stories notifications`} />
                                                <Search value={successSearch} onChange={(newValue) => handleSearchChange(newValue, 'success')} type='text' placeholder='Search' />
                                            </div>
                                        } />
                                </motion.dev>
                            ),
                        },
                        {
                            title: "Video Interviews",
                            render: () => (

                                refresh &&
                                <motion.dev
                                    variants={tabAnimation}
                                    initial="initial"
                                    animate="animate"
                                    exit="exit">
                                    <Table data={videoData} onSort={handleSortVideo} columns={columns3} loader={videosLoding} paginationTotalRows={videoDataTotal} onChangeRowsPerPage={handlePerRowsChangeVideo} onChangePage={handlePageChangeVideo}
                                        children={
                                            <div className="header_table">
                                                <TableHeader tableheader="Video Interviews" totalusers={`${videoDataTotal} total Video Interviews`} />
                                                <Search value={videoSearch} onChange={(newValue) => handleSearchChange(newValue, 'video')} type='text' placeholder='Search' />
                                            </div>
                                        } />
                                </motion.dev>
                            ),
                        },
                    ]}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                />
            </section>
            <ReusableModal
                title={`Requested Gift - ${giftDatas.gift_id} `}
                open={giftModal}
                setOpen={setGiftModal}
                handleSubmit={() => handleGiftUpdate(giftDatas.gift_id)}
                type={giftDatas.status === "Pending" ? " " : "view"} okay={giftDatas.status === "Pending" ? " " : "ok"}
                scrollType="body"
                size="sm"
                onCloseFunction={onCloseFunctiongift}
            >
                <>
                    <div className={classes.report_box}>
                        <p className={classes.module_text}>Request Details</p>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.contant_box_titel}>Groom ID</p>
                                <p className={classes.contant_box_sub}>{giftDatas.groom_id}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Groom Name</p>
                                <p className={classes.contant_box_sub}>{giftDatas.groom_name}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Bride ID</p>
                                <p className={classes.contant_box_sub}>{giftDatas.bride_id}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Bride Name</p>
                                <p className={classes.contant_box_sub}>{giftDatas.bride_name}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Engagement Date</p>
                                <p className={classes.contant_box_sub}>{giftDatas.engagement_date}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Marriage Date</p>
                                <p className={classes.contant_box_sub}>{giftDatas.marriage_date}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Mobile Number</p>
                                <p className={classes.contant_box_sub}>{giftDatas.mobile}</p>
                            </div>
                            <div style={{ width: ' 213px' }}>
                                <p className={classes.contant_box_titel}>Email Address</p>
                                <p className={classes.contant_box_sub}>{giftDatas.email}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Country Living In</p>
                                <p className={classes.contant_box_sub}>{giftDatas.country}</p>
                            </div>
                        </div>
                        <div className={classes.marg_top_1rem}>
                            <p className={classes.contant_box_titel}>Address</p>
                            <p className={classes.contant_box_sub}>{giftDatas.address}</p>
                        </div>
                    </div>

                    <div className={classes.p_top_1rem}>
                        {giftDatas.status === "Declined" && (
                            <>
                                <p className={classes.module_text}>Declined Reason</p>
                                {
                                    giftDatas.reject_reason && <p className={classes.action_btn_reason}>{giftDatas.reject_reason}</p>
                                }
                            </>
                        )}
                        {giftDatas.status === "Pending" ? <>
                            <p className={classes.module_text}>Action</p>
                            <div className={classes.radio_btn_set}>
                                <RadioButton
                                    changed={radioChangeHandler}
                                    id="1"
                                    isSelected={verifidUser === "1"}
                                    label="Approve"
                                    value="1"
                                />

                                <RadioButton
                                    changed={radioChangeHandler}
                                    id="2"
                                    isSelected={verifidUser === "0"}
                                    label="Decline with reason"
                                    value="0"
                                />

                            </div>
                            {verifidUser === "0" && (
                                <div className={classes.textarea_contoner}>
                                    <TextField
                                        maxLength="255"
                                        label="Reason"
                                        title="resane"
                                        name="gift_reason"
                                        rows={5}
                                        value={formData.gift_reason}
                                        placeholder="Type your reason…"
                                        showpertext={`${charsLeft}/255`}
                                        onChange={handleChange} />
                                    {errorMsg && <p className={`${classes.errorMessage} text_danger`}>Please Enter Reason</p>}
                                </div>
                            )}
                        </>
                            : 
                                <>
                                    <p className={classes.module_text}>Status</p>
                                    <> 
                                        {
                                            giftDatas.status === 'Approved' && (<span className="wigite_lable_satus verified marg_1rem">{giftDatas.status}</span>)
                                        } 
                                        {
                                            giftDatas.status === 'Declined' && (<span className="wigite_lable_satus rejected marg_1rem">{giftDatas.status}</span>)
                                        } 
                                    </>
                                    {
                                        giftDatas.reject_reason && <p className={classes.action_btn_reason}>{giftDatas.reject_reason}</p>
                                    }

                                </>
                        }
                    </div>
                </>
            </ReusableModal>

            <ReusableModal 
                open={storiesModal} 
                setOpen={setStoriesModal} 
                scrollType="body" 
                title={`Success Story - ${storiesDatas.story_id} `} 
                size="sm" 
                handleSubmit={() => handleStoriesUpdate(storiesDatas.story_id)} 
                type={storiesDatas.status === "Declined" ? "view" : " "} 
                okay={storiesDatas.status === "Declined" ? "ok" : " "} 
                onCloseFunction={onCloseFunctiongift}
            >
                <>
                    <div className={classes.report_box}>
                        <p className={classes.module_text}>Request Details</p>
                        <div className={classes.module__text_Container}>

                            <div>
                                <p className={classes.contant_box_titel}>Your Partner's ID</p>
                                <p className={classes.contant_box_sub}>{storiesDatas.bride_id}</p>
                            </div>

                            <div>
                                <p className={classes.contant_box_titel}>Engagement Date</p>
                                <p className={classes.contant_box_sub}>{storiesDatas.engagement_date}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Marriage Date</p>
                                <p className={classes.contant_box_sub}>{storiesDatas.marriage_date}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Status</p>
                                <>
                                    <> 
                                    {storiesDatas.status === 'Published' && (<span className="wigite_lable_satus verified marg_1rem">{storiesDatas.status}</span>)}
                                    {storiesDatas.status === 'Pending' && (<span className="wigite_lable_satus verified marg_1rem">{storiesDatas.status}</span>)} 
                                    {storiesDatas.status === 'Declined' && (<span className="wigite_lable_satus rejected marg_1rem">{storiesDatas.status}</span>)} 
                                    {storiesDatas.status === 'Approved' && (<span className="wigite_lable_satus approved marg_1rem">{storiesDatas.status}</span>)} 
                                    {storiesDatas.status === 'Revoked' && (<span className="wigite_lable_satus rejected marg_1rem">{storiesDatas.status}</span>)} 
                                    </>
                                    {/* {storiesDatas.reject_reason && <p className={classes.action_btn_reason}>{storiesDatas.reject_reason}</p>} */}
                                </>
                            </div>

                        </div>
                        <div className={classes.marg_top_1rem}>
                            <p className={classes.contant_box_titel}>Photo</p>
                            <div className={classes.contant_photo}>
                                {storiesDatas.image?.map((item, index) => (
                                    <Image className={classes.contant_photo_img} src={item} key={index} />
                                ))}
                            </div>
                        </div>

                        <div className={classes.marg_top_1rem}>
                            <p className={classes.contant_box_titel}>Address</p>
                            <p className={classes.contant_box_sub}>{storiesDatas.address}</p>
                        </div>
                        <div className={classes.marg_top_1rem}>
                            <p className={classes.contant_box_titel}>Success Story</p>
                            <p className={`${classes.contant_box_sub} ${classes.overflow_cont}`}>
                                {storiesDatas.success_story}
                            </p>
                        </div>
                    </div>

                    <div className={classes.p_top_1rem}>
                        {storiesDatas.status === "Approved" && (
                            <>
                                <p className={classes.module_text}>Action</p>
                                <div className={classes.approveContainer}>
                                    <Image
                                        onClick={() => {
                                            // handleStoriesUpdate(storiesDatas.story_id, !termsChecked)
                                            setTermsChecked(!termsChecked)
                                        }}
                                        src={ termsChecked ? checkedIcon : uncheckedIcon }
                                        className={classes.checkIcon}
                                    />
                                    <p className={classes.publishText}>Publish on Website</p>
                                </div>
                            </>
                        )}
                        {storiesDatas.status === "Published" && (
                            <>
                                <p className={classes.module_text}>Action</p>
                                <div className={classes.approveContainer}>
                                    <Image
                                        onClick={() => {
                                            // handleStoriesUpdate(storiesDatas.story_id, !termsCheckedRevoke)
                                            setTermsCheckedRevoke(!termsCheckedRevoke)
                                        }}
                                        src={ termsCheckedRevoke ? checkedIcon : uncheckedIcon }
                                        className={classes.checkIcon}
                                    />
                                    <p className={classes.publishText}>Revoke from Website</p>
                                </div>
                            </>
                        )}
                        {storiesDatas.status === "Revoked" && (
                            <>
                                <p className={classes.module_text}>Action</p>
                                <div className={classes.approveContainer}>
                                    <Image
                                        onClick={() => {
                                            // handleStoriesUpdate(storiesDatas.story_id, !termsCheckedRevoke)
                                            setTermsChecked(!termsChecked)
                                        }}
                                        src={ termsChecked ? checkedIcon : uncheckedIcon }
                                        className={classes.checkIcon}
                                    />
                                    <p className={classes.publishText}>Publish on Website</p>
                                </div>
                            </>
                        )}
                        {storiesDatas.status === "Pending" && <>
                            <p className={classes.module_text}>Action</p>
                            <div className={classes.radio_btn_set}>
                                <RadioButton
                                    changed={radioChangeHandlerStories}
                                    id="0"
                                    isSelected={storiesUser === "0"}
                                    label="Approve"
                                    value="0"
                                />

                                <RadioButton
                                    changed={radioChangeHandlerStories}
                                    id="1"
                                    isSelected={storiesUser === "1"}
                                    label="Decline with reason"
                                    value="1"
                                />

                            </div>
                            {storiesUser === "1" && (
                                <div className={classes.textarea_contoner}>
                                    <TextField
                                        maxLength="255"
                                        label="Reason"
                                        title="resane"
                                        name="stories_resane"
                                        rows={5}
                                        value={formData.stories_resane}
                                        placeholder="Type your reason…"
                                        showpertext={`${charsLeft}/255`}
                                        onChange={handleChange} />
                                    {errorMsg && <p className={`${classes.errorMessage} text_danger`}>Please Enter Reason</p>}
                                </div>
                            )}
                        </>}
                        { storiesDatas.status === "Declined" && ( <>
                                <p className={classes.module_text}>Status</p>

                                {/* <>{storiesDatas.status === 'Declined' && (<span className="wigite_lable_satus rejected marg_1rem">{storiesDatas.status}</span>)} </> */}
                                {storiesDatas.reject_reason && <p className={classes.action_btn_reason}>{storiesDatas.reject_reason}</p>}

                        </>)}
                    </div>
                </>
            </ReusableModal>
            <Modal openPopup={videoModal} setOpenPopup={setVideoModal} titel={`Video Interview - ${videoInterviewDatas.token}`} size="lg" type="view" okay="ok" width="Respons" onCloseFunction={onCloseFunction} >
                <>
                    <div className={classes.report_box_two}>
                        <p className={classes.module_text}>Interview Details</p>
                        <div className={classes.report_boxs}>
                            <div className={classes.module__text_Container_two}>
                                <div>
                                    <p className={classes.contant_box_titel}>Groom ID</p>
                                    <p className={classes.contant_box_sub}>{videoInterviewDatas.groom_id}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Groom Name</p>
                                    <p className={classes.contant_box_sub}>{videoInterviewDatas.groom_name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Bride ID</p>
                                    <p className={classes.contant_box_sub}>{videoInterviewDatas.bride_id}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Bride Name</p>
                                    <p className={classes.contant_box_sub}>{videoInterviewDatas.bride_name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Marriage Date</p>
                                    <p className={classes.contant_box_sub}>{videoInterviewDatas.marriage_date}</p>
                                </div>

                            </div>

                            {videoInterviewDatas.video ?
                                <div className={classes.inro_video}>
                                    <p className={classes.contant_box_titel}>Video</p>
                                    <VideoPlayers src={videoInterviewDatas.video} clear={clear} thumbnail={videoInterviewDatas.thumbnail} playBtn={PlayBtn} onCloseFunction={onCloseFunction} />
                                </div> : " "}

                        </div>

                        <div className={classes.marg_top_1rem}>
                            <p className={classes.contant_box_titel}>About</p>
                            <p className={classes.contant_box_sub}>{videoInterviewDatas.description}</p>
                        </div>

                    </div>
                </>
            </Modal>
            <ReusableModal open={addVideoModal} setOpen={setAddVideoModal} scrollType="body" title={`Add New`} size="sm" handleSubmit={handleAddVideo} width="Respons" onCloseFunction={onCloseFunctionsecond} isLoading={videoUplodingTime}>

                <p className={classes.video_add_titel}>Interview details</p>
                <div className={classes.layout_input} style={{ marginTop: 0 }}>
                    <div className={classes.input_position}>
                        <SelectBox
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={videoInterviews.groom_id}
                            // defaultValue={editeDesigName}
                            label="Groom Id"
                            onChange={(event) => setVideoInterviews({ ...videoInterviews, groom_id: event.target.value })}
                            labelname="Groom Id"
                            nonename="Groom Id"
                            data={groomidDropdown}
                        />
                        {videoInterviews.groom_id && <div className={classes.profile_img}>
                            <div className={classes.profile_img_contaner}><Image src={groomidImgDropdown.groom_image} /></div>
                            <p className={classes.contant_box_sub}>{groomidImgDropdown.groom_name}</p>
                        </div>}

                        {errors.groom_id && <span className={`${classes.absolute} text_danger marg_top_5rem`}>Please Select Groom Id !</span>}

                    </div>
                    <div className={classes.input_position}>
                        <SelectBox
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={videoInterviews.bride_id}
                            // defaultValue={editeDesigName}
                            label="Bride Id"
                            onChange={(event) => setVideoInterviews({ ...videoInterviews, bride_id: event.target.value })}
                            labelname="Bride Id"
                            nonename="Bride Id"
                            data={brideidDropdown}
                        />
                        {videoInterviews.bride_id && <div className={classes.profile_img}>
                            <div className={classes.profile_img_contaner}><Image src={brideidImgDropdown.groom_image} /></div>
                            <p className={classes.contant_box_sub}>{brideidImgDropdown.groom_name}</p>
                        </div>}
                        {errors.bride_id && <span className={`${classes.absolute} text_danger marg_top_5rem`}>Please Select Bride Id !</span>}
                    </div>
                </div>
                <div className={classes.layout_input}>
                    <div className={classes.layout_gap}>
                        <div className={classes.input_position}>
                            <FloatingDatePicker
                                selectedDate={videoInterviews.marriage_date}
                                onChange={(e) => setVideoInterviews({ ...videoInterviews, marriage_date: e })}
                                label={"Marriage Date"}
                                customStyle={{
                                    width: '100%',

                                }}
                            />

                            {errors.marriage_date && <span className={`${classes.absolute} text_danger marg_top_5rem`}>Please select Marriage Date !</span>}
                        </div>
                        <div className={classes.input_position}>
                            <TextArea
                                maxLength="255"
                                style={{ width: '100 %' }}
                                label="Description"
                                title="description"
                                name="description"
                                rows={4}
                                value={videoInterviews.description}
                                placeholder="Enter description"
                                showpertext={`${charsLeft}/255`}
                                onChange={handleWordCount} />
                            {errors.description && <span className={`${classes.absolute} text_danger`}>Please Enter Description !</span>}
                        </div>
                    </div>
                    <>
                        <div className={classes.input_position}>
                            {!videoUplodingTime ? <div>
                                <div className={classes.form}>
                                    <p className={classes.contant_box_titel} style={{ paddingBottom: ".3rem" }}>Upload Video</p>
                                    {uploadedVideo ? (
                                        <>
                                            <label htmlFor="file" className="sr-only">
                                                <div
                                                    className={`${classes.uploadedImage} ${classes.videoThumbnailContainer}`}
                                                    style={{ backgroundImage: `url(${videoThumbnail})`, }}
                                                >
                                                    <div className={classes.deleteContainer}>
                                                        <Image src={deleteIcon} onClick={() => setUploadedVideo(null)} />
                                                    </div>
                                                    <div
                                                        className={classes.reuploadContainer}
                                                        onClick={() => uploadVideoInput.current.click()}
                                                    >
                                                        <Image src={uploadWhiteIcon} />
                                                        <p className={classes.reuploadLabeText}>Reupload</p>
                                                    </div>
                                                </div>
                                            </label>
                                        </>
                                    ) : (
                                        <label htmlFor="file" className="sr-only">
                                            <div className={classes.upload} onClick={() => {
                                                if (uploadVideoInput.current) {
                                                    uploadVideoInput.current.click();
                                                }
                                            }}>
                                                <label htmlFor="file" className={classes.uploadImageLabel}>
                                                    <div className={classes.uploadImage}>
                                                        <img
                                                            src={uploadIcon}
                                                            className={classes.uploadLogo}
                                                            alt="Upload Icon"
                                                        />
                                                    </div>
                                                </label>
                                                <p className={classes.contant_box_titel} >Upload Video</p>

                                            </div>
                                        </label>
                                    )}
                                </div>
                                <input
                                    type="file"
                                    id='file'
                                    accept="video/*"
                                    onChange={handleVideoUpload}
                                    className={classes.uploadInput}
                                    onClick={(e) => {
                                        e.target.value = null
                                    }}
                                />
                                {errors.videoFile && <span className={`${classes.absolute} text_danger marg_top_5rem`}>Please Upload video !</span>}
                            </div> :
                                <div className={classes.upload}>
                                    <ProgressBar classname="video_loder" />
                                    <p className={classes.contant_box_titel}>Video Uploading Please Wait </p>
                                </div>
                            }
                        </div>
                    </>
                </div>
                <div className={classes.input_position}>
                    <div className={classes.layout_input} style={{ margin: '0' }}>

                        <div className={classes.form}>
                            <p className={classes.contant_box_titel} style={{ paddingTop: '0', paddingBottom: ".3rem" }}>Upload Video Thumbnail</p>
                            {uploadedImage ? (
                                <>
                                    <label htmlFor="fileimage" className="sr-only">
                                        <div
                                            className={`${classes.uploadedImage} ${classes.videoThumbnailContainer} ${classes.uploadedImage}`}
                                        >
                                            <img src={uploadedImage} alt="" className={classes.imgeulodeeeee} />
                                            <div className={`${classes.deleteContainer} ${classes.deletepotion}`}>
                                                <Image src={deleteIcon} onClick={() => setUploadedImage(null)} />
                                            </div>
                                            <div
                                                className={`${classes.reuploadContainer} ${classes.reulode}`}
                                            >
                                                <Image src={uploadWhiteIcon} />
                                                <p className={classes.reuploadLabeText}>Reupload</p>
                                            </div>
                                        </div>
                                    </label>
                                </>
                            ) : (
                                <label htmlFor="fileimage" className="sr-only">
                                    <div className={classes.upload}>
                                        <label className={classes.uploadImageLabel}>
                                            <label htmlFor="fileimage" className={classes.uploadImage}>
                                                <img
                                                    src={uploadimge}
                                                    className={classes.thumblin_uplode}
                                                    alt="Upload Icon"
                                                />
                                            </label>
                                        </label>
                                        <p className={classes.contant_box_titel}>Upload Image</p>

                                    </div>
                                </label>
                            )}
                        </div>
                        <input
                            type="file"
                            id='fileimage'
                            accept="image/*"
                            onChange={handleImageUpload}
                            className={classes.uploadInput}
                            onClick={(e) => {
                                e.target.value = null
                            }}
                        />
                    </div>
                    {errors.imageFile && <span className={`${classes.absolute} text_danger marg_top_5rem`}>Please select Thumbnail !</span>}
                </div>
            </ReusableModal>
            <ToastContainer />
        </AnimatePresence>
    )
}

export default ManageGifts