import React, { useEffect, useState, useRef } from 'react';
import style from "../css/Matches.module.css";
import Image from '../../../../../ui/Image/Image';
import Button from '../../../../../ui/Button/Button';
import sortlistStarIcon from "../../../assets/svg/Matches/Shortlisted.svg";
import sortlistGrayStarIcon from "../../../assets/svg/Matches/ShortlistGray.svg";
import degreeIcon from "../../../assets/common/Profession.svg";
import degreeWhiteIcon from "../../../assets/common/DegreeWhite.svg";
import professionWhiteIcon from "../assets/Profession Icon.svg";
import useApiCall from '../../../hooks/useApiCall';
import useChatSetter from '../../../hooks/useChatSetter';
import config from '../../../../../app.json';
import { Circles } from 'react-loader-spinner';
import { addScroll, avoidScroll, getUserData, getUserToken } from '../../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import gridIcon from "../assets/Grid View.svg"
import listViewIcon from "../assets/List View.svg"
import filterIcon from "../assets/Filter.svg"
import clearIcon from "../assets/Cancel.svg"
import unShortlistIcon from "../assets/unShortlist.svg"
import intrestSentIcon from "../assets/Interest Sent@2x.svg"
import sendIntrestIcon from "../assets/Send Interest.svg"
import SideDrawer from '../../../../../ui/SideDrawer/SideDrawer';
import { Filter } from '../Filter/Filter';
import { BlockReport } from '../../BlockReport/BlockReport';
import Carousel from 'react-grid-carousel';
import chatIcon from "../../../features/MailBox/assets/Chat.svg"
import infoIcon from "../../MailBox/Declined/assets/Info.svg";
import { DeclineModal } from '../DeclineModal/DeclineModal';
import ModalBox from '../../../../../ui/Modal/ModalBox';
import { NoData } from '../../NoData/NoData';
import { SubscribeWidget } from '../../SubscribeWidget/SubscribeWidget';
import { MembershipTypeCard } from '../../MembershipTypeCard/MembershipTypeCard';
import { BecameMemberModal } from '../../BecameMemberModal/BecameMemberModal';
import MaskImage from '../../MaskImage/MaskImage';

export default function View({ type, pageCredentials }) {
    const navigation = useNavigate("")
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(false);
    const [lastPageFilter, setLastPageFilter] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)
    const [isGridView, setIsGridView] = useState(false)
    const [isScrollAction, setIsScrollAction] = useState(false);
    const [filterHasValue, setFilterHasValue] = useState(false)
    const [interestClicked, setInterestClicked] = useState(false)

    const [showSubscribeWidget, setShowSubscribeWidget] = useState(false)

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [limitExceeded, setLimitExceeded] = useState(0)
    const [ afterBlocked, setAfterBlocked ] = useState(false)

    const [filterData, setFilterData] = useState({
        height: [],
        age: [],
        profile_created_name: "",
        profile_created_value: "",
        show_profiles: [],
        dont_show_profiles: [],
        marital_status: [],
        mother_tongue: [],
        religious_details: [],
        religion: [],
        community: [],
        star: [],
        raasi: [],
        gothram: [],
        citizenship: [],
        employed_in: []
    });

    const [filterDataFromFilter, setFilterDataFromFilter] = useState({
        height: [],
        age: [],
        profile_created_name: "",
        profile_created_value: "",
        show_profiles: [],
        dont_show_profiles: [],
        marital_status: [],
        mother_tongue: [],
        religious_details: [],
        religion: [],
        community: [],
        star: [],
        raasi: [],
        gothram: [],
        citizenship: [],
        employed_in: []
    })

    const [filterSubmitted, setFilterSubmitted] = useState(false)
    const [toggleFilterApi, setToggleFilterApi] = useState(false)
    const [clickedItem, setClickedItem] = useState(false)
    const [chatToken, setChatToken] = useState(null)
    const [rejectReason, setRejectReason] = useState(null)
    const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)
    const [reachedLastPage, setReachedLastPage] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [showBecameMemberModal, setShowBecameMemberModal] = useState(false)
    const [pages, setPages] = useState(0)

    const [rejectToken, setRejectToken] = useState(null)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: sendIntrest } = useApiCall();
    const { isLoading: sendShortListLoading, success: sendShortListSuccess, error: sendShortListError, sendRequest: shortlist } = useApiCall();
    const { loading: isChatSetterLoading, success: successChatSetter, error: errorChatSetter, setUserChatEntry: chatRoomSetup } = useChatSetter();

    const { sendRequest: acceptInterest } = useApiCall();
    const { sendRequest: declineInterest } = useApiCall();

    const pageContentContainerRef = useRef(null);

    const closeDrawer = (type) => {
        addScroll()
        setIsDrawerOpen(false);
    };

    const handleArrayValues = (arrayData, type) => {
        console.log("arrayData, type", arrayData, type);
        if (arrayData?.length && arrayData[0] === undefined) return
        let arrayValues = []
        arrayData?.length && arrayData?.map((item, index) => {
            arrayValues.push(item[type] !== undefined ? item[type] : "");
        })
        return arrayValues
    }

    useEffect(() => {
        setInterestClicked(false)
        setData([])
        setFilterData({
            height: [],
            age: [],
            profile_created_name: "",
            profile_created_value: "",
            show_profiles: [],
            dont_show_profiles: [],
            marital_status: [],
            mother_tongue: [],
            religious_details: [],
            religion: [],
            community: [],
            star: [],
            raasi: [],
            gothram: [],
            citizenship: [],
            employed_in: []
        })
        setLoadingMore(false)
        setReachedLastPage(false)
        setLastPageFilter(false)
        setCurrentPage(1)
        setRefresh(!refresh)
        setIsGridView(false)
    }, [type])

    useEffect(() => {
        if (lastPageFilter || ( pages !== 0 && currentPage > pages  ) ) {
            setLoadingMore(false);
            return;
        }
        const {
            profile_created_value,
            show_profiles,
            dont_show_profiles,
            age,
            height,
            marital_status,
            mother_tongue,
            physical_status,
            religion,
            community,
            star,
            raasi,
            gothram,
            citizenship,
            employed_in,
        } = filterData
        console.log("filterData", filterData);
        console.log("mother_tonque", mother_tongue);
        getDetails({
            url: `matches/${pageCredentials?.api}/filter?current_page=${currentPage}`,
            method: 'POST',
            body: {
                "profile_created": profile_created_value ? Number(profile_created_value) : "",
                "show_profiles": handleArrayValues(show_profiles, "value"),
                "dont_show_profiles": handleArrayValues(dont_show_profiles, "value"),
                "age": handleArrayValues(age, "value"),
                "height": handleArrayValues(height, "value"),
                "marital_status": handleArrayValues(marital_status, "token"),
                "mother_tongue": handleArrayValues(mother_tongue, "token"),
                "physical_status": handleArrayValues(physical_status, "value"),
                "religion": handleArrayValues(religion, "token"),
                "community": handleArrayValues(community, "token"),
                "star": handleArrayValues(star, "token"),
                "raasi": handleArrayValues(raasi, "token"),
                "gothram": handleArrayValues(gothram, "token"),
                "employed_in": handleArrayValues(employed_in, "token"),
                "citizenship": handleArrayValues(citizenship, "token"),
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setShowSubscribeWidget(responseData?.status === 402 ? true : false)
            if (responseData.data.data?.data?.length > 0) {
                setLimitExceeded(responseData.data.limit_exceeded)
                setShowBecameMemberModal(responseData.data.limit_exceeded)
                setPages(responseData.data.data?.last_page)
                setLastPageFilter(currentPage >= responseData.data.data?.last_page);
                setTotal(responseData.data.data?.total)
                console.log("responseData.data.data", responseData.data.data);
                setData((prevData) => {
                    if (isScrollAction) {
                        console.log("isScrollAction", "if");
                        // Scroll action: Only add previous values
                        return [...prevData, ...responseData.data.data?.data];
                    } else {
                        console.log("isScrollAction", "else");
                        // New filter submission: Replace data with new values
                        return responseData.data.data?.data;
                    }
                });
                setIsScrollAction(true)
                setLoadingMore(false);
                setAfterBlocked(false)
            } else {
                setData([])
                setTotal(0)
                setLoadingMore(false);
            }
        });
    }, [currentPage, lastPageFilter, toggleFilterApi, refresh, filterData]);

    // const handleScroll = () => {
    //     setIsScrollAction(true);
    //     const container = pageContentContainerRef.current;
    //     if (!container || reachedLastPage || loadingMore) return; // Do nothing if loading, or last page reached.
    //     // Check if the user has scrolled to the bottom of the container
    //     if ((container.scrollHeight - container.scrollTop - 50) < container.clientHeight) {
    //         // Load more data when the user reaches the bottom
    //         setLoadingMore(true);
    //         setCurrentPage((prevPage) => prevPage + 1);
    //     }
    // };

    useEffect(() => {
        if (lastPageFilter) {
            setReachedLastPage(true);
            setLoadingMore(false);
        } else {
            setReachedLastPage(false);
            setLoadingMore(true);
        }
    }, [lastPageFilter]);

    const handleScroll = () => {
        const scrollY = window.scrollY;
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        if (scrollY + windowHeight >= documentHeight - 100) {
            setLoadingMore(true);
            setCurrentPage(currentPage + 1);
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentPage]);

    const hasNonEmptyValue = Object.values(filterData).some(value => {
        if (Array.isArray(value)) {
            return value.length > 0;
        } else {
            return value && value !== "height" && value !== "age";
        }
    });

    useEffect(() => {
        if (sendIntrestSuccess) {
            console.log("sendIntrestSuccess", sendIntrestSuccess);
        }
        if (sendIntrestError) {
            console.log("loginError", sendIntrestError);
        }
    }, [sendIntrestSuccess, sendIntrestError]);

    const handleSendInterest = (token, status) => {
        setInterestClicked(true)
        if (!limitExceeded) {
            sendIntrest({
                url: `profile/toggle-interest`,
                method: 'POST',
                body: {
                    "profile_token": token,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (result) => {
                try {
                    console.log("status", status);
                    setLimitExceeded(result?.data?.limit_exceeded)
                    const index = data?.findIndex((item) => item.token === token);

                    if (index !== -1) {
                        // Update the specific property of the found object
                        data[index].interest_sent_status = status;
                        data[index].sentInterest = status;
                    }
                    setData(data)
                    // setLastPageFilter(false)
                    // setCurrentPage(1)
                    // setRefresh(!refresh)
                } catch (error) {
                    console.log("error", error);
                }
            });
        }
    }

    const handleShortlist = (token, status) => {
        shortlist({
            url: `profile/toggle-shortlist`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].shortlisted = status;
            }

            setData(data)
            // setLastPageFilter(false)
            // setCurrentPage(1)
            // setRefresh(!refresh)
        });
    }


    const handleAccept = (token) => {
        acceptInterest({
            url: `profile/accept-interest`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 2;
            }
            setData(data)
            // setLastPageFilter(false)
            // setCurrentPage(1)
            // setRefresh(!refresh)
        });
    }
    const handleCancel = (reason) => {
        declineInterest({
            url: `profile/reject-interest`,
            method: 'POST',
            body: {
                "profile_token": rejectToken,
                "reject_reason": reason.trim()
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === rejectToken);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 3;
            }
            setData(data)
            // setLastPageFilter(false)
            // setCurrentPage(1)
            // setRefresh(!refresh)
        });
    }

    const actionUi = (
        can_send_interest,
        interest_received_status,
        interest_sent_status,
        shortlisted,
        is_connected,
        token,
        name,
        image,
        user_id
    ) => {

        return (
            <>
                <div className={style.buttonProgressContainer}>
                    {/* <p className={style.likeProfileLabel}>Like her profile?</p> */}
                    <div className={style.buttonsContianer}>
                        {
                            interest_sent_status === 0 && interest_received_status === 0 ?
                                <Button
                                    className={`primaryButton`}
                                    onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                >
                                    Send Interest
                                </Button>
                                :
                                <></>
                        }
                        {
                            interest_sent_status === 1 && interest_received_status === 0 ?
                                <Button
                                    className={`primaryButton intrestSentStyle`}
                                    onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                >
                                    Interest Sent
                                </Button>
                                :
                                <></>
                        }
                        <Button
                            className={`${style.sortListedButton} ${shortlisted ? style.sortlisted : ""}`}
                            onClick={() => handleShortlist(token, shortlisted ? 0 : 1)}
                        >
                            <Image
                                src={shortlisted ? sortlistStarIcon : sortlistGrayStarIcon}
                            />
                            {shortlisted ? "Shortlisted" : "Shortlist"}
                        </Button>
                    </div>
                </div>
                {
                    interest_received_status === 2 ?
                        <div className={`${style.intrestSentContainer} ${style.accepted}`}>
                            <p className={style.declineInfoText}>You have accepted the interest request, now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    interest_received_status === 3 ?
                        <>
                            <p className={style.declineInfoText}>You declined the interest request.</p>
                            <div className={style.aboutDescription}>
                                <Image
                                    src={infoIcon}
                                />
                                <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                            </div>
                        </>
                        :
                        <></>
                }
                {
                    interest_sent_status === 2 ?
                        <div className={`${style.intrestSentContainer}`}>
                            <p className={style.declineInfoText}>{name} has accepted your interest.</p>
                            <p className={style.declineInfoText}> Now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        interest_sent_status === 3 ?
                            <>
                                <p className={style.declineInfoText}>Dr. {name} has declined your interest.</p>
                                <div className={style.aboutDescription}>
                                    <Image
                                        src={infoIcon}
                                    />
                                    <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                                </div>
                            </>
                            :
                            <></>
                }
                {
                    interest_received_status === 1 ?
                        <div className={style.buttonProgressContainer}>
                            <p className={style.declineInfoText}>Dr.{name} has expressed interest in you. Do you want to accept their request?</p>
                            <div className={style.buttonContainer}>
                                <Button
                                    className={`${style.button} primaryButton`}
                                    onClick={() => handleAccept(token)}
                                >
                                    Accept
                                </Button>
                                <Button
                                    className={`${style.declineButton} primaryButton cancelButton`}
                                    onClick={() => {
                                        setRejectToken(token)
                                        avoidScroll()
                                        setShowRejectReasonModal(true)
                                        // handleCancel(token)
                                    }}
                                >
                                    Decline
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                }
            </>
        )
    }

    useEffect(() => {
        if (successChatSetter) {
            navigation("/chat", { state: { token: chatToken } })
        }
        if (errorChatSetter) {
            console.log("errorChatSetter", errorChatSetter);
        }
    }, [successChatSetter, errorChatSetter]);

    const handleChat = (token, name, user_id, image) => {
        chatRoomSetup(
            token,
            name,
            user_id,
            image
            , (data) => {
                setChatToken(data)
            })
    }

    const openFilterDrawer = () => {
        avoidScroll()

        document.getElementById('filterContainer').scrollIntoView({
            behavior: 'smooth'
        });
        setFilterDataFromFilter(filterData)
        setIsDrawerOpen(true)
    }

    const clearFilterValue = (key, index) => {
        if (key === "profile_created_name") {
            setFilterData(prevFilterData => ({
                ...prevFilterData,
                profile_created_name: key === "profile_created_name" ? "" : prevFilterData[key]?.filter((_, i) => i !== index),
                profile_created_value: ""
            }));
            setFilterDataFromFilter(prevFilterData => ({
                ...prevFilterData,
                profile_created_name: key === "profile_created_name" ? "" : prevFilterData[key]?.filter((_, i) => i !== index),
                profile_created_value: ""
            }));
        } else {
            console.log("key", key, "index", index);
            setFilterData(prevFilterData => ({
                ...prevFilterData,
                [key]: key === "profile_created_name" ? "" : prevFilterData[key]?.filter((_, i) => i !== index)
            }));
            setFilterDataFromFilter(prevFilterData => ({
                ...prevFilterData,
                [key]: key === "profile_created_name" ? "" : prevFilterData[key]?.filter((_, i) => i !== index)
            }));
        }
        setData([])
        setLastPageFilter(false)
        setCurrentPage(1)
        setToggleFilterApi(!toggleFilterApi)
    }

    const profileNavigation = (token) => {
        localStorage.setItem("matchesTab", type)
        navigation("/profile_details", { state: { token: token } })
    }

    return (
        <>
            <ModalBox
                open={showRejectReasonModal}
                width={"598px"}
                modalContent={
                    <DeclineModal
                        buttonText={"Okay"}
                        setRejectReason={setRejectReason}
                        setShowRejectReasonModal={setShowRejectReasonModal}
                        onClick={handleCancel}
                    />
                }
            />
            <SideDrawer
                isOpen={isDrawerOpen}
                closeDrawer={closeDrawer}
                content={
                    <Filter
                        setIsScrollAction={setIsScrollAction} 
                        filterDataFromFilter={filterDataFromFilter}
                        setFilterDataFromFilter={setFilterDataFromFilter}
                        setFilterHasValue={setFilterHasValue}
                        pageType={type}
                        setReachedLastPage={setReachedLastPage}
                        setDataClear={setData}
                        setCurrentPage={setCurrentPage}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        closeDrawer={closeDrawer}
                        setFilterSubmitted={setFilterSubmitted}
                        setToggleFilterApi={setToggleFilterApi}
                        toggleFilterApi={toggleFilterApi}
                        setLastPageFilter={setLastPageFilter}
                        setLoadingMore={setLoadingMore}
                        setRefresh={setRefresh}
                        refresh={refresh}
                    />
                }
            />
            {
                (interestClicked && showBecameMemberModal) ? (
                    <BecameMemberModal status={true} setInterestClicked={setInterestClicked} />
                ) : <></>
            }
            {showSubscribeWidget ? (
                <div className={style.loaderContainer}>
                    <div className={style.pageContentContainer}>
                        <p className={style.title}>{pageCredentials?.title}</p>
                        <div className={style.subHeadingContainer}>
                            <p className={style.heading}>{total} {pageCredentials?.total_title}</p>
                        </div>
                        <SubscribeWidget height={40} />
                    </div>
                </div>
            ) : !isLoading ? (
                <div className={style.pageContentContainer} ref={pageContentContainerRef}>
                    <p className={style.title}>{pageCredentials?.title}</p>
                    <div className={style.subHeadingContainer}>
                        <p className={style.heading}>{total} {pageCredentials?.total_title}</p>
                        <div className={style.filterContainer}>
                            <Image onClick={() => setIsGridView(!isGridView)} src={isGridView ? listViewIcon : gridIcon} className={style.gridIcon} />
                            {(type === "Best Matches" || type === "Newly Joined" || type === "All Matches") && (<Image onClick={openFilterDrawer} src={filterIcon} className={style.gridIcon} />)}
                        </div>
                    </div>
                    <div className={style.filteredDataContainer}>
                        {
                            limitExceeded ? (
                                <div className={style.intrestLimitExceededContainer}>
                                    <div className={style.titleContainer}>
                                        <div className={style.intrestLimitTitle}>
                                            Please Upgrade Subscription
                                        </div>
                                        <div className={style.intrestLimitDescription}>
                                        You have reached the limit for sending interests as a complimentary user. To send more interests, please upgrade your account.
                                        </div>
                                    </div>
                                    <Button
                                        className={`${style.subscribeButton} primaryButton`}
                                        onClick={() => {
                                            localStorage.setItem('subscriptionFromHome', 3);
                                            navigation("/subscribe")
                                        }}
                                    >
                                        Subscribe Now
                                    </Button>
                                </div>
                            ) : <></>
                        }
                        <div className={style.filteredDatas}>
                            {
                                filterHasValue && Object.entries(filterData).map(([key, value], index) => (
                                    key !== "profile_created_value" && (
                                        typeof value === "object" ?
                                            value?.length ? value?.map((item, index) => {
                                                return (
                                                    <div key={index} className={style.filteredData}>
                                                        <p className={style.filterValue}>{item.name}</p>
                                                        <Image onClick={() => clearFilterValue(key, index)} src={clearIcon} className={style.clearIcon} />
                                                    </div>
                                                )
                                            })
                                                : <></>
                                            :
                                            value && key !== "height" && key !== "age" ?
                                                <div key={index} className={style.filteredData}>
                                                    <p className={style.filterValue}>{key !== "height" || key !== "age" ? value : value?.name}</p>
                                                    <Image onClick={() => clearFilterValue(key, index)} src={clearIcon} className={style.clearIcon} />
                                                </div>
                                                :
                                                <></>
                                    )
                                )
                                )}
                        </div>
                    </div>
                    <div className={style.pageContent}>
                        {
                            data?.length > 0 ?
                                isGridView ? (
                                    <>
                                        <div className={`${style.gridViewContainer} gridSection`}>
                                            {data?.map((item, index) => (
                                                <>
                                                    <div
                                                        style={{
                                                            // backgroundImage: `url(${process.env.REACT_APP_S3_CLOUD_FRONT_URL+item.image})`
                                                        }}
                                                        className={style.gridItem}
                                                    >
                                                        <Carousel
                                                            containerClassName={style.imagesCarouselContainer}
                                                            cols={1}
                                                            rows={1}
                                                            gap={40}
                                                            loop
                                                            dotColorActive={"#fff"}
                                                            dotColorInactive={"#ffffff75"}
                                                            showDots={true}
                                                            arrowLeft={<Image src={professionWhiteIcon} className={style.arrowLeft} />}
                                                            arrowRight={<Image src={professionWhiteIcon} className={style.arrowRight} />}
                                                        >
                                                            {
                                                                item?.images?.map((itemImage, index) => (
                                                                    <Carousel.Item>
                                                                        <div className={style.userProfileContainer} onClick={() => profileNavigation(item?.token)}>
                                                                            <MaskImage
                                                                                imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + itemImage}
                                                                                watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                                                                width={313}
                                                                                height={318}
                                                                                side={true}
                                                                                x1={-140}
                                                                                x2={-140}
                                                                                y1={-100}
                                                                                y2={-120}
                                                                            />
                                                                            {/* <Image
                                                                                onClick={() => profileNavigation(item?.token)}
                                                                                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + itemImage}
                                                                                className={style.profileCarousalImage}
                                                                            /> */}
                                                                        </div>
                                                                    </Carousel.Item>
                                                                ))
                                                            }
                                                        </Carousel>
                                                        <div className={style.gridViewContentContainer}>
                                                            <p onClick={() => profileNavigation(item?.token)} className={style.name}>{item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}</p>
                                                            {item?.is_onboard_completed === 1 ? <>
                                                                <div onClick={() => profileNavigation(item?.token)} className={style.personalDetails}>
                                                                    <p className={style.personaTtext}>{item.height}</p>
                                                                    <p className={style.personaTtext}>{item.mother_tongue}</p>
                                                                    <p className={style.personaTtext}>{item.community}</p>
                                                                    <p className={style.personaTtext}>{item.cityname}</p>
                                                                </div>
                                                                <div className={style.personalDetails}>
                                                                    <Image
                                                                        src={degreeWhiteIcon}
                                                                        className={style.professionIcon}
                                                                    />
                                                                    <p className={style.personaTtext}>{item.occupation}</p>
                                                                    <p className={style.personaTtext}>{item.annual_income}</p>
                                                                </div>
                                                            </> : ""}
                                                            <div className={style.shortListIntrestContainer}>
                                                                <div className={style.buttonsContainer}>
                                                                    <Image
                                                                        onClick={() => handleShortlist(item.token, item?.shortlisted ? 0 : 1)}
                                                                        src={item?.shortlisted ? sortlistStarIcon : unShortlistIcon}
                                                                    />
                                                                    <p className={style.shortlistText}>{item?.shortlisted ? "Shortlisted" : "Shortlist"}</p>
                                                                </div>
                                                                <div className={style.buttonsContainer}>
                                                                    <Image
                                                                        onClick={() => handleSendInterest(item.token, item?.sentInterest ? 0 : 1)}
                                                                        src={item?.sentInterest ? intrestSentIcon : sendIntrestIcon}
                                                                    />
                                                                    <p className={style.shortlistText}>{item?.sentInterest ? "Interest Sent" : "Send Interest"}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {data?.map((item, index) => (
                                            <div key={item.user_id} className={style.content}>
                                                <div className={style.imageAndDetailsContainer}>
                                                    <div className={style.detailsContainer} onClick={() => profileNavigation(item?.token)}>
                                                        <MaskImage
                                                            imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                            watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                                            width={182}
                                                            height={242}
                                                            side={false}
                                                        />
                                                    </div>
                                                    <div className={`${style.detailsContainer} ${style.nameBlockReportContainer}`}>
                                                        <div className={style.headerContainer}>
                                                            <div className={style.nameContainer}>
                                                                <div className={style.nameBlockContainer}>
                                                                    <div className={style.idTypeContainer}>
                                                                        <p className={style.id} onClick={() => profileNavigation(item?.token)}>{item.user_id}</p>
                                                                        <MembershipTypeCard type={item?.membership_type} />
                                                                    </div>
                                                                    <BlockReport setCurrentPage={setCurrentPage} setData={setData} setIsScrollAction={setIsScrollAction}  refresh={refresh} setRefresh={setRefresh} setLastPageFilter={setLastPageFilter} id={item.user_id} token={item.token} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h1 className={style.name} onClick={() => profileNavigation(item?.token)}>{item.name}<span>({item.age} yrs)</span></h1>
                                                        {item?.is_onboard_completed === 1 ? <>
                                                            <div className={style.personalDetails}>
                                                                <p className={style.personaTtext}>{item.height}</p>
                                                                <p className={style.personaTtext}>{item.mother_tongue}</p>
                                                                <p className={style.personaTtext}>{item.community}</p>
                                                                <p className={style.personaTtext}>{item.cityname}</p>
                                                            </div>
                                                            <div className={style.personalDetails}>
                                                                <Image
                                                                    src={degreeIcon}
                                                                    className={style.professionIcon}
                                                                />
                                                                <p className={style.personaTtext}>{item.occupation}</p>
                                                                <p className={style.personaTtext}>{item.annual_income}</p>
                                                            </div>
                                                            <p className={style.aboutText}>About {item.name}</p>
                                                            <p className={style.aboutDescription}>{item.about.length > 180 ? `${item.about.substring(0, 180)}...` : item.about}</p>
                                                        </> : <div className='non-onboarded-user'> </div>}
                                                        {
                                                            actionUi(
                                                                item?.can_send_interest,
                                                                item?.interest_received_status,
                                                                item?.interest_sent_status,
                                                                item?.shortlisted,
                                                                item?.is_connected,
                                                                item?.token,
                                                                item?.name,
                                                                item?.image,
                                                                item?.user_id,
                                                            )
                                                        }
                                                        {/* <div className={style.buttonsContianer}>
                                                            <Button
                                                                className={`primaryButton`}
                                                                onClick={()=> handleSendInterest(item.token, item?.intrestButtonText === "Interest sent" ? 1 : 0)}
                                                            >
                                                                {item.intrestButtonText}
                                                            </Button>
                                                            <Button
                                                                className={`${style.sortListedButton} ${item.shortlistButtonText === "Shortlisted" ? style.sortlisted : ""}`}
                                                                onClick={()=> handleShortlist(item.token, item?.shortlistButtonText === "Shortlisted" ? 1 : 0 )}
                                                            >
                                                                <Image
                                                                    src={item.shortlistButtonText === "Shortlisted" ? sortlistStarIcon : sortlistGrayStarIcon}
                                                                />
                                                                {item.shortlistButtonText}
                                                            </Button>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </>
                                )
                                :
                                !isLoading && (
                                    <NoData isChat={false} title={`No profiles found`} />
                                )

                        }
                    </div>
                    {loadingMore && !lastPage && (
                        <div className={style.loaderContainer}>
                            <Circles
                                height="35"
                                width="35"
                                color="rgb(239, 116, 53)"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass={style.loader}
                                visible={true}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={style.loaderContainer}>
                    <Circles
                        height="35"
                        width="35"
                        color="rgb(239, 116, 53)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass={style.loader}
                        visible={true}
                    />
                </div>
            )}
        </>
    );
}
