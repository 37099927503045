import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ModalBox = ({ open, modalContent, width }) => {

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ 
          position: 'absolute',
          top: '50%', 
          left: '50%',
          borderRadius: "12px", 
          transform: 'translate(-50%, -50%)', 
          maxWidth: width, 
          width: "90%", 
          bgcolor: 'background.paper', 
          boxShadow: 24, 
          p: 4, 
          paddingBottom: "24px",
          maxHeight: "670px",
          overflowY: "scroll",
          outline: "none"
        }}>
          <Typography id="modal-description">
            {modalContent}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ModalBox;
