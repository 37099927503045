import React from "react"
import style from "./Content.module.css"
import Button from "../../../../../../ui/Button/Button"
import { addScroll } from "../../../../../../utils/utils"

export const Content = ({onClick, setViewSuccessModal}) => {
    return(
        <div className={style.contentContainer}>
            <p className={style.title}>Are you sure want to logout?</p>
            <div className={style.btn_group}>
                <Button className={`primaryButton ${style.cancel}`} onClick={() => {
                    addScroll()
                    setViewSuccessModal(false)
                }}>No</Button>
                <Button className={`primaryButton ${style.submitButton}`} onClick={onClick}>Yes</Button>
            </div>
        </div>
    )
}