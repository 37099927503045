import React, { useState, useEffect } from "react";
import Tabs from "../../UI/Tabs/Tabs";
import DropdownButton from "../../UI/DropdownButton/DropdownButton";
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import Table from "../../UI/Table/TablePage";
import useApiHttp from "../../hooks/ues-http";
import Search from "../../UI/Search/Search";
import TableHeader from "../../UI/Table/TableHeader";
import Elite from "../../assets/SVG/Manage Users/Elite.svg";
import Premium from "../../assets/SVG/Manage Users/Premium.svg";
import basic from "../../assets/SVG/common/white.svg";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../utils/baseUrl";
import { useNumber } from "../../Context/CreatContext";
import UsersViewPage from "./UsersViewPage";
import VerifiViewPage from "./VerifiViewPage";
import classes from "./ManageUsers.module.css";
import { AnimatePresence, motion } from "framer-motion";

const Manageusers = () => {
  const { count, widgetCountRequestData } = useNumber();

  const [refresh, setRefresh] = useState(true);
  const [userDatas, setUserDatas] = useState([]);
  const [search, setSearch] = useState("");
  const [recentTotal, setRecentTotal] = useState([]);
  const [recentTotalAcount, setRecentTotalAcount] = useState(0);
  const [downloadCsvs, setDownloadCsvs] = useState("");

  const [activeUserDatas, setActiveUserDatas] = useState([]);
  const [activeUserSearch, setActiveUserSearch] = useState("");
  const [activeTotal, setActiveTotal] = useState([]);
  const [activeTotalAcount, setActiveTotalAcount] = useState(0);

  const [inActiveUserDatas, setInActiveUserDatas] = useState([]);
  const [inactiveUserSearch, setInActiveUserSearch] = useState("");
  const [inactiveTotal, setInactiveTotal] = useState([]);
  const [inactiveTotalAcount, setInactiveTotalAcount] = useState(0);

  const [pendingUserDatas, setPendingUserDatas] = useState([]);
  const [pendingUserSearch, setPendingUserSearch] = useState("");
  const [pendingTotal, setPendingTotal] = useState([]);
  const [pendingTotalAcount, setPendingTotalAcount] = useState(0);

  const [blockedUserDatas, setBlockedUserDatas] = useState([]);
  const [blockedUserSearch, setBlockedUserSearch] = useState("");
  const [blockedTotal, setBlockedTotal] = useState([]);
  const [blockedTotalAcount, setBlockedTotalAcount] = useState(0);

  const [sortDirectionData, setSortDirectionData] = useState("DESC");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [column, setColumn] = useState(0);
  const [tokenUser, setTokenUser] = useState("");
  const [verifyUserToke, setVerifyUserToke] = useState("");
  const [identifi, setIdentifi] = useState("Recently Joined");

  console.log("tokenUser", tokenUser);
  const onViewPage = (token) => {
    setTokenUser(token);
  };
  const verifyUserTokes = (token) => {
    setVerifyUserToke(token);
  };
  // useEffect(() => {
  //     const page = parseInt(localStorage.getItem('page'));
  //     const newPerPage = parseInt(localStorage.getItem('newPerPage'));
  //     if (page > 1) {
  //         setPage(page)
  //     } else {
  //         setPage(1)
  //     }
  //     if (newPerPage > 10) {
  //         setPerPage(newPerPage)
  //     } else {
  //         setPerPage(10)
  //     }
  // }, [page, perPage])
  // Retrieve the active tab index from local storage
  const initialActiveTab = parseInt(localStorage.getItem("manageTabIndex"), 10);
  const [activeTab, setActiveTab] = useState(initialActiveTab || 0);

  const { isLoading, success, error, sendRequest } = useApiHttp();
  const fetchUserData = (type) => {
    let requestData;
    if (
      type === "recent" ||
      type === "active" ||
      type === "inactive" ||
      type === "pending" ||
      type === "blocked"
    ) {
      requestData = {
        search_value: search,
        page: page,
        per_page: perPage,
        sort_dir: sortDirectionData,
        sort_column: column,
        type: type,
      };
    }
    sendRequest(
      {
        url: `${BASE_URL}ssp-manage-user`,
        method: "POST",
        body: requestData,
      },
      (data) => {
        if (type === "recent") {
          setUserDatas(data.aaData);
          setRecentTotal(data.iTotalRecords);
          setRecentTotalAcount(data.iTotalDisplayRecords);
        } else if (type === "active") {
          setActiveUserDatas(data.aaData);
          setActiveTotal(data.iTotalRecords);
          setActiveTotalAcount(data.iTotalDisplayRecords);
        } else if (type === "inactive") {
          setInActiveUserDatas(data.aaData);
          setInactiveTotal(data.iTotalRecords);
          setInactiveTotalAcount(data.iTotalDisplayRecords);
        } else if (type === "pending") {
          setPendingUserDatas(data.aaData);
          setPendingTotal(data.iTotalRecords);
          setPendingTotalAcount(data.iTotalDisplayRecords);
        } else if (type === "blocked") {
          setBlockedUserDatas(data.aaData);
          setBlockedTotal(data.iTotalRecords);
          setBlockedTotalAcount(data.iTotalDisplayRecords);
        } else {
          console.error("Error fetching data:", error);
        }
      }
    );
  };

  const handleSearchChange = (newValue, type) => {
    if (type === "recent") {
      setSearch(newValue);
    } else if (type === "active") {
      setSearch(newValue);
    } else if (type === "inactive") {
      setSearch(newValue);
    } else if (type === "pending") {
      setSearch(newValue);
    } else if (type === "blocked") {
      setSearch(newValue);
    }
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "User ID",
      selector: (row) => (
        <>
          <p
            onClick={() => onViewPage(row.token)}
            className="action_text action_text_pointer">
            {row.user_id}
          </p>
        </>
      ),
      // selector: (row) => (<><Link to={`/admin/dashboard/usersviewpage/${row.token}`} className='action_text action_text_pointer'>{row.user_id}</Link></>),
      sortable: true,
      sortField: 1,
    },
    {
      name: "User Name",
      selector: (row) => row.user_name,
      sortable: true,
      sortField: 2,
    },
    {
      name: "Contact Number",
      selector: (row) => row.contact_number,
      sortable: true,
      sortField: 3,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      sortField: 4,
    },
    {
      name: "Education",
      selector: (row) => row.education,
      sortable: true,
      sortField: 5,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      sortField: 6,
    },
    {
      name: "NMC or SMC Number",
      selector: (row) => row.imr_number,
      sortable: true,
      sortField: 7,
    },
    {
      name: "Registered Date",
      selector: (row) => row.reg_date,
      sortable: true,
      sortField: 8,
    },
    {
      name: "Member Type",
      cell: (row) => (
        <>
          {" "}
          {row.member_type === "Elite" && (
            <span className="wigite_lable elite">
              <img src={Elite} alt="Elite" />
              {row.member_type}
            </span>
          )}{" "}
          {row.member_type === "Premium" && (
            <span className="wigite_lable premium">
              <img src={Premium} alt="Premium" />
              {row.member_type}
            </span>
          )}{" "}
          {row.member_type === "Basic" && (
            <span className="wigite_lable basic">
              <img src={basic} width={"16px"} alt="Premium" />
              {row.member_type}
            </span>
          )}
          {row.member_type === "Rejected" && (
            <span className="wigite_lable_satus rejected">
              {row.member_type}
            </span>
          )}
        </>
      ),
      sortable: true,
      sortField: 9,
    },
    // {
    //   name: "",
    //   cell: (row) => (
    //     <>
    //       {row.member_type === "Basic" && (
    //         <span
    //           className="verifiyed_status width"
    //           onClick={() => verifyUserTokes(row.token)}>
    //           Verify
    //         </span>
    //       )}
    //       {row.member_type === "Rejected" && (
    //         <span
    //           className="verifiyed_status width"
    //           onClick={() => verifyUserTokes(row.token)}>
    //           View Reason
    //         </span>
    //       )}
    //     </>
    //   ),
    //   sortable: false,
    //   sortField: 10,
    // },
  ];
  const active = [
    {
      name: "S.No",
      selector: (row) => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "User ID",
      selector: (row) => (
        <>
          <p
            className="action_text action_text_pointer"
            onClick={() => onViewPage(row.token)}>
            {row.user_id}
          </p>
        </>
      ),
      // selector: (row) => (<><Link to={`/admin/dashboard/usersviewpage/${row.token}`} className='action_text action_text_pointer' state={{ some: "value" }}>{row.user_id}</Link></>),
      sortable: true,
      sortField: 1,
    },
    {
      name: "User Name",
      selector: (row) => row.user_name,
      sortable: true,
      sortField: 2,
    },
    {
      name: "Contact Number",
      selector: (row) => row.contact_number,
      sortable: true,
      sortField: 3,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      sortField: 4,
    },
    {
      name: "Education",
      selector: (row) => row.education,
      sortable: true,
      sortField: 5,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      sortField: 6,
    },
    {
      name: "NMC or SMC Number",
      selector: (row) => row.imr_number,
      sortable: true,
      sortField: 7,
    },
    {
      name: "Registered Date",
      selector: (row) => row.reg_date,
      sortable: true,
      sortField: 8,
    },
    {
      name: "Member Type",
      cell: (row) => (
        <>
          {" "}
          {row.member_type === "Elite" && (
            <span className="wigite_lable elite">
              <img src={Elite} alt="Elite" />
              {row.member_type}
            </span>
          )}{" "}
          {row.member_type === "Premium" && (
            <span className="wigite_lable premium">
              <img src={Premium} alt="Premium" />
              {row.member_type}
            </span>
          )}{" "}
          {row.member_type === "Basic" && (
            <span className="wigite_lable basic">
              <img src={basic} width={"16px"} alt="Premium" />
              {row.member_type}
            </span>
          )}
        </>
      ),
      sortable: true,
      sortField: 9,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          {" "}
          {row.photo_uploaded === 1 ? (
            <span className="photo__lable photo_update">{"PHOTO UPDATED"}</span>
          ) : (
            <span>-</span>
          )}
        </>
      ),
      sortable: true,
      sortField: 9,
    },
  ];

  const Verifycolumn = [
    {
      name: "S.No",
      selector: (row) => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "User ID",
      selector: (row) => (
        <>
          <p
            className="action_text action_text_pointer"
            onClick={() => verifyUserTokes(row.token)}>
            {row.user_id}
          </p>
        </>
      ),
      // cell: (row) => (<><Link to={`/admin/dashboard/verifiviewpage/${row.token}`} className='action_text action_text_pointer'>{row.user_id}</Link></>),
      sortable: true,
      sortField: 1,
    },
    {
      name: "User Name",
      selector: (row) => row.user_name,
      sortable: true,
      sortField: 2,
    },

    {
      name: "Mobile Number",
      selector: (row) => row.contact_number,
      sortable: true,
      sortField: 3,
    },
    {
      name: "NMC or SMC Number",
      selector: (row) => row.imr_number,
      sortable: true,
      sortField: 4,
    },
    {
      name: "Registered Date",
      selector: (row) => row.reg_date,
      sortable: true,
      sortField: 5,
    },
    {
      name: "Status",
      cell: (row) => (
        <>
          {" "}
          {row.status === "Pending" && (
            <span className="wigite_lable_satus pending">{row.status}</span>
          )}{" "}
          {row.status === "Verified" && (
            <span className="wigite_lable_satus verified">{row.status}</span>
          )}{" "}
          {row.status === "Rejected" && (
            <span className="wigite_lable_satus rejected">{row.status}</span>
          )}{" "}
          {row.status === "Subscribed" && (
            <span className="wigite_lable_satus blue_info">{row.status}</span>
          )}{" "}
          {row.status === "Onboarded" && (
            <span className="wigite_lable_satus blue_info">{row.status}</span>
          )}{" "}
          {row.status === "Onboarding" && (
            <span className="wigite_lable_satus blue_info">{row.status}</span>
          )}{" "}
          {row.status === "Preferencing" && (
            <span className="wigite_lable_satus blue_info">{row.status}</span>
          )}
        </>
      ),
      sortable: true,
      sortField: 6,
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setSortDirectionData(sortDirection || "desc");
    setColumn(column.sortField || 0);
    console.log("column", sortDirection, column.sortField);
  };

  const handlePageChange = (page) => {
    setPage(page || 1);
    {
      page > 1
        ? localStorage.setItem("page", parseInt(page))
        : console.log(page);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage || 10);
    {
      newPerPage > 10
        ? localStorage.setItem("newPerPage", parseInt(newPerPage))
        : console.log(newPerPage);
    }
  };

  const toggleRefresh = () => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 200); // Refreshing after 1 second
  };

  const onTabClick = (index) => {
    setActiveTab(index); // Update the active tab index
    localStorage.setItem("manageTabIndex", index.toString());
    toggleRefresh();
    setSortDirectionData("DESC");
    setPerPage(10);
    setPage(1);
    setColumn(0);
    localStorage.setItem("page", parseInt(1));
    localStorage.setItem("newPerPage", parseInt(10));
    if (index === 0) {
      fetchUserData("recent"); // Fetch data for the "Recently Joined" tab
      downlodRecentRequestData();
      setSearch("");
      setIdentifi("Recently Joined")
    } else if (index === 1) {
      fetchUserData("active"); // Fetch data for the "Active Users" tab
      downlodActiveRequestData();
      setSearch("");
      setIdentifi("Active Users")
    } else if (index === 2) {
      fetchUserData("inactive");
      downlodInactiveRequestData();
      setSearch("");
      setIdentifi("Inactive Users")
    } else if (index === 3) {
      fetchUserData("pending");
      downlodPendingRequestData();
      setSearch("");
      setIdentifi("Yet to be Verified")
    } else if (index === 4) {
      fetchUserData("blocked");
      downlodBlockedRequestData();
      setSearch("");
      setIdentifi("Blocked Users")
    }
  };

  useEffect(() => {
    if (activeTab === 0) {
      fetchUserData("recent");
    } else if (activeTab === 1) {
      fetchUserData("active");
    } else if (activeTab === 2) {
      fetchUserData("inactive");
    } else if (activeTab === 3) {
      fetchUserData("pending");
    } else if (activeTab === 4) {
      fetchUserData("blocked");
    }
  }, [search, page, perPage, sortDirectionData, column, activeTab]);

  const handleExportCSV = () => {
    if (activeTab === 0) {
      if (downloadCsvs) {
        const link = document.createElement("a");
        link.href = downloadCsvs.download_link;
        link.download = "downloaded.csv";
        link.click();
      }
    } else if (activeTab === 1) {
      if (downloadCsvs) {
        const link = document.createElement("a");
        link.href = downloadCsvs.download_link;
        link.download = "downloaded.csv";
        link.click();
      }
    } else if (activeTab === 2) {
      if (downloadCsvs) {
        const link = document.createElement("a");
        link.href = downloadCsvs.download_link;
        link.download = "downloaded.csv";
        link.click();
      }
    } else if (activeTab === 3) {
      if (downloadCsvs) {
        const link = document.createElement("a");
        link.href = downloadCsvs.download_link;
        link.download = "downloaded.csv";
        link.click();
      }
    } else if (activeTab === 4) {
      if (downloadCsvs) {
        const link = document.createElement("a");
        link.href = downloadCsvs.download_link;
        link.download = "downloaded.csv";
        link.click();
      }
    }
  };
  const handleMenuItemClick = (item) => {
    if (item.label === "CSV") {
      handleExportCSV();
    }
  };
  useEffect(() => {
    // Clear the local storage value when the page is reloaded
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("manageTabIndex");
    });
  }, []);

  // Export CSV
  const { sendRequest: downlodRecentRequest } = useApiHttp();
  const downlodRecentData = (data) => {
    setDownloadCsvs(data);
  };
  const downlodRecentRequestData = (data) => {
    downlodRecentRequest(
      {
        url: `${BASE_URL}csv-recent-join`,
        method: "POST",
        body: {
          search_value: search,
        },
      },
      downlodRecentData
    );
  };

  const { sendRequest: downlodActiveRequest } = useApiHttp();
  const downlodActiveData = (data) => {
    setDownloadCsvs(data);
  };
  const downlodActiveRequestData = () => {
    downlodActiveRequest(
      {
        url: `${BASE_URL}csv-active-user`,
        method: "POST",
        body: {
          search_value: search,
        },
      },
      downlodActiveData
    );
  };

  const { sendRequest: downlodInactiveRequest } = useApiHttp();
  const downlodInactiveData = (data) => {
    setDownloadCsvs(data);
  };
  const downlodInactiveRequestData = () => {
    downlodInactiveRequest(
      {
        url: `${BASE_URL}csv-inactive-user`,
        method: "POST",
        body: {
          search_value: search,
        },
      },
      downlodInactiveData
    );
  };

  const { sendRequest: downlodPendingRequest } = useApiHttp();
  const downlodPendingData = (data) => {
    setDownloadCsvs(data);
  };
  const downlodPendingRequestData = () => {
    downlodPendingRequest(
      {
        url: `${BASE_URL}csv-pending-user`,
        method: "POST",
        body: {
          search_value: search,
        },
      },
      downlodPendingData
    );
  };

  const { sendRequest: downlodBlockedRequest } = useApiHttp();
  const downlodBlockedData = (data) => {
    setDownloadCsvs(data);
  };
  const downlodBlockedRequestData = () => {
    downlodBlockedRequest(
      {
        url: `${BASE_URL}csv-blocked-user`,
        method: "POST",
        body: {
          search_value: search,
        },
      },
      downlodBlockedData
    );
  };
  useEffect(() => {
    if (activeTab === 0) {
      downlodRecentRequestData();
    } else if (activeTab === 1) {
      downlodActiveRequestData();
    } else if (activeTab === 2) {
      downlodInactiveRequestData();
    } else if (activeTab === 3) {
      downlodPendingRequestData();
    } else if (activeTab === 4) {
      downlodBlockedRequestData();
    }
  }, [search]);

  const tabAnimation = {
    initial: {
      y: -200,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      y: -200,
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      <div
        className={
          tokenUser || verifyUserToke
            ? classes.display_none
            : classes.display_block
        }>
        <PagesHeader
          title="Manage Users"
          children={
            <>
              <DropdownButton
                buttonLabel="Download as"
                menuItems={[
                  {
                    label: "CSV",
                    render: () => (
                      <span onClick={handleExportCSV}>Export CSV</span>
                    ),
                  },
                ]}
                onMenuItemClick={handleMenuItemClick}
              />{" "}
            </>
          }
        />
        <Tabs
          tabs={[
            {
              title: "Recently Joined",
              render: () =>
                refresh && (
                  <motion.dev
                    variants={tabAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit">
                    <Table
                      data={userDatas}
                      columns={columns}
                      loader={isLoading}
                      onSort={handleSort}
                      paginationTotalRows={recentTotal}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      children={
                        <div className="header_table">
                          <TableHeader
                            tableheader="Recently Joined"
                            totalusers={`${recentTotal} total users`}
                          />
                          <Search
                            value={search}
                            onChange={(newValue) =>
                              handleSearchChange(newValue, "recent")
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </div>
                      }
                    />
                  </motion.dev>
                ),
            },
            {
              title: "Active Users",
              msg: `${count == 0 ? "false" : "true"}`,
              count: `${count}`,
              render: () =>
                refresh && (
                  <motion.dev
                    variants={tabAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit">
                    <Table
                      data={activeUserDatas}
                      columns={active}
                      loader={isLoading}
                      onSort={handleSort}
                      paginationTotalRows={activeTotal}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      children={
                        <div className="header_table">
                          <TableHeader
                            tableheader="Active Users"
                            totalusers={`${activeTotal} total users`}
                          />
                          <Search
                            value={search}
                            onChange={(newValue) =>
                              handleSearchChange(newValue, "active")
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </div>
                      }
                    />
                  </motion.dev>
                ),
            },
            {
              title: "Inactive Users",
              render: () =>
                refresh && (
                  <motion.dev
                    variants={tabAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit">
                    <Table
                      data={inActiveUserDatas}
                      columns={columns}
                      loader={isLoading}
                      onSort={handleSort}
                      paginationTotalRows={inactiveTotal}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      children={
                        <div className="header_table">
                          <TableHeader
                            tableheader="Inactive Users"
                            totalusers={`${inactiveTotal} total users`}
                          />
                          <Search
                            value={search}
                            onChange={(newValue) =>
                              handleSearchChange(newValue, "inactive")
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </div>
                      }
                    />{" "}
                  </motion.dev>
                ),
            },
            {
              title: "Yet to be Verified",
              render: () =>
                refresh && (
                  <motion.dev
                    variants={tabAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit">
                    <Table
                      data={pendingUserDatas}
                      columns={Verifycolumn}
                      loader={isLoading}
                      onSort={handleSort}
                      paginationTotalRows={pendingTotal}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      children={
                        <div className="header_table">
                          <TableHeader
                            tableheader="Yet to be Verified"
                            totalusers={`${pendingTotal} total users`}
                          />
                          <Search
                            value={search}
                            onChange={(newValue) =>
                              handleSearchChange(newValue, "pending")
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </div>
                      }
                    />
                  </motion.dev>
                ),
            },
            {
              title: "Do Not Show Users",
              render: () =>
                refresh && (
                  <motion.dev
                    variants={tabAnimation}
                    initial="initial"
                    animate="animate"
                    exit="exit">
                    <Table
                      data={blockedUserDatas}
                      columns={columns}
                      loader={isLoading}
                      onSort={handleSort}
                      paginationTotalRows={blockedTotal}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      children={
                        <div className="header_table">
                          <TableHeader
                            tableheader="Do Not Show Users"
                            totalusers={`${blockedTotal} total users`}
                          />
                          <Search
                            value={search}
                            onChange={(newValue) =>
                              handleSearchChange(newValue, "blocked")
                            }
                            type="text"
                            placeholder="Search"
                          />
                        </div>
                      }
                    />
                  </motion.dev>
                ),
            },
          ]}
          onTabClick={onTabClick}
          activeTab={activeTab}
        />
      </div>

      {tokenUser ? (
        <div
          className={tokenUser ? classes.display_block : classes.display_none}>
          <UsersViewPage
            identifiLable={identifi}
            userToken={tokenUser}
            setTokenUser={setTokenUser}
            activeTabs={activeTab}
            datas={fetchUserData}
          />
        </div>
      ) : (
        ""
      )}
      {verifyUserToke ? (
        <VerifiViewPage
          identifiLable={identifi}
          setVerifyUserToke={setVerifyUserToke}
          verifyUserToke={verifyUserToke}
          datas={fetchUserData}
        />
      ) : (
        ""
      )}
    </AnimatePresence>
  );
};

export default Manageusers;
