import React from 'react'
import style from './SwitchComponts.module.css'
import CustomizedSwitches from '../../../../../ui/switch/Switch';

const SwitchComponts = ({ title, subTitle, checked, onChange, disabled }) => {
    return (
        <div className={style.switchComponts}>
            <div className={style.left}>
                <p className={style.title}>{title}</p>
                {subTitle ? <p className={style.sub_title}>{subTitle}</p> : ""}
            </div>
            <div className={style.right}>
                <CustomizedSwitches disabled={disabled} checked={checked} onChange={onChange} />
            </div>
        </div>
    )
}

export default SwitchComponts