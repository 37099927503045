import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './ProfessionalPreference.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import Button from '../../../../../ui/Button/Button';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../ui/Image/Image';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserToken, isStringValid, uploadFile } from '../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import { BuildPartnerProfileContext } from '../Context/BuildPartnerProfileContext';
import ModalBox from '../../../../../ui/Modal/ModalBox';
import Success from '../Modal/Success';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ProfessionalPreference() {

  const navigation = useNavigate("")

  const { sendRequest: getMetaData } = useApiCall();
  const [ viewDetailsModal, setViewDetailsModal ] = useState(false)
  
  const { setActiveStep, basicPreferenceData, setBasicPreferenceData } = useContext(BuildPartnerProfileContext);

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

  const [ educationData, setEducationData ] = useState([])
  const [ occupationData, setOccupationData ] = useState([])
  const [ employedInData, setEmployedInData ] = useState([])
  const [ annualIncomeData, setAnnualIncomeData ] = useState([])
  const [ countryData, setCountryData ] = useState([])
  const [ stateData, setStateData ] = useState([])
  const [ cityData, setCityData ] = useState([])
  const [ citizenshipData, setCitizenshipData ] = useState([])

  const [ validationError, setValidationError ] = useState("")

  const [ formClickedOnce, setFormClickedOnce ] = useState(false)

  const [ cityValue, setCityValue ] = useState([])

  const [formData, setFormData] = useState({
    employed: [],
    occupation: [],
    annualIncome: [],
    country: {
      token: "",
      value: ""
    },
    state: [],
    city: [],
  });
  const { sendRequest: getUserData } = useApiCall();
  const { sendRequest: getCountry } = useApiCall();
  const { sendRequest: getState } = useApiCall();
  const { sendRequest: getCity } = useApiCall();

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData(
      {
        url: 'preference/read-meta-2',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const { education, occupation, annual_income, citizenship, employed_in, country} = data.data;
        setEmployedInData(employed_in)
        setEducationData(education)
        setOccupationData(occupation)
        setAnnualIncomeData(annual_income)
        setCitizenshipData(citizenship)
        setCountryData(country);
      }
    );
  }, []);

  const extractTokenObject = (data, type) => {
    let token = []
    data?.map(item=> {
      token.push( {
        token: item.id,
        name: item.name
      })
    })
    return token;
  }

  useEffect(() => {
    getMetaData({
      url: "preference/read-step-2",
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`
      }
    }, (data) => {
      const { 
        work_location_detail : { 
          city_detail,
          country_detail,
          state_detail
        },
        professional_detail: {
          annual_income_detail,
          employed_in_detail,
          occupation_detail
        }
      } = data.data;
      setFormData({
        employed: employed_in_detail,
        occupation: occupation_detail,
        annualIncome: annual_income_detail,
        country: {
          token: country_detail.id,
          value: country_detail.name
        },
        state: extractTokenObject( state_detail ), 
        // city: extractTokenObject(city_detail)
      })
      setCityValue(extractTokenObject(city_detail))
    });
  }, []);

  useEffect(() => {
      getState({
          url: "state",
          method: 'POST',
          body: {
              "country_id": formData?.country.token
          }
      }, (data) => {
          setStateData(data.data); // Set work state data
          if( formClickedOnce ) {
            setCityData([])
          }
      });
  }, [formData?.country]);

  useEffect(() => {
      getCity({
          url: "city-for-multiple-states",
          method: 'POST',
          body: {
              "state_id": extractToken(formData?.state, "token")
          }
      }, (data) => {
          setCityData(data.data); // Set native city data
          // setCityValue([])
      });
  }, [formData?.state]);

  const handleInputChange = (event, inputType) => {
    setFormClickedOnce(true)
    if( event.target ) {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      if( inputType === "state" ) {
        setFormData({
          ...formData,
          [inputType]: event,
          city: []
        });
      } else if( inputType === "city" ) {
        setCityValue(event)
      } else {
        setFormData({
          ...formData,
          [inputType]: event,
        });
      }
    }
  };

  useEffect(() => {
    if (success) {
      setViewDetailsModal(true)
    }
    if (error !== '') {
      toast.error(error, {
          autoClose: 2500,
      })
    }
  }, [success, error]);

  const extractToken = (data, type) => {
    let token = []
    data?.map(item=> {
      token.push( item[type])
    })
    return token;
  }

  const [formDataTitle, setFormDataTitle ] = useState({
    employed: "Employed In",
    occupation: "Occupation",
    annualIncome: "Annual Income",
    country: "Country",
    state: "State",
    city: "City",
  });

  useEffect(() => {
    if (success) {
      setActiveStep(3)
    }
    if (error !== '') {
      toast.error(error, {
          autoClose: 2500,
      })
    }
  }, [success, error]);

  const submitForm = async () => {
    console.log("cityValue", cityValue);
    if (
      isStringValid(formData.employed) &&
      isStringValid(formData.occupation) &&
      isStringValid(formData.annualIncome) &&
      isStringValid(formData.country.value) &&
      isStringValid(formData.state) &&
      isStringValid(cityValue)
    ) {
      setValidationError("")
      const dataToSend = {
        "employed_in": extractToken(formData.employed, "token"),
        "occupation":extractToken(formData.occupation, "token"),
        "annual_income":extractToken(formData.annualIncome, "token"),
        "country":formData.country.value,
        "state":extractToken(formData.state, "name"),
        "city":extractToken(cityValue,  "name")
      };
      uploadUserDetails({
        url: `preference/save-step-2`,
        method: 'POST',
        body: dataToSend,
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
      })
      setBasicPreferenceData(dataToSend)
    } else {
      const dataArray = {
        employed: extractToken(formData.employed, "token"),
        occupation: extractToken(formData.occupation, "token"),
        annualIncome: extractToken(formData.annualIncome, "token"),
        country: formData.country.value,
        state: extractToken(formData.state, "name"),
        city: extractToken(formData.city,  "name"),
      }
      var result = Object.keys(dataArray).map((key) => [key, dataArray[key]]);
      for(const item of result || []) {
          if ( !item[1] || item[1].length == 0 || item[1] === "Select an option" ) {
              toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                  autoClose: 2500,
              })
              break; // This will exit the loop when the condition is met
          }
      }
    }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      {options.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.label}</p>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <ToastContainer />
      <ModalBox
        open={viewDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success 
            title={"Profile Created Successfully"} 
            description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
            buttonText={"Okay"}
            onClick={()=>{
              addScroll()
              setViewDetailsModal(false)
              navigation("/home")
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <ProgressBar width={`66.66%`} total={3} current={2} />
        <div className={style.formContainer}>
          <p className={style.formTitle}>Professional preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Employed in"
                name="employed"
                responseType="token"
                options={employedInData} // Add options
                value={formData.employed}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Annual Income"
                name="annualIncome"
                responseType="token"
                options={annualIncomeData} // Add options
                value={formData.annualIncome}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
            </div>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Occupation"
                name="occupation"
                responseType="token"
                options={occupationData} // Add options
                value={formData.occupation}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
            </div>
          </div>
        </div>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Location preferences</p>
          <div className={style.hobbiesContainer}>
              <div className={style.hobbieContainer}>
                <FloatingLabelDropdown
                  label="Country"
                  name="country"
                  responseType="id"
                  options={countryData} // Add options
                  value={formData.country.token}
                  onChange={(e, value) => {
                    setFormClickedOnce(true)
                    setFormData({ 
                      ...formData, 
                      country: { token: e.target.value, value: value },
                      state: [],
                      city: []
                    })
                    setCityValue([])
                  }}
                  style={{
                    paddingBottom: '26px',
                  }}
                />
                <MultiSelectDropdown
                  label="District/City"
                  name="city"
                  responseType="id"
                  options={cityData} // Add options
                  value={cityValue}
                  onChange={handleInputChange}
                  style={{
                    paddingBottom: '26px',
                  }}
                />
              </div>
              <div className={style.hobbieContainer}>
                <MultiSelectDropdown
                  label="State"
                  name="state"
                  responseType="id"
                  options={stateData} // Add options
                  value={formData.state}
                  onChange={handleInputChange}
                  style={{
                    paddingBottom: '26px',
                  }}
                />
              </div>
          </div>
        </div>
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

        <div className={style.buttonFooterContainer}>
            <Button
                className={`primaryButton ${style.cancelButton}`}
                onClick={()=> setActiveStep(1)}
            >
              Back
            </Button>
            <Button
              className={`primaryButton ${style.submitButton}`}
              onClick={submitForm}
              loading={isLoading}
            >
              Continue
            </Button>
        </div>
      </div>
    </>
  );
}
