import React, { useState } from "react";
import { Calendar } from "react-multi-date-picker";
import style from './DatePicker.module.css'; // Create a custom CSS file for styling
import calenderIcon from "../assets/calender.svg"
import Image from "../Image/Image"

export default function CustomDatePicker({ isOpen, setIsOpen, label, selectedDate, onChange, error }) {

  const handleCalendarChange = (date) => {
    onChange(timestampToMMDDYYYY(date));
    setIsOpen(false); // Close the calendar when a date is selected
  };

  function timestampToMMDDYYYY(timestamp) {
    const date = new Date(timestamp);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  }

  function is18OrOlder() {
    // Get the current date
    var currentDate = new Date();

    // Calculate the date of birth for an 18-year-old
    var dob = new Date(currentDate);
    dob.setFullYear(currentDate.getFullYear() - 18);

    // Convert the date of birth to a readable format
    var day = dob.getDate();
    var month = dob.getMonth() + 1; // Months are 0-indexed
    var year = dob.getFullYear();

    // Format the date of birth as a string
    return year + '/' + (month < 10 ? '0' : '') + month + '/' + (day < 10 ? '0' : '') + day;
  }

  function convertDateFormat(inputDate) {
    console.log("inputDate", inputDate)
    // Split the input date by hyphens
    const parts = inputDate.split('-');

    // Check if the input date has three parts (day, month, and year)
    if (parts.length === 3) {
      // Rearrange the parts to the desired format
      const newDate = `${parts[2]}/${parts[0]}/${parts[1]}`;
      return newDate;
    } else {
      // Return the original input if the format is not as expected
      return inputDate;
    }
  }

  return (
    <div className={` datePickerContainer ${error ? "dataError" : ""}`}>
      <p className={style.label}>{label}</p>
      <input
        type="text"
        value={selectedDate}
        onClick={() => setIsOpen(!isOpen)}
        readOnly
        placeholder="MM-DD-YYYY"
      />
      <div className={style.customIcon}>
        <Image
          onClick={() => setIsOpen(!isOpen)}
          src={calenderIcon}
        />
      </div>
      {isOpen && (
        <Calendar
          value={ selectedDate ? convertDateFormat(selectedDate) : is18OrOlder() }
          onChange={handleCalendarChange}
          maxDate={is18OrOlder()}
        />
      )}
    </div>
  );
}
