import React, { useEffect, useState } from 'react'
import style from "./NewlyJoined.module.css"
import Carousel from 'react-grid-carousel'
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import carousalRightIcon from "../../../assets/svg/Home/carousalRightIcon.svg"
import carousalleftIcon from "../../../assets/svg/Home/carousalleftIcon.svg"
import useApiCall from '../../../hooks/useApiCall';
import { useNavigate } from 'react-router-dom'
import updgradeToEliteIcon from "../../../assets/common/upgradeToElite.svg"
import premiumIcon from "../../MembershipTypeCard/assets/premium.svg"
import eliteIcon from "../../MembershipTypeCard/assets/elite.svg"
import MaskImage from '../../MaskImage/MaskImage'

export default function NewlyJoined({limitExceeded, details, subscribed}) {

    const navigation = useNavigate("")

    const [data, setData] = useState(null);
    const [ lookingForYouData, setLookingForYouData ] = useState(null)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    
    useEffect(() => {
        setData(details)
    }, [])

  return (
    <div className={style.newlyJoinedContainer}>
        <div className={style.newlyJoinedTitleContainer}>
            <p className={style.titleText}>Newly joined</p>
            {
                !subscribed && (
                <p onClick={()=> {
                    localStorage.removeItem("matchesTab")
                    navigation("/matches", {
                        state: {
                            selectedMatch: {
                                api: "newly-joined",
                                title: "Newly Joined",
                                total_title: "Newly Joined profiles"
                            }
                        }
                    } )}} 
                    className={style.viewAllText}
                >
                    View All
                </p>
            )}   
        </div>
        <div className={`${style.dataContainer} ${ data?.length > 4 ? "" : style.notCarousal }`}>
            {
                data?.length > 4 ?
                    <Carousel
                        cols={4}
                        rows={1}
                        gap={40}
                        loop
                        showDots={false}
                        arrowLeft={<Image src={carousalleftIcon} className={style.arrowLeft} />}
                        arrowRight={<Image src={carousalRightIcon} className={style.arrowRight} />}
                    >
                        {
                            data?.map((item, index) => (
                                <Carousel.Item key={`newlyJoined-${index}`}>
                                    <div className={style.newlyJoined}>
                                        <div className={style.profileImageContainer}  onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}>
                                            <MaskImage
                                                imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                                width={210}
                                                height={200}
                                                side={true}
                                            />
                                            {
                                               item?.membership_type !== "Basic" && (
                                                <Image src={item?.membership_type === "Premium" ? premiumIcon : eliteIcon } className={style.shortlistedStarIcon} />
                                               )
                                            }
                                        </div>
                                        <p className={style.id}>{item?.user_id}</p>
                                        <p className={style.name}>{item.name.slice(0, 10)+`${item.name?.length > 10 ? "..." : ""}`}</p>
                                        <p className={style.details}>{item?.age} yrs, {item?.height}, {item?.mother_tongue}, {item?.occupation}</p>
                                    </div>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                :
                    data?.map((item, index) => (
                        <div className={style.profileContainer}>
                            <div className={style.profileImageContainer}  onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}>
                                <MaskImage
                                    imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                    watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                    width={210}
                                    height={200}
                                    side={true}
                                />
                                {
                                   item?.membership_type !== "Basic" && (
                                    <Image src={item?.membership_type === "Premium" ? premiumIcon : eliteIcon } className={style.shortlistedStarIcon} />
                                   )
                                }
                            </div>
                            <p className={style.id}>{item?.user_id}</p>
                            <p className={style.name}>{item.name.slice(0, 10)+`${item.name?.length > 10 ? "..." : ""}`}</p>
                            <p className={style.details}>{item?.age} yrs, {item?.height}, {item?.mother_tongue}, {item?.occupation}</p>
                            
                        </div>
                    ))
            }
            {
                subscribed && (
                    <div className={style.updgradeToEliteContainer}>
                        <div className={style.updgradeToEliteContentsContainer}>
                            <Image src={updgradeToEliteIcon}/>
                            <p className={style.titleText}>Subscribe to view all the newly joined profiles</p>
                            <Button onClick={()=> navigation("/subscribe")} className={`primaryButton ${style.subscribeButton}`}>Subscribe Now</Button>
                        </div>
                    </div>
                )
            }
        </div>
    </div>
  )
}
