import React, { useState, useEffect } from "react";
import classes from "./ViewDocument.module.css";
import Image from "../Img/Img";
import PdfViewer from "../PDFViewer/PdfViewer";
import Loader from "../../UI/Loader/Loader";
import ReusableModal from "../../UI/Modal/ScrollingModal/ScrollingModal";
const DocumentViewer = ({ title, data }) => {
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const handlePdfView = (documentUrl) => {
    setSelectedDocument(documentUrl);
    setOpenPopup(true);
  };

  useEffect(() => {
    setIsLoading(true); // Start loading
    setTimeout(() => {
      setIsLoading(false); // Finish loading
    }, 2000); // Adjust the delay as needed
  }, [data]); // Re-trigger loading when data changes

  const isPdf = (url) => {
    return url && url.toLowerCase().endsWith(".pdf");
  };

  return (
    <div className={classes.document_viewer}>
      {title && <p className={classes.document_title}>{title}</p>}
      <div className={classes.document_container}>
        <div className={classes.viewdocument_img}>
          {isLoading ? (
            <Loader />
          ) : isPdf(data.imr_certificate) ? (
            <PdfViewer fileUrl={data.imr_certificate} />
          ) : (
            <Image src={data.imr_certificate} alt="document" />
          )}
        </div>
      </div>
      {isLoading ? (
        <p className={classes.viewdocument_text}>Loading...</p>
      ) : isPdf(data.imr_certificate) ? (
        <a
          href="#"
          className={classes.viewdocument_text}
          onClick={() => handlePdfView(data.imr_certificate)}>
          View Document
        </a>
      ) : (
        <a
          href="#"
          className={classes.viewdocument_text}
          onClick={() => handlePdfView(data.imr_certificate)}>
          View Document
        </a>
      )}

      <ReusableModal
        open={openPopup}
        setOpen={setOpenPopup}
        scrollType="body"
        size="sm"
        pdfViewer="pdf">
        {isPdf(selectedDocument) ? (
          <PdfViewer fileUrl={selectedDocument} />
        ) : (
          <Image
            src={selectedDocument}
            alt="document"
            className={classes.img__lightbox}
          />
        )}
      </ReusableModal>
    </div>
  );
};

export default DocumentViewer;
