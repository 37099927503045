import React, { useEffect, useState } from "react";
import style from "./SubscribePageNew.module.css";
import Image from "../../../../ui/Image/Image";
import CheckOff from "../../../../modules/web/assets/common/Close.svg";
import CheckOn from "../../../../modules/web/assets/svg/Premium/CheckOn.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../utils/baseUrl";
import useApiHttp from "../../../admin/hooks/ues-http";
import Loader from "../../UI/Loader/Loader";
import AmountInput from "../../UI/Input/AmountInput";
import editImage from "../../assets/SVG/common/Edit_Profile.svg";
import Modal from "../../UI/Modal/Modal"
import ModalBox from "../../../../ui/Modal/ModalBox";
import TextInput from "../../UI/Input/Input";
import { addScroll, avoidScroll } from "../../../../utils/utils";

const SubscribePageNew = () => {
  const { isLoading: isLoadingmanag, sendRequest: manageUsersRequest } =
    useApiHttp();
  const [tabData, setTabData] = useState(null);
  const [ viewPreviewModal, setViewPreviewModal ] = useState(false)
  const [newPrice, setNewPrice] = useState({
    price: "",
  });
  const [newPlan, setNewPlan] = useState({
    plan_token: "",
    duration: "",
  });
  const [validationError, setValidationError] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [tokenId, setTokenId] = useState("");
  const [ planData, setPlanData ] = useState(null)

  const [editeUser, setEditeUser] = useState({
    price: "",
    offer_price: "",
  });

  const manageUsersRequestData = () => {
    manageUsersRequest(
      {
        url: `${BASE_URL}plan-list`,
        method: "POST",
      },
      (data) => {
        setTabData(data.data);
      }
    );
  };

  useEffect(() => {
    manageUsersRequestData();
  }, []);
  const validateAmount = (value) => {
    if (!value || isNaN(value)) {
      return "Please enter a valid number.";
    }
    return ""; // No validation error
  };

  const handlePrice = (event) => {
    const { name, value } = event.target;
    setNewPrice({ ...newPrice, [name]: value });
    const error = validateAmount(value);
    setValidationError(error);
  };
  const onEdit = (count, name, token, plan_token, duration) => {
    setNewPlan({ ...newPlan, plan_token: plan_token, duration: duration });
    setNewPrice({ ...newPrice, price: count });
    setShowInput(name);
    setTokenId(token);
    setViewPreviewModal(true)
    avoidScroll()
  };
  const Close = () => {
    setShowInput(false);
    setPriceError(false);
  };

  const {
    success: priceEditSucces,
    error: priceEditErroe,
    sendRequest: priceEditRequest,
  } = useApiHttp();
  useEffect(() => {
    if (priceEditSucces !== "") {
      addScroll()
      toast.success(priceEditSucces, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setViewPreviewModal(false)
    }
    if (priceEditErroe !== "") {
      addScroll()
      toast.error(priceEditErroe, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [priceEditSucces, priceEditErroe]);

  const Submit = () => {
    if (!validationError) {
      console.log("Plan", planData);
      const {token, plan_token, duration } = planData
      priceEditRequest(
        {
          url: `${BASE_URL}price-edit`,
          method: "POST",
          body: {
            "token": token,
            "amount": editeUser.price,
            "discount_amount": editeUser.offer_price,
            "plan_token": plan_token,
            "duration": duration
          },
        },
        (data) => {
          setShowInput(false);
          setPriceError(false);
          manageUsersRequestData();
        }
      );
    } else {
      setPriceError(true);
    }
  };

  const addNewNotification = () => {

  }

  const handleChangeEditeFiled = (e) => {
    const { name, value } = e.target;

    console.log("value - name", name, value);
    // Regular expression to match only digits (0-9)
    const regex = /^[0-9]*$/;

    // Check if the value matches the regex (contains only digits)
    if (regex.test(value)) {
      setEditeUser({ ...editeUser, [name]: value });
    }
  };

  console.log("handleChangeEditeFiled", editeUser);

  return (
    <div className={style.subscribeContainer}>
      <ModalBox
          open={viewPreviewModal} 
          width={"453px"} 
          modalContent={
              <div className={style.formContainer}>
                  <p className={style.formTitle}>Edit Price</p>
                  <div className={style.fieldsContainer}>
                    <div className={style.inputContainer}>
                      <span>Rs.</span>
                      <TextInput
                        label="Actual Price"
                        name="price"
                        onChange={handleChangeEditeFiled}
                        value={editeUser.price}
                      />
                    </div>
                    <div className={style.divider}></div>
                    <div className={style.inputContainer}>
                      <span>Rs.</span>
                      <TextInput
                        label="Offer Price"
                        name="offer_price"
                        onChange={handleChangeEditeFiled}
                        value={editeUser.offer_price}
                      />
                    </div>
                  </div>
                  <div className={style.footerContainer}>
                      <div className={style.cancelButton} onClick={()=> {
                        setViewPreviewModal(false)
                        addScroll()
                      }}>Cancel</div>
                      <div className={style.submitButton} onClick={()=> {
                        Submit()
                        addScroll()
                      }}>Submit</div>
                  </div>
              </div>
          }
      />
      <ToastContainer />
      {isLoadingmanag ? (
        <div className={style.loader_box}>
          <Loader />
        </div>
      ) : (
        <table className={style.tableContainer}>
          <tbody>
            <tr className={`${style.tableHeader} ${style.rowContainer_heder}`}>
              <td className={style.titleHeaderTop}>Features</td>
              {tabData?.membership_type?.map((row, rowIndex) =>
                row?.plan_types?.slice(0, 1).map((plan, index) => (
                  <td colspan={row?.plan_types.length}>
                    <p className={style.titleHeader}>{row["name"]}</p>
                  </td>
                ))
              )}
            </tr>
            <div className={style.tabelDivider}></div>
            <tr className={`${style.tableHeader} ${style.rowContainer_heder}`}>
              <td className={style.titleHeaderTop}>Price Duration</td>
              {tabData?.membership_type?.map((row, rowIndex) =>
                row?.plan_types?.map((plan, index) => (
                  <td className={style.releteve_box} key={rowIndex}>
                    {( showInput !== plan?.unique_name && plan?.duration != 0 ) ? (
                      <span>
                        <p className={style.duration}>
                          {plan?.duration} Months
                        </p>
                      </span>
                    ) : (
                      <></>
                    )}
                  </td>
                ))
              )}
            </tr>
            <tr className={`${style.rowContainer} ${style.secondaryHeader}`}>
              <td className={style.featuresHeader}>Price Duration</td>
              {tabData?.membership_type?.map((row, rowIndex) =>
                row?.plan_types?.map((plan, index) => (
                  <td className={style.releteve_box} key={rowIndex}>
                   <div className={style.buttons_edite}>
                        <span>
                          <p className={style.price}>₹{plan?.discount_amount}</p>
                          <p className={style.discount_amount}>
                          ₹{plan?.amount}
                          </p>
                        </span>

                        {plan?.is_editable === 1 && (
                          <Image
                            src={editImage}
                            alt={"editImage"}
                            className={style.on_edit}
                            onClick={() => {
                              setPlanData(plan)
                              setEditeUser({
                                price: String(parseFloat(plan?.amount)),
                                offer_price: String(parseFloat(plan?.discount_amount)),
                              })
                              onEdit(
                                plan?.discount_amount,
                                plan?.amount,
                                plan?.unique_name,
                                plan?.token,
                                plan?.plan_token,
                                plan?.duration
                              )}
                            }
                          />
                        )}
                      </div>
                  </td>
                ))
              )}
            </tr>
            {tabData?.benefit_list?.map((rows, rowIndex) => (
              <tr className={style.rowContainer} key={rowIndex}>
                <td className={style.features}>{rows?.benefit_name}</td>
                {rows?.benefit_data?.map((row, rowIndex) => (
                  <td key={rowIndex}>
                    {row.value === "0" || row.value === "1" ? (
                      <Image
                        src={row.value === "1" ? CheckOn : CheckOff}
                        alt={"Check"}
                        className={style.available}
                      />
                    ) : (
                      <p>{row.value}</p>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
export default SubscribePageNew;
