import React from 'react';
import ReactApexChart from 'react-apexcharts';
import Loader from '../../../UI/Loader/Loader';

const LineChart = ({ datas }) => {

    if (!datas || !datas.lable || !datas.series) {
        return <div style={{ width: '100 %', textAlign: 'center', display: 'flex', justifyContent: 'center', }}><Loader /></div>;
    }

    const options = {
        chart: {
            type: 'line', // Change the chart type to 'line' for a line chart
            toolbar: {
                show: false, // Set this to false to hide the toolbar
            },
            zoom: {
                enabled: false,
            }
        },
        yaxis: {
            labels: {
                formatter: function (value) {
                    // Assuming value is a number and you want to format it
                    const amount = parseInt(value); // Convert value to a number

                    if (amount >= 100000000) {
                        return (amount / 10000000).toFixed(2) + 'C';
                    } else if (amount >= 100000) {
                        return (amount / 100000).toFixed(2) + 'L';
                    } else if (amount >= 1000) {
                        return (amount / 1000).toFixed(2) + 'k';
                    } else {
                        return '0';
                    }
                },
            },
        },
        plotOptions: {
            dataLabels: {
                enabled: false, // Hide the values on data points
            },
            line: {
                width: 6, // Adjust the line thickness (default is 2)
            },
        },
        markers: {
            size: 6
        },
        xaxis: {
            categories: datas.lable, // X-axis categories
        },
        colors: ['#29C48F'],
        toolbar: {
            show: false, // Hide header menu icons
        },
        grid: {
            show: true, // Show grid lines
            strokeDashArray: 2, // Set stroke dash array for dotted lines
        },
    };

    const series = [
        {
            name: 'Revenue',
            data: datas.series, // Data for the line chart in thousands
        },
    ];

    return (
        <ReactApexChart options={options} series={series} type="line" height={350} />
    );
};

export default LineChart;
