import React, { useEffect, useState, useRef, useContext } from 'react';
import style from "./Accepted.module.css";
import Image from '../../../../../ui/Image/Image';
import Button from '../../../../../ui/Button/Button';
import sortlistStarIcon from "../../../assets/svg/Matches/Shortlisted.svg";
import sortlistGrayStarIcon from "../../../assets/svg/Matches/ShortlistGray.svg";
import professionIcon from "../../../assets/common/Degree.svg";
import useApiCall from '../../../hooks/useApiCall';
import useChatSetter from '../../../hooks/useChatSetter';
import config from '../../../../../app.json';
import { Circles } from 'react-loader-spinner';
import { getUserToken } from '../../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import { MailboxContext } from '../Context/MailboxContext';
import chatIcon from "../assets/Chat.svg"
import { NoData } from '../../NoData/NoData';
import MaskImage from '../../MaskImage/MaskImage';

export default function Accepted() {

    const { activeType } = useContext(MailboxContext)
    const navigation = useNavigate("")
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState([]);
    const [lastPage, setLastPage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)
    const [clickedItem, setClickedItem] = useState(null);
    const [chatToken, setChatToken] = useState(null)
    const [refresh, setRefresh] = useState(false)

    const { loading: isChatSetterLoading, success: successChatSetter, error: errorChatSetter, setUserChatEntry: chatRoomSetup } = useChatSetter();
    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    const pageContentContainerRef = useRef(null);

    useEffect(() => {

        if (lastPage) {
            return;
        }
        console.log("activeType", activeType);
        getDetails({
            url: `${config.apiEndpoints.mailbox[activeType === "sent" ? 1 : 0].accepted}?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setLastPage(currentPage >= responseData.data.last_page);
            setTotal(responseData.data.total)
            setData((prevData) => {
                if (!prevData) {
                    return prevData;
                }
                return [...prevData, ...responseData.data.data]
            });
            setLoadingMore(false);
        });
    }, [currentPage, lastPage, refresh]);

    const handleScroll = () => {
        const container = pageContentContainerRef.current;
        if (!container || loadingMore || lastPage) return; // Do nothing if loading, or last page reached.

        // Check if the user has scrolled to the bottom of the container
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            // Load more data when the user reaches the bottom
            setLoadingMore(true);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const container = pageContentContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    useEffect(() => {
        if (successChatSetter) {
            navigation("/chat", { state: { token: chatToken } })
        }
        if (errorChatSetter) {
            console.log("errorChatSetter", errorChatSetter);
        }
    }, [successChatSetter, errorChatSetter]);

    const handleChat = (user) => {
        chatRoomSetup(
            user.token,
            user.name,
            user.user_id,
            user.image
            , (data) => {
                setChatToken(data)
            })
    }

    return (
        <>
            {error ? (
                <div className={style.loaderContainer}>
                    <p className={style.error}>{error}</p>
                </div>
            ) : !isLoading ? (
                <div className={style.pageContentContainer} ref={pageContentContainerRef}>
                    <p className={style.title}>Accepted</p>
                    <div className={style.subHeadingContainer}>
                        <p className={style.heading}>{total} Accepted Profiles</p>
                    </div>
                    <div className={style.pageContent}>
                        {
                            data?.length > 0 ?
                                data?.map((item, index) => (
                                    <div key={item.user_id} className={style.content}>
                                        <div className={style.imageAndDetailsContainer}>
                                            <div className={style.detailsContainer} onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>
                                                <MaskImage
                                                    imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                    watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                                    width={182}
                                                    height={242}
                                                    side={false}
                                                />
                                            </div>
                                            <div className={style.detailsContainer}>
                                                <div className={style.headerContainer}>
                                                    <div className={style.nameContainer}>
                                                        <p className={style.id} onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>{item.user_id}</p>
                                                    </div>
                                                </div>
                                                <h1 className={style.name} onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>{item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}<span>({item.age} yrs)</span></h1>
                                                {item?.is_onboard_completed === 1 ? <>
                                                    <div className={style.personalDetails}>
                                                        <p className={style.personaTtext}>{item.height}</p>
                                                        <p className={style.personaTtext}>{item.mother_tongue}</p>
                                                        <p className={style.personaTtext}>{item.community}</p>
                                                        <p className={style.personaTtext}>{item.cityname}</p>
                                                    </div>
                                                    <div className={style.personalDetails}>
                                                        <Image
                                                            src={professionIcon}
                                                        />
                                                        <p className={style.personaTtext}>{item.education}</p>
                                                        <p className={style.personaTtext}>{item.annual_income}</p>
                                                    </div>
                                                </> : <div className='non-onboarded-user'> </div>}
                                                {
                                                    activeType === "sent" ?
                                                        <p className={style.aboutDescription}>Dr. {item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`} has accepted your interest request. Now you can chat with Dr. {item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}.</p>
                                                        :
                                                        <p className={style.aboutDescription}>You have accepted the interest request, now you can chat with Dr. {item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}.</p>
                                                }
                                                <div className={style.chatImagesContainer}>
                                                    <Image onClick={() => {
                                                        setClickedItem(item.token)
                                                        handleChat(item)
                                                    }} src={chatIcon} className={style.chatImage} />
                                                    {isChatSetterLoading && clickedItem === item.token && (
                                                        <div>
                                                            <Circles
                                                                height="20"
                                                                width="20"
                                                                color="rgb(239, 116, 53)"
                                                                ariaLabel="circles-loading"
                                                                wrapperStyle={{}}
                                                                visible={true}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                :
                                !isLoading && (
                                    <NoData isChat={false} title={"No accepted interests"} />
                                )
                        }
                    </div>
                    {loadingMore && !lastPage && (
                        <div className={style.loaderContainer}>
                            <Circles
                                height="35"
                                width="35"
                                color="rgb(239, 116, 53)"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass={style.loader}
                                visible={true}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={style.loaderContainer}>
                    <Circles
                        height="35"
                        width="35"
                        color="rgb(239, 116, 53)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass={style.loader}
                        visible={true}
                    />
                </div>
            )}
        </>
    );
}
