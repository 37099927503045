import React, { useState, useEffect } from 'react'
import Tabs from "../../UI/Tabs/Tabs";
import PagesHeader from '../../features/PagesHeader/PagesHeader';
import DropdownButton from '../../UI/DropdownButton/DropdownButton';
import Table from "../../UI/Table/TablePage";
import TableHeader from "../../UI/Table/TableHeader";
import Search from '../../UI/Search/Search';
import useApiHttp from '../../hooks/ues-http';
import { BASE_URL } from '../../utils/baseUrl';
import Modal from '../../UI/Modal/Modal';
import classes from './UserSafety.module.css'
import CheckBox from '../../UI/Checkbox/CheckBox';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SelectBox from '../../UI/SelectFiled/SelectBox';
import Box from '@mui/material/Box';
import { RadioButton } from '../../UI/RadioButton/RadioButton'
import { AnimatePresence , motion} from 'framer-motion';

const UserSafety = () => {
    const [refresh, setRefresh] = useState(true);
    const [activeTab, setActiveTab] = useState(0);
    const [downloadCsvs, setDownloadCsvs] = useState('');

    const [flagUsers, setFlagUsers] = useState([]);
    const [flagUserSearch, setFlagUserSearch] = useState('');
    const [reportView, setReportView] = useState([]);
    const [totalRecords, setTotalRecords] = useState('');
    const [totalDisplayRecords, setTotalDisplayRecords] = useState('');
    const [sortDirectionData, setSortDirectionData] = useState('DESC');
    const [column, setColumn] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [reportDetailModal, setReportDetailModal] = useState(false);


    const [feedbacks, setFeedbacks] = useState([]);
    const [feedbackSearch, setFeedbackSearch] = useState('');
    const [feedbackView, setFeedbackView] = useState([]);
    const [totalFeedbackRecords, setTotalFeedbackRecords] = useState('');
    const [totalDisplayFeedbackRecords, setTotalDisplayFeedbackRecords] = useState('');
    const [feedbackSortDirectionData, setFeedbackSortDirectionData] = useState('DESC');
    const [feedbackColumn, setFeedbackColumn] = useState(0);
    const [feedbackPerPage, setfeedbackPerPage] = useState(10);
    const [feedbackPage, setFeedbackPage] = useState(1);
    const [feedbackDetailModal, setFeedbackDetailModal] = useState(false);


    const [catagorysSearch, setCatagorySearch] = useState('');
    const [catagorysView, setCatagoryView] = useState([]);
    const [catagorys, setCatagorys] = useState([]);
    const [totalCatagoryRecords, setTotalCatagoryRecords] = useState('');
    const [totalDisplayCatagoryRecords, setTotalDisplayCatagoryRecords] = useState('');
    const [catagorySortDirectionData, setCatagorySortDirectionData] = useState('DESC');
    const [catagoryColumn, setCatagoryColumn] = useState(0);
    const [catagoryPerPage, setCatagoryPerPage] = useState(10);
    const [catagoryPage, setCatagoryPage] = useState(1);
    const [catagoryDetailModal, setCatagoryDetailModal] = useState(false);

    const [userIdModal, setUserIdModal] = useState(false);
    const [reportsModal, setReportsModal] = useState(false);
    const [reportsSate, setReportsSate] = useState([]);
    const [userIdSate, setUserIdSate] = useState([]);
    const [flagDrop, setFlagDrop] = useState('');
    const [contactView, setContactView] = useState([]);
    const [contactDetailModal, setContactDetailModal] = useState(false);
    const [storiesUser, setStoriesUser] = useState("");
    const [selectedValues, setSelectedValues] = useState([]);
    const [errorMsg, setErrorMsg] = useState(false);

    const { isLoading: safetyLoding, success: safetySucces, error: safetyError, sendRequest: safetyRequest } = useApiHttp();
    const { isLoading: feedbackLoading, success: feedbackSuccess, error: feedbackError, sendRequest: feedbackRequest } = useApiHttp();
    const { isLoading: catagoryLoading, success: catagorySuccess, error: catagoryError, sendRequest: catagoryRequest } = useApiHttp();
    const { isLoading: ViewReportLoading, success: ViewReportSuccess, error: ViewReportError, sendRequest: viewReportDetailRequest } = useApiHttp();
    const { isLoading: ViewFeedbackLoading, success: ViewFeedbackSuccess, error: ViewFeedbackError, sendRequest: viewFeedbackDetailRequest } = useApiHttp();
    const { isLoading: ViewContactLoading, success: ViewContactSuccess, error: ViewContactError, sendRequest: viewContactDetailRequest } = useApiHttp();


    const safetyDatas = (data) => {
        setFlagUsers(data.aaData)
        setTotalRecords(data.iTotalRecords)
        setTotalDisplayRecords(data.iTotalDisplayRecords)
    };

    const safetyRequestData = () => {
        const requestData = {
            search_value: flagUserSearch,
            page: page,
            per_page: perPage,
            sort_column: column,
            sort_dir: sortDirectionData,
            filter: flagDrop
        };

        safetyRequest({
            url: `${BASE_URL}flag-user-list`,
            method: 'POST',
            body: requestData,
        }, safetyDatas);
    };
    useEffect(() => {
        safetyRequestData()
    }, [flagUserSearch, page, perPage, column, sortDirectionData, flagDrop]);


    const columns = [
        {
            name: 'S.No',
            selector: (row) => row.slno,
            sortable: false,
            sortField: 0,
        },
        {
            name: 'Report ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => viewReportDetail(row.report_id)}>{row.report_id}</span></>),
            sortable: true,
            sortField: 1,
        },
        {
            name: 'User ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => viewUserIdModal(row.user_token)}>{row.user_id}</span></>),
            sortable: true,
            sortField: 2,
        },
        {
            name: 'User Name',
            selector: (row) => row.user_name,
            sortable: true,
            sortField: 3,
        },
        {
            name: 'Contact Number',
            selector: (row) => row.mobile_number,
            sortable: true,
            sortField: 4,
        },
        {
            name: 'Reported ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => viewReportedIdModal(row.reported_token)}>{row.reported_id}</span></>),
            sortable: true,
            sortField: 5,
        },
        {
            name: 'Reported User Name',
            selector: (row) => row.reported_name,
            sortable: true,
            sortField: 6,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            sortField: 7,
        },
        {
            name: 'Status',
            cell: (row) => (<> {row.status === 'Pending' ? (<span className="wigite_lable premium">{row.status}</span>) : (<span className="wigite_lable green">{row.status}</span>)}</>),
            sortable: true,
            sortField: 8,
        },

    ];

    const ViewReportDatas = (data) => {
        setReportView(data.data)
    };

    const viewReportDetailData = (token) => {
        const requestData = {
            token: token,
        };

        viewReportDetailRequest({
            url: `${BASE_URL}flag-user-view`,
            method: 'POST',
            body: requestData,
        }, ViewReportDatas);
    };

    const viewReportDetail = (data) => {
        viewReportDetailData(data);
        setReportDetailModal(true);
    }

    // Export CSV
    const { sendRequest: downlodFlagUserRequest } = useApiHttp();
    const downlodFlagUsertData = (data) => {
        setDownloadCsvs(data);
    };
    const downlodFlagUserData = () => {
        downlodFlagUserRequest({
            url: `${BASE_URL}csv-safety-center`,
            method: 'POST',
            body: {
                "search_value": flagUserSearch,
                "filter": flagDrop
            }
        }, downlodFlagUsertData);
    };

    const { sendRequest: downlodFeedbacksRequest } = useApiHttp();
    const downlodFeedbacksData = (data) => {
        setDownloadCsvs(data);
    };
    const downlodFeedbacksRequestData = () => {
        downlodFeedbacksRequest({
            url: `${BASE_URL}csv-feedback`,
            method: 'POST',
            body: {
                "search_value": feedbackSearch,
            }
        }, downlodFeedbacksData);
    };

    const { sendRequest: contactCsvRequest } = useApiHttp();
    const contactCsvData = (data) => {
        setDownloadCsvs(data);
    };
    const downlodcontactCsvData = () => {
        contactCsvRequest({
            url: `${BASE_URL}csv-contact-us`,
            method: 'POST',
            body: {
                "search_value": catagorysSearch,
            }
        }, contactCsvData);
    };


    const handleExportCSV = () => {
        if (activeTab === 0) {
            if (downloadCsvs) {
                const link = document.createElement('a');
                link.href = downloadCsvs.download_link;
                link.download = 'downloaded.csv';
                link.click();
            }
        } else if (activeTab === 1) {
            if (downloadCsvs) {
                const link = document.createElement('a');
                link.href = downloadCsvs.download_link;
                link.download = 'downloaded.csv';
                link.click();
            }
        } else if (activeTab === 2) {
            if (downloadCsvs) {
                const link = document.createElement('a');
                link.href = downloadCsvs.download_link;
                link.download = 'downloaded.csv';
                link.click();
            }
        }

    };

    const handleMenuItemClick = (item) => {
        // Handle the menu item click event here
        if (item.label === 'CSV') {
            handleExportCSV()
        }
    };

    const handleFlagUserSearchChange = (newValue) => {
        setFlagUserSearch(newValue)
    };

    const handleSort = async (column, sortDirection) => {
        setSortDirectionData(sortDirection || 'DESC');
        setColumn(column.sortField || 0);

    };
    const handlePageChange = (page) => {
        setPage(page)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setPerPage(newPerPage);
    };

    const feedbackDatas = (data) => {
        setFeedbacks(data.aaData)
        setTotalFeedbackRecords(data.iTotalRecords)
        setTotalDisplayFeedbackRecords(data.iTotalDisplayRecords)
    };

    const feedbackData = () => {
        const requestData = {
            search_value: feedbackSearch,
            page: feedbackPage,
            per_page: feedbackPerPage,
            sort_column: feedbackColumn,
            sort_dir: feedbackSortDirectionData,
        };

        feedbackRequest({
            url: `${BASE_URL}user-feedback-list`,
            method: 'POST',
            body: requestData,
        }, feedbackDatas);
    };
    useEffect(() => {
        feedbackData()
    }, [feedbackSearch, feedbackPage, feedbackPerPage, feedbackColumn, feedbackSortDirectionData]);


    const handleFeedbackSort = async (column, sortDirection) => {
        setFeedbackSortDirectionData(sortDirection || 'DESC');
        setFeedbackColumn(column.sortField || 0);

    };
    const handleFeedbackPageChange = (page) => {
        setFeedbackPage(page)
    };

    const handleFeedbackPerRowsChange = async (newPerPage, page) => {
        setfeedbackPerPage(newPerPage);
    };



    const ViewFeedbackDatas = (data) => {
        setFeedbackView(data.data)
    };

    const viewFeedbackDetailData = (token) => {
        const requestData = {
            token: token,
        };

        viewFeedbackDetailRequest({
            url: `${BASE_URL}user-feedback-view`,
            method: 'POST',
            body: requestData,
        }, ViewFeedbackDatas);
    };

    const viewFeedbackDetail = (data) => {
        viewFeedbackDetailData(data);
        setFeedbackDetailModal(true);
    }

    const feedbackColumns = [
        {
            name: 'S.No',
            selector: (row) => row.slno,
            sortable: false,
            sortField: 0,
        },
        {
            name: 'Feedback ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => viewFeedbackDetail(row.feedback_id)}>{row.feedback_id}</span></>),
            sortable: true,
            sortField: 1,
        },
        {
            name: 'User ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={row.user_id === "Anonymous" ? () => { } : () => viewUserIdModal(row.user_id)}>{row.user_id}</span></>),
            // selector: (row) => row.user_id,
            sortable: true,
            sortField: 2,
        },
        {
            name: 'User Name',
            selector: (row) => row.user_name,
            sortable: true,
            sortField: 3,
        },
        {
            name: 'Contact Number',
            selector: (row) => row.contact_number,
            sortable: true,
            sortField: 4,
        },
        {
            name: 'Comment',
            selector: (row) => (<><p className='text___warp'>{row.feedback}</p></>),
            sortable: true,
            sortField: 5,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            sortField: 6,
        },
    ];

    const handleFeedbackSearchChange = (newValue) => {
        setFeedbackSearch(newValue)
    };

    const catagoryDatas = (data) => {
        setCatagorys(data.aaData)
        setTotalCatagoryRecords(data.iTotalRecords)
        // setTotalDisplayCatagoryRecords(data.iTotalDisplayRecords)
    };

    const catagoryData = () => {
        const requestData = {
            search_value: catagorysSearch,
            page: catagoryPage,
            per_page: catagoryPerPage,
            sort_column: catagoryColumn,
            sort_dir: catagorySortDirectionData,
        };

        catagoryRequest({
            url: `${BASE_URL}contact-us-list`,
            method: 'POST',
            body: requestData,
        }, catagoryDatas);
    };
    useEffect(() => {
        catagoryData()
    }, [catagorysSearch, catagoryPage, catagoryPerPage, catagoryColumn, catagorySortDirectionData]);

    const catagoryColumns = [
        {
            name: 'S.No',
            selector: (row) => row.slno,
            sortable: false,
            sortField: 0,
        },
        {
            name: 'Contact ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={row.c_token === null ? () => { } : () => viewCatagoryDetail(row.c_token)}>{row.c_token}</span></>),
            sortable: true,
            sortField: 1,
        },
        {
            name: 'User ID',
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={row.user_token === null ? () => { } : () => viewUserIdModal(row.user_token)}>{row.user_id}</span></>),
            sortable: true,
            sortField: 2,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            sortable: true,
            sortField: 3,
        },
        {
            name: 'Message',
            selector: (row) => (<><p className='text___warp'>{row.message}</p></>),
            sortable: true,
            sortField: 4,
        },
        {
            name: 'Date',
            selector: (row) => row.date,
            sortable: true,
            sortField: 5,
        },
        {
            name: 'Status',
            cell: (row) => (<> {row.status === 'Pending' && (<span className="wigite_lable premium">{row.status}</span>)} {row.status === 'Contacted' && (<span className="wigite_lable elite">{row.status}</span>)} {row.status === 'Resolved' && (<span className="wigite_lable green">{row.status}</span>)}</>),
            sortable: true,
            sortField: 6,
        },
    ];
    const handleCatagorySearchChange = (newValue) => {
        setCatagorySearch(newValue)
    };

    const handlecatagorySort = async (column, sortDirection) => {
        setCatagorySortDirectionData(sortDirection || 'DESC');
        setCatagoryColumn(column.sortField || 0);
    };
    const handlecatagoryPageChange = (page) => {
        setCatagoryPage(page)
    };

    const handlecatagoryPerRowsChange = async (newPerPage, page) => {
        setCatagoryPerPage(newPerPage);
    };

    const ViewContactDatas = (data) => {
        setContactView(data.data)
        setStoriesUser(data.data.status)
    };

    const viewContactDetailData = (token) => {
        const requestData = {
            token: token,
        };

        viewContactDetailRequest({
            url: `${BASE_URL}contact-us-view`,
            method: 'POST',
            body: requestData,
        }, ViewContactDatas);
    };

    const viewCatagoryDetail = (token) => {
        viewContactDetailData(token)
        setContactDetailModal(true)
    }

    const radioChangeHandlerStories = (e) => {
        setStoriesUser(e.target.value);
    };

    const { isLoading: contactLoding, success: contactSucces, error: contactError, sendRequest: contactRequest } = useApiHttp();
    useEffect(() => {
        if (contactSucces !== '') {
            toast.success(contactSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }

    }, [contactSucces]);

    const contactData = (token) => {

        const requestData = {
            token: token,
            status: storiesUser
        };

        contactRequest({
            url: `${BASE_URL}contact-us-update`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setContactDetailModal(false);
            catagoryData()
        });
    };

    const handleContactSubimt = (data) => {
        contactData(data)
    }


    const { isLoading: reportLoding, success: reportSucces, error: reportError, sendRequest: reportRequest } = useApiHttp();

    useEffect(() => {
        if (reportSucces !== '') {
            toast.success(reportSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
            setReportDetailModal(false);
        }

    }, [reportSucces]);

    const reportData = (token) => {
        let reportValue;

        if (selectedValues[0] === '1') {
            reportValue = '1'
        } else if (selectedValues[0] === undefined) {
            reportValue = '0'
        }
        const requestData = {
            token: token,
            status: reportValue
        };

        reportRequest({
            url: `${BASE_URL}flag-user-update`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setContactDetailModal(false);
            safetyRequestData()
            setSelectedValues([])
            setErrorMsg(false)
        });
    };

    const handleReportId = (data) => {
        if (selectedValues.length === 0) {
            setErrorMsg(true)
        } else {
            reportData(data)
        }

    }

    const handleCheckbox = (label) => {
        // Check if the label is in the selectedValues array
        if (selectedValues.includes(label)) {
            setSelectedValues(selectedValues.filter((value) => value !== label));
        } else {
            setSelectedValues([...selectedValues, label]);
        }
    };

    const { sendRequest: UserIdRequest } = useApiHttp();
    const UserIdData = (data) => {
        setUserIdSate(data.data)
    };
    const UserIdRequestData = (token) => {

        if (token === null) {
            setUserIdModal(false)
        } else {
            setUserIdModal(true)
        }
        UserIdRequest({
            url: `${BASE_URL}basic-user-detail`,
            method: 'POST',
            body: {
                "token": token
            }
        }, UserIdData);
    };

    const { sendRequest: ReportsRequest } = useApiHttp();
    const ReportsData = (data) => {
        setReportsSate(data.data)
    };
    const ReportsRequestData = (token) => {
        ReportsRequest({
            url: `${BASE_URL}basic-user-detail`,
            method: 'POST',
            body: {
                "token": token
            }
        }, ReportsData);
    };

    const viewUserIdModal = (data) => {
        UserIdRequestData(data)
    }

    const viewReportedIdModal = (data) => {
        setReportsModal(true)
        ReportsRequestData(data)
    }
    const handleChange = (event) => {
        setFlagDrop(event.target.value);
    };

    useEffect(() => {
        downlodFlagUserData()
    }, [flagUserSearch, flagDrop])

    useEffect(() => {
        downlodFeedbacksRequestData()
    }, [feedbackSearch])

    useEffect(() => {
        downlodcontactCsvData()
    }, [catagorysSearch])

    const toggleRefresh = () => {
        setRefresh(false);
        setTimeout(() => {
            setRefresh(true);
        }, 200); // Refreshing after 1 second
    };

    const refreshTable = () => {
        setSortDirectionData("DESC")
        setColumn(0)
        setPerPage(10)
        setPage(1)

        setFeedbackSortDirectionData("DESC")
        setFeedbackColumn(0)
        setfeedbackPerPage(10)
        setFeedbackPage(1)

        setCatagorySortDirectionData("DESC")
        setCatagoryColumn(0)
        setCatagoryPerPage(10)
        setCatagoryPage(1)
    }
    const onTabClick = (index) => {
        setActiveTab(index); // Update the active tab index
        toggleRefresh()

        refreshTable()
        if (index === 0) {
            downlodFlagUserData()
            setFlagUserSearch("")
            setFlagDrop("")
        } else if (index === 1) {
            downlodFeedbacksRequestData()
            setFeedbackSearch("")
            setFlagDrop("")
        } else if (index === 2) {
            downlodcontactCsvData()
            setFeedbackSearch("")
            setFlagDrop("")
        }
    };

    const staus = [
        { token: 'completed', designation: 'completed' },
        { token: 'pending', designation: 'pending' }
    ]
    const onCloseFunctiongift = () => {
        setSelectedValues([])
        setErrorMsg(false)
    };
    const tabAnimation = {
        initial: {
          y: -200,
          opacity: 0,
        },
        animate: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            staggerChildren: 0.1,
          },
        },
        exit: {
            y: -200,
            opacity: 0,
        }
      };

    return (
        <AnimatePresence>
            <PagesHeader
                title="User Safety Center"
                children={(
                    <DropdownButton
                        buttonLabel="Download as"
                        menuItems={[
                            { label: 'CSV', render: () => (<span onClick={handleExportCSV}>Export CSV</span>) },
                        ]}
                        onMenuItemClick={handleMenuItemClick}
                    />
                )}
            />

            <section className='tablesection_heder'>
                <Tabs
                    tabs={[
                        {
                            title: "Flag Users",
                            render: () => (
                                <>
                                    <Box sx={{ width: 200, marginBottom: '1rem' }}>
                                        <SelectBox
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={flagDrop}
                                            label="Status"
                                            onChange={handleChange}
                                            labelname="Status"
                                            nonename="Status"
                                            data={staus}
                                        />
                                    </Box>
                                    {refresh &&  
                                    <motion.dev 
                                        variants={tabAnimation}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit">
                                      <Table data={flagUsers} onSort={handleSort} columns={columns} loader={safetyLoding} paginationTotalRows={totalRecords} onChangeRowsPerPage={handlePerRowsChange} onChangePage={handlePageChange}
                                        children={
                                            <div className="header_table">
                                                <TableHeader tableheader="Flag Users" totalusers={`${totalRecords} total reports`} />
                                                <Search value={flagUserSearch} onChange={(newValue) => handleFlagUserSearchChange(newValue)} type='text' placeholder='Search' />
                                            </div>
                                        } />
                                    </motion.dev>}
                                </>
                            ),
                        },
                        {
                            title: "User Feedbacks",
                            render: () => (
                                <>
                                    {refresh && 
                                    <motion.dev 
                                        variants={tabAnimation}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit">
                                       <Table data={feedbacks} onSort={handleFeedbackSort} columns={feedbackColumns} loader={feedbackLoading} paginationTotalRows={totalFeedbackRecords} onChangeRowsPerPage={handleFeedbackPerRowsChange} onChangePage={handleFeedbackPageChange}
                                        children={
                                            <div className="header_table">
                                                <TableHeader tableheader="User Feedbacks" totalusers={`${totalFeedbackRecords} total feedbacks`} />
                                                <Search value={feedbackSearch} onChange={(newValue) => handleFeedbackSearchChange(newValue)} type='text' placeholder='Search' />
                                            </div>
                                        } />
                                    </motion.dev>}
                                </>
                            ),
                        },
                        {
                            title: "Contact Requests",
                            render: () => (
                                <>
                                    {refresh && 
                                    <motion.dev 
                                        variants={tabAnimation}
                                        initial="initial"
                                        animate="animate"
                                        exit="exit">
                                    <Table data={catagorys} onSort={handlecatagorySort} columns={catagoryColumns} loader={catagoryLoading} paginationTotalRows={totalCatagoryRecords} onChangeRowsPerPage={handlecatagoryPerRowsChange} onChangePage={handlecatagoryPageChange}
                                        children={
                                            <div className="header_table">
                                                <TableHeader tableheader="Contact Requests" totalusers={`${totalCatagoryRecords} total contact requests`} />
                                                <Search value={catagorysSearch} onChange={(newValue) => handleCatagorySearchChange(newValue)} type='text' placeholder='Search' />
                                            </div>
                                        } />
                                    </motion.dev>}
                                </>
                            ),
                        },

                    ]}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                />
            </section>

            <>
                <Modal openPopup={reportDetailModal} setOpenPopup={setReportDetailModal} titel={`Report ID - ${reportView.report_id}`} size="lg" type={reportView.status === "Completed" ? "view" : ""} okay={reportView.status === "Completed" ? "ok" : ""} handleSubmit={() => handleReportId(reportView.report_id)} onCloseFunction={onCloseFunctiongift}>
                    <>
                        <div className={classes.report_box} style={{ borderBottom: '2px dotted #8e9196' }}>

                            <p className={classes.module_text}>Report By </p>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>User ID</p>
                                    <p className={classes.contant_box_sub}>{reportView.user_id}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>User Name</p>
                                    <p className={classes.contant_box_sub}>{reportView.user_name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Mobile Number</p>
                                    <p className={classes.contant_box_sub}>{reportView.mobile_number}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.report_box} style={{ borderBottom: '2px dotted #8e9196' }}>
                            <p className={classes.module_text}>Report To </p>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>User ID</p>
                                    <p className={classes.contant_box_sub}>{reportView.reported_id}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>User Name</p>
                                    <p className={classes.contant_box_sub}>{reportView.reported_name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Mobile Number</p>
                                    <p className={classes.contant_box_sub}>{reportView.report_mobile_number}</p>
                                </div>
                            </div>

                            <div className={classes.contaniner__box_set}>
                                <p className={classes.contant_box_titel}>Reason</p>
                                <p className={classes.contant_box_sub}>{reportView.reason}</p>
                            </div>

                            <div className={classes.contaniner__box_set}>
                                <p className={classes.contant_box_titel}>Comment</p>
                                <p className={classes.contant_box_sub}>{reportView.comment}</p>
                            </div>
                        </div>
                        {reportView.status === "Completed" ? " " : <div className={classes.checke_box}> <p className={classes.module_text} style={{ marginTop: '1rem' }}>Report Action</p>
                            <div style={{ marginTop: '1rem' }}>
                                <CheckBox label="Report Resolved." checked={selectedValues.includes('1')} onChange={() => handleCheckbox('1')} />
                                {errorMsg && <p className={`text_danger`}>Please Select Report Resolved</p>}
                            </div>

                        </div>}

                    </>
                </Modal>
                <Modal openPopup={feedbackDetailModal} setOpenPopup={setFeedbackDetailModal} titel={`Feedback ID - ${feedbackView.feedback_token}`} size="lg" type="view" okay="ok">
                    <>
                        <div className={classes.feedback_container}>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>User ID</p>
                                    <p className={classes.contant_box_sub}>{feedbackView.user_id}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>User Name</p>
                                    <p className={classes.contant_box_sub}>{feedbackView.name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Contact Number</p>
                                    <p className={classes.contant_box_sub}>{feedbackView.mobile_number}</p>
                                </div>
                            </div>

                            <div className={classes.contaniner__box_set}>
                                <p className={classes.contant_box_titel}>Feedback</p>
                                <p className={classes.contant_box_sub}>{feedbackView.category}</p>
                            </div>

                            <div className={classes.contaniner__box_set}>
                                <p className={classes.contant_box_titel}>Comment</p>
                                <p className={classes.contant_box_sub}>{feedbackView.feedback}</p>
                            </div>
                        </div>
                    </>
                </Modal>
                <Modal openPopup={userIdModal} setOpenPopup={setUserIdModal} titel="User ID" size="lg" type="view" okay="ok">

                    <>
                        <div className={classes.feedback_container}>

                            <div className={classes.profile_image}>
                                <img src={userIdSate.image} alt="userIdSate" />
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>User ID</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.user_id}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>User Name</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.user_name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>NMC/SMC Number</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.imr_number}</p>
                                </div>
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>Gender</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.gender}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Mobile</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.mobile_number}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Email</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.email}</p>
                                </div>
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>Age</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.age}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Education</p>
                                    <p className={classes.contant_box_sub}>{userIdSate && userIdSate.education ? userIdSate.education.replace(/,/g, ', ') : ''}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Membership Type</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.membership_type}</p>
                                </div>
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>Institution Working</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.institution_working}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>City</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.city}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>State</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.state}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Country</p>
                                    <p className={classes.contant_box_sub}>{userIdSate.country}</p>
                                </div>
                            </div>

                            <div>
                                <p className={classes.contant_box_titel}>About</p>
                                <p className={classes.contant_box_sub}>{userIdSate.abount}</p>
                            </div>


                        </div>

                    </>
                </Modal>
                <Modal openPopup={reportsModal} setOpenPopup={setReportsModal} titel="Reports ID" size="lg" type="view" okay="ok">
                    <>
                        <div className={classes.feedback_container}>

                            <div className={classes.profile_image}>
                                <img src={reportsSate.image} alt="reportsSate" />
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>User ID</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.user_id}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>User Name</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.user_name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>NMC/SMC Number</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.imr_number}</p>
                                </div>
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>Gender</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.gender}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Mobile</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.mobile_number}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Email</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.email}</p>
                                </div>
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>Age</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.age}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Education</p>
                                    <p className={classes.contant_box_sub}>{reportsSate && reportsSate.education ? reportsSate.education.replace(/,/g, ', ') : ''}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Membership Type</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.membership_type}</p>
                                </div>
                            </div>
                            <div className={classes.module__text_Container}>
                                <div>
                                    <p className={classes.contant_box_titel}>Institution Working</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.institution_working}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>City</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.city}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>State</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.state}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Country</p>
                                    <p className={classes.contant_box_sub}>{reportsSate.country}</p>
                                </div>
                            </div>

                            <div>
                                <p className={classes.contant_box_titel}>About</p>
                                <p className={classes.contant_box_sub}>{reportsSate.abount}</p>
                            </div>

                        </div>
                    </>
                </Modal>
                <Modal openPopup={contactDetailModal} setOpenPopup={setContactDetailModal} titel={`Contact - ${contactView.c_token}`} size="lg" handleSubmit={() => handleContactSubimt(contactView.c_token)}>
                    <>
                        <div className={classes.report_box} style={{ borderBottom: '2px dotted #8e9196' }}>

                            <p className={classes.module_text}>Contact</p>
                            <div className={classes.module__text_Container}>

                                <div>
                                    <p className={classes.contant_box_titel}>User Name</p>
                                    <p className={classes.contant_box_sub}>{contactView.user_name}</p>
                                </div>
                                <div>
                                    <p className={classes.contant_box_titel}>Email</p>
                                    <p className={classes.contant_box_sub}>{contactView.email}</p>
                                </div>
                            </div>
                        </div>
                        <div className={classes.report_box} style={{ borderBottom: '2px dotted #8e9196' }}>

                            <div className={classes.contaniner__box_set}>
                                <p className={classes.contant_box_titel}>Message</p>
                                <p className={classes.contant_box_sub}>{contactView.message}</p>
                            </div>
                        </div>

                        {contactView.status === "Resolved" ? <span style={{ marginTop: ' 1rem' }} className="wigite_lable green">{contactView.status}</span> : <>
                            <p className={classes.module_text} style={{ marginTop: '1rem' }}>Action</p>

                            <div className={classes.radio_btn_set}>

                                <RadioButton
                                    changed={radioChangeHandlerStories}
                                    id="Contacted"
                                    isSelected={storiesUser === "Contacted"}
                                    label="Contacted"
                                    value="Contacted"
                                />

                                <RadioButton
                                    changed={radioChangeHandlerStories}
                                    id="Resolved"
                                    isSelected={storiesUser === "Resolved"}
                                    label="Resolved"
                                    value="Resolved"
                                />
                            </div>
                        </>}

                    </>
                </Modal>
                <ToastContainer />
            </>
        </AnimatePresence>
    )
}

export default UserSafety