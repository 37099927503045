import React, { useEffect, useState } from 'react'
import style from "./BuildProfile.module.css"
import Image from '../../../../ui/Image/Image'
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import WorkDetails from '../../features/BuildProfile/WorkDetails/WorkDetails'
import FamilyDetails from '../../features/BuildProfile/FamilyDetails/FamilyDetails'
import MedicalConditions from '../../features/BuildProfile/MedicalConditions/MedicalConditions'
import ReligiousDetails from '../../features/BuildProfile/ReligiousDetails/ReligiousDetails'
import PersonalDetails from '../../features/BuildProfile/PersonalDetails/PersonalDetails'
import { BuildProfileContext } from '../../features/BuildProfile/Context/BuildProfileContext'
import { useNavigate } from 'react-router-dom'
import useAuthentication from '../../hooks/useAuthentication'
import { getUserData, getUserToken } from '../../../../utils/utils'
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';

export default function BuildProfile() {

  const navigation = useNavigate();

  const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

  const [activeStep, setActiveStep] = useState(1)

  useEffect(() => {
    if (getUserToken() === null) {
      navigation("/")
    } else {
      getDetails({
        url: "auth/check-user",
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      }, (data) => {
        let { status, onboard_status } = data.data
        if( status === "Onboarding" ) {
         setActiveStep( onboard_status === 0 ? onboard_status + 1 : onboard_status )
        } else if( status === "Preferencing" ) {
            // navigation("/partner_preference")
        }
      });
    }
  }, []);

  // Create a function to render the component based on activeStep
  const renderComponentBasedOnStep = () => {
    switch (activeStep) {
      case 1:
        return <PersonalDetails />;
      case 2:
        return <WorkDetails />;
      case 3:
        return <ReligiousDetails />;
      case 4:
        return <FamilyDetails />;
      case 5:
        return <MedicalConditions />;
      default:
        return null; // Handle other cases as needed
    }
  };

  return (
    <BuildProfileContext.Provider value={{ activeStep, setActiveStep }}>
      <div className={style.buildProfileContainer}>
        <div className={style.logoContainer}>
          <Image
            src={logoImage}
            className={style.logo}
          />
        </div>
        <div className={style.headerContainer}>
          <h2 className={style.headerTitle}>Let’s build your profile</h2>
          <p className={style.headerSubTitle}>A good profile will help you find a perfect match.</p>
        </div>
        {renderComponentBasedOnStep()}
      </div>
    </BuildProfileContext.Provider>
  )
}
