import React from 'react'
import ViewPagesHeader from '../../features/PagesHeader/ViewPagesHeader'

const ViewPage = (props) => {
    const { title, children, blackuser, path, ids, userToken, setTokenUser, identifiLable } = props
    return (
        <>
            <ViewPagesHeader title={title} children={blackuser} path={path} ids={ids} userToken={userToken} setTokenUser={setTokenUser} identifiLable={identifiLable} />

            {children}
        </>
    )
}

export default ViewPage
