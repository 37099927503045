import React, { useContext, useEffect, useRef, useState } from 'react'
import style from "./Form.module.css"
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput'
import PopoutDatePicker from '../../../../../ui/PopoutDatePicker/PopoutDatePicker'
import PopoutMobileInput from '../../../../../ui/PopoutMobileInput/PopoutMobileInput'
import checkedIcon from "../../../assets/svg/Signup/checked.svg"
import uncheckedIcon from "../../../assets/svg/Signup/uncheck.svg"
import uploadIcon from '../../../assets/common/Upload.svg';
import uploadImageIcon from '../../../assets/common/Upload Image.svg';
import { phoneCode } from '../../../../../Data/data'
import { addScroll, avoidScroll, dateFormator, defaultDate, formatMuiDate, isAlphaNumeric, uploadFile } from '../../../../../utils/utils'
import useApiCall from '../../../hooks/useApiCall'
import config from "../../../../../app.json"
import { SignUpContext } from '../Context/SignUpContext'
import { RegisterContext } from '../../LandingPage/Register/Context/RegisterContext'
import MuiDatePicker from '../../../../../ui/MuiDatePicker/MuiDatePicker'
import dayjs from 'dayjs'
import FloatingDatePicker from '../../../../../ui/FloatingDatePicker/FloatingDatePicker'
import pdfIcon from "../../../assets/common/pdf.svg"
import { useNavigate } from 'react-router-dom'
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown'
import Iframe from 'react-iframe'
import ModalBox from '../../../../../ui/Modal/ModalBox'
import closeIcon from "../../../assets/svg/Signup/Close.svg"
import deleteIcon from '../../../assets/common/Bin.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notesIcon from "../../../assets/common/notes.svg"
import registerSuccessIcon from "../../../assets/common/Profile Private@2x.svg"
import completedIcon from "../../../assets/common/Purchase Completed.svg"
import completedIconVector from "../../../assets/common/overallSuccessVector.svg"
import FloatingYearPicker from '../../../../../ui/FloatingYearPicker/FloatingYearPicker'
import $ from "jquery";

export default function Form({data}) {

  const navigation = useNavigate()

  const { clearData } = useContext(RegisterContext)
  const [isOpenDob, setIsOpenDob] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [viewSuccessModal, setViewSuccessModal] = useState(false);
  const [imageVerificationMessage, setImageVerificationMessage] = useState("");

  const { setActiveStep, mobileNumber, setMobileNumber, signupData, setSignupData, handleCloseDrawer, setDrawerType } = useContext(SignUpContext);
  const { isLoading: signUpLoading, success: signUpSuccess, error: signUpError, sendRequest: signUp } = useApiCall();

  const { success: loginSuccess, error: loginError, sendRequest: login } = useApiCall();


  const [uploadedImages, setUploadedImages] = useState([]);
  const uploadInputs = useRef([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [formData, setFormData] = useState({
    images: []
  })
  const dropdownRef = useRef(null);
  const dropdownRefDob = useRef(null);

  // const { data, loading, error, fetchData } = useApiCall();

  const [name, setName] = useState("");
  const [dob, setDob] = useState("") 
  const [email, setEmail] = useState("") 
  const [gender, setGender] = useState("") 
  const [councilName, setCouncilName] = useState("") 
  const [councilNameData, setCouncilNameData] = useState("") 
  const [genderData, setGenderData] = useState("")
  const [education, setEducation] = useState("") 
  const [educationData, setEducationData] = useState([])
  const [imrNumber, setImrNumber] = useState("");
  const [studiedYear, setStudiedYear] = useState("") 
  const [studiedYearData, setStudiedYearData] = useState("") 
  const [termsChecked, setTermsChecked] = useState(false);
  const [selectedCertificate, setSelectedCertificate] = useState(null); // State to store the selected certificate file
  const [selectedCertificatePreview, setSelectedCertificatePreview] = useState(null);
  const [selectedValue, setSelectedValue] = useState(`+${phoneCode[0].code}`); // State to store the selected value

  // State for error messages
  const [imageError, setImageError] = useState('');
  const [nameError, setNameError] = useState('');
  const [dobError, setDobError] = useState('');
  const [genderError, setGenderError] = useState('');
  const [councilNameError, setCouncilNameError] = useState("") 
  const [mobileNumberError, setMobileNumberError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [imrNumberError, setImrNumberError] = useState('');
  const [studiedYearError, setStudiedYearError] = useState("") 
  const [certificateError, setCertificateError] = useState('');
  const [termsError, setTermsError] = useState('');
  const [viewTermsModal, setViewTermsModal] = useState(false);
  const [viewPrivacyModal, setViewPrivacyModal] = useState(false);

  const [formSubmitted, setFormSubmitted] = useState(false);

  const { sendRequest: getMetaData } = useApiCall();

  useEffect(() => {
    setUploadedImages([])
    setImageFiles([])
    setFormData({...formData, images: [] })

    setImageError('');
    setNameError('');
    setDobError('');
    setGenderError('');
    setCouncilNameError('');
    setMobileNumberError('');
    setEmailError('');
    setImrNumberError('');
    setStudiedYearError('');
    setCertificateError('');
    setTermsError('');
    setImageVerificationMessage("")

    let currentYear = new Date().getFullYear();    
    let earliestYear = 1995;   
    let dateOption = []  
    while (currentYear >= earliestYear) {      
      dateOption.push({
        name: currentYear,
        value: currentYear
      })   
      currentYear -= 1;    
    }
    setStudiedYearData(dateOption)
    getMetaData(
      {
        url: 'register/read-meta-data',
        method: 'POST'
      },
      (data) => {
        const { gender, education, medical_council } = data?.data;
        setGenderData(gender)
        setEducationData(education)
        setCouncilNameData(medical_council)
      }
    );
  }, []);

  useEffect(()=> {
    if( signupData?.name ) {
      setCouncilName(signupData?.council_name)
      setStudiedYear(signupData?.studied_year)
      setUploadedImages(signupData?.uploadedImages)
      setImageFiles(signupData?.user_image)
      setFormData({...formData, images: signupData?.user_image })
      setEmail(signupData?.email)
      setGender(signupData?.gender)
      setEducation(signupData?.education)
      setName(signupData?.name);
      setTermsChecked(signupData?.termsChecked);
      setDob(signupData?.dob);
      setImrNumber(signupData?.imr_number)
      setMobileNumber(signupData?.mobile_number)
      setSelectedCertificate(signupData?.certificate_url)
      setSelectedCertificatePreview(signupData?.preview)
      setSelectedValue(signupData?.countryCode ? signupData?.countryCode : `+${phoneCode[0].code}`)
    }
  },[signupData])

  useEffect(()=> {
    if( data?.name ) {
      setImageVerificationMessage("")
      setUploadedImages([])
      setImageFiles([])
      setFormData({...formData, images: [] })
      setImageError('');
      setNameError('');
      setDobError('');
      setGenderError('');
      setCouncilNameError('');
      setMobileNumberError('');
      setEmailError('');
      setImrNumberError('');
      setStudiedYearError('');
      setCertificateError('');
      setTermsError('');
      setName(data?.name);
      setDob(data?.dob);
      setImrNumber(data?.imr)
      setMobileNumber(data?.mobile)
      setSelectedValue(data?.countryCode ? data?.countryCode : `+${phoneCode[0].code}`)
    }
  },[data])

  const handleNameChange = (e) => {
    setIsOpen(false)
    setName(e.target.value);
    setNameError(''); // Clear error when the input changes
  };

  const handleEmailChange = (e) => {
    setIsOpen(false)
    setEmail(e.target.value);
    setEmailError(''); // Clear error when the input changes
  };

  const handleCouncilNameChange = (e) => {
    setIsOpen(false)
    setCouncilName(e.target.value);
    setCouncilNameError(''); // Clear error when the input changes
  };

  const handleMobileNumberChange = (mobileValue) => {
    setIsOpen(false)
    setMobileNumber(mobileValue);
    setMobileNumberError('');
  };

  const handleImrNumberChange = (e) => {
    setIsOpen(false)
    setImrNumber(e.target.value);
    setImrNumberError('');
  };

  const handleStudiedYearChange = (e) => {
    setIsOpen(false)
    setStudiedYear(e.target.value);
    setStudiedYearError('');
  };

  const handleCertificateUpload = (e) => {
    setIsOpen(false)
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setCertificateError("")
      setSelectedCertificate(selectedFile);
      const selectedFileURL = URL.createObjectURL(selectedFile);
      setSelectedCertificatePreview(selectedFileURL);
    }
  };

  const validateEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  };

  const handleSubmit = async() => {
    
    setFormSubmitted(true)

    let hasErrors = false;

    // Validation checks
    if (!imageFiles?.length) {
      setImageError('Please select Profile Image');
      hasErrors = true;
    } else {
      setImageError('')
    }

    // Validation checks
    if (!name?.trim()) {
      setNameError('Please enter your name');
      hasErrors = true;
    } else {
      setNameError('')
    }

    if (!dob) {
      setDobError('Please select your date of birth');
      hasErrors = true;
    } else {
      setDobError('')
    }

    if (!gender?.trim()) {
      setGenderError('Please choose your Gender');
      hasErrors = true;
    } else {
      setGenderError('')
    }

    if (!councilName?.trim() || councilName?.trim() === "Select an option") {
      setCouncilNameError('Please choose the Council Name');
      hasErrors = true;
    } else {
      setCouncilNameError('')
    }

    if (!studiedYear?.trim() || studiedYear?.trim() == "Select an option") {
      setStudiedYearError('Please choose Studied Year');
      hasErrors = true;
    } else {
      setStudiedYearError('')
    }

    if (!mobileNumber?.trim()) {
      setMobileNumberError('Please enter your mobile number');
      hasErrors = true;
    } else {
      if( mobileNumber?.trim()?.length !== 10 ) {
        hasErrors = true;
        setMobileNumberError('Please enter valid mobile number');
      } else {
        setMobileNumberError('')
      }
    }

    if (!email?.trim()) {
      setEmailError('Please enter your Email address');
      hasErrors = true;
    } else {
      if (email?.trim() && !validateEmail(email) ) {
        setEmailError('Please enter the valid Email address');
        hasErrors = true;
      } else {
        setEmailError('')
      }
    }

    if (!imrNumber?.trim()) {
      setImrNumberError('Please enter your NMC/SMC Number');
      hasErrors = true;
    } else {
      if( !isAlphaNumeric(imrNumber) ) {
        setImrNumberError('Please enter only alpha numeric');
        hasErrors = true;
      } else {
        setImrNumberError('')
      }
    }

    if (!selectedCertificate) {
      setCertificateError('Please upload your NMC/SMC certificate');
      hasErrors = true;
    } else {
      const fileSize = selectedCertificate.size;
      // Maximum allowed file size (6MB in bytes)
      const maxFileSize = 6 * 1024 * 1024;
      if (fileSize <= maxFileSize) { 
        setCertificateError('')
      } else {
        setCertificateError('File size exceeds the maximum limit of 6MB.');
        hasErrors = true;
      }
    }

    if (!termsChecked) {
      setTermsError('Please check Terms and Privacy');
      hasErrors = true;
    } else {
      setTermsError('')
    }

    if (hasErrors) {
      // Stop processing if there are errors
      return;
    }

    // let s3Image = await uploadFile(selectedCertificate)

    const url = "auth/register/send-otp"

    setSignupData({
      "name": name.trim(),
      "dob": dob,
      "gender": gender,
      "mobile_number": mobileNumber.trim(),
      "email": email.trim(),
      "council_name": councilName,
      "studied_year": studiedYear,
      "user_image": imageFiles,
      "uploadedImages": uploadedImages,
      "imr_number": imrNumber.trim(),
      "preview": selectedCertificatePreview,
      "certificate_url": selectedCertificate,
      "country_code": selectedValue,
      "termsChecked": termsChecked
    })

    const postData = {
      "country_code": selectedValue,
      "mobile_number": mobileNumber,
      "email": email.trim()
    }

    signUp({
      url: url,
      method: 'POST',
      body: postData,
    });
  };

  useEffect(() => {
    if (signUpSuccess) {
      avoidScroll()
      setActiveStep("otp")
      // setViewOverallSuccessModal(true)
    }
    if (signUpError) {
      setTermsError(signUpError)
    }
  }, [signUpSuccess, signUpError]);

  const handleRadioChange = (name, value) => {
    setGender(value);
  };

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      { options && options?.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => {
            setGenderError("")
            handleRadioChange(name, option.value)
          }}
        >
          <Image src={gender === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  const uploadElements = [];

  // const numberOfImages = getUserData()?.membership_type === "Basic" ? 1 : 6

  for (let i = 0; i < 1; i++) {
    uploadElements.push(
      <>
        {uploadedImages[i] ? (
          <div
            key={i}
            className={style.uploadedImage}
            style={{
              backgroundImage: `url(${uploadedImages[i]?.includes("blob:") ? uploadedImages[i] : process.env.REACT_APP_S3_CLOUD_FRONT_URL + uploadedImages[i]})`,
            }}
          >
            <div className={style.deleteContainer}>
              <Image src={deleteIcon} onClick={() => handleImageDelete(i)} />
            </div>
            <div
              className={style.reuploadContainer}
              // onClick={() => uploadInputs.current[i].click()}
            >
              {/* <Image src={uploadWhiteIcon} /> */}
              <p className={style.reuploadLabeText}>Waiting Verification</p>
            </div>
          </div>
        ) : (
          <div
            key={i}
            className={style.upload}
            onClick={() => uploadInputs.current[i].click()}
          >
            <label className={style.uploadImageLabel}>
              <div className={style.uploadImage}>
                <img
                  src={uploadImageIcon}
                  className={style.uploadLogo}
                  alt="Upload Icon"
                />
              </div>
            </label>
            <p className={style.uploadLabel}>Upload Image</p>
          </div>
        )}
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={(event) => handleImageUpload(event, i)}
          className={style.uploadInput}
          ref={(el) => (uploadInputs.current[i] = el)} // Assign the ref to the input element
        />
      </>
    );
  }

  const handleImageDelete = (index) => {
    setImageVerificationMessage("")
    const newImages = [...uploadedImages];
    newImages[index] = null;
    setUploadedImages(newImages);

    // Reset the file input
    if (uploadInputs.current[index]) {
      uploadInputs.current[index].value = null;
    }

    const newImageFiles = [...imageFiles];
    newImageFiles.splice(index, 1);
    setImageFiles(newImageFiles);

    setFormData({ ...formData, images: newImageFiles })
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    setImageVerificationMessage("")
    if (file) {
      const allowedFormats = ['image/jpeg', 'image/png', 'image/gif']; // Add more formats if needed
  
      // Check if the file size is less than or equal to 6 MB (6 * 1024 * 1024 bytes)
      if (file.size <= 6 * 1024 * 1024) {
        // Check if the file type is allowed
        if (allowedFormats.includes(file.type)) {
          const newImages = [...uploadedImages];
          newImages[index] = URL.createObjectURL(file); // Store the data URL for preview
          setUploadedImages(newImages);
  
          const newImageFiles = [...imageFiles];
          newImageFiles[index] = file; // Store the File object
          setImageFiles(newImageFiles);
  
          setFormData({ ...formData, images: newImageFiles });
          // setViewSuccessModal(true)
          setImageVerificationMessage("Photo will reflect after 24 hrs.")
          setImageError("")
        } else {
          // Invalid file format, handle the error here (e.g., show a message to the user)
          toast.error('Invalid file format. Please choose a JPG, PNG file.', {
            autoClose: 2500,
          });
          // Optionally, you can clear the input field to allow the user to choose another file
          event.target.value = null;
        }
      } else {
        // File size exceeds 6 MB, handle the error here (e.g., show a message to the user)
        toast.error('File size exceeds 6 MB. Please choose a smaller file.', {
          autoClose: 2500,
        });
        // Optionally, you can clear the input field to allow the user to choose another file
        event.target.value = null;
      }
    }
  };

  // Close the dropdown when clicking outside of it
  const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setIsOpen(false);
      }
      if (dropdownRefDob.current && !dropdownRefDob.current.contains(event.target)) {
        setIsOpenDob(false);
    }
  };

  useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []);

  return (
    <div className={style.contentContainer}>
      <ToastContainer />
      <ModalBox
        open={viewSuccessModal}
        width={"609px"}
        modalContent={
          <div className={style.registerSuccessContainer}>
            <Image src={registerSuccessIcon} className={style.registerSuccessIcon}/>
            <p className={style.registerSuccessTitle}>Photo Verification Pending</p>
            <p className={style.registerSuccessDescription}>Your photo is under verification, and we will validate it within 24 hours.</p>
            <div className={style.registerOkayButtonContainer}>
              <Button
                style={{ width: "80%",  }}
                className={`primaryButton`}
                onClick={()=> {
                  setViewSuccessModal(false)
                }}
                // loading={signUpLoading}
              >
                Okay
              </Button>
            </div>
          </div>
        }
      />
      <ModalBox
        open={viewTermsModal}
        width={"1000px"}
        modalContent={
          <div className={style.termsPrivacyContainer}>
            <Image src={closeIcon} className={style.closeIcon} onClick={()=> {
              setViewTermsModal(false)
            }}/>
            <Iframe url={"/terms_and_conditions_app"}
              width="970px"
              height="600px"
              id=""
              className=""
              display="block"
              position="relative"
              allowFullScreen={true}
            />
          </div>
        }
      />
      <ModalBox
        open={viewPrivacyModal}
        width={"1000px"}
        modalContent={
          <div className={style.termsPrivacyContainer}>
            <Image src={closeIcon} className={style.closeIcon} onClick={()=> {
              setViewPrivacyModal(false)
            }}/>
            <Iframe url={"/privacy_policy_app"}
              width="970px"
              height="600px"
              id=""
              className=""
              display="block"
              position="relative"
              allowFullScreen={true}
            />
          </div>
        }
      />
      <p className={style.title}>Register Now</p>
      <p className={style.description}>Please enter details to log in to your account</p>

      <div className={style.enterImageDetailsContainer}>
        <p className={style.headingText}>Upload Photo</p>
        <div className={style.uploadContainer}>{uploadElements}</div>
        {
          imageVerificationMessage && (
            <div className={style.eliteVideoInstructionContainer}>
              <Image src={notesIcon} className={style.notesIcon}/>
              <p className={style.instructionTitle}>Your photo is in the reviewing phase. Your profile will update with the approved image within 24 hours.</p>
            </div>
          )
        }
        {/* <p className={style.instructionTitleMessage}>{imageVerificationMessage}</p> */}
        {imageError && <p className={"error"}>{imageError}</p>}
      </div>

      <div className={style.enterDetailsContainer}>
        <p className={style.headingText}>Enter Details</p>
        <div className={style.inputContainer}>
          <PopoutInput
            label="Name"
            name={"name"}
            value={name}
            onChange={handleNameChange}
          />
          {nameError && <p className={"error"}>{nameError}</p>}
        </div>
        <div className={style.inputContainer}>
          <PopoutMobileInput
            name={"mobile_number"}
            label={"Mobile Number"}
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            selectedValue={selectedValue}
            setSelectedValue={setSelectedValue}
          />
          {mobileNumberError && <p className={"error"}>{mobileNumberError}</p>}
        </div>
        <div className={style.inputContainer}>
          <PopoutInput
            name={"email"}
            label={"Email Address"}
            value={email}
            onChange={handleEmailChange}
          />
          {emailError && <p className={"error"}>{emailError}</p>}
        </div>
        <div className={style.inputContainer} ref={dropdownRefDob}>
          <FloatingDatePicker
            isOpen={isOpenDob}
            setIsOpen={setIsOpenDob}
            error={dobError}
            selectedDate={dob}
            onChange={(value)=> {
              setDob(value)
              setDobError('')
            }}
            label={"Date of Birth"}
          />
          {dobError && <p className={"error"}>{dobError}</p>}
          <div style={{ minWidth: "342px", padding: "15px 0px" }} className={style.field}>
            <p className={style.fieldTitle}>Gender</p>
            <div className={style.fieldValue}>
              {renderRadioButtons('gender', genderData)}
            </div>
          </div>
            {genderError && <p className={"error"}>{genderError}</p>}
        </div>
        <div className={style.inputContainer}>
          <FloatingLabelDropdown
            label="Council Name"
            name="council_name"
            responseType="name"
            options={councilNameData} // Add options
            value={councilName}
            onChange={handleCouncilNameChange}
          />
          {councilNameError && <p className={"error"}>{councilNameError}</p>}
        </div>
        <div className={style.inputContainer}>
          <PopoutInput
            label="NMC/SMC Number"
            value={imrNumber}
            onChange={handleImrNumberChange}
          />
          {imrNumberError && <p className={"error"}>{imrNumberError}</p>}
        </div>
        <div className={style.inputContainer}>
          <FloatingLabelDropdown
            label="Year of Registration"
            name="studied_year"
            responseType="name"
            options={studiedYearData} // Add options
            value={studiedYear}
            onChange={handleStudiedYearChange}
          />
          {studiedYearError && <p className={"error"}>{studiedYearError}</p>}
        </div>
        <div className={style.fileUploadContainer}>
          <p className={style.fileTitle}>NMC/SMC Certificate</p>
          <p className={style.fileDescription}>PDF, PNG, JPG with a Max file size of 6MB</p>
          <label htmlFor="certificateUpload" style={{ gap: selectedCertificate ? '12px' : '' }} className={style.uploadButton}>
            {selectedCertificate ? (
                <>
                {
                  selectedCertificate?.type === "application/pdf" ?
                    <Image onClick={()=> {
                      // avoidScroll()
                      // setShowRejectReasonModal(true)
                    }} src={pdfIcon} width={"70px"} height={"50px"}/>
                  :
                  <Image src={selectedCertificatePreview} width="70px" height="50px" />
                }
                </>
            ) : (
              <Image src={uploadIcon} /> // Display a placeholder image if no certificate is selected
            )}
            <p className={style.uploadButtonTitle}>{selectedCertificate ? 'Edit' : 'Upload Certificate'}</p>
          </label>
          <input
            type="file"
            id="certificateUpload"
            accept="application/pdf,image/png,image/jpeg"
            onChange={handleCertificateUpload}
            style={{ display: 'none' }}
          />
          {certificateError && <p className={"error"}>{certificateError}</p>}
        </div>
        <div className={style.terms}>
          <div className={style.termsContainer}>
            <Image
              onClick={() => {
                setTermsChecked(!termsChecked)
                setTermsError("")
              }}
              src={ termsChecked ? checkedIcon : uncheckedIcon }
              className={style.checkIcon}
            />
            <p className={style.termsText}>I agree to the <span onClick={()=> { 
              avoidScroll()
              setViewTermsModal(true)
            }}>Terms and Conditions</span> & <span  onClick={()=> { 
              avoidScroll()
              setViewPrivacyModal(true)
            }}>Privacy Policy</span> of Doctors Matrimony.</p>
          </div>
          {termsError && formSubmitted && <p className={"error"}>{termsError}</p>}
        </div>
      </div>

      <Button
        style={{ width: "100%" }}
        className={`primaryButton`}
        onClick={handleSubmit}
        loading={signUpLoading}
      >
        Continue
      </Button>
      <p className={style.loginNow}>Already have an account? <span onClick={()=> {
        if( setDrawerType ) {
          setDrawerType("login")
        } else {
          // $(".Signup_signupContainer__CVfD3").remove()
          $(".Signup_closeIcon__wwCfs").trigger("click")
          setTimeout(() => {
            $(".Header_loginButton__-ysGT").trigger("click")
          }, 100);
        }
      }}>Login Now</span></p>
    </div>
  )
}
