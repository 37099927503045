import React, { useEffect, useState } from 'react';
import style from './MultiSelect.module.css';
import checkedIcon from '../../../../assets/svg/Signup/checked.svg';
import uncheckedIcon from '../../../../assets/svg/Signup/uncheck.svg';
import Image from '../../../../../../ui/Image/Image';
import Button from '../../../../../../ui/Button/Button';
import { addScroll } from '../../../../../../utils/utils';

export default function MultiSelect({ name, label, selectedData, options, setIsFocused, handleData, value, responseType }) {
  const [selectedOptions, setSelectedOptions] = useState(selectedData || []);;
  const [searchQuery, setSearchQuery] = useState('');

  const [ enableButton, setEnableButton ] = useState(false)

  useEffect(() => {
    setSelectedOptions(selectedData || []);
  }, [selectedData]);

  useEffect(()=> {
    if( selectedOptions.length > 0 ) {
      setEnableButton(true)
    } else {
      setEnableButton(false)
    }
  }, [selectedOptions])

  const toggleOption = (token, name) => {
    const existingOptionIndex = selectedOptions?.findIndex((item) => item.name === name);

    if (existingOptionIndex !== -1) {
      // If the token is already in selectedOptions, remove it.
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(existingOptionIndex, 1);
      setSelectedOptions(updatedOptions);
    } else {
      // If the token is not in selectedOptions, add it as an object.
      setSelectedOptions([...selectedOptions, { token, name }]);
    }
  };

  // Filter options based on search query
  const filteredOptions = options?.filter((item) =>
    item?.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={style.multiSelectContainer}>
      <input
        type="text"
        placeholder={`Search ${label} preference`}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className={style.listContainer}>
        {
          filteredOptions?.length > 0 ?
            filteredOptions?.map((item) => (
              <div
                className={style.dataContainer}
                key={item.token}
                onClick={() => {
                    toggleOption(item[responseType], item.name)
                }} // Pass token and name to toggleOption
              >
                <div className={style.checkIcon}>
                  <Image
                    src={
                      selectedOptions.some((selected) => selected.name === item.name)
                        ? checkedIcon
                        : uncheckedIcon
                    }
                  />
                </div>
                <p>{item.name}</p>
              </div>
            ))
          :
          <p>No data found.</p>
        }
      </div>
      <div className={style.footer}>
        <Button
          className={`${style.submitButton} primaryButton cancelButton`}
          onClick={() => {
            addScroll();
            setIsFocused(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className={`primaryButton ${style.submitButton} ${ !enableButton ? style.disbledButton : ""}`}
          onClick={() => {
            if( enableButton ) {
              addScroll();
              handleData(selectedOptions);
            }
          }}
        >
          Okay
        </Button>
      </div>
    </div>
  );
}
