import React, { useState, useEffect } from 'react'
import classes from './MediLoyout.module.css'
import LineChart from '../LineChart/LineChart'
import Table from "../../../UI/Table/DashbordTable";
import Image from '../../../UI/Img/Img';
import invoice from '../../../assets/SVG/Manage Users/DownloadInvoice.svg'
import invoicedisble from '../../../assets/SVG/Manage Users/DownloadInvoicegray.svg'
import Slecter from '../../../UI/Dashboardui/Slecters'
import useApiHttp from "../../../hooks/ues-http";
import { BASE_URL } from "../../../utils/baseUrl";
import ReusableModal from '../../../UI/Modal/ScrollingModal/ScrollingModal';
import Tooltip from '@material-ui/core/Tooltip';

const MediLoyout = () => {
    const [selectedYearMonth, setSelectedYearMonth] = useState("");
    const [tableInvoiceData, setTableInvoiceData] = useState([]);
    const [line, setLine] = useState([]);
    const [userIdModal, setUserIdModal] = useState(false);
    const [userIdSate, setUserIdSate] = useState([]);

    const handleYearMonthChange = (event) => {
        setSelectedYearMonth(event.target.value);
    };

    const { isLoading: columIsLoading, sendRequest: culumRequest } = useApiHttp();
    const culumData = (data) => {
        setTableInvoiceData(data.data)
    }
    const fetchCulumData = () => {
        culumRequest({
            url: `${BASE_URL}recent-invoice`,
            method: 'POST',
        }, culumData);
    }

    const { sendRequest: pieRequest } = useApiHttp();
    const pieData = (data) => {
        setLine(data.data)
    }
    const fetchpieData = () => {
        const requestData = {
            revenue_year: `${selectedYearMonth}`,
        };

        pieRequest({
            url: `${BASE_URL}revenue-statistic`,
            method: 'POST',
            body: requestData,
        }, pieData);
    }

    useEffect(() => {
        fetchCulumData()
    }, [])
    useEffect(() => {
        fetchpieData()
    }, [selectedYearMonth])

    const { sendRequest: UserIdRequest } = useApiHttp();
    const UserIdData = (data) => {
        setUserIdSate(data.data)
    };
    const UserIdRequestData = (token) => {

        if (token === null) {
            setUserIdModal(false)
        } else {
            setUserIdModal(true)
        }
        UserIdRequest({
            url: `${BASE_URL}basic-user-detail`,
            method: 'POST',
            body: {
                "token": token
            }
        }, UserIdData);
    };

    const viewUserIdModal = (data) => {
        UserIdRequestData(data)
    }

    const columns = [
        {
            name: "S.No",
            selector: (row) => row.slno,
        },
        {
            name: "User ID",
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => viewUserIdModal(row.user_token)}>{row.user_id}</span></>),
        },
        {
            name: "User Name",
            selector: (row) => row.user_name,
        },
        {
            name: "Plan Amount",
            selector: (row) => row.plan_amount,
        },
        {
            name: "Payment Date",
            selector: (row) => row.payment_date,
        },
        {
            name: "Invoice",
            cell: (row) => (
                <>
                    <Tooltip title={row.invoice === "Unavailable" ? row.invoice : "Download Invoice"}><span
                        onClick={() => {
                            if (row.invoice !== "Unavailable") {
                                handleDownloadInvoice(row.invoice, row.invoice_name);
                            }
                        }}
                        className={`${row.invoice === "Unavailable" ? "unavailable" : "action_text action_text_pointer w__300"}`}
                    >
                        {row.invoice === "Unavailable" ? <Image src={invoicedisble} alt="Invoice" /> : <Image src={invoice} alt="Invoice" />} Download
                    </span>
                    </Tooltip>
                </>
            ),

        },

    ];

    const handleDownloadInvoice = (fileLink, fileName) => {
        if (fileLink) {
            fetch(fileLink)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const linkElement = document.createElement('a');
                    linkElement.href = url;
                    linkElement.setAttribute('download', fileName);
                    document.body.appendChild(linkElement);
                    linkElement.click();
                    document.body.removeChild(linkElement);
                })
                .catch(error => {
                    console.error('Error downloading file:', error);
                });
        }
    }


    return (
        <>
            <div className={classes.toployout_container}>
                <div className={classes.table_section}>
                    <div className={classes.titleContainer}>
                        <p className={classes.title}>Recent Invoices</p>
                    </div>

                    <Table data={tableInvoiceData} columns={columns} loader={columIsLoading} />
                </div>
                <div className={classes.linechart_section}>
                    <div className={classes.titleContainer}>
                        <p className={classes.title}>Revenue Statistics</p>

                        <div className={classes.dropdownContainer}>
                            <div className={classes.dropdown}>
                                <Slecter name="month" options={line.dropdown} selectedValue={selectedYearMonth} handleChange={handleYearMonthChange} />
                            </div>
                        </div>

                    </div>

                    <LineChart datas={line} />
                </div>
            </div>

            <ReusableModal open={userIdModal} setOpen={setUserIdModal} titel="User ID" type="view" okay="ok" scrollType="body" size="sm">
                <>
                    <div className={classes.feedback_container}>

                        <div className={classes.profile_image}>
                            <img src={userIdSate.image} alt="userIdSate" />
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.content_box_title}>User ID</p>
                                <p className={classes.content_box_sub}>{userIdSate.user_id}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>User Name</p>
                                <p className={classes.content_box_sub}>{userIdSate.user_name}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>NMC or SMC number Number</p>
                                <p className={classes.content_box_sub}>{userIdSate.imr_number}</p>
                            </div>
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.content_box_title}>Gender</p>
                                <p className={classes.content_box_sub}>{userIdSate.gender}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>Mobile</p>
                                <p className={classes.content_box_sub}>{userIdSate.mobile_number}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>Email</p>
                                <p className={classes.content_box_sub}>{userIdSate.email}</p>
                            </div>
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.content_box_title}>Age</p>
                                <p className={classes.content_box_sub}>{userIdSate.age}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>Education</p>
                                <p className={classes.content_box_sub}>{userIdSate && userIdSate.education ? userIdSate.education.replace(/,/g, ', ') : ''}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>Membership Type</p>
                                <p className={classes.content_box_sub}>{userIdSate.membership_type}</p>
                            </div>
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.content_box_title}>Institution Working</p>
                                <p className={classes.content_box_sub}>{userIdSate.institution_working}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>City</p>
                                <p className={classes.content_box_sub}>{userIdSate.city}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>State</p>
                                <p className={classes.content_box_sub}>{userIdSate.state}</p>
                            </div>
                            <div>
                                <p className={classes.content_box_title}>Country</p>
                                <p className={classes.content_box_sub}>{userIdSate.country}</p>
                            </div>
                        </div>

                        <div>
                            <p className={classes.content_box_title}>About</p>
                            <p className={classes.content_box_sub}>{userIdSate.abount}</p>
                        </div>
                    </div>

                </>
            </ReusableModal>
        </>
    )
}

export default MediLoyout
