import React from 'react';
import classes from './Progressbar.module.css'

const ProgressBar = ({ classname }) => {
    return (
        <div className={`${classname}`}>
            <div className={`${classes["custom-loader"]}`}></div>
        </div>
    );
};

export default ProgressBar;
