import React from 'react';
import styles from './PopoutMobileInput.module.css';
import Dropdown from '../Dropdown/Dropdown';
import { phoneCode } from '../../Data/data';


function PopoutMobileInput({ error, label, placeholder, value, onChange, style, name, selectedValue, setSelectedValue }) {
  

  const handleDropdownSelect = (option) => {
    setSelectedValue(option); // Update the selected value when an option is selected
  };

  const phoneCodeWithPlus = phoneCode.map(countryObj => ({
    ...countryObj,
    code: `+${countryObj.code}`
  }));

  const options = phoneCodeWithPlus;
  
  // Function to handle changes in the input field
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Ensure the input value contains only numbers and is limited to 10 digits
    const numericValue = inputValue.replace(/\D/g, '').slice(0, 10);

    // Call the parent onChange function with the sanitized value
    onChange(numericValue);
  };

  return (
    <div style={style} className={`${styles['popout-input']} ${styles.focused} ${ error ? "dataError" : "" }`}>
      <label className={styles['input-label']}>{label}</label>
      <input
        type="text"
        name={name}
        className={styles['input-field']}
        placeholder={placeholder}
        value={value}
        onChange={(e)=>handleInputChange(e)}
      />
      <div className={styles.dropdownContainer}>
          <Dropdown
            label="Select an option"
            value={selectedValue}
            options={options}
            mobileDropdown={true} // You can set this prop as needed
            onSelect={handleDropdownSelect} // Pass the callback function
          />
      </div>
    </div>
  );
}

export default PopoutMobileInput;
