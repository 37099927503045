import React from 'react'
import style from "./TrustedFeatures.module.css"
import Image from '../../../../../ui/Image/Image'

import verifiedImage from "../../../assets/svg/LandingPage/Verified.svg"
import brandImage from "../../../assets/svg/LandingPage/Brand.svg"
import relationShipImage from "../../../assets/svg/LandingPage/Relationship.svg"

export default function TrustedFeatures() {
  return (
    <div className={`${style.trustedContainer} container`}>
        <div className={style.content}>
            <Image 
                src={verifiedImage}
                width={"60px"}
                height={"60px"}
            />
            <p>Contact genuine profiles with 100% verified IMR profiles</p>
        </div>
        <div className={style.content}>
                <Image 
                    src={brandImage}
                    width={"60px"}
                    height={"60px"}
                />
            <p>The most trusted matrimony brand by Doctors</p>
        </div>
        <div className={style.content}>
                <Image
                    src={relationShipImage}
                    width={"60px"}
                    height={"60px"}
                />
            <p>Best matches for a successful and fulfilling relationship</p>
        </div>
    </div>
  )
}
