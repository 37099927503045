import React, { useEffect, useState } from "react";
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';
import CustomerSupport from "../../features/ApplicationStatus/CustomerSupport/CustomerSupport";
import Divider from "../../../../ui/Divider/Divider";
import style from "./ApplicationStatus.module.css";

import VerificationImg from "../../assets/svg/ApplicationStatus/Status.svg";
import rejectImg from "../../assets/svg/ApplicationStatus/Illustration.svg";

import Status from "../../features/ApplicationStatus/Status/Status";
import Stories from "../../features/ApplicationStatus/Stories/Stories";
import CoupleInterviews from "../../features/ApplicationStatus/CoupleInterviews/CoupleInterviews";
import { getUserData, getUserToken } from "../../../../utils/utils";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";

const ApplicationStatus = () => {

    const navigation = useNavigate();

    const [statusCheck, setStatusCheck] = useState("Pending");

    const { isAuthenticated } = useAuthentication();

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    useEffect(() => {
        if( getUserToken() === null ) {
            navigation("/")
        } else {
            getDetails({
                url: "auth/check-user",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                const storedData = JSON.parse(localStorage.getItem('user'));
                storedData.status = data.data?.status;
                localStorage.setItem('user', JSON.stringify(storedData));

                let { status } = data.data
   
                switch (status) {

                    case "Active":
                        navigation("/home")
                        break;
                    case "Verified":
                        localStorage.setItem('subscriptionFromHome', 0);
                        navigation("/subscribe")
                        break;
                    case "Onboarding":
                        navigation("/build_profile")
                        break;
                    case "Preferencing":
                        navigation("/partner_preference")
                        break;
                }
                setStatusCheck(data.data.status)
            });
        }
    }, []);

    const verifyContent = {
      title: "Verification in Process!",
      image: VerificationImg,
      content: [
        `Your profile is under verification and will be completed within 7 to 10 working days. We appreciate your patience and trust in our service, ensuring 100% verified doctor profiles for authenticity.`,
      ],
    };
    const rejectContent = {
        title: `${ statusCheck === "Blocked" ? "Blocked" : "Application Rejected" }`,
        image: rejectImg,
        content: `${ statusCheck === "Blocked" ? `Sorry! Your Account has been blocked. Please Contact our support team for additional enquiries.` : `Sorry! Your Application has been rejected by the Doctors Matrimony 
                        Team because your supporting documentation is inaccurate. 
                        Contact our Doctors Matrimony team for additional enquiries.`}`,
    };

    return (
        <div>
            <Status
                title={
                    statusCheck === "Pending"
                        ? verifyContent.title
                        : rejectContent.title
                }
                image={
                    statusCheck === "Pending"
                        ? verifyContent.image
                        : rejectContent.image
                }
                description={
                    statusCheck === "Pending"
                        ? verifyContent.content
                        : rejectContent.content
                }
            />
            {statusCheck === "Pending" ? (
                <>
                    {/* <Stories />
                    <CoupleInterviews /> */}
                </>
            ) : (
                <>
                    <Divider className={style.dividerStyle} />
                    <CustomerSupport />
                </>
            )}
        </div>
    );
};

export default ApplicationStatus;
