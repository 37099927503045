import React, { useContext, useEffect, useState } from 'react';
import style from './Certificate.module.css';
import Image from '../../../../../ui/Image/Image';
import Button from '../../../../../ui/Button/Button';
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput';
import uploadIcon from '../../../assets/common/Upload.svg';
import useApiCall from '../../../hooks/useApiCall'
import config from "../../../../../app.json"
import { getUserToken, uploadFile } from '../../../../../utils/utils';
import Dropdown from '../../../../../ui/Dropdown/Dropdown';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import { useNavigate } from 'react-router-dom';
import { LoginContext } from '../Context/LoginContext';
import pdfIcon from "../../../assets/common/pdf.svg"

export default function Certificate({ setActiveStep }) {

  const { id, handleCloseDrawer } = useContext(LoginContext);

  const navigation = useNavigate("")
  
  const { sendRequest: getQualifications } = useApiCall();

  const { isLoading: isLoading, success: success, error: error, sendRequest: submitDocuments } = useApiCall();

  const [ qualificationData, setQualificationData ] = useState([]);
  const [ qualificationErrorData, setQualificationErrorData ] = useState([]);
  const [ qualificationDisplayData, setQualificationDisplayData ] = useState([]);
  const [ selectedQualificationData, setSelectedQualificationData ] = useState([]);

  const [qualifications, setQualifications] = useState([
    { college_name: '', qualification_detail: '', certificate_url: null },
  ]);

  const [ loader, setLoader ] = useState(false)

  useEffect(()=> {
    getQualifications({
      url: "education",
      method: 'POST',
    }, resultQualification)
  }, [])

  const resultQualification = (data) => {
    setQualificationData(data.data)
  }

  const [ submitError, setSubmitError ] = useState("")
  const [selectedOption, setSelectedOption] = useState(null);
  const [imagePreviews, setImagePreviews] = useState([
    null // Initial image previews, one for each qualification
  ]);

  const handleNameChange = (e, index) => {
    const updatedQualifications = [...qualifications];
    updatedQualifications[index][e.target.name] = e.target.value;
    setQualifications(updatedQualifications);
  };

  const handleCertificateUpload = (e, index) => {
    const file = e.target.files[0];
  
    if( file ) { 
      // Check if the file size is greater than 6MB
      if (file && file.size > 6 * 1024 * 1024) {
        const errors = [...qualificationErrorData];
        errors[index] = "File size must be less than 6MB.";
        setQualificationErrorData(errors);
        return;
      }
    
      // Clear the specific error message for this file
      const errors = [...qualificationErrorData];
      errors[index] = "";
      setQualificationErrorData(errors);
    
      const updatedQualifications = [...qualifications];
      updatedQualifications[index].certificate = file;
      setQualifications(updatedQualifications);
    
      // Handle image preview
      const reader = new FileReader();
      reader.onload = (event) => {
        const updatedImagePreviews = [...imagePreviews];
        updatedImagePreviews[index] = event.target.result;
        setImagePreviews(updatedImagePreviews);
      };
      reader.readAsDataURL(file);
    
      // Clear any previous submit error message
      setSubmitError("");
    }
  };

  const addQualification = () => {
    setQualifications([...qualifications, { college_name: '', qualification_detail: '', certificate: null }]);
    setImagePreviews([...imagePreviews, null]);
  };

  const removeQualification = (index) => {
    const updatedQualifications = [...qualifications];
    updatedQualifications.splice(index, 1);
    setQualifications(updatedQualifications);

    const updatedImagePreviews = [...imagePreviews];
    updatedImagePreviews.splice(index, 1);
    setImagePreviews(updatedImagePreviews);
  };

  useEffect(() => {
    if (success) {
      handleCloseDrawer()
      navigation("/status")
    }
    if (error !== '') {
      setSubmitError(error)
    }
    setLoader(false)
  }, [success, error]);

  const submit = async () => {
    try {
      // Validate each qualification
      for (const qualification of qualifications) {
        if (!qualification.college_name || !qualification.qualification_detail || qualification.qualification_detail === "Select an option" || !qualification.certificate) {
          setSubmitError("Please fill in all qualification details.");
          return;
        }
      }
      if( qualificationErrorData?.length ) {
        qualificationErrorData?.map((item, index) => {
          if( item !== "" ) {
            return;
          }
        })
      }
      setLoader(true)
  
      setSubmitError(""); // Clear any previous error message
  
      // Upload all images to S3 in parallel
      const uploadPromises = qualifications.map(async (qualification, index) => {
        if (qualification.certificate) {
          // Replace 'uploadFile' with your actual S3 upload function
          return await uploadFile(qualification.certificate);
        }
        return null;
      });
  
      const uploadedImages = await Promise.all(uploadPromises);
  
      // Now, 'uploadedImages' will contain the S3 URLs of the uploaded certificates.
      // You can use them to update the 'qualifications' array if needed.
  
      // For example, you can update 'qualifications' like this:
      const updatedQualifications = qualifications.map((qualification, index) => ({
        college_name: qualification.college_name.trim(),
        qualification_detail: qualification.qualification_detail,
        certificate_url: uploadedImages[index], // Replace with the S3 URL
      }));
  
      // Finally, send the request with the updated qualifications
      submitDocuments({
        url: "auth/register/add-qualification",
        method: 'POST',
        body: {
          "qualification": updatedQualifications,
        },
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        }
      });
    } catch (error) {
      // Handle any errors that occur during validation or uploading
      console.error("Error during submission:", error);
      setSubmitError("An error occurred during submission.");
    }
  };

  return (
    <div className={style.contentContainer}>
      <p className={style.title}>We’d like to know more about you</p>
      <div className={style.description}>
        <p>This helps us verify your profile since we aim to</p>
        <p> provide 100% authentic profiles to all our</p><p> registered users.</p>
      </div>
      {qualifications.map((qualification, index) => (
        <>
        <div key={index} className={style.formHeader}>
          <p className={style.titleText}>Qualification {index + 1}</p>
          {index > 0 && (
            <p className={style.remove} onClick={() => removeQualification(index)}>
              Remove
            </p>
          )}
        </div>
        <PopoutInput
          label="College Name"
          name="college_name"
          value={qualification.college_name}
          onChange={(e) => handleNameChange(e, index)}
          style={{
            paddingBottom: '26px',
          }}
        />
        {
          qualificationData && 
            <FloatingLabelDropdown
              label="Qualification Details"
              name="qualification_detail"
              responseType="token"
              options={qualificationData}
              value={qualification.qualification_detail}
              onChange={(e) => handleNameChange(e, index)}
              style={{
                paddingBottom: '26px',
              }}
            />
        }
        <div className={style.fileUploadContainer}>
          <p className={style.fileTitle}>Qualification Certificate</p>
          <p className={style.fileDescription}>PDF, PNG, JPG with a Max file size of 6MB</p>
          <label htmlFor={`certificateUpload-${index}`} style={{ gap: imagePreviews[index] ? "12px" : "" }} className={style.uploadButton}>
            {imagePreviews[index] ? (
              <>
                {
                  qualifications[index].certificate?.type === "application/pdf" ?
                    <Image onClick={()=> {
                      // avoidScroll()
                      // setShowRejectReasonModal(true)
                    }} src={pdfIcon} width={"70px"} height={"50px"}/>
                  :
                  <Image src={imagePreviews[index]} width={"70px"} height={"50px"}/>
                }
              </>
            ) : (
              <Image src={uploadIcon} />
            )}
            <p className={style.uploadButtonTitle}>{ imagePreviews[index] ? "Edit" : "Upload Certificate" }</p>
          </label>
          <input
            type="file"
            id={`certificateUpload-${index}`}
            accept="application/pdf,image/png,image/jpeg"
            onChange={(e) => handleCertificateUpload(e, index)}
            style={{ display: 'none' }}
          />
          <p className={`${style.errorMessage} error`}>{qualificationErrorData[index]}</p>
        </div>
        </>
      ))}
      <div className={style.cloneButton} onClick={addQualification}>
        <p className={style.cloneButtonTitle}>+ Add Qualification</p>
      </div>
      {submitError && <p className={`${style.errorMessage} error`}>{submitError}</p>}
      <Button
        style={{ width: '100%' }}
        className={`primaryButton`}
        onClick={submit}
        loading={isLoading}
      >
        Submit for verification
      </Button>
      <p className={style.loginNow}>
        Profile verification takes 2-3 business days, post which you can choose the plan and fill in other details.
      </p>
    </div>
  );
}
