import React, { useState, useEffect } from "react";
import style from "./SubscribeCard.module.css";
import CustomButton from "../../../../../ui/Button/Button";
import ModalBox from "../../../../../ui/Modal/ModalBox";
import Success from "../Modal/Success";
import { avoidScroll, getUserToken } from "../../../../../utils/utils";
import axios from "axios";
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import Preference from "./ModalPreference/Preference";

const SubscribeCard = ({item, backgroundColor, type}) => {

    const [ viewDetailsModal, setViewDetailsModal ] = useState(false)
    const [ viewVideoUploadModal, setViewVideoUploadModal ] = useState(false)
    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const { isLoading: paymentLoading, success: paymentSuccess, error: paymentError, sendRequest: postDetails } = useApiCall();

    //Function to load razorpay script for the display of razorpay payment SDK.
    function loadRazorpayScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    useEffect(()=> {
        if(paymentSuccess) {
            avoidScroll()
            setViewDetailsModal(true)
        }
    }, [paymentSuccess])

    //function will get called when clicked on the pay button.
    async function displayRazorpayPaymentSdk() {
            const res = await loadRazorpayScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );

            if (!res) {
                alert("Razorpay SDK failed to load. Are you online?");
                return;
            }

            getDetails({
                url: `payment/create-order`,
                method: 'POST',
                body: {
                    "plan_token": item?.token
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (responseData) => {
                // Getting the order details back
                const { order_id } = responseData.data;
                const options = {
                    key: process.env.REACT_APP_S3_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
                    order_id: order_id,
                    handler: async function (response) {
                        postDetails({
                            url: `payment/success`,
                            method: 'POST',
                            body: {
                                "order_id": order_id,
                                "payment_id": response.razorpay_payment_id,
                                "payment_signature": response.razorpay_signature,
                            },
                            headers: {
                                Authorization: `Bearer ${getUserToken()}`
                            }
                        })
                    }
                };
    
                const paymentObject = new window.Razorpay(options);
                paymentObject.open();
            });
    }

    return (
        <>
            <ModalBox
                open={viewVideoUploadModal} 
                width={"880px"}
                modalContent={
                    <Preference from={"subscribe"}/>
                } 
            />
            <ModalBox
            open={viewDetailsModal} 
            width={"598px"}
            modalContent={
                <Success 
                    type={type} 
                    setViewVideoUploadModal={setViewVideoUploadModal}
                    setViewDetailsModal={setViewDetailsModal}
                />
            } 
            />
            <div className={style.boxLayout} style={{ backgroundColor }}>
                <div className={style.cardStyle}>
                    <h6>{item?.name} {item.duration} Months</h6>
                    <h2>
                        {item?.discount_amount}
                        <p className={style.price}>{item?.amount}</p>
                    </h2>
                    <p>(Incl. of all applicable taxes)</p>
                </div>
                <CustomButton
                    className={style.submitBtn}
                    sx={{ backgroundColor: '#EF7435' }}
                    onClick={displayRazorpayPaymentSdk}
                >
                    Subscribe
                </CustomButton>
            </div>
        </>
    );
}

export default SubscribeCard;