
import React, { useEffect, useState, useRef } from 'react';
import style from './BlockedProfiles.module.css'
import Image from '../../../../../ui/Image/Image';
import Button from '../../../../../ui/Button/Button';
import sortlistStarIcon from "../../../assets/svg/Matches/Shortlisted.svg";
import sortlistGrayStarIcon from "../../../assets/svg/Matches/ShortlistGray.svg";
import professionIcon from "../../../assets/svg/Matches/Profession Icon.svg";
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import { Circles } from 'react-loader-spinner';
import { getUserToken } from '../../../../../utils/utils';
import { useNavigate } from 'react-router-dom';
import ModalBox from "../../../../../ui/Modal/ModalBox";
import Success from './Modal/Success';
import { NoData } from '../../NoData/NoData';
import { MembershipTypeCard } from '../../MembershipTypeCard/MembershipTypeCard';

const BlockedProfiles = () => {
    const navigation = useNavigate("")
    const [currentPage, setCurrentPage] = useState(1);
    const [data, setData] = useState(null);
    const [lastPage, setLastPage] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(null)
    const [modal, setModal] = useState(false)
    const [textChange, setTextChange] = useState("")

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const { isLoading: unblockLoading, success: unblockSuccess, error: unblockError, sendRequest: unblockRquest } = useApiCall();

    const pageContentContainerRef = useRef(null);

    useEffect(() => {
        if (lastPage) {
            return;
        }
        getDetails({
            url: `my-account/blocked-profiles/read?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setLastPage(currentPage >= responseData.data.last_page);

            console.log('responseData', responseData);
            setTotal(responseData.data.total)
            // Initialize buttonText property for each item
            setData(responseData.data.data)

            setLoadingMore(false);
        });
    }, [currentPage, lastPage]);

    const reCallApi = () => {
        getDetails({
            url: `my-account/blocked-profiles/read?current_page=${currentPage}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setLastPage(currentPage >= responseData.data.last_page);

            console.log('responseData', responseData);
            setTotal(responseData.data.total)
            // Initialize buttonText property for each item
            setData(responseData.data.data)

            setLoadingMore(false);
        });
    }

    const handleUnBlack = (token) => {
        unblockRquest({
            url: `my-account/blocked-profiles/unblock`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setCurrentPage(0)
            setModal(true)
        });
    }



    const handleScroll = () => {
        const container = pageContentContainerRef.current;
        if (!container || loadingMore || lastPage) return; // Do nothing if loading, or last page reached.

        // Check if the user has scrolled to the bottom of the container
        if (container.scrollHeight - container.scrollTop === container.clientHeight) {
            // Load more data when the user reaches the bottom
            setLoadingMore(true);
            setCurrentPage((prevPage) => prevPage + 1);
        }
    };

    useEffect(() => {
        const container = pageContentContainerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    return (
        <>
            {error ? (
                <div className={style.loaderContainer}>
                    <p className={style.error}>{error}</p>
                </div>
            ) : !isLoading ? (
                <div className={style.pageContentContainer} ref={pageContentContainerRef}>
                    <p className={style.title}>Do not show Profiles</p>
                    {data !== null && data.length > 0 ? (
                        <div className={style.pageContent}>
                            {data?.map((item, index) => (
                                <div key={item.user_id} className={style.content}>
                                    <div className={style.imageAndDetailsContainer}>
                                        <div className={style.detailsContainer}>
                                            <Image

                                                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                className={style.profileImage}
                                            />
                                        </div>
                                        <div className={style.detailsContainer}>
                                            <div className={style.headerContainer}>
                                                <div className={style.nameContainer}>
                                                    <p className={style.id}>{item.user_id}</p>
                                                    <MembershipTypeCard type={item?.membership_type} />
                                                </div>
                                            </div>
                                            <h1 className={style.name}>{item.name}<span>({item.age} yrs)</span></h1>
                                            {item?.is_onboard_completed === 1 ? <>
                                                <div className={style.personalDetails}>
                                                    <p className={style.personaTtext}>{item.height}</p>
                                                    <p className={style.personaTtext}>{item.mother_tongue}</p>
                                                    <p className={style.personaTtext}>{item.community}</p>
                                                    <p className={style.personaTtext}>{item.cityname}</p>
                                                </div>
                                                <div className={style.personalDetails}>
                                                    <Image
                                                        src={professionIcon}
                                                    />
                                                    <p className={style.personaTtext}>{item.education}</p>
                                                    <p className={style.personaTtext}>{item.annual_income}</p>
                                                </div>
                                                <p className={style.aboutText}>About {item.name}</p>
                                                <p className={style.aboutDescription}>{item.about.length > 180 ? `${item.about.substring(0, 180)}...` : item.about}</p>
                                            </> : <div className='non-onboarded-user'> </div>}
                                            <div className={style.buttonsContianer}>
                                                <Button
                                                    className={`primaryButton`}
                                                    onClick={() => handleUnBlack(item.token)}
                                                >
                                                    {textChange === 'unblack' ? 'Profile Hidden' : 'Show Profile'}

                                                </Button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <NoData isChat={false} title={"No Hidden Profiles"} />
                    )}

                    {loadingMore && !lastPage && (
                        <div className={style.loaderContainer}>
                            <Circles
                                height="35"
                                width="35"
                                color="rgb(239, 116, 53)"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass={style.loader}
                                visible={true}
                            />
                        </div>
                    )}
                </div>
            ) : (
                <div className={style.loaderContainer}>
                    <Circles
                        height="35"
                        width="35"
                        color="rgb(239, 116, 53)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass={style.loader}
                        visible={true}
                    />
                </div>
            )}

            <ModalBox
                open={modal}
                width={"598px"}
                modalContent={
                    <Success
                        onClick={() => {
                            setModal(false)
                            reCallApi()
                        }}
                    />
                }
            />
        </>
    );
}

export default BlockedProfiles
