import React from 'react'
import style from "./FaqSection.module.css"
import Faq from "react-faq-component";
import plusIcon from "../../../assets/svg/LandingPage/Add Copy.svg"
import minusIcon from "../../../assets/svg/LandingPage/FAQ Subtract.svg"
import Image from '../../../../../ui/Image/Image';

export default function FaqSection() {
    const data = {
        rows: [
            {
                title: "How does Doctors Matrimony ensure the authenticity of user profiles?",
                content: `At Doctors Matrimony, we prioritize the security of our members. Our verification process includes an automatic check using an external API for professional details. In cases where auto-verification is inconclusive, users have the option to request manual verification, which is diligently handled by our admin team. This two-step process ensures the authenticity of profiles on our platform.`,
            },
            {
                title: "Can I maintain privacy on Doctors Matrimony?",
                content:
                    "Absolutely. We understand the importance of privacy for our members. Our platform provides robust privacy settings that allow you to control the visibility of your contact details, including phone number and email address. You have the flexibility to configure your profile visibility based on your preferences, ensuring a secure and confidential experience.",
            },
            {
                title: "How does the matchmaking algorithm work?",
                content: `Our advanced matchmaking algorithm considers a range of factors, including professional details, preferences, and other relevant criteria provided in your profile. This algorithm refines and suggests potential matches based on compatibility, ensuring that you receive personalized recommendations tailored to your unique requirements.`,
            },
            {
                title: "What features are available with a subscription, and how does it work?",
                content: "To access the full range of features, users can choose from our subscription plans. Our subscription plans offer various features and durations to suit your preferences. After selecting a membership directly from the homepage, users can enjoy an enhanced experience on Doctors Matrimony.",
            },{
                title: "How can I contact customer support if I need assistance?",
                content: `We are here to assist you. For any queries or assistance, you can access our comprehensive list of Frequently Asked Questions (FAQs) for quick references. Additionally, you can reach out to our Help Desk within the app or website for personalized support. Contact details for our customer support, including phone number, email, and postal address, can be found in the Customer Support section for further assistance.`,
            }
        ],
    };

    const config = {
        animate: true,
        tabFocus: true,
        expandIcon: <Image src={plusIcon} width={"20px"} height={"20px"}/>,
        collapseIcon: <Image src={minusIcon} width={"20px"} height={"20px"}/>,
    };
  return (
    <div className={style.faqContainer}>
        <h1 className={style.title}>Frequently-Asked Questions</h1>
        <Faq
            data={data}
            config={config}
        />
    </div>
  )
}
