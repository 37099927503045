import { useState, useCallback } from 'react';

const useApiHttp = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const AdminbearerToken = localStorage.getItem('authToken');
    const sendRequest = useCallback(async (requestConfig, applyData = null) => {
        setIsLoading(true);
        setSuccess(null);
        setError(null);
        try {
            const response = await fetch(requestConfig.url, {
                method: requestConfig.method ? requestConfig.method : 'GET',
                headers: AdminbearerToken
                    ? {
                        Authorization: `Bearer ${AdminbearerToken}`,
                        'Content-Type': 'application/json',
                    }
                    : {
                        'Content-Type': 'application/json',
                    },
                body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
            });

            const data = await response.json();

            if (data.status === 200 || data.status === 201) {
                setSuccess(data.message);
            }
            if (data.status !== 200 && data.status !== 201) {
                throw new Error(data.message);
            }

            applyData && applyData(data);
        } catch (err) {
            setError(err.message || 'Something went wrong!');
        }
        setIsLoading(false);
    }, [AdminbearerToken]);

    return {
        isLoading,
        success,
        error,
        sendRequest,
    };
};

export default useApiHttp;
