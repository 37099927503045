import React, { useEffect } from "react"
import style from "./BecameMemberModal.module.css"
import ModalBox from "../../../../ui/Modal/ModalBox"
import Button from "../../../../ui/Button/Button"
import Image from "../../../../ui/Image/Image"
import becameMemberIcon from "../../assets/common/becameMember.svg"
import { addScroll, avoidScroll } from "../../../../utils/utils"
import { useNavigate } from "react-router-dom"

export const BecameMemberModal = ({status, setInterestClicked}) => {

    const navigation = useNavigate();
    
    useEffect(()=> {
        avoidScroll()
    }, [])
    
    return(
        <ModalBox
            open={status}
            width={"609px"}
            modalContent={
            <div className={style.overallRegisterContainer}>
                <div className={`${style.registerSuccessContainer} ${style.overallSuccess}`}>
                <Image src={becameMemberIcon} className={style.registerSuccessIcon}/>
                <p className={style.registerSuccessTitle}>Please Upgrade Your Membership</p>
                <p className={style.registerSuccessDescription}>You have reached the limit for sending interests as a complimentary user. To send more interests, please upgrade your account.</p>
                <div className={style.registerOkayButtonContainer}>
                    <Button
                        style={{ width: "159px" }}
                        className={style.cancelButton}
                        onClick={()=> {
                            addScroll()
                            setInterestClicked(false)
                        }}
                        >
                        Cancel
                    </Button>
                    <Button
                    style={{ width: "159px"}}
                    className={style.upgradeButton}
                    onClick={()=> {
                        addScroll()
                        navigation("/subscribe")
                    }}
                    // loading={signUpLoading}
                    >
                    Upgrade Now
                    </Button>
                </div>
                </div>
            </div>
            }
        />
    )
}