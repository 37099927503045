import React, { useEffect, useState } from 'react'
import style from "./MatchesCount.module.css"
import Image from '../../../../../ui/Image/Image'
import useApiCall from '../../../hooks/useApiCall';
import elliteIcon from "../../../assets/svg/Home/elliteIcon.svg"
import locationIcon from "../../../assets/svg/Home/locationIcon.svg"
import educationIcon from "../../../assets/svg/Home/educationIcon.svg"
import professionIcon from "../../../assets/svg/Home/professionIcon.svg"
import starIcon from "../../../assets/svg/Home/starIcon.svg"
import citizenshipIcon from "../../../assets/svg/Home/citizenship.svg"
import { useNavigate } from 'react-router-dom';

export default function MatchesCount({details}) {

    const navigation = useNavigate()

    const [data, setData] = useState(null);
    const [ lookingForYouData, setLookingForYouData ] = useState(null)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    
    useEffect(() => {
        setData(details)
    }, [])

    const discoverMatchesImages = (type) => {
        switch(type) {
            case "Location":
                return locationIcon
            case "Citizenship":
                return citizenshipIcon
            case "Profession":
                return professionIcon
            case "Star":
                return starIcon
            default:
                return starIcon

        }
    }

    const handleRedirect = (item) => {
        if( item?.need_elite ) {
            localStorage.setItem('subscriptionFromHome', 1);
            navigation("/subscribe")
        } else {
            navigation("/discover_matches", { state: { name: item?.name, end_point: item?.end_point } })
        }
    }

    const buttonUi = (item) => {
        return(
            <>
                {
                    item?.need_elite ?
                        <div onClick={()=> handleRedirect(item)} className={`${style.button} ${item?.need_elite && style.elite}`}>
                            {item?.need_elite && <Image src={elliteIcon} />}
                            <p className={`${item?.need_elite ? style.eliteText : style.text }`}>{ item?.need_elite ? "Elite" : "View matches" }</p>
                        </div>
                    :
                        <div onClick={()=> handleRedirect(item)} className={`${style.button} ${style.premium}`}>
                            {/* <Image src={elliteIcon} /> */}
                            <p>View matches</p>
                        </div>
                }
            </>
        )
    }

  return (
    <div className={style.matchesCountsContainer}>
        <p className={style.titleText}>Discover matches based on</p>
        <div className={style.cardContainer}>
            {
                data?.map((item, index)=> (
                    <div key={`discover_match-${index}`} className={style.card}>
                        <Image src={discoverMatchesImages(item?.name)} />
                        <p className={style.title}>{item?.name}</p>
                        <p className={style.count}>{item?.count} Matches</p>
                        {buttonUi(item)}
                    </div>
                ))
            }
        </div>
    </div>
  )
}
