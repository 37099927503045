import React, { useEffect, useState } from 'react'
import style from './PaymentHistory.module.css'
import { useNavigate } from 'react-router-dom';
import Image from '../../../../../ui/Image/Image';
import upgradeimg from '../../../assets/MyAccount/Upgrade Now.svg'
import groupIfo from '../../../assets/common/GroupIfo.svg'
import download from '../../../assets/MyAccount/Download Invoice.svg'
import elituser from '../../../assets/common/elituser.svg'
import complimentryUser from "../../../assets/common/complimentryUser.svg";
import Button from '../../../../../ui/Button/Button'
import useApiCall from '../../../hooks/useApiCall';
import { getUserToken } from '../../../../../utils/utils';
import config from '../../../../../app.json';
import { Circles } from 'react-loader-spinner';

const PaymentHistory = () => {
    const navigate = useNavigate(); // Initialize useHistory
    const [allData, setAllData] = useState([])
    const [paymentHistory, setPaymentHistory] = useState([])
    const [activePlan, setActivePlan] = useState([])

    const { isLoading: isLoading, success: success, error: error, sendRequest: getMyprofile } = useApiCall();
    const { isLoading: isCreateOrderLoading, success: successCreateOrder, error: errorCreateOrder, sendRequest: createOrder } = useApiCall();
    const { isLoading: paymentLoading, success: paymentSuccess, error: paymentError, sendRequest: postDetails } = useApiCall();

    const getMyprofileApi = () => {
        getMyprofile(
            {
                url: "my-account/payment-history/read",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                setPaymentHistory(data.data.payment_history)
                setAllData(data.data)
                setActivePlan(data.data.active_plan)
            }
        );
    }

    useEffect(() => {
        getMyprofileApi()
    }, [paymentSuccess]);

    const subScriptionPage = () => {
        localStorage.setItem('subscriptionFromHome', 1);
        navigate("/subscribe")
    }

    const handleChagePlan = () => {
        localStorage.setItem('subscriptionFromHome', 2);
        navigate("/subscribe")
    }

    const handleRenew = () => {
        displayRazorpayPaymentSdk()
    }

    //Function to load razorpay script for the display of razorpay payment SDK.
    function loadRazorpayScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    //function will get called when clicked on the pay button.
    async function displayRazorpayPaymentSdk() {
        const res = await loadRazorpayScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );

        if (!res) {
            alert("Razorpay SDK failed to load. Are you online?");
            return;
        }

        createOrder({
            url: `my-account/payment-history/renew`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            console.log("responseData", responseData);
            // Getting the order details back
            const { order_id, key } = responseData.data;
            const options = {
                key: key, // Enter the Key ID generated from the Dashboard
                order_id: order_id,
                handler: async function (response) {
                    postDetails({
                        url: `payment/success`,
                        method: 'POST',
                        body: {
                            "order_id": order_id,
                            "payment_id": response.razorpay_payment_id,
                            "payment_signature": response.razorpay_signature,
                        },
                        headers: {
                            Authorization: `Bearer ${getUserToken()}`
                        }
                    })
                }
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        });
    }

    const handleDownloadInvoice = async(fileLink) => {
        let url = `${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${fileLink}`;
        const proxyUrl = url;
        const image = await fetch(proxyUrl)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)
    
        const link = document.createElement('a')
        link.href = imageURL
        link.download = fileLink
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // if (url) {
        //     fetch(url, {
        //         method: 'GET',
        //         headers: {
        //             'Content-Type': 'application/octet-stream',
        //         },
        //     })
        //         .then(response => response.blob())
        //         .then(blob => {
        //             const url = window.URL.createObjectURL(new Blob([blob]));
        //             const linkElement = document.createElement('a');
        //             linkElement.href = url;
        //             linkElement.setAttribute('download', fileLink);
        //             document.body.appendChild(linkElement);
        //             linkElement.click();
        //             document.body.removeChild(linkElement);
        //         })
        //         .catch(error => {
        //             console.error('Error downloading file:', error);
        //         });
        // }
    }

    return (
      <>
        {error ? (
          <div className={style.loaderContainer}>
            <p className={style.error}>{error}</p>
          </div>
        ) : !isLoading ? (
          <div className={style.pageContentContainer}>
            <div className={style.edit_cont}>
              <p className={style.title}>
                Payment History
                {allData?.expiry_alert || allData?.is_expired ? (
                  <span
                    onClick={() => {
                      localStorage.setItem("subscriptionFromHome", 3);
                      navigate("/subscribe");
                    }}
                    className={style.browseAllText}
                  >
                    Browse all
                  </span>
                ) : (
                  <></>
                )}
              </p>
            </div>
            <div className={style.purchase_history_texts}>
              {activePlan?.tag === "Complementry" && (
                <p
                  className={style.purchase_history_cont}
                  style={{ paddingBottom: 20 }}
                  >
                    Your Current Plan is <span className={style.premium_title}>Complementry</span> and it is Valid for {activePlan?.membership_duration}{" "}
                        months from {activePlan?.purchase_date} till{" "}
                        {activePlan?.expire_date}{" "}
                  </p>
              )}
              {allData?.membership_type === "Basic" && (
                <p
                  className={style.purchase_history_cont}
                  style={{ paddingBottom: 20 }}
                >
                  Your current plan is{" "}
                  <span className={style.premium_title}>Basic</span>. To enjoy
                  unlimited access please subscribe to any of our plans.
                </p>
              )}
              {allData?.is_expired ? (
                allData?.membership_type !== "Basic" && (
                  <div
                    className={`${style.purchase_history_card} ${style.light_red}`}
                  >
                    <p className={style.plan_expired}>
                      <span>
                        <Image src={groupIfo} />
                      </span>
                      <span>Plan Expired</span>
                    </p>
                    <p className={style.purchase_history_cont}>
                      To continue enjoying the benefits of our platform and
                      increasing your chances of finding the ideal match, we
                      encourage you to renew your subscription plan today.
                    </p>
                    <Button
                      onClick={handleRenew}
                      className={`primaryButton ${style.submitButton} ${style.p_5}`}
                    >
                      Renew Now
                    </Button>
                    <Button
                      onClick={handleChagePlan}
                      className={`primaryButton ${style.submitButton} ${style.p_5}`}
                    >
                      Change Plan
                    </Button>
                  </div>
                )
              ) : (
                <>
                  {allData?.membership_type === "Elite" &&
                    activePlan.tag !== "Complementry" && (
                      <p className={style.purchase_history_cont}>
                        Your Current Plan is{" "}
                        <span className={style.premium_title}>
                          {activePlan?.membership_name}
                        </span>{" "}
                        and it is Valid for {activePlan?.membership_duration}{" "}
                        months from {activePlan?.purchase_date} till{" "}
                        {activePlan?.expire_date}{" "}
                        {allData?.expiry_alert ? (
                          <p className={style.purchase_skye}>
                            {allData?.expiry_alert}
                          </p>
                        ) : (
                          <></>
                        )}
                      </p>
                    )}

                  {allData?.expiry_alert === "" ? (
                    ""
                  ) : (
                    <>
                      <div className={style.renewTextContainer}>
                        <div className={style.purchase_status_show}>
                          <p className={style.renewString}>
                            {allData?.renew_string}
                          </p>
                        </div>
                        <div className={style.purchase_status_show}>
                          <Button
                            onClick={handleRenew}
                            className={`primaryButton ${style.submitButton}`}
                          >
                            Renew Now
                          </Button>
                        </div>
                      </div>
                      <div className={style.changePlanContainer}>
                        <p className={style.renewString}>
                          If you wish to change your current plan,{" "}
                          <span
                            onClick={handleChagePlan}
                            className={style.changePlanText}
                          >
                            Click here
                          </span>
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
              {activePlan?.tag === "Complementry" ? (
                <div
                  className={`${style.purchase_history_elit_card} ${style.greenCard}`}
                >
                  <div className={`${style.elite_cord}`}>
                    <div className={style.elite_cord_letf}>
                      <p className={style.elite_cord_text}>
                        Enjoy our Elite service free for first 3 months! Connect
                        with verified profiles and explore exclusive launch
                        benefits.
                      </p>
                    </div>
                    <div className={style.elite_cord_right}>
                      <Image src={complimentryUser} />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {allData.membership_type === "Premium" ? (
                <div
                  className={`${style.purchase_history_elit_card} ${style.purbule}`}
                >
                  <div className={`${style.elite_cord}`}>
                    <div className={style.elite_cord_letf}>
                      <p className={style.elite_cord_text}>
                        Upgrade your plan to Elite Membership to unlock more
                        feature and get more matches
                      </p>
                      <p
                        className={`${style.wigite} ${style.upgrade}`}
                        onClick={subScriptionPage}
                      >
                        <Image src={upgradeimg} /> Upgrade Now
                      </p>
                    </div>
                    <div className={style.elite_cord_right}>
                      <Image src={elituser} />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {allData.membership_type === "Basic" ? (
                <div
                  className={`${style.purchase_history_elit_card} ${style.purbule}`}
                >
                  <div className={`${style.elite_cord}`}>
                    <div className={style.elite_cord_letf}>
                      <p className={style.elite_cord_text}>
                        Upgrade your plan to Elite Membership to unlock more
                        feature and get more matches
                      </p>
                      <p
                        className={`${style.wigite} ${style.upgrade}`}
                        onClick={subScriptionPage}
                      >
                        <Image src={upgradeimg} /> Upgrade Now
                      </p>
                    </div>
                    <div className={style.elite_cord_right}>
                      <Image src={elituser} />
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className={style.line}></div>

            {paymentHistory?.length > 0 && (
              <div className={style.purchase_history}>
                <p className={style.plan_title}>Purchase History</p>

                {paymentHistory?.length > 0 &&
                  paymentHistory.map((item, index) => (
                    <div className={style.plans_det} key={index}>
                      <div className={style.left}>
                        <p className={style.months}>{item.purchase_month}</p>
                        <p
                          className={style.title}
                        >{`${item.membership_type} ${item.membership_duration} Months`}</p>
                        <p
                          className={style.valid_title}
                        >{`Purchased Date : ${item.purchase_date}`}</p>
                      </div>
                      <div className={style.right}>
                        {item?.tag !== "Complementry" ? (
                          <>
                            <p className={style.title}>{`Rs.${item.amount}`}</p>
                            <p
                              className={style.invoice}
                              onClick={() => {
                                if (item.invoice !== "Unavailable") {
                                  handleDownloadInvoice(item.invoice);
                                }
                              }}
                            >
                              <Image src={download} />
                              {item.invoice !== "Unavailable"
                                ? "Download Invoice"
                                : "Unavailable"}
                            </p>
                          </>
                        ) : (
                          <p className={style.complimentryLabel}>{item?.tag}</p>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        ) : (
          <div className={style.loaderContainer}>
            <Circles
              height="35"
              width="35"
              color="rgb(239, 116, 53)"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass={style.loader}
              visible={true}
            />
          </div>
        )}
      </>
    );
}

export default PaymentHistory
