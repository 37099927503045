import React, { useEffect, useState } from 'react'
import style from  "./PartnerPreference.module.css"
import Image from '../../../../ui/Image/Image'
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import FamilyDetails from '../../features/BuildProfile/FamilyDetails/FamilyDetails'
import MedicalConditions from '../../features/BuildProfile/MedicalConditions/MedicalConditions'
import ReligiousDetails from '../../features/BuildProfile/ReligiousDetails/ReligiousDetails'
import { useNavigate } from 'react-router-dom'
import useAuthentication from '../../hooks/useAuthentication'
import Basic from '../../features/PartnerPreference/Basic/Basic'
import BasicPreference from '../../features/PartnerPreference/BasicPreference/BasicPreference'
import { BuildPartnerProfileContext } from '../../features/PartnerPreference/Context/BuildPartnerProfileContext'
import ProfessionalPreference from '../../features/PartnerPreference/ProfessionalPreference/ProfessionalPreference'
import ReligiousPreference from '../../features/PartnerPreference/ReligiousPreference/ReligiousPreference'
import { getUserToken } from '../../../../utils/utils'
import useApiCall from "../../hooks/useApiCall"

export default function PartnerPreference() {

  const navigation = useNavigate();

  const [ basicPreferenceData, setBasicPreferenceData ] = useState([])

  const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

  const { isAuthenticated } = useAuthentication();

  useEffect(() => {
    if (getUserToken() === null) {
      navigation("/")
    } else {
      getDetails({
        url: "auth/check-user",
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      }, (data) => {
        let { status, preference_status } = data.data
        if( status === "Preferencing" ) {
         setActiveStep( preference_status === 0 ? preference_status + 1 : preference_status )
        }
      });
    }
  }, []);
  
  const [activeStep, setActiveStep] = useState(1)
  
  // Create a function to render the component based on activeStep
  const renderComponentBasedOnStep = () => {
    switch (activeStep) {
      case 1:
        return <BasicPreference />;
      case 2:
        return <ProfessionalPreference />;
      case 3:
        return <ReligiousPreference />;
      default:
        return null; // Handle other cases as needed
    }
  };

  return (
    <BuildPartnerProfileContext.Provider value={{ activeStep, setActiveStep, basicPreferenceData, setBasicPreferenceData }}>
      <div className={style.buildProfileContainer}>
        <div className={style.logoContainer}>
          <Image 
            src={logoImage}
            className={style.logo}
          />
        </div>
        <div className={style.headerContainer}>
          <h2 className={style.headerTitle}>Partner Preference</h2>
          <p className={style.headerSubTitle}>Enter partner preference to find perfect match.</p>
        </div>
        {renderComponentBasedOnStep()}
      </div>
    </BuildPartnerProfileContext.Provider>
  )
}
