import React from 'react';
import style from './CustomerSupport.module.css';
import Call from '../../../assets/svg/ApplicationStatus/Call.svg';
import Mail from '../../../assets/svg/ApplicationStatus/Mail.svg';


const CustomerSupport = () => {
    return (
        <div className={style.VerificationContainer}>
            <div className={`${style.bannerSectionContainer} container`}>
                <div className={style.conductBox}>
                    <h4>Customer Support</h4>
                    <p>
                    Our friendly customer support team is available to assist 
                    you from Monday to Friday, 9:00 AM to 6:00 PM (GMT).
                    </p>
                    <div className={style.phoneEmailList}>
                        <h5><span><img src={Call} className={style.rejectAsset} alt='phoneNo' /></span>Phone: <strong className={style.rejectpara}>76583 64839</strong></h5>
                        <h5><span><img src={Mail} className={style.rejectAsset} alt='emailNo' /></span>Email: <strong className={style.rejectpara}>support@doctorsmatrimonyapp.com</strong></h5>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomerSupport;