import React, { useEffect, useState } from 'react'
import style from "./Homepage.module.css"
import Image from '../../../../ui/Image/Image'
import Button from '../../../../ui/Button/Button'
import CoupleInterviews from '../../features/ApplicationStatus/CoupleInterviews/CoupleInterviews'
import Stories from '../../features/ApplicationStatus/Stories/Stories'

import shortListImage from "../../assets/svg/Home/Shortlist.svg"
import verifyImage from "../../assets/svg/Home/verify.svg"
import emailIcon from "../../assets/common/email.svg"
import percentageImage from "../../assets/svg/Home/percentage.svg"
import bestmatchesImage from "../../assets/svg/Home/bestmatches.svg"
import sendIntrestImage from "../../assets/svg/Home/sendIntrest.svg"
import horoscodeMatchesImage from "../../assets/svg/Home/horoscodeMatches.svg"

import RecentActivity from '../../features/HomePage/RecentActivity/RecentActivity'
import AcceptedIntrest from '../../features/HomePage/AcceptedIntrest/AcceptedIntrest'
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';
import { Circles } from 'react-loader-spinner'
import { useLocation, useNavigate } from 'react-router-dom'
import { avoidScroll, getUserData, getUserToken } from '../../../../utils/utils'
import Counts from '../../features/HomePage/Counts/Counts'
import LookingForYou from '../../features/HomePage/LookingForYou/LookingForYou'
import NewlyJoined from '../../features/HomePage/NewlyJoined/NewlyJoined'
import BestMatches from '../../features/HomePage/BestMatches/BestMatches'
import MatchesCount from '../../features/HomePage/MatchesCount/MatchesCount'
import Shortlisted from '../../features/HomePage/Shortlisted/Shortlisted'
import Sidebar from '../../features/Chat/Sidebar/Sidebar'
import BuildPreference from '../../features/HomePage/BuildPreference/BuildPreference'
import ShortlistedBasic from '../../features/HomePage/ShortlistedBasic/ShortlistedBasic'
import { SubscribeWidget } from '../../features/SubscribeWidget/SubscribeWidget'
import { UpdateNow } from '../../features/HomePage/UpdateNow/UpdateNow'
import { BecameMemberModal } from '../../features/BecameMemberModal/BecameMemberModal'
import BuildPreferenceVideo from '../../features/HomePage/BuildPreferenceVideo/BuildPreferenceVideo'

export default function Homepage() {

    const navigation = useNavigate("")

    const location = useLocation("")

    const [data, setData] = useState(null);

    const [refresh, setRefresh] = useState(false);
    const [ showBecameMemberModal, setShowBecameMemberModal ] = useState(false)
    const [ interestClicked, setInterestClicked ] = useState(false)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    const { sendRequest: sendOtp } = useApiCall();

    const [sendOtpButton, setSendOtpButton] = useState("Get OTP")

    useEffect(()=> {
        console.log("location?.pathname", location?.pathname);
        setTimeout(() => {
            window.scrollTo(0,0);
        }, 100); // Adjust the delay as needed
    }, [location])

    useEffect(() => {
        setInterestClicked(false)
        if( getUserToken() ) {
            getDetails({
                url: `home-data`,
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (responseData) => {
                setData(responseData.data)
                if( responseData.data.limit_exceeded ) {
                    
                }
                setShowBecameMemberModal(responseData.data.limit_exceeded)
            });
        } else {
            
        }
    }, [refresh]);

    const handleSendOtp = () => {
        navigation("/myaccount")
        // if(  )
        // sendOtp({
        //     url: `auth/email-otp`,
        //     method: 'POST',
        //     headers: {
        //         Authorization: `Bearer ${getUserToken()}`
        //     }
        // }, (responseData) => {
        //     setSendOtpButton("Check")
        // });
    }

    return (
        <>
            {
                ( interestClicked && showBecameMemberModal ) ? (
                    <BecameMemberModal status={true} setInterestClicked={setInterestClicked}/>
                ) : <></>
            }
            {error ? (
                <div className={style.loaderContainer}>
                    <p className={style.error}>{error}</p>
                </div>
            ) : (
                <>
                    <div className={`container`}>
                        <div className={`${style.homepageContainer}`}>
                            <div className={style.profilesContainer}>
                                {
                                    data?.has_email_verification ? (
                                        <div className={style.emailVerificationContainer}>
                                            <div className={style.emailVerificationImageContainerContainer}>
                                                <Image src={emailIcon} />
                                                <div className={style.emailTitleContainer}>
                                                    <p className={style.title}>Verify Email</p>
                                                    <p className={style.description}>Your email needs to be verified for receiving regular alerts and updates. Please verify it</p>
                                                </div>
                                            </div>
                                            <p className={style.getOtpButton} onClick={handleSendOtp}>Check</p>
                                        </div>
                                    ) : <></>
                                }
                                {data?.matchCount.length ? <Counts details={data?.matchCount} /> : <> </>}
                                {
                                    getUserData()?.membership_type !== "Basic" && (
                                        <> 
                                            {
                                                data?.interest_received.length || data?.interest_accepted.length ?
                                                    <p className={style.titleText}>Recent Activity</p> : <></>
                                            }
                                            {
                                                data?.interest_received.length ?
                                                    <RecentActivity
                                                        refresh={refresh}
                                                        setRefresh={setRefresh}
                                                        details={data?.interest_received}
                                                        subscribed={data?.need_sub_for_best_matches}
                                                    /> : 
                                                <> </>
                                            }
                                            {data?.interest_accepted.length ? <AcceptedIntrest details={data?.interest_accepted} /> : <> </>}
                                            {data?.my_shortlist.length ? <Shortlisted refresh={refresh} setRefresh={setRefresh} details={data?.my_shortlist} /> : <> </>}
                                            {data?.best_matches.length ? <BestMatches setInterestClicked={setInterestClicked} limitExceeded={data?.limit_exceeded} refresh={refresh} setRefresh={setRefresh} details={data?.best_matches} /> : <> </>}
                                            {data?.newly_joined.length ?
                                                <NewlyJoined
                                                    details={data?.newly_joined}
                                                    subscribed={data?.need_sub_for_newly_joined}
                                                /> : <> </>}
                                            {data?.is_incomplete_elite ? <BuildPreference /> : <> </>}
                                            {data?.has_elite_video ? <BuildPreferenceVideo /> : <> </>}
                                            {!data?.is_incomplete_elite && data?.discover_match.length ? <MatchesCount details={data?.discover_match} /> : <> </>}
                                        </>
                                    )
                                }
                                {data?.looking_for_me.length ? <LookingForYou setInterestClicked={setInterestClicked} limitExceeded={data?.limit_exceeded} refresh={refresh} setRefresh={setRefresh} totalCount={data?.looking_for_me_count} details={data?.looking_for_me} /> : <> </>}
                                {
                                    getUserData()?.membership_type === "Basic" && (
                                        <SubscribeWidget height={0}/>
                                    )
                                }
                            </div>
                            {
                                getUserToken() && (<Sidebar />)
                            }
                        </div>
                    </div>
                    {
                        getUserData()?.membership_type === "Basic" && (
                            <div className={`container`}>
                                {data?.my_shortlist.length ? <ShortlistedBasic refresh={refresh} setRefresh={setRefresh} details={data?.my_shortlist} /> : <> </>}
                            </div>
                        )
                    }
                    {
                        getUserData()?.membership_type === "Basic" && (
                            <div className={`container`}>
                                <UpdateNow />
                            </div>
                        )
                    }           
                    {
                        data?.membership_type === "Basic" &&
                        <div className={`${style.membershipContainer}`}>
                            <div className={`${style.membership} container`}>
                                <p className={style.header}>Upgrade your membership to get more matches</p>
                                <div className={style.membershipDetailsContainer}>
                                    <div className={style.membershipDetail}>
                                        <Image
                                            src={verifyImage}
                                            className={style.image}
                                        />
                                        <p className={style.text}>You can view</p> <p className={style.text}><span>Verified Profiles</span></p>
                                    </div>
                                    <div className={style.membershipDetail}>
                                        <Image
                                            src={shortListImage}
                                            className={style.image}
                                        />
                                        <p className={style.text}>You can <span>shortlist more</span> profiles</p><p className={style.text}> you like the most</p>
                                    </div>
                                    <div className={style.membershipDetail}>
                                        <Image
                                            src={percentageImage}
                                            className={style.image}
                                        />
                                        <p className={style.text}>You can view the</p> <p className={style.text}><span>match percentage</span></p>
                                    </div>
                                    <div className={style.membershipDetail}>
                                        <Image
                                            src={sendIntrestImage}
                                            className={style.image}
                                        />
                                        <p className={style.text}>You can <span>send more interest</span> to the profiles</p><p className={style.text}> you are interested in</p>
                                    </div>
                                    <div className={style.membershipDetail}>
                                        <Image
                                            src={bestmatchesImage}
                                            className={style.image}
                                        />
                                        <p className={style.text}>You can view the profiles</p><p className={style.text}> of the <span>best matches</span></p>
                                    </div>
                                    <div className={style.membershipDetail}>
                                        <Image
                                            src={horoscodeMatchesImage}
                                            className={style.image}
                                        />
                                        <p className={style.text}>You can view</p><p className={style.text}><span>horoscope-based matches</span></p>
                                    </div>
                                </div>
                                <Button
                                    className={`primaryButton ${style.upgradeButton}`}
                                    onClick={() => {
                                        localStorage.setItem('subscriptionFromHome', 1);
                                        navigation("/subscribe")
                                    }}
                                >
                                    Upgrade to Elite
                                </Button>
                            </div>
                        </div>
                    }
                    <div className={`container`}>
                        <Stories title={true} label={false} />
                    </div>
                    <div className={style.coupleInterviewContainer}>
                        { getUserToken() && ( <CoupleInterviews /> ) }
                    </div>
                    <div className={`container ${style.storyAndCeleberation}`}>
                        <div className={style.storyContainer}>
                            <p className={style.storyTitle}>Share your success story & Inspire others to find their best match!</p>
                            <Button
                                className={`primaryButton ${style.storyButton}`}
                                onClick={() => navigation("/share_your_story")}
                            >
                                Share your story
                            </Button>
                        </div>
                        <div className={style.storyContainer}>
                            <p className={style.storyTitle}>Celebrate Your Match with Exclusive Gifts from Doctors Matrimony!</p>
                            <Button
                                className={`primaryButton ${style.storyButton}`}
                                onClick={() => navigation("/celeberate_match")}
                            >
                                Apply Now
                            </Button>
                        </div>
                    </div>
                </>
            )
            }
        </>
    )
}