import React, { useState } from 'react'
import style from "./Success.module.css"
import successIcon from "../../../assets/svg/Premium/Purchase Completed.svg"
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import { getUserData } from "../../../../../utils/utils";

export default function Success({ onClick }) {
  const { complimentary_period } = getUserData();
  return (
    <div className={style.successModalContainer}>
      <Image src={successIcon} />
      {complimentary_period ? (
        <>
          <p className={style.title}>Congratulations!</p>
          <p className={style.description}>
            Your Profile setup is completed. Begin your journey exploring our
            benefits.
          </p>
        </>
      ) : (
        <>
          <p className={style.title}>Profile Created Successfully</p>
          <p className={style.description}>
            Let's create your partner preferences so you can view your daily
            best matches.
          </p>
        </>
      )}
      <Button
        className={`primaryButton ${style.submitButton}`}
        onClick={onClick}
      >
        Okay
      </Button>
    </div>
  );
}
