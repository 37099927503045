import React, { useEffect, useState } from "react";
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import totalusers from "../../assets/SVG/Dashboard/TotalUsers.svg";
import activeusers from "../../assets/SVG/Dashboard/ActiveUsers.svg";
import premiumusers from "../../assets/SVG/Dashboard/PremiumUsers.svg";
import eliteusers from "../../assets/SVG/Dashboard/EliteUsers.svg";
import totalrevenue from "../../assets/SVG/Dashboard/TotalRevenue.svg";
import totalbasic from "../../assets/SVG/common/Black.svg";
import useApiHttp from "../../hooks/ues-http";
import { BASE_URL } from "../../utils/baseUrl";
import classes from "./Dshbord.module.css";
import Card from "../../UI/Card/Card";
import TopLoyout from "../../features/Dashboard/DashbordLoyout/TopLoyout";
import MediLoyout from "../../features/Dashboard/DashbordLoyout/MediLoyout";
import LastLoyout from "../../features/Dashboard/DashbordLoyout/LastLoyout";
import { AnimatePresence, motion } from "framer-motion";

const Dshbord = () => {
  const [userCount, setUserCount] = useState([]);

  const { sendRequest } = useApiHttp();

  const UserData = (data) => {
    setUserCount(data.data);
  };
  const fetchUserData = () => {
    sendRequest(
      {
        url: `${BASE_URL}count-data`,
        method: "POST",
      },
      UserData
    );
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  const cardData = [
    {
      src: totalusers,
      title: "Total Users",
      subTitle: userCount.totalUserCount,
      className: "card_box ornge",
    },
    {
      src: activeusers,
      title: "Active Users",
      subTitle: userCount.activeUserCount,
      className: "card_box skyeblue",
    },
    {
      src: totalbasic,
      title: "Basic Users",
      subTitle: userCount.basicCount,
      className: "card_box basic",
    },
    {
      src: premiumusers,
      title: "Premium Users",
      subTitle: userCount.premiumCount,
      className: "card_box yellow",
    },
    {
      src: eliteusers,
      title: "Elite Users",
      subTitle: userCount.eliteCount,
      className: "card_box purple",
    },
    {
      src: totalrevenue,
      title: "Total Revenue",
      subTitle: userCount.totalRevenue,
      className: "card_box gren",
    },
  ];

  return (
    <AnimatePresence mode="wait">
      <PagesHeader title="Dashboard" />
      <motion.div
        initial={{ opacity: 0 }} // Initial position off-screen to the left
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={classes.membership_payments_card}>
        {cardData.map((card, index) => (
          <motion.div
            initial={{ opacity: 0, x: -300 }} // Initial position off-screen to the left
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -300 }}
            transition={{
              duration: 0.8,
              delay: index * 0.2,
            }}
            key={index}>
            <Card
              src={card.src}
              title={card.title}
              subTitle={card.subTitle == undefined ? 0 : card.subTitle}
              className={card.className}
            />
          </motion.div>
        ))}
      </motion.div>
      <div>
        <TopLoyout />
        <MediLoyout />
        <LastLoyout />
      </div>
    </AnimatePresence>
  );
};

export default Dshbord;
