import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './BasicPreference.module.css';
import useApiCall from '../../../../../hooks/useApiCall';
import config from '../../../../../../../app.json';
import Button from '../../../../../../../ui/Button/Button';
import ProgressBar from '../../../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../../../ui/Image/Image';
import FloatingLabelDropdown from '../../../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserToken, isStringValid, uploadFile } from '../../../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import checkedIcon from "../../../../../assets/svg/Signup/checked.svg";
import uncheckedIcon from "../../../../../assets/svg/Signup/uncheck.svg";
import Success from '../Modal/Success';
import ModalBox from '../../../../../../../ui/Modal/ModalBox';
import { useNavigate } from 'react-router-dom';

export default function BasicPreference({familyDetailsError, handleShowSuccessModal, setViewDetailsModal}) {

  const navigation = useNavigate("")

  const { sendRequest: getMetaData } = useApiCall();

  const [ loader, setLoader ] = useState(false)

  const [ validationError, setValidationError ] = useState("")
  const [ viewSuccessDetailsModal, setViewSuccessDetailsModal ] = useState(false)

  const [ userMetaData, setUserMetaData ] = useState(null)

  const [formData, setFormData] = useState({
    age: [],
    height: [],
    motherTongue: [],
    citizenship: '',
    physicalStatus: '',
    maritalStatus: [],
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'preference/elite/read-meta',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        setUserMetaData(data.data);
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'my-account/preference/read',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const { age_detail, height_detail, mother_tongue_detail, citizenship, physical_status, marital_status_detail } = data.data.basic_detail
        setFormData({
          age: age_detail,
          height: height_detail,
          motherTongue: mother_tongue_detail,
          citizenship: citizenship,
          physicalStatus: physical_status,
          maritalStatus: marital_status_detail,
        })
      }
    );
  }, []);

  const handleInputChange = (event, inputType) => {
    console.log("event-inputType", inputType , event);
    if( event.target ) {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [inputType]: event,
      });
    }
  };

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  const extractToken = (data, isToken) => {
    let token = []
    data?.map(item=> {
      token.push( isToken ? item.token : item.value)
    })
    return token;
  }

  const extractName = (data) => {
    let token = []
    data?.map(item=> {
      token.push( item.name )
    })
    return token;
  }

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    useEffect(()=> {
      if (success) {
        handleShowSuccessModal()
      }
      if(error) {
        familyDetailsError(error)
      }
      setLoader(false)
    }, [success, error])

  const submitForm = async () => {
  
    // if (
    //   isStringValid(formData.age) &&
    //   isStringValid(formData.height) &&
    //   isStringValid(formData.motherTongue) &&
    //   isStringValid(formData.citizenship) &&
    //   formData.physicalStatus.length &&
    //   formData.maritalStatus.length
    // ) {
      setLoader(true)
      setValidationError("")

      console.log("formData.maritalStatus", formData.maritalStatus);

      const dataToSend = {
        "age": extractToken(formData.age, true),
        "height": extractToken(formData.height, true),
        "mother_tongue": extractToken(formData.motherTongue, true),
        "citizenship": formData.citizenship,
        "physical_status": formData.physicalStatus,
        "marital_status": extractName(formData.maritalStatus),
      };

      uploadUserDetails({
        url: "my-account/preference/update/basic-detail",
        method: 'POST',
        body: dataToSend,
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
    });
      setLoader(false)
    // } else {
    //   setValidationError("Some required fields are missing.")
    // }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (name, value) => {
    setFormData((prevFilterData) => {
      const currentFilter = prevFilterData[name] || [];
      const updatedFilter = currentFilter.includes(value)
        ? currentFilter.filter((item) => item !== value)
        : [...currentFilter, value];
      return {
        ...prevFilterData,
        [name]: updatedFilter,
      };
    });
  };

  const isCheckboxChecked = (name, value) => {
    const dataArray = formData[name];
    return dataArray && dataArray.indexOf(value) !== -1;
  };

  return (
    <>
      <ModalBox
        open={viewSuccessDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success
            title={"Basic Details updated successfully"} 
            description={""} 
            buttonText={"Okay"}
            onClick={()=>{
              addScroll()
              setViewSuccessDetailsModal(false)
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Basic Preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Age"
                name="age"
                responseType="token"
                options={userMetaData?.age} // Add options
                value={formData.age}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Mother Tongue"
                name="motherTongue"
                responseType="token"
                options={userMetaData?.mother_tongue} // Add options
                value={formData.motherTongue}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Marital Status"
                name="maritalStatus"
                responseType="value"
                options={userMetaData?.marital_status} // Add options
                value={formData.maritalStatus}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
            </div>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Height"
                name="height"
                responseType="token"
                options={userMetaData?.height} // Add options
                value={formData.height}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <FloatingLabelDropdown
                label="Citizenship"
                name="citizenship"
                responseType="token"
                options={userMetaData?.citizenship} // Add options
                value={formData.citizenship}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Physical Status</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('physicalStatus', userMetaData?.physical_status)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className={style.formContainer}>
          <p className={style.formTitle}>Basic Preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={`${style.field} ${style.marital}`}>
                <p className={style.fieldTitle}>Eating Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('eatingHabit', userMetaData?.eating_habit)}
                </div>
              </div>
              <div style={{ minWidth: "342px", marginTop: "26px" }} className={style.field}>
                <p className={style.fieldTitle}>Drinking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('drinkingHabit', userMetaData?.drinking_habit)}
                </div>
              </div>
            </div>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Smoking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('smokingHabit', userMetaData?.smoking_habit)}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
        <div className={style.footer}>
          <Button
            className={`${style.submitButton} primaryButton cancelButton`}
            onClick={() => {
              addScroll();
              setViewDetailsModal(false)
            }}
          >
            Cancel
          </Button>
          <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={() => {
              addScroll();
              submitForm()
            }}
            loading={isLoading}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </>
  );
}
