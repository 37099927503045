import "./globalUi/globalUi.css"
import Web from "./routes/Web";
import Admin from "./routes/Admin";
import { getFirebaseToken, onForegroundMessage, isMessagingSupported } from './utils/firebase';
import { useEffect, useState } from "react";
import useApiCall from './modules/web/hooks/useApiCall'
import config from "./app.json"
import { getUserToken } from "./utils/utils";
import addNotification from 'react-push-notification';
import ReactGA from 'react-ga4';

function App() {

  // const TRACKING_ID = "G-S20519WDE4"; // OUR_TRACKING_ID
  // ReactGA.initialize(TRACKING_ID);

  const pathname = window.location.pathname
  const loadAdmin = pathname.includes("/admin")

  const { isLoading: isLoginLoading, success: loginSuccess, error: loginError, sendRequest: sendDeviceToken } = useApiCall();

  useEffect(() => {
    if (!loadAdmin && getUserToken() && isMessagingSupported() ) {
      handleGetFirebaseToken()
    }
    if( !loadAdmin ) {
      import('./index.css')
    }
  }, [])

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        // console.log('Received foreground message: ', payload);
        const { notification: { title, body }, data: { redirectScreen, profileToken } } = payload;
        addNotification({
          title: title,
          subtitle: '',
          message: body,
          theme: 'darkblue',
          native: true, // when using native, your OS will handle theming.
          onClick: () => {
            localStorage.setItem("redirectToken", profileToken)
            if( redirectScreen === "UserProfileScreen" ) {
              window.location.href = "/profile_details"
            } else if( redirectScreen === "UserChatScreen" ) {
              window.location.href = "/chat"
            }
          }
        });
      })
      .catch(err => console.log('An error occured while retrieving foreground message. ', err));
  }, []);

  const handleGetFirebaseToken = () => {
    getFirebaseToken()
      .then((firebaseToken) => {
        if (firebaseToken) {
          sendDeviceToken({
            url: "auth/update-fcm",
            method: 'POST',
            body: {
              "device_type": 'Web',
              "device_token": firebaseToken
            },
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            }
          });
        }
      })
      .catch((err) => {
        sendDeviceToken({
          url: "auth/update-fcm",
          method: 'POST',
          body: {
            "device_type": null,
            "device_token": null
          },
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          }
        });
        console.error('An error occured while retrieving firebase token. ', err)
      })
  }

  return (
    <>
      {
        loadAdmin ? <Admin /> : <Web />
      }
    </>

  );
}

export default App;
