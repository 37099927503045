import React from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Dashboard from '../modules/admin/pages/Dashboard/Dashboard';
import Login from '../modules/admin/pages/Login/Login';
import ManageUsers from '../modules/admin/pages/ManageUsers/ManageUsers';
import MemberShip from '../modules/admin/pages/MemberShip/MemberShip';
import ManageGifts from '../modules/admin/pages/ManageGifts/ManageGifts';
import Reports from '../modules/admin/pages/Reports/Reports';
import ManageNotification from '../modules/admin/pages/ManageNotification/ManageNotification';
import UserSafety from '../modules/admin/pages/UserSafety/UserSafety';
import UserRole from '../modules/admin/pages/UserRoles/UserRoles'
import RootLayOut from '../modules/admin/layout/RootLayOut';
import Legal from '../modules/admin/pages/Legal/Legal';
import Forgot from '../modules/admin/pages/Login/Forgot';
import VerifiViewPage from '../modules/admin/pages/ManageUsers/VerifiViewPage';
import UsersViewPage from '../modules/admin/pages/ManageUsers/UsersViewPage';
import ErrorPage from '../modules/admin/UI/ErrorPage/ErrorPage';

const router = createBrowserRouter([
    {
        path: '/admin/',
        element: <Login />,
    },
    {
        path: '/admin/dashboard',
        element: <RootLayOut />,
        children: [
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'manageusers', element: <ManageUsers />, },
            // { path: 'verifiviewpage/:token', element: <VerifiViewPage /> },
            // { path: 'usersviewpage/:token', element: <UsersViewPage /> },
            { path: 'membership', element: <MemberShip /> },
            { path: 'managegifts', element: <ManageGifts /> },
            { path: 'reports', element: <Reports /> },
            { path: 'managenotification', element: <ManageNotification /> },
            { path: 'usersafety', element: <UserSafety /> },
            { path: 'userrole', element: <UserRole /> },
            { path: 'legal', element: <Legal /> },
        ],

    },
    {
        path: '/admin/forgot',
        element: <Forgot />,
    },
    {
        path: '*',
        element: <ErrorPage />,
    }
]);

export default function Admin() {
    return <RouterProvider router={router} />;
}

