import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './ShareStorySubmit.module.css';
import useApiCall from '../../hooks/useApiCall';
import config from '../../../../app.json';
import Button from '../../../../ui/Button/Button';
import Image from '../../../../ui/Image/Image';
import uploadIcon from '../../assets/common/Upload Image.svg';
import uploadWhiteIcon from '../../assets/common/upload-white.svg';
import backArrowIcon from '../../assets/common/Back Arrow.svg';
import searchImage from "../../assets/common/Search.svg"
import professionImage from "../../assets/common/Profession.svg"
import deleteIcon from '../../assets/common/Bin.svg';
import PopoutInput from '../../../../ui/PopoutInput/PopoutInput';
import PopoutDatePicker from '../../../../ui/PopoutDatePicker/PopoutDatePicker';
import FloatingLabelDropdown from '../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../assets/common/Radiobutton_on.svg"
import PopoutTextarea from '../../../../ui/PopoutTextarea/PopoutTextarea';
import { getUserToken, uploadFile, getUserData, defaultDate, formatMuiDate, isStringValid, avoidScroll, addScroll, formatYMDDate, formatDMYDate } from '../../../../utils/utils';
import dayjs, { Dayjs } from 'dayjs';
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import { useNavigate } from 'react-router-dom';
import FloatingDatePicker from '../../../../ui/FloatingDatePicker/FloatingDatePicker';
import ModalBox from '../../../../ui/Modal/ModalBox';
import Success from '../CeleberateMatch/Modal/Success';
import { Search } from "../../features/Header/Confirm/Search/Search";
import { MembershipTypeCard } from '../../features/MembershipTypeCard/MembershipTypeCard';
import { BlockReport } from '../../features/BlockReport/BlockReport';
import Stories from '../../features/CoupleStory/Stories/Stories';

export default function ShareStorySubmit() {

    const navigation = useNavigate();

  const { sendRequest: getMetaData } = useApiCall();
  const uploadVideoInput = useRef(null);

  const [searchValue, setSearchValue] = useState("")
  const [searchResult, setSearchResult] = useState("")
  const [viewSearchAlertModal, setViewSearchAlertModal] = useState(false)
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();
  const [uploadedImages, setUploadedImages] = useState([]);
  const uploadInputs = useRef([]);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFile, setVideoFile] = useState("test.mp4");

  const [ motherTongueData, setMotherTongueData ] = useState([])
  const [ heightData, setHeightData ] = useState([])
  const [ citizenshipData, setCitizenshipData ] = useState([])
  const [ loader, setLoader ] = useState(false)
  const [ viewDetailsModal, setViewDetailsModal ] = useState(false)
  const [ resubmit, setResubmit] = useState(0)

  const [ validationError, setValidationError ] = useState("")

  const [ statusData, setStatusData ] = useState("");

  const [ refresh, setRefresh ] = useState("");

  const [formData, setFormData] = useState({
    bride_name: "",
    matrimony_id: "",
    email: "",
    engagement_date: "",
    marriage_date: "",
    address: "",
    country_living_in: "",
    mobile: "",
    success_story: "",
    images: []
  });

  const [formDataError, setFormDataError] = useState({
    bride_name: "",
    matrimony_id: "",
    email: "",
    engagement_date: "",
    marriage_date: "",
    address: "",
    country_living_in: "",
    mobile: "",
    success_story: "",
    images: []
  });

  const { sendRequest: getDetails } = useApiCall();

  const getStatusDetails = () => {
    getDetails({
      url: `read-user/success-story`,
      method: 'GET',
      headers: {
          Authorization: `Bearer ${getUserToken()}`
      }
    }, (responseData) => {
      setValidationError("")
      const { user_name, partner_name, success_story, marriage_date, address, image, status, is_submitted, is_created, engagement_date, partner_id } = responseData?.data
      setResubmit(is_submitted)
      const data = {
        bride_name: user_name, 
        groom_name: partner_name,
        success_story: success_story,
        images: image,
        status: status,
        is_submitted: is_submitted,
        is_created: is_created
      }
      setImageFiles(image)
      setUploadedImages(image)
      setSearchValue(partner_id)
      setFormData({
        bride_name: user_name,
        matrimony_id: "",
        email: "",
        engagement_date: formatDMYDate(engagement_date),
        marriage_date: formatDMYDate(marriage_date),
        address: address,
        country_living_in: "",
        mobile: "",
        success_story: success_story,
        images: image
      })
      setStatusData(data)
    });
  }

  useEffect(()=> {
    getStatusDetails()
  }, [refresh])

  useEffect(() => {
    window.scrollTo(0,0)
    getMetaData(
      {
        url: 'country',
        method: 'POST',
      },
      (data) => {
        setCitizenshipData(data.data);
      }
    );
  }, []);

  const handleImageDelete = (index) => {
    const newImages = [...uploadedImages];
    newImages[index] = null;
    setUploadedImages(newImages);
  
    // Reset the file input
    if (uploadInputs.current[index]) {
      uploadInputs.current[index].value = null;
    }
  };

  const { isLoading: isSearchLoading, success: successSearch, error: errorSearch, sendRequest: uploadSearchDetails } = useApiCall();

  const searchPartner = () => {
    // Redirect to a new page or perform an action here
          // For redirection, you can use history.push
          // For action, you can call a function
        if( searchValue?.trim() === "" ) {
            setViewSearchAlertModal(true)
        } else {
          uploadSearchDetails({
            url: `profile-list/search-connected/${searchValue}`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
          }, (responseData) => {
            setSearchResult(responseData?.data?.data[0])
          });
        }
  }

  useEffect(()=> {
    if (searchValue) {
      searchPartner()
    }
  }, [searchValue])

  const handleKeyPress = (e) => {
      if (e.key === 'Enter' && searchValue) {
        searchPartner()
      }
  };
  
  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const newImages = [...uploadedImages];
      newImages[index] = URL.createObjectURL(file); // Store the data URL for preview
      setUploadedImages(newImages);

      const newImageFiles = [...imageFiles];
      newImageFiles[index] = file; // Store the File object
      setImageFiles(newImageFiles);
    }
  };

  const handleInputChange = (event) => {
    setIsOpen1(false)
    setIsOpen2(false)
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    if (success) {
      setRefresh(!refresh)
    }
    if (error !== '') {
      setValidationError(error)
    }
    setLoader(false)
  }, [success, error]);

  const submitForm = async () => {
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    };
  
    const { matrimony_id, engagement_date, marriage_date, address, success_story } = formData;
  
    const newFormDataError = {}; // Create a new object to store error messages
  
    if (!searchResult?.user_id) {
      newFormDataError.matrimony_id = "Please select the Matrimony ID";
    } else {
      newFormDataError.matrimony_id = "";
    }
  
    if (!marriage_date) {
      newFormDataError.marriage_date = "Please select the Marriage Date";
    } else {
      newFormDataError.marriage_date = "";
    }
  
    if (!engagement_date) {
      newFormDataError.engagement_date = "Please select the Engagement Date";
    } else {
      newFormDataError.engagement_date = "";
    }
  
    if (!address) {
      newFormDataError.address = "Please enter the Address";
    } else {
      newFormDataError.address = "";
    }
  
    if (!success_story) {
      newFormDataError.success_story = "Please enter the Success Story";
    } else {
      newFormDataError.success_story = "";
    }
  
    if (!imageFiles.length) {
      newFormDataError.images = "Please upload images";
    } else {
      newFormDataError.images = "";
    }
  
    // Update the error messages for each input field
    setFormDataError({
      ...formDataError,
      ...newFormDataError,
    });
  
    // Check if there are any error messages
    const hasErrors = Object.values(newFormDataError).some((message) => message !== "");
  
    if (!hasErrors) {
      // If there are no errors, proceed to submit the form
      setLoader(true);

      // Upload all image files to S3 in parallel
      const uploadPromises = imageFiles.map(async (imageFile) => {
        if (imageFile && typeof imageFile ===  "object") {
          // Replace 'uploadFile' with your actual S3 upload function
          return await uploadFile(imageFile);
        }
        return imageFile;
      });

      const images = await Promise.all(uploadPromises);

      let uploadedImageNotNull = images.filter(item => item !== undefined);
      
      uploadUserDetails({
        url: ( statusData?.status === "Declined" && statusData?.is_created ) ? "success-story/update" : "success-story/create",
        method: 'POST',
        body: {
          "partner_user_id" : searchResult?.user_id,
          "engagement_date" : formatYMDDate(engagement_date),
          "marriage_date" : formatYMDDate(marriage_date),
          "address" : address.trim(),
          "success_story" : success_story.trim(),
          "images" : uploadedImageNotNull
        },
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      });
    }
  };

  const uploadElements = [];

  for (let i = 0; i < 3; i++) {
    uploadElements.push(
      <>
        {uploadedImages[i] ? (
          <div
            key={i}
            className={style.uploadedImage}
            style={{
              backgroundImage: `url(${uploadedImages[i]?.includes("blob:") ? uploadedImages[i] : process.env.REACT_APP_S3_CLOUD_FRONT_URL + uploadedImages[i]})`,
            }}
          >
            <div className={style.deleteContainer}>
              <Image src={deleteIcon} onClick={() => handleImageDelete(i)} />
            </div>
            <div
              className={style.reuploadContainer}
              onClick={() => uploadInputs.current[i].click()}
            >
              <Image src={uploadWhiteIcon} />
              <p className={style.reuploadLabeText}>Reupload</p>
            </div>
          </div>
        ) : (
          <div
            key={i}
            className={style.upload}
            onClick={() => uploadInputs.current[i].click()}
          >
            <label className={style.uploadImageLabel}>
              <div className={style.uploadImage}>
                <img
                  src={uploadIcon}
                  className={style.uploadLogo}
                  alt="Upload Icon"
                />
              </div>
            </label>
            <p className={style.uploadLabel}>Upload Image</p>
          </div>
        )}
        <input
          type="file"
          accept="image/*"
          onChange={(event) => handleImageUpload(event, i)}
          className={style.uploadInput}
          ref={(el) => (uploadInputs.current[i] = el)} // Assign the ref to the input element
        />
      </>
    );
  }

  return (
    <div className={style.buildProfileContainer}>
        <ModalBox
          open={viewSearchAlertModal}
          width={"500px"}
          modalContent={
              <Search
                  onClick={() => {
                      setViewSearchAlertModal(false)
                  }}
              />
          }
        />
        <ModalBox
          open={viewDetailsModal} 
          width={"598px"} 
          modalContent={
            <Success
              title={"Submitted Successfully"} 
              // description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
              buttonText={"Okay"}
              onClick={()=>{
                setUploadedImages([])
                setFormData({
                  bride_name: "",
                  matrimony_id: "",
                  email: "",
                  engagement_date: "",
                  marriage_date: "",
                  address: "",
                  country_living_in: "",
                  mobile: "",
                  success_story: "",
                  images: []
                })
                addScroll()
                setViewDetailsModal(false)
                navigation("/home")
              }}
            />
          } 
        />
        <div className={style.logoContainer}>
          <Image 
            src={logoImage}
            className={style.logo}
          />
        </div>
        {
          resubmit ? (
            <div className={`${style.workDetailsContainer}`}>
              <div className={style.formContainer}>
                  <div className={style.titleBackArrowContainer}>
                    <Image src={backArrowIcon} onClick={()=> navigation(-1)} className={style.backArrowIcon}/>
                    <div className={style.titleLoginContainer}>
                      <p className={style.formTitleSubmited}>Dear {statusData?.bride_name},</p>
                      <p className={style.descriptionSubmited}>Thank you for sharing your Story with us!</p>
                      <p className={style.subDescriptionSubmited}>Your success story is invaluable to us, and we're eager to share it with our esteemed Doctors Matrimony team. Please allow us a brief moment as we ensure that your inspiring journey gets the spotlight it deserves. Stay tuned for the grand reveal of your verified success story – because your triumph is meant to shine brightly!</p>
                    </div>
                  </div>
                  {
                    ( statusData?.is_created && statusData?.status === "Declined" ) && (
                      <div className={style.storyRejectContentContainer}>
                        <div className={style.storyRejectContentHeader}>
                          <p className={style.storyHeader}>Success Story Declined</p>
                          <p className={style.storyDescription}>While we value the success stories of our users, we also prioritize privacy and confidentiality. Upon review, we found that your submission contained sensitive information that may compromise your privacy or that of others involved.</p>
                        </div>
                        <p className={style.resubmitButton} onClick={()=> setResubmit(0)}>Re-Submit Now</p>
                      </div>
                    )
                  }

                  <div className={style.searchResultContainer}>
                    <Stories  title={false} label={true}  data={[statusData]}/>
                  </div>
              </div>
          </div>
          ) :
          <div className={`${style.workDetailsContainer}`}>
              <div className={style.formContainer}>
                  <div className={style.titleBackArrowContainer}>
                    <Image src={backArrowIcon}  onClick={()=> navigation(-1)} className={style.backArrowIcon}/>
                    <div className={style.titleLoginContainer}>
                      <p className={style.formTitle}>Share your success story & Inspire others to find their best match!</p>
                      <p className={style.description}>Search profiles by ID, fill your details, and unveil tales that inspire. Become a beacon of love – illuminate others!</p>
                    </div>
                  </div>
                  <div className={style.searchContentsContainer}>
                    <div className={style.searchContainer}>
                      <Image
                          className={style.searchImage}
                          src={searchImage}
                      />
                      <input
                          value={searchValue}
                          onChange={(e) => {
                            console.log("e.target.value", e.target.value);
                            setSearchValue(e.target.value)
                          }}
                          onKeyPress={handleKeyPress}
                          className={style.searchField}
                          type={"text"}
                          placeholder='Search Matrimony ID…'
                      />
                    </div>
                  </div>
                  <div className={style.searchResultContainer}>
                    {
                      searchResult ?
                        <div className={style.profileDetailsContainer}>
                          <div key={`looking_for_me`} className={style.data}> 
                            <Image
                                onClick={()=> navigation("/profile_details", { state: { token: searchResult.token } })}
                                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+searchResult?.image}
                                className={style.profileImage}
                            />
                            <div className={style.detailsContainer}>
                                <div className={style.idContainer}>
                                    <div className={style.idTypeContainer}>
                                        <p   onClick={()=> navigation("/profile_details", { state: { token: searchResult.token } })} className={style.id}>{searchResult.user_id}</p>
                                    </div>
                                </div>
                                <p onClick={()=> navigation("/profile_details", { state: { token: searchResult.token } })} className={style.name}>{searchResult.name}<span className={style.age}>({searchResult.age}yrs)</span></p>
                                <div className={style.moreDetailsContainer}>
                                    <Image src={professionImage} />
                                    <p className={style.detail}>{searchResult.height}</p>
                                    <p className={style.detail}>{searchResult.mother_tongue}</p>
                                    <p className={style.detail}>{searchResult.community}</p>
                                    <p className={style.detail}>{searchResult.city}</p>
                                </div>
                                <div className={style.workDetailsContainer}>
                                    <p className={style.work}>{searchResult.occupation}</p>
                                </div>
                            </div>
                          </div>  
                        </div>
                      :
                        <p className={style.noResultText}>No result found.</p>
                    }
                  </div>
                  {
                    searchResult && (
                      <div className={style.hobbiesContainer}>
                        <div className={style.hobbieContainer}>
                            <FloatingDatePicker
                              isOpen={isOpen1}
                              setIsOpen={setIsOpen1}
                              fromCurrentYear={true}
                              selectedDate={formData.marriage_date}
                              onChange={(e) => setFormData({...formData, marriage_date: e})}
                              label={"Marriage Date*"}
                              customStyle={{
                                paddingBottom: formDataError.marriage_date ? "0px" : '26px',
                                marginBottom:  formDataError.marriage_date ? "10px" : '0px',
                              }}
                            />
                            { 
                              formDataError.marriage_date ? 
                              <p 
                                className="error"
                                style={{
                                  marginBottom:  formDataError.marriage_date ? "10px" : '0px',
                                  paddingBottom: formDataError.marriage_date ? "10px" : '0px',
                                }}
                              >{formDataError.marriage_date}
                              </p> 
                              : <></> 
                            }
                        </div>
                        <div className={style.hobbieContainer}>
                            <FloatingDatePicker
                              isOpen={isOpen2}
                              setIsOpen={setIsOpen2}
                              fromCurrentYear={true}
                              selectedDate={formData.engagement_date}
                              onChange={(e) => setFormData({...formData, engagement_date: e})}
                              label={"Engagement Date"}
                              customStyle={{
                                paddingBottom: formDataError.engagement_date ? "0px" : '26px',
                                marginBottom:  formDataError.engagement_date ? "10px" : '0px',
                              }}
                            />
                            { 
                              formDataError.engagement_date ? 
                              <p 
                                className="error"
                                style={{
                                  paddingBottom: formDataError.engagement_date ? "10px" : '26px',
                                  marginBottom:  formDataError.engagement_date ? "10px" : '0px',
                                }}
                              >{formDataError.engagement_date}
                              </p> 
                              : <></> 
                            }
                        </div>
                      </div>
                    )
                  }
              </div>
              {
                  searchResult && (
                    <div className={style.storyContainer}>
                        <PopoutTextarea 
                            characterLimit={255}
                            label="Address*"
                            name="address"
                            className={style.moreAbout}
                            value={formData.address}
                            onChange={handleInputChange}
                        />
                        { 
                          formDataError.address ? 
                          <p 
                            className="error"
                            style={{
                              paddingBottom: formDataError.address ? "10px" : '26px',
                              marginBottom:  formDataError.address ? "10px" : '0px',
                            }}
                          >{formDataError.address}
                          </p> 
                          : <></> 
                        }
                        <PopoutTextarea 
                            characterLimit={255}
                            label="Success Story*"
                            name="success_story"
                            className={style.moreAbout}
                            value={formData.success_story}
                            onChange={handleInputChange}
                        />
                        { 
                          formDataError.success_story ? 
                          <p 
                            className="error"
                            style={{
                              paddingBottom: formDataError.success_story ? "10px" : '26px',
                              marginBottom:  formDataError.success_story ? "10px" : '0px',
                            }}
                          >{formDataError.success_story}
                          </p> 
                          : <></> 
                        }
                        <div className={style.form}>
                            <p className={style.formTitle}>Upload Photo</p>
                            <div className={style.uploadContainer}>{uploadElements}</div>
                        </div>
                        { 
                          formDataError.images ? 
                          <p 
                            className="error"
                            style={{
                              paddingBottom: formDataError.images ? "0px" : '26px',
                              marginBottom:  formDataError.images ? "10px" : '0px',
                            }}
                          >{formDataError.images}
                          </p> 
                          : <></> 
                        }
                    </div>
                  )
              }
              {
                searchResult && (
                  <>
                    {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
                    <Button
                        className={`primaryButton ${style.submitButton}`}
                        onClick={submitForm}
                        loading={isLoading || loader}
                    >
                        Submit
                    </Button>
                  </>
              )}
          </div>
        }
      </div>
  );
}