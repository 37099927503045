import React, { useState, useEffect } from 'react'
import classes from './LastLoyout.module.css'
import Table from "../../../UI/Table/DashbordTable";
import Elite from '../../../assets/SVG/Manage Users/Elite.svg'
import Premium from '../../../assets/SVG/Manage Users/Premium.svg'
import basic from '../../../assets/SVG/common/white.svg'
import Slecter from '../../../UI/Dashboardui/Slecters'
import useApiHttp from "../../../hooks/ues-http";
import { BASE_URL } from "../../../utils/baseUrl";
import ReusableModal from '../../../UI/Modal/ScrollingModal/ScrollingModal';

const LastLoyout = () => {
    const [selectedYear, setSelectedYear] = useState("");
    const [tableRegistrations, setTableRegistrations] = useState([]);
    const [userIdModal, setUserIdModal] = useState(false);
    const [userIdSate, setUserIdSate] = useState([]);

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const { isLoading: columIsLoading, sendRequest: culumRequest } = useApiHttp();
    const culumData = (data) => {
        setTableRegistrations(data.data)
    }

    const fetchCulumData = () => {
        const requestData = {
            latest_user_year: `${selectedYear}`,
        };
        culumRequest({
            url: `${BASE_URL}latest-user`,
            method: 'POST',
            body: requestData,
        }, culumData);
    }

    useEffect(() => {
        fetchCulumData()
    }, [selectedYear])

    const { sendRequest: UserIdRequest } = useApiHttp();
    const UserIdData = (data) => {
        setUserIdSate(data.data)
    };
    const UserIdRequestData = (token) => {

        if (token === null) {
            setUserIdModal(false)
        } else {
            setUserIdModal(true)
        }
        UserIdRequest({
            url: `${BASE_URL}basic-user-detail`,
            method: 'POST',
            body: {
                "token": token
            }
        }, UserIdData);
    };

    const viewUserIdModal = (data) => {
        UserIdRequestData(data)
    }


    const columns = [
        {
            name: "S.No",
            selector: (row) => row.slno,
        },
        {
            name: "User ID",
            selector: (row) => (<><span className='action_text action_text_pointer' onClick={() => viewUserIdModal(row.token)}>{row.user_id}</span></>),
        },
        {
            name: "User Name",
            selector: (row) => row.user_name,
        },
        {
            name: "Contact Number",
            selector: (row) => row.contact_number,
        },
        {
            name: "Gender",
            selector: (row) => row.gender,
        },
        {
            name: "Education",
            selector: (row) => row.education,
        },
        {
            name: "Location",
            selector: (row) => row.location,
        },
        {
            name: "License Number",
            selector: (row) => row.imr_number,
        },
        {
            name: "Registered Date",
            selector: (row) => row.reg_date,
        },
        {
            name: "Member Type",
            cell: (row) => (<> {row.member_type === 'Elite' && (<span className="wigite_lable elite"><img src={Elite} alt="Elite" />{row.member_type}</span>)} {row.member_type === 'Premium' && (<span className="wigite_lable premium"><img src={Premium} alt='Premium' />{row.member_type}</span>)} {row.member_type === 'Basic' && (<span className="wigite_lable basic"><img src={basic} width={"16px"} alt='Premium' />{row.member_type}</span>)}</>),
        },
    ];

    return (
        <>
            <div className={classes.toployout_container}>
                <div className={classes.table_section}>
                    <div className={classes.titleContainer}>
                        <p className={classes.title}>Latest User Registrations</p>

                        <div className={classes.dropdownContainer}>
                            <div className={classes.dropdown}>
                                <Slecter name="year" options={tableRegistrations.dropdown} selectedValue={selectedYear} handleChange={handleYearChange} />
                            </div>
                        </div>

                    </div>
                    <Table data={tableRegistrations.latest_users} columns={columns} loader={columIsLoading} />
                </div>
            </div>
            <ReusableModal open={userIdModal} setOpen={setUserIdModal} titel="User ID" type="view" okay="ok" scrollType="body" size="sm">

                <>
                    <div className={classes.feedback_container}>
                        <div className={classes.profile_image}>
                            <img src={userIdSate.image} alt="userIdSate" />
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.contant_box_titel}>User ID</p>
                                <p className={classes.contant_box_sub}>{userIdSate.user_id}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>User Name</p>
                                <p className={classes.contant_box_sub}>{userIdSate.user_name}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>NMC or SMC number Number</p>
                                <p className={classes.contant_box_sub}>{userIdSate.imr_number}</p>
                            </div>
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.contant_box_titel}>Gender</p>
                                <p className={classes.contant_box_sub}>{userIdSate.gender}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Mobile</p>
                                <p className={classes.contant_box_sub}>{userIdSate.mobile_number}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Email</p>
                                <p className={classes.contant_box_sub}>{userIdSate.email}</p>
                            </div>
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.contant_box_titel}>Age</p>
                                <p className={classes.contant_box_sub}>{userIdSate.age}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Education</p>
                                <p className={classes.contant_box_sub}>{userIdSate.education}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Membership Type</p>
                                <p className={classes.contant_box_sub}>{userIdSate.membership_type}</p>
                            </div>
                        </div>
                        <div className={classes.module__text_Container}>
                            <div>
                                <p className={classes.contant_box_titel}>Institution Working</p>
                                <p className={classes.contant_box_sub}>{userIdSate.institution_working}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>City</p>
                                <p className={classes.contant_box_sub}>{userIdSate.city}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>State</p>
                                <p className={classes.contant_box_sub}>{userIdSate.state}</p>
                            </div>
                            <div>
                                <p className={classes.contant_box_titel}>Country</p>
                                <p className={classes.contant_box_sub}>{userIdSate.country}</p>
                            </div>
                        </div>

                        <div>
                            <p className={classes.contant_box_titel}>About</p>
                            <p className={classes.contant_box_sub}>{userIdSate.abount}</p>
                        </div>
                    </div>
                </>
            </ReusableModal>

        </>
    )
}

export default LastLoyout