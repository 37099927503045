import React, { useContext, useEffect, useState } from 'react';
import style from './MedicalConditions.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import Button from '../../../../../ui/Button/Button';
import ModalBox from '../../../../../ui/Modal/ModalBox';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../ui/Image/Image';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import {
  addScroll,
  avoidScroll,
  formatYMDDate,
  getUserData,
  getUserToken,
} from "../../../../../utils/utils";
import { BuildProfileContext } from "../Context/BuildProfileContext";
import Success from "../Modal/Success";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Preference from "../../SubscribePage/SubscribeCard/ModalPreference/Preference";
import {
  collection,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import initializeFirebase from "../../../pages/Chat/Db/FirebaseDb";

export default function MedicalConditions() {
  const navigation = useNavigate();
  const { db } = initializeFirebase();
  const userTokenData = getUserData();
  const userToken = userTokenData.user_token;
  const userName = userTokenData.name;
  const userProfileId = userTokenData.user_id;
  const userImage = userTokenData.image;

  const {
    isLoading: isLoading,
    success: successUpload,
    error: error,
    sendRequest: uploadUserDetails,
  } = useApiCall();

  const { setActiveStep } = useContext(BuildProfileContext);

  const { sendRequest: getMedicalConditionsData } = useApiCall();

  const { sendRequest: checkUserData } = useApiCall();

  const [conditions, setConditions] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const [userData, setUserData] = useState([]);

  const [preferenceModal, setViewPreferenceModal] = useState(false);

  const { success: getUserDataSuccess, sendRequest: getUserDatas } =
    useApiCall();

  const [validationError, setValidationError] = useState("");
  const [viewDetailsModal, setViewDetailsModal] = useState(false);

  useEffect(() => {
    getMedicalConditionsData(
      {
        url: "onboard/read-meta-data",
        method: "GET",
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      },
      (data) => {
        let answers = [...data.data.medical_question];
        getUserDatas(
          {
            url: "onboard/read-step-5",
            method: "GET",
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          },
          (data1) => {
            console.log("data?.data", data?.data?.medical_question);
            console.log("answers", answers);
            if (data1?.data?.length > 0) {
              let readData = [...answers];
              let conditionsData = [...data?.data?.medical_question];
              readData.length > 0 &&
                readData?.map((readItem, index) => {
                  conditionsData[index].answer = readItem?.answer;
                });
              setConditions(conditionsData);
            } else {
              let conditionsData = [...data?.data?.medical_question];
              conditionsData?.map((readItem, index) => {
                conditionsData[index].answer = "";
              });
              setConditions(conditionsData);
            }
          }
        );
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleAnswerChange = (index, answer) => {
    let answers = [...conditions];
    answers[index].answer = answer;
    setConditions(answers);
  };

  useEffect(() => {
    if (successUpload) {
      if (getUserToken()) {
        checkUserData(
          {
            url: "auth/check-user",
            method: "GET",
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          },
          (data) => {
            const { name, image, user_id, user_token, gender } = data?.data;
            const checkUserQuery = doc(db, "Users", user_token);
            getDoc(checkUserQuery).then((userData) => {
              if (userData.exists()) {
                updateDoc(checkUserQuery, {
                  isOnline: true,
                }).then(() => {
                  avoidScroll();
                  setViewDetailsModal(true);
                });
              } else {
                setDoc(checkUserQuery, {
                  image: image,
                  profile_id: user_id,
                  name: name,
                  blockList: [],
                  isOnline: true,
                  isDeleted: false,
                }).then(() => {
                  avoidScroll();
                  setViewDetailsModal(true);
                });
              }
            });
          }
        );
      }
    }
    if (error !== "") {
      toast.error(error, {
        autoClose: 2500,
      });
    }
  }, [successUpload, error]);

  const handleSubmit = () => {
    let conditionsData = [];
    let valueFiled = true;
    conditions.map((item) => {
      if (item.answer === "") {
        valueFiled = false;
      }
      conditionsData.push({
        question_token: item.token,
        answer: item.answer,
      });
    });

    const data = {
      medical_question: conditionsData,
    };
    if (valueFiled) {
      uploadUserDetails({
        url: "onboard/save-step-5",
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${getUserToken()}`,
        },
      });
    } else {
      toast.error("Please fill all the medical questions", {
        autoClose: 2500,
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <ModalBox
        open={viewDetailsModal}
        width={"598px"}
        modalContent={
          <Success
            onClick={() => {
              setViewDetailsModal(false);
              if (userTokenData?.membership_type === "Premium") {
                addScroll();
                navigation("/home");
              } else {
                setViewPreferenceModal(true);
              }
            }}
          />
        }
      />
      <ModalBox
        open={preferenceModal}
        width={"880px"}
        modalContent={<Preference from={"medical"} />}
      />
      <div className={style.workDetailsContainer}>
        <ProgressBar width={`${16.66 * 5}%`} total={5} current={5} />
        <div className={style.formContainer}>
          <p className={style.formTitle}>Medical Conditions</p>
          <p className={style.formDescription}>
            We understand the sensitive nature of medical information and assure
            you that all the information provided will be kept strictly
            confidential. This information will only be shared with your
            potential partner if and when both parties express mutual interest
            in moving forward.
          </p>
          <div className={style.conditionsContainer}>
            {conditions?.map((item, index) => (
              <div className={style.condition} key={index}>
                <p className={style.questionTitle}>
                  <span>{index + 1}.</span>
                  {item.question}
                </p>
                <div className={style.answers}>
                  <div
                    className={style.answer}
                    onClick={() => handleAnswerChange(index, "No")}
                  >
                    <Image
                      src={
                        item.answer === "No" ? radioSelected : radioUnselected
                      }
                    />
                    <p>No</p>
                  </div>
                  <div
                    className={style.answer}
                    onClick={() => handleAnswerChange(index, "Yes")}
                  >
                    <Image
                      src={
                        item.answer === "Yes" ? radioSelected : radioUnselected
                      }
                    />
                    <p>Yes</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={style.buttonFooterContainer}>
          <Button
            className={`primaryButton ${style.cancelButton}`}
            onClick={() => setActiveStep(4)}
          >
            Back
          </Button>
          <Button
            className={`primaryButton ${style.submitButton}`}
            loading={isLoading}
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
}
