export const getUserData = () => {
    return localStorage.getItem('authToken');
}

export const removeUserData = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("manageTabIndex");
    localStorage.removeItem("userTabIndex");
    localStorage.removeItem("page");
    localStorage.removeItem("newPerPage");
}