import React, { useContext } from 'react'
import style from './FamilyDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function FamilyDetails() {
  const { data } = useContext(ProfileDetailsContext)
  const details = data?.profile_details?.user_profile_details?.family_details[0]
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Family Details</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            <div className={style.personalsDetail}>
              <p className={style.label}>Family Type</p>
              <p className={style.title}>{details?.family_type}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Family Status</p>
              <p className={style.title}>{details?.family_status}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Father’s Occupation</p>
              <p className={style.title}>{details?.father_occupation}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Mother’s Occupation</p>
              <p className={style.title}>{details?.family_type}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>No of Brothers</p>
              <p className={style.title}>{details?.brothers}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Brothers Married</p>
              <p className={style.title}>{details?.married_brothers}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>No of Sisters</p>
              <p className={style.title}>{details?.sisters}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Sisters Married</p>
              <p className={style.title}>{details?.married_sisters}</p>
            </div>
          </div>
        </div>
    </div>
  )
}
