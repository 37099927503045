import React, { useEffect, useState } from 'react'
import style from "./BestMatches.module.css"
import Carousel from 'react-grid-carousel'
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import carousalLeft from "../../../assets/svg/Home/carousalLeft.svg"
import carousalRight from "../../../assets/svg/Home/carousalRight.svg"
import sortlistStarIcon from "../../../assets/svg/Matches/Shortlisted.svg";
import sortlistGrayStarIcon from "../../../assets/svg/Matches/ShortlistGray.svg";
import lockedProfileImage from "../../../assets/svg/Home/lockedProfile.svg"
import heartScoreImage from "../../../assets/svg/Home/heartScore.svg"
import shortlistGrayIcon from "../../../assets/svg/Home/shortlistGrayIcon.svg"
import shortlistIcon from "../../../assets/svg/Home/Shortlist Selected.svg"
import editOptionImage from "../../../assets/svg/Home/editIcon.svg"
import doctorImage from "../../../assets/png/Home/Dr. Nandhini.png"
import useApiCall from '../../../hooks/useApiCall';
import useChatSetter from '../../../hooks/useChatSetter';
import config from '../../../../../app.json';
import { useNavigate } from 'react-router-dom'
import { avoidScroll, getUserToken } from '../../../../../utils/utils'
import { BlockReport } from '../../BlockReport/BlockReport'
import { Circles } from 'react-loader-spinner'
import chatIcon from "../../../features/MailBox/assets/Chat.svg"
import infoIcon from "../../MailBox/Declined/assets/Info.svg";
import ModalBox from '../../../../../ui/Modal/ModalBox'
import { DeclineModal } from '../../Matches/DeclineModal/DeclineModal'
import updgradeToEliteIcon from "../../../assets/common/upgradeToElite.svg"
import MaskImage from '../../MaskImage/MaskImage';

export default function BestMatches({ limitExceeded, details, subscribed, refresh, setRefresh, setInterestClicked }) {

    const navigation = useNavigate("")

    const [data, setData] = useState(null);
    const [sortlisted, setShortlised] = useState(false);
    const [clickedItem, setClickedItem] = useState(false)
    const [chatToken, setChatToken] = useState(null)
    const [rejectReason, setRejectReason] = useState(null)
    const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)

    const [rejectToken, setRejectToken] = useState(null)

    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: sendIntrest } = useApiCall();
    const { isLoading: sendShortListLoading, success: sendShortListSuccess, error: sendShortListError, sendRequest: shortlist } = useApiCall();
    const { loading: isChatSetterLoading, success: successChatSetter, error: errorChatSetter, setUserChatEntry: chatRoomSetup } = useChatSetter();
    const { sendRequest: acceptInterest } = useApiCall();
    const { sendRequest: declineInterest } = useApiCall();

    useEffect(() => {
        // Initialize buttonText property for each item
        const updatedData = details?.map(item => ({
            ...item,
            intrestButtonText: item?.sentInterest === 1 ? 'Interest sent' : item?.sentInterest === 0 ? 'Send Interest' : item?.sentInterest === 2 ? "Accepted" : "Rejected",
            shortlistButtonText: item.shortlisted === 1 ? 'Shortlisted' : 'Shortlist',
        }));
        setData(updatedData);
    }, [details])

    useEffect(() => {
        if (sendIntrestSuccess) {
            console.log("sendIntrestSuccess", sendIntrestSuccess);
        }
        if (sendIntrestError) {
            console.log("loginError", sendIntrestError);
        }
    }, [sendIntrestSuccess, sendIntrestError]);

    const handleSendInterest = (token, status) => {
        setInterestClicked(true)
        if (!limitExceeded) {
            sendIntrest({
                url: `profile/toggle-interest`,
                method: 'POST',
                body: {
                    "profile_token": token,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, () => {
                const index = data.findIndex((item) => item.token === token);

                if (index !== -1) {
                    // Update the specific property of the found object
                    data[index].interest_sent_status = status;
                }
                setData(data)
                setRefresh(!refresh)
            });
        }
    }

    const handleShortlist = (token, status) => {
        shortlist({
            url: `profile/toggle-shortlist`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].shortlisted = status;
            }
            setData(data)
            setRefresh(!refresh)
        });
    }


    const handleAccept = (token) => {
        acceptInterest({
            url: `profile/accept-interest`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 2;
            }
            setData(data)
            setRefresh(!refresh)
        });
    }
    const handleCancel = (reason) => {
        declineInterest({
            url: `profile/reject-interest`,
            method: 'POST',
            body: {
                "profile_token": rejectToken,
                "reject_reason": reason.trim()
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === rejectToken);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 3;
            }
            setData(data)
            setRefresh(!refresh)
        });
    }

    const actionUi = (
        can_send_interest,
        interest_received_status,
        interest_sent_status,
        shortlisted,
        is_connected,
        token,
        name,
        image,
        user_id
    ) => {

        return (
            <>
                <div className={style.buttonProgressContainer}>
                    {/* <p className={style.likeProfileLabel}>Like her profile?</p> */}
                    <div className={style.buttonsContainer}>
                        {
                            interest_sent_status === 0 && interest_received_status === 0 ?
                                <Button
                                    className={`primaryButton`}
                                    onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                >
                                    Send Interest
                                </Button>
                                :
                                interest_sent_status === 1 && interest_received_status === 0 ?
                                    <Button
                                        className={`primaryButton intrestSentStyle`}
                                        onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                    >
                                        Interest Sent
                                    </Button>
                                    :
                                    <></>
                        }
                    </div>
                    {/* {
                        interest_sent_status === 1 ?
                            <div className={style.buttonStatusContainer}>
                                <p className={style.sentTitle}>Interest Sent Successfully</p>
                                <p className={style.declineInfoText}>Once accepts your interest You can chat with Dr. {name}</p>
                            </div>
                        :
                        <></>
                    } */}
                </div>
                {
                    interest_received_status === 2 ?
                        <div className={`${style.intrestSentContainer} ${style.accepted}`}>
                            <p className={style.declineInfoText}>You have accepted the interest request, now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    interest_received_status === 3 ?
                        <>
                            <p className={style.declineInfoText}>You declined the interest request.</p>
                            <div className={style.aboutDescription}>
                                <Image
                                    src={infoIcon}
                                />
                                <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                            </div>
                        </>
                        :
                        <></>
                }
                {
                    interest_sent_status === 2 ?
                        <div className={`${style.intrestSentContainer}`}>
                            <p className={style.declineInfoText}>{name} has accepted your interest.</p>
                            <p className={style.declineInfoText}> Now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        interest_sent_status === 3 ?
                            <>
                                <p className={style.declineInfoText}>Dr. {name} has declined your interest.</p>
                                <div className={style.aboutDescription}>
                                    <Image
                                        src={infoIcon}
                                    />
                                    <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                                </div>
                            </>
                            :
                            <></>
                }
                {
                    interest_received_status === 1 ?
                        <div className={style.buttonProgressContainer}>
                            <p className={style.declineInfoText}>Dr.{name} has expressed interest in you. Do you want to accept their request?</p>
                            <div className={style.buttonsContainer}>
                                <Button
                                    className={`${style.button} primaryButton`}
                                    onClick={() => handleAccept(token)}
                                >
                                    Accept
                                </Button>
                                <Button
                                    className={`${style.declineButton} primaryButton cancelButton`}
                                    onClick={() => {
                                        setRejectToken(token)
                                        avoidScroll()
                                        setShowRejectReasonModal(true)
                                        // handleCancel(token)
                                    }}
                                >
                                    Decline
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                }
            </>
        )
    }

    useEffect(() => {
        if (successChatSetter) {
            navigation("/chat", { state: { token: chatToken } })
        }
        if (errorChatSetter) {
            console.log("errorChatSetter", errorChatSetter);
        }
    }, [successChatSetter, errorChatSetter]);

    const handleChat = (token, name, user_id, image) => {
        chatRoomSetup(
            token,
            name,
            user_id,
            image
            , (data) => {
                setChatToken(data)
            })
    }

    return (
        <div className={style.topBestMatchesContainer}>
            <ModalBox
                open={showRejectReasonModal}
                width={"598px"}
                modalContent={
                    <DeclineModal
                        buttonText={"Okay"}
                        setRejectReason={setRejectReason}
                        setShowRejectReasonModal={setShowRejectReasonModal}
                        onClick={handleCancel}
                    />
                }
            />
            <div className={style.newlyJoinedTitleContainer}>
                <p className={style.titleText}>Top 10 best matches based on your preferences</p>
                {
                    !subscribed && (
                        <p onClick={() => {
                            localStorage.removeItem("matchesTab")
                            navigation("/matches", {
                                state: {
                                    selectedMatch: {
                                        api: "best-matches",
                                        title: "Best Matches",
                                        total_title: "Best Matches profiles"
                                    }
                                }
                            })
                        }}
                            className={style.viewAllText}
                        >View All Best Matches</p>
                    )
                }
            </div>
            <div className={style.imageAndDetailsContainer}>
                <Carousel
                    containerClassName={style.imageAndDetailCarouselContainer}
                    cols={1}
                    rows={1}
                    gap={40}
                    loop
                    showDots={false}
                    arrowLeft={<Image src={carousalLeft} className={style.arrowLeft} />}
                    arrowRight={<Image src={carousalRight} className={style.arrowRight} />}
                >
                    {
                        data?.map((item, index) => (
                            <Carousel.Item>
                                <div className={style.profileImageContainer}>
                                    <div className={style.imagesCarousalContainer} onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>
                                        <Carousel
                                            containerClassName={style.imagesCarouselContainer}
                                            cols={1}
                                            rows={1}
                                            gap={40}
                                            loop
                                            dotColorActive={"rgb(255, 255, 255)"}
                                            dotColorInactive={"rgba(255, 255, 255, 0.36)"}
                                            showDots={true}
                                            arrowLeft={<Image src={carousalLeft} className={style.arrowLeft} />}
                                            arrowRight={<Image src={carousalRight} className={style.arrowRight} />}
                                        >
                                            <Carousel.Item>
                                                <div className={style.userProfileContainer}>
                                                    <MaskImage
                                                        imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                        watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                                        width={333}
                                                        height={317}
                                                        side={true}
                                                        x1={-140}
                                                        x2={-140}
                                                        y1={-100}
                                                        y2={-120}
                                                    />
                                                    {/* <Image
                                                        src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                        className={style.doctorImage}
                                                    /> */}
                                                    <div className={style.matchScoreContainer}>
                                                        <Image
                                                            src={heartScoreImage}
                                                        />
                                                        <p className={style.scoreValue}>{item?.match_percentage}% Match</p>
                                                    </div>
                                                    {/* <div className={style.lockedProfileContainer}>
                                                    <Image
                                                        src={lockedProfileImage}
                                                    />
                                                    <p className={style.lockedMessage}>Only connected members can view Dr.Abinaya’s photo.</p>
                                                </div> */}
                                                </div>
                                            </Carousel.Item>
                                        </Carousel>
                                    </div>
                                    <div className={style.detailsContainer}>
                                        <div className={style.headerContainer}>
                                            <div className={style.nameContainer}>
                                                <p className={style.id} onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>{item?.user_id}</p>
                                                {/* {
                                                item?.active_status && 
                                                <div className={style.onlineContainer}>
                                                    <div className={style.dot}></div>
                                                    <p className={style.labelText}>Online Now</p>
                                                </div>
                                            } */}
                                            </div>
                                            <div className={style.imageContainer}>
                                                <Image
                                                    onClick={() => handleShortlist(item.token, item?.shortlisted ? 0 : 1)}
                                                    src={item?.shortlisted ? shortlistIcon : shortlistGrayIcon}
                                                />
                                                <BlockReport refresh={refresh} setRefresh={setRefresh} id={item.user_id} token={item.token} />
                                            </div>
                                        </div>
                                        <h1 onClick={() => navigation("/profile_details", { state: { token: item?.token } })} className={style.name}>{item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}</h1>
                                        {item?.is_onboard_completed === 1 ? <>
                                            <div className={style.personalDetails}>
                                                <p className={style.personaTtext}>{item?.age} yrs, {item?.height}, {item?.mother_tongue}, {item?.occupation}</p>
                                                <p className={style.personaTtext}>{item?.community}</p>
                                                <p className={style.personaTtext}>{item?.cityname}</p>
                                            </div>
                                            <p className={style.aboutText}>About {item.name}</p>
                                            <p className={style.aboutDescription}>{item?.about}</p>
                                        </> : <div className='non-onboarded-user'> </div>}
                                        {
                                            actionUi(
                                                item?.can_send_interest,
                                                item?.interest_received_status,
                                                item?.interest_sent_status,
                                                item?.shortlisted,
                                                item?.is_connected,
                                                item?.token,
                                                item?.name,
                                                item?.image,
                                                item?.user_id,

                                            )
                                        }
                                        {/* <Button 
                                        className={`primaryButton`}
                                        onClick={()=> handleSendInterest(item.token, item?.intrestButtonText === "Interest sent" ? 1 : 0)}
                                    >
                                        {item?.intrestButtonText}
                                    </Button> */}
                                    </div>
                                </div>
                            </Carousel.Item>
                        ))
                    }
                </Carousel>
                {
                    subscribed && (
                        <div className={style.updgradeToEliteContainer}>
                            <div className={style.updgradeToEliteContentsContainer}>
                                <Image src={updgradeToEliteIcon} />
                                <p className={style.titleText}>Subscribe to view all the Top 10 matches based on your Preferences</p>
                                <Button onClick={() => navigation("/subscribe")} className={`primaryButton ${style.subscribeButton}`}>Subscribe Now</Button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}
