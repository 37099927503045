import React, { useContext } from 'react'
import style from './PersonalDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function PersonalDetails() {
  const { data } = useContext(ProfileDetailsContext)
  const personalData = data?.profile_details?.user_profile_details?.personal_details[0]
  return (
    <div className={style.personalDetailsContainer}>
        <p className={style.title}>Personal Details</p>
        <div className={style.detailsContainer}>
          <div className={style.personalsDetails}>
            <div className={style.personalsDetail}>
              <p className={style.label}>Date of Birth</p>
              <p className={style.title}>{personalData?.dob}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Height</p>
              <p className={style.title}>{personalData?.height}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Weight</p>
              <p className={style.title}>{personalData?.weight}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Marital Status</p>
              <p className={style.title}>{personalData?.marital_status}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Mother Tongue</p>
              <p className={style.title}>{personalData?.mother_tongue}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Citizenship</p>
              <p className={style.title}>{personalData?.citizenship}</p>
            </div>
            <div className={style.personalsDetail}>
              <p className={style.label}>Physical Status</p>
              <p className={style.title}>{personalData?.physical_status}</p>
            </div>
          </div>
        </div>
    </div>
  )
}
