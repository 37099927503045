import React from "react"
import style from "./NoData.module.css"
import Image from "../../../../ui/Image/Image"
import noChatImage from "./assets/No Chat.svg"
import noDataImage from "./assets/NoResult.svg"

export const NoData = ({isChat, title}) => {
    return(
        <div className={style.noDataFoundContainer}>
            <div className={style.imageTitle}>
                <Image src={ isChat ? noChatImage : noDataImage} className={style.image}/>
                <p className={style.title}>{title}</p>
            </div>
        </div>
    )
}