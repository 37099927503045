import React, { useEffect, useState } from 'react';
import style from "./MailBox.module.css";
import Image from '../../../../ui/Image/Image';
import downArrowIcon from "../../../../ui/assets/dropdown.svg"

import inactiveReceivedIcon from "./assets/inactive/Received.svg";
import inactiveSentIcon from "./assets/inactive/Sent.svg";

import receivedIcon from "./assets/Received.svg";
import sentIcon from "./assets/Sent.svg";

import { useLocation, useNavigate } from 'react-router-dom';
import Sidebar from '../../features/Chat/Sidebar/Sidebar';
import Pending from '../../features/MailBox/Pending/Pending';
import Accepted from '../../features/MailBox/Accepted/Accepted';
import Declined from '../../features/MailBox/Declined/Declined';
import All from '../../features/MailBox/All/All';
import { MailboxContext } from '../../features/MailBox/Context/MailboxContext';
import SentAll from '../../features/MailBox/SentAll/SentAll';
import { getUserToken } from '../../../../utils/utils';

export default function MailBox() {
    const navigate = useNavigate(); // Initialize useHistory
    const { state } = useLocation();
  const [ openReceived, setOpenReceived ] = useState(true)
  const [ openSent, setOpenSent ] = useState(false)
  const [ selectedPage, setSelectedPage ] = useState("all")
  const [ activeType, setActiveType ] = useState(state?.activeTab ? state?.activeTab : "recieved")

  useEffect(()=> {
    if( state?.activeTab ) {
      if (state?.activeTab === "sent") {
        setOpenReceived(false);
        setOpenSent(!openSent);
        setSelectedPage("sent_"+state?.tabContent); // Update the selected type
      } else {
        setSelectedPage(state?.tabContent); // Update the selected type
      }
    }
  }, [state])

  const handleToggle = (type) => {
    setActiveType(type)
    if (type === "received") {
      setOpenReceived(!openReceived);
      setOpenSent(false);
    } else {
      setOpenReceived(false);
      setOpenSent(!openSent);
    }
    setSelectedPage(type === "received" ? "all" : "sent_all"); // Update the selected type
  };

  useEffect(()=> {
  }, [selectedPage])

  const menuItems = [
    { id: 1, label: "All" },
    { id: 2, label: "Pending" },
    { id: 3, label: "Accepted" },
    { id: 4, label: "Declined" },
  ];
  
  function MenuComponent(type) {
    return (
      <div>
        {menuItems.map((item) => (
          <p 
            key={item.id} 
            className={`${style.menuTitle} ${ selectedPage === ( activeType === "sent" ? "sent_"+item.label.toLocaleLowerCase() : item.label.toLocaleLowerCase() ) ? style.active : ""}`} 
            onClick={()=> {
              setSelectedPage( activeType === "sent" ? "sent_"+item.label.toLocaleLowerCase() : item.label.toLocaleLowerCase() )
            }}>
            {item.label} {/* Convert label to lowercase */}
          </p>
        ))}
      </div>
    );
  }

  return (
    <div className={`container ${style.matchesContainer}`}>
      <div className={style.sideBarContainer}>
        <p className={style.title}>Mail Box</p>
        <div className={style.contentContainer}>
          <div 
            className={`${style.content} ${ openReceived ? style.active : "" }`} 
            onClick={()=> handleToggle("received")}
          >
            <div className={style.menuContentContainer}>
              <Image src={ openReceived ? receivedIcon : inactiveReceivedIcon } />
              <p className={style.menuTitle}>Received</p>
            </div>
            <Image src={downArrowIcon} className={`${style.downArrow} ${ openReceived ? style.open : "" }`}/>
          </div>
          <div className={`${style.menuDropdownContainer} ${ openReceived ? style.open : style.close }`}>
            {MenuComponent("received")}
          </div>
        </div>
        <div className={style.contentContainer}>
          <div 
            className={`${style.content} ${ openSent ? style.active : "" }`} 
            onClick={()=> handleToggle("sent")}
          >
            <div className={style.menuContentContainer}>
              <Image src={ openSent ? sentIcon : inactiveSentIcon} />
              <p className={style.menuTitle}>Sent</p>
            </div>
            <Image src={downArrowIcon} className={`${style.downArrow} ${ openSent ? style.open : "" }`}/>
          </div>
          <div className={`${style.menuDropdownContainer} ${ openSent ? style.open : style.close }`}>
            {MenuComponent("sent")}
          </div>
        </div>
      </div>
      {/* Render the component based on the currentPage */}
      <MailboxContext.Provider value={{ activeType }}>
        {
          selectedPage === "all" ? 
            <All />
          :
            selectedPage === "sent_all" ?
              <SentAll />
            :
              selectedPage === "pending" || selectedPage === "sent_pending" ? 
                <Pending />
              :
                selectedPage === "accepted" || selectedPage === "sent_accepted" ? 
                  <Accepted />
                :
                  selectedPage === "declined" || selectedPage === "sent_declined" ?
                    <Declined />
                  :
                  <></>
        }
      </MailboxContext.Provider>
      {
        getUserToken() && (
          <Sidebar />
        )
      }
    </div>
  );
}
