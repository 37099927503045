import React, { useEffect, useRef, useState, useContext } from 'react';
import classes from "./Otp.module.css";
import Button from '../../../UI/Button/Button';
import Image from '../../../UI/Img/Img'
import FromHeader from '../FromHeader';
import clockimg from '../../../assets/SVG/common/Clock.svg'
import { LoginContext } from '../../Login/Context/CreatContext'
import useHttp from '../../../hooks/ues-http'
// import { sendOtpUrl, verifyOtp } from '../../../utils/baseUrl';
import { BASE_URL } from '../../../utils/baseUrl';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function Otp({ setOtpVerify, setnewPassword, setForgotMail }) {

  const { emailStore } = useContext(LoginContext);

  const [otp, setOtp] = useState(['', '', '', '']);
  const inputRefs = [useRef(), useRef(), useRef(), useRef()];
  const [totalSeconds, setTotalSeconds] = useState(30); // 1 minute and 30 seconds



  const { isLoading: sendOtpIsLoading, success: sendOtpSuccess, error: sendOtpError, sendRequest: sendOtpData } = useHttp();
  const { isLoading: verifyOtpIsLoading, success: verifyOtpSuccess, error: verifyOtpError, sendRequest: otpVerification } = useHttp();

  useEffect(() => {
    const interval = setInterval(() => {
      if (totalSeconds > 0) {
        setTotalSeconds(totalSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [totalSeconds]);



  useEffect(() => {
    if (sendOtpSuccess !== '') {
      setOtp(['', '', '', ''])
      setTotalSeconds(30);
      toast.success(verifyOtpSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (sendOtpError !== '') {
      toast.error(sendOtpError, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [sendOtpSuccess, sendOtpError]);


  const VerifySendOtpData = (data) => {

  }

  const resendOtp = () => {
    sendOtpData({
      url: `${BASE_URL}send-otp`,
      method: 'POST',
      body: {
        email_id: emailStore,
      },
    }, VerifySendOtpData);

  }
  const changeMail = () => {
    setForgotMail(true)
    setOtpVerify(false)
  }

  useEffect(() => {
    if (verifyOtpSuccess !== '') {
      toast.success(verifyOtpSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (verifyOtpError !== '') {
      toast.error(verifyOtpError, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [verifyOtpSuccess, verifyOtpError]);

  const VerifyOtpData = (data) => {
    setOtpVerify(false)
    setnewPassword(true)
  }

  const verifyOtpEvent = () => {
    const otpValue = otp.join('');
    otpVerification({

      url: `${BASE_URL}forgot-password-verify-otp`,
      method: 'POST',
      body: {
        email_id: emailStore,
        otp: otpValue,
        device_type: "Web"
      },

    }, VerifyOtpData);
  }



  const handleChange = (index, value) => {

    if (!isNaN(value) && value !== '') {
      otp[index] = value;
      setOtp([...otp]);
      if (index < 3 && value !== '') {
        inputRefs[index + 1].current.focus();
      }
    } else if (value === '' && index > 0) {
      otp[index] = '';
      setOtp([...otp]);
      inputRefs[index - 1].current.focus();
    } else if (value === '' && index === 0) {
      otp[index] = '';
      setOtp([...otp]);
    }
  };

  const handleKeyPress = (e, index) => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
      inputRefs[index - 1].current.focus();

    }
  };

  return (
    <>
      <div className={classes.contentContainer}>
        <FromHeader className={classes.login_titel}
          titel="Forgot Password"
          subtitel={`Enter the verification code we sent on your registered email address ${emailStore}`} />

        <p className={classes.text_center_email}>Change email address? <span className={classes.text_change} onClick={changeMail}>Change</span></p>

        <div className={classes.otpInputContainer}>
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              placeholder='0'
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, index)}
              maxLength={1}
              ref={inputRefs[index]}
            />
          ))}
        </div>
        <div className={classes.timerContainer}>
          {totalSeconds > 0 ? (
            <div className={classes.timer}>
              <Image
                src={clockimg}
              />
              <p className={classes.resendText}>
                {totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds} <span>sec</span>
              </p>
            </div>
          ) : (<p className={classes.text_center_email}>Don’t receive the code? <span className={classes.text_change} onClick={resendOtp}>Resend</span></p>

          )}
        </div>
        <div className={classes.verify_btn_set}>
          <Button className={`primarybtn`} onClick={verifyOtpEvent} disabled={verifyOtpIsLoading}>
            Verify OTP
          </Button>
        </div>

      </div>
      <ToastContainer />
    </>

  );
}
