import React from 'react'
import style from "./BannerSection.module.css"
import bannerImage from "../../../assets/png/LandingPage/Home Photos.png"
import Image from '../../../../../ui/Image/Image'
import Register from '../Register/Register'

export default function BannerSection() {
  return (
    <div className={style.landingPageContainer}>
        <div className={`${style.bannerSectionContainer} container`}>
            <div className={style.textContainer}>
                <h1>Your Perfect Match Awaits Join Doctor Matrimony Today!</h1>
                <h2>We Bring People Together Love Unites Them</h2>
            </div>
            <div className={style.imageContainer}>
                <Image 
                    src={bannerImage}
                    className={style.bannerImage}
                />
            </div>
        </div>
        <div className={style.registerContainer}>
            <Register />
        </div>
    </div>
  )
}
