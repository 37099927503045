import React from "react";
import Carousel from 'react-grid-carousel'

const CarouselItem = ({ showDots, cols, rows, ItemUrl, gap, dotColorActive, dot, mobileBreakpoint, responsiveLayout}) => {
    return (
        <Carousel showDots={showDots}  dotColorActive={dotColorActive} cols={cols} rows={rows} dot={dot} gap={gap} mobileBreakpoint={mobileBreakpoint} responsiveLayout={responsiveLayout} loop>
            {ItemUrl?.map((data, index) => (
                <Carousel.Item key={index}>{data}</Carousel.Item>
            ))}
        </Carousel>
    );
};

export default CarouselItem;
