import React from 'react'
import style from "./Success.module.css"
import successIcon from "../../../../../assets/svg/Premium/Purchase Completed.svg"
import Image from '../../../../../../../ui/Image/Image'
import Button from '../../../../../../../ui/Button/Button'

export default function Success({ title, description, buttonText, onClick }) {
  return (
    <div className={style.successModalContainer}>
      <Image
        src={successIcon}
      />
      <p className={style.title}>{title}</p>
      <p className={style.description}>{description}</p>
      <Button
        className={`primaryButton ${style.submitButton}`}
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </div>
  )
}
