import React from 'react'
import classes from './PagesHeader.module.css'

const PagesHeader = (props) => {
    const { title, children } = props
    return (
        <div className={classes.pagesheader}>
            <div className={classes.pagesheader_left}>
                <h3>{title}</h3>
            </div>
            <div className={classes.pagesheader_right}>
                {children}
            </div>
        </div>
    )
}

export default PagesHeader