import React, { useEffect, useState } from 'react'
import style from "./RecentActivity.module.css"
import Carousel from 'react-grid-carousel'
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import carousalRightIcon from "../../../assets/svg/Home/carousalRightIcon.svg"
import carousalleftIcon from "../../../assets/svg/Home/carousalleftIcon.svg"
import shortlistedStarIcon from "../../../assets/svg/Home/shortlistedStarIcon.svg"
import newlyJoinedImage1 from "../../../assets/png/Home/Dr-1.png"
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import { avoidScroll, getUserToken } from '../../../../../utils/utils'
import { useNavigate } from 'react-router-dom'
import ModalBox from '../../../../../ui/Modal/ModalBox'
import { DeclineModal } from '../../Matches/DeclineModal/DeclineModal'
import premiumIcon from "../../MembershipTypeCard/assets/premium.svg"
import eliteIcon from "../../MembershipTypeCard/assets/elite.svg"

export default function RecentActivity({details, refresh, setRefresh}) {

    const navigation = useNavigate("")
    const [data, setData] = useState(null);
    const [ lookingForYouData, setLookingForYouData ] = useState(null)
    const [ clickedItem, setClickedItem ] = useState(false)
    const [ chatToken, setChatToken ] = useState(null)
    const [ rejectReason, setRejectReason ] = useState(null)
    const [ showRejectReasonModal, setShowRejectReasonModal ] = useState(false)

    const [ rejectToken, setRejectToken ] = useState(null)

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const { sendRequest: responseInterest } = useApiCall();
    const { sendRequest: cancel } = useApiCall();
    
    useEffect(() => {
        setData(details)
    }, [])

    const handleAccept = (token) => {
        responseInterest({
            url: `profile/accept-interest`,
            method: 'POST',
            body: {
                "profile_token":token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            // Filter out the accepted item from the data array
            const updatedData = data.filter((dataItem) => dataItem.token !== token);
            setData(updatedData);
            setRefresh(!refresh)
        });
    }
    const handleCancel = (reason) => {
        responseInterest({
            url: `profile/reject-interest`,
            method: 'POST',
            body: {
                "profile_token":clickedItem,
                "reject_reason": reason.trim()
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            // Filter out the accepted item from the data array
            const updatedData = data.filter((dataItem) => dataItem.token !== clickedItem);
            setData(updatedData);
            setRefresh(!refresh)
        });
    }

  return (
    <div className={`${style.newlyJoinedContainer} ${style.recentActivityContainer}`}>
        <ModalBox
            open={showRejectReasonModal} 
            width={"598px"} 
            modalContent={
                <DeclineModal
                    buttonText={"Okay"}
                    setRejectReason={setRejectReason}
                    setShowRejectReasonModal={setShowRejectReasonModal}
                    onClick={handleCancel}
                />
            }
        />
        <div className={style.subTitleContainer}>
            <p className={style.titleText}>{data?.length} New Interests</p>
            <p  onClick={()=> 
                navigation("/mailbox", {
                    state: {
                        activeTab: "recieved",
                        tabContent: "pending"
                    }
                } )} 
            className={style.viewAllText}>View All</p>
        </div>
        <div className={`${style.dataContainer}  ${ data?.length > 3 ? "" : style.notCarousal }`}>
            {
                data?.length > 3 ?
                    <Carousel
                        cols={3}
                        rows={1}
                        gap={16}
                        showDots={false}
                        arrowLeft={<Image src={carousalleftIcon} className={style.arrowLeft} />}
                        arrowRight={<Image src={carousalRightIcon} className={style.arrowRight} />}
                    >
                        {
                            data?.map((item, index) => (
                                <Carousel.Item>
                                    <div className={style.profileImageContainer}  onClick={()=> navigation("/profile_details", { state: { token: item?.token } })}>
                                        <div className={style.imageContainer}>
                                            <Image
                                                src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+item.image}
                                                borderRadius={"10px"}
                                                className={style.image}
                                            />
                                            {
                                                item?.membership_type !== "Basic" && (
                                                    <Image src={item?.membership_type === "Premium" ? premiumIcon : eliteIcon } className={style.shortlistedStarIcon} />
                                                )
                                            }
                                        </div>
                                        <div className={style.detailsContainer}>
                                            <p className={style.id}>{item.name.slice(0, 10)+`${item.name?.length > 10 ? "..." : ""}`}</p>
                                            <p className={style.name}>{item?.age}yrs,{item?.education}</p>
                                            <div className={style.buttonContainer}>
                                                <Button 
                                                    className={`${style.button} primaryButton`}
                                                    onClick={()=> handleAccept(item.token)}
                                                >
                                                    Accept
                                                </Button>
                                                <Button 
                                                    className={`${style.declineButton} primaryButton cancelButton`}
                                                    onClick={()=> handleCancel(item.token)}
                                                >
                                                    Decline
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            ))
                        }
                    </Carousel>
                :
                    data?.map((item, index) => (
                        <div className={style.profileImageContainer}>
                            <div className={style.imageContainer}>
                                <Image
                                    src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+item.image}
                                    borderRadius={"10px"}
                                    className={style.image}
                                />
                                {
                                    item?.membership_type !== "Basic" && (
                                        <Image src={item?.membership_type === "Premium" ? premiumIcon : eliteIcon } className={style.shortlistedStarIcon} />
                                    )
                                }
                            </div>
                            <div className={style.detailsContainer}>
                                <p className={style.id}>{item.name.slice(0, 10)+`${item.name?.length > 10 ? "..." : ""}`}</p>
                                <p className={style.name}>{item?.age}yrs,{item?.education}</p>
                                <div className={style.buttonContainer}>
                                    <Button 
                                        className={`${style.button} primaryButton`}
                                        onClick={()=> handleAccept(item.token)}
                                    >
                                        Accept
                                    </Button>
                                    <Button 
                                        className={`${style.declineButton} primaryButton cancelButton`}
                                        onClick={()=> {
                                            setClickedItem(item.token)
                                            avoidScroll()
                                            setShowRejectReasonModal(true)
                                        }}
                                    >
                                        Decline
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))
            }
        </div>
    </div>
  )
}
