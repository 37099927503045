import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './BasicPreference.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import Button from '../../../../../ui/Button/Button';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../ui/Image/Image';
import uploadWhiteIcon from '../../../assets/common/upload-white.svg';
import deleteIcon from '../../../assets/common/Bin.svg';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserData, getUserToken, isStringValid, uploadFile } from '../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import { BuildPartnerProfileContext } from '../Context/BuildPartnerProfileContext';
import checkedIcon from "../../../assets/svg/Signup/checked.svg";
import uncheckedIcon from "../../../assets/svg/Signup/uncheck.svg";
import Success from '../Modal/Success';
import ModalBox from '../../../../../ui/Modal/ModalBox';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notesIcon from "../../../assets/common/notes.svg"

export default function BasicPreference() {

  const navigation = useNavigate("")

  const { sendRequest: getMetaData } = useApiCall();
  
  const { setActiveStep, setBasicPreferenceData } = useContext(BuildPartnerProfileContext);

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();
  const [ loader, setLoader ] = useState(false)

  const [ validationError, setValidationError ] = useState("")
  const [ viewDetailsModal, setViewDetailsModal ] = useState(false)

  const [ userMetaData, setUserMetaData ] = useState(null)
  const [videoFile, setVideoFile] = useState("");
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const uploadVideoInput = useRef(null);
  const [uploadPersonalVideo, setUploadPersonalVideo] = useState("");

  const [formData, setFormData] = useState({
    age: [],
    video: "",
    height: [],
    motherTongue: [],
    citizenship: '',
    physicalStatus: '',
    maritalStatus: [],
    eatingHabit: '',
    drinkingHabit: '',
    smokingHabit: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'preference/read-meta-1',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        setUserMetaData(data.data);
      }
    );
  }, []);

  useEffect(() => {
    getMetaData({
      url: "preference/read-step-1",
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`
      }
    }, (data) => {
      const { 
        basic_detail : { age_detail, height_detail, mother_tongue_detail, citizenship, physical_status, marital_status_detail },
        habit_detail: { drinking_habit, eating_habit, smoking_habit },
        video
      } = data?.data
      setVideoFile(video)
      setUploadedVideo(video)
      
      setFormData({
        age: age_detail,
        video: video,
        height: height_detail,
        motherTongue: mother_tongue_detail,
        citizenship: citizenship,
        physicalStatus: physical_status,
        maritalStatus: marital_status_detail,
        eatingHabit: eating_habit,
        drinkingHabit: drinking_habit,
        smokingHabit: smoking_habit
      })
    });
  }, []);

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
        const videoFormats = ['video/mp4', 'video/webm', 'video/ogg', "video/quicktime"]; // Add more video MIME types if needed

        // Check if the file type is in the list of allowed video formats
        if (videoFormats.includes(file.type)) {
            // Create a video element to get the duration
            const videoElement = document.createElement('video');

            // Listen for the 'loadedmetadata' event to ensure the duration is available
            videoElement.addEventListener('loadedmetadata', () => {
                // Check if the duration is less than 3 minutes (180 seconds)
                if (videoElement.duration <= 180) {
                    setUploadedVideo(URL.createObjectURL(file)); // Store the data URL for preview
                    setUploadPersonalVideo(URL.createObjectURL(file))
                    setVideoFile(file); // Store the File object
                    setFormData({ ...formData, video: file });
                    generateVideoThumbnail(file);
                } else {
                    toast.error(`Video duration exceeds 3 minutes. Please select a shorter video.`, {
                        autoClose: 2500,
                    })
                }
            });

            // Set the video element's source to the file URL
            videoElement.src = URL.createObjectURL(file);
        } else {
            // Handle invalid file type (not a video)
            toast.error(`Unsupported file format. Please select a supported video file format.`, {
              autoClose: 2500,
          })
        }
    }
};


  const generateVideoThumbnail = (videoFile) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      // Capture a frame from the video as a thumbnail
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a data URL
      const thumbnailURL = canvas.toDataURL('image/jpeg'); // You can change the format if needed

      setVideoThumbnail(thumbnailURL); // Store the thumbnail URL in state
    };

    video.src = URL.createObjectURL(videoFile);
  };

  const handleInputChange = (event, inputType) => {
    if( event.target ) {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [inputType]: event,
      });
    }
  };

  useEffect(() => {
    if (success) {
      setViewDetailsModal(true)
    }
    if (error !== '') {
      setValidationError(error)
    }
    setLoader(false)
  }, [success, error]);

  const extractToken = (data, isToken) => {
    let token = []
    data?.map(item=> {
      token.push( isToken ? item.token : item.value)
    })
    return token;
  }

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  const [formDataTitle, setFormDataTitle] = useState({
    video: "video",
    age: "Age",
    height: "height",
    motherTongue: "Mother Tongue",
    citizenship: 'Citizenship',
    physicalStatus: 'Physical Status',
    maritalStatus: "Marital Status",
    eatingHabit: 'Eating Habit',
    drinkingHabit: 'Drinking Habit',
    smokingHabit: 'Smoking Habit'
  });

  useEffect(() => {
    if (success) {
      setLoader(false)
      setActiveStep(2)
    }
    if (error !== '') {
      toast.error(error, {
          autoClose: 2500,
      })
    }
    setLoader(false)
  }, [success, error]);

  const submitForm = async () => {
    if (
      isStringValid(formData.age) &&
      // ( ( getUserData()?.membership_type === "Elite" && videoFile ) || ( getUserData()?.membership_type !== "Elite" ) ) &&
      isStringValid(formData.height) &&
      isStringValid(formData.motherTongue) &&
      isStringValid(formData.citizenship) &&
      formData.physicalStatus?.length &&
      formData.maritalStatus?.length &&
      formData.eatingHabit?.length &&
      formData.smokingHabit?.length &&
      formData.drinkingHabit?.length
    ) {
      setLoader(true)
      setValidationError("")

      // Upload the video file to S3
      let videoUrl = videoFile;
      if ( videoFile  && typeof videoFile === "object") {
        videoUrl = await uploadFile(videoFile);
      }
        const dataToSend = {
          "video": videoUrl,
          "age": extractToken(formData.age, true),
          "height": extractToken(formData.height, true),
          "mother_tongue": extractToken(formData.motherTongue, true),
          "citizenship": formData.citizenship,
          "physical_status": formData.physicalStatus,
          "marital_status": extractToken(formData.maritalStatus, false),
          "eating_habit": formData.eatingHabit,
          "smoking_habit": formData.smokingHabit,
          "drinking_habit": formData.drinkingHabit
        };
        uploadUserDetails({
          url: `preference/save-step-1`,
          method: 'POST',
          body: dataToSend,
          headers: {
              Authorization: `Bearer ${getUserToken()}`
          }
        })
        setBasicPreferenceData(dataToSend)
    } else {
      const dataArray = {
        video: formData.video,
        age: isStringValid(formData.age),
        height: isStringValid(formData.height),
        motherTongue: isStringValid(formData.motherTongue),
        citizenship: formData.citizenship,
        maritalStatus: extractToken(formData.maritalStatus, false),
        physicalStatus: formData.physicalStatus,
        eatingHabit: formData.eatingHabit,
        smokingHabit: formData.smokingHabit,
        drinkingHabit: formData.drinkingHabit
      }

      var result = Object.keys(dataArray).map((key) => [key, dataArray[key]]);
      for(const item of result || []) {
        if(  ( item[0] !== "video" ) ) {
          if ( !item[1] || item[1] === "" || item[1].length === 0 || item[1] === "Select an option" ) {
            toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                autoClose: 2500,
            })
            break; // This will exit the loop when the condition is met
          }
        }
      }
    }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (name, value) => {
    setFormData((prevFilterData) => {
      const currentFilter = prevFilterData[name] || [];
      const isExists = currentFilter.some(function(el){ return el.value === value.value})
      const updatedFilter = isExists ? currentFilter.filter((item) => item.value !== value.value) : [...currentFilter, value];
      return {
        ...prevFilterData,
        [name]: updatedFilter,
      };
    });
  };

  const isCheckboxChecked = (name, value) => {
    const dataArray = formData[name];
    var isPresent = dataArray?.some(function(el){ return el.value === value});
    return isPresent;
  };

  return (
    <>
      <ToastContainer />
      <ModalBox
        open={viewDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success
            title={"Profile Created Successfully"} 
            description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
            buttonText={"Okay"}
            onClick={()=>{
              addScroll()
              navigation("/home")
              setViewDetailsModal(false)
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <ProgressBar width={`33.33%`} total={3} current={1} />
        {
          getUserData()?.membership_type === "Elite" && (
            <div className={style.uploadDataContainer}>
              <div className={style.form}>
                <p className={style.formTitle}>Upload Video</p>
                  { uploadedVideo ? (
                    <div
                      className={`${style.uploadedImage} ${style.videoThumbnailContainer}`}
                      // style={{
                      //   backgroundImage: `url(${videoThumbnail?.includes("blob:") ? videoThumbnail : process.env.REACT_APP_S3_CLOUD_FRONT_URL + videoThumbnail})`,
                      // }}
                    >
                      <video key={uploadedVideo} width={126} height={70} controls={0}>
                          <source src={`${uploadPersonalVideo ? uploadPersonalVideo : process.env.REACT_APP_S3_CLOUD_FRONT_URL+uploadedVideo +"#t=0.1"}`}/>
                      </video>
                      <div className={style.deleteContainer}>
                        <Image src={deleteIcon} onClick={() =>  {
                          setFormData({
                            ...formData,
                            video: ""
                          })
                          setVideoFile("")
                          setUploadedVideo("") 
                        }} />
                      </div>
                      <div
                        className={style.reuploadVideoContainer}
                        // onClick={() => uploadVideoInput.current.click()}
                      >
                        <p className={style.reuploadLabeText}>Waiting for verification</p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={style.videoUploadContainer}>
                        <p className={style.text}>Capture your true self with a genuine smile and share your story through our self-video upload feature.</p>
                        <Button
                          className={`primaryButton ${style.videoButton}`}
                          onClick={() => {
                              if (uploadVideoInput.current) {
                                uploadVideoInput.current.click();
                              }
                          }}
                        >
                          Upload Video
                        </Button>
                      </div>
                      <div className={style.eliteVideoInstructionContainer}>
                        <Image src={notesIcon} className={style.notesIcon}/>
                        <p className={style.instructionTitle}>Craft a compelling, desire-filled video under 3 minutes of maximum length</p>
                      </div>
                    </>
                  )}
              </div>
              <input
                type="file"
                accept="video/*"
                onChange={handleVideoUpload}
                className={style.uploadInput}
                ref={(el) => (uploadVideoInput.current = el)} // Assign the ref to the input element
                onClick={(e)=> { 
                  e.target.value = null
                }}
              />
            </div>
          )
        }
        <div className={style.formContainer}>
          <p className={style.formTitle}>Basic Preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Age"
                name="age"
                responseType="token"
                options={userMetaData?.age} // Add options
                value={formData.age}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Mother Tongue"
                name="motherTongue"
                responseType="token"
                options={userMetaData?.mother_tongue} // Add options
                value={formData.motherTongue}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Marital Status</p>
                <div className={style.fieldValue}>
                  {
                    userMetaData?.marital_status?.map((item, index) => (
                        <div className={style.content}>
                            <Image
                                onClick={() => handleCheckBoxChange("maritalStatus", item)}
                                src={ isCheckboxChecked("maritalStatus", item.value) ? checkedIcon : uncheckedIcon }
                                className={style.checkIcon}
                            />
                            <p className={style.label}>{item?.name}</p>
                        </div>
                    ))
                  }
                </div>
              </div>
            </div>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Height"
                name="height"
                responseType="token"
                options={userMetaData?.height} // Add options
                value={formData.height}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <FloatingLabelDropdown
                label="Citizenship"
                name="citizenship"
                responseType="token"
                options={userMetaData?.citizenship} // Add options
                value={formData.citizenship}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Physical Status</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('physicalStatus', userMetaData?.physical_status)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Habits Preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={`${style.field} ${style.marital}`}>
                <p className={style.fieldTitle}>Eating Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('eatingHabit', userMetaData?.eating_habit)}
                </div>
              </div>
              <div style={{ minWidth: "342px", marginTop: "26px" }} className={style.field}>
                <p className={style.fieldTitle}>Drinking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('drinkingHabit', userMetaData?.drinking_habit)}
                </div>
              </div>
            </div>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Smoking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('smokingHabit', userMetaData?.smoking_habit)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
        <Button
           className={`primaryButton ${style.submitButton}`}
           onClick={submitForm}
           loading={isLoading || loader}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
