import React, { useContext, useEffect, useState } from 'react';
import style from "./WorkDetails.module.css";
import useApiCall from '../../../hooks/useApiCall';
import config from "../../../../../app.json";
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput';
import Button from "../../../../../ui/Button/Button"
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import { BuildProfileContext } from '../Context/BuildProfileContext';
import { getUserToken, isStringValid, uploadFile } from '../../../../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import pdfIcon from "../../../assets/common/pdf.svg"
import uploadIcon from '../../../assets/common/Upload.svg';
import Image from '../../../../../ui/Image/Image';

export default function WorkDetails() {

    const { setActiveStep } = useContext(BuildProfileContext);
    const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    const { sendRequest: getUserData } = useApiCall();
    const { sendRequest: getCountry } = useApiCall();
    const { sendRequest: getState } = useApiCall();
    const { sendRequest: getCity } = useApiCall();
    const { sendRequest: getMetaData } = useApiCall();
    const { sendRequest: getEmployedIn } = useApiCall();
    const { sendRequest: getAnnualIncome } = useApiCall();
    const [qualifications, setQualifications] = useState([{ college_name: '', qualification_detail: '', certificate_url: null }]);
    const [ qualificationErrorData, setQualificationErrorData ] = useState([]);

    const [countryData, setCountryData] = useState([]);
    const [ loader, setLoader ] = useState(false)
    const [institutionName, setInstitutionName] = useState("");

    const [imagePreviews, setImagePreviews] = useState([
      null // Initial image previews, one for each qualification
    ]);

    // Separate state data for native and work locations
    const [nativeCountry, setNativeCountry] = useState({
        token: "",
        value: ""
    });
    const [nativeStateData, setNativeStateData] = useState([]); // Separate for native state
    const [nativeState, setNativeState] = useState({
        token: "",
        value: ""
    });
    const [nativeCityData, setNativeCityData] = useState([]); // Separate for native city
    const [nativeCity, setNativeCity] = useState({
        token: "",
        value: ""
    });

    const [employedIn, setEmployedIn] = useState([]);
    const [employedInData, setEmployedInData] = useState([]); // Separate for native state
    const [occupationData, setOccupationData] = useState([]); 
    const [annualIncome, setAnnualIncome] = useState([]);
    const [occupation, setOccupation] = useState([]);
    const [annualIncomeData, setAnnualIncomeData] = useState([]); // Separate for native state

    const [workCountry, setWorkCountry] = useState({
        token: "",
        value: ""
    });
    const [workStateData, setWorkStateData] = useState([]); // Separate for work state
    const [workState, setWorkState] = useState({
        token: "",
        value: ""
    });
    const [workCityData, setWorkCityData] = useState([]); // Separate for work city
    const [workCity, setWorkCity] = useState({
        token: "",
        value: ""
    });

    const [ qualificationData, setQualificationData ] = useState([]);
    const [ userData, setUserData ] = useState([])  
    const [ validationError, setValidationError ] = useState("")
    const [userMeta, setUserMeta] = useState([]);

    const [ formClickedOnce, setFormClickedOnce ] = useState(false)

    const [formDataTitle, setFormDataTitle] = useState({
        workCountry: "Work Country",
        workState: "Work State",
        workCity: "Work City",
        qualification: 'Qualification',
        nativeCountry: 'Place of Orgin Country',
        nativeState: 'Place of Orgin State',
        nativeCity: "Place of Orgin City",
        employedIn: "Employed In",
        occupation: "Occupation",
        annualIncome: 'Annual Income',
        institutionName: 'Institution Name',
    });

    const { sendRequest: getQualifications } = useApiCall();

    useEffect(()=> {

        getQualifications({
            url: "education",
            method: 'POST',
        }, resultQualification)
    }, [])

    const resultQualification = (data) => {
      setQualificationData(data.data)
    }

    useEffect(() => {
      getMetaData(
        {
          url: 'onboard/read-meta-data',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        },
        (data) => {
          setUserMeta(data.data);
          setCountryData(data.data.country);
          setAnnualIncomeData(data.data.annual_income);
        }
      );
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        getUserData({
            url: "onboard/read-step-2",
            method: 'GET',
            headers: {
              Authorization: `Bearer ${getUserToken()}`
            }
        }, (data) => {
            if( data?.data ) {
                const { 
                    native_country, 
                    native_state, 
                    native_city, 
                    institution_working, 
                    employed_in, 
                    occupation, 
                    annual_income, 
                    work_country, 
                    work_state, 
                    work_city,
                    qualification
                } = data?.data
                if( qualification?.length ) {
                    setQualifications(qualification)
                }

                let urls = []
                
                qualification?.map((item)=> {
                    urls.push(item?.certificate_url)
                })
                setImagePreviews(urls)

                setNativeCountry({
                    token: native_country.id,
                    value: native_country.name,
                })
                setNativeState({
                    token: native_state.id,
                    value: native_state.name,
                })
                setNativeCity({
                    token: native_city.id,
                    value: native_city.name,
                })
                setInstitutionName(institution_working ? institution_working : "")
                setOccupation(occupation ? occupation : "")
                setEmployedIn(employed_in ? employed_in : "")
                setAnnualIncome(annual_income ? annual_income : "")
                setWorkCountry({
                    token: work_country.id,
                    value: work_country.name,
                })
                setWorkState({
                    token: work_state.id,
                    value: work_state.name,
                })
                setWorkCity({
                    token: work_city.id,
                    value: work_city.name,
                })
            } else {
                setUserData(data.data);
            }
        });
    }, []);

    useEffect(() => {
        getState({
            url: "state",
            method: 'POST',
            body: {
                "country_id": nativeCountry.token
            }
        }, (data) => {
            setNativeStateData(data.data); // Set native state data
            if( formClickedOnce ) {
                setNativeCityData([])
                setNativeCity({
                    token: "",
                    value: ""
                })
            }
        });
    }, [nativeCountry.token]);

    useEffect(() => {
        getState({
            url: "state",
            method: 'POST',
            body: {
                "country_id": workCountry.token
            }
        }, (data) => {
            setWorkStateData(data.data); // Set work state data
            if( formClickedOnce ) {
                setWorkCityData([])
                setWorkCity({
                    token: "",
                    value: ""
                })
            }
        });
    }, [workCountry.token]);

    useEffect(() => {
            getCity({
                url: "city",
                method: 'POST',
                body: {
                    "state_id": nativeState.token
                }
            }, (data) => {
                setNativeCityData(data.data); // Set native city data
            });
    }, [nativeState.token]);

    useEffect(() => {
            getCity({
                url: "city",
                method: 'POST',
                body: {
                    "state_id": workState.token
                }
            }, (data) => {
                setWorkCityData(data.data); // Set work city data
            });
    }, [workState.token]);

    // Check if a country is selected for native and work locations
    const isNativeCountrySelected = Boolean(nativeCountry.length);
    const isWorkCountrySelected = Boolean(workCountry.length);

    useEffect(() => {
      if (success) {
        // Optionally, you can navigate to the next step or perform any other actions here
        setFormClickedOnce(false)
        setActiveStep(3);
      }
      if (error !== '') {
        toast.error(error, {
            autoClose: 2500,
        })
      }
    }, [success, error]);

    const nextStep = async() => {
        

        // Check if all required fields in dataToSend have values
        if (
            isStringValid(workCountry.value) &&
            isStringValid(workState.value) &&
            isStringValid(workCity.value) &&
            isStringValid(nativeCountry.value) &&
            isStringValid(nativeState.value) &&
            isStringValid(nativeCity.value) &&
            isStringValid(employedIn) &&
            isStringValid(occupation) &&
            institutionName &&
            isStringValid(annualIncome) &&
            userData &&
            qualifications?.length > 0
        ) {
            // Validate each qualification
            for (const qualification of qualifications) {
    
                if (!qualification.college_name || !qualification.qualification_detail || qualification.qualification_detail === "Select an option" || !qualification.certificate_url) {
                    toast.error(`Please fill the qualification`, {
                        autoClose: 2500,
                    })
                    return;
                }
            }
    
            if( qualificationErrorData?.length ) {
                qualificationErrorData?.map((item, index) => {
                if( item !== "" ) {
                    return;
                }
                })
            }
    
            setLoader(true)
    
            // Upload all images to S3 in parallel
            const uploadPromises = qualifications.map(async (qualification, index) => {
                if (qualification.certificate_url && typeof qualification.certificate_url === "object" ) {
                // Replace 'uploadFile' with your actual S3 upload function
                
                    return await uploadFile(qualification.certificate_url);
                }
                return qualification.certificate_url;
            });
        
            const uploadedImages = await Promise.all(uploadPromises);
        
            // Now, 'uploadedImages' will contain the S3 URLs of the uploaded certificates.
            // You can use them to update the 'qualifications' array if needed.
        
            // For example, you can update 'qualifications' like this:
            const updatedQualifications = qualifications.map((qualification, index) => ({
                college_name: qualification.college_name.trim(),
                qualification_detail: qualification.qualification_detail,
                certificate_url: uploadedImages[index], // Replace with the S3 URL
            }));
            
            // All required fields have values, so proceed with submission
            const dataToSend = {
                native_country: nativeCountry.value,
                native_state: nativeState.value,
                native_city: nativeCity.value,
                institution_working: institutionName.trim(),
                employed_in: employedIn,
                occupation: occupation,
                annual_income: annualIncome,
                work_country: workCountry.value,
                work_state: workState.value,
                work_city: workCity.value,
                qualification: updatedQualifications
            };
    
            uploadUserDetails({
                url: "onboard/save-step-2",
                method: 'POST',
                body: dataToSend,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            });
        } else {
            const dataArray = {
                nativeCountry: nativeCountry.value,
                nativeState: nativeState.value,
                nativeCity: nativeCity.value,
                institutionName: institutionName,
                employedIn: employedIn,
                occupation: occupation,
                annualIncome: annualIncome,
                workCountry:  workCountry.value, 
                workState: workState.value,
                workCity: workCity.value,
            }

            // Validate each qualification
            for (const qualification of qualifications) {
    
                if (!qualification.college_name || !qualification.qualification_detail || qualification.qualification_detail === "Select an option" || !qualification.certificate_url ) {
                    console.log("nativeCity.value", nativeCity.value);
                    if( nativeCity.value !== "Select an option" && nativeCity.value !== "" && nativeCity.value !== null ) {
                        toast.error(`Please fill the qualification`, {
                            autoClose: 2500,
                        })
                        return;
                    }
                }
            }

            var result = Object.keys(dataArray).map((key) => [key, dataArray[key]]);
            for(const item of result || []) {
                if ( !item[1] || item[1].length == 0 || item[1] === "Select an option" ) {
                    toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                        autoClose: 2500,
                    })
                    break; // This will exit the loop when the condition is met
                }
            }
        }
    }

    const addQualification = () => {
        if( qualifications?.length < 10 ) {
          setQualifications([...qualifications, { college_name: '', qualification_detail: '', certificate_url: null }]);
        }
        setImagePreviews([...imagePreviews, null]);
      };
    
      const removeQualification = (index) => {
        const updatedQualifications = [...qualifications];
        updatedQualifications.splice(index, 1);
        setQualifications(updatedQualifications);
    
        const updatedImagePreviews = [...imagePreviews];
        updatedImagePreviews.splice(index, 1);
        setImagePreviews(updatedImagePreviews);
      };
    
  
      const handleQualificationUpload = (e, index) => {
          const file = e.target.files[0];
      
          if( file ) { 
          // Check if the file size is greater than 6MB
          if (file && file.size > 6 * 1024 * 1024) {
              const errors = [...qualificationErrorData];
              errors[index] = "File size must be less than 6MB.";
              setQualificationErrorData(errors);
              return;
          }
          
          // Clear the specific error message for this file
          const errors = [...qualificationErrorData];
          errors[index] = "";
          setQualificationErrorData(errors);
          
          const updatedQualifications = [...qualifications];
          updatedQualifications[index].certificate_url = file;
          setQualifications(updatedQualifications);
          
          // Handle image preview
          const reader = new FileReader();
          reader.onload = (event) => {
              const updatedImagePreviews = [...imagePreviews];
              updatedImagePreviews[index] = event.target.result;
              setImagePreviews(updatedImagePreviews);
          };
          reader.readAsDataURL(file);
          
          // Clear any previous submit error message
          // setSubmitError("");
          }
      };

      const handleNameChange = (e, index) => {
        const updatedQualifications = [...qualifications];
        updatedQualifications[index][e.target.name] = e.target.value;
        setQualifications(updatedQualifications);
      };

    return (
        <div className={style.workDetailsContainer}>
            <ToastContainer />
            <ProgressBar width={`${16.66 * 2}%`} total={5} current={2}/>
            <div className={style.formContainer}>
                <p className={style.formTitle}>Place of Origin</p>
                <div className={style.fieldContainer}>
                    <FloatingLabelDropdown
                        label="Country"
                        name="country"
                        responseType="id"
                        options={countryData}
                        value={nativeCountry.token}
                        onChange={(e, value) => {
                            setFormClickedOnce(true)
                            setNativeCountry({ ...nativeCountry, token: e.target.value, value: value})
                            setNativeState({ token: "", value: ""})
                            setNativeCity({ token: "", value: ""})
                        }}
                    />
                    <FloatingLabelDropdown
                        label="State"
                        name="state"
                        responseType="id"
                        options={nativeStateData} // Use native state data here
                        value={nativeStateData.length > 0 ? nativeState.token : null}
                        onChange={(e, value) => {
                            setFormClickedOnce(true)
                            setNativeState({ ...nativeState, token: e.target.value, value: value})
                            setNativeCity({ token: "", value: ""})
                        }}
                    />
                </div>
                <FloatingLabelDropdown
                    label="District/City"
                    name="district_city"
                    responseType="id"
                    options={nativeCityData} // Use native city data here
                    value={nativeCityData.length > 0 ? nativeCity.token : null}
                    onChange={(e, value) => {
                        setFormClickedOnce(true)
                        setNativeCity({ ...nativeCity, token: e.target.value, value: value})
                    }}
                    style={{
                        marginTop: '30px',
                    }}
                />
            </div>
            <div>
                <p className={style.formQualificationTitle}>Qualification Details</p>
                <div className={style.qualificationContainer}>
                        {qualifications?.map((qualification, index) => (
                            <>
                            <div key={index} className={style.formHeader}>
                                <p className={style.titleText}>Qualification {index + 1}</p>
                                {index > 0 && (
                                    <p className={style.remove} onClick={() => removeQualification(index)}>
                                    Remove
                                    </p>
                                )}
                            </div>
                            <div className={style.inputFieldContainer}>
                                <PopoutInput
                                    label="College Name"
                                    name="college_name"
                                    value={qualification.college_name}
                                    onChange={(e) => handleNameChange(e, index)}
                                    style={{
                                        maxWidth: "342px",
                                        width: "100%",
                                        paddingBottom: '26px',
                                    }}
                                />
                                {
                                qualificationData && 
                                    <FloatingLabelDropdown
                                        label="Qualification Details"
                                        name="qualification_detail"
                                        responseType="token"
                                        options={qualificationData}
                                        value={qualification.qualification_detail}
                                        onChange={(e) => handleNameChange(e, index)}
                                        style={{
                                            maxWidth: "342px",
                                            width: "100%",
                                            paddingBottom: '26px',
                                        }}
                                    />
                                }
                            </div>
                            <div className={style.fileUploadContainer}>
                            <p className={style.fileTitle}>Certificate</p>
                            <p className={style.fileDescription}>PDF, PNG, JPG with a Max file size of 6MB</p>
                            <label htmlFor={`certificateUpload-${index}`} style={{ gap: imagePreviews[index] ? "12px" : "" }} className={style.uploadButton}>
                                {imagePreviews[index] ? (
                                <>
                                    {
                                    ( ( typeof qualifications[index].certificate_url === "object" && qualifications[index].certificate_url?.type === "application/pdf" ) || ( typeof qualifications[index].certificate_url !== "object" && qualifications[index].certificate_url?.includes(".pdf") ) )  ?
                                        <Image onClick={()=> {
                                        // avoidScroll()
                                        // setShowRejectReasonModal(true)
                                        }} src={pdfIcon} width={"70px"} height={"50px"}/>
                                    :
                                        <Image src={  ( imagePreviews[index]?.includes("blob:") || imagePreviews[index]?.includes("data:image") ) ?  imagePreviews[index] : process.env.REACT_APP_S3_CLOUD_FRONT_URL+ imagePreviews[index]} width={"70px"} height={"50px"}/>
                                    }
                                </>
                                ) : (
                                <Image src={uploadIcon} />
                                )}
                                <p className={style.uploadButtonTitle}>{ imagePreviews[index] ? "Edit" : "Upload Certificate" }</p>
                            </label>
                            <input
                                type="file"
                                id={`certificateUpload-${index}`}
                                accept="application/pdf,image/png,image/jpeg"
                                onChange={(e) => handleQualificationUpload(e, index)}
                                style={{ display: 'none' }}
                            />
                            <p className={`${style.errorMessage} error`}>{qualificationErrorData[index]}</p>
                            </div>
                            </>
                        ))}
                    {
                        qualifications?.length < 10 && (
                        <div className={style.cloneButton} onClick={addQualification}>
                            <p className={style.cloneButtonTitle}>+ Add Qualification</p>
                        </div>
                        )
                    }
                    {/* {submitError && <p className={`${style.errorMessage} error`}>{submitError}</p>} */}
                </div>
            </div>
            <div className={style.formContainer}>
                <p className={style.formTitle}>Professional Details</p>
                <div className={style.fieldContainer}>
                    <PopoutInput
                        label="Institution Name"
                        name="institution_name"
                        value={institutionName}
                        onChange={(e) => setInstitutionName(e.target.value)}
                    />
                    <FloatingLabelDropdown
                        label="Employed in"
                        name="employed in"
                        responseType="token"
                        options={userMeta.employed_in}
                        value={employedIn}
                        onChange={(e) => setEmployedIn(e.target.value)}
                    />
                </div>
                <div className={style.fieldContainer}>
                    <FloatingLabelDropdown
                        label="Occupation"
                        name="occupation"
                        responseType="token"
                        options={userMeta.occupation}
                        value={occupation}
                        onChange={(e) => setOccupation(e.target.value)}
                        style={{
                            marginTop: '30px',
                        }}
                    />
                    <FloatingLabelDropdown
                        label="Annual Income"
                        name="annual_income"
                        responseType="token"
                        options={annualIncomeData} // Use annual income data here
                        value={annualIncome}
                        onChange={(e) => setAnnualIncome(e.target.value)}
                        style={{
                            marginTop: '30px',
                        }}
                    />
                </div>
            </div>
            <div className={style.formContainer}>
                <p className={style.formTitle}>Work Location</p>
                <div className={style.fieldContainer}>
                    <FloatingLabelDropdown
                        label="Country"
                        name="country"
                        responseType="id"
                        options={countryData}
                        value={workCountry.token}
                        onChange={(e, value) => {
                            setFormClickedOnce(true)
                            setWorkCountry({ ...workCountry, token: e.target.value, value: value})
                            setWorkState({ token: "", value: ""})
                            setWorkCity({ token: "", value: ""})
                        }}
                    />
                    <FloatingLabelDropdown
                        label="State"
                        name="state"
                        responseType="id"
                        options={workStateData} // Use work state data here
                        value={workStateData.length > 0 ? workState.token : null}
                        onChange={(e, value) => {
                            setFormClickedOnce(true)
                            setWorkState({ ...workState, token: e.target.value, value: value})
                            setWorkCity({ token: "", value: ""})
                        }}
                    />
                </div>
                <FloatingLabelDropdown
                    label="District/City"
                    name="district_city"
                    responseType="id"
                    options={workCityData} // Use work city data here
                    value={workCityData.length > 0 ? workCity.token : null}
                    onChange={(e, value) => {
                        setFormClickedOnce(true)
                        setWorkCity({ ...workCity, token: e.target.value, value: value})
                    }}
                    style={{
                        marginTop: '30px',
                    }}
                />
            </div>
            {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

            <div className={style.buttonFooterContainer}>
                <Button
                    className={`primaryButton ${style.cancelButton}`}
                    onClick={()=> {
                        setFormClickedOnce(false)
                        setActiveStep(1)
                    }}
                >
                    Back
                </Button>
                <Button
                    className={`primaryButton ${style.submitButton}`}
                    onClick={nextStep}
                    loading={isLoading}
                >
                    Continue
                </Button>
            </div>
        </div>
    );
}
