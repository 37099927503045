import React from 'react'
import style from  "./ShortVideo.module.css"
import Image from '../../../../../ui/Image/Image'
import expressVideoImage from "../../../assets/png/LandingPage/Express Video@2x.png"
import playstoreImage from "../../../assets/svg/LandingPage/Playstore.svg"
import appleStoreImage from "../../../assets/svg/LandingPage/AppleStore.svg"
import expressVideoIconImage from "../../../assets/svg/LandingPage/Express Video.svg"

export default function ShortVideo() {
  return (
    <div className={style.appStoreContainer}>
        <div className={`${style.appStoreData} container p-t-60`}>
            <div className={style.image}>
                <Image src={expressVideoImage} className={style.mobileImage}/>
            </div>
            <div className={style.content}>
                <Image src={expressVideoIconImage} width={"86px"} height={"86px"}/>
                <p className={style.heading}>Express more about you in a short video!</p>
                <p className={style.description}>Share your views, interests, experiences, hobbies and much more in a short video to your future life partner, and know about each other 
before you even meet.</p>
            </div>
        </div>
    </div>
  )
}
