import React, { useContext, useEffect, useState } from 'react';
import style from './medicalCondition.module.css';
import useApiCall from '../../../../hooks/useApiCall';
import config from '../../../../../../app.json';
import Button from '../../../../../../ui/Button/Button';
import Image from '../../../../../../ui/Image/Image';
import radioUnselected from "../../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../../assets/common/Radiobutton_on.svg"
import { avoidScroll, getUserToken } from '../../../../../../utils/utils';
import axios from "axios"
import Success from '../Modal/Success';
import ModalBox from '../../../../../../ui/Modal/ModalBox';


export default function MedicalConditions({ Cancel, data, setViewDetailsModal, refresh }) {

    const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

    const { sendRequest: getMedicalConditionsData } = useApiCall();

    const [modal, setModal] = useState(false)
    const [conditions, setConditions] = useState([]);


    const [userData, setUserData] = useState([])

    const { sendRequest: getUserData } = useApiCall();
    console.log("conditions", conditions);




    useEffect(() => {
        window.scrollTo(0, 0);
        getUserData({
            url: "retrieve-json-data",
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (data) => {
            setUserData(data.data);
            if (data.data.medical_question) {
                // avoidScroll()
                // setViewDetailsModal(true)
            }
        });
    }, []);

    useEffect(() => {
        getMedicalConditionsData(
            {
                url: 'onboard/read-meta-data',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                const { medical_question } = data.data;
                setConditions(medical_question);
            });
    }, []);

    const handleAnswerChange = (index, answer) => {
        let answers = [...conditions]
        answers[index].answer = answer
        setConditions(answers);
    };

    useEffect(() => {
        if (success) {
            let conditionsData = []
            conditions.map((item) => {
                conditionsData.push({
                    "answer": item.answer,
                    "question": item.question,
                    "token": item.token
                })
            })
            const data = {
                "medical_question": conditionsData
            };
            const configs = {
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            };
            axios.post("insert-user", data, configs)
                .then(response => {
                    avoidScroll()
                    setViewDetailsModal(true)
                })
                .catch(error => {
                    // Handle errors here
                    console.error(error);
                });
        }
        if (error !== '') {
        }
    }, [success, error]);

    const handleHobbiesInterests = () => {
        let conditionsData = []
        conditions.map((item) => {
            conditionsData.push({
                "answer": item.answer,
                "question": item.question,
                "token": item.token


            })
        })
        const data = {
            "medical_question": conditionsData
        };
        uploadUserDetails({
            url: "my-account/my-profile/update/medical-questions",
            method: 'POST',
            body: data,
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (data) => {
            setModal(true)
            refresh()

        });
    };

    return (
        <>
            <div className={style.workDetailsContainer}>
                <div className={style.formContainer}>

                    <div className={style.conditionsContainer}>
                        {
                            conditions?.map((item, index) => (
                                <div className={style.condition} key={index}>
                                    <p className={style.questionTitle}><span>{index + 1}.</span>{item.question}</p>
                                    <div className={style.answers}>
                                        <div className={style.answer}>
                                            <Image src={item.answer === 'No' ? radioSelected : radioUnselected} onClick={() => handleAnswerChange(index, 'No')} />
                                            <p>No</p>
                                        </div>
                                        <div className={style.answer}>
                                            <Image src={item.answer === 'Yes' ? radioSelected : radioUnselected} onClick={() => handleAnswerChange(index, 'Yes')} />
                                            <p>Yes</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>

                <div className={style.btn_group}>
                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                    <Button className={`primaryButton ${style.submitButton}`} onClick={handleHobbiesInterests}> Save Changes</Button>
                </div>
            </div>

            <ModalBox
                open={modal}
                width={"598px"}
                modalContent={
                    <Success
                        title="Medical History"
                        onClick={() => {
                            setModal(false)
                            setViewDetailsModal(false)
                            refresh()
                        }}
                    />
                }
            />
        </>
    );
}
