// Button.js
import React from 'react';
import { Circles } from  'react-loader-spinner'

const CustomButton = ({ onClick, children, className, style, loading }) => {
  return (
    <button disabled={loading} style={style} className={`${className}`} onClick={onClick}>
      {children}
      {
        loading &&
          <Circles
            height="20"
            width="20"
            color="#fff"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
      }
    </button>
  );
};

export default CustomButton;
