import React, { useState, useEffect } from 'react'
import classes from './Login.module.css'
import { FormControl, InputAdornment, OutlinedInput } from '@mui/material';
import Visibility from '../../assets/SVG/Login/passwordopen.svg'
import VisibilityOff from '../../assets/SVG/Login/Hide.svg'
import InputLabel from '@mui/material/InputLabel';
import { Link, Navigate } from 'react-router-dom';
import TextInput from '../../UI/Input/Input';
import Button from '../../UI/Button/Button';
import Image from '../../UI/Img/Img';
import logo from '../../assets/SVG/Login/Logo.svg';
import FromHeader from './FromHeader';
import useHttp from '../../hooks/ues-http'
import { isAuthenticated } from '../../hooks/Auth';
import { BASE_URL } from '../../utils/baseUrl';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    FormControl: {
        fontFamily: 'Regular',
    },
    InputLabel: {
        fontSize: '14px',
        fontFamily: 'Regular',
    },
    OutlinedInput: {
        height: '52px',
        fontFamily: 'Regular',
        fontSize: '14px',
    },
});

const Login = () => {

    const classesinput = useStyles(); // Use the custom styles
    const { isLoading, success, error: loginError, sendRequest: sendTaskRequest } = useHttp();
    const initialState = {
        email: { required: false, invalidFormat: false },
        password: { required: false },
    };
    // States Difin
    const [errors, setErrors] = useState(initialState);
    const [showPassword, setShowPassword] = useState(false);
    const [user, setUser] = useState({ email: '', password: '' });
    const [sideMenuItems, setSideMenuItems] = useState([]);
    console.log("sideMenuItems", sideMenuItems);
    // Functions Difin
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const subimtHandling = (e) => {
        e.preventDefault();
        let errore = initialState;
        let hasErrore = false;

        if (user.email === '') {
            errore.email.required = true;
            hasErrore = true;
        } else {
            if (user.password === '') {
                errore.password.required = true;
                hasErrore = true;
            }
        }

        if (!hasErrore) {
            const verifyOutData = (data) => {
                const DataValue = data.data;
                // console.log(DataValue)
                localStorage.setItem('authToken', DataValue.access_token);
                localStorage.setItem('name', DataValue.name);
                localStorage.setItem('email', DataValue.email);
                // Reload the window after successful login
                // localStorage.setItem('page', 1);
                // localStorage.setItem('newPerPage', 10);
                window.location.reload();

            }

            const verifyUser = ({ email, password }) => {
                sendTaskRequest({
                    url: `${BASE_URL}login`,
                    method: 'POST',
                    body: {
                        email_id: email,
                        password: password,
                    },
                }, verifyOutData);

            }
            verifyUser({ email: user.email, password: user.password });
        }
        setErrors({ ...errore });
    }


    const handleChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const { sendRequest } = useHttp();
    const sideMenu = (data) => {
        setSideMenuItems(data.data)
    }

    const sideMenuRequst = () => {
        sendRequest({
            url: `${BASE_URL}module-side-list`,
            method: 'POST',
        }, sideMenu);
    }
    useEffect(() => {
        sideMenuRequst()
    }, [])

    if (isAuthenticated()) {
        const firstRedirectURL = sideMenuItems[0] && `/admin/dashboard/${sideMenuItems[0].redirect_url}`; // Get the first redirect URL from the sideMenuItems
        return <Navigate to={firstRedirectURL} />;
    }

    return (

        <div className={classes.login_page}>
            <div className={classes.login_container}>
                <div className={classes.login_container_heder}>
                    <Image src={logo} alt='logo' className={classes.login_logo} />
                </div>
                <form id="form" onSubmit={subimtHandling}>
                    <FromHeader className={classes.login_titel} titel="Login Now" subtitel="Enter your credentials to continue" />

                    <div className={classes["validate-input"]}>
                        {errors.email.required ? (
                            <span className='text_danger'>Please enter valid email id !</span>
                        ) : errors.email.invalidFormat ? (
                            <span className='text_danger'>Please enter a valid email address!</span>
                        ) : null}

                        <TextInput label="Email Address" onChange={handleChange} name="email" />
                    </div>
                    <div className={classes["validate-input"]} >
                        {errors.password.required ? (
                            <span className='text_danger'>Please enter valid Password !</span>
                        ) : null}

                        <FormControl sx={{ m: 1, width: '100%', margin: '0px' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password" className={classesinput.InputLabel}>Password</InputLabel>
                            <OutlinedInput
                                onChange={handleChange}
                                name="password"
                                id="outlined-adornment-password"
                                type={showPassword ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <span className={classes.visibility}
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Image src={VisibilityOff} alt="icon" /> : <Image src={Visibility} alt="icon" />}
                                        </span>
                                    </InputAdornment>
                                }
                                label="Password"
                                className={classesinput.OutlinedInput}
                            />
                        </FormControl>


                    </div>
                    <div className={classes.forgot}> <Link to="/admin/forgot">Forgot Password?</Link></div>
                    {loginError ? (
                        <span className='text_danger'>{loginError}</span>
                    ) : null}
                    <Button className={classes.login_btn} disabled={isLoading}>  {!isLoading ? "Login" : (<span className={classes.btn_spiner}><div className="spinner"></div>Login</span>)}</Button>

                </form>

            </div>
        </div>
    )
}

export default Login