import React from "react"
import style from "./UpdateNow.module.css"
import Button from "../../../../../ui/Button/Button"
import { useNavigate } from "react-router-dom"

export const UpdateNow = () => {
    const navigation = useNavigate("")
    return(
        <div className={style.updateNowContainer}>
            <p className={style.text}>Become an Elite member to unlock the video upload feature to express yourself & partner’s preference in a short video!</p>
            <Button
                style={{ width: "218px",  }}
                className={`primaryButton`}
                onClick={()=> {
                // setViewSuccessModal(false)
                // setActiveStep("otp");
                navigation("/subscribe")
                }}
                // loading={signUpLoading}
            >
                Let’s Update Video
            </Button>
        </div>
    )
}