import React, { useEffect, useRef, useState } from "react";
import style from "./BlockReport.module.css";
import Image from "../../../../ui/Image/Image";

import moreIcon from "../../assets/common/Menu.svg";
import ModalBox from "../../../../ui/Modal/ModalBox";
import { ReportModal } from "./ReportModal/ReportModal";
import { addScroll, avoidScroll, getUserToken } from "../../../../utils/utils";
import config from '../../../../app.json';
import useApiCall from '../../hooks/useApiCall';
import Success from "../../pages/CeleberateMatch/Modal/Success";
import { Content } from "./Confirm/Content/Content";
import { useLocation, useNavigate } from "react-router-dom";

export const BlockReport = ({token, id, refresh, setCurrentPage, setData, setRefresh, setIsScrollAction, setLastPageFilter}) => {

    const navigation = useNavigate("")
    const { pathname } = useLocation();
    console.log("pathname", pathname);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [ viewDetailsModal, setViewDetailsModal ] = useState("")
    const blockReportRef = useRef(null);
    const [ viewSuccessModal, setViewSuccessModal ] = useState(false)
    const [ viewReportSuccessModal, setViewReportSuccessModal ] = useState(false)
    const [ viewConfirmationPopup, setViewConfirmationPopup ] = useState(false)
    const [ type, setType ] = useState("")

    const { isLoading: sendBlockLoading, success: sendBlockSuccess, error: sendBlockError, sendRequest: block } = useApiCall();
  
    const toggleTooltip = () => {
      setTooltipVisible(!tooltipVisible);
    };
  
    const closeTooltip = () => {
      setTooltipVisible(false);
    };

    // Function to close the tooltip when a click occurs outside
    const handleOutsideClick = (e) => {
      if (blockReportRef.current && !blockReportRef.current.contains(e.target)) {
        closeTooltip();
      }
    };

    useEffect(()=> {
      if( sendBlockSuccess ) {
        setViewConfirmationPopup(false)
        avoidScroll()
        setViewSuccessModal(true)
      }
    }, [sendBlockSuccess, sendBlockError])

    useEffect(() => {
      // Add the event listener when the component mounts
      document.addEventListener("click", handleOutsideClick);
  
      // Remove the event listener when the component unmounts
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, []);

  const handleBlock = () => {
    block({
        url: `profile/block`,
        method: 'POST',
        body: {
          "profile_token": token
        },
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
    });
  }

  const dropdownRef = useRef(null);

  useEffect(() => {
      // Function to handle outside click
      const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setTooltipVisible(false);
      }
      };

      // Adding event listener when component mounts
      document.addEventListener('mousedown', handleClickOutside);

      // Removing event listener when component unmounts
      return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      };
  }, []); // Empty dependency array ensures that effect runs only once, like componentDidMount

  return (
    <div className={style.containe} ref={dropdownRef}>
        <ModalBox
          open={viewSuccessModal} 
          width={"598px"} 
          modalContent={
            <Success
              title={"Profile Hidden Successfully"} 
              // description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
              buttonText={"Okay"}
              onClick={()=>{
                if( setLastPageFilter ) {
                  setLastPageFilter(false)
                } 
                if( setCurrentPage ) {
                  setCurrentPage(1)
                }
                if( setData ) {
                  setData([])
                }
                setRefresh(!refresh)
                addScroll()
                if( pathname === "/profile_details" ) {
                  navigation("/home")
                }
                setViewSuccessModal(false)
                // navi
              }}
            />
          }
        />
        <ModalBox
          open={viewReportSuccessModal} 
          width={"598px"} 
          modalContent={
            <Success
              title={"Reported Successfully"} 
              // description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
              buttonText={"Okay"}
              onClick={()=>{
                if( setLastPageFilter ) {
                  setLastPageFilter(false)
                }
                if( setCurrentPage ) {
                  setCurrentPage(1)
                }
                if( setData ) {
                  setData([])
                }
                setRefresh(!refresh)
                addScroll()
                setViewReportSuccessModal(false)
              }}
            />
          }
        />
        <ModalBox
            open={viewDetailsModal} 
            width={"796px"}
            modalContent={
                <ReportModal setViewReportSuccessModal={setViewReportSuccessModal} id={id} token={token} setViewDetailsModal={setViewDetailsModal}/>
            } 
        />
        <ModalBox
            open={viewConfirmationPopup}
            width={"500px"}
            modalContent={
                <Content
                    type={"show this profile"}
                    setViewSuccessModal={setViewConfirmationPopup}
                    onClick={handleBlock}
                />
            }
        />
      {/* Image with a click event handler */}
      <Image src={moreIcon} className={style.moreIcon} onClick={toggleTooltip} />

      {/* Conditional rendering of the tooltip based on state */}
      {tooltipVisible && (
        <div className={style.tooltip}>
          <p className={style.menu} onClick={()=>{
            setType("Block")
            setTooltipVisible(false)
            setViewConfirmationPopup(true)
            // handleBlock()
          }}>Do not show</p>
          <p className={style.menu} onClick={()=> {
            setType("Report Profile")
            avoidScroll()
            setTooltipVisible(false)
            setViewDetailsModal(true)
        }}>Report Profile</p>
        </div>
      )}
    </div>
  );
};
