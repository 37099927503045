import React, { useEffect, useRef, useState } from "react"
import style from "./Dropdown.module.css"
import { avoidScroll, getUserToken } from "../../../../../utils/utils"
import useApiCall from "../../../hooks/useApiCall"
import config from '../../../../../app.json';
import Image from "../../../../../ui/Image/Image";
import { useNavigate } from "react-router-dom";

export const Dropdown = ({toggleNotificationDropdown, toggleRefresh, setIsNotificationDropdownOpen}) => {

    const navigation = useNavigate()
    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: getDetails } = useApiCall();
    const { isLoading: sendUnreadLoading, success: sendUnreadSuccess, error: sendUnreadError, sendRequest: unread } = useApiCall();
    const { isLoading: sendUnreadSingleLoading, success: sendUnreadSingleSuccess, error: sendUnreadSingleError, sendRequest: unreadSingle } = useApiCall();

    const [data, setData] = useState(null);
    const [total, setTotal] = useState(null);
    const [ refresh, setRefresh ] = useState(false)
    const [ clickedItem, setClickedItem ] = useState(null)
    const [ unreadCount, setUnreadCount ] = useState(null)
    const [ showMarkAsRead, setShowMarkAsRead ] = useState(false)

    useEffect(() => {
        getDetails({
            url: `notification/get-unread-count`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setUnreadCount(responseData?.data)
            setShowMarkAsRead( responseData?.data > 0 ? true : false )
        });
        getDetails({
            url: `notification/read-all`,
            method: 'GET',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setData(responseData.data.data)
            setTotal(responseData.data.total)
        });
    }, [refresh]);

    const handleViewAll = () => {
        toggleNotificationDropdown()
        navigation("/notifications")
    }

    useEffect(() => {
      if (sendUnreadSuccess) {
        toggleRefresh()
        setRefresh(!refresh)
        setShowMarkAsRead(false)
      }
    }, [sendUnreadSuccess]);

    const handleMarkAsUnread = () => {
        unread({
            url: "notification/mark-all-as-read",
            method: 'POST',
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
          });
    }

    useEffect(() => {
      if (sendUnreadSingleSuccess) {
        toggleNotificationDropdown()
        navigation("/profile_details", { state: { token: clickedItem} })
      }
    }, [sendUnreadSingleSuccess]);

    const handleProfileClick = (token, profile_token) => {
        setClickedItem(profile_token)
        unreadSingle({
            url: "notification/mark-as-read",
            method: 'POST',
            body: {
                "token": token
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        });
    }

    const dropdownRef = useRef(null);

    useEffect(() => {
        // Function to handle outside click
        const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsNotificationDropdownOpen(false)
        }
        };

        // Adding event listener when component mounts
        document.addEventListener('mousedown', handleClickOutside);

        // Removing event listener when component unmounts
        return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []); // Empty dependency array ensures that effect runs only once, like componentDidMount

    return(
        <div className={style.notificationContainer} ref={dropdownRef}>
            <div className={style.notificationTitleContainer}>
                <p className={style.title}>Notifications ({total})</p>
                {
                    showMarkAsRead ? <p className={style.markAsRead} onClick={handleMarkAsUnread}>({unreadCount}) Mark all as Read</p> : <></>
                }
            </div>
            <div className={style.notificationContentsContainer}>
                {
                    data?.slice(0, 2).map((item)=> (
                        <div onClick={()=> item?.profile_token !== null && handleProfileClick(item?.token, item?.profile_token)} className={style.notificationContentContainer}>
                            <div className={style.notificationImageNameContainer}>
                                {
                                    !item?.is_seen ? (
                                        <div className={style.notificationDot}></div>
                                    ) :  <div className={style.notificationDotless}></div>
                                }
                                <Image src={process.env.REACT_APP_S3_CLOUD_FRONT_URL+item?.image} className={style.profileImage} />
                                <div className={style.notificationNameContainer}>
                                    <p className={style.name}>{item?.title}</p>
                                    <p className={style.message}>{item?.description}</p>
                                </div>
                            </div>
                            <p className={style.date}>{item?.created_at}</p>
                        </div>
                    ))
                }
                <p className={style.viewAll} onClick={handleViewAll}>View All Notifications</p>
            </div>
        </div>
    )
}