import React, { useState, useEffect } from "react";
import Tabs from "../../UI/Tabs/Tabs";
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import Table from "../../UI/Table/Table";
import Tables from "../../UI/Table/TablePage";
import useApiHttp from "../../hooks/ues-http";
import TableHeader from "../../UI/Table/TableHeader";
import Search from "../../UI/Search/Search";
import Button from "../../UI/Button/Button";
import Modal from "../../UI/Modal/Modal";
import classes from "./UserRoles.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextInput from "../../UI/Input/Input";
import Box from "@mui/material/Box";
import CheckBox from "../../UI/Checkbox/CheckBox";
import { BASE_URL } from "../../utils/baseUrl";
import SelectBox from "../../UI/SelectFiled/SelectBox";
import Swal from "sweetalert2";
import { AnimatePresence, motion } from "framer-motion";

const UserRoles = () => {
  const initializeSateError = {
    name: { required: false, invalidFormat: false },
    email: { required: false, invalidFormat: false },
    number: { required: false, invalidFormat: false },
    selectmgs: { required: false, invalidFormat: false },
  };

  const [validationError, setValidationError] = useState("");
  const [refresh, setRefresh] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [errors, setErrors] = useState(initializeSateError);
  const [openPopup, setOpenPopup] = useState(false);
  const [editeOpenPopup, setEditeOpenPopup] = useState(false);

  const [addNewUser, setAddNewUser] = useState({
    name: "",
    email: "",
    number: "",
  });
  const [desigName, setDesigName] = useState("");

  const [editeUser, setEditeUser] = useState({
    name: "",
    email: "",
    number: "",
  });
  const [editeDesigName, setEditeDesigName] = useState("");
  const [userToken, setUserToken] = useState("");

  const [userRoles, setUserRoles] = useState([]);
  const [userRolesSearch, setUserRolesSearch] = useState("");
  const [userRolesCount, setUserRolesCount] = useState([]);
  const [iTotalDisplayRecords, setITotalDisplayRecords] = useState(0);
  const [sortDirectionData, setSortDirectionData] = useState("DESC");
  const [column, setColumn] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);

  const [userLists, setUserLists] = useState([]);
  const [userListsSearch, setUserListsSearch] = useState("");
  const [userListsCount, setUserListsCount] = useState([]);

  const [iTotalDisplayRecordsLists, setITotalDisplayRecordsLists] = useState(0);
  const [sortDirectionDataLists, setSortDirectionDataLists] = useState("DESC");
  const [columnLists, setColumnLists] = useState(0);
  const [perPageLists, setPerPageLists] = useState(10);
  const [pageLists, setPageLists] = useState(1);

  const [designation, setDesignation] = useState([]);

  const [moduleList, setModuleList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [selectedItemsError, setSelectedItemsError] = useState(true);
  const [newRole, setNewRole] = useState({
    role: "",
  });
  const [newRoleError, setNewRoleError] = useState(true);

  const [editeUserToken, setEditeUserToken] = useState("");
  const [editedUserRole, setEditedUserRole] = useState({
    role: "",
    modules: [],
  });

  // designationDropdwon ==========================

  const onCloseFunction = () => {
    setValidationError("");
    setErrors({
      name: { required: false, invalidFormat: false },
      email: { required: false, invalidFormat: false },
      number: { required: false, invalidFormat: false },
      selectmgs: { required: false, invalidFormat: false },
    });
    setAddNewUser({ name: "", email: "", number: "" });
    setEditeUser({ name: "", email: "", number: "" });
    setNewRoleError(true);
    setNewRole({ ...newRole, role: "" });
    setSelectedItems([]);
    setEditeDesigName("");
    setDesigName("");
  };

  const { sendRequest: designationRequest } = useApiHttp();

  const designationData = (data) => {
    setDesignation(data.data);
  };

  const designationRequestData = () => {
    designationRequest(
      {
        url: `${BASE_URL}designation-dropdown`,
        method: "POST",
      },
      designationData
    );
  };
  // moduleListCheckBox ==========================
  const { sendRequest: moduleListRequest } = useApiHttp();

  const moduleListData = (data) => {
    setModuleList(data.data);
  };

  const moduleListRequestData = () => {
    moduleListRequest(
      {
        url: `${BASE_URL}module-list`,
        method: "POST",
      },
      moduleListData
    );
  };

  // user-list requst ============================

  const {
    isLoading: userRolesLoding,
    success: userRolesSucces,
    error: userRolesError,
    sendRequest: userRolesRequest,
  } = useApiHttp();

  const userRolesData = (data) => {
    setUserRoles(data.aaData);
    setUserRolesCount(data.iTotalRecords);
    setITotalDisplayRecords(data.iTotalDisplayRecords);
  };

  const userRolesRequestData = () => {
    userRolesRequest(
      {
        url: `${BASE_URL}ssp-user-list`,
        method: "POST",
        body: {
          search_value: userRolesSearch,
          page: page,
          per_page: perPage,
          sort_dir: sortDirectionData,
          sort_column: column,
        },
      },
      userRolesData
    );
  };

  const columns = [
    {
      name: "S.No",
      selector: (row) => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "User ID",
      selector: (row) => (
        <>
          <span className="action_text_pointer">{row.user_id}</span>
        </>
      ),
      sortable: true,
      sortField: 1,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      sortField: 2,
    },
    {
      name: "Email Address",
      selector: (row) => row.email,
      sortable: true,
      sortField: 3,
    },
    {
      name: "Contact Number",
      selector: (row) => row.contac_number,
      sortable: true,
      sortField: 4,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true,
      sortField: 5,
    },
    {
      name: "Create date",
      selector: (row) => row.created_date,
      sortable: true,
      sortField: 6,
    },
    {
      name: "",
      cell: (row) => (
        <>
          {row.designation === "Super Admin" ? (
            ""
          ) : (
            <>
              <span
                className="action_text action_text_pointer mr-r-1rem"
                onClick={() => editeFiestPopPup(row)}>
                Edit
              </span>{" "}
              <span
                className="action_text action_text_pointer"
                onClick={() => userListdelete(row.user_id)}>
                Delete
              </span>
            </>
          )}
        </>
      ),
      sortable: false,
    },
  ];
  useEffect(() => {
    userRolesRequestData();
  }, [userRolesSearch, page, perPage, sortDirectionData, column]);

  const handleSearchChange = (newValue) => {
    setUserRolesSearch(newValue);
  };

  const handleSort = async (column, sortDirection) => {
    setSortDirectionData(sortDirection || "DESC");
    setColumn(column.sortField || 0); // Ensure to set column with the sortField value or default to 0
  };

  const handlePageChange = (page) => {
    setPage(page || 1);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage || 10);
  };

  // user-Role requst =====================

  const {
    isLoading: userListLoding,
    success: userListSucces,
    error: userListError,
    sendRequest: userListRequest,
  } = useApiHttp();

  const userListData = (data) => {
    setUserLists(data.aaData);
    setUserListsCount(data.iTotalRecords);
    setITotalDisplayRecordsLists(data.iTotalDisplayRecords);
  };

  const userListRequestData = () => {
    userListRequest(
      {
        url: `${BASE_URL}ssp-role-list`,
        method: "POST",
        body: {
          search_value: userListsSearch,
          page: pageLists,
          per_page: perPageLists,
          sort_dir: sortDirectionDataLists,
          sort_column: columnLists,
        },
      },
      userListData
    );
  };

  const columnstwo = [
    {
      name: "S.No",
      selector: (row) => row.slno,
      sortable: false,
      sortField: 0,
    },
    {
      name: "Role ID",
      selector: (row) => (
        <>
          <span className=" action_text_pointer">{row.rol_id}</span>
        </>
      ),
      sortable: true,
      sortField: 1,
    },
    {
      name: "Role Name",
      selector: (row) => row.role_name,
      sortable: true,
      sortField: 2,
    },
    {
      name: "Access Modules",
      selector: (row) => row.access_module,
      sortable: true,
      sortField: 3,
    },
    {
      name: "Create date",
      selector: (row) => row.created_date,
      sortable: true,
      sortField: 4,
    },
    {
      name: "",
      cell: (row) => (
        <>
          {row.role_name === "Super Admin" ? (
            ""
          ) : (
            <>
              <span
                className="action_text action_text_pointer mr-r-1rem"
                onClick={() => editeSecondPopPup(row.rol_id)}>
                Edit
              </span>{" "}
              <span
                className="action_text action_text_pointer"
                onClick={() => roleDelete(row.rol_id)}>
                Delete
              </span>
            </>
          )}
        </>
      ),
      sortable: false,
    },
  ];

  useEffect(() => {
    userListRequestData();
  }, [
    userListsSearch,
    pageLists,
    perPageLists,
    sortDirectionDataLists,
    columnLists,
  ]);

  useEffect(() => {
    designationRequestData();
    moduleListRequestData();
  }, []);

  const handleSearchChangeList = (newValue) => {
    setUserListsSearch(newValue);
  };
  const handleSortLists = async (column, sortDirection) => {
    setSortDirectionDataLists(sortDirection || "DESC");
    setColumnLists(column.sortField || 0);
  };

  const handlePageChangeLists = (page) => {
    setPageLists(page || 1);
  };

  const handlePerRowsChangeLists = async (newPerPage, page) => {
    setPerPageLists(newPerPage || 10);
  };

  // create-user qust ========================
  const {
    isLoading: priceLoding,
    success: priceSucces,
    error: priceError,
    sendRequest: priceRequest,
  } = useApiHttp();

  useEffect(() => {
    if (priceSucces !== "") {
      toast.success(priceSucces, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (priceError !== "") {
      setErrors({
        ...errors,
        name: { required: false, invalidFormat: false },
        email: { required: false, invalidFormat: false },
        number: { required: false, invalidFormat: false },
        selectmgs: { required: false, invalidFormat: false },
      });

      setValidationError(priceError);
    }
  }, [priceSucces, priceError]);

  const priceData = (data) => {
    if (priceSucces !== "") {
      setAddNewUser({ ...addNewUser, name: "", email: "", number: "" });
      setDesigName("");
    }
    setErrors({
      ...errors,
      name: { required: false, invalidFormat: false },
      email: { required: false, invalidFormat: false },
      number: { required: false, invalidFormat: false },
      selectmgs: { required: false, invalidFormat: false },
    });
    userRolesRequestData();
    setOpenPopup(false);
    setValidationError("");
  };

  const priceUpdateRequst = () => {
    const requestData = {
      name: addNewUser.name,
      email_id: addNewUser.email,
      mobile_number: addNewUser.number,
      role_token: desigName,
    };

    priceRequest(
      {
        url: `${BASE_URL}create-user`,
        method: "POST",
        body: requestData,
      },
      priceData
    );
  };

  const handleAddNewUser = () => {
    let errore = initializeSateError;
    let hasErrore = false;

    if (addNewUser.name === "") {
      errore.name.required = true;
      hasErrore = true;
    }

    if (addNewUser.email === "") {
      errore.email.required = true;
      hasErrore = true;
    } else if (!/^\S+@\S+\.\S+$/.test(addNewUser.email)) {
      errore.email.invalidFormat = true;
      hasErrore = true;
    }

    if (addNewUser.number === "") {
      errore.number.required = true;
      hasErrore = true;
    }
    if (desigName === "") {
      errore.selectmgs.required = true;
      hasErrore = true;
    }
    if (!hasErrore) {
      priceUpdateRequst();
    } else {
      setErrors({ ...errore });
      // setValidationError("Some required fields are missing.")
    }
    // setErrors({ ...errore });
  };

  // create userRole ==================

  const {
    isLoading: userRoleLoding,
    success: userRoleSucces,
    error: userRoleErroe,
    sendRequest: userRoleRequest,
  } = useApiHttp();

  useEffect(() => {
    if (userRoleSucces !== "") {
      toast.success(userRoleSucces, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (userRoleErroe !== "") {
      setValidationError(userRoleErroe);
    }
  }, [userRoleSucces, userRoleErroe]);

  const userRoleData = () => {
    userListRequestData();
    setValidationError("");
    setOpenPopup(false);
    setNewRole({ ...newRole, role: "" });
  };

  const userRoleAddRequst = () => {
    const requestData = {
      role: `${newRole.role}`,
      module_token: selectedItems,
    };
    userRoleRequest(
      {
        url: `${BASE_URL}role-create`,
        method: "POST",
        body: requestData,
      },
      userRoleData
    );
  };

  const handleAddNewUserRole = () => {
    let hasErrore = false;
    if (newRole.role === "") {
      hasErrore = true;
      setNewRoleError(false);
    }
    if (!hasErrore) {
      userRoleAddRequst();
      setSelectedItems([]);
      setNewRoleError(true);
    }
  };

  const handleChangePopPupFiled = (e) => {
    setAddNewUser({ ...addNewUser, [e.target.name]: e.target.value });
  };

  const handleChange = (event) => {
    setDesigName(event.target.value);
  };

  // Function to handle checkbox toggle
  const handleCheckboxToggle = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item)
      );
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  // poppup and tab function
  const handleRole = (event) => {
    const { name, value } = event.target;
    setNewRole({ ...newRole, [name]: value });
  };
  //================ Edite view handle checkbox toggle api call user Role ================
  const { sendRequest: editeRoleViewRequest } = useApiHttp();

  const editeRoleViewData = (row) => {
    const moduleTokens = row.data.module.map((item) => item.token);
    setEditedUserRole({
      role: row.data.role,
      modules: [...moduleTokens], // Assuming row.access_module contains module tokens
    });
  };

  const editeRoleViewAddRequst = (token) => {
    const requestData = {
      role_token: token,
    };
    editeRoleViewRequest(
      {
        url: `${BASE_URL}role-view`,
        method: "POST",
        body: requestData,
      },
      editeRoleViewData
    );
  };

  const editeSecondPopPup = (token) => {
    editeRoleViewAddRequst(token);
    setEditeUserToken(token);
    setEditeOpenPopup(true);
  };

  //================ Edite add user Role ================
  const {
    isLoading: editeUserRoleLoding,
    success: editeUserRoleSucces,
    error: editeUserRoleErroe,
    sendRequest: editeUserRoleRequest,
  } = useApiHttp();

  useEffect(() => {
    if (editeUserRoleSucces !== "") {
      toast.success(editeUserRoleSucces, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (editeUserRoleErroe !== "") {
      setValidationError(editeUserRoleErroe);
    }
  }, [editeUserRoleSucces, editeUserRoleErroe]);

  const editeUserRoleData = (data) => {
    userListRequestData();
    setEditeOpenPopup(false);
    setValidationError("");
  };

  const editeUserRoleAddRequst = () => {
    const requestData = {
      token: editeUserToken,
      role: editedUserRole.role,
      module_token: editedUserRole.modules,
    };
    editeUserRoleRequest(
      {
        url: `${BASE_URL}role-edit`,
        method: "POST",
        body: requestData,
      },
      editeUserRoleData
    );
  };

  const handleRoleEdite = (e) => {
    setEditedUserRole({ ...editedUserRole, [e.target.name]: e.target.value });
  };

  // Function to handle checkbox toggle for editing user role
  const handleEditCheckboxToggle = (moduleToken) => {
    const updatedModules = [...editedUserRole.modules];

    if (updatedModules.includes(moduleToken)) {
      // Deselect the module
      updatedModules.splice(updatedModules.indexOf(moduleToken), 1);
    } else {
      // Select the module
      updatedModules.push(moduleToken);
    }

    setEditedUserRole({
      ...editedUserRole,
      modules: updatedModules,
    });
  };

  const handleEditeUserRole = () => {
    if (editedUserRole) {
      editeUserRoleAddRequst();
    }
  };

  // deleted add user
  const {
    isLoading: deleteRoleLoding,
    success: deleteRoleSuccess,
    error: deleteRoleError,
    sendRequest: deleteRoleRequest,
  } = useApiHttp();
  useEffect(() => {
    if (deleteRoleSuccess !== "") {
      toast.success(deleteRoleSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (deleteRoleError !== "") {
      toast.error(deleteRoleError, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [deleteRoleSuccess, deleteRoleError]);
  const deleteRoleData = (data) => {
    userListRequestData();
  };

  const deleteRoleRequestData = (token) => {
    const requestData = {
      token: token,
    };

    deleteRoleRequest(
      {
        url: `${BASE_URL}role-delete`,
        method: "POST",
        body: requestData,
      },
      deleteRoleData
    );
  };

  const roleDelete = (token) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EF7435",
      cancelButtonColor: "#aeaeae",
      confirmButtonText: "Delete it!",
      customClass: {
        title: "your-custom-title-class",
        content: "your-custom-content-class",
        confirmButton: "your-custom-button-class",
        cancelButton: "your-custom-button-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteRoleRequestData(token);
      }
    });
  };

  // ===================================== Update Function ================================================

  const handleChangeEditeFiled = (e) => {
    setEditeUser({ ...editeUser, [e.target.name]: e.target.value });
  };
  const handleChangeEdite = (event) => {
    setEditeDesigName(event.target.value);
  };

  const {
    isLoading: editeUserLoding,
    success: editeUserSuccess,
    error: editeUserError,
    sendRequest: editeUserRequest,
  } = useApiHttp();

  useEffect(() => {
    if (editeUserSuccess !== "") {
      toast.success(editeUserSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      });

      setEditeOpenPopup(false);
    }
    if (editeUserError !== "") {
      toast.error(editeUserError, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [editeUserSuccess, editeUserError]);

  const editeUserData = (data) => {
    userRolesRequestData();
  };

  const editeUserRequestData = () => {
    const requestData = {
      token: userToken,
      name: editeUser.name,
      email_id: editeUser.email,
      mobile_number: editeUser.number,
      role_token: editeDesigName,
    };

    editeUserRequest(
      {
        url: `${BASE_URL}user-edit`,
        method: "POST",
        body: requestData,
      },
      editeUserData
    );
  };

  const handleEditeUser = () => {
    let errore = initializeSateError;
    let hasErrore = false;

    if (editeUser.name === "") {
      errore.name.required = true;
      hasErrore = true;
    }

    if (editeUser.email === "") {
      errore.email.required = true;
      hasErrore = true;
    } else if (!/^\S+@\S+\.\S+$/.test(editeUser.email)) {
      errore.email.invalidFormat = true;
      hasErrore = true;
    }

    if (editeUser.number === "") {
      errore.number.required = true;
      hasErrore = true;
    }
    if (editeDesigName === "") {
      errore.selectmgs.required = true;
      hasErrore = true;
    }
    if (!hasErrore) {
      editeUserRequestData();
    } else {
      // setValidationError("Some required fields are missing.")
      setErrors({ ...errore });
    }
    // setErrors({ ...errore });
  };
  const editeFiestPopPup = (row) => {
    setEditeUser({
      ...editeUser,
      name: row.name,
      email: row.email,
      number: row.contac_number,
    });
    setUserToken(row.user_id);
    setEditeDesigName(row.role_token);
    setEditeOpenPopup(true);
  };

  // deleted add user
  const {
    success: deleteUserSuccess,
    error: deleteUserError,
    sendRequest: deleteUserRequest,
  } = useApiHttp();
  useEffect(() => {
    if (deleteUserSuccess !== "") {
      toast.success(deleteUserSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (deleteUserError !== "") {
      toast.error(deleteUserError, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }, [deleteUserSuccess, deleteUserError]);
  const deleteUserData = () => {
    userRolesRequestData();
  };

  const deleteUserRequestData = (token) => {
    const requestData = {
      token: token,
    };

    deleteUserRequest(
      {
        url: `${BASE_URL}user-delete`,
        method: "POST",
        body: requestData,
      },
      deleteUserData
    );
  };

  const userListdelete = (token) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EF7435",
      cancelButtonColor: "#aeaeae",
      confirmButtonText: "Delete it!",
      customClass: {
        title: "your-custom-title-class",
        content: "your-custom-content-class",
        confirmButton: "your-custom-button-class",
        cancelButton: "your-custom-button-class",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUserRequestData(token);
      }
    });
  };

  const fiestPopPup = () => {
    setOpenPopup(true);
  };
  const SecondPopPup = () => {
    setOpenPopup(true);
  };
  const toggleRefresh = () => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, 200); // Refreshing after 1 second
  };
  const refreshTable = () => {
    setSortDirectionData("DESC");
    setColumn(0);
    setPerPage(10);
    setPage(1);
    setSortDirectionDataLists("DESC");
    setColumnLists(0);
    setPerPageLists(10);
    setPageLists(1);
  };
  const onTabClick = (index) => {
    setActiveTab(index); // Update the active tab index
    toggleRefresh();
    refreshTable();
    if (index === 0) {
      userRolesRequestData();
      designationRequestData();
      setUserRolesSearch("");
    } else if (index === 1) {
      setPerPage(10);
      userListRequestData();
      setUserListsSearch("");
    }
  };

  const tabAnimation = {
    initial: {
      y: -200,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      y: -200,
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      <PagesHeader title="User Roles" />
      <section className="tablesection_heder">
        <div className={classes.buttonsContainer}>
          {activeTab === 0 ? (
            <Button className={classes.login_btn} onClick={fiestPopPup}>
              + Add New User
            </Button>
          ) : (
            <Button className={classes.login_btn} onClick={SecondPopPup}>
              + Add New User Role
            </Button>
          )}
        </div>
        <Tabs
          tabs={[
            {
              title: "User Lists",
              render: () => (
                <>
                  {refresh && (
                    <motion.dev
                      variants={tabAnimation}
                      initial="initial"
                      animate="animate"
                      exit="exit">
                      <Tables
                        data={userRoles}
                        columns={columns}
                        loader={userRolesLoding}
                        onSort={handleSort}
                        paginationTotalRows={userRolesCount}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        children={
                          <div className="header_table">
                            <TableHeader
                              tableheader="User Lists"
                              totalusers={`${userRolesCount} total users`}
                            />
                            <Search
                              value={userRolesSearch}
                              onChange={(newValue) =>
                                handleSearchChange(newValue)
                              }
                              type="text"
                              placeholder="Search"
                            />
                          </div>
                        }
                      />
                    </motion.dev>
                  )}
                </>
              ),
            },
            {
              title: "User Roles",
              render: () => (
                <>
                  {refresh && (
                    <motion.dev
                      variants={tabAnimation}
                      initial="initial"
                      animate="animate"
                      exit="exit">
                      <Tables
                        data={userLists}
                        columns={columnstwo}
                        loader={userListLoding}
                        onSort={handleSortLists}
                        paginationTotalRows={userListsCount}
                        onChangeRowsPerPage={handlePerRowsChangeLists}
                        onChangePage={handlePageChangeLists}
                        children={
                          <div className="header_table">
                            <TableHeader
                              tableheader="User Roles"
                              totalusers={`${userListsCount} total users roles`}
                            />
                            <Search
                              value={userListsSearch}
                              onChange={(newValue) =>
                                handleSearchChangeList(newValue)
                              }
                              type="text"
                              placeholder="Search"
                            />
                          </div>
                        }
                      />
                    </motion.dev>
                  )}
                </>
              ),
            },
          ]}
          onTabClick={onTabClick}
          activeTab={activeTab}
        />
      </section>

      {activeTab === 0 ? (
        <Modal
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          titel="Add New User"
          size="lg"
          handleSubmit={handleAddNewUser}
          onCloseFunction={onCloseFunction}>
          <>
            <div className={classes.mrg_top_poppup}>
              <div className={classes.inputs_box}>
                <div className={classes.input_position}>
                  {errors.name.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please Enter Employee Name !
                    </span>
                  ) : null}
                  <TextInput
                    label="User Name"
                    name="name"
                    onChange={handleChangePopPupFiled}
                    value={addNewUser.name}
                  />
                </div>
                <div className={classes.input_position}>
                  {errors.email.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please enter valid email id !
                    </span>
                  ) : errors.email.invalidFormat ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please enter a valid email address !
                    </span>
                  ) : null}
                  <TextInput
                    label="Email Address"
                    name="email"
                    onChange={handleChangePopPupFiled}
                    value={addNewUser.email}
                  />
                </div>
              </div>
              <div className={classes.inputs_box}>
                <div className={classes.input_position}>
                  {errors.number.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please Enter Contact Number !
                    </span>
                  ) : null}
                  <TextInput
                    label="Contact Number"
                    name="number"
                    onChange={handleChangePopPupFiled}
                    value={addNewUser.number}
                  />
                </div>
                <div className={classes.input_position}>
                  {errors.selectmgs.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please Select Designation !
                    </span>
                  ) : null}
                  <Box sx={{ minWidth: 120 }}>
                    <SelectBox
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={desigName}
                      label="Designation"
                      onChange={handleChange}
                      labelname="Designation"
                      nonename="Designation"
                      data={designation}
                    />
                  </Box>
                </div>
              </div>
            </div>
            {validationError && (
              <p className={`${classes.errorMessage} text_danger`}>
                {validationError}
              </p>
            )}
          </>
        </Modal>
      ) : (
        <Modal
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          titel="Add New User Role"
          size="lg"
          handleSubmit={handleAddNewUserRole}
          onCloseFunction={onCloseFunction}>
          <>
            <div className={classes.mrg_top_poppup}>
              <div className={classes.contaniner__box}>
                <div className={classes.text_filed_box}>
                  {newRoleError ? (
                    ""
                  ) : (
                    <span className="text_danger">
                      Please Enter Role Name !
                    </span>
                  )}
                  <TextInput
                    label="Role Name"
                    name="role"
                    value={newRole.role}
                    onChange={handleRole}
                  />
                </div>
                <p className={classes.module_text}>Choose Module</p>
                {/* {!selectedItemsError ? "" : ""} */}
                <div className={classes.checkbox_box}>
                  {moduleList.map((item) => (
                    <CheckBox
                      key={item.token}
                      label={item.name}
                      checked={selectedItems.includes(item.token)} // Use item.token here
                      onChange={() => handleCheckboxToggle(item.token)}
                    />
                  ))}
                </div>
                {validationError && (
                  <p className={`${classes.errorMessage} text_danger`}>
                    {"Please select the module !"}
                  </p>
                )}
              </div>
            </div>
          </>
        </Modal>
      )}

      {activeTab === 0 ? (
        <Modal
          openPopup={editeOpenPopup}
          setOpenPopup={setEditeOpenPopup}
          titel="Edit User"
          size="lg"
          handleSubmit={handleEditeUser}
          onCloseFunction={onCloseFunction}>
          <>
            <div className={classes.mrg_top_poppup}>
              <div className={classes.inputs_box}>
                <div className={classes.input_position}>
                  {errors.name.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please Enter Employee Name !
                    </span>
                  ) : null}
                  <TextInput
                    label="User Name"
                    name="name"
                    onChange={handleChangeEditeFiled}
                    value={editeUser.name}
                    defaultValue={editeUser.name}
                  />
                </div>
                <div className={classes.input_position}>
                  {errors.email.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please enter valid email id !
                    </span>
                  ) : errors.email.invalidFormat ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please enter a valid email address !
                    </span>
                  ) : null}
                  <TextInput
                    label="Email Address"
                    name="email"
                    onChange={handleChangeEditeFiled}
                    value={editeUser.email}
                    defaultValue={editeUser.email}
                  />
                </div>
              </div>
              <div className={classes.inputs_box}>
                <div className={classes.input_position}>
                  {errors.number.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please Enter Contact Number !
                    </span>
                  ) : null}
                  <TextInput
                    label="Contact Number"
                    name="number"
                    onChange={handleChangeEditeFiled}
                    value={editeUser.number}
                    defaultValue={editeUser.number}
                  />
                </div>
                <div className={classes.input_position}>
                  {errors.selectmgs.required ? (
                    <span className={`${classes.error_postion} text_danger`}>
                      Please Slect Designation !
                    </span>
                  ) : null}

                  <Box sx={{ minWidth: 120 }}>
                    <SelectBox
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={editeDesigName}
                      defaultValue={editeDesigName}
                      label="Designation"
                      onChange={handleChangeEdite}
                      labelname="Designation"
                      nonename="Designation"
                      data={designation}
                    />
                  </Box>
                </div>
              </div>
            </div>
            {validationError && (
              <p className={`${classes.errorMessage} text_danger`}>
                {validationError}
              </p>
            )}
          </>
        </Modal>
      ) : (
        <Modal
          openPopup={editeOpenPopup}
          setOpenPopup={setEditeOpenPopup}
          titel="Edit User Role"
          size="lg"
          handleSubmit={handleEditeUserRole}
          onCloseFunction={onCloseFunction}>
          <>
            <div className={classes.mrg_top_poppup}>
              <div className={classes.contaniner__box}>
                <div className={classes.text_filed_box}>
                  <TextInput
                    label="Role Name"
                    name="role"
                    value={editedUserRole.role}
                    defaultValue={editedUserRole.role}
                    onChange={handleRoleEdite}
                  />
                </div>
                <p className={classes.module_text}>Choose Module</p>
                <div className={classes.checkbox_box}>
                  {moduleList.map((item) => (
                    <CheckBox
                      key={item.token}
                      label={item.name}
                      checked={editedUserRole.modules.includes(item.token)} // Use item.token here
                      onChange={() => handleEditCheckboxToggle(item.token)}
                    />
                  ))}
                </div>
                {validationError && (
                  <p className={`${classes.errorMessage} text_danger`}>
                    {"Please select the module !"}
                  </p>
                )}
              </div>
            </div>
          </>
        </Modal>
      )}

      <ToastContainer />
    </AnimatePresence>
  );
};

export default UserRoles;
