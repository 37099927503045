
import React from 'react'
import ProfileMenu from '../../UI/ProfileMenu/ProfileMenu'
import classes from './Header.module.css'
const Header = () => {

    return (
        <nav className={classes.nav}>
            <ProfileMenu />
        </nav>

    )
}

export default Header