import React, { useState, useEffect } from 'react'
import style from './NotificationSettings.module.css'
import SwitchComponts from '../SwitchComponts/SwitchComponts'
import useApiCall from '../../../hooks/useApiCall';
import { getUserToken } from '../../../../../utils/utils';
import config from '../../../../../app.json';


const NotificationSettings = () => {
    const { sendRequest: getNotificationSettings } = useApiCall();

    const { sendRequest: getVisitorsEmail } = useApiCall();
    const { sendRequest: getShortlistedEmail } = useApiCall();
    const { sendRequest: getsChats } = useApiCall();
    const { sendRequest: getProfileViewed } = useApiCall();
    const { sendRequest: getNewMatches } = useApiCall();
    const { sendRequest: getDailyMatches } = useApiCall();


    const [notificationSettings, setNotificationSettings] = useState([]);


    const [requests, setRequests] = useState('');
    const [shortlists, setShortlists] = useState('');
    const [chats, setChats] = useState('');
    const [profileViewed, setProfileViewed] = useState('');
    const [newMatches, setNewMatches] = useState('');
    const [dailyMatches, setDailyMatches] = useState('');

    const handleRequests = (event) => {
        const newChecked = event.target.checked;
        setRequests(newChecked);
        getVisitorsEmailApi()
    };

    const handleShortlists = (event) => {
        const newChecked = event.target.checked;
        setShortlists(newChecked);
        getShortlistedEmailApi()
    };

    const handleChats = (event) => {
        const newChecked = event.target.checked;
        setChats(newChecked);
        getsChatsApi()
    };

    const handleProfileViewed = (event) => {
        const newChecked = event.target.checked;
        setProfileViewed(newChecked);
        getProfileViewedApi()
    };


    const handleNewMatches = (event) => {
        const newChecked = event.target.checked;
        setNewMatches(newChecked);
        getNewMatchesApi()
    };
    const handleDailyMatches = (event) => {
        const newChecked = event.target.checked;
        setDailyMatches(newChecked);
        getDailyMatchesApi()
    };


    const getVisitorsEmailApi = () => {
        getVisitorsEmail(
            {
                url: "my-account/notification-setting/toggle-requests",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
        );
    }
    const getShortlistedEmailApi = () => {
        getShortlistedEmail(
            {
                url: "my-account/notification-setting/toggle-shortlists",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
        );
    }
    const getsChatsApi = () => {
        getsChats(
            {
                url: "my-account/notification-setting/toggle-chats",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
        );
    }
    const getProfileViewedApi = () => {
        getProfileViewed(
            {
                url: "my-account/notification-setting/toggle-visitors",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
        );
    }
    const getNewMatchesApi = () => {
        getNewMatches(
            {
                url: "my-account/notification-setting/toggle-new-matches",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
        );
    }
    const getDailyMatchesApi = () => {
        getDailyMatches(
            {
                url: "my-account/notification-setting/toggle-daily-matches",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
        );
    }

    const getNotificationSettingsApi = () => {
        getNotificationSettings(
            {
                url: "my-account/notification-setting/read",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                setNotificationSettings(data.data)
            }
        );
    }

    useEffect(() => {
        getNotificationSettingsApi()

    }, []);

    useEffect(() => {
        if (notificationSettings.request_notification === 1) {
            setRequests(true)
        } else if (notificationSettings.request_notification === 0) {
            setRequests(false)
        }
        if (notificationSettings.shortlist_notification === 1) {
            setShortlists(true)
        } else if (notificationSettings.shortlist_notification === 0) {
            setShortlists(false)
        }
        if (notificationSettings.chat_notification === 1) {
            setChats(true)
        } else if (notificationSettings.chat_notification === 0) {
            setChats(false)
        }

        if (notificationSettings.visitor_notification === 1) {
            setProfileViewed(true)
        } else if (notificationSettings.visitor_notification === 0) {
            setProfileViewed(false)
        }
        if (notificationSettings.new_matches_notification === 1) {
            setNewMatches(true)
        } else if (notificationSettings.new_matches_notification === 0) {
            setNewMatches(false)
        }

        if (notificationSettings.daily_matches_notification === 1) {
            setDailyMatches(true)
        } else if (notificationSettings.daily_matches_notification === 0) {
            setDailyMatches(false)
        }

    }, [notificationSettings])

    return (
        <div className={style.pageContentContainer} >
            <div className={style.edit_cont}>
                <p className={style.title}>Notification Settings</p>
                <p className={style.sub__title}>If you turn these off, you won’t be notified of any member’s activity on your profile. This will help you to customize your communication preferences.</p>
            </div>
            <div className={style.notification}>
                {/* <div className={style.profile_settings}>
                    <SwitchComponts title="Requests" subTitle="When members request for your information" checked={requests} onChange={handleRequests} />
                </div> */}
                <div className={style.profile_settings}>
                    <SwitchComponts title="Shortlists" subTitle="When members shortlist you" checked={shortlists} onChange={handleShortlists} />
                </div>
                <div className={style.profile_settings}>
                    <SwitchComponts title="Chats" subTitle="When members are online or initiate chat" checked={chats} onChange={handleChats} />
                </div>

                <div className={style.profile_settings}>
                    <SwitchComponts title="Profile viewed" subTitle="When members Viewed your profile information" checked={profileViewed} onChange={handleProfileViewed} />
                </div>
            </div>
            {/* <div className={style.matches}>
                <p className={style.title}>Matches</p>

                <div className={style.profile_settings}>
                    <SwitchComponts title="New Matches" subTitle="Everday Matches" checked={newMatches} onChange={handleNewMatches} />
                </div>
                <div className={style.profile_settings}>
                    <SwitchComponts title="Daily Matches" subTitle="Everyday Matches" checked={dailyMatches} onChange={handleDailyMatches} />
                </div>
            </div> */}
        </div>
    )
}

export default NotificationSettings
