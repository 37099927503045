import React from "react"
import style from "./Viewer.module.css"
import { checkPdf } from "../../../../../../utils/utils"
import Image from "../../../../../../ui/Image/Image"
import closeIcon from "../../../../assets/svg/Signup/Close.svg"

export const Viewer = ({doc, onClick}) => {
    return(
        <div className={style.documentViewer}>
            <Image src={closeIcon} onClick={onClick} className={style.closeIcon}/>
           {
              checkPdf(doc) ?
                <embed src={`${doc}#toolbar=0`} className={style.previewFile} />
              :
                <Image src={doc} className={style.image}/>
           } 
        </div>
    )
}