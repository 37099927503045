import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import Image from "../../../../ui/Image/Image";
import menu from "../../assets/PNG/menu.png";
import logo from "../../assets/SVG/Dashboard/Logo.svg";
import { NavLink } from "react-router-dom";
import useApiHttp from "../../hooks/ues-http";
import { BASE_URL } from "../../utils/baseUrl";
import { useNumber } from "../../Context/CreatContext";
import Tooltip from "@material-ui/core/Tooltip";
import { AnimatePresence, motion } from "framer-motion";

const MsgBox = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
  font-family: ${(props) => (props.active ? "Bold" : "Medium")};
  font-size: 14px;
  color: ${(props) => (props.active ? "#EF7435" : "#FFFFFF")};
  transition: 0.2s;
  padding: 5px 8px;
  background: ${(props) => (props.active ? "#FFFFFF" : "#EF7435")};
  border-radius: 4px;
`;

const Sidebar = (props) => {
  const { isActives, onClick } = props;
  const [sideMenuItems, setSideMenuItems] = useState([]);
  const { count, widgetCountRequestData } = useNumber();
  const { sendRequest } = useApiHttp();
  const sideMenu = (data) => {
    setSideMenuItems(data.data);
  };
  const sideMenuRequst = () => {
    sendRequest(
      {
        url: `${BASE_URL}module-side-list`,
        method: "POST",
      },
      sideMenu
    );
  };
  useEffect(() => {
    sideMenuRequst();
  }, []);

  const clearTapIndex = () => {
    localStorage.removeItem("userTabIndex");
    localStorage.removeItem("manageTabIndex");
  };

  return (
    <AnimatePresence>
      <motion.section
        initial={{ opacity: 1 }} // Initial position off-screen to the left
        animate={{ opacity: 1 }} // Final position, animating from left to righ
        exit={{ opacity: 1 }}
        transition={{
          duration: 0.0,
        }}
        id="sidebar"
        className={isActives ? "hide" : null}>
        <div>
          <img
            onClick={onClick}
            src={menu}
            alt="menu"
            style={{ width: "31px" }}
            className="close"
          />
        </div>
        <a href="#" className="brand">
          <Image src={logo} alt="logo" className="brand_img" />
        </a>
        <ul className="side-menu top">
          {sideMenuItems.map((sidebar, index) => (
            <NavLink
              to={`/admin/dashboard/${sidebar.redirect_url}`}
              key={index}
              exact={true}>
              {({ isActive }) => (
                <motion.li
                  initial={{ x: -1000, color: "#737373", opacity: 0 }} // Initial position off-screen to the left
                  animate={{ x: 0, color: "#303030", opacity: 1 }} // Final position, animating from left to right
                  exit={{ x: -1000, color: "#737373", opacity: 0 }}
                  transition={{
                    duration: 0.4,
                    delay: index * 0.1,
                    type: "just",
                    ease: "easeInOut",
                  }}
                  className={isActive ? "active" : ""}
                  onClick={clearTapIndex}>
                  <motion.a whileHover={{ scale: 1.1 }} href="#">
                    <Tooltip title={sidebar.name}>
                      <img
                        src={
                          isActive
                            ? `${sidebar.active_image}`
                            : `${sidebar.inactive_image}`
                        }
                        alt={sidebar.name}
                      />
                    </Tooltip>
                    <span className="text">{sidebar.name}</span>
                    {count == 0
                      ? ""
                      : sidebar.name === "Manage Users" && (
                          <span className={`message_box active`}>{count}</span>
                        )}
                  </motion.a>
                </motion.li>
              )}
            </NavLink>
          ))}
        </ul>
      </motion.section>
    </AnimatePresence>
  );
};

export default Sidebar;
