import React, { useState, useEffect } from 'react'
import style from './AlertsUpdates.module.css'
import SwitchComponts from '../SwitchComponts/SwitchComponts'
import useApiCall from '../../../hooks/useApiCall';
import emailIcon from "../../../assets/common/email.svg"
import { getUserToken } from '../../../../../utils/utils';
import config from '../../../../../app.json';
import Image from '../../../../../ui/Image/Image';
import { useNavigate } from 'react-router-dom';

const AlertsUpdates = () => {
    const { sendRequest: getAlertsUpdates } = useApiCall();

    const navigation = useNavigate()

    const { sendRequest: getVisitorsEmail } = useApiCall();
    const { sendRequest: getShortlistedEmail } = useApiCall();
    const { sendRequest: getProfilesEmail } = useApiCall();
    const { sendRequest: getDailyMatches } = useApiCall();
    const { sendRequest: getSimilarProfilesEmail } = useApiCall();

    const [alertsUpdates, setAlertsUpdates] = useState([]);

    const [visitorsEmail, setVisitorsEmail] = useState(true);
    const [shortlistedEmail, setShortlistedEmail] = useState(false);
    const [profilesEmail, setProfilesEmail] = useState(true);
    const [dailyMatches, setDailyMatches] = useState(false);
    const [similarProfilesEmail, setSimilarProfilesEmail] = useState(false);

    const handleVisitorsEmail = (event) => {
        const newChecked = event.target.checked;
        setVisitorsEmail(newChecked);
        getVisitorsEmailApi()
    };

    const handleShortlistedEmail = (event) => {
        const newChecked = event.target.checked;
        setShortlistedEmail(newChecked);
        getShortlistedEmailApi()
    };

    const handleProfilesEmail = (event) => {
        const newChecked = event.target.checked;
        setProfilesEmail(newChecked);
        getProfilesEmailApi()
    };
    const handleDailyMatches = (event) => {
        const newChecked = event.target.checked;
        setDailyMatches(newChecked);
        getDailyMatchesApi()
    };
    const handleSimilarProfilesEmail = (event) => {
        const newChecked = event.target.checked;
        setSimilarProfilesEmail(newChecked);
        getSimilarProfilesEmailApi()
    };

    const getVisitorsEmailApi = () => {
        getVisitorsEmail(
            {
                url: "my-account/alert-setting/toggle-visitors",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {

            }
        );
    }
    const getShortlistedEmailApi = () => {
        getShortlistedEmail(
            {
                url: "my-account/alert-setting/toggle-shortlists",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {

            }
        );
    }
    const getProfilesEmailApi = () => {
        getProfilesEmail(
            {
                url: "my-account/alert-setting/toggle-visits",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {

            }
        );
    }
    const getDailyMatchesApi = () => {
        getDailyMatches(
            {
                url: "my-account/alert-setting/toggle-daily-matches",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {

            }
        );
    }
    const getSimilarProfilesEmailApi = () => {
        getSimilarProfilesEmail(
            {
                url: "my-account/alert-setting/toggle-similar-profiles",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {

            }
        );
    }


    const getProfileSettingsApi = () => {
        getAlertsUpdates(
            {
                url: "my-account/alert-setting/read",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                setAlertsUpdates(data.data)
            }
        );
    }

    useEffect(() => {
        getProfileSettingsApi()

    }, []);

    useEffect(() => {
        if (alertsUpdates.visitor_alert === 1) {
            setVisitorsEmail(true)
        } else if (alertsUpdates.visitor_alert === 0) {
            setVisitorsEmail(false)
        }
        if (alertsUpdates.shortlist_alert === 1) {
            setShortlistedEmail(true)
        } else if (alertsUpdates.shortlist_alert === 0) {
            setShortlistedEmail(false)
        }
        if (alertsUpdates.visits_alert === 1) {
            setProfilesEmail(true)
        } else if (alertsUpdates.visits_alert === 0) {
            setProfilesEmail(false)
        }
        if (alertsUpdates.daily_matches_alert === 1) {
            setDailyMatches(true)
        } else if (alertsUpdates.daily_matches_alert === 0) {
            setDailyMatches(false)
        }
        if (alertsUpdates.similar_profiles_alert === 1) {
            setSimilarProfilesEmail(true)
        } else if (alertsUpdates.similar_profiles_alert === 0) {
            setSimilarProfilesEmail(false)
        }

    }, [alertsUpdates])

    return (
        <div className={style.pageContentContainer} >
            {
                alertsUpdates?.has_email_verification ?
                <>
                    <div className={style.emailVerificationContainer}>
                        <div className={style.emailVerificationImageContainerContainer}>
                            <Image src={emailIcon} />
                            <div className={style.emailTitleContainer}>
                                <p className={style.title}>Verify Email</p>
                                <p className={style.description}>Your email needs to be verified for receiving regular alerts and updates. Please verify it</p>
                            </div>
                        </div>
                        <p className={style.getOtpButton} onClick={()=> navigation(0)}>Check</p>
                    </div>
                    <div className={style.edit_cont}>
                        <p className={style.title}>Alerts & Updates</p>
                        <p className={style.sub__title}>If you turn these off, you won’t be notified of any Alerts and Updates activity on your profile. This will help you to customize your communication preferences.</p>
                    </div>
                    <div className={style.profile_settings}>
                        <SwitchComponts disabled={true} title="Recent Visitors Email" subTitle="An email notification of Members who have recently Viewed your Profile" checked={visitorsEmail} onChange={handleVisitorsEmail} />
                    </div>
                    <div className={style.profile_settings}>
                        <SwitchComponts disabled={true} title="Members who Shortlisted you Email" subTitle="An email notification of Members who have recently Shortlisted your Profile" checked={shortlistedEmail} onChange={handleShortlistedEmail} />
                    </div>
                </>
                :
                <>
                    <div className={style.edit_cont}>
                        <p className={style.title}>Alerts & Updates</p>
                        <p className={style.sub__title}>If you turn these off, you won’t be notified of any Alerts and Updates activity on your profile. This will help you to customize your communication preferences.</p>
                    </div>
                    <div className={style.profile_settings}>
                        <SwitchComponts title="Recent Visitors Email" subTitle="An email notification of Members who have recently Viewed your Profile" checked={visitorsEmail} onChange={handleVisitorsEmail} />
                    </div>
                    <div className={style.profile_settings}>
                        <SwitchComponts title="Members who Shortlisted you Email" subTitle="An email notification of Members who have recently Shortlisted your Profile" checked={shortlistedEmail} onChange={handleShortlistedEmail} />
                    </div>
                    {/* <div className={style.profile_settings}>
                        <SwitchComponts title="Viewed Profiles Email" subTitle="An email reminder containing Profiles you have Viewed recently but not yet Expressed Interest in." checked={profilesEmail} onChange={handleProfilesEmail} />
                    </div> */}
                    {/* <div className={style.profile_settings}>
                        <SwitchComponts title="Daily Matches" subTitle="Everyday Matches" checked={dailyMatches} onChange={handleDailyMatches} />
                    </div> */}
                    {/* <div className={style.profile_settings}>
                        <SwitchComponts title="Similar Profiles Email" subTitle="An email containing Profiles that are similar to the ones you have liked recently." checked={similarProfilesEmail} onChange={handleSimilarProfilesEmail} />
                    </div> */}
                </>
            }
        </div>
    )
}

export default AlertsUpdates
