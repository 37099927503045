import React from "react"
import style from "./SubscribeWidget.module.css"
import updgradeToEliteIcon from "../../assets/common/subscribeGroupPhoto.png"
import Image from "../../../../ui/Image/Image"
import Button from "../../../../ui/Button/Button"
import { useNavigate } from "react-router-dom"

export const SubscribeWidget = ({height}) => {

    const navigation = useNavigate()
    return(
        <div className={style.updgradeToEliteContainer}>
            <div className={style.updgradeToEliteContentsContainer} style={{ minHeight: `${height}vh` }}>
                <div className={style.updgradeToEliteContentContainer}>
                    <Image src={updgradeToEliteIcon} className={style.image}/>
                    <p className={style.titleText}>Verified profiles waiting for you</p>
                    <p className={style.subtitleText}>Subscribe to view all the members looking for you</p>
                    <Button onClick={()=> {
                        localStorage.setItem('subscriptionFromHome', 3);
                        navigation("/subscribe")
                    }} className={`primaryButton ${style.subscribeButton}`}>Subscribe Now</Button>
                </div>
            </div>
        </div>
    )
}