import React from "react"
import style from "./BasicUserRedirection.module.css"
import redirectImage from "../../assets/common/403_page.svg"
import Image from "../../../../ui/Image/Image"

export const BasicUserRedirection = () => {
    return(
        <div className={style.redirectionPageContainer}>
            <Image src={redirectImage} />
        </div>
    )
}