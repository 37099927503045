import React from 'react'
import style from  "./AppStore.module.css"
import Image from '../../../../../ui/Image/Image'
import appstoreImage from "../../../assets/png/LandingPage/Mobile Mockup@2x.png"
import playstoreImage from "../../../assets/svg/LandingPage/Playstore.svg"
import appleStoreImage from "../../../assets/svg/LandingPage/AppleStore.svg"

export default function AppStore() {
  return (
    <div className={style.appStoreContainer}>
        <div className={`${style.appStoreData} container p-t-60 p-b-60`}>
            <div className={style.image}>
                <Image className={style.image} src={appstoreImage}/>
            </div>
            <div className={style.content}>
                <p className={style.headingLabel}>#1 Trusted matrimony app among doctors</p>
                <p className={style.heading}>Doctors Matrimony</p>
                <p className={style.description}>Welcome to the Doctors Matrimony App, where healthcare professionals find their perfect match! Our app is designed exclusively for doctors, providing a secure and tailored platform to connect and build meaningful relationships.</p>
                
                <p className={style.storeLabel}>Get Doctors Matrimony App on Android & IOS</p>
                <div className={style.storeImagesContainer}>
                    <Image src={playstoreImage}/>
                    <Image src={appleStoreImage}/>
                </div>
            </div>
        </div>
    </div>
  )
}
