import React from "react";
import { Link } from "react-router-dom";
import style from "./Stories.module.css";

import couples from "../../../assets/png/ApplicationStatus/Couple.png";
import CarouselItem from "../../../../../ui/Carousel/CarouselItem/CarouselItem";
import Gallery from "../../../../../ui/Carousel/Gallery/Gallery";
import timerIcon from "../../../assets/common/groupTimer.svg"
import declinedStoryIcon from "../../../assets/common/declinedStory.svg"
import statusPublishedIcon from "../../../assets/common/statusPublished.svg"
import Image from "../../../../../ui/Image/Image";

const Stories = ({data, title, label}) => {
    console.log("data", data);
    const galleryItems = data?.map((item, index) => (
        <div className={style.carouselCard} key={index} id="arrowRemove">
            {
                item?.status === "Pending" && (
                    <div className={ style.statusBadge }>
                        <Image src={timerIcon} />
                        <p className={style.statusText}>Waiting For Verification</p>
                    </div>
                )
            }
            {
                item?.status === "Declined" && (
                    <div className={ `${style.statusBadge} ${style.declienedBadge}` }>
                        <Image src={declinedStoryIcon} />
                        <p className={style.statusText}>Declined</p>
                    </div>
                )
            }
            {
                item?.status === "Published" && (
                    <div className={ `${style.statusBadge} ${style.statusBadgePublished} ` }>
                        <Image src={statusPublishedIcon} />
                        <p className={style.statusTextPublished}>Published</p>
                    </div>
                )
            }
            {
                item?.status === "Approved" && (
                    <div className={ `${style.statusBadge} ${style.statusBadgePublished} ` }>
                        <Image src={statusPublishedIcon} />
                        <p className={style.statusTextPublished}>Approved</p>
                    </div>
                )
            }
            <div className={style.ImageGallery}>
                <Gallery
                    cols={1}
                    rows={1}
                    gap={5}
                    autoplay={2000}
                    ImageUrl={item?.images}
                    className={style.couplesFeedBack}
                    mobileBreakpoint={520}
                    responsiveLayout={[
                        { breakpoint: 1024, cols: 1 },
                        { breakpoint: 750, cols: 1, rows: 1, gap: 10 },
                        {
                            breakpoint: 520,
                            autoplay: 3000,
                            cols: 1,
                            loop: true,
                            gap: 15,
                        },
                    ]}
                />
            </div>
            <div className={style.slideBox}>
                <h3>Dr.{item?.bride_name} & Dr.{item?.groom_name}</h3>
                <p>{item?.success_story}</p>
            </div>
        </div>
    ));

    return (
        <div className={style.StoriesContainer}>
            <div className={`${style.bannerSectionContainer} container`}>
                {
                    title && (
                        <div className={style.textContainer}>
                            <h2>
                                Thousands of Doctors have found their life partner at
                                Doctors Matrimony!
                            </h2>
                        </div>
                    )
                }
                <div className={style.CarouselOutline} id="arrowMark">
                    {
                        data?.length ?
                            galleryItems
                        :
                        <p className={style.noDataFound}>No stories found.</p>
                    }
                </div>
            </div>
        </div>
    );
};

export default Stories;