import React, { useEffect, useMemo, useState } from "react";
import style from "../../../pages/Chat/Chat.module.css"
import Image from "../../../../../ui/Image/Image"
import drImage from "../../../pages/Chat/assets/Dr. Vidhya@2x.png"
import maleAvatar from "../../../assets/common/profile_active.png"
import femaleAvatar from "../../../assets/common/female.svg"
import { collection,doc,setDoc,getDoc,getDocs,Timestamp,onSnapshot,query,orderBy,where, Firestore, updateDoc } from "firebase/firestore"; 
import { borderRadius } from "@mui/system";
import { formatTimeAgo } from "../../../../../utils/utils";
import $ from "jquery";


const ChatListData1 = (props) => {
    const {db,det,clickHandle, chatDetails, state} = props;
    const [newMsgCount, setNewMsgCount] = useState(0);
    const [unreadMsgTime, setUnreadMsgTime] = useState("");
    let newMsgListenerUnsub;
    let profile_image = det?.participant_image !== undefined ? det?.participant_image : '';
    let date = new Date();
    const ques = query(collection(db, `Chats/${det.doc_id}/message`), orderBy("msg_time", "asc"), where("msg_time", ">=", date));

    
    const updateLastSeenQuery = doc(db, "Chats", det.doc_id);

    let onlineOfflineStatus = style.offline;
    if(det.isOnline === true){
        onlineOfflineStatus = style.online;
    }

    const [ token, setToken ] = useState(det?.participant_token);
    
    const docRef = doc(db, "Chats", `${det.doc_id}`);

    const docRefActive = doc(db, "Chats", `${chatDetails?.doc_id}`);

    useEffect(()=> {
        if( localStorage?.getItem("sidebarToChatToken") ) {
           $(`#${localStorage?.getItem("sidebarToChatToken")}`).trigger("click")
        }
    },[localStorage?.getItem("sidebarToChatToken")])

    const onClickListHandler = () => {
        localStorage?.removeItem("sidebarToChatToken")
        setNewMsgCount(0)
        clickHandle(det,token)
        let lastSeenDate = new Date();
        if(chatDetails?.doc_id !== undefined && chatDetails?.doc_id !== det?.doc_id){
            updateDoc(docRefActive, {
                [`${chatDetails?.participant_token}_msg_seen`]: 0,
            });
        }
      
    }


    const startOfWeek = (date) => {
        const firstDayOfWeek = new Date(date);
        firstDayOfWeek.setDate(date.getDate() - date.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
        return firstDayOfWeek;
      }
      
    const endOfWeek = (date) => {
        const lastDayOfWeek = new Date(date);
        lastDayOfWeek.setDate(date.getDate() - date.getDay() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
        return lastDayOfWeek;
      }
      
    const getUnReadMsgTime = (incTime) => {
        let todayDateTime = new Date();
        let msgDateTime = incTime.toDate();
        let msgTime = incTime.toDate().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        let msgDate = incTime.toDate().toDateString()
        let todayDateTimeDateVal = todayDateTime.getDate();
        let todayDateTimeMonthVal = todayDateTime.getMonth();
        let todayDateTimeYearVal = todayDateTime.getFullYear();
        let msgDateVal = msgDateTime.getDate()
        let msgMonthVal = msgDateTime.getMonth();
        let msgYearVal = msgDateTime.getFullYear();
        const daysOfCurrentWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        if(todayDateTimeMonthVal === msgMonthVal && todayDateTimeYearVal === msgYearVal){
            if(msgDateVal === todayDateTimeDateVal){
                
                    return "Today";
                

            }else if((todayDateTimeDateVal - 1) === msgDateVal){
                
                    return "Yesterday";
                

            }else if(msgDateTime >= startOfWeek(todayDateTime) && msgDateTime <= endOfWeek(todayDateTime)){
                const dayOfWeekIndex = msgDateTime.getDay();
               
                    return daysOfCurrentWeek[dayOfWeekIndex];
                 
            }else {
               
                    return msgDate

               
            }
        }else{
            
                return msgDate

            
        }
        
    }

    

     useEffect(() => {
        if(det.chatCountEnable) {
           
            setNewMsgCount(0);
            let userLastSeen = det[`${det?.user_token}_msg_seen`];
            let dateForLastSeen = userLastSeen !== null ? userLastSeen: new Date();
            let queryForLastSeen = query(collection(db, `Chats/${det.doc_id}/message`), orderBy("msg_time", "asc"), where("msg_time", ">=", dateForLastSeen));
           
            
                getDocs(queryForLastSeen).then((snap)=>{
                    setNewMsgCount(snap.size);
                    if(snap.size > 0){
                        let i = 0;
                        snap.forEach(function(doc){
                            if(i === snap.size - 1){
                                let dateVal = getUnReadMsgTime(doc.data().msg_time);
                                setUnreadMsgTime(dateVal);
                            }
                            i++;
                        })
                       
                    }
                    if(newMsgListenerUnsub === undefined){
                        newMsgListenerUnsub = onSnapshot(queryForLastSeen, (snap) => {
                            setNewMsgCount(snap.size);
                            if(snap.size > 0){
                                let i = 0;
                                snap.forEach(function(doc){
                                    if(i == snap.size - 1){
                                        let dateVal = getUnReadMsgTime(doc.data().msg_time);
                                        setUnreadMsgTime(dateVal);
                                    }
                                    i++;
                                })
                            }
                           
                        })
                    }
                })
            // } 
          
            
        } else{

            
            setNewMsgCount(0);
            setUnreadMsgTime('');
            if(newMsgListenerUnsub !== undefined){
                newMsgListenerUnsub();
            }
            let lastSeenDate = new Date();
            updateDoc(docRef, {
                [`${det?.participant_token}_msg_seen`]:  0,
                
            });
              
        }

        return () => {
            let lastSeenDate = new Date();
         
            setNewMsgCount(0);
            if(newMsgListenerUnsub !== undefined){
                newMsgListenerUnsub();
            }
            if( chatDetails?.doc_id ) {
                updateDoc(docRef, {
                    [`${chatDetails?.participant_token}_msg_seen`]:  0,
                });
            }
        }
    
      
    }, [det.chatCountEnable])
    
    const lastMessageTimestamp = det.lastMessageTimestamp;
    const timeAgo = formatTimeAgo(lastMessageTimestamp);

    return (
       
            <div onClick={onClickListHandler} id={det.participant_token} style={{cursor:"pointer"}} className={style.notificationListContainer}>
                <div className={style.notificationImageNameContainer}>
                    <div className={style.profileImageContainer}>
                        <Image 
                            src={ det?.isDeleted ? maleAvatar : process.env.REACT_APP_S3_CLOUD_FRONT_URL+ profile_image}
                            width={70}
                            height={70}
                            borderRadius={"50%"}
                        />
                        { !det?.isDeleted && (<div className={`${style.onlineStatus} ${onlineOfflineStatus}`}></div>) }
                    </div>
                    <div className={style.notificationName}>
                    { !det?.isDeleted && ( <p className={style.id}>{det.participant_profile_id}</p> )}
                        <p className={style.name}>{ det?.isDeleted ? "Unknown User" : det.participant_name }</p>
                        {(det[`${det.participant_token}_msg_seen`] > 0 && det?.chatCountEnable) ? (
                            <p className={style.shortMessage}>Has messaged you</p>
                        ): det?.chatCountEnable ? ( <p className={style.shortMessage}>{det.lastMessageText.slice(0, 25)}{det.lastMessageText?.length > 25 && "..."}</p> ) : <></>}
                    </div>
                </div>
                
                    <div className={style.notificationTime}>
                    { !det?.isDeleted && (<p className={style.time}>{timeAgo}</p>)}
                        { (det[`${det.participant_token}_msg_seen`] > 0 && det?.chatCountEnable) ? (
                            <p className={style.newMessageCount}>{ det[`${det.participant_token}_msg_seen`]}</p>
                        ):  <p  className={style.newNoMessageCount}></p> }
                    </div>
                
               
            </div>
                       
    )

}
export default ChatListData1;