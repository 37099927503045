import React, { useState, useEffect } from 'react';
import styles from './MultiSelectDropdown.module.css';
import dropdownIcon from "../assets/dropdown.svg"
import ModalBox from '../Modal/ModalBox';
import MultiSelect from '../../modules/web/features/PartnerPreference/BasicPreference/Modal/MultiSelect';
import { avoidScroll } from '../../utils/utils';

function MultiSelectDropdown({ label, options, responseType, value, style, name, onChange }) {
  const [isFocused, setIsFocused] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [displayOptions, setDisplayOptions] = useState("");

  useEffect(() => {
    // Initialize selected options when the component mounts
    if (value) {
      setSelectedOptions(value);
      handleData(value)
    }
  }, [value]);
  
  const handleData = (data) => {
    onChange(data, name )
    setSelectedOptions(data);
    let dataValue = ""
    data?.map((item, index) => {
      dataValue += `${item.name}${index === data.length -1 ? "" : ","}`
    })
    if (dataValue.length > 30) {
      const truncatedText = dataValue.slice(0, 30) + '...';
      setDisplayOptions(truncatedText)
    } else {
      setDisplayOptions(dataValue)
    }
    setIsFocused(false)
  }

  return (
    <>
      <ModalBox
        open={isFocused} 
        width={"598px"} 
        modalContent={
          <MultiSelect
            name={name}
            label={label}
            responseType={responseType}
            selectedData={selectedOptions}
            value={value}
            options={options}
            setIsFocused={setIsFocused}
            handleData={handleData}
          />
        } 
      />
      <div style={style} className={`${styles['popout-input']} ${styles.focused}`}>
        <label className={styles['input-label']}>{label}</label>
        <div className={styles['custom-dropdown']}>
          <div
            className={styles['dropdown-toggle']}
            onClick={() => {
              avoidScroll()
              setIsFocused(!isFocused)
            }}
          >
            {
              displayOptions !== "" ?
                <p className={`${styles?.value} multiselect_dropdown_value`}>{displayOptions}</p>
              : 
                <p className={styles?.value}>Select an option</p>
            }
            <img src={dropdownIcon} alt="Dropdown Icon" className={styles.dropdownIcon}/>
          </div>
        </div>
      </div>
    </>
  );
}

export default MultiSelectDropdown;
