import React, { useState, useEffect } from "react"
import CulomeData from "../../UI/Culome/CulomeData"
import ViewPage from "../ViewPage/ViewPage"
import { useParams, useNavigate } from "react-router-dom"
import { BASE_URL } from "../../utils/baseUrl"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useApiHttp from "../../hooks/ues-http"
import Tabs from "../../UI/Tabs/Tabs"
import Table from "../../UI/Table/Table"
import Search from "../../UI/Search/Search"
import TableHeader from "../../UI/Table/TableHeader"
import classes from "./UsersViewPage.module.css"
import Card from "../../UI/Card/Card"
import Chips from "../../UI/Chip/Chip"
import Image from "../../UI/Img/Img"
import Loader from "../../UI/Loader/Loader"
import totalrevenue from "../../assets/SVG/Manage Users/TotalRevenue.svg"
import yearpayments from "../../assets/SVG/common/YearlyPayment.svg"
import monthlypayments from "../../assets/SVG/Manage Users/MonthlyPayments.svg"
import Elite from "../../assets/SVG/Manage Users/Elite.svg"
import Premium from "../../assets/SVG/Manage Users/Premium.svg"
import blocked from "../../assets/SVG/Manage Users/blocked.svg"
import info from "../../assets/SVG/Manage Users/info.svg"
import groupicon from "../../assets/SVG/common/Groupicon.svg"
import invoice from "../../assets/SVG/Manage Users/DownloadInvoice.svg"
import VideoPlayers from "../../features/ManageUsers/Video/video"
import PlayBtn from "../../assets/SVG/Manage Users/VideoPlay.svg"
import basic from "../../assets/SVG/common/white.svg"
import ImageCarousel from "../../UI/Carousel/Carousel"
import Swal from "sweetalert2"
import Tooltip from "@material-ui/core/Tooltip"
import invoicedisble from "../../assets/SVG/Manage Users/DownloadInvoicegray.svg"
import Modal from "../../UI/Modal/ScrollingModal/ScrollingModal"
import { RadioButton } from "../../UI/RadioButton/RadioButton"
import ViewSmallDocument from "../../UI/ViewDocument/viewSmallDocument"
import ViewDocument from "../../UI/ViewDocument/ViewDocument"
import ImrDocument from "../../UI/ViewDocument/ImrDocument"
import SelectBox from "../../UI/SelectFiled/SlectboxRejectResan"
import ThreeCulome from "../../UI/Culome/ThreeCulome"
import { useNumber } from "../../Context/CreatContext"
import { AnimatePresence, motion } from "framer-motion"

const UsersViewPage = ({ userToken, setTokenUser, activeTabs, datas, identifiLable }) => {
  const navigate = useNavigate()
  const { token } = useParams()

  const { count, widgetCountRequestData } = useNumber()
  const [openVideoAndImg, setOpenVideoAndImg] = useState(false)
  const [checkType, setCheckType] = useState("")
  const [videoPhotsData, setVideoPhotsData] = useState({
    tokens: "",
    urls: "",
    videos_type: "",
    status: "",
  })
  const [profileDetails, setProfileDetails] = useState({})
  const [membershipPayment, setMembershipPayment] = useState([])
  const [membershipTableData, setMembershipMembershipTableData] = useState([])
  const [membershipSearch, setMembershipSearch] = useState("")
  const initialActiveTab = parseInt(localStorage.getItem("userTabIndex"), 10)
  const [activeTab, setActiveTab] = useState(initialActiveTab || 0)
  const [videoInterviewDatas, setVideoInterviewDatas] = useState([])
  const [clear, setClear] = useState(false)
  const { isLoading, success, error, sendRequest } = useApiHttp()
  const [storiesUser, setStoriesUser] = useState("1")
  const [photoDesignation, setPhotoDesignation] = useState([])
  const [photoDesigName, setPhotoDesigName] = useState("")
  const [videoDesignation, setVideoDesignation] = useState([])
  const [videoDesigName, setVideoDesigName] = useState("")
  const [errorMsg, setErrorMsg] = useState("")

  console.log("videoDesigName", videoDesigName);
  console.log("photoDesigName", photoDesigName);

  const fetchUserData = type => {
    const requestData = {
      type,
      user_token: `${userToken}`,
    }

    sendRequest(
      {
        url: `${BASE_URL}profile-detail`,
        method: "POST",
        body: requestData,
      },
      data => {
        if (type === "profile") {
          setProfileDetails(data.data)
        } else if (type === "plan") {
          setMembershipPayment(data)
          setMembershipMembershipTableData(data.aaData)
        } else {
          console.error("Error fetching data:", error)
        }
      }
    )
  }
  useEffect(() => {
    fetchUserData("profile")
    fetchUserData("plan")
  }, [])

  const onTabClick = index => {
    setActiveTab(index)
    localStorage.setItem("userTabIndex", index.toString())

    if (index === 0) {
      setMembershipSearch("")
    } else if (index === 1) {
      setMembershipSearch("")
    }
  }

  const {
    isLoading: blackIsLoading,
    success: blackSuccess,
    error: blackError,
    sendRequest: sendRequestBlack,
  } = useApiHttp()
  useEffect(() => {
    if (blackSuccess !== "") {
      toast.success(blackSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (blackError !== "") {
      toast.error(blackError, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [blackSuccess, blackError])

  const fetchBlackData = () => {
    const VerifyEmailData = data => {
      const timeout = setTimeout(() => {
        navigate("/admin/dashboard/manageusers")
        setTokenUser("")
        if (activeTabs === 0) {
          datas("recent")
        } else if (activeTabs === 1) {
          datas("active")
        } else if (activeTabs === 2) {
          datas("inactive")
        } else if (activeTabs === 3) {
          datas("pending")
        } else if (activeTabs === 4) {
          datas("blocked")
        }
      }, 1000)
    }

    const requestData = {
      type: "block",
      user_token: `${userToken}`,
      reason: "empty",
    }

    sendRequestBlack(
      {
        url: `${BASE_URL}profile-detail`,
        method: "POST",
        body: requestData,
      },
      VerifyEmailData
    )
  }

  const {
    isLoading: unBlackIsLoading,
    success: unBlackSuccess,
    error: unBlackError,
    sendRequest: sendRequestUnBlack,
  } = useApiHttp()
  useEffect(() => {
    if (unBlackSuccess !== "") {
      toast.success(unBlackSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (unBlackError !== "") {
      toast.error(unBlackError, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [unBlackSuccess, unBlackError])

  const fetchUnBlackData = () => {
    const VerifyEmailData = data => {
      const timeout = setTimeout(() => {
        navigate("/admin/dashboard/manageusers")
        setTokenUser("")
        if (activeTabs === 0) {
          datas("recent")
        } else if (activeTabs === 1) {
          datas("active")
        } else if (activeTabs === 2) {
          datas("inactive")
        } else if (activeTabs === 3) {
          datas("pending")
        } else if (activeTabs === 4) {
          datas("blocked")
        }
      }, 1000)
    }

    const requestData = {
      type: "unblock",
      user_token: `${userToken}`,
      reason: "verify",
    }

    sendRequestUnBlack(
      {
        url: `${BASE_URL}profile-detail`,
        method: "POST",
        body: requestData,
      },
      VerifyEmailData
    )
  }

  const onBackClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Are you sure do not want to show this profile?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#E04848",
      cancelButtonColor: "#aeaeae",
      confirmButtonText: "Do Not Show",
      customClass: {
        title: "your-custom-title-class",
        content: "your-custom-content-class",
        confirmButton: "your-custom-button-class",
        cancelButton: "your-custom-button-class",
      },
    }).then(result => {
      if (result.isConfirmed) {
        fetchBlackData()
      }
    })
  }
  const onUnBackClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to restore this user?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#EF7435",
      cancelButtonColor: "#aeaeae",
      confirmButtonText: "Show it!",
      customClass: {
        title: "your-custom-title-class",
        content: "your-custom-content-class",
        confirmButton: "your-custom-button-class",
        cancelButton: "your-custom-button-class",
      },
    }).then(result => {
      if (result.isConfirmed) {
        fetchUnBlackData()
      }
    })
  }

  const userName = profileDetails?.user_profile_details?.name || ""
  const infor = `${profileDetails?.user_profile_details?.age} yrs, ${profileDetails?.user_profile_details?.height}, ${profileDetails?.user_profile_details?.mother_tongue},  ${profileDetails?.user_profile_details?.education}, ${profileDetails?.user_profile_details?.religion} |  ${profileDetails?.user_profile_details?.work_city} , ${profileDetails?.user_profile_details?.work_state} `
  const permanentLocation = `${profileDetails?.user_profile_details?.native_city}, ${profileDetails?.user_profile_details?.native_state},  ${profileDetails?.user_profile_details?.native_country} `
  const professionalLocation = `${profileDetails?.user_profile_details?.work_city}, ${profileDetails?.user_profile_details?.work_state},  ${profileDetails?.user_profile_details?.work_country} `
  const membershipType =
    profileDetails?.user_profile_details?.membership_type || ""
  const carouselImg = profileDetails?.user_profile_details?.image || ""

  const columns = [
    {
      name: "S.No",
      selector: row => row.slno,
    },
    {
      name: "Plan",
      selector: row => row.plan_name,
    },
    {
      name: "Plan Duration",
      selector: row => row.plan_duration,
    },
    {
      name: "Plan Amount",
      selector: row => row.plan_amount,
    },
    {
      name: "Payment Date",
      selector: row => row.payment_date,
    },
    {
      name: "Next Payment",
      selector: row => row.next_payment,
    },
    {
      name: "Invoice",
      cell: row => (
        <>
          <Tooltip
            title={
              row.invoice === "Unavailable" ? row.invoice : "Download Invoice"
            }
          >
            <span
              onClick={() => {
                if (row.invoice !== "Unavailable") {
                  handleDownloadInvoice(row.invoice, row.invoice_name)
                }
              }}
              className={`${row.invoice === "Unavailable"
                ? "unavailable"
                : "action_text action_text_pointer w__300"
                }`}
            >
              {row.invoice === "Unavailable" ? (
                <Image src={invoicedisble} alt="Invoice" />
              ) : (
                <Image src={invoice} alt="Invoice" />
              )}{" "}
              Download
            </span>
          </Tooltip>
        </>
      ),
    },
  ]

  const handleDownloadInvoice = (fileLink, fileName) => {
    if (fileLink) {
      fetch(fileLink)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(blob)
          const linkElement = document.createElement("a")
          linkElement.href = url
          linkElement.setAttribute("download", fileName)
          document.body.appendChild(linkElement)
          linkElement.click()
          document.body.removeChild(linkElement)
        })
        .catch(error => {
          console.error("Error downloading file:", error)
        })
    }
  }

  const handleSearchChange = newValue => {
    setMembershipSearch(newValue)
  }

  useEffect(() => {
    // Clear the local storage value when the page is reloaded
    window.addEventListener("beforeunload", () => {
      localStorage.removeItem("userTabIndex")
    })
  }, [])

  const filteredData = membershipTableData.filter(userData => {
    const searchValue = membershipSearch.toLowerCase()
    return Object.values(userData).some(
      value => value && value.toString().toLowerCase().includes(searchValue)
    )
  })

  const onCloseFunction = () => {
    setClear(true)
    setVideoPhotsData({
      ...videoPhotsData,
      tokens: "",
      urls: "",
      videos_type: "",
      status: "",
    })
    setStoriesUser("1")
    setPhotoDesigName("")
    setVideoDesigName("")
    setErrorMsg("")
  }
  const openPopPup = (type, token, url, video_type, status) => {
    setVideoPhotsData({
      ...videoPhotsData,
      tokens: token,
      urls: url,
      videos_type: video_type,
      status: status,
    })
    setCheckType(type)
    setOpenVideoAndImg(true)
  }
  const {
    isLoading: imageIsLoading,
    success: imageSuccess,
    error: imageError,
    sendRequest: sendRequestImage,
  } = useApiHttp()
  const {
    isLoading: videoIsLoading,
    success: videoSuccess,
    error: videoError,
    sendRequest: sendRequestVideo,
  } = useApiHttp()
  useEffect(() => {
    if (imageSuccess !== "") {
      toast.success(imageSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (imageError !== "") {
      toast.error(imageError, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [imageSuccess, imageError])

  useEffect(() => {
    if (videoSuccess !== "") {
      toast.success(videoSuccess, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
    if (videoError !== "") {
      toast.error(videoError, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }
  }, [videoSuccess, videoError])

  const imageUpdate = () => {
    let rejectreason = photoDesigName === "" ? null : photoDesigName
    sendRequestImage(
      {
        url: `${BASE_URL}verify-image`,
        method: "POST",
        body: {
          image_token: videoPhotsData.tokens,
          verification: parseFloat(storiesUser), //0-reject,1-approve,
          user_token: userToken,
          reject_reason: rejectreason,
        },
      },
      data => {
        setOpenVideoAndImg(false)
        fetchUserData("profile")
        widgetCountRequestData()
        setStoriesUser("1")
      }
    )
  }
  const videoUpdate = () => {
    let rejectreason = videoDesigName === "" ? null : videoDesigName
    sendRequestVideo(
      {
        url: `${BASE_URL}verify-video`,
        method: "POST",
        body: {
          token: videoPhotsData.tokens,
          type: videoPhotsData.videos_type, //preference_video for preference , user_video for user
          verification: parseFloat(storiesUser),
          reject_reason: rejectreason,
        },
      },
      data => {
        setOpenVideoAndImg(false)
        fetchUserData("profile")
        widgetCountRequestData()
        setStoriesUser("1")
      }
    )
  }

  const handleOpenVideoAndImg = () => {
    if (checkType === "Videos") {
      if (storiesUser === "0") {
        if (videoDesigName !== "") {
          videoUpdate()
        } else {
          setErrorMsg("please select reason")
        }
      } else {
        videoUpdate()
      }
    } else {
      if (storiesUser === "0") {
        if (photoDesigName !== "") {
          imageUpdate()
        } else {
          setErrorMsg("please select reason")
        }
      } else {
        imageUpdate()
      }
    }
  }

  const radioChangeHandler = e => {
    setStoriesUser(e.target.value)
  }
  const handleImageReasonChange = event => {
    setPhotoDesigName(event.target.value)
  }
  const { sendRequest: imageRejectReasonRequest } = useApiHttp()
  const imageRejectReasonRequestData = () => {
    imageRejectReasonRequest(
      {
        url: `${BASE_URL}image-reject-reason-dropdown`,
        method: "POST",
      },
      data => setPhotoDesignation(data.data)
    )
  }
  useEffect(() => {
    imageRejectReasonRequestData()
  }, [])

  // const radioChangeHandlerVideo = (e) => {
  //   setStoriesUser(e.target.value);
  // };
  const handleVideoReasonChange = event => {
    setVideoDesigName(event.target.value)
  }
  const { sendRequest: videoRejectReasonRequest } = useApiHttp()
  const videoRejectReasonRequestData = () => {
    videoRejectReasonRequest(
      {
        url: `${BASE_URL}video-reject-reason-dropdown`,
        method: "POST",
      },
      data => setVideoDesignation(data.data)
    )
  }
  useEffect(() => {
    videoRejectReasonRequestData()
  }, [])

  const tabAnimation = {
    initial: {
      y: -200,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      y: -200,
      opacity: 0,
    },
  }
  // ======== Animation Function only End ========
  const cardData = [
    {
      src: totalrevenue,
      title: "Total Revenue",
      subTitle: `Rs.${membershipPayment.total_revenue}`,
      className: "card_box green",
    },
    {
      src: yearpayments,
      title: "Quarterly Payments",
      subTitle: `${membershipPayment.quarterly_payment}`,
      className: "card_box darkyellow",
    },
    {
      src: monthlypayments,
      title: "Half-Yearly Payments",
      subTitle: `${membershipPayment.halfyearly_payment}`,
      className: "card_box darkblue",
    },
  ]

  {
    profileDetails?.user_profile_details?.status === "Blocked" && (
      <span className="wigite_lable_lg blacked">
        <Image src={blocked} alt="Premium" /> Profile Hidden
      </span>
    )
  }

  return (
    <AnimatePresence mode="wait">
      <motion.dev
        variants={tabAnimation}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <ViewPage
          identifiLable={identifiLable}
          userToken={userToken}
          setTokenUser={setTokenUser}
          path="/admin/dashboard/manageusers"
          title={userName}
          ids={profileDetails?.user_profile_details?.user_id}
          blackuser={
            <>
              {profileDetails?.user_profile_details?.status === "Blocked" && (
                <span className="black_user" onClick={onUnBackClick}>
                  Show User
                </span>
              )}{" "}
              {profileDetails?.user_profile_details?.status === "Active" && (
                <span className="black_user" onClick={onBackClick}>
                  Do Not Show User
                </span>
              )}
            </>
          }
          children={
            <Tabs
              tabs={[
                {
                  title: "Profile Details",
                  render: () => (
                    <motion.dev
                      variants={tabAnimation}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      {!isLoading ? (
                        <div className={classes.profile_details}>
                          {/* -------------------------------profile_details_left------------------------------------- */}
                          {profileDetails?.user_profile_details ? (
                            <div className={classes.profile_details_left}>
                              <div className={classes.carousel}>
                                <ImageCarousel images={carouselImg} />
                              </div>

                              <div className={classes.user_profile_details}>
                                <p className={classes.user_profile_details_id}>
                                  {
                                    profileDetails?.user_profile_details
                                      ?.user_id
                                  }
                                </p>
                                <p
                                  className={classes.user_profile_details_name}
                                >
                                  {userName}
                                </p>
                                <div className={classes.gap}>
                                  <p className={classes.contant_box_sub}>
                                    {profileDetails?.user_profile_details
                                      ?.preference_status !== 0 ||
                                      profileDetails?.user_profile_details
                                        ?.onboard_status !== 0
                                      ? infor
                                      : ""}
                                  </p>
                                  <div className={classes.border_wigit_set}>
                                    {profileDetails?.user_profile_details
                                      ?.imr_number && (
                                        <span className={classes.border_wigit}>
                                          {
                                            profileDetails?.user_profile_details
                                              ?.imr_number
                                          }
                                        </span>
                                      )}{" "}
                                    {profileDetails?.user_profile_details
                                      ?.mobile_number && (
                                        <span
                                          className={classes.border_wigit}
                                        >{`${profileDetails?.user_profile_details?.mobile_number}`}</span>
                                      )}
                                  </div>
                                </div>

                                <p className={classes.contant_box_titel}>
                                  <Image src={info} alt="info" />{" "}
                                  {`Profile created date - ${profileDetails?.user_profile_details?.created_date}`}
                                </p>
                                {profileDetails?.user_profile_details
                                  ?.preference_status !== 0 ||
                                  profileDetails?.user_profile_details
                                    ?.onboard_status !== 0 ? (
                                  <>
                                    <p className={classes.info}>
                                      {userName} Info
                                    </p>
                                    <div className={classes.culome__two}>
                                      <CulomeData
                                        title="Date of Birth"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.dob
                                        }
                                      />
                                      <CulomeData
                                        title="Email Address"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.email
                                        }
                                      />
                                      <CulomeData
                                        title="Height"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.height
                                        }
                                      />
                                      <CulomeData
                                        title="Weight"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.weight
                                        }
                                      />
                                      <CulomeData
                                        title="Physical Status"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.physical_status
                                        }
                                      />
                                      <CulomeData
                                        title="Marital Status"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.marital_status
                                        }
                                      />
                                      <CulomeData
                                        title="Mother Tongue"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.mother_tongue
                                        }
                                      />
                                      <CulomeData
                                        title="Religion"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.religion
                                        }
                                      />
                                      <CulomeData
                                        title="Community"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.community
                                        }
                                      />
                                      <CulomeData
                                        title="Gothram"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.gothram
                                        }
                                      />
                                      <CulomeData
                                        title="Star"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.star
                                        }
                                      />
                                      <CulomeData
                                        title="Raasi"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.raasi
                                        }
                                      />
                                      <CulomeData
                                        title="Chevvai Dosham"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.chevvai_dosam
                                        }
                                      />
                                      <CulomeData
                                        title="District"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.work_city
                                        }
                                      />
                                      <CulomeData
                                        title="State"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.work_state
                                        }
                                      />
                                      <CulomeData
                                        title="Country"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.work_country
                                        }
                                      />
                                      <CulomeData
                                        title="Citizenship"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.citizenship
                                        }
                                      />
                                    </div>
                                    <div className={classes.culome__one}>
                                      <CulomeData
                                        title="Permanent Location"
                                        subTitle={permanentLocation}
                                      />
                                      <CulomeData
                                        title="Professional Location"
                                        subTitle={professionalLocation}
                                      />
                                    </div>

                                    <div className={classes.culome__one}>
                                      <p className={classes.info}>
                                        NMC/SMC Certificate Details
                                      </p>
                                      <CulomeData
                                        title="NMC/SMC Number"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.imr_number
                                        }
                                      />
                                      {/* <CulomeData
                                        title="Studied College"
                                        subTitle={professionalLocation}
                                      /> */}
                                      <CulomeData
                                        title="Medical Council Name"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.council_name
                                        }
                                      />
                                      <CulomeData
                                        title="Registration"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.council_name
                                        }
                                      />
                                    </div>
                                    <p className={classes.contant_box_titel}>
                                      NMC/SMC Certificate
                                    </p>
                                    <ImrDocument
                                      isLoading={false}
                                      data={
                                        profileDetails?.user_profile_details
                                      }
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="loader_bg">
                                <Loader />
                              </div>
                            </>
                          )}
                          {/* -------------------------------profile_details_right------------------------------------- */}
                          {profileDetails?.user_profile_details ? (
                            <div className={classes.profile_details_right}>
                              <div className={classes.wigite_header}>
                                {membershipType === "Basic" ? (
                                  <span className="wigite_lable_lg basic">
                                    <Image
                                      className={"width_controle"}
                                      src={basic}
                                      alt="Premium"
                                    />
                                    {`${membershipType} Member`}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {membershipType === "Premium" ? (
                                  <span className="wigite_lable_lg premium">
                                    <Image src={Premium} alt="Premium" />
                                    {`${membershipType} Member`}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {membershipType === "Elite" ? (
                                  <span className="wigite_lable_lg elite">
                                    <Image src={Elite} alt="Elite" />
                                    {`${membershipType} Member`}
                                  </span>
                                ) : (
                                  ""
                                )}
                                {profileDetails?.user_profile_details
                                  ?.status === "Blocked" && (
                                    <span className="wigite_lable_lg blacked">
                                      <Image src={blocked} alt="Premium" />{" "}
                                      Profile Hidden
                                    </span>
                                  )}
                              </div>
                              {profileDetails?.user_profile_details
                                ?.onboard_status !== 0 ||
                                profileDetails?.user_profile_details
                                  ?.preference_status !== 0 ? (
                                <>
                                  <div className={classes.chip_box}>
                                    {profileDetails?.user_profile_details?.hobbies.map(
                                      (hobby, index) => (
                                        <Chips key={index} label={hobby} />
                                      )
                                    )}
                                  </div>
                                  <p className={classes.Profile_details_titel}>
                                    About {userName}
                                  </p>
                                  <p
                                    className={classes.Profile_details_subtitel}
                                  >
                                    {
                                      profileDetails?.user_profile_details
                                        ?.abount
                                    }
                                  </p>
                                  <div className={classes.gap_details_box}>
                                    <p
                                      className={classes.Profile_details_titel}
                                    >
                                      {" "}
                                      Professional Details
                                    </p>
                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Institution Name"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.institution_working
                                        }
                                      />
                                      <CulomeData
                                        title="Employed in"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.employed_in
                                        }
                                      />
                                      <CulomeData
                                        title="Occupation"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.user_occupation
                                        }
                                      />
                                      <CulomeData
                                        title="Annual Income"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.annual_income
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="line"></div>
                                  <div className={classes.gap_details_box}>
                                    <ViewDocument
                                      small={true}
                                      isLoading={isLoading}
                                      data={profileDetails?.user_profile_details}
                                      title="Qualification Details"
                                    />
                                  </div>
                                  <div className="line"></div>
                                  <div className={classes.gap_details_box}>
                                    <p className={classes.Profile_details_titel}>
                                      Location Details
                                    </p>
                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Country"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.work_country
                                        }
                                      />
                                      <CulomeData
                                        title="State"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.work_state
                                        }
                                      />
                                      <CulomeData
                                        title="District/City"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.work_city
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="line"></div>
                                  <div className={classes.gap_details_box}>
                                    <p
                                      className={classes.Profile_details_titel}
                                    >
                                      Religious Details
                                    </p>
                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Religion"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.religion
                                        }
                                      />
                                      <CulomeData
                                        title="Community"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.community
                                        }
                                      />
                                      <CulomeData
                                        title="Raasi"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.raasi
                                        }
                                      />
                                      <CulomeData
                                        title="Star"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.star
                                        }
                                      />
                                      <CulomeData
                                        title="Gothram"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.gothram
                                        }
                                      />
                                      <CulomeData
                                        title="Chevvai Dosham"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.chevvai_dosam
                                        }
                                      />
                                    </div>
                                    <div className="document_view">
                                      {profileDetails?.user_profile_details
                                        ?.horoscope !== "" && (
                                          <ViewSmallDocument
                                            isLoading={isLoading}
                                            data={
                                              profileDetails?.user_profile_details
                                                ?.horoscope
                                            }
                                            title="Horoscope"
                                          />
                                        )}
                                    </div>
                                  </div>

                                  <div className="line"></div>
                                  <div className={classes.gap_details_box}>
                                    <p
                                      className={classes.Profile_details_titel}
                                    >
                                      {profileDetails?.user_profile_details
                                        ?.gender === "Male"
                                        ? "His"
                                        : "Her"}{" "}
                                      Habit Details
                                    </p>

                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Eating Habits"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.eating_habbit
                                        }
                                      />
                                      <CulomeData
                                        title="Drinking Habits"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.drinking_habit
                                        }
                                      />
                                      <CulomeData
                                        title="Smoking Habits"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.smoking_habit
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="line"></div>
                                  <div className={classes.gap_details_box}>
                                    <p
                                      className={classes.Profile_details_titel}
                                    >
                                      Family Details
                                    </p>

                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Family Type"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.family_type
                                        }
                                      />
                                      <CulomeData
                                        title="Family Status"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.family_status
                                        }
                                      />
                                      <CulomeData
                                        title="Father’s Occupation"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.father_occupation
                                        }
                                      />
                                      <CulomeData
                                        title="Mother’s Occupation"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.mother_occupation
                                        }
                                      />
                                      <CulomeData
                                        title="No of Brothers"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.brothers
                                        }
                                      />
                                      <CulomeData
                                        title="No of Brothers Married"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.married_brother
                                        }
                                      />
                                      <CulomeData
                                        title="No of Sisters"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.sisters
                                        }
                                      />
                                      <CulomeData
                                        title="No of Sisters Married"
                                        subTitle={
                                          profileDetails?.user_profile_details
                                            ?.married_sister
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="line"></div>

                                  <div className={classes.gap_details_box}>
                                    <p
                                      className={classes.Profile_details_titel}
                                    >
                                      Medical History
                                    </p>

                                    <div className={classes.three_culome}>
                                      {profileDetails?.user_profile_details?.medical_condition.map(
                                        (item, index) => (
                                          <CulomeData
                                            key={index}
                                            title={item.question}
                                            subTitle={item.answers}
                                          />
                                        )
                                      )}
                                    </div>
                                  </div>

                                  <div className="line"></div>

                                  <div className={classes.gap_details_box}>
                                    {
                                      <p
                                        className={
                                          classes.Profile_details_titel
                                        }
                                      >
                                        Basic Preferences
                                      </p>
                                    }

                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Age"
                                        subTitle={profileDetails?.user_preference_details?.user_preference_age.join(
                                          ", "
                                        )}
                                      />
                                      <CulomeData
                                        title="Height"
                                        subTitle={profileDetails?.user_preference_details?.user_preference_height.join(
                                          ", "
                                        )}
                                      />
                                      <CulomeData
                                        title="Physical Status"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.user_preference_physical_status
                                        }
                                      />
                                      <CulomeData
                                        title="Marital Status"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.user_preference_marital_status
                                        }
                                      />
                                      <CulomeData
                                        title="Religion"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.user_preference_religion
                                        }
                                      />
                                      <CulomeData
                                        title="Mother Tongue"
                                        subTitle={profileDetails?.user_preference_details?.preference_mother_tounge.join(
                                          ", "
                                        )}
                                      />
                                    </div>
                                  </div>

                                  <div className="line"></div>

                                  <div className={classes.gap_details_box}>
                                    <p className={classes.Profile_details_titel}>
                                      Professional Preferences
                                    </p>

                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Employed in"
                                        subTitle={profileDetails?.user_preference_details?.user_preference_employed_in.join(
                                          ", "
                                        )}
                                      />
                                      <CulomeData
                                        title="Occupation"
                                        subTitle={profileDetails?.user_preference_details?.user_preference_occupation.join(
                                          ", "
                                        )}
                                      />
                                      <CulomeData
                                        title="Annual Income"
                                        subTitle={profileDetails?.user_preference_details?.user_preference_income.join(
                                          ", "
                                        )}
                                      />
                                    </div>
                                  </div>

                                  <div className="line"></div>

                                  <div className={classes.gap_details_box}>
                                    <p
                                      className={classes.Profile_details_titel}
                                    >
                                      Work Location Preferences
                                    </p>

                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Country"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.preference_country
                                        }
                                      />
                                      <CulomeData
                                        title="State"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.preference_state
                                        }
                                      />
                                      <CulomeData
                                        title="District/City"
                                        subTitle={profileDetails?.user_preference_details?.preference_city.join(
                                          ", "
                                        )}
                                      />
                                    </div>
                                  </div>

                                  <div className="line"></div>

                                  <div className={classes.gap_details_box}>
                                    <p className={classes.Profile_details_titel}>
                                      {profileDetails?.user_profile_details?.gender === "Male" ? "His" : "Her"} Habit Preferences
                                    </p>

                                    <div className={classes.three_culome}>
                                      <CulomeData
                                        title="Eating Habits"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.user_preference_eating_habbit
                                        }
                                      />
                                      <CulomeData
                                        title="Drinking Habits"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.user_preference_drinking_habit
                                        }
                                      />
                                      <CulomeData
                                        title="Smoking Habits"
                                        subTitle={
                                          profileDetails
                                            ?.user_preference_details
                                            ?.user_preference_smoking_habit
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div>
                                  <ThreeCulome
                                    style={"culome_style"}
                                    title="Registration Details"
                                    data={profileDetails?.user_profile_details}
                                  />
                                  <div className={classes.document_boxs}>
                                    <p className={classes.contant_box_titel}>
                                      NMC/SMC Certificate
                                    </p>
                                    <ImrDocument
                                      isLoading={false}
                                      data={
                                        profileDetails?.user_profile_details
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="line"></div>
                              <div className={classes.gap_details_box}>
                                <p className={classes.Profile_details_titel}>
                                  Photos
                                </p>
                                {/* <p
                                  className={classes.Profile_details_sub__titel}
                                >
                                  <Image src={groupicon} alt="info" />{" "}
                                  {profileDetails?.verify_image?.total_verified}
                                  /{profileDetails?.verify_image?.total_count}{" "}
                                  Photos Verified
                                </p> */}

                                <div className={classes.photos__align}>
                                  {profileDetails?.verify_image?.data?.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                        className={classes.img__box__set}
                                        onClick={() =>
                                          openPopPup(
                                            "Photos",
                                            item.token,
                                            item.content,
                                            "Photos",
                                            item.status
                                          )
                                        }
                                      >
                                        <label
                                          className={`photo_lable ${item.status === "Approved"
                                            ? "verified"
                                            : item.status === "Pending"
                                              ? "pending"
                                              : item.status === "Rejected"
                                                ? "rejected"
                                                : ""
                                            }`}
                                        >
                                          {item.status === "Approved"
                                            ? "verified"
                                            : item.status === "Pending"
                                              ? "pending"
                                              : item.status === "Rejected"
                                                ? "Rejected"
                                                : ""}
                                        </label>

                                        <Image
                                          src={item.content}
                                          alt="photo_lable"
                                        />
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>

                              {profileDetails?.verify_video.length !== 0 ? <div className="line"></div> : ""}
                              {membershipType === "Elite" &&
                                <div className={classes.gap_details_box}>
                                  <>
                                    {profileDetails?.verify_video.length !== 0 ? (
                                      <p className={classes.Profile_details_titel}>
                                        Videos
                                      </p>
                                    ) : (
                                      ""
                                    )}

                                    <div className={classes.video__set}>
                                      {profileDetails?.verify_video?.map(
                                        (item, index) => (
                                          <div
                                            key={index}
                                            className={
                                              classes.video_container_set
                                            }
                                          >
                                            <p className={classes.video__set_sub__titel}>
                                              {item.type === "preference_video"
                                                ? "Partner Preference"
                                                : "Self-Intro"}
                                            </p>
                                            <div
                                              className={classes.video__box__set}
                                              onClick={() =>
                                                openPopPup(
                                                  "Videos",
                                                  item.token,
                                                  item.content,
                                                  item.type,
                                                  item.status
                                                )
                                              }
                                            >
                                              <label
                                                className={`photo_lable ${item.status === "Approved"
                                                  ? "verified"
                                                  : item.status === "Pending"
                                                    ? "pending"
                                                    : item.status === "Rejected"
                                                      ? "rejected"
                                                      : ""
                                                  }`}
                                              >
                                                {item.status === "Approved"
                                                  ? "verified"
                                                  : item.status === "Pending"
                                                    ? "pending"
                                                    : item.status === "Rejected"
                                                      ? "Rejected"
                                                      : ""}
                                              </label>
                                              <video
                                                controls={false}
                                                disablePictureInPicture
                                                className={
                                                  classes.video_lable_index
                                                }
                                              >
                                                <source
                                                  src={item.content}
                                                  type="video/mp4"
                                                />
                                              </video>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </>
                                </div>
                              }
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </motion.dev>
                  ),
                },
                {
                  title: "Membership Payments",
                  render: () => (
                    <motion.dev
                      variants={tabAnimation}
                      initial="initial"
                      animate="animate"
                      exit="exit"
                    >
                      {!isLoading ? (
                        <div className={classes.membership_payments}>
                          {activeTab === 1 && (
                            <motion.div
                              initial={{ opacity: 0 }} // Initial position off-screen to the left
                              animate={{ opacity: 1 }}
                              exit={{ opacity: 0 }}
                              className={classes.membership_payments_card}
                            >
                              {cardData.map((card, index) => (
                                <motion.div
                                  initial={{ opacity: 0, x: -300 }} // Initial position off-screen to the left
                                  animate={{ opacity: 1, x: 0 }}
                                  exit={{ opacity: 0, x: -300 }}
                                  transition={{
                                    duration: 0.8,
                                    delay: index * 0.2,
                                  }}
                                  key={index}
                                >
                                  <Card
                                    src={card.src}
                                    title={card.title}
                                    subTitle={
                                      card.subTitle == undefined
                                        ? 0
                                        : card.subTitle
                                    }
                                    className={card.className}
                                  />
                                </motion.div>
                              ))}
                            </motion.div>
                          )}

                          <div className={classes.membership_payments_table}>
                            <Table
                              data={filteredData}
                              columns={columns}
                              loader={isLoading}
                              children={
                                <div className="header_table">
                                  <TableHeader
                                    tableheader="Membership Payments"
                                    totalusers={`${membershipPayment.iTotalRecords} total payments`}
                                  />
                                  <Search
                                    value={membershipSearch}
                                    onChange={newValue =>
                                      handleSearchChange(newValue)
                                    }
                                    type="text"
                                    placeholder="Search"
                                  />
                                </div>
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="loader_bg">
                            <Loader />
                          </div>
                        </>
                      )}
                    </motion.dev>
                  ),
                },
              ]}
              onTabClick={onTabClick}
              activeTab={activeTab}
            />
          }
        />

        <ToastContainer />
        <Modal
          title={`${checkType === "Videos" ? videoPhotsData.videos_type === "user_video" ? "Self-Intro" : "Partner Preference" : "Photo"}`}
          open={openVideoAndImg}
          setOpen={setOpenVideoAndImg}
          handleSubmit={() => handleOpenVideoAndImg()}
          type={videoPhotsData.status === "Pending" ? "" : "view"}
          okay={videoPhotsData.status === "Pending" ? "" : "ok"}
          scrollType="body"
          size="sm"
          onCloseFunction={onCloseFunction}
        >
          <>
            {checkType === "Videos" ? (
              <div className={classes.inro_video}>
                <VideoPlayers
                  src={videoPhotsData.urls}
                  clear={clear}
                  playBtn={PlayBtn}
                />
              </div>
            ) : (
              <div className={classes.inro_video}>
                <div className={classes.farame_img}>
                  <Image src={videoPhotsData.urls} alt="photo_lable" />
                </div>
              </div>
            )}

            {videoPhotsData.status === "Pending" ? (
              <div className={classes.inro_video_contants}>
                <p className={classes.module_text}>{`Approve/${checkType === "Videos" ? "Reject Self-Intro" : "Reject Photo"
                  }`}</p>
                <div className={classes.radio_btn_set}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="1"
                    isSelected={storiesUser === "1"}
                    label="Approve"
                    value="1"
                  />

                  <RadioButton
                    changed={radioChangeHandler}
                    id="2"
                    isSelected={storiesUser === "0"}
                    label="Reject"
                    value="0"
                  />
                </div>
                {storiesUser === "0" && (
                  <>
                    {checkType === "Videos" ? (
                      <div className={classes.textarea_contoner}>
                        {errorMsg && <p className="text_danger">{errorMsg}</p>}
                        <SelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={videoDesigName}
                          label="Reason"
                          onChange={handleVideoReasonChange}
                          labelname="Reason"
                          nonename="Reason"
                          data={videoDesignation}
                        />
                      </div>
                    ) : (
                      <div className={classes.textarea_contoner}>
                        {errorMsg && <p className="text_danger">{errorMsg}</p>}
                        <SelectBox
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={photoDesigName}
                          label="Reason"
                          onChange={handleImageReasonChange}
                          labelname="Reason"
                          nonename="Reason"
                          data={photoDesignation}
                        />
                      </div>
                    )}
                  </>
                )}
                <p className={classes.contant_box_sub}>Note:</p>
                {checkType === "Videos" ? (
                  <p className={classes.contant_box_titel_video}>
                    The video should be clear and well-lit, ensuring the
                    person’s face is visible. Avoid video with excessive
                    filters or heavy editing that may distort the person's
                    appearance.
                  </p>
                ) : (
                  <p className={classes.contant_box_titel_video}>
                    Photos should be clear and well-lit, ensuring the
                    person’s face is visible. Avoid photos with excessive
                    filters or heavy editing that may distort the person's
                    appearance.
                  </p>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        </Modal>
      </motion.dev>
    </AnimatePresence>
  )
}

export default UsersViewPage
