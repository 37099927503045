import React, { useState } from 'react'
import style from  "./PremiumPartnerPreference.module.css"
import Image from '../../../../ui/Image/Image'
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import { useNavigate } from 'react-router-dom'
import useAuthentication from '../../hooks/useAuthentication'
import Basic from '../../features/PremiumPartnerPreference/Basic/Basic'
import { BuildPartnerProfileContext } from '../../features/PremiumPartnerPreference/Context/BuildPartnerProfileContext'
import BasicPreference from '../../features/PremiumPartnerPreference/BasicPreference/BasicPreference'

export default function PremiumPartnerPreference() {

  const navigation = useNavigate();

  const [ basicPreferenceData, setBasicPreferenceData ] = useState([])

  const { isAuthenticated } = useAuthentication();

  if( !isAuthenticated ) {
      navigation("/")                 
  }
  
  const [activeStep, setActiveStep] = useState(1)
  
  // Create a function to render the component based on activeStep
  const renderComponentBasedOnStep = () => {
    switch (activeStep) {
      case 1:
        return <BasicPreference />;
      default:
        return null; // Handle other cases as needed
    }
  };

  return (
    <BuildPartnerProfileContext.Provider value={{ activeStep, setActiveStep, basicPreferenceData, setBasicPreferenceData }}>
      <div className={style.buildProfileContainer}>
        <div className={style.logoContainer}>
          <Image 
            src={logoImage}
            className={style.logo}
          />
        </div>
        <div className={style.headerContainer}>
          <h2 className={style.headerTitle}>Partner Preference</h2>
          <p className={style.headerSubTitle}>Enter partner preference to find perfect match.</p>
        </div>
        {renderComponentBasedOnStep()}
      </div>
    </BuildPartnerProfileContext.Provider>
  )
}
