import React, { useEffect, useRef, useState } from "react"
import style from "./ApplicationRejected.module.css"
import useApiCall from '../../hooks/useApiCall';
import Image from "../../../../ui/Image/Image"
import rejectIcon from "../../assets/common/reject.svg"
import PopoutInput from "../../../../ui/PopoutInput/PopoutInput"
import radioUnselected from "../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../assets/common/Radiobutton_on.svg"
import uploadIcon from '../../assets/common/Upload.svg';
import pdfIcon from "../../assets/common/pdf.svg"
import { formatDMYDate, formatYMDDate, getUserData, getUserToken, isAlphaNumeric, uploadFile } from "../../../../utils/utils";
import FloatingLabelDropdown from "../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown";
import FloatingDatePicker from "../../../../ui/FloatingDatePicker/FloatingDatePicker";
import { useNavigate } from "react-router-dom";
import { Circles } from  'react-loader-spinner'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import deleteIcon from '../../assets/common/Bin.svg';
import notesIcon from "../../assets/common/notes.svg"
import uploadImageIcon from '../../assets/common/Upload Image.svg';

export const ApplicationRejected = () => {

    const { name, date_of_birth, image_reject_reason, image_status, council_name, year_of_reg, gender, email, image, education, imr_number, imr_certificate, qualification, status_reason  } = getUserData()

    const { sendRequest: getMetaData } = useApiCall();

    const navigation = useNavigate()

    const { isLoading: isLoading, success: success, error: error, sendRequest: submitDocuments } = useApiCall();
    const [genderData, setGenderData] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenDob, setIsOpenDob] = useState(false);
    const [ loader, setLoader ] = useState(false)
    const [studiedYearData, setStudiedYearData] = useState("") 

    const [ imageStatus, setImageStatus ] = useState(image_status)
    const [formData, setFormData] = useState({
      images: [image],
      collegeName: name,
      dob: date_of_birth ? formatDMYDate(date_of_birth) : "",
      emailAddress: email,
      council_name: council_name,
      gender: gender,
      imr_number: imr_number,
      studied_year: year_of_reg,
      certificate_url: ""
    });
      const [imageVerificationMessage, setImageVerificationMessage] = useState("");
      const [educationData, setEducationData] = useState([])
      const [councilName, setCouncilName] = useState("") 

      const [viewSuccessModal, setViewSuccessModal] = useState(false);
      const [uploadedImages, setUploadedImages] = useState([image]);
      const uploadInputs = useRef([]);
      const [imageFiles, setImageFiles] = useState([image]);
      const dropdownRef = useRef(null);
      const dropdownRefDob = useRef(null);
      const [councilNameData, setCouncilNameData] = useState("") 
  
      const [ qualificationData, setQualificationData ] = useState([]);
      const [selectedCertificate, setSelectedCertificate] = useState(imr_certificate);
      const [certificateError, setCertificateError] = useState("");
      const [selectedCertificatePreview, setSelectedCertificatePreview] = useState(imr_certificate);
      const [ selectedCertificateType, setSelectedCertificateType ] = useState(imr_certificate?.includes(".pdf") ? true : false)
      const [ qualificationErrorData, setQualificationErrorData ] = useState([]);
      const [ submitError, setSubmitError ] = useState("")
      const [studiedYearError, setStudiedYearError] = useState("") 

      const [qualifications, setQualifications] = useState(qualification);

      const [imagePreviews, setImagePreviews] = useState([
        null // Initial image previews, one for each qualification
      ]);

      const { sendRequest: getQualifications } = useApiCall();

      useEffect(() => {
        let currentYear = new Date().getFullYear();    
        let earliestYear = 1970;   
        let dateOption = []  
        while (currentYear >= earliestYear) {      
          dateOption.push({
            name: currentYear,
            value: currentYear
          })   
          currentYear -= 1;    
        }
        setStudiedYearData(dateOption)
        let urls = []
        
        qualification?.map((item)=> {
            urls.push(item?.certificate_url)
        })
        setImagePreviews(urls)
        getMetaData(
          {
            url: 'register/read-meta-data',
            method: 'POST'
          },
          (data) => {
            const { gender, medical_council } = data?.data;
            setGenderData(gender)
            setCouncilNameData(medical_council)
          }
        );
      }, []);

      useEffect(()=> {
        getQualifications({
          url: "education",
          method: 'POST',
        }, resultQualification)
      }, [])

      const resultQualification = (data) => {
        setQualificationData(data.data)
      }


    const handleCertificateUpload = (e) => {
        const selectedFile = e.target.files[0];
        if( selectedFile ) {
        // Check if the file size is greater than 6MB
        if (selectedFile && selectedFile.size > 6 * 1024 * 1024) {
            setCertificateError("File size must be less than 6MB.")
            return;
        }
        setCertificateError("")
        setSelectedCertificate(e.target.files[0]);
        if (selectedFile) {
            setSelectedCertificateType( selectedFile?.type === "application/pdf" ? true : false )
            const selectedFileURL = URL.createObjectURL(selectedFile);
            setSelectedCertificatePreview(selectedFileURL)
            setFormData({...formData, certificate_url: e.target.files[0]})
            // setCertificateError('');
        } else {
            setSelectedCertificate(null);
        }
        }
    };

      const handleInputChange = (event) => {
        setIsOpen(false)
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      const handleStudiedYearChange = (e) => {
        setFormData({
          ...formData,
          studied_year: e.target.value,
        });
      };

      const handleRadioChange = (name, value) => {
    
        setIsOpen(false)
        setFormData({
          ...formData,
          [name]: value,
        });
      };

      // Create a function to render radio buttons
      const renderRadioButtons = (name, options) => (
        <div className={`${style.answers}`}>
          {options.map((option) => (
            <div
              key={option.value}
              className={style.answer}
              onClick={() => handleRadioChange(name, option.value)}
            >
              <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
              <p>{option.name}</p>
            </div>
          ))}
        </div>
      );

    useEffect(()=> {
        if( success ) {
            setLoader(false)
            navigation("/status")
        }
    }, [success])

    const [formDataTitle, setFormDataTitle] = useState({
        collegeName: "Name",
        emailAddress: "Email",
        dob: "Date of Birth",
        gender: "Gender",
        imr_number: "NMC or SMC Number",
        studied_year: "Studied Year",
        council_name: "Council Name",
        images: "Profile Image",
        certificate_url: "NMC/SMC Certificate"
    });
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    };

    const handleSubmit = async() => {

        if( 
          imageFiles?.length &&
          formData.collegeName &&
          formData.dob &&
          formData.gender &&
          formData.emailAddress &&
          formData.council_name !== "Select an option" &&
          formData.studied_year !== "Select an option" &&
          validateEmail(formData.emailAddress) &&
          formData.imr_number &&
          isAlphaNumeric(formData.imr_number) &&
          selectedCertificate
        ) {
          console.log("imageFiles[0]", imageFiles[0]);
          let profileImage = imageFiles[0]
          if (imageFiles[0] && typeof imageFiles[0] === "object" ) {
            profileImage = await uploadFile(imageFiles[0])
          }
          let nmcCertificate = selectedCertificate
  
          if (selectedCertificate && typeof selectedCertificate === "object" ) {
              // Replace 'uploadFile' with your actual S3 upload function
              
                  nmcCertificate = await uploadFile(selectedCertificate);
          }

            if( nmcCertificate ) {
              submitDocuments({
                  url: "auth/register/resubmit-verification",
                  method: 'POST',
                  body: {
                      "name": formData.collegeName,
                      "dob": formatYMDDate(formData.dob),
                      "gender": formData.gender,
                      "email": formData.emailAddress,
                      "education": formData.education,
                      "imr_number": formData.imr_number,
                      "certificate_url": nmcCertificate,
                      "council_name": formData.council_name, 
                      "year_of_reg": formData.studied_year, 
                      "user_image": [ profileImage ],
                      // "qualification": updatedQualifications
                  },
                  headers: {
                    Authorization: `Bearer ${getUserToken()}`,
                  }
                });
            }
        } else {
            setLoader(false)
            if( formData.emailAddress && !validateEmail(formData.emailAddress) ) {
                toast.error(`Please fill the valid email`, {
                  autoClose: 2500,
                })
            }
            if( imageFiles?.length && formData.imr_number && !isAlphaNumeric(formData.imr_number)) {
                toast.error(`Please enter only NMC or SMC Number alpha numeric`, {
                  autoClose: 2500,
                })
            }
            // if( imageFiles?.length && !selectedCertificate ) {
            //     toast.error(`Please enter the NMC or SMC certificate`, {
            //         autoClose: 2500,
            //     })
            // }
              var result = Object.keys(formData).map((key) => [key, formData[key]]);
              for (const item of result || []) {
                if( item[0] !== "video" ) {
                  if ( item[0] !== "mobileNumber" ) {
                    if ( !item[1] || item[1].length == 0 || item[1] === "Select an option" ) {
                      toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                        autoClose: 2500,
                      })
                      break; // This will exit the loop when the condition is met
                    }
                  }
                }
              }
        }

    }

    const uploadElements = [];

  // const numberOfImages = getUserData()?.membership_type === "Basic" ? 1 : 6

  for (let i = 0; i < 1; i++) {
    uploadElements.push(
      <>
        {uploadedImages[i] ? (
          <div
            key={i}
            className={style.uploadedImage}
            style={{
              backgroundImage: `url('${uploadedImages[i]?.includes("blob:") ? uploadedImages[i] : process.env.REACT_APP_S3_CLOUD_FRONT_URL + uploadedImages[i]}')`,
            }}
          >
            {
              imageStatus !== "Approved" && (
                <div className={style.deleteContainer}>
                  <Image src={deleteIcon} onClick={() => handleImageDelete(i)} />
                </div>
              )
            }
            {
              imageStatus !== "Approved" && (
                <div
                  className={style.reuploadContainer}
                  // onClick={() => uploadInputs.current[i].click()}
                >
                  {/* <Image src={uploadWhiteIcon} /> */}
                  <p className={style.reuploadLabeText}>{ imageStatus === "Pending" ? "Waiting Verification" : imageStatus }</p>
                </div>
              )
            }
          </div>
        ) : (
          <div
            key={i}
            className={style.upload}
            onClick={() => uploadInputs.current[i].click()}
          >
            <label className={style.uploadImageLabel}>
              <div className={style.uploadImage}>
                <img
                  src={uploadImageIcon}
                  className={style.uploadLogo}
                  alt="Upload Icon"
                />
              </div>
            </label>
            <p className={style.uploadLabel}>Upload Image</p>
          </div>
        )}
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={(event) => handleImageUpload(event, i)}
          className={style.uploadInput}
          ref={(el) => (uploadInputs.current[i] = el)} // Assign the ref to the input element
        />
      </>
    );
  }

  const handleImageDelete = (index) => {
    setImageVerificationMessage("")
    const newImages = [...uploadedImages];
    newImages[index] = null;
    setUploadedImages(newImages);

    // Reset the file input
    if (uploadInputs.current[index]) {
      uploadInputs.current[index].value = null;
    }

    const newImageFiles = [...imageFiles];
    newImageFiles.splice(index, 1);
    setImageFiles(newImageFiles);

    setFormData({ ...formData, images: newImageFiles })
  };

  const handleImageUpload = (event, index) => {
    setImageVerificationMessage("")
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = ['image/jpeg', 'image/png', 'image/gif']; // Add more formats if needed
  
      // Check if the file size is less than or equal to 6 MB (6 * 1024 * 1024 bytes)
      if (file.size <= 6 * 1024 * 1024) {
        // Check if the file type is allowed
        if (allowedFormats.includes(file.type)) {
          const newImages = [...uploadedImages];
          newImages[index] = URL.createObjectURL(file); // Store the data URL for preview
          setUploadedImages(newImages);
  
          const newImageFiles = [...imageFiles];
          newImageFiles[index] = file; // Store the File object
          setImageFiles(newImageFiles);
  
          setFormData({ ...formData, images: newImageFiles });
          setViewSuccessModal(true)
          setImageStatus("Pending")
          setImageVerificationMessage("Photo will reflect after 24 hrs.")
        } else {
          // Invalid file format, handle the error here (e.g., show a message to the user)
          toast.error('Invalid file format. Please choose a JPG, PNG file.', {
            autoClose: 2500,
          });
          // Optionally, you can clear the input field to allow the user to choose another file
          event.target.value = null;
        }
      } else {
        // File size exceeds 6 MB, handle the error here (e.g., show a message to the user)
        toast.error('File size exceeds 6 MB. Please choose a smaller file.', {
          autoClose: 2500,
        });
        // Optionally, you can clear the input field to allow the user to choose another file
        event.target.value = null;
      }
    }
  };

    // Close the dropdown when clicking outside of it
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
        if (dropdownRefDob.current && !dropdownRefDob.current.contains(event.target)) {
            setIsOpenDob(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
     
    console.log("uploadedImages", uploadedImages);
      
    return(
        <div className={`${style.container} container`}>
            <ToastContainer />
            <div className={style.rejectBoxContainer}>
                <div className={style.rejectBoxImageAndTextContainer}>
                    <Image  src={rejectIcon}/>
                    <div className={style.textContainer}>
                        <p className={style.statusText}>Application Rejected</p>
                        <p className={style.statusTextDescription}>Oops! Your Doctors Matrimony application was rejected due to <span className={style.status}>{status_reason}</span> { image_reject_reason && `and`} <span className={style.status}>{image_reject_reason}</span>. Please enter your details to verify your profile easily. Update now for seamless connections!</p>
                    </div>
                </div>
            </div>
            <div className={style.allDataContainer}>
                <p className={style.contentTitle}>Update Details</p>
                <div className={style.contentContainer}>
                    <p className={style.contentSubTitle}>Upload Photo</p>
                    <div className={style.uploadContainer}>{uploadElements}</div>
                    {
                      imageVerificationMessage && (
                        <div className={style.eliteVideoInstructionContainer}>
                          <Image src={notesIcon} className={style.notesIcon}/>
                          <p className={style.instructionTitle}>Your photo is in the reviewing phase. Your profile will update with the approved image within 24 hours.</p>
                        </div>
                      )
                    }
                </div>
                <div className={style.contentContainer}>
                    <p className={style.contentSubTitle}>Basic Details</p>
                </div>
                <div className={style.formContainer}>
                    <div className={style.hobbiesContainer}>
                        <div className={style.hobbieContainer}>
                            <PopoutInput
                                label="Name"
                                name="collegeName"
                                value={formData.collegeName}
                                onChange={handleInputChange}
                                style={{
                                    paddingBottom: '26px',
                                }}
                            />
                            <PopoutInput
                                label="Email Address"
                                name="emailAddress"
                                value={formData.emailAddress}
                                onChange={handleInputChange}
                                style={{
                                    paddingBottom: '26px',
                                }}
                            />
                            <div style={{ minWidth: "342px", paddingBottom: '26px' }} className={style.field}>
                                <p className={style.fieldTitle}>Gender</p>
                                <div className={style.fieldValue}>
                                    {renderRadioButtons('gender', genderData)}
                                </div>
                            </div>
                            <div className={style.inputContainer}>
                              <FloatingLabelDropdown
                                label="Year of Registration"
                                name="studied_year"
                                responseType="name"
                                options={studiedYearData} // Add options
                                value={formData.studied_year}
                                onChange={handleStudiedYearChange}
                              />
                            </div>
                        </div>
                        <div className={style.hobbieContainer}>
                            <div className={style.inputContainer} ref={dropdownRefDob}>
                                <FloatingDatePicker
                                    isOpen={isOpenDob}
                                    setIsOpen={setIsOpenDob}
                                    selectedDate={formData.dob}
                                    onChange={(e) => setFormData({ ...formData, dob: e })}
                                    label={"Date of Birth"}
                                    // customStyle={{
                                    //     paddingBottom: '26px',
                                    // }}
                                />
                            </div>
                            <div className={style.inputContainer} style={{
                                    paddingBottom: '26px',
                                }}>
                                <FloatingLabelDropdown
                                    label="Council Name"
                                    name="council_name"
                                    responseType="name"
                                    options={councilNameData} // Add options
                                    value={formData.council_name}
                                    onChange={handleInputChange}
                                    customStyle={{
                                        paddingTop: '26px',
                                    }}
                                />
                            </div>
                            <PopoutInput
                                label="NMC or SMC Number"
                                name="imr_number"
                                value={formData.imr_number}
                                onChange={handleInputChange}
                            />
                            <div className={style.hobbieContainer} 
                                    style={{
                                        paddingTop: '26px',
                                    }}>
                                <div className={style.fieldContainer}>
                                    <div className={style.field}>
                                        <p className={style.fieldTitle}>NMC/SMC certificate</p>
                                        <p className={style.fieldTitleLabel}>PDF, PNG, JPG with a Max file size of 6MB</p>
                                        <label htmlFor="certificateUpload" style={{ gap: selectedCertificate ? '12px' : '' }} className={style.uploadButton}>
                                        {selectedCertificate ? (
                                            <>
                                            {
                                                selectedCertificateType ?
                                                <Image onClick={()=> {
                                                    // avoidScroll()
                                                    // setShowRejectReasonModal(true)
                                                }} src={pdfIcon} width={"70px"} height={"50px"}/>
                                                :
                                                <Image src={ (selectedCertificatePreview?.includes("blob:") ) ? selectedCertificatePreview : process.env.REACT_APP_S3_CLOUD_FRONT_URL+selectedCertificatePreview} width="70px" height="50px" />
                                            }
                                            </>
                                        ) : (
                                            <Image src={uploadIcon} />
                                        )}
                                        <p className={style.uploadButtonTitle}>{selectedCertificate ? 'Edit' : 'Upload Certificate'}</p>
                                        </label>
                                        <input
                                        type="file"
                                        id="certificateUpload"
                                        accept="application/pdf,image/png,image/jpeg"
                                        onChange={handleCertificateUpload}
                                        style={{ display: 'none' }}
                                        />
                                        { certificateError !== "" ? <p className={`${style.errorMessage} error`}>{certificateError}</p> : <></> }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.submitButtonContainer} onClick={handleSubmit}>
                    Submit for verification
                    <Circles
                        height="20"
                        width="20"
                        color="#fff"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={loader}
                    />
                </div>
            </div>
        </div>
    )
}