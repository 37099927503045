import React, { useState,useEffect } from "react"
import style from "./Chat.module.css"
import Image from "../../../../ui/Image/Image"
import drImage from "./assets/Dr. Vidhya@2x.png"
import moreIcon from "../../assets/common/Menu.svg"
import messageSentImage from "./assets/Message Sent.svg"
// import db from "./Db/FirebaseDb"
import initializeFirebase from "./Db/FirebaseDb"
import {getUserData} from '../../../../utils/utils'
import ChatListData from "../../features/Chat/chatData/ChatList"
import ChatHistory from "../../features/Chat/chatData/ChatCanvas"
import { collection,doc,setDoc,getDoc,getDocs,Timestamp,onSnapshot,query,orderBy,where } from "firebase/firestore"; 
import { logDOM } from "@testing-library/react"
import { Circles } from "react-loader-spinner"
import { useLocation } from "react-router-dom"
import { NoData } from "../../features/NoData/NoData"

export const Chat = () => {

  const [ refresh, setRefresh ] = useState(false)
  const { state } = useLocation();
  const [ onLoadDocId, setOnLoadDocId ] = useState(localStorage.getItem("redirectToken") ? localStorage.getItem("redirectToken") : state?.token)

    const [ message, setMessage ] = useState("")
    const {db} = initializeFirebase();
    const userData = getUserData();
    const userToken =  userData.user_token;
    let date = new Date();
    const userAvailabilityQuery = query(collection(db, "room"), where("users", "array-contains", userToken));
    const allDocArrayQuery = query(collection(db, "room"),orderBy("created_time","desc"));
    const newListDataQuery = query(collection(db, "room"),orderBy("created_time","asc"),where("created_time", ">=", date));
    const [chatListArray, setChatListArray] = useState([]);
    const [activeChatData, setActiveChatData] = useState({});
    const [chatListCount, setChatListCount] = useState(0);
    const [loading, setLoading] = useState(false);
    let changeState = true;
    let tempData = [];
    let chatListUnsub;

    // useEffect(()=> {
    //   window.scrollTo(0, 0);
    // })

    const updateCount = (doc_id, count) => {
      // console.log("inside - updateCount");
      // let newListData = chatListArray.map((item,index)=>{
      //   if(item.id === doc_id){
      //       return {...item,newChatCount:count}
      //   }else{
      //       return item
      //   }
      // })
      // console.log("newListData", newListData);
      // setChatListArray(newListData)
      // setActiveChatData((curr)=> {
      //   return {...curr, newChatCount:count }
      // })
    }

    const chatListCallBack = (details,tok) => {
      localStorage.removeItem("redirectToken")
        if(activeChatData.participant_token !== tok){
            let oldChatIndex = chatListArray.findIndex(x => x === activeChatData);
            let newChatIndex = chatListArray.findIndex(x => x === details);
            
            let newListData = chatListArray.map((item,index)=>{
                if(index === oldChatIndex){
                    return {...item,chatCountEnable:true}
                }else if(index === newChatIndex){
                    return {...item,chatCountEnable:false }
                }else{
                    return item
                }
            })
            setChatListArray(newListData);
            setActiveChatData(newListData[newChatIndex]);
        }

    }


    const fetchArrayData = async () => {
        setLoading(true)
        const querySnapshot = await getDocs(userAvailabilityQuery);
        if (querySnapshot.size > 0) {
          const allDocQueryResuly = await getDocs(allDocArrayQuery);
          let docIndex = 0;
          allDocQueryResuly.forEach((doc) => {
            if (doc.data().users.includes(userToken)) {
              let participantToken = doc.data().users.find((x) => x !== userToken);
              let chatCountEnableBool = docIndex === 0 ? false : true;
              let currentDocData = {
                doc_id: doc.id,
                participant_token: doc.data().users.find((x) => x !== userToken),
                user_token: userToken,
                user_profile_id: doc.data()[participantToken].profile_id,
                user_image: doc.data()[participantToken].image,
                user_name: doc.data()[participantToken].name,
                chatCountEnable: chatCountEnableBool
              };
      
              // Replace the check for duplicates
              if (!isDuplicate(tempData, currentDocData)) {
                tempData.push(currentDocData);
                setChatListArray((curr) => [...curr, currentDocData]);
      
                if(docIndex === 0 && onLoadDocId !== '' && onLoadDocId !== undefined){
                     if(onLoadDocId === currentDocData.doc_id){
                           setActiveChatData(currentDocData);
                           docIndex += 1;
                      }
                 }else if (docIndex === 0) {
                  setActiveChatData(currentDocData);
                  docIndex += 1;
                }
      
                setChatListCount(tempData.length);
              }
      
              // Listener for new chat
              if (chatListUnsub === undefined) {
                chatListUnsub = onSnapshot(newListDataQuery, (snap) => {
                  snap.docChanges().forEach(function (change) {
                    if (change.type === "added" && change.doc.data().users.includes(userToken)) {
                      let participantToken = change.doc.data().users.find((x) => x !== userToken);
      
                      let newCurrentDocData = {
                        doc_id: change.doc.id,
                        participant_token: change.doc.data().users.find((x) => x !== userToken),
                        user_token: userToken,
                        user_profile_id: change.doc.data()[participantToken].profile_id,
                        user_image: change.doc.data()[participantToken].image,
                        user_name: change.doc.data()[participantToken].name,
                        chatCountEnable: true,
                      };
      
                      // Replace the check for duplicates
                      if (!isDuplicate(tempData, newCurrentDocData)) {
                        tempData.push(newCurrentDocData);
      
                        let newDataArray = [newCurrentDocData, ...chatListArray];
                        setChatListArray(newDataArray);
                        setChatListCount(tempData.length);
                      }
                    }
                  });
                });
              }
            }
          });
        }
        setLoading(false)
    };
      

    const isDuplicate = (array, newItem) => {
        return array.some(item => (
          item.doc_id === newItem.doc_id &&
          item.participant_token === newItem.participant_token 
        ));
      };

    useEffect(() => {

        setChatListArray([]);
        tempData = [];
        if(chatListUnsub !== undefined){
            chatListUnsub();
        }

        fetchArrayData()
    
      return () => {
        if(chatListUnsub !== undefined){
            chatListUnsub();
        }
      }
    }, [refresh, localStorage.getItem("redirectToken")])
    
    return(
        <>
            {
                // error ? (
                //     <div className={style.loaderContainer}>
                //         <p className={style.error}>{error}</p>
                //     </div>
                // ) : 
                    !loading ? (
                        <div className={`${style.notificationsContainer} container`}>
                            <div className={style.notificationContainer}>
                                <div className={style.notificationSidebarContainer}>
                                    <p className={style.title}>Chat</p>
                                    <p className={style.subtitle}>{chatListCount} Chat History</p>
                                    <div className={style.notificationListsContainer}>

                                        {(chatListArray.length > 0 && chatListArray)
                                            ? chatListArray.map((item, index) =>{
                                                return(
                                                    <ChatListData
                                                        key={item.doc_id} 
                                                        db={db}
                                                        det={item}
                                                        chatDetails={activeChatData}
                                                        clickHandle={chatListCallBack}
                                                        refresh={refresh}
                                                        setRefresh={setRefresh}
                                                        updateCount={updateCount}
                                                    />
                                                )})
                                            : <div style={{fontSize:"20px",fontWeight:"bold",marginTop:"20px"}}>No Chats Available</div>}
                                        
                                    </div>
                                </div>
                                {
                                  (chatListArray?.length > 0  && chatListArray ) ?
                                    <ChatHistory
                                        db={db}
                                        chatDetails={activeChatData}
                                        setRefresh={setRefresh}
                                        refresh={refresh}
                                        updateCount={updateCount}
                                    />
                                  :
                                    <NoData isChat={true} title={"No chats found"}/>
                                }
                            </div>
                        </div>
                    ) : (
                        <div className={style.loaderContainer}>
                            <Circles
                                height="35"
                                width="35"
                                color="rgb(239, 116, 53)"
                                ariaLabel="circles-loading"
                                wrapperStyle={{}}
                                wrapperClass={style.loader}
                                visible={true}
                            />
                        </div>
                    )
            }
        </>
    )
}