import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './BasicPreference.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import Button from '../../../../../ui/Button/Button';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../ui/Image/Image';
import uploadWhiteIcon from '../../../assets/common/upload-white.svg';
import deleteIcon from '../../../assets/common/Bin.svg';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserToken, isStringValid, uploadFile } from '../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import { BuildPartnerProfileContext } from '../Context/BuildPartnerProfileContext';
import checkedIcon from "../../../assets/svg/Signup/checked.svg";
import uncheckedIcon from "../../../assets/svg/Signup/uncheck.svg";
import Success from '../Modal/Success';
import ModalBox from '../../../../../ui/Modal/ModalBox';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function BasicPreference() {

  const navigation = useNavigate("")

  const { sendRequest: getMetaData } = useApiCall();
  
  const { setActiveStep, setBasicPreferenceData } = useContext(BuildPartnerProfileContext);

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();
  const [ loader, setLoader ] = useState(false)

  const [ validationError, setValidationError ] = useState("")
  const [ viewDetailsModal, setViewDetailsModal ] = useState(false)

  const [ userMetaData, setUserMetaData ] = useState(null)

  const [formData, setFormData] = useState({
    age: [],
    height: [],
    motherTongue: [],
    citizenship: '',
    physicalStatus: '',
    maritalStatus: [],
    eatingHabit: '',
    drinkingHabit: '',
    smokingHabit: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    getMetaData(
      {
        url: 'preference/premium/read-meta',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        setUserMetaData(data.data);
      }
    );
  }, []);

  const handleInputChange = (event, inputType) => {
    console.log(event, inputType);
    if( event.target ) {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [inputType]: event,
      });
    }
  };

  useEffect(() => {
    if (success) {
      avoidScroll()
      setViewDetailsModal(true)
    }
    if (error !== '') {
      toast.error(error, {
          autoClose: 2500,
      })
    }
    setLoader(false)
  }, [success, error]);

  const extractToken = (data, isToken) => {
    let token = []
    data?.map(item=> {
      token.push( isToken ? item.token : item.value)
    })
    return token;
  }

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  const [formDataTitle, setFormDataTitle] = useState({
    age: "Age",
    height: "height",
    motherTongue: "Mother Tongue",
    citizenship: 'Citizenship',
    physicalStatus: 'Physical Status',
    maritalStatus: "Marital Status",
    eatingHabit: 'Eating Habit',
    drinkingHabit: 'Drinking Habit',
    smokingHabit: 'Smoking Habit'
  });

  const submitForm = async () => {
  
    if (
      isStringValid(formData.age) &&
      isStringValid(formData.height) &&
      isStringValid(formData.motherTongue) &&
      isStringValid(formData.citizenship) &&
      formData.physicalStatus.length &&
      formData.maritalStatus.length &&
      formData.eatingHabit.length &&
      formData.smokingHabit.length &&
      formData.drinkingHabit.length
    ) {
      setLoader(true)
      setValidationError("")

      const dataToSend = {
        "age": extractToken(formData.age, true),
        "height": extractToken(formData.height, true),
        "mother_tongue": extractToken(formData.motherTongue, true),
        "citizenship": formData.citizenship,
        "physical_status": formData.physicalStatus,
        "marital_status": extractToken(formData.maritalStatus, false),
        "eating_habit": formData.eatingHabit,
        "smoking_habit": formData.smokingHabit,
        "drinking_habit": formData.drinkingHabit
      };
      uploadUserDetails({
        url: `preference/premium/update-preference`,
        method: 'POST',
        body: dataToSend,
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
      })
    } else {
      const dataArray = {
        age: isStringValid(formData.age),
        height: isStringValid(formData.height),
        motherTongue: isStringValid(formData.motherTongue),
        citizenship: formData.citizenship,
        physicalStatus: formData.physicalStatus,
        maritalStatus: extractToken(formData.maritalStatus, false),
        eatingHabit: formData.eatingHabit,
        drinkingHabit: formData.drinkingHabit,
        smokingHabit: formData.smokingHabit
      }
      var result = Object.keys(dataArray).map((key) => [key, dataArray[key]]);
      for(const item of result || []) {
          if ( !item[1] || item[1].length == 0) {
              toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                  autoClose: 2500,
              })
              break; // This will exit the loop when the condition is met
          }
      }
    }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleCheckBoxChange = (name, value) => {
    setFormData((prevFilterData) => {
      const currentFilter = prevFilterData[name] || [];
      const updatedFilter = currentFilter.includes(value)
        ? currentFilter.filter((item) => item !== value)
        : [...currentFilter, value];
      return {
        ...prevFilterData,
        [name]: updatedFilter,
      };
    });
  };

  const isCheckboxChecked = (name, value) => {
    const dataArray = formData[name];
    return dataArray && dataArray.indexOf(value) !== -1;
    };

  return (
    <>
      <ToastContainer />
      <ModalBox
        open={viewDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success
            title={"Profile Created Successfully"} 
            description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
            buttonText={"Okay"}
            onClick={()=>{
              const storedData = JSON.parse(localStorage.getItem('user'));
              storedData.status = "Active";
              localStorage.setItem('user', JSON.stringify(storedData));
              addScroll()
              navigation("/home")
              setViewDetailsModal(false)
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <ProgressBar width={`100%`} total={1} current={1} />
        <div className={style.uploadDataContainer}>
          <div className={style.form}>
            <p className={style.formTitle}>Upload Video</p>
                <>
                  <div className={style.videoUploadContainer}>
                    <p className={style.text}>Take your profile to the next level with an elite membership and showcase your uniqueness by uploading a captivating video.</p>
                    <Button
                      className={`primaryButton ${style.videoButton}`}
                      onClick={()=>navigation("/subscribe")}
                    >
                      Subscribe
                    </Button>
                  </div>
                </>
          </div>
        </div>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Basic Preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Age"
                name="age"
                responseType="token"
                options={userMetaData?.age} // Add options
                value={formData.age}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Mother Tongue"
                name="motherTongue"
                responseType="token"
                options={userMetaData?.mother_tongue} // Add options
                value={formData.motherTongue}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Physical Status</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('physicalStatus', userMetaData?.physical_status)}
                </div>
              </div>
            </div>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Height"
                name="height"
                responseType="token"
                options={userMetaData?.height} // Add options
                value={formData.height}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <FloatingLabelDropdown
                label="Citizenship"
                name="citizenship"
                responseType="token"
                options={userMetaData?.citizenship} // Add options
                value={formData.citizenship}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Marital Status</p>
                <div className={style.fieldValue}>
                  {
                    userMetaData?.marital_status?.map((item, index) => (
                        <div className={style.content}>
                            <Image
                                onClick={() => handleCheckBoxChange("maritalStatus", item)}
                                src={ isCheckboxChecked("maritalStatus", item) ? checkedIcon : uncheckedIcon }
                                className={style.checkIcon}
                            />
                            <p className={style.label}>{item?.name}</p>
                        </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={style.formContainer}>
          <p className={style.formTitle}>Habits Preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={`${style.field} ${style.marital}`}>
                <p className={style.fieldTitle}>Eating Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('eatingHabit', userMetaData?.eating_habit)}
                </div>
              </div>
              <div style={{ minWidth: "342px", marginTop: "26px" }} className={style.field}>
                <p className={style.fieldTitle}>Drinking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('drinkingHabit', userMetaData?.drinking_habit)}
                </div>
              </div>
            </div>
            <div className={style.hobbieContainer}>
              <div style={{ minWidth: "342px" }} className={style.field}>
                <p className={style.fieldTitle}>Smoking Habits</p>
                <div className={style.fieldValue}>
                  {renderRadioButtons('smokingHabit', userMetaData?.smoking_habit)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
        <Button
           className={`primaryButton ${style.submitButton}`}
           onClick={submitForm}
           loading={isLoading || loader}
        >
          Continue
        </Button>
      </div>
    </>
  );
}
