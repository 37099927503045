import React from 'react'
import classes from './ErrorPage.module.css'
import Image from '../Img/Img'
import errorImage from "../../assets/SVG/common/Eror.svg"
import { Link } from 'react-router-dom'
const ErrorPage = () => {
    return (
        <div className={classes.errorPage}>
            <div className={classes.errorPage_box}>
                <Image src={errorImage} alt="404" />
                <div className={classes.btn}>

                    <Link to="/admin" className="primary__btn w_200 color_white">Go to home page</Link>
                </div>
            </div>

        </div>
    )
}

export default ErrorPage