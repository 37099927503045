import React, { useContext, useEffect, useState } from 'react';
import style from './FamilyDetails.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput';
import Button from '../../../../../ui/Button/Button';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import { BuildProfileContext } from '../Context/BuildProfileContext';
import { getUserToken, isStringValid } from '../../../../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function FamilyDetails() {

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

  const { setActiveStep } = useContext(BuildProfileContext);

  const { sendRequest: getFamilyData } = useApiCall();

  const [familyType, setFamilyType] = useState([]);
  const [familyStatus, setFamilyStatus] = useState([]);
  const [numberOfBrothers, setNumberOfBrothers] = useState('');
  const [numberOfSisters, setNumberOfSisters] = useState('');
  const [brothersMarried, setBrothersMarried] = useState('');
  const [sistersMarried, setSistersMarried] = useState('');
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [fathersOccupation, setFathersOccupation] = useState([]);
  const [mothersOccupation, setMothersOccupation] = useState([]);

  const [hobbiesData, setHobbiesData] = useState([]);
  const [occupationData, setOccupationData] = useState([]);
  const [numberOfBrothersData, setNumberOfBrothersData] = useState([]);
  const [numberOfSistersData, setNumberOfSistersData] = useState([]);
  const [numberOfBrothersMarriedData, setNumberOfBrothersMarriedData] = useState([]);
  const [numberOfSistersMarriedData, setNumberOfSistersMarriedData] = useState([]);
  const [familyTypeData, setFamilyTypeData] = useState([]);
  const [familyStatusData, setFamilyStatusData] = useState([]);

  const [ userData, setUserData ] = useState([])

  const { sendRequest: getUserData } = useApiCall();
  const [ validationError, setValidationError ] = useState("")

  useEffect(() => {
    getFamilyData(
      {
        url: 'onboard/read-meta-data',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const { parent_occupation, hobbies, no_of_sister, no_of_brother, family_type, family_status } = data.data;
        setFamilyStatusData(family_status)
        setFamilyTypeData(family_type)
        setNumberOfSistersData(no_of_sister)
        setNumberOfBrothersData(no_of_brother)
        setOccupationData(parent_occupation);
        setHobbiesData(hobbies);
        setNumberOfBrothersMarriedData(no_of_brother)
        setNumberOfSistersMarriedData(no_of_brother)
      }
    );
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserData({
        url: "onboard/read-step-4",
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
    }, (data) => {
      if( data.data ) {
        const { 
          family_type,
          family_status,
          father_occupation,
          mother_occupation,
          no_of_brother,
          brother_married,
          no_of_sister,
          sister_married,
          hobbies
        } = data?.data
        setFamilyType(family_type? family_type : "")
        setFamilyStatus(family_status? family_status : "")
        setFathersOccupation( father_occupation ? father_occupation : "" )
        setMothersOccupation( mother_occupation ? mother_occupation : "" )
        setNumberOfBrothers( no_of_brother ? no_of_brother : "" )
        setBrothersMarried( brother_married ? brother_married : "" )
        setNumberOfSisters( no_of_sister ? no_of_sister : "" )
        setSistersMarried( sister_married ? sister_married : "" )
        setSelectedHobbies(hobbies.length ? hobbies : [])
        handleMarriedValue("brothers", no_of_brother)
        handleMarriedValue("sisters", no_of_sister)
      } else {
          setUserData(data.data);
      }
    });
  }, []);

  const toggleHobby = (hobbyId) => {
    // Check if the hobby is already selected
    const isHobbySelected = selectedHobbies.includes(hobbyId);

    if (isHobbySelected) {
      // If it's selected, remove it from the selected hobbies
      setSelectedHobbies((prevSelected) =>
        prevSelected.filter((id) => id !== hobbyId)
      );
    } else {
      // If it's not selected, add it to the selected hobbies
      setSelectedHobbies((prevSelected) => [...prevSelected, hobbyId]);
    }
  };

  useEffect(() => {
    if (success) {
      // Optionally, you can navigate to the next step or perform any other actions here
      setActiveStep(5);
    }
    if (error !== '') {
      toast.error(error, {
          autoClose: 2500,
      })
    }
  }, [success, error]);

  const [formDataTitle, setFormDataTitle] = useState({
    family_type: "Family Type",
    family_status: "Family Status",
    father_occupation: "Father Occupation",
    mother_occupation: "Mother Occupation",
    no_of_sister: "No Of Sisters",
    no_of_brother: "No Of Brothers",
    sister_married: "Sisters Married",
    brother_married: "Brothers Married",
    hobbies: "Hobbies",
  });

  const nextStep = () => {
    if( 
      isStringValid(familyType) &&
      isStringValid(familyStatus) &&
      isStringValid(numberOfBrothers) &&
      isStringValid(numberOfSisters) &&
      isStringValid(brothersMarried) &&
      isStringValid(sistersMarried) &&
      isStringValid(selectedHobbies) && 
      isStringValid(fathersOccupation) &&
      isStringValid(mothersOccupation)
    ) {
      // Collect all the form data and radio button selections
      const dataToSend = {
        "family_type": familyType,
        "family_status": familyStatus,
        "father_occupation": fathersOccupation,
        "mother_occupation": mothersOccupation,
        "no_of_brother": numberOfBrothers,
        "brother_married": brothersMarried,
        "no_of_sister": numberOfSisters,
        "sister_married": sistersMarried,
        "hobbies": selectedHobbies,
      };

      uploadUserDetails({
          url: "onboard/save-step-4",
          method: 'POST',
          body: dataToSend,
          headers: {
              Authorization: `Bearer ${getUserToken()}`
          }
      });
    } else {
      const dataArray = {
        family_type: familyType,
        family_status: familyStatus,
        father_occupation: fathersOccupation,
        mother_occupation: mothersOccupation,
        no_of_brother: numberOfBrothers,
        brother_married: brothersMarried,
        no_of_sister: numberOfSisters,
        sister_married: sistersMarried,
        hobbies:  selectedHobbies,
      }
      var result = Object.keys(dataArray).map((key) => [key, dataArray[key]]);
      for(const item of result || []) {
          if ( !item[1] || item[1].length == 0 || item[1] === "Select an option" ) {
              toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                  autoClose: 2500,
              })
              break; // This will exit the loop when the condition is met
          }
      }
    }
  }

  const handleMarriedValue = (type, value) => {
      const arrayData = [{name: "None", value: "None"}]
      for( let i = 1; i <= value; i++ ) {
        arrayData.push({name: `${i}`, value: `${i}`})
      }
      if( type === "brothers" ) {
        setNumberOfBrothersMarriedData(arrayData)
      } else {
        setNumberOfSistersMarriedData(arrayData)
      }
  }

  return (
    <div className={style.workDetailsContainer}>
      <ToastContainer />
      <ProgressBar width={`${16.66*4}%`} total={5} current={4} />
      <div className={style.formContainer}>
        <p className={style.formTitle}>Family Details</p>
        <div className={style.fieldContainer}>
          <FloatingLabelDropdown
            label="Family Type"
            name="country"
            responseType="token"
            options={familyTypeData}
            value={familyType}
            onChange={(e) => setFamilyType(e.target.value)}
          />
          <FloatingLabelDropdown
            label="Family Status"
            name="state"
            responseType="token"
            options={familyStatusData} // Use native state data here
            value={familyStatus}
            onChange={(e) => setFamilyStatus(e.target.value)}
          />
        </div>
        <div className={style.fieldContainer}>
          <FloatingLabelDropdown
            label="Father's Occupation"
            name="country"
            responseType="name"
            options={occupationData}
            value={fathersOccupation}
            onChange={(e) => setFathersOccupation(e.target.value)}
          />
          <FloatingLabelDropdown
            label="Mother's Occupation"
            name="state"
            responseType="name"
            options={occupationData} // Use native state data here
            value={mothersOccupation}
            onChange={(e) => setMothersOccupation(e.target.value)}
          />
        </div> 
        <div className={style.fieldContainer}>
          <div className={style.multipleFieldContainer}>
            <FloatingLabelDropdown
              label="No of Brothers"
              name="number_of_brothers"
              responseType="token"
              options={numberOfBrothersData}
              value={numberOfBrothers}
              onChange={(e) => {
                setNumberOfBrothers(e.target.value)
                handleMarriedValue("brothers", e.target.value)
              }}
            />
            <FloatingLabelDropdown
              label="Brothers Married"
              name="brothers_married"
              responseType="token"
              options={numberOfBrothersMarriedData}
              value={brothersMarried}
              disabled={numberOfBrothers ? false : true}
              onChange={(e) => setBrothersMarried(e.target.value)}
            />
          </div>
          <div className={style.multipleFieldContainer}>
            <FloatingLabelDropdown
              label="No of Sisters"
              name="number_of_sisters"
              responseType="token"
              options={numberOfSistersData}
              value={numberOfSisters}
              onChange={(e) => {
                setNumberOfSisters(e.target.value)
                handleMarriedValue("sisters", e.target.value)
              }}
            />
            <FloatingLabelDropdown
              label="Sisters Married"
              name="sisters_married"
              responseType="token"
              disabled={numberOfSisters ? false : true}
              options={numberOfSistersMarriedData}
              value={sistersMarried}
              onChange={(e) => setSistersMarried(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className={style.formContainer}>
        <p className={style.formTitle}>Hobbies and interest</p>
        <div className={style.hobbiesContainer}>
          {hobbiesData?.map((item) => (
            <div
              key={item.token}
              className={`${style.hobby} ${
                selectedHobbies.includes(item.token) ? style.hobbyActive : ''
              }`}
              onClick={() => toggleHobby(item.token)}
            >
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

      <div className={style.buttonFooterContainer}>
          <Button
              className={`primaryButton ${style.cancelButton}`}
              onClick={()=> setActiveStep(3)}
          >
            Back
          </Button>
          <Button
              className={`primaryButton ${style.submitButton}`}
              onClick={nextStep}
              loading={isLoading}
          >
              Continue
          </Button>
      </div>
    </div>
  );
}
