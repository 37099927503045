import React from "react";
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import classes from "./MemberShip.module.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SubscribePageNew from "../../features/SubscribePageNew/SubscribePageNew";
import { AnimatePresence, motion } from "framer-motion";

const Membership = () => {
  const tabAnimation = {
    initial: {
      y: -200,
      opacity: 0,
    },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1,
      },
    },
    exit: {
      y: -200,
      opacity: 0,
    },
  };

  return (
    <AnimatePresence>
      <PagesHeader title="Membership Plans" />
      <div className={classes.tableContinaer}>
        <motion.dev
          variants={tabAnimation}
          initial="initial"
          animate="animate"
          exit="exit">
          <SubscribePageNew />
        </motion.dev>
      </div>
      <ToastContainer />
    </AnimatePresence>
  );
};

export default Membership;
