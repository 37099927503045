import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './ReligiousPreference.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import Button from '../../../../../ui/Button/Button';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../ui/Image/Image';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import { addScroll, avoidScroll, getUserData, getUserToken, isStringValid, uploadFile } from '../../../../../utils/utils';
import MultiSelectDropdown from '../../../../../ui/MultiSelectDropdown/MultiSelectDropdown';
import { BuildPartnerProfileContext } from '../Context/BuildPartnerProfileContext';
import ModalBox from '../../../../../ui/Modal/ModalBox';
import Success from '../Modal/Success';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection,doc,setDoc,getDoc,updateDoc,getDocs,query,where} from "firebase/firestore"; 
import initializeFirebase from '../../../pages/Chat/Db/FirebaseDb';

export default function ReligiousPreference() {

  const navigation = useNavigate("")
  const {db} = initializeFirebase();
  const userTokenData = getUserData()
  const userToken = userTokenData.user_token;
  const userName = userTokenData.name;
  const userProfileId = userTokenData.user_id;
  const userImage = userTokenData.image;
  const gender = userTokenData.gender;
  const checkUserQuery = doc(db, "Users", userToken);      

  const { sendRequest: getMetaData } = useApiCall();
  const { sendRequest: getCommunityMeta } = useApiCall();
  const [ viewDetailsModal, setViewDetailsModal ] = useState(false)

  const [ communityData, setCommunityData ] = useState(null)
  
  const { setActiveStep, basicPreferenceData, setBasicPreferenceData } = useContext(BuildPartnerProfileContext);

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();

  const [ validationError, setValidationError ] = useState("")

  const [ userData, setUserData ] = useState(null)

  const [ religionValue, setReligionValue ] = useState(null)

  const [formData, setFormData] = useState({
    religion: '',
    star: [],
    community: [],
    gothram: [],
    raasi: [],
    chevvaiDosham: "" 
  });
  const { sendRequest: getUserDatas } = useApiCall();

  useEffect(() => {
    window.scrollTo(0, 0);
    getUserDatas(
      {
        url: 'preference/read-meta-3',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        setUserData(data.data)
      }
    );
  }, []);

  useEffect(() => {
    getMetaData({
      url: "preference/read-step-3",
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`
      }
    }, (data) => {
      const { 
        religious_detail : { 
          religion,
          religion_detail,
          star_detail,
          community_detail,
          gothram_detail,
          raasi_detail,
          chevvai_dosam
        }
      } = data.data;
      setFormData({
        religion: religion_detail,
        star: star_detail,
        community: community_detail,
        gothram: gothram_detail,
        raasi: raasi_detail,
        chevvaiDosham: chevvai_dosam 
      })
    });
  }, []);

  useEffect(() => {
    if( formData.religion?.length > 0 ) {
      getCommunityMeta({
          url: "communities-for-multiple-religion",
          method: 'POST',
          body: {
            "religion_token": extractToken(formData.religion)
          }
      }, (data) => {
          setCommunityData(data.data); // Set work state data
      });
    }
  }, [formData.religion]);

  const handleInputChange = (event, inputType) => {
    if( event.target ) {
      const { name, value } = event.target
        setFormData({
          ...formData,
          [name]: value,
        });
    } else {
      if( inputType === "religion" ) {
        setFormData({
          ...formData,
          [inputType]: event,
          community: []
        });
      } else {
        setFormData({
          ...formData,
          [inputType]: event,
        });
      }
    }
  };

  useEffect(() => {
    if (success) {
      avoidScroll()
      setViewDetailsModal(true)
    }
    if (error !== '') {
      toast.error(error, {
          autoClose: 2500,
      })
    }
  }, [success, error]);

  const extractToken = (data) => {
    let token = []
    data?.map(item=> {
      token.push(item.token)
    })
    return token;
  }

  const [formDataTitle, setFormDataTitle] = useState({
    religion: "Religion",
    community: "Community",
    raasi: "Raasi",
    star: "Star",
    gothram: "Gothram",
    chevvai_dosam: "Chevvai Dosham"
  });

  const submitForm = async () => {
    // if (
    //   formData.religion.length &&
    //   formData.community.length &&
    //   formData.raasi.length &&
    //   formData.star.length &&
    //   formData.gothram.length &&
    //   formData.chevvaiDosham !== ""
    // ) {
      setValidationError("")
      const dataToSend = {
        "religion": extractToken(formData.religion),
        "community": extractToken(formData.community),
        "raasi": extractToken(formData.raasi),
        "star": extractToken(formData.star),
        "gothram": extractToken(formData.gothram),
        "chevvai_dosam": formData.chevvaiDosham
      };
      uploadUserDetails({
        url: `preference/save-step-3`,
        method: 'POST',
        body: dataToSend,
        headers: {
            Authorization: `Bearer ${getUserToken()}`
        }
      });
    // } else {
    //   const dataArray = {
    //     religion: extractToken(formData.religion),
    //     star: extractToken(formData.star),
    //     community: extractToken(formData.community),
    //     gothram: extractToken(formData.gothram),
    //     raasi: extractToken(formData.raasi),
    //     chevvai_dosam: formData.chevvaiDosham,
    //   }
    //   var result = Object.keys(dataArray).map((key) => [key, dataArray[key]]);
    //   for(const item of result || []) {
    //       if ( !item[1] || item[1].length == 0) {
    //           toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
    //               autoClose: 2500,
    //           })
    //           break; // This will exit the loop when the condition is met
    //       }
    //   }
    // }
  };

  const handleRadioChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={style.answers}>
      {options?.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <ToastContainer />
      <ModalBox
        open={viewDetailsModal} 
        width={"598px"} 
        modalContent={
          <Success 
            title={"Profile Created Successfully"} 
            description={"Congratulations! You've taken your first step towards finding your perfect life partner on Doctors matrimony App."} 
            buttonText={"Okay"}
            onClick={()=>{
              const storedData = JSON.parse(localStorage.getItem('user'));
              storedData.status = "Active";
              localStorage.setItem('user', JSON.stringify(storedData));
              addScroll()
              setViewDetailsModal(false)
                getDoc(checkUserQuery).then((userData)=>{
                  if(userData.exists()){
                      // updateDoc(checkUserQuery,{
                      //   isOnline:true,
                      // }).then(()=>{
                      //   navigation("/home")
                      // })
                      navigation("/home")
                  }else{
                      setDoc(checkUserQuery,{
                          image:userImage,
                          profile_id:userProfileId,
                          name:userName,
                          blockList:[],
                          isOnline:true,
                          isDeleted: false
                      }).then(()=>{
                        navigation("/home")
                      })
                  }
              })
            }}
          />
        } 
      />
      <div className={style.workDetailsContainer}>
        <ProgressBar width={`100%`} total={3} current={3} />
        <div className={style.formContainer}>
          <p className={style.formTitle}>Religious preferences</p>
          <div className={style.hobbiesContainer}>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Reigion"
                name="religion"
                responseType="token"
                options={userData?.religion} // Add options
                value={formData.religion}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Community"
                name="community"
                responseType="token"
                options={communityData} // Add options
                value={formData.community}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Raasi"
                name="raasi"
                responseType="token"
                options={userData?.raasi} // Add options
                value={formData.raasi}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
            </div>
            <div className={style.hobbieContainer}>
              <MultiSelectDropdown
                label="Star"
                name="star"
                responseType="token"
                options={userData?.star} // Add options
                value={formData.star}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <MultiSelectDropdown
                label="Gothram"
                name="gothram"
                responseType="token"
                options={userData?.gothram} // Add options
                value={formData.gothram}
                onChange={handleInputChange}
                style={{
                  paddingBottom: '26px',
                }}
              />
              <div className={style.hobbieContainer}>
                <div style={{ maxWidth: "342px" }} className={style.field}>
                  <p className={style.fieldTitle}>Chevvai Dosam</p>
                  <div className={style.fieldValue}>
                    {renderRadioButtons('chevvaiDosham', userData?.chevvai_dosam)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

        <div className={style.buttonFooterContainer}>
            <Button
                className={`primaryButton ${style.cancelButton}`}
                onClick={()=> setActiveStep(2)}
            >
              Back
            </Button>
          <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={submitForm}
            loading={isLoading}
          >
            Continue
          </Button>
        </div>
      </div>
    </>
  );
}
