import React, { useEffect, useState } from 'react'
import style from './MyAccount.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import Image from '../../../../ui/Image/Image';

import back from '../../../web/assets/MyAccount/BackArrow.svg'
import next from '../../../web/assets/MyAccount/NextArrow.svg'
import editmyprofile from '../../../web/assets/MyAccount/Edit My Profile.svg'
import partnerPreferences from '../../../web/assets/MyAccount/Partner Preferences.svg'
import paymenthistory from '../../../web/assets/MyAccount/Payment History.svg'
import profileSettings from '../../../web/assets/MyAccount/Profile Settings.svg'
import blockedProfiles from '../../../web/assets/MyAccount/Blocked Profiles.svg'
import alertsUpdates from '../../../web/assets/MyAccount/AlertsUpdates.svg'
import notificationsettings from '../../../web/assets/MyAccount/Notification Settings.svg'
import makeProfilePrivate from '../../../web/assets/MyAccount/Make Profile Private.svg'
import deleteProfile from '../../../web/assets/MyAccount/Delete Profile.svg'

import EditeMyProfile from '../../features/MyAccount/EditeMyProfile/EditeMyProfile';
import PartnerPreferences from '../../features/MyAccount/PartnerPreferences/PartnerPreferences';
import PaymentHistory from '../../features/MyAccount/PaymentHistory/PaymentHistory';
import ProfileSettings from '../../features/MyAccount/ProfileSettings/ProfileSettings';
import BlockedProfiles from '../../features/MyAccount/BlockedProfiles/BlockedProfiles';
import AlertsUpdates from '../../features/MyAccount/AlertsUpdates/AlertsUpdates';
import NotificationSettings from '../../features/MyAccount/NotificationSettings/NotificationSettings';
import MakeProfilePrivate from '../../features/MyAccount/MakeProfilePrivate/MakeProfilePrivate';
import DeleteProfile from '../../features/MyAccount/DeleteProfile/DeleteProfile';
import ProgressBar from "@ramonak/react-progress-bar";
import { getUserData } from '../../../../utils/utils';

const MyAccount = () => {
    const navigate = useNavigate(); // Initialize useHistory
    const { state } = useLocation();
    const [currentPage, setCurrentPage] = useState(state?.selectedMatch ? state?.selectedMatch : "edit_my_profile");
    const [userData, setUserData] = useState(getUserData());
    const [ refreshSideBar, setRefreshSideBar ] = useState(false)

    useEffect(() => {
        const checkUserData = () => {
            const currentData = getUserData();
            setUserData(currentData);
        };
        setTimeout(()=> {
            checkUserData()
        }, 1000)
    },[ refreshSideBar ]);
    

    const menuItems = [
        {
            title: "Edit My Profile",
            icon: currentPage === "edit_my_profile" ? editmyprofile : editmyprofile,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("edit_my_profile")
            },
        },
        {
            title: "Partner Preferences",
            icon: currentPage === "partner_preferences" ? partnerPreferences : partnerPreferences,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("partner_preferences")
            },
        },
        {
            title: "Payment History",
            icon: currentPage === "payment_history" ? paymenthistory : paymenthistory,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("payment_history")
            },
        },
        {
            title: "Profile Settings",
            icon: currentPage === "profile_settings" ? profileSettings : profileSettings,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("profile_settings")
            },
        },
        {
            title: "Do Not Show Profiles",
            icon: currentPage === "blocked_profiles" ? blockedProfiles : blockedProfiles,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("blocked_profiles")
            },
        },
        {
            title: "Alerts and Updates",
            icon: currentPage === "alerts_and_updates" ? alertsUpdates : alertsUpdates,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("alerts_and_updates")
            },
        },
        {
            title: "Notification Settings",
            icon: currentPage === "notification_settings" ? notificationsettings : notificationsettings,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("notification_settings")
            },
        },
        {
            title: "Make Profile Private",
            icon: currentPage === "make_profile_private" ? makeProfilePrivate : makeProfilePrivate,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("make_profile_private")
            },
        },
        {
            title: "Delete Profile",
            icon: currentPage === "delete_profile" ? deleteProfile : deleteProfile,
            onClick: () => {
                replaceNavigationState()
                setCurrentPage("delete_profile")
            },
        },
    ];

    const replaceNavigationState = () => {
        navigate("/myaccount")
    }
    const backToHome = () => {
        navigate("/home")
    }

    // Define a mapping object for page components
    const pageComponents = {
        edit_my_profile: <EditeMyProfile  refreshSideBar={refreshSideBar} setRefreshSideBar={setRefreshSideBar}/>,
        partner_preferences: <PartnerPreferences  refreshSideBar={refreshSideBar} setRefreshSideBar={setRefreshSideBar}/>,
        payment_history: <PaymentHistory />,
        profile_settings: <ProfileSettings />,
        blocked_profiles: <BlockedProfiles />,
        alerts_and_updates: <AlertsUpdates />,
        notification_settings: <NotificationSettings />,
        make_profile_private: <MakeProfilePrivate />,
        delete_profile: <DeleteProfile />,
        // Add other page components here
    };


    return (
        <div className={`container ${style.matchesContainer}`}>
            <div className={style.sideBarContainer}>
                <div className={style.controle_icon}>
                    <div className={style.arrow}>  <Image src={back} onClick={backToHome} /></div>
                    <p className={style.title}>My Account</p>
                </div>

                <div className={style.contentContainer}>
                    {menuItems.map((menuItem, index) => {
                        return (
                            <MatchMenuItem
                                userData={userData}
                                key={index}
                                title={menuItem.title}
                                icon={menuItem.icon}
                                isActive={currentPage === menuItem.title.toLowerCase().replaceAll(" ", "_")}
                                onClick={menuItem.onClick}
                            />
                        )
                    })}
                </div>
            </div>
            {/* Render the component based on the currentPage */}
            {pageComponents[currentPage]}

        </div>
    )
}

export default MyAccount


export function MatchMenuItem({ userData, title, isActive, icon, onClick }) {
    return (
        <>
            <div style={{ paddingBottom: title === "Edit My Profile" || title === "Partner Preferences" ? 7 : 24 }} className={`${style.content} ${isActive ? style.active : ""}`} onClick={onClick}>
                <div className={style.left_content}>
                    <Image src={icon} />
                    <p className={style.menuTitle}>{title}</p>
                </div>

                <Image src={next} />
            </div>
            {
                title === "Edit My Profile" && 
                <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                    <ProgressBar 
                        completed={userData?.profile_strength}  
                        maxCompleted={100} 
                        height={15}
                        labelSize={13}
                        bgColor={"green"}
                    />
                </div>
            }
            {
                title === "Partner Preferences" && 
                <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                    <ProgressBar 
                        completed={userData?.preference_strength}
                        maxCompleted={100} 
                        height={15}
                        labelSize={13}
                        bgColor={"green"}
                    />
                </div>
            }
        </>
    );
}
