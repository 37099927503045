import React, { useState, useEffect, useRef } from 'react'
import style from './EditeMyProfile.module.css'
import Image from '../../../../../ui/Image/Image';
import ModalBox from "../../../../../ui/Modal/ModalBox";
import profile from '../../../../web/assets/png/Profile/Profile Photo@2x.png'
import profession from '../../../../web/assets/MyAccount/Profession.svg'
import edit from '../../../../web/assets/MyAccount/Edit Profile.svg'
import { getUserToken, uploadFile, getUserData, defaultDate, formatMuiDate, isStringValid, currentDateForFilename, slugify, checkPdf, avoidScroll, addScroll, formatYMDDate } from '../../../../../utils/utils';
import FloatingDatePicker from '../../../../../ui/FloatingDatePicker/FloatingDatePicker';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import Button from '../../../../../ui/Button/Button'
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import warningIcon from "../../../assets/common/warning.svg"
import closeIcon from "../../../assets/svg/Signup/Close.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import PopoutTextarea from '../../../../../ui/PopoutTextarea/PopoutTextarea';
import uploadIcon from '../../../assets/common/Upload Image.svg';
import uploadWhiteIcon from '../../../assets/common/upload-white.svg';
import deleteIcon from '../../../assets/common/Bin.svg';
import MedicalConditions from './medicalCondition/medicalCondition';
import FamilyDetails from './FamilyDetails/FamilyDetails'
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput';
import Success from './Modal/Success';
import { Circles } from 'react-loader-spinner';
import pdfIcon from "../../../assets/common/pdf.svg"
import whiteCheckbox from "../../../assets/common/whiteCheckbox.svg"
import orangeCheckbox from "../../../assets/common/orangeCheckbox.svg"
import { VerifyOtp } from './VerifyOtp/VerifyOtp';
import { useNavigate } from 'react-router-dom';
import CheckOnIcon from "../../../assets/svg/Premium/CheckOn.svg";
import timerIcon from "../../../assets/common/Clock.svg"
import notesIcon from "../../../assets/common/notes.svg"
import { ToastContainer, toast } from 'react-toastify';
import VideoThumbnail from 'react-video-thumbnail';
import { Viewer } from '../../ProfileDetails/ReligiousDetails/Viewer/Viewer';
// import notesIcon from "../../../assets/common/notes.svg"

const EditeMyProfile = ({ refreshSideBar, setRefreshSideBar }) => {

    const navigation = useNavigate()
    const { isLoading: isLoading, success: success, error: error, sendRequest: getMyprofile } = useApiCall();
    const { sendRequest: getMetaData } = useApiCall();
    const { sendRequest: getCommunity } = useApiCall();
    const { sendRequest: getCountry } = useApiCall();
    const { sendRequest: getState } = useApiCall();
    const { sendRequest: getCity } = useApiCall();
    const { sendRequest: getEmployedIn } = useApiCall();
    const { sendRequest: getAnnualIncome } = useApiCall();
    const { sendRequest: getMedaData } = useApiCall();


    const uploadVideoInput = useRef(null);
    const uploadInputs = useRef([]);

    const openPop = {
        photos: { open: false },
        qualificationDetailsModal: { open: false },
        photosBasicUser: { open: false },
        videos: { open: false },
        horoscope: { open: false },
        personalInformation: { open: false },
        personalInformationBasicUser: { open: false },
        basicDetails: { open: false },
        professionalDetails: { open: false },
        familyDetails: { open: false },
        habitsDetails: { open: false },
        interests: { open: false },
        workloction: { open: false },
        medicalhistory: { open: false },
    }
    const [modal, setModal] = useState(false)
    const [showPopPup, setShowPopPup] = useState(openPop)
    const [viewDetailsModal, setViewDetailsModal] = useState(false);

    const [myAccount, setMyAccount] = useState([])
    const [isOpen, setIsOpen] = useState(false);
    const [managePhoto, setManagePhoto] = useState([])
    const [personalDetail, setPersonalDetail] = useState([])
    const [basicDetail, setBasicDetail] = useState([])
    const [professionalDetail, setProfessionalDetail] = useState([])
    const [familyDetail, setFamilyDetail] = useState([])
    const [habitDetail, setHabitDetail] = useState([])
    const [hobbiesDetail, setHobbiesDetail] = useState([])
    const [workLocation, setWorkLocation] = useState([])
    const [medicalHistory, setMedicalHistory] = useState([])
    const [profileCheckBoxClicked, setProfileCheckBoxClicked] = useState(false)
    const [ showSetProfileModal, setShowSetProfileModal ] = useState(false)
    const [ selectedProfileImage, setSelectedProfileImage ] = useState(null)
    const [ showQualificationViewerModal, setShowQualificationViewerModal ] = useState(false)
    const [ selectedQualificationPreview, setSelectedQualificationPreview ] = useState(null)
    const [ weightData, setWeightData ] = useState(null)
    const [ editMyVideoStatus, setEditMyVideoStatus ] = useState("")


    const [selectedCertificate, setSelectedCertificate] = useState(null);
    const [certificateError, setCertificateError] = useState("");
    const [selectedCertificatePreview, setSelectedCertificatePreview] = useState(null);

    // ==================Manage Photos & Videos=============================
    const [uploadedImages, setUploadedImages] = useState([]);
    const [uploadedVideo, setUploadedVideo] = useState(null);
    const [uploadedVideoDisplay, setUploadedVideoDisplay] = useState(null);
    const [videoThumbnail, setVideoThumbnail] = useState(null);
    const [imageFiles, setImageFiles] = useState([]);
    const [imageUploadError, setImageUploadError] = useState("")
    const [videoUploadError, setVideoUploadError] = useState("")
    const [imageFilesCancel, setImageFilesCancel] = useState([]);
    const [videoFile, setVideoFile] = useState("test.mp4");
    const [uploadPersonalVideo, setUploadPersonalVideo] = useState("");
    const [videoIsLoading, setVideoIsLoading] = useState(false);
    const [showViewerModal, setShowViewerModal] = useState(false);
    
    // ==================Professional =============================

    const [institutionName, setInstitutionName] = useState("");
    const [employedIn, setEmployedIn] = useState("");
    const [annualIncome, setAnnualIncome] = useState("");
    const [occupation, setOccupation] = useState("");


    const [employedInData, setEmployedInData] = useState([]); // Separate for native state
    const [occupationData, setOccupationData] = useState([]); // Separate for native state
    const [annualIncomeData, setAnnualIncomeData] = useState([]); // Separate for native state
    // ==================personalinformation=============================
    const [personalInformation, setPersonalInformation] = useState("")
    // ==================Basic Details=============================
    const [validationError, setValidationError] = useState("")
    const [motherTongueData, setMotherTongueData] = useState([])
    const [heightData, setHeightData] = useState([])
    const [citizenshipData, setCitizenshipData] = useState([])
    const [userMeta, setUserMeta] = useState([]);
    const [religion, setReligion] = useState([]);
    const [communityData, setCommunityData] = useState([]);
    const [community, setCommunity] = useState([])
    const [gothram, setGothram] = useState([]);
    const [star, setStar] = useState([]);
    const [refreshApi, setRefreshApi] = useState(false);
    const [raasi, setRaasi] = useState([]);
    const [qualificationLoading, setQualificationLoading] = useState(false);
    const [countryData, setCountryData] = useState([]);
    // Separate state data for native and work locations
    const [nativeCountry, setNativeCountry] = useState({
        token: "",
        value: ""
    });
    const [nativeStateData, setNativeStateData] = useState([]); // Separate for native state
    const [nativeState, setNativeState] = useState({
        token: "",
        value: ""
    });
    const [nativeCityData, setNativeCityData] = useState([]); // Separate for native city
    const [nativeCity, setNativeCity] = useState({
        token: "",
        value: ""
    });



    const [formData, setFormData] = useState({
        dob: "",
        height: [],
        weight: "",
        motherTongue: [],
        emailAddress: "",
        citizenship: [],
        religion: [],
        gothram: [],
        raasi: [],
        star: [],
        maritalStatus: '',
        ChevvaiDhosham: '',
        physicalStatus: '',

    });
    const [formDataBasicUser, setFormDataBasicUser] = useState({
        dob: "",
        emailAddress: ""
    });
    // ==================Habit =============================
    const [eatingHabits, setEatingHabits] = useState('');
    const [drinkingHabits, setDrinkingHabits] = useState('');
    const [smokingHabits, setSmokingHabits] = useState('');


    const [eatingHabitMeta, setEatingHabitMeta] = useState('');
    const [drinkingMeta, setDrinkingMeta] = useState('');
    const [smokingHabitMeta, setSmokingHabitMeta] = useState('');

    // ==================Hobbies & Interests=============================

    const [hobbiesData, setHobbiesData] = useState([]);
    const [selectedHobbies, setSelectedHobbies] = useState([]);




    // ===================== Work Loaction ===================
    const [workCountry, setWorkCountry] = useState({
        token: "",
        value: ""
    });
    const [workStateData, setWorkStateData] = useState([]); // Separate for work state
    const [workState, setWorkState] = useState({
        token: "",
        value: ""
    });
    const [workCityData, setWorkCityData] = useState([]); // Separate for work city
    const [workCity, setWorkCity] = useState({
        token: "",
        value: ""
    });
    const [ qualificationData, setQualificationData ] = useState([]);
    const [physicalStatusData, setPhysicalStatusData] = useState([])
    const [maritalStatusData, setMaritalStatusData] = useState([])
    const [ chevvaiDosamData, setChevvaiDosamData ] = useState([])
    const [ data, setData ] = useState(null)
    const [qualifications, setQualifications] = useState([]);
    const [ qualificationErrorData, setQualificationErrorData ] = useState([]);

    const [imagePreviews, setImagePreviews] = useState([
      null // Initial image previews, one for each qualification
    ]);


    const { sendRequest: getQualifications } = useApiCall();

    useEffect(()=> {

        let maxWeight = 150;    
        let minWeight = 35;   
        let dateOption = []  
        for( let i = minWeight; maxWeight >= minWeight; i++ ) {
          dateOption.push({
            name: minWeight+ " Kg",
            value: minWeight+ " Kg",
          })   
          minWeight += 1;
        }
        setWeightData(dateOption)

        getQualifications({
            url: "education",
            method: 'POST',
        }, resultQualification)
    }, [])

    const resultQualification = (data) => {
      setQualificationData(data.data)
    }


    const addQualification = () => {
        if( qualifications?.length < 10 ) {
          setQualifications([...qualifications, { college_name: '', qualification_detail: '', certificate_url: null }]);
        }
        setImagePreviews([...imagePreviews, null]);
      };
    
      const removeQualification = (index) => {
        const updatedQualifications = [...qualifications];
        updatedQualifications.splice(index, 1);
        setQualifications(updatedQualifications);
    
        const updatedImagePreviews = [...imagePreviews];
        updatedImagePreviews.splice(index, 1);
        setImagePreviews(updatedImagePreviews);
      };
    
  
      const handleQualificationUpload = (e, index) => {
          const file = e.target.files[0];
      
          if( file ) { 
          // Check if the file size is greater than 6MB
          if (file && file.size > 6 * 1024 * 1024) {
              const errors = [...qualificationErrorData];
              errors[index] = "File size must be less than 6MB.";
              setQualificationErrorData(errors);
              return;
          }
          
          // Clear the specific error message for this file
          const errors = [...qualificationErrorData];
          errors[index] = "";
          setQualificationErrorData(errors);
          
          const updatedQualifications = [...qualifications];
          updatedQualifications[index].certificate_url = file;
          setQualifications(updatedQualifications);
          
          // Handle image preview
          const reader = new FileReader();
          reader.onload = (event) => {
              const updatedImagePreviews = [...imagePreviews];
              updatedImagePreviews[index] = event.target.result;
              setImagePreviews(updatedImagePreviews);
          };
          reader.readAsDataURL(file);
          
          // Clear any previous submit error message
          // setSubmitError("");
          }
      };

      const handleNameChange = (e, index) => {
        const updatedQualifications = [...qualifications];
        updatedQualifications[index][e.target.name] = e.target.value;
        setQualifications(updatedQualifications);
      };

    useEffect(() => {
        setSelectedHobbies(hobbiesDetail?.map((item) => item.token));
    }, [hobbiesDetail]);



    function timestampToMMDDYYYY(timestamp) {
        const date = new Date(timestamp);
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${month}-${day}-${year}`;
    }

    useEffect(() => {
      getMetaData(
        {
          url: 'onboard/read-meta-data',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        },
        (data) => {
          const { chevvai_dosam, physical_status, marital_status } = data.data;
          setChevvaiDosamData(chevvai_dosam)
          setPhysicalStatusData(physical_status)
          setMaritalStatusData(marital_status)
        }
      );
    }, []);

    useEffect(() => {
        setFormData({
            ...formData,
            dob: timestampToMMDDYYYY(basicDetail?.date_of_birth),
            motherTongue: basicDetail?.mother_tongue_token,
            emailAddress: basicDetail?.email,
            height: basicDetail?.height_token,
            citizenship: basicDetail?.citizenship_token,
            religion: basicDetail?.religion_token,
            gothram: basicDetail?.gothram_token,
            raasi: basicDetail?.raasi_token,
            star: basicDetail?.star_token,
            maritalStatus: basicDetail?.marital_status,
            ChevvaiDhosham: basicDetail?.chevvai_dosam,
            physicalStatus: basicDetail?.physical_status,
        })
        setCommunity(basicDetail?.community_token)
        setNativeCountry({ ...nativeCountry, token: basicDetail?.country_id, value: basicDetail?.country })
        setNativeState({ ...nativeState, token: basicDetail?.state_id, value: basicDetail?.state })
        setNativeCity({ ...nativeCity, token: basicDetail?.city_id, value: basicDetail?.city })
    }, [basicDetail])


    useEffect(() => {
        setWorkCountry({ ...workCountry, token: workLocation?.country_id, value: workLocation?.country })
        setWorkState({ ...workState, token: workLocation?.state_id, value: workLocation?.state })
        setWorkCity({ ...workCity, token: workLocation?.city_id, value: workLocation?.city })
    }, [workLocation])

    useEffect(() => {
        setInstitutionName(professionalDetail?.institution_working)
        setEmployedIn(professionalDetail?.employed_token)
        setAnnualIncome(professionalDetail?.annual_income_token)
        setOccupation(professionalDetail?.occupation_token)
    }, [professionalDetail])

    const getMyprofileApi = () => {
        getMyprofile(
            {
                url: "my-account/my-profile/read",
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                setData(data.data)
                setEditMyVideoStatus(data.data.video_status)
                if( !data?.data?.is_onboard_completed ) {
                    setMyAccount(data.data.registered_detail)
                    setManagePhoto( data.data?.image_detail_array )
                    setFormDataBasicUser({ ...formDataBasicUser, emailAddress: data.data.registered_detail?.email })
                } else {
                    setQualifications(data.data?.qualification_details)

                    let urls = []
                    
                    data.data?.qualification_details?.map((item)=> {
                        urls.push(item?.certificate_url)
                    })
                    setImagePreviews(urls)
                    setMyAccount(data.data.profile)
                    setManagePhoto( data.data?.image_detail_array )
                    setPersonalDetail(data.data?.personal_detail)
                    setPersonalInformation(data.data?.personal_detail)
                    setBasicDetail(data.data?.basic_detail)
                    setProfessionalDetail(data.data?.professional_detail)
                    setFamilyDetail(data.data?.family_detail)
                    setHabitDetail(data.data?.habit_detail)
                    setHobbiesDetail(data.data?.hobbies_detail)
                    setWorkLocation(data.data?.work_location_details)
                    setVideoFile(data.data?.video)
                    setUploadedVideo(data.data?.video)
                    setUploadedVideoDisplay(data.data?.video)
                    setUploadPersonalVideo(data.data?.video)
                    setSelectedCertificatePreview(data.data?.basic_detail?.horoscope)
                    setRefreshSideBar(!refreshSideBar)
                }
            }
        );
    }

    useEffect(() => {
        getMyprofileApi()
    }, [refreshApi]);


    const basic_details1 = [
        { title: "Date of Birth", sub_title: basicDetail?.date_of_birth },
        { title: "Height", sub_title: basicDetail?.height },
        { title: "Physical Status", sub_title: basicDetail?.physical_status },
        { title: "Marital Status", sub_title: basicDetail?.marital_status },
        { title: "Country", sub_title: basicDetail?.country },
        { title: "Gender", sub_title: basicDetail?.gender },
        { title: "Weight", sub_title: basicDetail?.weight+ " Kg" },
    ]
    const basic_details2 = [
        { title: "State", sub_title: basicDetail?.state },
        { title: "District/City", sub_title: basicDetail?.city },
        { title: "Citizenship", sub_title: basicDetail?.citizenship },
        { title: "Religion", sub_title: basicDetail?.religion },
        { title: "Mother Tongue", sub_title: basicDetail?.mother_tongue },
        { title: "Email Address", sub_title: basicDetail?.email },
    ]
    const basic_details3 = [
        { title: "Mobile Number", sub_title: basicDetail?.mobile_number },
        { title: "Community", sub_title: basicDetail?.community },
        { title: "Gothram", sub_title: basicDetail?.gothram },
        { title: "Star", sub_title: basicDetail?.star },
        { title: "Raasi", sub_title: basicDetail?.raasi },
        { title: "Chevvai Dosham", sub_title: basicDetail?.chevvai_dosam },
    ]

    const professionalDetails1 = [
        { title: "Institution", sub_title: professionalDetail?.institution_working },
        { title: "Occupation", sub_title: professionalDetail?.occupation },
    ]
    const professionalDetails2 = [
        // { title: "Education", sub_title: professionalDetail.occupation },
        { title: "Employed in", sub_title: professionalDetail?.employed_in },

    ]
    const professionalDetails3 = [
        { title: "Annual Income", sub_title: professionalDetail?.annual_income },
        // { title: "Employed in", sub_title: professionalDetail.employed_in },
    ]

    const familyDetails1 = [
        { title: "Family Type", sub_title: familyDetail?.family_type },
        { title: "Mother’s Occupation", sub_title: familyDetail?.mother_occupation },
        { title: "Married Brother", sub_title: familyDetail?.married_brother },
    ]
    const familyDetails2 = [
        { title: "Family Status", sub_title: familyDetail?.family_status },
        { title: "No of Brothers", sub_title: familyDetail?.brothers },
        { title: "Married Sisters", sub_title: familyDetail?.married_sister },
    ]
    const familyDetails3 = [
        { title: "Father’s Occupation", sub_title: familyDetail?.father_occupation },
        { title: "No of Sisters", sub_title: familyDetail?.sisters },
    ]

    const habitsDetails1 = [
        { title: "Eating Habits", sub_title: habitDetail?.eating_habit },
    ]
    const habitsDetails2 = [
        { title: "Drinking Habits", sub_title: habitDetail?.drinking_habit },
    ]
    const habitsDetails3 = [
        { title: "Smoking Habits", sub_title: habitDetail?.smoking_habit },
    ]


    const work_location1 = [
        { title: "Country", sub_title: workLocation?.country },
    ]
    const work_location2 = [
        { title: "State", sub_title: workLocation?.state },
    ]
    const work_location3 = [
        { title: "District/City", sub_title: workLocation?.city },
    ]




    const DetailsEdit = (data) => {
        let modal = openPop;
        if (data === "QualificationDetails") {
            modal.qualificationDetailsModal.open = true
            setImageUploadError("")
            setViewDetailsModal(true)
        }
        if (data === "Photos") {
            modal.photos.open = true
            setImageUploadError("")
            setViewDetailsModal(true)
        }
        if (data === "PhotosBasicUser") {
            modal.photosBasicUser.open = true
            setImageUploadError("")
            setViewDetailsModal(true)
        }
        if (data === "Videos") {
            modal.videos.open = true
            setVideoUploadError("")
            setViewDetailsModal(true)
        }
        if (data === "horoscope") {
            modal.horoscope.open = true
            setSelectedCertificatePreview(basicDetail?.horoscope)
            setViewDetailsModal(true)
        }
        if (data === "PersonalInformation") {
            modal.personalInformation.open = true
            setViewDetailsModal(true)
        }
        if (data === "PersonalInformationBasicUser") {
            modal.personalInformationBasicUser.open = true
            setViewDetailsModal(true)
        }
        if (data === "BasicDetails") {
            modal.basicDetails.open = true
            setViewDetailsModal(true)
        }
        if (data === "ProfessionalDetails") {
            modal.professionalDetails.open = true
            setViewDetailsModal(true)
        }
        if (data === "FamilyDetails") {
            modal.familyDetails.open = true
            setViewDetailsModal(true)
        }
        if (data === "HabitsDetails") {
            modal.habitsDetails.open = true
            setViewDetailsModal(true)
        }
        if (data === "Interests") {
            modal.interests.open = true
            setViewDetailsModal(true)
        }
        if (data === "WorkLocation") {
            modal.workloction.open = true
            setViewDetailsModal(true)
        }
        if (data === "MedicalHistory") {
            modal.medicalhistory.open = true
            setViewDetailsModal(true)
        }
        setShowPopPup({ ...modal })
    }

    const personalInformationChanges = (event) => {
        const { name, value } = event.target;

        setPersonalInformation(value)
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        console.log("name", name);
        if( name === "religion" ) {
            setFormData({
                ...formData,
                [name]: value,
                community: ""
            });
            setCommunity("")
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleInputChangeBasicUser = (event) => {
        const { name, value } = event.target;
        setFormDataBasicUser({
            ...formDataBasicUser,
            [name]: value,
        });
    }

    useEffect(() => {
        getCommunity({
            url: "community",
            method: 'POST',
            body: {
                "religion_token": formData.religion,
            }
        }, (data) => {
            setCommunityData(data.data);
        });
    }, [formData]);

    const getMedaDataRequst = () => {
        getMetaData(
            {
                url: 'onboard/read-meta-data',
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            },
            (data) => {
                const { mother_tongue, citizenship, height, religion, gothram, rasi, star, hobbies, employed_in, occupation, medical_question, eating_habit, smoking_habit, drinking_habit } = data.data;
                setMotherTongueData(mother_tongue);
                setCitizenshipData(citizenship);
                setHeightData(height);
                setReligion(religion);
                setGothram(gothram);
                setRaasi(rasi)
                setStar(star)
                setHobbiesData(hobbies);
                setEmployedInData(employed_in)
                setOccupationData(occupation)
                setMedicalHistory(medical_question)
                setUserMeta(data.data);
                setCountryData(data.data.country);
                setAnnualIncomeData(data.data.annual_income);
                setEatingHabitMeta(eating_habit)
                setSmokingHabitMeta(smoking_habit)
                setDrinkingMeta(drinking_habit)

            }
        );
    }
    useEffect(() => {
        getMedaDataRequst()
    }, []);

    useEffect(() => {
        getState({
            url: "state",
            method: 'POST',
            body: {
                "country_id": nativeCountry.token
            }
        }, (data) => {
            setNativeStateData(data.data); // Set native state data

        });
    }, [nativeCountry.token]);

    useEffect(() => {
        getState({
            url: "state",
            method: 'POST',
            body: {
                "country_id": workCountry.token
            }
        }, (data) => {
            setWorkStateData(data.data); // Set work state data
        });
    }, [workCountry.token]);


    useEffect(() => {
        getCity({
            url: "city",
            method: 'POST',
            body: {
                "state_id": nativeState.token
            }
        }, (data) => {
            setNativeCityData(data.data); // Set native city data
        });
    }, [nativeState.token]);

    useEffect(() => {
        getCity({
            url: "city",
            method: 'POST',
            body: {
                "state_id": workState.token
            }
        }, (data) => {
            setWorkCityData(data.data); // Set work city data
        });
    }, [workState.token]);

    const handleRadioChange = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // ==================Manage Photos & Videos =============================

    useEffect(() => {
        if (managePhoto && managePhoto.length > 0) {
            setUploadedImages(managePhoto);
            setImageFiles(managePhoto);
        }
    }, [managePhoto]);

    const handleImageDelete = (index) => {
        const newImages = [...uploadedImages];
        newImages[index] = null;
        setUploadedImages(newImages);

        // Reset the file input
        if (uploadInputs.current[index]) {
            uploadInputs.current[index].value = null;
        }

        const newImageFiles = [...imageFiles];
        newImageFiles[index] = null;
        setImageFiles(newImageFiles);
    };

    const handleImageUpload = (event, index) => {
        const file = event.target.files[0];
        const allowedFormats = ['image/jpeg', 'image/png', 'image/gif']; // Add more formats if needed
        if (file) {
            if (file.size <= 6 * 1024 * 1024) {
                if (allowedFormats.includes(file.type)) {
                    const newImages = [...uploadedImages];
                    newImages[index] = URL.createObjectURL(file); // Store the data URL for preview
                    setUploadedImages(newImages);

                    const newImageFiles = [...imageFiles];
                    newImageFiles[index] = file; // Store the File object
                    setImageFiles(newImageFiles);
                }  else {
                    // Invalid file format, handle the error here (e.g., show a message to the user)
                    toast.error('Invalid file format. Please choose a JPEG, PNG, or GIF file.', {
                      autoClose: 2500,
                    });
                    // Optionally, you can clear the input field to allow the user to choose another file
                    event.target.value = null;
                }
            } else {
                // File size exceeds 6 MB, handle the error here (e.g., show a message to the user)
                toast.error('File size exceeds 6 MB. Please choose a smaller file.', {
                    autoClose: 2500,
                })
                // Optionally, you can clear the input field to allow the user to choose another file
                event.target.value = null;
            }
        }
    };


    const { isLoading: imageisLoading, success: imagesuccess, error: imageerror, sendRequest: imageRequst } = useApiCall();

    useEffect(() => {
        if (imagesuccess) {
            setUploadedImages([])
        }
    }, [])

    useEffect(()=> {
        if(imageerror) {
            toast.error(imageerror, {
                autoClose: 2500,
            })
        }
    }, [imageerror])

    const sendImgeandVedioFile = async () => {
        if (imageFiles.length) {
            const uploadedImageUrls = await Promise.all(
                imageFiles.map(async (imageFile) => {
                    if( imageFile !== null ) {
                        if (typeof imageFile === "object" && imageFile?.name) {
                            return await uploadFile(imageFile);
                        } else if (typeof imageFile === "object") {
                            return imageFile?.content;
                        }
                    }
                    return imageFile
                })
            );

            let isError = true
            let finalImages = []
            uploadedImageUrls?.map((item) => {
                if (item !== null || item) {
                    finalImages?.push(item)
                    isError = false
                }
            })

            if (!isError) {
                setImageUploadError("")
                imageRequst({
                    url: "my-account/my-profile/update/photos",
                    method: 'POST',
                    body: {
                        "user_image": finalImages,
                    },
                    headers: {
                        Authorization: `Bearer ${getUserToken()}`
                    }
                }, (data) => {
                    setModal(true);
                    // setUploadedImages([]);
                    // setManagePhoto(finalImages);
                });
            } else {
                setImageUploadError("Please select an image")
            }
        }
    };

    const uploadElements = [];

    // const numberOfImages = getUserData()?.membership_type === "Basic" ? 1 : 6

    for (let i = 0; i < 6; i++) {

        uploadElements.push(
            <>
                {uploadedImages[i] ? (
                    <div key={i} className={style.uploadedImage}>
                        <Image src={ ( typeof uploadedImages[i] === "string" && uploadedImages[i]?.includes("blob:") ) ? uploadedImages[i] : process.env.REACT_APP_S3_CLOUD_FRONT_URL + uploadedImages[i].content} className={style.bgImage} />
                       
                        <div className={style.deleteContainer}>
                            <Image src={deleteIcon} onClick={() => handleImageDelete(i)} />
                        </div>
                        {
                            uploadedImages[i]?.status !== "Approved" && (
                                <div className={style.reuploadContainer}>
                                    {/* <Image src={uploadWhiteIcon} /> */}
                                    <p className={style.reuploadLabeText}>{ ( uploadedImages[i]?.status === "Pending" || !uploadedImages[i]?.status ) ? "Waiting Verification" : uploadedImages[i]?.status }</p>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <div key={i} className={style.upload} onClick={() => uploadInputs.current[i].click()}>
                        <label className={style.uploadImageLabel}>
                            <div className={style.uploadImage}>
                                <img
                                    src={uploadIcon}
                                    className={style.inImg}
                                    alt="Upload Icon"
                                />
                            </div>
                        </label>
                        <p className={style.uploadLabel}>Upload Image</p>
                    </div>
                )}
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(event) => handleImageUpload(event, i)}
                    className={style.uploadInput}
                    ref={(el) => (uploadInputs.current[i] = el)} // Assign the ref to the input element
                />
            </>
        );
    }



    const uploadElementsBasicUser = [];

    console.log("uploadedImages", uploadedImages);

    // const numberOfImages = getUserData()?.membership_type === "Basic" ? 1 : 6

    for (let i = 0; i < 1; i++) {

        uploadElementsBasicUser.push(
            <>
                {uploadedImages[i] ? (
                    <div key={i} className={style.uploadedImage}>
                        <Image src={ ( typeof uploadedImages[i] === "string" && uploadedImages[i]?.includes("blob:") ) ? uploadedImages[i] :  typeof uploadedImages[i] === "string" ? process.env.REACT_APP_S3_CLOUD_FRONT_URL + uploadedImages[i] :  process.env.REACT_APP_S3_CLOUD_FRONT_URL + uploadedImages[i].content } className={style.bgImage} />
                       
                        <div className={style.deleteContainer}>
                            <Image src={deleteIcon} onClick={() => handleImageDelete(i)} />
                        </div>
                        {
                            uploadedImages[i]?.status !== "Approved" && (
                                <div className={style.reuploadContainer}>
                                    {/* <Image src={uploadWhiteIcon} /> */}
                                    <p className={style.reuploadLabeText}>{uploadedImages[i]?.status === "Pending" ? "Waiting Verification" : uploadedImages[i]?.status }</p>
                                </div>
                            )
                        }
                    </div>
                ) : (
                    <div key={i} className={style.upload} onClick={() => uploadInputs.current[i].click()}>
                        <label className={style.uploadImageLabel}>
                            <div className={style.uploadImage}>
                                <img
                                    src={uploadIcon}
                                    className={style.inImg}
                                    alt="Upload Icon"
                                />
                            </div>
                        </label>
                        <p className={style.uploadLabel}>Upload Image</p>
                    </div>
                )}
                <input
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(event) => handleImageUpload(event, i)}
                    className={style.uploadInput}
                    ref={(el) => (uploadInputs.current[i] = el)} // Assign the ref to the input element
                />
            </>
        );
    }

    // ==================Personal Information =============================
    const { isLoading: personalInformatisLoading, success: personalInformatsuccess, error: personalInformaterror, sendRequest: personalInformationRequst } = useApiCall();

    useEffect(()=> {
        if(personalInformaterror) {
            toast.error(personalInformaterror, {
                autoClose: 2500,
            })
        }
    }, [personalInformaterror])

    const personalInformationsubmit = () => {
        // if (personalInformation) {
            personalInformationRequst({
                url: "my-account/my-profile/update/about",
                method: 'POST',
                body: { "about": personalInformation.trim() },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        // } else {
        //     setValidationError("Some required fields are missing.")
        // }

    }
    // ==================Basic Details Edit=============================
    const { isLoading: basicDetailsisLoading, success: basicDetailssuccess, error: basicDetailserror, sendRequest: basicDetailsUpdate } = useApiCall();
    const { isLoading: basicUserDetailsisLoading, success: basicUserDetailssuccess, error: basicUserDetailserror, sendRequest: basicUserDetailsUpdate } = useApiCall();

    useEffect(()=> {
        if(basicDetailserror) {
            toast.error(basicDetailserror, {
                autoClose: 2500,
            })
        }
    }, [basicDetailserror])

    useEffect(()=> {
        if(basicUserDetailserror) {
            toast.error(basicUserDetailserror, {
                autoClose: 2500,
            })
        }
    }, [basicUserDetailserror])

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return regex.test(email);
    };

    const basicUserDetailsSubmit = () => {
        const email = formDataBasicUser?.emailAddress?.trim()
        if( email && validateEmail(email) ) {
            const data = {
                "email": formDataBasicUser?.emailAddress?.trim(),
            }
    
            basicUserDetailsUpdate({
                url: "my-account/my-profile/update/update-email",
                method: 'POST',
                body: data,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        } else {
            if( !email ) {
                toast.error("Please enter the Email", {
                    autoClose: 2500,
                })
            } else {
                if( !validateEmail(email) ) {
                    toast.error("Please enter the valid Email", {
                        autoClose: 2500,
                    })
                }
            }
        }
    }
    const basicDetailsSmbite = () => {

        // if (formData.dob &&
        //     formData.height &&
        //     formData.motherTongue &&
        //     formData.citizenship &&
        //     formData.religion &&
        //     formData.gothram &&
        //     formData.raasi &&
        //     formData.star &&
        //     formData.maritalStatus &&
        //     formData.ChevvaiDhosham &&
        //     formData.physicalStatus &&
        //     community &&
        //     nativeCountry.value &&
        //     nativeState.value &&
        //     nativeCity.value
        // ) {
            const data = {
                "dob": formatYMDDate(formData.dob),
                "height": formData.height,
                "religion": formData.religion === "Select an option" ? "" : formData.religion,
                "mother_tongue": formData.motherTongue,
                "email": formData.emailAddress.trim(),
                "physical_status": formData.physicalStatus,
                "marital_status": formData.maritalStatus,
                "citizenship": formData.citizenship,
                "country": nativeCountry.value,
                "state": nativeState.value,
                "city": nativeCity.value,
                "weight": Number(formData?.weight?.split(" ")?.[0]),
                "community":  formData.community === "Select an option" ? "" : community,
                "gothram":  formData.gothram === "Select an option" ? "" : formData.gothram,
                "star":  formData.star === "Select an option" ? "" : formData.star,
                "raasi":  formData.raasi === "Select an option" ? "" : formData.raasi,
                "chevvai_dosam": formData.ChevvaiDhosham
            }

            basicDetailsUpdate({
                url: "my-account/my-profile/update/basic-details",
                method: 'POST',
                body: data,
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                console.log("data", data);
                setModal(true)
            });

        // } else {
        //     setValidationError("Some required fields are missing.")
        // }


    }

    // ==================Habits Information =============================
    const { isLoading: habitsisLoading, success: habitssuccess, error: habitserror, sendRequest: habitsRequst } = useApiCall();

    useEffect(()=> {
        if(habitserror) {
            toast.error(habitserror, {
                autoClose: 2500,
            })
        }
    }, [habitserror])

    const HabitsSmbite = () => {
        // if (eatingHabits && drinkingHabits && smokingHabits) {
            habitsRequst({
                url: "my-account/my-profile/update/habit-details",
                method: 'POST',
                body: {
                    "eating_habit": eatingHabits,
                    "smoking_habit": drinkingHabits,
                    "drinking_habit": smokingHabits
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        // } else {
        //     setValidationError("Some required fields are missing.")
        // }
    }

    useEffect(() => {
        if (habitDetail) {
            setEatingHabits(habitDetail?.eating_habit || '');
            setDrinkingHabits(habitDetail?.drinking_habit || '');
            setSmokingHabits(habitDetail?.smoking_habit || '');
        }
    }, [habitDetail]);


    const toggleHobby = (hobbyId) => {
        // Check if the hobby is already selected
        const isHobbySelected = selectedHobbies.includes(hobbyId);

        if (isHobbySelected) {
            // If it's selected, remove it from the selected hobbies
            setSelectedHobbies((prevSelected) =>
                prevSelected.filter((id) => id !== hobbyId)
            );
        } else {
            // If it's not selected, add it to the selected hobbies
            setSelectedHobbies((prevSelected) => [...prevSelected, hobbyId]);
        }
    };

    // ===================== professionalDetailsSubmit===================

   const { error: professionalDetailsRequstError, sendRequest: professionalDetailsRequst } = useApiCall();

   useEffect(()=> {
       if(professionalDetailsRequstError) {
           toast.error(professionalDetailsRequstError, {
               autoClose: 2500,
           })
       }
   }, [professionalDetailsRequstError])

    const professionalDetailsSubmit = () => {

        // if (institutionName && employedIn && annualIncome) {
            professionalDetailsRequst({
                url: "my-account/my-profile/update/professional-details",
                method: 'POST',
                body: {
                    "institution_working": institutionName.trim(),
                    "employed_in": employedIn,
                    "occupation": occupation,
                    "annual_income": annualIncome
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        // } else {
        //     setValidationError("Some required fields are missing.")
        // }


    }

    // ===================== Hobbies & Interests===================

   const { error: HobbiesInterestsRequstError, sendRequest: HobbiesInterestsRequst } = useApiCall();

   useEffect(()=> {
       if(HobbiesInterestsRequstError) {
           toast.error(HobbiesInterestsRequstError, {
               autoClose: 2500,
           })
       }
   }, [HobbiesInterestsRequstError])

    const handleHobbiesInterests = () => {
        if( selectedHobbies?.length > 0 ) {
            HobbiesInterestsRequst({
                url: "my-account/my-profile/update/hobbies-interests",
                method: 'POST',
                body: {
                    "hobbies": selectedHobbies
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        } else {
            toast.error("Please select hobbies and intrests", {
                autoClose: 2500,
            })
        }

    }


    // ===================== Work Loaction ===================

   const { isLoading: workRequstLoading, success: workRequstSuccess, error: workRequstError, sendRequest: workRequst } = useApiCall();

   useEffect(()=> {
       if(workRequstError) {
           toast.error(workRequstError, {
               autoClose: 2500,
           })
       }
   }, [workRequstError])

    const handleWorkLoction = () => {

        // if (workCountry.value && workCity.value && workState.value) {
            workRequst({
                url: "my-account/my-profile/update/work-location-details",
                method: 'POST',
                body: {
                    "country": workCountry.value,
                    "state": workState.value,
                    "city": workCity.value
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        // } else {
        //     setValidationError("Some required fields are missing.")
        // }
    }


    const Cancel = () => {
        setViewDetailsModal(false)
        setValidationError("")
        getMyprofileApi()
    }

    const refresh = () => {
        getMyprofileApi()
    }
    const refreshMedData = () => {
        getMedaDataRequst()
    }

    // Create a function to render radio buttons
    const renderRadioButtons = (name, options) => (
        <div className={style.answers}>
            {options.map((option) => (
                <div
                    key={option.value}
                    className={style.answer}
                    onClick={() => handleRadioChange(name, option.value)}
                >
                    <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
                    <p>{option.value}</p>
                </div>
            ))}
        </div>
    );

    const handleCertificateUpload = (e) => {
        const selectedFile = e.target.files[0];
        // Check if the file size is greater than 6MB
        if (selectedFile && selectedFile.size > 6 * 1024 * 1024) {
            setCertificateError("File size must be less than 6MB.")
            return;
        }
        setCertificateError("")
        if (selectedFile) {
            setSelectedCertificate(e.target.files[0]);
            const selectedFileURL = URL.createObjectURL(selectedFile);
            setSelectedCertificatePreview(selectedFileURL)
            // setCertificateError('');
        }
    };

   const { isLoading: horoscopeRequestLoading, success: horoscopeRequestSuccess, error: horoscopeRequestError, sendRequest: horoscopeRequest } = useApiCall();

   useEffect(()=> {
       if(horoscopeRequestError) {
           toast.error(horoscopeRequestError, {
               autoClose: 2500,
           })
       }
   }, [horoscopeRequestError])

    const horoscopeSubmit = async () => {
        if (selectedCertificate) {
            const horoscopeUrl = await uploadFile(selectedCertificate);
            horoscopeRequest({
                url: "my-account/my-profile/update/horoscope",
                method: 'POST',
                body: {
                    "horoscope": horoscopeUrl,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        } else {
            console.log("selectedCertificatePreview", selectedCertificatePreview);
            horoscopeRequest({
                url: "my-account/my-profile/update/horoscope",
                method: 'POST',
                body: {
                    "horoscope": selectedCertificatePreview,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setModal(true)
            });
        }
    }

    const handleVideoUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        // Assuming you have a supported video file format like mp4
        if (file.type.startsWith('video/')) {
          // Create a video element to get the duration
          const videoElement = document.createElement('video');
    
          // Listen for the 'loadedmetadata' event to ensure the duration is available
          videoElement.addEventListener('loadedmetadata', () => {
            // Check if the duration is less than 3 minutes (180 seconds)
            if (videoElement.duration <= 180) {
              setUploadedVideo(URL.createObjectURL(file)); // Store the data URL for preview
              setUploadPersonalVideo(URL.createObjectURL(file))
              setEditMyVideoStatus("Pending")
              setVideoFile(file); // Store the File object
              setFormData({ ...formData, video: file });
              generateVideoThumbnail(file);
            } else {
              toast.error(`Video duration exceeds 3 minutes. Please select a shorter video.`, {
                autoClose: 2500,
              })
            }
          });
    
          // Set the video element's source to the file URL
          videoElement.src = URL.createObjectURL(file);
        } else {
          // Handle invalid file type (not a video)
          console.error('Invalid file type. Please select a video file.');
        }
      }
    };
  
    const generateVideoThumbnail = (videoFile) => {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        // Capture a frame from the video as a thumbnail
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  
        // Convert the canvas image to a data URL
        const thumbnailURL = canvas.toDataURL('image/jpeg'); // You can change the format if needed
  
        // setVideoThumbnail(thumbnailURL); // Store the thumbnail URL in state
      };
  
      video.src = URL.createObjectURL(videoFile);
    };

    const { isLoading: isVideoLoading, success: videoSuccess, error: videoError, sendRequest: saveVideo } = useApiCall();

    useEffect(()=> {
        if(videoError) {
            toast.error(videoError, {
                autoClose: 2500,
            })
        }
    }, [videoError])

    const handleVideoSubmit = async() => {
        if( uploadPersonalVideo ) {
            setVideoIsLoading(true)
            // Upload the video file to S3
            let videoUrl = videoFile;
            if ( videoFile  && typeof videoFile === "object") {
                videoUrl = await uploadFile(videoUrl);
            }
            saveVideo({
                url: "my-account/my-profile/update/video",
                method: 'POST',
                body: {
                    "video": videoUrl,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, (data) => {
                setUploadedVideoDisplay(uploadedVideo)
                setVideoIsLoading(false)
                setModal(true)
            });
        } else {
            toast.error("Please select video", {
                autoClose: 2500,
            })
        }
    }

    const familyDetailsError = (error) => {
        toast.error(error, {
            autoClose: 2500,
        })
    }
    const { isLoading: setProfileLoading, success: setProfileSuccess, error: setProfileError, sendRequest: setProfile } = useApiCall();

    useEffect(()=>{
        if( setProfileSuccess ) {
            setShowSetProfileModal(false)
            setModal(true)
        }
        if(setProfileError) {
            toast.error(setProfileError, {
                autoClose: 2500,
            })
        }

    }, [setProfileSuccess, setProfileError])

    const handleSetProfile = (status) => {
        if( status ) {
            setProfile({
                url: "my-account/edit-profile/set-profile-image",
                method: 'POST',
                body: {
                    "token": selectedProfileImage?.token,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            })
        }
    }
    const { isLoading: updateQualificationsLoading, success: updateQualificationsSuccess, error: updateQualificationsError, sendRequest: updateQualifications } = useApiCall();

    useEffect(()=>{
        if( updateQualificationsSuccess ) {
            setQualificationLoading(false)
            setModal(true)
        }
        if(updateQualificationsError) {
            toast.error(updateQualificationsError, {
                autoClose: 2500,
            })
        }

    }, [updateQualificationsSuccess, updateQualificationsError])

    const handleQualificationData = async() => {
        setQualificationLoading(false)
        if( !qualifications?.length ) {
            toast.error(`Please fill the qualification`, {
                autoClose: 2500,
            })
            return;
        } else {
            // Validate each qualification
            for (const qualification of qualifications) {
    
                if (!qualification.college_name || !qualification.qualification_detail || qualification.qualification_detail === "Select an option" || !qualification.certificate_url) {
                    toast.error(`Please fill the qualification`, {
                        autoClose: 2500,
                    })
                    return;
                }
            }
        }

        if( qualificationErrorData?.length ) {
            qualificationErrorData?.map((item, index) => {
            if( item !== "" ) {
                return;
            }
            })
        }

        // Upload all images to S3 in parallel
        const uploadPromises = qualifications.map(async (qualification, index) => {
            if (qualification.certificate_url && typeof qualification.certificate_url === "object" ) {
            // Replace 'uploadFile' with your actual S3 upload function
            
                return await uploadFile(qualification.certificate_url);
            }
            return qualification.certificate_url;
        });
    
        const uploadedImages = await Promise.all(uploadPromises);
    
        // Now, 'uploadedImages' will contain the S3 URLs of the uploaded certificates.
        // You can use them to update the 'qualifications' array if needed.
    
        // For example, you can update 'qualifications' like this:
        const updatedQualifications = qualifications.map((qualification, index) => ({
            college_name: qualification.college_name.trim(),
            qualification_detail: qualification.qualification_detail,
            certificate_url: uploadedImages[index], // Replace with the S3 URL
        }));

        updateQualifications({
            url: "auth/register/add-qualification",
            method: 'POST',
            body: {
                "qualification": updatedQualifications,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`,
            }
        });
    }

    console.log("formDataBasicUser", formDataBasicUser);

    return (
        <>
            <ModalBox
                open={showViewerModal} 
                width={"598px"} 
                modalContent={
                    <Viewer
                    buttonText={"Okay"}
                    doc={process.env.REACT_APP_S3_CLOUD_FRONT_URL + basicDetail?.horoscope}
                    onClick={()=> {
                        addScroll()
                        setShowViewerModal(false)
                    }}
                    />
                }
            />
            <ModalBox
                open={showQualificationViewerModal} 
                width={"598px"} 
                modalContent={
                    <Viewer
                    buttonText={"Okay"}
                    doc={process.env.REACT_APP_S3_CLOUD_FRONT_URL + selectedQualificationPreview}
                    onClick={()=> {
                        addScroll()
                        setShowQualificationViewerModal(false)
                    }}
                    />
                }
            />
            <ModalBox
                open={showSetProfileModal} 
                width={"480px"} 
                modalContent={
                   <div className={style.setProfileContainer}>
                        <div className={style.closeIconContainer}>
                            <Image src={closeIcon} onClick={()=> {
                                addScroll()
                                setShowSetProfileModal(false)
                            }} className={style.closeIcon}/>
                        </div>
                        <p className={style.title}>Manage Photos</p>
                        <div className={style.profileImageContainer}>
                            <Image src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + selectedProfileImage?.content} className={style.profileImage}/>
                            <div className={style.markProfileContainer}>
                                <Image onClick={()=> {
                                    setProfileCheckBoxClicked(!profileCheckBoxClicked)
                                    handleSetProfile(!profileCheckBoxClicked)
                                }} src={ profileCheckBoxClicked ? orangeCheckbox : whiteCheckbox} className={style.checkboxImage}/>
                                <p className={style.text}>Mark as profile photo</p>
                            </div>
                        </div>
                   </div>
                }
            />
            <ToastContainer />
            {error ? (
                <div className={style.loaderContainer}>
                    <p className={style.error}>{error}</p>
                </div>
            ) : !isLoading ? (
                <div className={style.pageContentContainer} >
                    {
                        data?.is_onboard_completed ? (
                            <div className={style.edit_cont}>
                                <p className={style.title}>Edit My Profile</p>
                                <div className={style.show_detials_continer}>
                                    <div className={style.show_detials}>
                                        <div className={style.img}><Image src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${myAccount?.profile_image}`} /></div>
                                        <div className={style.contens}>
                                            <spa className={style.ornge_titel}>{myAccount?.user_id}</spa>
                                            <p className={style.title}>{myAccount?.name} <span className={style.suport_text}>{`(${myAccount?.age} year)`}</span></p>
                                            <p className={style.sup__title}>{`${myAccount?.height}| ${myAccount?.mother_tongue} ${ myAccount?.community !== null ? `| ${myAccount?.community}` : `` } | ${myAccount?.city}`}</p>
                                            <div className={style.text__container}>
                                                <p className={`${style.sup__img} ${style.sup__title}`}><Image src={profession} /> {`${professionalDetail?.occupation}`}</p>
                                                <p className={style.sup__title}>{`Earns ${myAccount?.annual_income}`}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className={style.edit_cont}>
                                    <p className={style.title}>Edit My Profile</p>
                                    <div className={style.show_detials_continer}>
                                        <div className={style.show_detials}>
                                            <div className={style.img}><Image src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${ data?.profile?.profile_image}`} /></div>
                                            <div className={style.contens}>
                                                <spa className={style.ornge_titel}>{myAccount?.user_id}</spa>
                                                <p className={style.title}>{myAccount?.name} <span className={style.suport_text}>{`(${myAccount?.age} years)`}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ContentBox title="Manage Photos" onClick={() => DetailsEdit("PhotosBasicUser")}>

                                    <div className={style.photos_controle}>
                                        {
                                            data?.image_detail_array?.length && (
                                                <div className={style.photos}><img src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${data?.image_detail_array[0]?.content}`} /></div>
                                            )
                                        }
                                    </div>
                                    <div className={style.eliteVideoInstructionContainer}>
                                        <Image src={notesIcon} className={style.notesIcon}/>
                                        <p className={style.instructionTitle}>Minimum validation time 24hrs.</p>
                                    </div>
                                </ContentBox>
                                <ContentBox title="Personal Information" onClick={() => DetailsEdit("PersonalInformationBasicUser")}>
                                    <div className={style.details}>
                                        <div className={style.details_box}>
                                            <Details title={"Name"} subtitle={myAccount?.name}/>
                                            <Details title={"Mobile Number"} subtitle={myAccount?.mobile_number}/>
                                            <Details title={"Email Address"} subtitle={myAccount?.email}  email_verified={myAccount?.email_verified} setRefreshApi={setRefreshApi} refreshApi={refreshApi}/>
                                            
                                        </div>
                                        <div className={style.details_box}>
                                            <Details title={"Date Of Birth"} subtitle={myAccount?.date_of_birth}/>
                                            <Details title={"Gender"} subtitle={myAccount?.gender}/>
                                        </div>
                                        {/* <div className={style.details_box}>
                                            {basic_details2.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} email_verified={basicDetail?.email_verified} setRefreshApi={setRefreshApi} refreshApi={refreshApi} />
                                            ))}
                                        </div> */}
                                    </div>
                                </ContentBox>
                            </>
                        )

                    }
                    {
                        data?.is_onboard_completed && (
                            <>
                                <ContentBox title="Manage Photos" onClick={() => DetailsEdit("Photos")}>

                                    <div className={style.photos_controle}>
                                        {managePhoto !== undefined ? (
                                            managePhoto && managePhoto?.map((item, index) => {

                                                // const fileExtension = item.split('.').pop().toLowerCase();
                                                // if (fileExtension === 'mp4' || fileExtension === 'avi') {
                                                //     return <div className={style.photos}> <video key={index} controls>
                                                //         <source src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${item}`} type={`video/${fileExtension}`} />
                                                //     </video> </div>;
                                                // } else {
                                                //     return <div className={style.photos}><img src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${item}`} key={index} alt={`Image ${index}`} /></div>;
                                                // }
                                                return <div onClick={()=> {
                                                    avoidScroll()
                                                    setProfileCheckBoxClicked(false)
                                                    setSelectedProfileImage(item)
                                                    setShowSetProfileModal(true)
                                                }} className={style.photos}><img src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${item?.content}`} key={index} alt={`Image ${index}`} /></div>;
                                            })
                                        ) : (
                                            <p>No images or videos available</p>
                                        )}
                                    </div>
                                    <div className={style.eliteVideoInstructionContainer}>
                                        <Image src={notesIcon} className={style.notesIcon}/>
                                        <p className={style.instructionTitle}>Minimum validation time 24hrs.</p>
                                    </div>
                                    </ContentBox>
                                    {
                                        getUserData()?.membership_type === "Elite" && (
                                            <ContentBox title="Manage Videos" onClick={() => DetailsEdit("Videos")}>
                                            <div className={style.uploadDataContainer}>
                                                <div className={style.form}>
                                                    {
                                                        uploadedVideoDisplay === "" ?
                                                            <div className={`${style.uploadImageIconContainer} ${style.uploadImageModalTitleContainer}`}>
                                                                <div className={style.videoUploadContainer}>
                                                                    <p className={style.text}>Capture your true self with a genuine smile and share your story through our self-video upload feature.</p>
                                                                    {/* <Button
                                                                        className={`primaryButton ${style.videoButton}`}
                                                                        onClick={() => {
                                                                            if (uploadVideoInput.current) {
                                                                            uploadVideoInput.current.click();
                                                                            }
                                                                        }}
                                                                    >
                                                                    Upload Video
                                                                    </Button> */}
                                                                </div>
                                                                {/* {
                                                                getUserData()?.membership_type === "Elite" ? */}
                                                                    <div className={style.eliteVideoInstructionContainer}>
                                                                    <Image src={notesIcon} className={style.notesIcon}/>
                                                                    <p className={style.instructionTitle}>Craft a compelling, desire-filled video under 3 minutes of maximum length</p>
                                                                    </div>
                                                                {/* :
                                                                    <></>
                                                                } */}
                                                            </div>
                                                        :
                                                            uploadedVideoDisplay && (
                                                                <div className={style.displayManageVideos}> 
                                                                    <video controls={0}>
                                                                    <source src={`${process.env.REACT_APP_S3_CLOUD_FRONT_URL}${uploadedVideoDisplay}#t=0.1`}/>
                                                                    </video> 
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                            </ContentBox>
                                        )
                                    }
                                    <ContentBox title="Manage Horoscope" onClick={() => DetailsEdit("horoscope")}>
                                    <div className={style.photos_controle}>
                                        <label htmlFor="certificateUpload" style={{ gap: selectedCertificate ? '12px' : '' }} className={style.uploadButton}>
                                            {
                                                basicDetail?.horoscope ? (
                                                    <>
                                                        {
                                                            checkPdf(selectedCertificatePreview) ?
                                                                <div className={style.imageViewContainer}>
                                                                    <Image onClick={() => {
                                                                        // avoidScroll()
                                                                        // setShowRejectReasonModal(true)
                                                                    }} src={pdfIcon} width={"70px"} height={"50px"} />
                                                                    <p onClick={()=> {
                                                                        avoidScroll()
                                                                        setShowViewerModal(true)
                                                                    }} className={style.viewLabel}>View</p>
                                                                </div>
                                                                :
                                                                <div className={style.imageViewContainer}>
                                                                    <Image src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + basicDetail?.horoscope} width="70px" height="50px" />
                                                                    <p onClick={()=> {
                                                                        avoidScroll()
                                                                        setShowViewerModal(true)
                                                                    }} className={style.viewLabel}>View</p>
                                                                </div>
                                                        }
                                                    </>
                                                ) : (
                                                    <p>No data found.</p>
                                                )}
                                        </label>
                                    </div>
                                    </ContentBox>
                                    <ContentBox title="Personal Information" onClick={() => DetailsEdit("PersonalInformation")}>
                                    {personalDetail}
                                    </ContentBox>
                                    <ContentBox title="Basic Details" onClick={() => DetailsEdit("BasicDetails")}>
                                    <div className={style.details}>
                                        <div className={style.details_box}>
                                            {basic_details1.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {basic_details2.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} email_verified={basicDetail?.email_verified} setRefreshApi={setRefreshApi} refreshApi={refreshApi} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {basic_details3.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                    </ContentBox>
                                    <ContentBox title="Qualification Details" onClick={() => DetailsEdit("QualificationDetails")}>
                                        <div className={style.qualificationDetailsContainer}>
                                            <div className={style.qualification}>
                                            {data?.qualification_details?.map((item, index)=> (
                                                <div className={style.details_box}>
                                                     <Details title={item.college_name} subtitle={item.qualification_name} key={index} />
                                                     <div className={style.photos_controle}>
                                                        <label htmlFor="certificateUpload" style={{ gap: selectedCertificate ? '12px' : '' }} className={style.uploadButton}>
                                                            {
                                                                item?.certificate_url && (
                                                                    <>
                                                                        {
                                                                            checkPdf( typeof item?.certificate_url === "object" ? item?.certificate_url.name : item?.certificate_url ) ?
                                                                                <div className={style.imageViewContainer}>
                                                                                    <Image onClick={() => {
                                                                                        // avoidScroll()
                                                                                        // setShowRejectReasonModal(true)
                                                                                    }} src={pdfIcon} width={"70px"} height={"50px"} />
                                                                                    <p onClick={()=> {
                                                                                        avoidScroll()
                                                                                        setSelectedQualificationPreview(item?.certificate_url)
                                                                                        setShowQualificationViewerModal(true)
                                                                                    }} className={style.viewLabel}>View</p>
                                                                                </div>
                                                                                :
                                                                                <div className={style.imageViewContainer}>
                                                                                    <Image src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item?.certificate_url} width="70px" height="50px" />
                                                                                    <p onClick={()=> {
                                                                                        avoidScroll()
                                                                                        setSelectedQualificationPreview(item?.certificate_url)
                                                                                        setShowQualificationViewerModal(true)
                                                                                    }} className={style.viewLabel}>View</p>
                                                                                </div>
                                                                        }
                                                                    </>
                                                            )}
                                                        </label>
                                                    </div>
                                                </div>
                                            ))}
                                            </div>
                                        </div>
                                    </ContentBox>
                                    <ContentBox title="Professional Details" onClick={() => DetailsEdit("ProfessionalDetails")}>
                                    <div className={style.details}>
                                        <div className={style.details_box}>
                                            {professionalDetails1.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {professionalDetails2.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {professionalDetails3.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                    </ContentBox>
                                    <ContentBox title="Family Details" onClick={() => DetailsEdit("FamilyDetails")}>
                                    <div className={style.details}>
                                        <div className={style.details_box}>
                                            {familyDetails1.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {familyDetails2.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {familyDetails3.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                    </ContentBox>
                                    <ContentBox title="Habits Details" onClick={() => DetailsEdit("HabitsDetails")}>
                                    <div className={style.details}>
                                        <div className={style.details_box}>
                                            {habitsDetails1.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {habitsDetails2.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {habitsDetails3.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                    </ContentBox>
                                    <ContentBox title="Hobbies & Interests" onClick={() => DetailsEdit("Interests")}>
                                    <div className={style.hobbiesContainer}>
                                        {hobbiesDetail?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`${style.hobby}`}
                                            >
                                                <p>{item.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                    </ContentBox>
                                    <ContentBox title="Work Location" onClick={() => DetailsEdit("WorkLocation")}>

                                    <div className={style.details}>
                                        <div className={style.details_box}>
                                            {work_location1.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {work_location2.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                        <div className={style.details_box}>
                                            {work_location3.map((item, index) => (
                                                <Details title={item.title} subtitle={item.sub_title} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                    </ContentBox>
                                    <ContentBox title="Medical History" onClick={() => DetailsEdit("MedicalHistory")}>
                                    <div className={style.container_history}>
                                        {medicalHistory?.map((item, index) => (
                                            <div
                                                key={index}
                                                className={`${style.container_history_id}`}

                                            >
                                                <p className={style.details_title}>{item.question}</p>
                                                <p className={style.details_sub_title}>{item.answer}</p>

                                            </div>
                                        ))}
                                    </div>
                                    </ContentBox>
                            </>
                        )
                    }
                </div>) : (
                <div className={style.loaderContainer}>
                    <Circles
                        height="35"
                        width="35"
                        color="rgb(239, 116, 53)"
                        ariaLabel="circles-loading"
                        wrapperStyle={{}}
                        wrapperClass={style.loader}
                        visible={true}
                    />
                </div>
            )}


            <ModalBox
                open={viewDetailsModal}
                width={
                    showPopPup.photos.open ? "1000px" :
                    showPopPup.videos.open ? "500px" :
                        showPopPup.horoscope.open ? "700px" :
                            showPopPup.personalInformation.open ? "600px" :
                            showPopPup.personalInformationBasicUser.open ? "600px" :
                                showPopPup.professionalDetails.open ? "600px" :
                                    showPopPup.familyDetails.open ? "700px" :
                                        showPopPup.habitsDetails.open ? "600px" :
                                            showPopPup.interests.open ? "1000px" :
                                                showPopPup.workloction.open ? "500px" :
                                                    "1172px"
                }
                modalContent={
                    <>

                        {showPopPup.personalInformationBasicUser.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Basic Details</p>
                                    <div className={style.inputs}>
                                        <PopoutInput
                                            label="Email Address"
                                            name="emailAddress"
                                            value={formDataBasicUser.emailAddress}
                                            onChange={handleInputChangeBasicUser}
                                            style={{
                                                paddingBottom: '26px',
                                            }}
                                        />

                                    </div>
                                    {/* {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>} */}
                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} loading={basicDetailsisLoading} onClick={basicUserDetailsSubmit}> Save Changes</Button>
                                </div>
                            </>
                        }

                        {showPopPup.photos.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Manage Photos</p>
                                    <div className={style.mangePhoto}>
                                        <div className={style.uploadContainer}>
                                            {uploadElements}
                                        </div>
                                    </div>
                                        <div className={style.eliteVideoInstructionContainerPhotos}>
                                            <Image src={notesIcon} className={style.notesIcon}/>
                                            <p className={style.instructionTitle}>Upload JPG/PNG Format only ( Max size 6MB )</p>
                                        </div>

                                </div>
                                {imageUploadError !== "" && (<div className={`error ${style.errorText}`}>{imageUploadError}</div>)}
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={() => {
                                        setUploadedImages(managePhoto)
                                        setImageFiles(managePhoto)
                                        Cancel()
                                    }}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={sendImgeandVedioFile} loading={imageisLoading}> Save Changes</Button>
                                </div>
                            </>
                        }

                        {showPopPup.qualificationDetailsModal.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Manage Qualification</p>
                                    <div className={style.qualificationContainer}>
                                        {qualifications?.map((qualification, index) => (
                                            <>
                                            <div key={index} className={style.formHeader}>
                                                <p className={style.titleText}>Qualification {index + 1}</p>
                                                {index > 0 && (
                                                    <p className={style.remove} onClick={() => removeQualification(index)}>
                                                    Remove
                                                    </p>
                                                )}
                                            </div>
                                            <div className={style.inputFieldContainer}>
                                                <PopoutInput
                                                    label="College Name"
                                                    name="college_name"
                                                    value={qualification.college_name}
                                                    onChange={(e) => handleNameChange(e, index)}
                                                    style={{
                                                        maxWidth: "342px",
                                                        width: "100%",
                                                        paddingBottom: '26px',
                                                    }}
                                                />
                                                {
                                                qualificationData && 
                                                    <FloatingLabelDropdown
                                                        label="Qualification Details"
                                                        name="qualification_detail"
                                                        responseType="token"
                                                        options={qualificationData}
                                                        value={qualification.qualification_detail}
                                                        onChange={(e) => handleNameChange(e, index)}
                                                        style={{
                                                            maxWidth: "342px",
                                                            width: "100%",
                                                            paddingBottom: '26px',
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <div className={style.fileUploadContainer}>
                                            <p className={style.fileTitle}>Certificate</p>
                                            <p className={style.fileDescription}>PDF, PNG, JPG with a Max file size of 6MB</p>
                                            <label htmlFor={`certificateUpload-${index}`} style={{ gap: imagePreviews[index] ? "12px" : "" }} className={style.uploadButton}>
                                                {imagePreviews[index] ? (
                                                    <>
                                                        {( ( typeof qualifications[index].certificate_url === "object" && qualifications[index].certificate_url?.type === "application/pdf" ) || ( typeof qualifications[index].certificate_url !== "object" && qualifications[index].certificate_url?.includes(".pdf") ) )  ?
                                                            <Image onClick={()=> {
                                                            // avoidScroll()
                                                            // setShowRejectReasonModal(true)
                                                            }} src={pdfIcon} width={"70px"} height={"50px"}/>
                                                        :
                                                            <Image src={  ( imagePreviews[index]?.includes("blob:") || imagePreviews[index]?.includes("data:image") ) ?  imagePreviews[index] : process.env.REACT_APP_S3_CLOUD_FRONT_URL+ imagePreviews[index]} width={"70px"} height={"50px"}/>
                                                        }
                                                    </>
                                                ) : (
                                                <Image src={uploadIcon} />
                                                )}
                                                <p className={style.uploadButtonsTitle}>{ imagePreviews[index] ? "Edit" : "Upload Certificate" }</p>
                                            </label>
                                            <input
                                                type="file"
                                                id={`certificateUpload-${index}`}
                                                accept="application/pdf,image/png,image/jpeg"
                                                onChange={(e) => handleQualificationUpload(e, index)}
                                                style={{ display: 'none' }}
                                            />
                                            <p className={`${style.errorMessage} error`}>{qualificationErrorData[index]}</p>
                                            </div>
                                            </>
                                        ))}
                                    {
                                        qualifications?.length < 10 && (
                                        <div className={style.cloneButton} onClick={addQualification}>
                                            <p className={style.cloneButtonTitle}>+ Add Qualification</p>
                                        </div>
                                        )
                                    }
                                    {/* {submitError && <p className={`${style.errorMessage} error`}>{submitError}</p>} */}
                                </div>

                                </div>
                                {imageUploadError !== "" && (<div className={`error ${style.errorText}`}>{imageUploadError}</div>)}
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={() => {
                                        Cancel()
                                    }}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={handleQualificationData} loading={qualificationLoading}> Save Changes</Button>
                                </div>
                            </>
                        }

                        {showPopPup.photosBasicUser.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Manage Photos</p>
                                    <div className={style.mangePhoto}>
                                        <div className={style.uploadContainer}>
                                            {uploadElementsBasicUser}
                                        </div>
                                    </div>
                                        <div className={style.eliteVideoInstructionContainerPhotos}>
                                            <Image src={notesIcon} className={style.notesIcon}/>
                                            <p className={style.instructionTitle}>Upload JPG/PNG Format only ( Max size 6MB )</p>
                                        </div>

                                </div>
                                {imageUploadError !== "" && (<div className={`error ${style.errorText}`}>{imageUploadError}</div>)}
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={() => {
                                        setUploadedImages(managePhoto)
                                        setImageFiles(managePhoto)
                                        Cancel()
                                    }}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={sendImgeandVedioFile} loading={imageisLoading}> Save Changes</Button>
                                </div>
                            </>
                        }

                        {showPopPup.videos.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Manage Video</p>
                                    <div>
                                        <div className={style.uploadImageIconContainer}>
                                            { uploadPersonalVideo ? (
                                                <div
                                                    className={`${style.uploadedVideo} ${style.videoThumbnailContainer}`}
                                                    // style={{
                                                    // backgroundImage: `url(${videoThumbnail})`,
                                                    // }}
                                                >
                                                    <video width={200} controls={0}>
                                                        <source src={`${ uploadPersonalVideo?.includes("blob:") ? uploadPersonalVideo : process.env.REACT_APP_S3_CLOUD_FRONT_URL+uploadPersonalVideo }`+"#t=0.1"}/>
                                                    </video>
                                                    {
                                                        editMyVideoStatus !== "Approved" && (
                                                            <div className={style.deleteContainer}>
                                                                <Image src={deleteIcon} onClick={() => {
                                                                    setUploadPersonalVideo("")
                                                                    setUploadedVideo(null)
                                                                }} />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        editMyVideoStatus !== "Approved" && (
                                                            <div
                                                                className={style.reuploadVideoContainer}
                                                                // onClick={() => uploadVideoInput.current.click()}
                                                            >
                                                                <p className={style.reuploadLabeTextVideo}> { editMyVideoStatus === "Pending" ? "Waiting for verification" : editMyVideoStatus } </p>
                                                            </div>
                                                    )}
                                                </div>
                                            ) : (
                                            <>
                                                <div className={style.videoUploadContainer}>
                                                    <p className={style.text}>Capture your true self with a genuine smile and share your story through our self-video upload feature.</p>
                                                    <Button
                                                        className={`primaryButton ${style.videoButton}`}
                                                        onClick={() => {
                                                        // if( getUserData()?.membership_type === "Elite" ) {
                                                            if (uploadVideoInput.current) {
                                                            uploadVideoInput.current.click();
                                                            }
                                                        // } else {
                                                        //     navigation("/subscribe")
                                                        // }
                                                        }}
                                                    >
                                                        {/* { getUserData()?.membership_type === "Elite" ? "Upload Video" : "Subscribe"  }  */}
                                                        Upload Video
                                                    </Button>
                                                </div>
                                                {/* {
                                                getUserData()?.membership_type === "Elite" ? */}
                                                    <div className={style.eliteVideoInstructionContainer}>
                                                    <Image src={notesIcon} className={style.notesIcon}/>
                                                    <p className={style.instructionTitle}>Craft a compelling, desire-filled video under 3 minutes of maximum length</p>
                                                    </div>
                                                {/* :
                                                    <></>
                                                } */}
                                            </>
                                            )}
                                        </div>
                                        <input
                                        type="file"
                                        accept="video/*"
                                        onChange={handleVideoUpload}
                                        className={style.uploadInput}
                                        ref={(el) => (uploadVideoInput.current = el)} // Assign the ref to the input element
                                        onClick={(e)=> { 
                                          e.target.value = null
                                        }}
                                        />
                                    </div>
                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={() => {
                                        Cancel()
                                    }}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={handleVideoSubmit} loading={videoIsLoading}> Save Changes</Button>
                                </div>
                            </>
                        }

                        {showPopPup?.horoscope?.open &&
                            <div className={style.basicDetails_cont}>
                                <p className={style.header_titele}>Horoscope Information</p>
                                <label htmlFor="certificateUpload" style={{ gap: selectedCertificate ? '12px' : '' }} className={style.uploadButton1}>
                                    {
                                        selectedCertificatePreview && (
                                            <>
                                                {
                                                    selectedCertificatePreview.includes("blob:") ?
                                                        selectedCertificate?.type === "application/pdf" ?
                                                            <Image onClick={() => {
                                                                // avoidScroll()
                                                                // setShowRejectReasonModal(true)
                                                            }} src={pdfIcon} width={"70px"} height={"50px"} />
                                                            :
                                                            <Image src={selectedCertificatePreview} width="70px" height="50px" />
                                                        :
                                                        checkPdf(selectedCertificatePreview) ?
                                                            <Image onClick={() => {
                                                                // avoidScroll()
                                                                // setShowRejectReasonModal(true)
                                                            }} src={pdfIcon} width={"70px"} height={"50px"} />
                                                            :
                                                            <Image src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + selectedCertificatePreview} width="70px" height="50px" />
                                                }
                                            </>
                                        )}
                                    <div className={style.uploadLabelTextContainer}>
                                        {
                                            !selectedCertificatePreview && (
                                                <>
                                                    <Image src={uploadIcon} />
                                                    <p className={style.fieldTitleLabel}>PDF, PNG, JPG with a Max file size of 6MB</p>
                                                </>
                                            )
                                        }
                                        <p className={style.uploadButtonTitleEdit}> { !selectedCertificatePreview ? "Upload" : "Edit" }</p>
                                    </div>
                                </label>
                                <input
                                    type="file"
                                    id="certificateUpload"
                                    accept="application/pdf,image/png,image/jpeg"
                                    onChange={handleCertificateUpload}
                                    style={{ display: 'none' }}
                                />
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={() => {
                                        setSelectedCertificatePreview(basicDetail?.horoscope)
                                        setSelectedCertificate("")
                                        Cancel()
                                    }}>Cancel</Button>
                                    {
                                        selectedCertificatePreview && (
                                            <Button className={`primaryButton ${style.submitButton}`} onClick={horoscopeSubmit} loading={personalInformatisLoading}> Save Changes</Button>
                                        )
                                    }
                                </div>
                            </div>
                        }

                        {showPopPup.personalInformation.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Personal Information</p>
                                    <PopoutTextarea
                                        label="Personal Information"
                                        name="personalinformation"
                                        className={style.moreAbout}
                                        value={personalInformation}
                                        characterLimit={255}
                                        onChange={personalInformationChanges}
                                    />
                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={personalInformationsubmit} loading={personalInformatisLoading}> Save Changes</Button>
                                </div>
                            </>
                        }

                        {showPopPup.basicDetails.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Basic Details</p>
                                    <div className={style.inputs}>
                                        <FloatingDatePicker
                                            isOpen={isOpen}
                                            setIsOpen={setIsOpen}
                                            selectedDate={formData.dob}
                                            onChange={(e) => setFormData({ ...formData, dob: e })}
                                            label={"DOB"}
                                            customStyle={{

                                                width: '100%',
                                                minWidth: '150px',

                                            }}
                                        />

                                        <FloatingLabelDropdown
                                            label="Height"
                                            name="height"
                                            responseType="token"
                                            options={heightData} // Add options
                                            value={formData.height}
                                            onChange={handleInputChange}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />

                                        <FloatingLabelDropdown
                                            label="Weight"
                                            name="weight"
                                            responseType="token"
                                            options={weightData} // Add options
                                            value={formData.weight}
                                            onChange={handleInputChange}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        <FloatingLabelDropdown
                                            label="Country"
                                            name="country"
                                            responseType="id"
                                            options={countryData}
                                            value={nativeCountry.token}
                                            onChange={(e, value) => {
                                                setNativeCountry({ ...nativeCountry, token: e.target.value, value: value })
                                                setNativeState({
                                                    token: "",
                                                    value: ""
                                                })
                                                setNativeCity({
                                                    token: "",
                                                    value: ""
                                                })
                                            }}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        <FloatingLabelDropdown
                                            label="State"
                                            name="state"
                                            responseType="id"
                                            options={nativeStateData} // Use native state data here
                                            value={nativeStateData.length > 0 ? nativeState.token : null}
                                            onChange={(e, value) => {
                                                setNativeState({ ...nativeState, token: e.target.value, value: value })
                                                setNativeCity({
                                                    token: "",
                                                    value: ""
                                                })
                                            }}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />
                                    </div>
                                    <div className={style.inputs}>
                                        <FloatingLabelDropdown
                                            label="District/City"
                                            name="district_city"
                                            responseType="id"
                                            options={nativeCityData} // Use native city data here
                                            value={nativeCityData.length > 0 ? nativeCity.token : null}
                                            onChange={(e, value) => setNativeCity({ ...nativeCity, token: e.target.value, value: value })}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />

                                        <FloatingLabelDropdown
                                            label="Citizenship"
                                            name="citizenship"
                                            responseType="token"
                                            options={citizenshipData} // Add options
                                            value={formData.citizenship}
                                            onChange={handleInputChange}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />

                                        <FloatingLabelDropdown
                                            label="Religion"
                                            name="religion"
                                            responseType="token"
                                            options={religion}
                                            value={formData.religion}
                                            onChange={handleInputChange}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        <FloatingLabelDropdown
                                            label="Community"
                                            name="community"
                                            responseType="token"
                                            options={communityData}
                                            value={community}
                                            onChange={(e) => setCommunity(e.target.value)}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />


                                    </div>
                                    <div className={style.inputs}>
                                        <FloatingLabelDropdown
                                            label="Gothram"
                                            name="gothram"
                                            responseType="token"
                                            options={gothram}
                                            value={formData.gothram}
                                            onChange={handleInputChange}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />

                                        <FloatingLabelDropdown
                                            label="Star"
                                            name="star"
                                            responseType="token"
                                            options={star}
                                            value={formData.star}
                                            onChange={handleInputChange}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />

                                        <FloatingLabelDropdown
                                            label="Raasi"
                                            name="raasi"
                                            responseType="token"
                                            options={raasi}
                                            value={formData.raasi}
                                            onChange={handleInputChange}
                                            style={{

                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        <FloatingLabelDropdown
                                            label="MotherTongue"
                                            name="motherTongue"
                                            responseType="token"
                                            options={motherTongueData} // Add options
                                            value={formData.motherTongue}
                                            onChange={handleInputChange}
                                            style={{
                                                minWidth: '150px',
                                                width: '100%',
                                            }}
                                        />
                                        <PopoutInput
                                            label="Email Address"
                                            name="emailAddress"
                                            value={formData.emailAddress}
                                            onChange={handleInputChange}
                                            style={{
                                                paddingBottom: '26px',
                                            }}
                                        />

                                    </div>
                                    <div className={style.rodio_btn}>
                                        <div className={`${style.field} ${style.marital}`}>
                                            <p className={style.fieldTitle}>Chevvai Dosham</p>
                                            <div className={style.fieldValue}>
                                                {renderRadioButtons('ChevvaiDhosham', [
                                                    { label: 'No', value: 'No' },
                                                    { label: 'Yes', value: 'Yes' },
                                                    { label: 'Don’t Know', value: 'Don’t Know' },

                                                ])}
                                            </div>
                                        </div>

                                        <div className={style.field}>
                                            <p className={style.fieldTitle}>Physical Status</p>
                                            <div className={style.fieldValue}>
                                                {renderRadioButtons('physicalStatus', physicalStatusData)}
                                            </div>
                                        </div>

                                        <div className={`${style.field} ${style.marital}`}>
                                            <p className={style.fieldTitle}>Marital Status</p>
                                            <div className={style.fieldValue}>
                                                {renderRadioButtons('maritalStatus', maritalStatusData)}
                                            </div>
                                        </div>


                                    </div>
                                    {/* {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>} */}
                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} loading={basicDetailsisLoading} onClick={basicDetailsSmbite}> Save Changes</Button>
                                </div>
                            </>
                        }

                        {showPopPup.professionalDetails.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Professional Details</p>

                                    <div className={style.fieldContainer}>
                                        <PopoutInput
                                            label="Institution Name"
                                            name="institution_name"
                                            value={institutionName}
                                            onChange={(e) => setInstitutionName(e.target.value)}
                                        />
                                        <FloatingLabelDropdown
                                            label="Employed in"
                                            name="employed in"
                                            responseType="token"
                                            options={employedInData}
                                            value={employedIn}
                                            onChange={(e) => setEmployedIn(e.target.value)}
                                        />

                                    </div>
                                    <div className={style.fieldContainer}>
                                        <FloatingLabelDropdown
                                            label="Occupation"
                                            name="occupation"
                                            responseType="token"
                                            options={occupationData}
                                            value={occupation}
                                            onChange={(e) => setOccupation(e.target.value)}
                                            style={{
                                                marginTop: '30px',
                                            }}
                                        />
                                        <FloatingLabelDropdown
                                            label="Annual Income"
                                            name="annual_income"
                                            responseType="token"
                                            options={annualIncomeData} // Use annual income data here
                                            value={annualIncome}
                                            onChange={(e) => setAnnualIncome(e.target.value)}

                                        />
                                    </div>
                                    {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={professionalDetailsSubmit} loading={imageisLoading}> Save Changes</Button>
                                </div>
                            </>
                        }
                        {showPopPup.familyDetails.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Family Details</p>
                                    <div className={style.mangePhoto}>
                                        <FamilyDetails familyDetailsError={familyDetailsError} Cancel={Cancel} data={familyDetail} setViewDetailsModal={setViewDetailsModal} refresh={refresh} />
                                    </div>

                                </div>

                            </>
                        }
                        {showPopPup.habitsDetails.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Habits Details</p>

                                    <div className={style.fieldContainer}>
                                        <div className={style.field}>
                                            <p className={style.fieldTitle}>Eating Habits</p>
                                            <div className={style.fieldValue}>
                                                <div className={style.answers}>
                                                    {eatingHabitMeta.map((item, index) => (
                                                        <div className={style.answer} onClick={() => setEatingHabits(item.value)} key={index}>
                                                            <Image src={eatingHabits === item.value ? radioSelected : radioUnselected} />
                                                            <p>{item.name}</p>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.field}>
                                            <p className={style.fieldTitle}>Drinking Habits</p>
                                            <div className={style.fieldValue}>
                                                <div className={style.answers}>

                                                    {drinkingMeta.map((item, index) => (
                                                        <div className={style.answer} onClick={() => setDrinkingHabits(item.value)} key={index}>
                                                            <Image src={drinkingHabits === item.value ? radioSelected : radioUnselected} />
                                                            <p>{item.name}</p>
                                                        </div>
                                                    ))}

                                                    {/* <div className={style.answer} onClick={() => setDrinkingHabits('Yes')}>
                                                        <Image src={drinkingHabits === 'Yes' ? radioSelected : radioUnselected} />
                                                        <p>Yes</p>
                                                    </div> */}
                                                    {/* <div className={style.answer} onClick={() => setDrinkingHabits('Doesn’t Matter')}>
                                                        <Image src={drinkingHabits === 'Doesn’t Matter' ? radioSelected : radioUnselected} />
                                                        <p>Doesn’t Matter</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={style.field}>
                                            <p className={style.fieldTitle}>Smoking Habits</p>
                                            <div className={style.fieldValue}>
                                                <div className={style.answers}>

                                                    {smokingHabitMeta.map((item, index) => (
                                                        <div className={style.answer} onClick={() => setSmokingHabits(item.value)} key={index}>
                                                            <Image src={smokingHabits === item.value ? radioSelected : radioUnselected} />
                                                            <p>{item.name}</p>
                                                        </div>
                                                    ))}

                                                    {/* <div className={style.answer} onClick={() => setSmokingHabits('No')}>
                                                        <Image src={smokingHabits === 'No' ? radioSelected : radioUnselected} />
                                                        <p>No</p>
                                                    </div>
                                                    <div className={style.answer} onClick={() => setSmokingHabits('Yes')}>
                                                        <Image src={smokingHabits === 'Yes' ? radioSelected : radioUnselected} />
                                                        <p>Yes</p>
                                                    </div> */}
                                                    {/* <div className={style.answer} onClick={() => setSmokingHabits('Doesn’t Matter')}>
                                                        <Image src={smokingHabits === 'Doesn’t Matter' ? radioSelected : radioUnselected} />
                                                        <p>Doesn’t Matter</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}

                                </div>

                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={HabitsSmbite} loading={habitsisLoading}> Save Changes</Button>
                                </div>
                            </>
                        }
                        {showPopPup.interests.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Hobbies & Interests</p>
                                    <div className={style.hobbiesContainer}>
                                        {hobbiesData?.map((item) => (
                                            <div
                                                key={item.token}
                                                className={`${style.hobby} ${selectedHobbies.includes(item.token) ? style.hobbyActive : ''
                                                    }`}
                                                onClick={() => toggleHobby(item.token)}
                                            >
                                                <p>{item.name}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={handleHobbiesInterests}> Save Changes</Button>
                                </div>
                            </>


                        }
                        {showPopPup.workloction.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Work Loction</p>
                                    <div className={style.work_loction}>
                                        <FloatingLabelDropdown
                                            label="Country"
                                            name="country"
                                            responseType="id"
                                            options={countryData}
                                            value={workCountry.token}
                                            onChange={(e, value) => {
                                                setWorkCountry({ ...workCountry, token: e.target.value, value: value })
                                                setWorkState({
                                                    token: "",
                                                    value: ""
                                                })
                                            }}
                                        />
                                        <FloatingLabelDropdown
                                            label="State"
                                            name="state"
                                            responseType="id"
                                            options={workStateData} // Use work state data here
                                            value={workState.token}
                                            onChange={(e, value) => {
                                                setWorkState({ ...workState, token: e.target.value, value: value })
                                                setWorkCity({
                                                    token: "",
                                                    value: ""
                                                })
                                            }}
                                            style={{
                                                marginTop: '30px',
                                            }}
                                        />

                                        <FloatingLabelDropdown
                                            label="District/City"
                                            name="district_city"
                                            responseType="id"
                                            options={workCityData} // Use work city data here
                                            value={workCity.token}
                                            onChange={(e, value) => setWorkCity({ ...workCity, token: e.target.value, value: value })}
                                            style={{
                                                marginTop: '30px',
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className={style.btn_group}>
                                    <Button className={`primaryButton ${style.cancel}`} onClick={Cancel}>Cancel</Button>
                                    <Button className={`primaryButton ${style.submitButton}`} onClick={handleWorkLoction}> Save Changes</Button>
                                </div>
                            </>


                        }
                        {showPopPup.medicalhistory.open &&
                            <>
                                <div className={style.basicDetails_cont}>
                                    <p className={style.header_titele}>Medical History</p>
                                    <div className={style.medical}>
                                        <MedicalConditions Cancel={Cancel} data={medicalHistory} setViewDetailsModal={setViewDetailsModal} refresh={refreshMedData} />
                                    </div>
                                </div>

                            </>


                        }

                    </>
                } />

            <ModalBox
                open={modal}
                width={"598px"}
                modalContent={
                    <Success
                        onClick={() => {
                            setModal(false)
                            setViewDetailsModal(false)
                            getMyprofileApi()
                        }}
                    />
                }
            />

        </>
    )
}

export default EditeMyProfile

export function ContentBox({ title, children, onClick, removeEdit = false }) {
    return (
        <div className={style.edit_cont}>
            <div className={style.titel_header}>
                <p className={style.title}>{title}</p>
                {
                    !removeEdit ?
                        <Image src={edit} onClick={onClick} className={style.edit_curuser_point} />
                    :
                        <></>
                }
            </div>
            <div className={style.box_shadow_container}>
                {children}
            </div>
        </div>
    );
}

export function Details({ title, subtitle, basicDetail, setRefreshApi, refreshApi, email_verified }) {

    const navigation = useNavigate()

    const { isLoading: isLoading, success: success, error: error, sendRequest: sendOtp } = useApiCall();

    const [viewOtpModal, setViewOtpModal] = useState(false)

    const [successModal, setSuccessModal] = useState(false)

    const handleSendOtp = () => {
        avoidScroll()
        setViewOtpModal(true)
    }

    const handleAcceptApi = () => {
        setSuccessModal(true)
    }

    return (
        <>
            <ModalBox
                open={viewOtpModal}
                width={"480px"}
                modalContent={
                    <VerifyOtp
                        handleAcceptApi={handleAcceptApi}
                        email={subtitle}
                        setViewOtpModal={setViewOtpModal}
                    />
                }
            />

            <ModalBox
                open={successModal}
                width={"598px"}
                modalContent={
                    <Success
                        onClick={() => {
                            navigation(0);
                        }}
                    />
                }
            />
            <div className={style.details_text}>
                <p className={style.details_title}>{title}</p>
                <p className={style.details_sub_title}>{subtitle}
                    {
                        !email_verified && title === "Email Address" ? (
                            <div onClick={handleSendOtp} className={style.verifyEmailTextContainer}>
                                <Image src={warningIcon} />
                                <p className={style.verifyText}>Verify Email</p>
                            </div>
                        ) : (
                            title === "Email Address" && (
                                <div className={style.verifyEmailTextContainer}>
                                    <Image src={CheckOnIcon} />
                                    <p className={style.verifiedText}>Verified</p>
                                </div>
                            )
                        )
                    }</p>
            </div>
        </>
    );
}
