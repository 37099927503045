import React, { useEffect, useRef, useState } from "react"
import style from  "./AccountDeletion.module.css"
import Image from "../../../../ui/Image/Image"
import Button from "../../../../ui/Button/Button"
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import PopoutMobileInput from "../../../../ui/PopoutMobileInput/PopoutMobileInput"
import useApiCall from "../../hooks/useApiCall"
import { phoneCode } from "../../../../Data/data"
import timerIcon from "../../assets/common/Clock.svg"
import successIcon from "../../assets/svg/Premium/Purchase Completed.svg"
import PopoutTextarea from "../../../../ui/PopoutTextarea/PopoutTextarea"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"
import initializeFirebase from '../Chat/Db/FirebaseDb'; 
import { collection,doc,setDoc,getDoc,updateDoc,getDocs,query,where} from "firebase/firestore";

export const AccountDeletion = () => {

    const navigation = useNavigate();
    const {db} = initializeFirebase();
    const [ mobileNumber, setMobileNumber ] = useState("") 
    const [ moreAbout, setMoreAbout ] = useState("") 
    const [ status, setstatus ] = useState("form") 
    const [mobileNumberError, setMobileNumberError] = useState('');
    const [moreAboutError, setMoreAboutError] = useState('');
    const [selectedValue, setSelectedValue] = useState(`+${phoneCode[0].code}`); // State to store the selected value
    
    const [otp, setOtp] = useState(['', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [ loader, setLoader ] = useState(false)

    const inputRefs = [useRef(), useRef(), useRef(), useRef()];

    const [totalSeconds, setTotalSeconds] = useState(30); // 1 minute and 30 seconds

    useEffect(() => {
        const interval = setInterval(() => {
        if (totalSeconds > 0) {
            setTotalSeconds(totalSeconds - 1);
        } else {
            clearInterval(interval);
        }
        }, 1000);

        return () => {
        clearInterval(interval);
        };
    }, [totalSeconds]);

    const handleChange = (index, value) => {
      if (!isNaN(value) && value !== '') {
        otp[index] = value;
        setOtp([...otp]);
  
        if (index < 3 && value !== '') {
          inputRefs[index + 1].current.focus();
        }
        checkOtpValid() && setOtpError('');
      } else if (value === '' && index > 0) {
        otp[index] = '';
        setOtp([...otp]);
        inputRefs[index - 1].current.focus();
      } else if (value === '' && index === 0) {
        otp[index] = '';
        setOtp([...otp]);
      }
    };
  
    const handleKeyPress = (e, index) => {
      if (e.key === 'Backspace' && index > 0 && otp[index] === '') {
        inputRefs[index - 1].current.focus();
      }
    };

    const checkOtpValid = () => {
      return otp?.every((digit) => !isNaN(digit) && digit !== '');
    }

    const handleMobileNumberChange = (mobileValue) => {
      setMobileNumber(mobileValue);
    };

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setMoreAbout(value)
    };

    const { isLoading: sendOtpLoading, success: sendOtpSuccess, error: sendOtpError, sendRequest: sendOtp } = useApiCall();

    useEffect(()=> {
        if( sendOtpSuccess ) {
            setstatus("otp")
        }
        if( sendOtpError ) {
            toast.error(sendOtpError, {
                autoClose: 2500,
              })
        }
    }, [sendOtpSuccess, sendOtpError])

    const handleSendOtp = () => {
        let hasErrors = false;
            
        if (!mobileNumber?.trim()) {
            setMobileNumberError('Please enter your mobile number');
            hasErrors = true;
        } else {
            if( mobileNumber?.trim()?.length !== 10 ) {
            hasErrors = true;
            setMobileNumberError('Please enter valid mobile number');
            } else {
            setMobileNumberError('')
            }
        }

        if (hasErrors) {
            // Stop processing if there are errors
            return;
        }
        sendOtp({
            url: "my-account/delete/send-otp",
            method: 'POST',
            body: {
                "user_id" : mobileNumber
            },
        });
    }

    const { isLoading: resendOtpLoading, success: resendOtpSuccess, error: resendOtpError, sendRequest: resendOtp } = useApiCall();

    useEffect(() => {
        if (resendOtpSuccess) {
          setOtpError("")
          setLoader(false)
          setOtp(['', '', '', ''])
          setTotalSeconds(30); // Reset to 1 minute and 30 seconds
        }
        if (resendOtpError) {
          setLoader(false)
          toast.error(resendOtpError, {
            autoClose: 2500,
          })
        }
      }, [resendOtpSuccess, resendOtpError]);

    const resendOTP = async () => {
        resendOtp({
          url: "my-account/delete/resend-otp",
          method: 'POST',
          body: {
            "user_id" : mobileNumber
          },
        });
    };

    const { isLoading: verifyOtpLoading, success: verifyOtpSuccess, error: verifyOtpError, sendRequest: verifyOtp } = useApiCall();

    useEffect(()=> {
        if( verifyOtpSuccess ) {
            setstatus("reasonForm")
        }
        if( verifyOtpError ) {
            toast.error(verifyOtpError, {
                autoClose: 2500,
              })
        }
    }, [verifyOtpSuccess, verifyOtpError])

    const handleVerifyOtp = async () => {
        const otpValue = otp.join("")
        if (checkOtpValid()) {
            verifyOtp({
                url: "my-account/delete/verify-otp",
                method: 'POST',
                body: {
                    "user_id" : mobileNumber,
                    "otp": otpValue,
                },
            });
        }
    };

    const { isLoading: deleteProfileLoading, success: deleteProfileSuccess, error: deleteProfileError, sendRequest: deleteProfile } = useApiCall();


    useEffect(()=> {
        if( deleteProfileSuccess ) {
            
            setstatus("success")
        }
        if( deleteProfileError ) {
            toast.error(deleteProfileError, {
                autoClose: 2500,
            })
        }
    }, [deleteProfileSuccess, deleteProfileError])

    const handleOkay = () => {
        window.location.href = "/"
    }

    const handleDeleteProfile = () => {
        if( moreAbout ) {
            deleteProfile({
                url: "my-account/delete/my-profile",
                method: 'POST',
                body: {
                    "user_id": mobileNumber,
                    "reason": moreAbout
                },
            }, (response) => {
                const { user_token } = response?.data
                const checkUserQuery = doc(db, "Users", user_token);   
                getDoc(checkUserQuery).then((userData)=>{
                    if(userData.exists()){
                        updateDoc(checkUserQuery,{
                            isOnline:false,
                            isDeleted: true
                        }).then(()=>{
                            localStorage.removeItem("token");
                            localStorage.removeItem("subscriptionFromHome");
                            localStorage.removeItem("user");
                        })
                    }
                })
            });
        } else {
            setMoreAboutError("Please fill the delete reason")
        }
    }

    const handleChangeMobile = () => {
        setOtpError("")
        setLoader(false)
        setOtp(['', '', '', ''])
        setTotalSeconds(30); // Reset to 1 minute and 30 seconds
        setstatus("form")
    }

    return(
        <div className={style.accountDeletionContainer}>
            <ToastContainer />
            <div className={style.accountDeletionBgContainer}>
                <div className={style.contentContainer}>
                    <Image src={logoImage} width={410} height={100} className={style.logoImage}/>
                    <div className={`${style.content} ${  status !== "form" ? style.noPadding : ""}`}>
                        {
                            status !== "success" && (
                                <>
                                    <p className={style.title}>Delete Account</p>
                                    <p className={style.description}>The account will be no longer available and all data related to this account will be deleted permanently.</p>
                                </>
                            )
                        }
                        {
                            status === "form" && (
                                <>
                                    <p className={style.subtitle}>Enter Details</p>
                                    <p className={style.subtitleDescription}>Enter your mobile number</p>
                                    <PopoutMobileInput
                                        name={"mobile_number"}
                                        label={"Mobile Number"}
                                        value={mobileNumber}
                                        onChange={handleMobileNumberChange}
                                        selectedValue={selectedValue}
                                        setSelectedValue={setSelectedValue}
                                    />
                                    {mobileNumberError && <p className={"error"}>{mobileNumberError}</p>}
                                    <Button
                                        style={{ width: "100%", marginTop: '60px' }}
                                        className={`primaryButton`}
                                        onClick={handleSendOtp}
                                        loading={sendOtpLoading}
                                    >
                                        Get OTP
                                    </Button>
                                    <p className={style.footerDescription}>
                                        We will be sending you a One-Time Password (OTP) on your registered mobile number.
                                    </p>
                                </>
                            )
                        }
                        {
                            status === "otp" && (
                                <>
                                    <p className={style.subtitle}>Enter OTP</p>
                                    <p className={style.subtitleDescription}>We will be sending you a One Time-Password (OTP) on you registered mobile number.</p>
                                    <p className={style.description}>{mobileNumber} <span onClick={handleChangeMobile} className={style.changeNumber}>Change</span></p>
                                    <div className={style.otpErrorContainer}>
                                        <div className={style.otpInputContainer}>
                                        {otp.map((digit, index) => (
                                            <input
                                            key={index}
                                            type="text"
                                            placeholder='0'
                                            value={digit}
                                            onChange={(e) => handleChange(index, e.target.value)}
                                            onKeyPress={(e) => handleKeyPress(e, index)}
                                            maxLength={1}
                                            ref={inputRefs[index]}
                                            />
                                        ))}
                                        </div>
                                        {otpError && <p className={`${style.errorMessage} error`}>{otpError}</p>}
                                    </div>
                                    <div className={style.timerContainer}>
                                        {totalSeconds > 0 ? (
                                        <div className={style.timer}>
                                            <Image
                                            src={timerIcon}
                                            />
                                            <p className={style.resendText}>
                                            {totalSeconds < 10 ? `0${totalSeconds}` : totalSeconds} sec
                                            </p>
                                        </div>
                                        ) : (
                                        <p
                                            onClick={resendOTP}
                                            className={style.resendText}
                                        >
                                            Resend OTP
                                        </p>
                                        )}
                                    </div>
                                    <Button
                                        style={{ width: "100%", marginTop: '60px' }}
                                        className={`primaryButton`}
                                        onClick={handleVerifyOtp}
                                        loading={verifyOtpLoading}
                                    >
                                        Continue
                                    </Button>
                                </>
                            )
                        }
                        {
                            status === "reasonForm" && (
                                <>
                                    <p className={style.subtitle}>Delete Reason</p>
                                    <p className={style.subtitleDescription}>Can you please share with us what was not working? We are fixing bugs as soon as we spot them. If something slipped through our fingers, we’d be grateful to be aware of it…</p>
                                    <div className={style.otpErrorContainer}>
                                        <PopoutTextarea
                                            characterLimit={255}
                                            label="Delete Reason"
                                            name="moreAbout"
                                            className={style.moreAbout}
                                            value={moreAbout}
                                            onChange={handleInputChange}
                                        />
                                        {moreAboutError && <p className={`${style.errorMessage} error`}>{moreAboutError}</p>}
                                    </div>
                                    <Button
                                        style={{ width: "100%", marginTop: '60px' }}
                                        className={`primaryButton`}
                                        onClick={handleDeleteProfile}
                                        loading={deleteProfileLoading}
                                    >
                                        Delete My Account
                                    </Button>
                                </>
                            )
                        }
                        {
                            status === "success" && (
                                <>
                                    <Image src={successIcon} className={style.successIcon}/>
                                    <p className={style.subtitle}>Account Deleted Successfully</p>
                                    <p className={style.subtitleDescription}>Account Deleted Successfully! We're sorry to see you go. If you ever decide to return, we'll be here to welcome you back with open arms. Wishing you all the best on your journey!</p>
                                    
                                    <Button
                                        style={{ width: "100%", marginTop: '60px' }}
                                        className={`primaryButton`}
                                        onClick={handleOkay}
                                    >
                                        Okay
                                    </Button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}