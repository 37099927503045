import React, { useState } from 'react'
import Header from '../features/Header/Header'
import Sidebar from '../features/Sidebar/Sidebar'
import { Navigate, Outlet } from 'react-router-dom'
import './RootLayOut.css'
import '../../../globalUi/globaladminUi.css'
import { isAuthenticated } from '../hooks/Auth'
import { NumberProvider } from '../Context/CreatContext';

const Root = () => {
    const [isActives, setActives] = useState(false);

    if (!isAuthenticated()) {
        return <Navigate to="/admin" />
    }

    return (
        <NumberProvider>
            <div className="App body">
                <Sidebar isActives={isActives} setActives={setActives} onClick={() => setActives(!isActives)} />
                <section id="content">
                    <Header />
                    <main>
                        < Outlet />
                    </main>
                </section>

            </div>
        </NumberProvider>
    )
}

export default Root