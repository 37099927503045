import React from 'react'
const FromHeader = (props) => {
    return (
        <div className={props.className}>
            <h3>{props.titel} </h3>
            <p className={props.classe}>{props.subtitel}</p>
            <p className={props.classe}>{props.subcon}</p>
        </div>
    )
}

export default FromHeader