import React, { useEffect, useState } from "react"
import style from "./Faq.module.css"
import FaqSection from "../../features/LandingPage/FaqSection/FaqSection"

export const Faq = () => {
    

    return(
        <FaqSection />
    )
}