import React, { useEffect, useMemo, useState } from "react";
import style from "./ChatSidebarList.module.css"
import Image from "../../../../../ui/Image/Image"
import drImage from "../../../pages/Chat/assets/Dr. Vidhya@2x.png"
import { collection,doc,setDoc,getDoc,getDocs,Timestamp,onSnapshot,query,orderBy,where, Firestore, updateDoc } from "firebase/firestore"; 
import { borderRadius } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { formatTimeAgo } from "../../../../../utils/utils";
import maleAvatar from "../../../assets/common/profile_active.png"
import femaleAvatar from "../../../assets/common/female.svg"

const ChatSidebarList = (props) => {
    const navigation = useNavigate(null)
    const {db,det,clickHandle} = props;
    const [newMsgCount, setNewMsgCount] = useState(0);
    const [unreadMsgTime, setUnreadMsgTime] = useState("");
    const updateLastSeenQuery = doc(db, "Chats", det.doc_id);
    let newMsgListenerUnsub;
    let profile_image = det?.user_image !== undefined ? det?.user_image : '';
    let date = new Date();
    const docRef = doc(db, "Chats", `${det.doc_id}`);
    const ques = query(collection(db, `Chats/${det.doc_id}/message`), orderBy("msg_time", "asc"), where("msg_time", ">=", date));

    let onlineOfflineStatus = style.offline;
    let token = det?.participant_token;

    const onClickListHandler = () => {
        localStorage?.setItem("sidebarToChatToken", token)
        navigation("/chat")
    }


    const startOfWeek = (date) => {
        const firstDayOfWeek = new Date(date);
        firstDayOfWeek.setDate(date.getDate() - date.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
        return firstDayOfWeek;
      }
      
    const endOfWeek = (date) => {
        const lastDayOfWeek = new Date(date);
        lastDayOfWeek.setDate(date.getDate() - date.getDay() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
        return lastDayOfWeek;
      }
      
    const getUnReadMsgTime = (incTime) => {
        let todayDateTime = new Date();
        let msgDateTime = incTime.toDate();
        let msgTime = incTime.toDate().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        let msgDate = incTime.toDate().toDateString()
        let todayDateTimeDateVal = todayDateTime.getDate();
        let todayDateTimeMonthVal = todayDateTime.getMonth();
        let todayDateTimeYearVal = todayDateTime.getFullYear();
        let msgDateVal = msgDateTime.getDate()
        let msgMonthVal = msgDateTime.getMonth();
        let msgYearVal = msgDateTime.getFullYear();
        const daysOfCurrentWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

        if(todayDateTimeMonthVal === msgMonthVal && todayDateTimeYearVal === msgYearVal){
            if(msgDateVal === todayDateTimeDateVal){
                
                    return "Today";
                

            }else if((todayDateTimeDateVal - 1) === msgDateVal){
                
                    return "Yesterday";
                

            }else if(msgDateTime >= startOfWeek(todayDateTime) && msgDateTime <= endOfWeek(todayDateTime)){
                const dayOfWeekIndex = msgDateTime.getDay();
               
                    return daysOfCurrentWeek[dayOfWeekIndex];
                 
            }else {
               
                    return msgDate

               
            }
        }else{
            
                return msgDate

            
        }
        
    }

    useEffect(() => {
        if(det.chatCountEnable) {
           
            setNewMsgCount(0);
            let userLastSeen = det[`${det?.user_token}_msg_seen`];
            let dateForLastSeen = userLastSeen !== null ? userLastSeen: new Date();
            let queryForLastSeen = query(collection(db, `Chats/${det.doc_id}/message`), orderBy("msg_time", "asc"), where("msg_time", ">=", dateForLastSeen));
           
            
                getDocs(queryForLastSeen).then((snap)=>{
                    setNewMsgCount(snap.size);
                    if(snap.size > 0){
                        let i = 0;
                        snap.forEach(function(doc){
                            if(i === snap.size - 1){
                                let dateVal = getUnReadMsgTime(doc.data().msg_time);
                                setUnreadMsgTime(dateVal);
                            }
                            i++;
                        })
                       
                    }
                    if(newMsgListenerUnsub === undefined){
                        newMsgListenerUnsub = onSnapshot(queryForLastSeen, (snap) => {
                            setNewMsgCount(snap.size);
                            if(snap.size > 0){
                                let i = 0;
                                snap.forEach(function(doc){
                                    if(i == snap.size - 1){
                                        let dateVal = getUnReadMsgTime(doc.data().msg_time);
                                        setUnreadMsgTime(dateVal);
                                    }
                                    i++;
                                })
                            }
                           
                        })
                    }
                })
            // } 
          
            
        } else{

            
            setNewMsgCount(0);
            setUnreadMsgTime('');
            if(newMsgListenerUnsub !== undefined){
                newMsgListenerUnsub();
            }
            let lastSeenDate = new Date();
            updateDoc(docRef, {
                [`${det?.user_token}_msg_seen`]:  Timestamp.fromDate(lastSeenDate),
                
            });
              
        }

        return () => {
            let lastSeenDate = new Date();
         
            setNewMsgCount(0);
            if(newMsgListenerUnsub !== undefined){
                newMsgListenerUnsub();
            }
        }
    
      
    }, [det.chatCountEnable])
    
    const lastMessageTimestamp = det.lastMessageTimestamp;
    const timeAgo = formatTimeAgo(lastMessageTimestamp);
    

    return (
        <div  onClick={onClickListHandler} style={{cursor:"pointer"}} className={style.chatList}>
            <div className={style.imageNameContainer}>
                <Image
                    src={det?.isDeleted ? maleAvatar : process.env.REACT_APP_S3_CLOUD_FRONT_URL+ det.participant_image} 
                    width={44}
                    height={44}
                    className={style.chatProfileImage}
                />
                <div className={style.nameContainer}>
                    <p className={style.name}>{ det?.isDeleted ? "Unknown User" : det.participant_name}{ !det?.isDeleted && <span className={style.id}>({det.participant_profile_id})</span>}</p>
                    {( det.chatCountEnable && det[`${det.participant_token}_msg_seen`] > 0 ) ? (
                        <p className={style.messageStatus}>Has messaged you</p>
                    ): (
                        det?.chatCountEnable ? ( <p className={style.messageStatus}>{det.lastMessageText.slice(0, 25)}{det.lastMessageText?.length > 25 && "..."}</p> ) : <></>
                    )}
                </div>
            </div>
            
                <div className={style.timeCountContainer}>
                    { !det?.isDeleted && <p className={style.time}>{timeAgo}</p> }
                    { ( det.chatCountEnable && det[`${det.participant_token}_msg_seen`] > 0 ) ? (
                        <p className={style.unreadCount}>{det[`${det.participant_token}_msg_seen`]}</p>
                    ): <p  className={style.noUnreadCount}></p>}
                </div>
        </div>
                       
    )

}
export default ChatSidebarList;