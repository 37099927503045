

import * as React from 'react';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import Modal from '@mui/material/Modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CustomButton from '../../../UI/Button/Button';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    minWidth: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    p: 4,
};
const Responsstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '5px',
    p: 4,
};

const CenteredTitle = styled.h2`
  text-align: center;
  font-size: 24px;
  color: #090909;
  font-family: 'Semibold';
  
`;

export default function ResponsiveDialog(props) {
    const { titel, children, openPopup, setOpenPopup, size, handleSubmit, type, okay, width, onCloseFunction } = props

    const handleClose = () => {
        setOpenPopup(false); // Close the modal
        if (onCloseFunction) {
            onCloseFunction(); // Call the additional function passed through props
        }
    };


    return (
        <div>
            <Modal
                open={openPopup}
                keepMounted
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-titl"
                aria-describedby="keep-mounted-modal-descriptio"
            >

                <Box sx={width ? Responsstyle : style}>
                    <div className='poppup_pading'>
                        <CenteredTitle>{titel}</CenteredTitle>

                        {children}

                        <Box>
                            <div className='dialogActions'>
                                <CustomButton className={okay === 'ok' ? 'secondary_btn' : "primary_btn"} children={okay === 'ok' ? 'Okay' : "Cancel"} onClick={handleClose} />
                                {type === 'view' ? " " : <CustomButton className="secondary_btn" children="Submit" onClick={handleSubmit} />}
                            </div>
                        </Box>
                    </div>
                </Box>

            </Modal>
        </div>
    );
}


// import * as React from 'react';

// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogTitle from '@mui/material/DialogTitle';


// export default function ResponsiveDialog(props) {
//     const { header, children, openPopup, setOpenPopup } = props



//     return (
//         <div>

//             <Dialog
//                 open={openPopup}

//                 aria-labelledby="responsive-dialog-title"
//             >
//                 <div className='titels'>
//                     <p style={{ cursor: "pointer" }} onClick={() => setOpenPopup(false)}>X</p>
//                 </div>
//                 <DialogTitle>
//                     <h2 style={{ textAlign: "center" }}>{header}</h2>
//                 </DialogTitle>

//                 <DialogContent >
//                     {children}
//                 </DialogContent>
//                 <DialogActions>


//                 </DialogActions>
//             </Dialog>
//         </div>
//     );
// }