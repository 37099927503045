
import React, { useState, useEffect } from 'react'
import classes from './Legal.module.css'
import Tabs from "../../UI/Tabs/Tabs";
import PagesHeader from "../../features/PagesHeader/PagesHeader";
import Modal from './Modal/Modal';
import useApiHttp from '../../hooks/ues-http';
import { BASE_URL } from '../../utils/baseUrl';
import Button from '../../UI/Button/Button';
import RichText from '../../UI/RichText/Editor';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import Loader from '../../UI/Loader/Loader';
import { AnimatePresence , motion} from 'framer-motion';

const Legal = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);

    const [termsContentData, setTermsContentData] = useState([]);
    const [privacyPolicyData, setPrivacyPolicyData] = useState([]);
    const [aboutUsData, setAboutUsData] = useState([]);
    const [contactUsData, setContactUsData] = useState([]);

    const [termsContent, setTermsContent] = useState({ value: null });
    const [privacyPolicy, setPrivacyPolicy] = useState({ value: null });
    const [aboutUs, setAboutUs] = useState({ value: null });
    const [contactUs, setContactUs] = useState({ value: null });

    const handleTermsContentChange = value => {
        setTermsContent({ value });
    };
    const handlePrivacyPolicyChange = value => {
        setPrivacyPolicy({ value });
    };
    const handleAboutUsChange = value => {
        setAboutUs({ value });
    };

    const fiestPopPup = () => {
        setOpenPopup(true)
    }

    const { isLoading, success, error, sendRequest } = useApiHttp();
    const fetchUserData = (type) => {
        const requestData = {
            type,
        };
        sendRequest({
            url: `${BASE_URL}legals-view`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            if (type === 'terms') {
                setTermsContentData(data.data)
            } else if (type === 'privacy') {
                setPrivacyPolicyData(data.data)
            } else if (type === 'about_us') {
                setAboutUsData(data.data)
            } else if (type === 'contact_us') {
                setContactUsData(data.data)
            } else {
                console.error('Error fetching data:', error);
            }
        });
    }
    useEffect(() => {
        if (activeTab === 0) {
            fetchUserData('terms');
        } else if (activeTab === 1) {
            fetchUserData('privacy');
        } else if (activeTab === 2) {
            fetchUserData('about_us');
        } else if (activeTab === 3) {
            fetchUserData('contact_us');
        }
    }, [activeTab])

    useEffect(() => {
        setTermsContent({ ...termsContent, value: termsContentData.terms_and_condition })
        setPrivacyPolicy({ ...privacyPolicy, value: privacyPolicyData.privacy_policy })
        setAboutUs({ ...aboutUs, value: aboutUsData.content })
        setContactUs({ ...contactUs, value: contactUsData.content })
    }, [termsContentData, privacyPolicyData, aboutUsData, contactUsData])

    const { success: termsContentSucces, error: termsContentErroe, sendRequest: termsContentRequest } = useApiHttp();
    useEffect(() => {
        if (termsContentSucces !== '') {
            toast.success(termsContentSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (termsContentErroe !== '') {
            toast.error(termsContentErroe, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [termsContentSucces, termsContentErroe]);

    const handleSubmitTermsContent = () => {
        const requestData = {
            "type": "terms",
            "content": termsContent.value
        };
        termsContentRequest({
            url: `${BASE_URL}legals-update`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setOpenPopup(false)
            fetchUserData('terms')
        });
    }
    const { success: privacyPolicySucces, error: privacyPolicyErroe, sendRequest: privacyPolicyRequest } = useApiHttp();
    useEffect(() => {
        if (privacyPolicySucces !== '') {
            toast.success(privacyPolicySucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (privacyPolicyErroe !== '') {
            toast.error(privacyPolicyErroe, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [privacyPolicySucces, privacyPolicyErroe]);
    const handleSubmitPrivacyPolicy = () => {
        const requestData = {
            "type": "privacy",
            "content": privacyPolicy.value
        };
        termsContentRequest({
            url: `${BASE_URL}legals-update`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setOpenPopup(false)
            fetchUserData('privacy')
        });
    }

    const { isLoading: aboutUsLoding, success: aboutUsSucces, error: aboutUsErroe, sendRequest: aboutUsRequest } = useApiHttp();
    useEffect(() => {
        if (aboutUsSucces !== '') {
            toast.success(aboutUsSucces, {
                position: toast.POSITION.TOP_RIGHT,
            })
        }
        if (aboutUsErroe !== '') {
            toast.error(aboutUsErroe, {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }, [aboutUsSucces, aboutUsErroe]);
    const handleSubmitAboutUs = () => {
        const requestData = {
            "type": "about_us",
            "content": aboutUs.value
        };
        termsContentRequest({
            url: `${BASE_URL}legals-update`,
            method: 'POST',
            body: requestData,
        }, (data) => {
            setOpenPopup(false)
            fetchUserData('about_us')
        });
    }

    const onTabClick = (index) => {
        setActiveTab(index); // Update the active tab index
    };
    const tabAnimation = {
        initial: {
          y: -200,
          opacity: 0,
        },
        animate: {
          y: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            staggerChildren: 0.1,
          },
        },
        exit: {
            y: -200,
            opacity: 0,
        }
      };
    return (
        <AnimatePresence>
            <PagesHeader title="Legal" />
            <section className='tablesection_heder'>
                <div className={classes.buttonsContainer}>
                    {activeTab === 0 && <Button className={classes.login_btn} onClick={fiestPopPup}>Update</Button>} {activeTab === 1 && <Button className={classes.login_btn} onClick={fiestPopPup}>Update</Button>} {activeTab === 2 && <Button className={classes.login_btn} onClick={fiestPopPup}>Update</Button>} {activeTab === 3 && <Button className={classes.login_btn} onClick={fiestPopPup}>Update</Button>}
                </div>
                <Tabs
                    tabs={[
                        {
                            title: "Terms and Conditions",
                            render: () => (
                                <motion.dev 
                                variants={tabAnimation}
                                initial="initial"
                                animate="animate"
                                exit="exit">
                                    {!isLoading ? <div className={classes.container_box}>
                                        <ReactQuill
                                            readOnly
                                            theme="bubble"
                                            value={termsContentData.terms_and_condition}
                                        />
                                    </div>
                                        :
                                        <><div className='loader_bg'><Loader /></div></>
                                    }

                                </motion.dev >
                            ),
                        },
                        {
                            title: "Privacy Policy",
                            render: () => (
                                <motion.dev 
                                variants={tabAnimation}
                                initial="initial"
                                animate="animate"
                                exit="exit">
                                    {!isLoading ? <div className={classes.container_box}>
                                        <ReactQuill
                                            readOnly
                                            theme="bubble"
                                            value={privacyPolicyData.privacy_policy}
                                        />
                                    </div>
                                        : <><div className='loader_bg'><Loader /></div></>}

                                </motion.dev >
                            ),
                        },
                        {
                            title: "About Us",
                            render: () => (
                                <motion.dev 
                                variants={tabAnimation}
                                initial="initial"
                                animate="animate"
                                exit="exit">
                                    {!isLoading ? <div className={classes.container_box}>
                                        <ReactQuill
                                            readOnly
                                            theme="bubble"
                                            value={aboutUsData.content}
                                        />
                                    </div>
                                        :
                                        <><div className='loader_bg'><Loader /></div></>}
                                </motion.dev >
                            ),
                        },
                    ]}
                    onTabClick={onTabClick}
                    activeTab={activeTab}
                />
            </section>

            {activeTab === 0 &&

                (<Modal openPopup={openPopup} setOpenPopup={setOpenPopup} titel="Terms and Conditions Update" size="lg" handleSubmit={handleSubmitTermsContent} >
                    <>
                        <div className={classes.richtext_box}>
                            {openPopup && <RichText
                                label="Terms and Conditions"
                                value={termsContent.value}
                                onChange={handleTermsContentChange}
                                placeholder="Write Terms and Conditions here..."
                                theme="snow"
                            />}
                        </div>
                    </>
                </Modal>)
            }

            {activeTab === 1 && (<Modal openPopup={openPopup} setOpenPopup={setOpenPopup} titel="Privacy Policy Update" size="lg" handleSubmit={handleSubmitPrivacyPolicy}>
                <>
                    <div className={classes.richtext_box}>
                        {openPopup && <RichText
                            label="Privacy Policy"
                            value={privacyPolicy.value}
                            onChange={handlePrivacyPolicyChange}
                            placeholder="Write Privacy Policy here..."
                            theme="snow"
                        />}
                    </div>
                </>
            </Modal>)}

            {activeTab === 2 && (<Modal openPopup={openPopup} setOpenPopup={setOpenPopup} titel="About Us Update" size="lg" handleSubmit={handleSubmitAboutUs}>
                <>
                    <div className={classes.richtext_box}>
                        {openPopup && <RichText
                            label="About Us"
                            value={aboutUs.value}
                            onChange={handleAboutUsChange}
                            placeholder="Write About Us here..."
                            theme="snow"
                        />}
                    </div>
                </>
            </Modal>)}
            <ToastContainer />
        </AnimatePresence>
    )
}

export default Legal