import React, { useEffect, useState } from 'react'
import style from "./Preference.module.css"
import useApiCall from '../../../../hooks/useApiCall';
import config from '../../../../../../app.json';
import partnerPreferenceIcon from "../../../../assets/common/letsUploadVideo.svg"
import Image from '../../../../../../ui/Image/Image'
import Button from '../../../../../../ui/Button/Button'
import { useNavigate } from 'react-router-dom'
import { addScroll, getUserData, getUserToken } from '../../../../../../utils/utils'

export default function Preference({from}) {
  const navigate = useNavigate("")

  const { isLoading: isLoading, success: success, error: error, sendRequest: skipPreference } = useApiCall();

  useEffect(() => {
    if (success) {
      addScroll()
      const storedData = JSON.parse(localStorage.getItem('user'));
      storedData.status = "Active";
      localStorage.setItem('user', JSON.stringify(storedData));
      navigate("/home")
    }
    if (error !== '') {
    }
  }, [success, error]);

  const handleSkip = () => {
    skipPreference({
      url: "preference/skip",
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`
      }
    });
  }
  
  return (
    <div className={style.successModalContainer}>
        <Image
            src={partnerPreferenceIcon}
        />
        <p className={style.title}>Craft Your Perfect Connection: Fine-Tune Your Matches with Personalized Self-Intro Video</p>
        <p className={style.description}>Unveiling a World of Love and Connection Where Hearts Align, Bonds Strengthen, and Souls Discover Everlasting Bliss</p>
        <Button
            className={`primaryButton ${style.submitButton}`}
            onClick={()=> {
              addScroll()
              navigate("/partner_preference")
            }}
        >
          Let's build preference for your partner
        </Button>
        {
          getUserData()?.membership_type === "Elite" ?
            <p onClick={handleSkip} className={style.skipSection}>Skip</p>
          :
            <></>
        }
    </div>
  )
}
