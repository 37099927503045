import React, { useState } from 'react'
import style from "./NavMenu.module.css"
import Image from '../../../../ui/Image/Image';
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import closeImage from "../../assets/svg/Signup/Close.svg"
import { addScroll } from '../../../../utils/utils';
import { NavbarContext } from './Context/NavbarContext';
import Form from "./Form/Form"

export default function NavMenu({closeDrawer, setDrawerType}) {

  const [ activeStep, setActiveStep ] = useState("form")
  const [id, setId] = useState('');

  const handleCloseDrawer = () => {
    setActiveStep("form")
    closeDrawer(true)
  };

  return (
    <NavbarContext.Provider value={{setActiveStep, id, setId, handleCloseDrawer}}>
      <div className={style.signupContainer}>
        <Image
          onClick={()=> {
              addScroll()
              handleCloseDrawer()
            }
          }
          src={closeImage}
          className={style.closeIcon}
        />
        <div className={style.contentContainer}>
          <Image
            src={logoImage}
            className={style.logo}
          />
          <Form setDrawerType={setDrawerType}/>
        </div>
      </div>
    </NavbarContext.Provider>
  );
}
