import React, { useEffect, useState } from 'react'
import style from "./ProfileDetails.module.css"
import Carousel from 'react-grid-carousel'
import Image from '../../../../ui/Image/Image'
import doctorImage from "../../assets/png/Home/Dr. Nandhini.png"
import sortlistStarIcon from "../../assets/svg/Matches/Shortlisted.svg";
import sortlistGrayStarIcon from "../../assets/svg/Matches/ShortlistGray.svg";
import editOptionImage from "../../assets/svg/Home/editIcon.svg"
import nextArrowImage from "../../assets/svg/ProfileView/Next Arrow.svg"
import Button from '../../../../ui/Button/Button'
import Tabs from '../../features/ProfileDetails/Tabs/Tabs'
import 'react-circular-progressbar/dist/styles.css';
import CircularProgress from '@mui/joy/CircularProgress';
import useApiCall from '../../hooks/useApiCall';
import useChatSetter from '../../hooks/useChatSetter';
import config from '../../../../app.json';
import { Circles } from 'react-loader-spinner'
import { addScroll, avoidScroll, getUserData, getUserToken } from '../../../../utils/utils'
import { ProfileDetailsContext } from '../../features/ProfileDetails/Context/ProfileDetailsContext'
import HobbieDetails from '../../features/ProfileDetails/HobbieDetails/HobbieDetails'
import { useLocation, useNavigate } from 'react-router-dom'
import { BlockReport } from '../../features/BlockReport/BlockReport'
import chatIcon from "../../features/MailBox/assets/Chat.svg"
import infoIcon from "../../features/MailBox/All/assets/Info.svg";
import ModalBox from '../../../../ui/Modal/ModalBox'
import { DeclineModal } from '../../features/Matches/DeclineModal/DeclineModal'
import BuildPreference from '../../features/HomePage/BuildPreference/BuildPreference'
import maleAvatar from "../../assets/common/male.svg"
import femaleAvatar from "../../assets/common/female.svg"
import { MembershipTypeCard } from '../../features/MembershipTypeCard/MembershipTypeCard'
import { Viewer } from './Viewer/Viewer'
import { BecameMemberModal } from '../../features/BecameMemberModal/BecameMemberModal'
import Canvas from '../../features/MaskImage/MaskImage'
import MaskImage from '../../features/MaskImage/MaskImage'
import { width } from '@mui/system'

export default function ProfileDetails() {

    const { state } = useLocation();

    const navigation = useNavigate()

    const [data, setData] = useState(null);
    const [selectedToken, setSelectedToken] = useState(localStorage.getItem("redirectToken") ? localStorage.getItem("redirectToken") : state?.token)
    const [intrestRecieved, setIntrestRecieved] = useState("")
    const [shortlisted, setShortlisted] = useState("")
    const [intrestSent, setIntrestSent] = useState("")
    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();
    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: sendIntrest } = useApiCall();
    const { isLoading: sendShortListLoading, success: sendShortListSuccess, error: sendShortListError, sendRequest: shortlist } = useApiCall();
    const { sendRequest: acceptInterest } = useApiCall();
    const { sendRequest: declineInterest } = useApiCall();
    const { loading: isChatSetterLoading, success: successChatSetter, error: errorChatSetter, setUserChatEntry: chatRoomSetup } = useChatSetter();

    const [actionData, setActionData] = useState(null)
    const [limitExceeded, setLimitExceeded] = useState(0)

    const [refresh, setRefresh] = useState(false)
    const [clickedItem, setClickedItem] = useState(false)
    const [chatToken, setChatToken] = useState(null)
    const [rejectReason, setRejectReason] = useState(null)
    const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)
    const [showPreviewModal, setShowPreviewModal] = useState(false)
    const [previewData, setPreviewData] = useState()

    const [rejectToken, setRejectToken] = useState(null)

    const [interestClicked, setInterestClicked] = useState(false)
    const [showBecameMemberModal, setShowBecameMemberModal] = useState(false)

    useEffect(() => {
        if (successChatSetter) {
            navigation("/chat", { state: { token: chatToken } })
        }
        if (errorChatSetter) {
            console.log("errorChatSetter", errorChatSetter);
        }
    }, [successChatSetter, errorChatSetter]);

    const handleChat = (token, name, user_id, image) => {
        chatRoomSetup(
            token,
            name,
            user_id,
            image
            , (data) => {
                setChatToken(data)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        addScroll()
        getDetails({
            url: `profile/get-detail`,
            method: 'POST',
            body: {
                "profile_token": selectedToken
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, (responseData) => {
            setData(responseData.data)
            if (responseData.data.limit_exceeded) {
                // avoidScroll()
            }
            setShowBecameMemberModal(responseData.data.limit_exceeded)
            localStorage.removeItem("redirectToken")
        });
    }, [selectedToken, refresh]);

    useEffect(() => {
        setLimitExceeded(data?.limit_exceeded)
        setActionData(data?.profile_details?.user_profile_details)
        setIntrestSent(data?.profile_details?.user_profile_details?.personal_details[0]?.interest_status)
        setShortlisted(data?.profile_details?.user_profile_details?.personal_details[0]?.shortlisted)
        setIntrestRecieved(data?.profile_details?.user_profile_details?.personal_details[0]?.interest_recevied)
        setPreviewData(data?.profile_details?.user_profile_details?.image)
    }, [data])

    const handleSendInterest = (token, status) => {
        setInterestClicked(true)
        if (!limitExceeded) {
            sendIntrest({
                url: `profile/toggle-interest`,
                method: 'POST',
                body: {
                    "profile_token": token,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, () => {
                setIntrestSent(status)
                setRefresh(!refresh)
            });
        }
    }

    const handleShortlist = (token, status) => {
        shortlist({
            url: `profile/toggle-shortlist`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            setShortlisted(status)
            setRefresh(!refresh)
        });
    }

    const handleAccept = (token) => {
        acceptInterest({
            url: `profile/accept-interest`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            setIntrestRecieved("2")
            setRefresh(!refresh)
        });
    }

    const handleCancel = (reason) => {
        declineInterest({
            url: `profile/reject-interest`,
            method: 'POST',
            body: {
                "profile_token": rejectToken,
                "reject_reason": reason.trim()
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            setRefresh(!refresh)
        });
    }

    const actionUi = () => {

        const {
            can_send_interest,
            interest_received_status,
            interest_sent_status,
            is_connected,
        } = actionData

        const token = data?.profile_details?.user_profile_details?.token
        const name = data?.profile_details?.user_profile_details?.name
        const image = data?.profile_details?.user_profile_details?.image[0]
        const user_id = data?.profile_details?.user_profile_details?.user_id

        return (
            <>
                {
                    can_send_interest === 1 && interest_sent_status === 0 ?
                        <div className={style.buttonProgressContainer}>
                            {/* <p className={style.likeProfileLabel}>Like her profile?</p> */}
                            <div className={style.buttonContainer}>
                            <Button
                                className={`primaryButton`}
                                onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                            >
                                Send Interest
                            </Button>
                            </div>
                        </div>
                        :
                        <></>
                }
                {
                    interest_sent_status === 1 ?
                        <div className={`${style.intrestSentContainer} ${style.accepted}`}>
                            <p className={style.title}>Interest Sent Successfully</p>
                            <p className={style.message}>Once accepts your interest You can chat with Dr. {name}.</p>
                        </div>
                        :
                        <></>
                }
                {
                    interest_received_status === 2 ?
                        <div className={`${style.intrestSentContainer} ${style.accepted}`}>
                            <p className={style.title}>Interest Accepted</p>
                            <p className={style.message}>You have accepted the interest request, now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    interest_received_status === 3 ?
                        <>
                            <div className={`${style.intrestSentContainer} ${style.rejected}`}>
                                <p className={style.title}>Interest Rejected</p>
                                <p className={style.message}>Dr. {name} has declined your interest.</p>
                            </div>
                        </>
                        :
                        <></>
                }
                {
                    interest_sent_status === 2 ?
                        <div className={`${style.intrestSentContainer} ${style.accepted}`}>
                            <p className={style.title}>Interest Accepted</p>
                            <p className={style.message}>Dr. {name} has accepted your interest.</p><p> Now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        interest_sent_status === 3 ?
                            <div className={`${style.intrestSentContainer} ${style.rejected}`}>
                                <p className={style.title}>Interest Rejected</p>
                                <p className={style.message}>Dr. {name} has declined your interest.</p>
                            </div>
                            :
                            <></>
                }
                {
                    interest_received_status === 1 ?
                        <div className={style.buttonProgressContainer}>
                            <div className={style.buttonContainer}>
                                <Button
                                    className={`${style.button} primaryButton`}
                                    onClick={() => handleAccept(token)}
                                >
                                    Accept
                                </Button>
                                <Button
                                    className={`${style.declineButton} primaryButton cancelButton`}
                                    onClick={() => {
                                        setRejectToken(token)
                                        avoidScroll()
                                        setShowRejectReasonModal(true)
                                        // handleCancel(token)
                                    }}
                                >
                                    Decline
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                }
            </>
        )
    }


    return (
        <>
            {
                (interestClicked && showBecameMemberModal) ? (
                    <BecameMemberModal status={true} setInterestClicked={setInterestClicked} />
                ) : <></>
            }
            <ModalBox
                open={showRejectReasonModal}
                width={"598px"}
                modalContent={
                    <DeclineModal
                        buttonText={"Okay"}
                        setRejectReason={setRejectReason}
                        setShowRejectReasonModal={setShowRejectReasonModal}
                        onClick={handleCancel}
                    />
                }
            />
            <ModalBox
                open={showPreviewModal}
                width={"598px"}
                modalContent={
                    <Viewer
                        data={data}
                        buttonText={"Okay"}
                        doc={previewData}
                        onClick={() => {
                            addScroll()
                            setShowPreviewModal(false)
                        }}
                    />
                }
            />
            {error ? (
                <div className={style.loaderContainer}>
                    <p className={style.error}>{error}</p>
                </div>
            ) : (
                <ProfileDetailsContext.Provider value={{ data }}>
                    <div className={`container ${style.profileDetailsContainer}`}>
                        <div className={`${style.topBestMatchesContainer}`}>
                            <div className={style.imageAndDetailsContainer}>
                                <div className={style.profileImageContainer}>
                                    <div className={style.imagesCarousalContainer}>
                                        {
                                            data?.profile_details?.user_profile_details?.image?.length === 0 ?
                                                <Image
                                                    src={getUserData()?.gender === "Male" ? maleAvatar : femaleAvatar}
                                                    className={style.doctorImage}
                                                />
                                                :
                                                getUserData()?.membership_type === "Basic" ?
                                                    <MaskImage 
                                                        imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + data?.profile_details?.user_profile_details?.image[0]?.content}
                                                        watermarkText={`${data?.profile_details?.user_profile_details?.name}(${data?.profile_details?.user_profile_details?.user_id})`}
                                                        width={181}
                                                        height={210}
                                                        side={true}
                                                    />
                                                    // <Image
                                                    //     src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + data?.profile_details?.user_profile_details?.image[0]?.content}
                                                    //     className={style.doctorImage}
                                                    // />
                                                    :
                                                    <Carousel
                                                        containerClassName={style.imagesCarouselContainer}
                                                        cols={1}
                                                        rows={1}
                                                        gap={40}
                                                        loop
                                                        dotColorActive={"rgb(230, 70, 98)"}
                                                        dotColorInactive={"rgb(68 68 68 / 49%)"}
                                                        showDots={true}
                                                    >
                                                        {
                                                            data?.profile_details?.user_profile_details?.image?.map((itemImage, index) => (
                                                                <Carousel.Item>
                                                                    <div className={style.userProfileContainer}>
                                                                        {
                                                                            itemImage?.type === "image" ?
                                                                                <div onClick={() => {
                                                                                    avoidScroll()
                                                                                    setShowPreviewModal(true)
                                                                                }}>
                                                                                    <MaskImage 
                                                                                        imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + itemImage?.content} 
                                                                                        watermarkText={`${data?.profile_details?.user_profile_details?.name}(${data?.profile_details?.user_profile_details?.user_id})`}
                                                                                        width={181}
                                                                                        height={210}
                                                                                        side={true}
                                                                                    />
                                                                                </div>
                                                                                // <Image
                                                                                //     onClick={() => {
                                                                                //         avoidScroll()
                                                                                //         setShowPreviewModal(true)
                                                                                //     }}
                                                                                //     src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + itemImage?.content}
                                                                                //     className={style.doctorImage}
                                                                                // />
                                                                                :
                                                                                <video width={126} height={126} controls>
                                                                                    <source src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + itemImage?.content} />
                                                                                </video>
                                                                        }
                                                                    </div>
                                                                </Carousel.Item>
                                                            ))
                                                        }
                                                    </Carousel>
                                        }
                                    </div>
                                    <div className={style.detailsContainer}>
                                        <div className={style.headerContainer}>
                                            <div className={style.nameContainer}>
                                                <p className={style.id}>{data?.profile_details?.user_profile_details?.user_id}</p>
                                                <MembershipTypeCard type={data?.profile_details?.user_profile_details?.membership_type} />
                                            </div>
                                            <div className={style.imageContainer}>
                                                <Button
                                                    className={`${style.sortListedButton} ${shortlisted === 1 ? style.sortlisted : ""}`}
                                                    onClick={() => handleShortlist(data?.profile_details?.user_profile_details?.token, shortlisted === 1 ? 0 : 1)}
                                                >
                                                    <Image
                                                        src={shortlisted === 1 ? sortlistStarIcon : sortlistGrayStarIcon}
                                                    />
                                                    {shortlisted === 1 ? "Shortlisted" : "Shortlist"}
                                                </Button>
                                                <BlockReport  refresh={refresh} setRefresh={setRefresh} id={data?.profile_details?.user_profile_details?.user_id} token={data?.profile_details?.user_profile_details?.token} />
                                            </div>
                                        </div>
                                        <h1 className={style.name}>{data?.profile_details?.user_profile_details?.name}</h1>
                                        {data?.is_onboard_completed === 1 ? <>
                                            <div className={style.personal}>
                                                {/* { getUserData()?.membership_type !== "Basic" && ( */}
                                                <div className={style.personalDetails}>
                                                    <p className={style.personaTtext}>{data?.profile_details?.user_profile_details?.user_summary?.age} yrs, {data?.profile_details?.user_profile_details?.personal_details[0]?.height}, {data?.profile_details?.user_profile_details?.personal_details[0]?.mother_tongue}, {data?.profile_details?.user_profile_details?.user_summary?.occupation}</p>
                                                    { data?.profile_details?.user_profile_details?.user_summary?.community !== null && data?.profile_details?.user_profile_details?.user_summary?.community !== "Not Specified" ? <p className={style.personaTtext}>{data?.profile_details?.user_profile_details?.user_summary?.community}</p> : <></> }
                                                    <p className={style.personaTtext}>{data?.profile_details?.user_profile_details?.user_summary?.city}, {data?.profile_details?.user_profile_details?.user_summary?.state}</p>
                                                </div>
                                                {/* )} */}
                                                {/* <div className={style.onlineContainer}> */}
                                                {/* <div className={style.dot}></div>
                                                <p className={style.labelText}>Online Now</p> */}
                                                {/* </div> */}
                                            </div>
                                            <p className={style.aboutText}>About {data?.profile_details?.user_profile_details?.name}</p>
                                            <p className={style.aboutDescription}>{data?.profile_details?.user_profile_details?.abount}</p>
                                            <HobbieDetails />
                                        </> : <div className='non-onboarded-user'> </div>}

                                        <div className={style.percentageAndIntrestContainer}>
                                            {
                                                actionData ?
                                                    actionUi()
                                                    :
                                                    <></>
                                            }
                                            {data?.is_onboard_completed === 1 ? <>
                                                {getUserData()?.membership_type !== "Basic" && (
                                                    <div className={style.buttonProgressContainer}>
                                                        <div className={style.buttonProgressContainer}>
                                                            <CircularProgress
                                                                size="lg"
                                                                determinate
                                                                value={data?.profile_details?.user_matches_calculation?.percentage}
                                                                sx={{
                                                                    '--CircularProgress-size': '80px',
                                                                    color: "red", // This sets the color of the entire CircularProgress component.
                                                                    '& .MuiCircularProgress-circleDeterminate': {
                                                                        color: "red", // This sets the color of the filled progress.
                                                                    },
                                                                }}
                                                            >
                                                                <div className={style.matchProgressContainer}>
                                                                    <p className={style.progressValue}>{data?.profile_details?.user_matches_calculation?.percentage}%</p>
                                                                    <p className={style.progressLabel}>Match</p>
                                                                </div>
                                                            </CircularProgress>
                                                        </div>
                                                    </div>
                                                )}</> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {data?.is_onboard_completed === 1 ? <>
                                <div className={style.preferenceContainer}>
                                    {data?.is_incomplete_elite ? <BuildPreference /> : <> </>}
                                </div>
                                <div className={style.tabsContainer}>
                                    <Tabs details={data} />
                                </div>
                            </> : <p className={style.aboutText} style={{ textAlign: 'center' }}> The user has yet to be onboarded.<br/>
Your interest may encourage him/her to onboard soon.</p>}
                        </div>
                        {
                            (getUserData().membership_type !== "Basic" && data?.simiar_matches?.length) ?
                                <div className={style.similarMatchesContainer}>
                                    <p className={style.title}>Similar Matches for You!</p>
                                    <div className={style.similarMatches}>
                                        {
                                            data?.simiar_matches?.map((item, index) => (
                                                <div className={style.similarMatch}>
                                                    <div className={style.imageNameContainer}>
                                                        <Image
                                                            onClick={() => setSelectedToken(item?.token)}
                                                            src={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item?.image}
                                                            className={style.similarMatchImage}
                                                        />
                                                        <div className={style.details}>
                                                            <p
                                                                className={style.id}
                                                                onClick={() => setSelectedToken(item?.token)}
                                                            >{item?.user_id}</p>
                                                            <p
                                                                className={style.name}
                                                                onClick={() => setSelectedToken(item?.token)}
                                                            >{item?.name}</p>
                                                            {item?.is_onboard_completed === 1 ? <>
                                                                <p className={style.physique}>{item?.age} yrs, {item?.height}, {item?.mother_tongue}, {item?.occupation}</p>
                                                                <div className={style.personalDetails}>
                                                                    <p className={style.personaTtext}>{item?.community}</p>
                                                                    <p className={style.personaTtext}>{item?.city}, {item?.state}</p>
                                                                </div> </> : ""}
                                                        </div>
                                                    </div>
                                                    <Image
                                                        onClick={() => setSelectedToken(item?.token)}
                                                        src={nextArrowImage}
                                                        className={style.arrowImage}
                                                    />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                : <></>
                        }
                    </div>
                </ProfileDetailsContext.Provider>
            )}
        </>
    )
}
