import React, { useEffect, useState, useRef, useContext } from 'react';
import style from './PersonalDetails.module.css';
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import Button from '../../../../../ui/Button/Button';
import ProgressBar from '../../../../../ui/ProgressBar/ProgressBar';
import Image from '../../../../../ui/Image/Image';
import uploadIcon from '../../../assets/common/Upload Image.svg';
import uploadWhiteIcon from '../../../assets/common/upload-white.svg';
import deleteIcon from '../../../assets/common/Bin.svg';
import PopoutInput from '../../../../../ui/PopoutInput/PopoutInput';
import PopoutDatePicker from '../../../../../ui/PopoutDatePicker/PopoutDatePicker';
import FloatingLabelDropdown from '../../../../../ui/FloatingLabelDropdown/FloatingLabelDropdown';
import radioUnselected from "../../../assets/common/Radiobutton_off.svg"
import radioSelected from "../../../assets/common/Radiobutton_on.svg"
import { BuildProfileContext } from '../Context/BuildProfileContext';
import PopoutTextarea from '../../../../../ui/PopoutTextarea/PopoutTextarea';
import { getUserToken, uploadFile, getUserData, defaultDate, formatMuiDate, isStringValid, slugify, currentDateForFilename } from '../../../../../utils/utils';
import MuiDatePicker from '../../../../../ui/MuiDatePicker/MuiDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import FloatingDatePicker from '../../../../../ui/FloatingDatePicker/FloatingDatePicker';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import notesIcon from "../../../assets/common/notes.svg"

export default function PersonalDetails() {

  const navigation = useNavigate()

  const { sendRequest: getMetaData } = useApiCall();

  const { setActiveStep } = useContext(BuildProfileContext);
  const uploadVideoInput = useRef(null);

  const { isLoading: isLoading, success: success, error: error, sendRequest: uploadUserDetails } = useApiCall();
  const [uploadedImages, setUploadedImages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const uploadInputs = useRef([]);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFile, setVideoFile] = useState("");
  const [uploadPersonalVideo, setUploadPersonalVideo] = useState("");

  const [motherTongueData, setMotherTongueData] = useState([])
  const [heightData, setHeightData] = useState([])
  const [citizenshipData, setCitizenshipData] = useState([])
  const [genderData, setGenderData] = useState([])
  const [physicalStatusData, setPhysicalStatusData] = useState([])
  const [maritalStatusData, setMaritalStatusData] = useState([])
  const [loader, setLoader] = useState(false)
  const [weight, setWeight] = useState("") 
  const [weightData, setWeightData] = useState("") 

  const [validationError, setValidationError] = useState("")

  const [formData, setFormData] = useState({
    images: [],
    video: "",
    collegeName: "",
    dob: "",
    weight: "",
    height: [],
    emailAddress: '',
    citizenship: [],
    motherTongue: [],
    maritalStatus: '',
    physicalStatus: '',
    moreAbout: '',
    gender: '',
    mobileNumber: ''
  });

  const { sendRequest: getUserDetails } = useApiCall();

  function timestampToMMDDYYYY(timestamp) {
    const date = new Date(timestamp);
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-based
    const day = date.getDate().toString().padStart(2, '0');
    const year = date.getFullYear();

    return `${month}-${day}-${year}`;
  }

  useEffect(() => {

    let maxWeight = 150;    
    let minWeight = 35;   
    let dateOption = []  
    for( let i = minWeight; maxWeight >= minWeight; i++ ) {
      dateOption.push({
        name: minWeight+ " Kg",
        value: minWeight+ " Kg",
      })   
      minWeight += 1;
    }
    console.log("dateOption", dateOption);
    setWeightData(dateOption)

    window.scrollTo(0, 0);
    getUserDetails({
      url: "onboard/read-step-1",
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getUserToken()}`
      }
    }, (data) => {
      const { user_image, video, gender, weight, height, mother_tongue, citizenship, physical_status, marital_status, about } = data?.data
      setUploadedImages(user_image)
      setImageFiles(user_image)
      setVideoFile(video)
      setUploadedVideo(video)
      setWeight(weight+" Kg")
      setFormData({
        images: user_image?.length ? user_image : [],
        collegeName: getUserData().name, 
        dob: timestampToMMDDYYYY(getUserData().date_of_birth),
        weight: weight+" Kg",
        height: height ? height : [],
        emailAddress: getUserData().email,
        citizenship: citizenship,
        video: video ? video : "",
        motherTongue: mother_tongue ? mother_tongue : [],
        maritalStatus: marital_status ? marital_status : "",
        physicalStatus: physical_status ? physical_status : "",
        gender: getUserData().gender,
        moreAbout: about ? about : "",
      })
    });
  }, []);

  useEffect(() => {
    getMetaData(
      {
        url: 'onboard/read-meta-data',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      },
      (data) => {
        const { mother_tongue, citizenship, height, gender, physical_status, marital_status } = data.data;
        setMotherTongueData(mother_tongue);
        setCitizenshipData(citizenship);
        setHeightData(height);
        setGenderData(gender)
        setPhysicalStatusData(physical_status)
        setMaritalStatusData(marital_status)
      }
    );
  }, []);

  const handleImageDelete = (index) => {
    const newImages = [...uploadedImages];
    newImages.splice(index, 1);
    setUploadedImages(newImages);

    // Reset the file input
    if (uploadInputs.current[index]) {
      uploadInputs.current[index].value = null;
    }

    const newImageFiles = [...imageFiles];
    newImageFiles.splice(index, 1);
    setImageFiles(newImageFiles);

    setFormData({ ...formData, images: newImageFiles })
  };

  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      const allowedFormats = ['image/jpeg', 'image/png', 'image/gif']; // Add more formats if needed
  
      // Check if the file size is less than or equal to 6 MB (6 * 1024 * 1024 bytes)
      if (file.size <= 6 * 1024 * 1024) {
        // Check if the file type is allowed
        if (allowedFormats.includes(file.type)) {
          const newImages = [...uploadedImages];
          newImages[index] = {
            image_name:  URL.createObjectURL(file),
            image_status: "Pending"
          }; // Store the data URL for preview
          setUploadedImages(newImages);
  
          const newImageFiles = [...imageFiles];
          newImageFiles[index] = {
            image_name: file,
            image_status: "Pending"
          } // Store the File object
          setImageFiles(newImageFiles);
  
          setFormData({ ...formData, images: newImageFiles });
        } else {
          // Invalid file format, handle the error here (e.g., show a message to the user)
          toast.error('Invalid file format. Please choose a JPEG, PNG, or GIF file.', {
            autoClose: 2500,
          });
          // Optionally, you can clear the input field to allow the user to choose another file
          event.target.value = null;
        }
      } else {
        // File size exceeds 6 MB, handle the error here (e.g., show a message to the user)
        toast.error('File size exceeds 6 MB. Please choose a smaller file.', {
          autoClose: 2500,
        });
        // Optionally, you can clear the input field to allow the user to choose another file
        event.target.value = null;
      }
    }
  };

  const handleVideoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Check if the file type is a supported video format
      const supportedFormats = ['video/mp4', 'video/webm', 'video/ogg', "video/quicktime"]; // Add more supported formats if needed
      if (supportedFormats.includes(file.type)) {
        // Create a video element to get the duration
        const videoElement = document.createElement('video');
  
        // Listen for the 'loadedmetadata' event to ensure the duration is available
        videoElement.addEventListener('loadedmetadata', () => {
          // Check if the duration is less than 3 minutes (180 seconds)
          if (videoElement.duration <= 180) {
            setUploadedVideo(URL.createObjectURL(file)); // Store the data URL for preview
            setUploadPersonalVideo(URL.createObjectURL(file))
            setVideoFile(file); // Store the File object
            setFormData({ ...formData, video: file });
            generateVideoThumbnail(file);
          } else {
            toast.error(`Video duration exceeds 3 minutes. Please select a shorter video.`, {
              autoClose: 2500,
            })
          }
        });
  
        // Set the video element's source to the file URL
        videoElement.src = URL.createObjectURL(file);
      } else {
        // Handle unsupported file format
        toast.error(`Unsupported file format. Please select a supported video file format.`, {
          autoClose: 2500,
        })
      }
    }
  };

  const generateVideoThumbnail = (videoFile) => {
    const video = document.createElement('video');
    video.preload = 'metadata';
    video.onloadedmetadata = () => {
      // Capture a frame from the video as a thumbnail
      const canvas = document.createElement('canvas');
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Convert the canvas image to a data URL
      const thumbnailURL = canvas.toDataURL('image/jpeg'); // You can change the format if needed

      setVideoThumbnail(thumbnailURL); // Store the thumbnail URL in state
    };

    video.src = URL.createObjectURL(videoFile);
  };


  const handleInputChange = (event) => {
    setIsOpen(false)
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDobChange = (value) => {
    setFormData({
      ...formData,
      ["dob"]: value,
    });
  };

  useEffect(() => {
    if (success) {
      toast.success(success, {
        autoClose: 2500,
      })
      setLoader(false)
      // Optionally, you can navigate to the next step or perform any other actions here
      setActiveStep(2);
    }
    if (error !== '') {
      setLoader(false)
      toast.error(error, {
        autoClose: 2500,
      })
    }
  }, [success, error]);

  const [formDataTitle, setFormDataTitle] = useState({
    images: "Images",
    video: "video",
    collegeName: "Name",
    weight: 'Weight',
    height: "Height",
    emailAddress: "Email",
    citizenship: "Citizenship",
    motherTongue: "Mother Tongue",
    maritalStatus: 'Marital Status',
    physicalStatus: 'Physical Status',
    moreAbout: 'About'
  });

  const imageValid = (images) => {
    images?.map((item)=> {
      if( item ) {
        return true
      }
    })
    return false
  } 

  console.log("formdata11", formData);
  const submitForm = async () => {
    const validateEmail = (email) => {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return regex.test(email);
    };
    console.log("formdata", formData);
    if (
      imageFiles?.length &&
      formData.collegeName &&
      formData.gender &&
      formData.weight &&
      formData.weight !== "0 Kg" &&
      isStringValid(formData.height) &&
      formData.emailAddress && // Check if email address is provided
      validateEmail(formData.emailAddress) && // Validate email format
      isStringValid(formData.motherTongue) &&
      formData.physicalStatus &&
      formData.maritalStatus &&
      isStringValid(formData.citizenship) &&
      formData.moreAbout
    ) {
      setLoader(true)
      // Upload all image files to S3 in parallel
      let uploadedImageUrls = await Promise.all(
        imageFiles.map(async (imageFile) => {
            if (typeof imageFile?.image_name === "object" && imageFile?.image_name !== null) {
                return {
                  image_name: await uploadFile(imageFile?.image_name),
                  image_status: "Pending"
                };
            }
            return {
              image_name: imageFile?.image_name,
              image_status: imageFile?.image_status
            };
        })
      );

      console.log("uploadedImageUrls", uploadedImageUrls);

      let uploadedImageNotNull = uploadedImageUrls.filter(item => item?.image_name !== undefined);
      console.log("uploadedImageNotNull", uploadedImageNotNull);

      let isError = true
      uploadedImageNotNull?.map((item) => {
          if (item !== null || item) {
              isError = false
          }
      })

      // Upload the video file to S3
      let videoUrl = videoFile;
      if (videoFile && typeof videoFile === "object") {
        videoUrl = await uploadFile(videoFile);
      }

      const dataToSend = {
        user_image: uploadedImageNotNull,
        // video: getUserData()?.membership_type === "Elite" ? videoUrl : null, // Add the uploaded video
        video: videoUrl,
        // name: formData.collegeName,
        // gender: formData.gender,
        // dob: formData.dob,
        email: formData.emailAddress.trim(),
        height: formData.height,
        mother_tongue: formData.motherTongue,
        physical_status: formData.physicalStatus,
        marital_status: formData.maritalStatus,
        citizenship: formData.citizenship,
        about: formData.moreAbout.trim(),
        weight: Number(formData?.weight?.split(" ")?.[0])
      };

      uploadUserDetails({
        url: "onboard/save-step-1",
        method: 'POST',
        body: dataToSend,
        headers: {
          Authorization: `Bearer ${getUserToken()}`
        }
      })
    } else {
      if( formData.emailAddress && !validateEmail(formData.emailAddress) ) {
        toast.error(`Please fill the valid email`, {
          autoClose: 2500,
        })
      }
      var result = Object.keys(formData).map((key) => [key, formData[key]]);
      for (const item of result || []) {
        if( item[0] !== "video" ) {
          console.log("item[0]", item[0], item[1]);
          if ( item[0] !== "mobileNumber" && item[0] !== "dob" ) {
            if ( !item[1] || item[1].length == 0 || item[1] === "Select an option" || item[1] === "0 Kg" ) {
              toast.error(`Please fill the ${formDataTitle[item[0]]}`, {
                autoClose: 2500,
              })
              break; // This will exit the loop when the condition is met
            }
          }
        }
      }
    }
  };

  const uploadElements = [];

  // const numberOfImages = getUserData()?.membership_type === "Basic" ? 1 : 6

  for (let i = 0; i < 6; i++) {
    uploadElements.push(
      <>
        {uploadedImages[i] ? (
          <div
            key={i}
            className={style.uploadedImage}
            style={{
              backgroundImage: `url('${uploadedImages[i]?.image_name?.includes("blob:") ? uploadedImages[i]?.image_name : process.env.REACT_APP_S3_CLOUD_FRONT_URL + uploadedImages[i]?.image_name}')`,
            }}
          >
            {
              uploadedImages[i]?.image_status !== "Approved" && (
                <div className={style.deleteContainer}>
                  <Image src={deleteIcon} onClick={() => handleImageDelete(i)} />
                </div>
              )
            }
            {
              uploadedImages[i]?.image_status !== "Approved" && (
                <div
                  className={style.reuploadContainer}
                  // onClick={() => uploadInputs.current[i].click()}
                >
                  {/* <Image src={uploadWhiteIcon} /> */}
                  <p className={style.reuploadLabeText}>{uploadedImages[i]?.image_status === "Pending" ? "Waiting Verification" : uploadedImages[i]?.image_status }</p>
                </div>
              )
            }
          </div>
        ) : (
          <div
            key={i}
            className={style.upload}
            onClick={() => uploadInputs.current[i].click()}
          >
            <label className={style.uploadImageLabel}>
              <div className={style.uploadImage}>
                <img
                  src={uploadIcon}
                  className={style.uploadLogo}
                  alt="Upload Icon"
                />
              </div>
            </label>
            <p className={style.uploadLabel}>Upload Image</p>
          </div>
        )}
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={(event) => handleImageUpload(event, i)}
          className={style.uploadInput}
          ref={(el) => (uploadInputs.current[i] = el)} // Assign the ref to the input element
        />
      </>
    );
  }

  const handleRadioChange = (name, value) => {

    setIsOpen(false)
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Create a function to render radio buttons
  const renderRadioButtons = (name, options) => (
    <div className={`${style.answers} ${name === "gender" ? style.disabled : ""}`}>
      {options.map((option) => (
        <div
          key={option.value}
          className={style.answer}
          onClick={() => name !== "gender" && handleRadioChange(name, option.value)}
        >
          <Image src={formData[name] === option.value ? radioSelected : radioUnselected} />
          <p>{option.name}</p>
        </div>
      ))}
    </div>
  );

  const handleWeightData = (e) => {
    setWeight(e.target?.value)
    setFormData({ ...formData, weight:  e.target?.value })
  }


  return (
    <div className={style.workDetailsContainer}>
      <ToastContainer />
      <ProgressBar width={`16.66%`} total={5} current={1} />
      <div className={style.uploadDataContainer}>
        <div className={style.form}>
          <p className={style.formTitle}>Upload Photo</p>
          <div className={style.uploadContainer}>{uploadElements}</div>
          <div className={style.eliteVideoInstructionContainer}>
            <Image src={notesIcon} className={style.notesIcon}/>
            <p className={style.instructionTitle}>Upload JPG/PNG Format only ( Max size 6MB )</p>
          </div>
            {/* {
              getUserData()?.membership_type === "Basic" && (
                <div className={style.basicUserContainer}>
                    <p className={style.upgradeDescription}>
                      Upgrade to Premium/Elite for more photo uploads and elevate your profile! To unlock this exclusive feature.
                    </p>
                    <p className={style.subscribeButton} onClick={()=> navigation("/subscribe")}>
                      Subscribe Now!
                    </p>
                </div>
              )
            } */}
        </div>
        {
          getUserData()?.membership_type === "Elite" && (
            <>
              <div className={style.form}>
                <p className={style.formTitle}>Upload Video</p>
                  { uploadedVideo ? (
                    <div
                      className={`${style.uploadedImage} ${style.videoThumbnailContainer}`}
                      // style={{
                      //   backgroundImage: `url(${videoThumbnail?.includes("blob:") ? videoThumbnail : process.env.REACT_APP_S3_CLOUD_FRONT_URL + videoThumbnail})`,
                      // }}
                    >
                      <video key={uploadedVideo} width={126} height={70} controls={0}>
                          <source src={`${uploadPersonalVideo ? uploadPersonalVideo : process.env.REACT_APP_S3_CLOUD_FRONT_URL+uploadedVideo +"#t=0.1"}`}/>
                      </video>
                      <div className={style.deleteContainer}>
                        <Image src={deleteIcon} onClick={() => {
                          setFormData({
                            ...formData,
                            video: ""
                          })
                          setVideoFile("")
                          setUploadedVideo("") 
                        } } />
                      </div>
                      <div
                        className={style.reuploadVideoContainer}
                        // onClick={() => uploadVideoInput.current.click()}
                      >
                        <p className={style.reuploadLabeText}>Waiting for verification</p>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={style.videoUploadContainer}>
                        {/* <p className={style.text}>{ getUserData()?.membership_type === "Elite" ? "Capture your true self with a genuine smile and share your story through our self-video upload feature." : "Take your profile to the next level with an elite membership and showcase your uniqueness by uploading a captivating video." }</p> */}
                        <p className={style.text}>Capture your true self with a genuine smile and share your story through our self-video upload feature.</p>
                        <Button
                          className={`primaryButton ${style.videoButton}`}
                          onClick={() => {
                            // if( getUserData()?.membership_type === "Elite" ) {
                              if (uploadVideoInput.current) {
                                uploadVideoInput.current.click();
                              }
                            // } else {
                            //   navigation("/subscribe")
                            // }
                          }}
                        >
                          {/* { getUserData()?.membership_type === "Elite" ? "Upload Video" : "Subscribe"  }  */}
                          Upload Video
                        </Button>
                      </div>
                      {/* {
                        getUserData()?.membership_type === "Elite" ? */}
                          <div className={style.eliteVideoInstructionContainer}>
                            <Image src={notesIcon} className={style.notesIcon}/>
                            <p className={style.instructionTitle}>Craft a compelling, desire-filled video under 3 minutes of maximum length</p>
                          </div>
                        {/* :
                          <></>
                      } */}
                    </>
                  )}
              </div>
              <input
                type="file"
                accept="video/*"
                onChange={handleVideoUpload}
                className={style.uploadInput}
                ref={(el) => (uploadVideoInput.current = el)} // Assign the ref to the input element
                onClick={(e)=> { 
                  e.target.value = null
                }}
              />
            </>

          )
        }
      </div>
      <div className={style.formContainer}>
        <p className={style.formTitle}>Personal Details</p>
        <div className={style.hobbiesContainer}>
          <div className={style.hobbieContainer}>
            <PopoutInput
              label="Name"
              name="collegeName"
              disabled={true}
              value={formData.collegeName}
              onChange={handleInputChange}
              style={{
                paddingBottom: '26px',
              }}
            />
            <FloatingDatePicker
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              selectedDate={formData.dob}
              disabled={true}
              onChange={(e) => setFormData({ ...formData, dob: e })}
              label={"Date of Birth"}
              customStyle={{
                paddingBottom: '26px',
              }}
            />
            <FloatingLabelDropdown
              label="Weight"
              name="weight"
              responseType="name"
              options={weightData} // Add options
              value={weight}
              onChange={handleWeightData}
              style={{
                paddingBottom: '26px',
              }}
            />
            <PopoutInput
              label="Email Address"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleInputChange}
              style={{
                paddingBottom: '26px',
              }}
            />
            <FloatingLabelDropdown
              label="Mother Tongue"
              name="motherTongue"
              responseType="token"
              options={motherTongueData} // Add options
              value={formData.motherTongue}
              onChange={handleInputChange}
              style={{
                paddingBottom: '26px',
              }}
            />
            <div style={{ minWidth: "342px" }} className={style.field}>
              <p className={style.fieldTitle}>Physical Status</p>
              <div className={style.fieldValue}>
                {renderRadioButtons('physicalStatus', physicalStatusData )}
              </div>
            </div>
          </div>
          <div className={style.hobbieContainer}>
            <PopoutInput
              label="Mobile Number"
              name="mobileNumber"
              disabled={true}
              value={getUserData().mobile_number}
              onChange={handleInputChange}
              style={{
                paddingBottom: '26px',
              }}
            />
            <div style={{ minWidth: "342px" }} className={style.field}>
              <p className={style.fieldTitle}>Gender</p>
              <div className={style.fieldValue}>
                {renderRadioButtons('gender', genderData)}
              </div>
            </div>
            <FloatingLabelDropdown
              label="Height"
              name="height"
              responseType="token"
              options={heightData} // Add options
              value={formData.height}
              onChange={handleInputChange}
              style={{
                marginTop: '26px',
                paddingBottom: '26px',
              }}
            />
            <FloatingLabelDropdown
              label="Citizenship"
              name="citizenship"
              responseType="token"
              options={citizenshipData} // Add options
              value={formData.citizenship}
              onChange={handleInputChange}
              style={{
                paddingBottom: '26px',
              }}
            />
            <div style={{ minWidth: "342px" }} className={`${style.field} ${style.marital}`}>
              <p className={style.fieldTitle}>Marital Status</p>
              <div className={style.fieldValue}>
                {renderRadioButtons('maritalStatus', maritalStatusData)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopoutTextarea
        characterLimit={255}
        label="More about Myself"
        name="moreAbout"
        className={style.moreAbout}
        value={formData.moreAbout}
        onChange={handleInputChange}
      />
      {validationError && <p className={`${style.errorMessage} error`}>{validationError}</p>}
      <Button
        className={`primaryButton ${style.submitButton}`}
        onClick={submitForm}
        loading={isLoading || loader}
      >
        Continue
      </Button>
    </div>
  );
}
