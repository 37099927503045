import React, { useState } from 'react'
import style from "./Login.module.css"
import Image from '../../../../ui/Image/Image';
import logoImage from "../../assets/svg/LandingPage/footerLogo.svg"
import closeImage from "../../assets/svg/Signup/Close.svg"
import Form from './Form/Form';
import Otp from './Otp/Otp';
import { LoginContext } from './Context/LoginContext';
import { addScroll } from '../../../../utils/utils';
import Certificate from '../Login/Certificate/Certificate';

export default function Login({closeDrawer, setDrawerType}) {

  const [ activeStep, setActiveStep ] = useState("form")
  const [id, setId] = useState('');

  const handleCloseDrawer = () => {
    setActiveStep("form")
    closeDrawer(true)
  };

  return (
    <LoginContext.Provider value={{setActiveStep, id, setId, handleCloseDrawer}}>
      <div className={style.signupContainer}>
        <Image
          onClick={()=> {
              addScroll()
              handleCloseDrawer()
            }
          }
          src={closeImage}
          className={style.closeIcon}
        />
        <div className={style.contentContainer}>
          <Image
            src={logoImage}
            className={style.logo}
          />
          {
            activeStep === "form" ? 
              <Form setDrawerType={setDrawerType}/>
            :
              activeStep === "otp" ?  
                <Otp/>
              :
                <Certificate id={id}/>
          }
        </div>
      </div>
    </LoginContext.Provider>
  );
}
