import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import "react-quill/dist/quill.snow.css";
import classes from "./Editor.module.css";

export const Editor = ({ label, onChange, value }) => {
    const [state, setState] = React.useState({ value: null });
    // const handleChange = value => {
    //     setState({ value });
    // };
    // console.log("state", state);
    return (
        <div className="text-editor">

            <div className={classes.textarea_box}>
                <p className={classes.lable_text}>{label}</p>
                <ReactQuill
                    theme="snow"
                    value={value}
                    onChange={onChange}
                    placeholder={"Write something awesome..."}
                    modules={modules}
                // formats={formats}
                />
            </div>
            <div className={classes.toolbar}>
                <EditorToolbar />
            </div>
        </div>
    );
};

export default Editor;
