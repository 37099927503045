import React, { useContext } from 'react'
import style from './HobbieDetails.module.css'
import { ProfileDetailsContext } from '../Context/ProfileDetailsContext'

export default function HobbieDetails() {
  const { data } = useContext(ProfileDetailsContext)
  return (
    <>
      { 
        data?.profile_details?.user_profile_details?.hobbies?.length ? 
          <div className={style.personalDetailsContainer}>
              <p className={style.title}>Hobbies & Interests</p>
              <ul>
                {
                  data?.profile_details?.user_profile_details?.hobbies?.map((item, index) => (
                    <li>{item}</li>
                  ))
                }
              </ul>
          </div>
        :
          <></>
      }
    </>
  )
}
