import React, { useEffect, useState } from "react";
import style from "./MembershipBanner.module.css";
import Image from "../../../../../ui/Image/Image";
import memberImg from "../../../assets/png/Subscribe/VerifiedProfile.png";
import flower from '../../../assets/svg/SubscribePage/flower.svg';
import Tabs from "../../../../../ui/Tabs/Tabs";
import PremiumPlan from "../PremiumPlan/PremiumPlan";
import { avoidScroll, getUserToken } from "../../../../../utils/utils";
import axios from "axios";
import useApiCall from '../../../hooks/useApiCall';
import config from '../../../../../app.json';
import { SubscribePageNew } from "../../../pages/SubscribePageNew/SubscribePageNew";

const MembershipBanner = () => {

    const { isLoading: isLoading, success: success, error: error, sendRequest: getDetails } = useApiCall();

    const [ premiumDetails, setPremiumDetails ] = useState([]);
    const [ tabData, setTabData ] = useState(null)
    

    // useEffect(() => {
    //     const redirectionStatus = localStorage.getItem('subscriptionFromHome')
    //     console.log("redirectionStatus", redirectionStatus);
    //     let endPoint = ""
    //     if( redirectionStatus == 2 ) {
    //         endPoint = "read-change-plans"
    //     } else if( redirectionStatus == 1 ) {
    //         endPoint = "browse-elite-plans"
    //     } else if( redirectionStatus == 3 ) {
    //         endPoint = "browse-all-plans"
    //     } else {
    //         endPoint = "browse-all-plans"
    //     }

    //     getDetails({
    //         url: `membership-plan/${endPoint}`,
    //         method: 'GET',
    //         headers: {
    //         Authorization: `Bearer ${getUserToken()}`
    //         }
    //     }, (data) => {
    //         setPremiumDetails(data.data)
    //         let tabList = []
    //         data?.data?.map((item, index)=> {
    //             tabList.push({ label: item?.membership_type, content: <PremiumPlan data={item} /> })
    //         })
    //         setTabData(tabList)
    //     });
    // }, []);
        
    let inlineStyle = {
        fontFamily: "SourceSansPro-SemiBold",
        fontSize: "12px",
        fontWeight: "600",
        fontStyle: "normal",
        letterSpacing: "0",
        textAlign: "center",
    };
    return (
        <div className={style.MembershipContainer}>
            <Image className={style.bannerDesign} alt={'BackDesign'} src={flower}/>
            <div className={`${style.bannerSectionContainer} container`}>
                <div className={style.titleBannerHead}>
                    <h2>
                        Unlock Limitless Possibilities with Doctor Matrimony
                        Membership
                    </h2>
                    <Image
                        src={memberImg}
                        alt={"Member Image"}
                        className={style.memberImg}
                    />
                    <p className={style.verifiedTitle}>9000+ verified profiles</p>
                    <div className={style.tabsPosition}>
                        <SubscribePageNew />
                        {/*{ premiumDetails?.length ? <Tabs tabs={tabData} customStyle={inlineStyle} /> : <></> } */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MembershipBanner;
