import React, { useEffect, useState } from 'react'
import style from "./LookingForYou.module.css"
import Image from '../../../../../ui/Image/Image'
import Button from '../../../../../ui/Button/Button'
import useApiCall from '../../../hooks/useApiCall';
import useChatSetter from '../../../hooks/useChatSetter';
import config from '../../../../../app.json';
import { useNavigate } from 'react-router-dom'
import sortlistStarIcon from "../../../assets/svg/Matches/Shortlisted.svg";
import degreeWhiteIcon from "../../../assets/common/DegreeWhite.svg";
import professionWhiteIcon from "../../Matches/assets/Profession Icon.svg";
import sortlistGrayStarIcon from "../../../assets/svg/Matches/ShortlistGray.svg";
import professionIcon from "../../../assets/common/Profession.svg";
import editOptionImage from "../../../assets/svg/Home/editIcon.svg"
import { avoidScroll, getUserData, getUserToken } from '../../../../../utils/utils'
import { BlockReport } from '../../BlockReport/BlockReport';
import chatIcon from "../../../features/MailBox/assets/Chat.svg"
import infoIcon from "../../MailBox/Declined/assets/Info.svg";
import ModalBox from '../../../../../ui/Modal/ModalBox';
import { Circles } from 'react-loader-spinner';
import { DeclineModal } from '../../Matches/DeclineModal/DeclineModal';
import { MembershipTypeCard } from '../../MembershipTypeCard/MembershipTypeCard';
import gridIcon from "../../Matches/assets/Grid View.svg"
import listViewIcon from "../../Matches/assets/List View.svg"
import Carousel from 'react-grid-carousel';
import unShortlistIcon from "../../Matches/assets/unShortlist.svg"
import intrestSentIcon from "../../Matches/assets/Interest Sent@2x.svg"
import sendIntrestIcon from "../../Matches/assets/Send Interest.svg"
import MaskImage from '../../MaskImage/MaskImage';

export default function LookingForYou({ limitExceeded, details, refresh, setRefresh, totalCount, setInterestClicked }) {

    const navigation = useNavigate("")

    const [data, setData] = useState(null);
    const [clickedItem, setClickedItem] = useState(false)
    const [chatToken, setChatToken] = useState(null)
    const [rejectReason, setRejectReason] = useState(null)
    const [showRejectReasonModal, setShowRejectReasonModal] = useState(false)
    const [isGridView, setIsGridView] = useState(false)

    const [rejectToken, setRejectToken] = useState(null)

    const { isLoading: sendIntrestLoading, success: sendIntrestSuccess, error: sendIntrestError, sendRequest: sendIntrest } = useApiCall();
    const { isLoading: sendShortListLoading, success: sendShortListSuccess, error: sendShortListError, sendRequest: shortlist } = useApiCall();
    const { loading: isChatSetterLoading, success: successChatSetter, error: errorChatSetter, setUserChatEntry: chatRoomSetup } = useChatSetter();
    const { sendRequest: acceptInterest } = useApiCall();
    const { sendRequest: declineInterest } = useApiCall();

    useEffect(() => {
        // Initialize buttonText property for each item
        const updatedData = details?.map(item => ({
            ...item,
            intrestButtonText: item?.sentInterest ? 'Interest sent' : 'Send Interest',
            shortlistButtonText: item.shortlisted ? 'Shortlisted' : 'Shortlist',
        }));
        setData(updatedData);
    }, [details])

    useEffect(() => {
        if (sendIntrestSuccess) {
            console.log("sendIntrestSuccess", sendIntrestSuccess);
        }
        if (sendIntrestError) {
            console.log("loginError", sendIntrestError);
        }
    }, [sendIntrestSuccess, sendIntrestError]);

    const handleSendInterest = (token, status) => {
        setInterestClicked(true)
        if (!limitExceeded) {
            sendIntrest({
                url: `profile/toggle-interest`,
                method: 'POST',
                body: {
                    "profile_token": token,
                },
                headers: {
                    Authorization: `Bearer ${getUserToken()}`
                }
            }, () => {
                const index = data.findIndex((item) => item.token === token);

                if (index !== -1) {
                    // Update the specific property of the found object
                    data[index].interest_sent_status = status;
                }
                setData(data)
                setRefresh(!refresh)
            });
        }
    }

    const handleShortlist = (token, status) => {
        shortlist({
            url: `profile/toggle-shortlist`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].shortlisted = status;
            }
            setData(data)
            setRefresh(!refresh)
        });
    }


    const handleAccept = (token) => {
        acceptInterest({
            url: `profile/accept-interest`,
            method: 'POST',
            body: {
                "profile_token": token,
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === token);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 2;
            }
            setData(data)
            setRefresh(!refresh)
        });
    }
    const handleCancel = (reason) => {
        declineInterest({
            url: `profile/reject-interest`,
            method: 'POST',
            body: {
                "profile_token": rejectToken,
                "reject_reason": reason.trim()
            },
            headers: {
                Authorization: `Bearer ${getUserToken()}`
            }
        }, () => {
            const index = data.findIndex((item) => item.token === rejectToken);

            if (index !== -1) {
                // Update the specific property of the found object
                data[index].interest_received_status = 3;
            }
            setData(data)
            setRefresh(!refresh)
        });
    }

    const actionUi = (
        can_send_interest,
        interest_received_status,
        interest_sent_status,
        shortlisted,
        is_connected,
        token,
        name,
        image,
        user_id
    ) => {

        return (
            <>
                <div className={style.buttonProgressContainer}>
                    {/* <p className={style.likeProfileLabel}>Like her profile?</p> */}
                    <div className={style.buttonsContianer}>
                        {
                            interest_sent_status === 0 && interest_received_status === 0 ?
                                <Button
                                    className={`primaryButton`}
                                    onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                >
                                    Send Interest
                                </Button>
                                :
                                interest_sent_status === 1 && interest_received_status === 0 ?
                                    <Button
                                        className={`primaryButton intrestSentStyle`}
                                        onClick={() => handleSendInterest(token, interest_sent_status === 1 ? 0 : 1)}
                                    >
                                        Interest Sent
                                    </Button>
                                    :
                                    <></>
                        }
                        <Button
                            className={`${style.sortListedButton} ${shortlisted ? style.sortlisted : ""}`}
                            onClick={() => handleShortlist(token, shortlisted ? 0 : 1)}
                        >
                            <Image
                                src={shortlisted ? sortlistStarIcon : sortlistGrayStarIcon}
                            />
                            {shortlisted ? "Shortlisted" : "Shortlist"}
                        </Button>
                    </div>
                </div>
                {
                    interest_received_status === 2 ?
                        <div className={`${style.intrestSentContainer} ${style.accepted}`}>
                            <p className={style.declineInfoText}>You have accepted the interest request, now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        <></>
                }

                {
                    interest_received_status === 3 ?
                        <>
                            <p className={style.declineInfoText}>You declined the interest request.</p>
                            <div className={style.aboutDescription}>
                                <Image
                                    src={infoIcon}
                                />
                                <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                            </div>
                        </>
                        :
                        <></>
                }
                {
                    interest_sent_status === 2 ?
                        <div className={`${style.intrestSentContainer}`}>
                            <p className={style.declineInfoText}>{name} has accepted your interest.</p>
                            <p className={style.declineInfoText}> Now you can chat with Dr. {name}.</p>
                            <div className={style.chatImagesContainer}>
                                <Image onClick={() => {
                                    setClickedItem(token)
                                    handleChat(token, name, user_id, image)
                                }} src={chatIcon} className={style.chatImage} />
                                {isChatSetterLoading && clickedItem === token && (
                                    <div>
                                        <Circles
                                            height="20"
                                            width="20"
                                            color="rgb(239, 116, 53)"
                                            ariaLabel="circles-loading"
                                            wrapperStyle={{}}
                                            visible={true}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        :
                        interest_sent_status === 3 ?
                            <>
                                <p className={style.declineInfoText}>Dr. {name} has declined your interest.</p>
                                <div className={style.aboutDescription}>
                                    <Image
                                        src={infoIcon}
                                    />
                                    <p className={style.declineInfoSubText}>This Member cannot be contacted.</p>
                                </div>
                            </>
                            :
                            <></>
                }
                {
                    interest_received_status === 1 ?
                        <div className={style.buttonProgressContainer}>
                            <p className={style.declineInfoText}>Dr.{name} has expressed interest in you. Do you want to accept their request?</p>
                            <div className={style.buttonsContianer}>
                                <Button
                                    className={`${style.button} primaryButton`}
                                    onClick={() => handleAccept(token)}
                                >
                                    Accept
                                </Button>
                                <Button
                                    className={`${style.declineButton} primaryButton cancelButton`}
                                    onClick={() => {
                                        setRejectToken(token)
                                        avoidScroll()
                                        setShowRejectReasonModal(true)
                                        // handleCancel(token)
                                    }}
                                >
                                    Decline
                                </Button>
                            </div>
                        </div>
                        :
                        <></>
                }
            </>
        )
    }

    useEffect(() => {
        if (successChatSetter) {
            navigation("/chat", { state: { token: chatToken } })
        }
        if (errorChatSetter) {
            console.log("errorChatSetter", errorChatSetter);
        }
    }, [successChatSetter, errorChatSetter]);

    const handleChat = (token, name, user_id, image) => {
        chatRoomSetup(
            token,
            name,
            user_id,
            image
            , (data) => {
                setChatToken(data)
            })
    }

    const profileNavigation = (token) => {
        navigation("/profile_details", { state: { token: token } })
    }

    return (
        <div className={`${style.lookingForyouContainer} ${getUserData()?.membership_type === "Basic" ? style.isGridView : ""}`}>
            <ModalBox
                open={showRejectReasonModal}
                width={"598px"}
                modalContent={
                    <DeclineModal
                        buttonText={"Okay"}
                        setRejectReason={setRejectReason}
                        setShowRejectReasonModal={setShowRejectReasonModal}
                        onClick={handleCancel}
                    />
                }
            />
            <div className={style.headerContainer}>
                <p className={style.header}>Members looking for you</p>
                <div className={style.gridListContainer}>
                    <Image onClick={() => setIsGridView(!isGridView)} src={isGridView ? listViewIcon : gridIcon} className={style.gridIcon} />
                    <p onClick={() =>
                        navigation("/suggetions", {
                            state: {
                                selectedMatch: "shortlisted"
                            }
                        })}
                        className={style.viewAllContainer}>View All members looking for you</p>
                </div>
            </div>
            <div className={style.dataContainer}>
                {
                    isGridView ? (
                        <>
                            <div className={`${style.gridViewContainer} gridSection`}>
                                {data?.map((item, index) => (
                                    <>
                                        <div
                                            style={{
                                                // backgroundImage: `url(${process.env.REACT_APP_S3_CLOUD_FRONT_URL+item.image})`
                                            }}
                                            className={style.gridItem}
                                        >
                                            <Carousel
                                                containerClassName={style.imagesCarouselContainer}
                                                cols={1}
                                                rows={1}
                                                gap={40}
                                                loop
                                                dotColorActive={"#fff"}
                                                dotColorInactive={"#ffffff75"}
                                                showDots={true}
                                                arrowLeft={<Image src={professionWhiteIcon} className={style.arrowLeft} />}
                                                arrowRight={<Image src={professionWhiteIcon} className={style.arrowRight} />}
                                            >
                                                {
                                                    item?.images?.map((itemImage, index) => (
                                                        <Carousel.Item>
                                                            <div className={style.userProfileContainer} onClick={() => profileNavigation(item?.token)}>
                                                                <MaskImage
                                                                    imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                                                    watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                                                    width={313}
                                                                    height={318}
                                                                    side={true}
                                                                    x1={-140}
                                                                    x2={-140}
                                                                    y1={-100}
                                                                    y2={-120}
                                                                />
                                                            </div>
                                                        </Carousel.Item>
                                                    ))
                                                }
                                            </Carousel>
                                            <div className={style.gridViewContentContainer}>
                                                <p onClick={() => profileNavigation(item?.token)} className={style.name}>{item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}</p>
                                                {item?.is_onboard_completed === 1 ? <>
                                                    <div onClick={() => profileNavigation(item?.token)} className={style.personalDetails}>
                                                        <p className={style.personaTtext}>{item.height}</p>
                                                        <p className={style.personaTtext}>{item.mother_tongue}</p>
                                                        <p className={style.personaTtext}>{item.community}</p>
                                                        <p className={style.personaTtext}>{item.cityname}</p>
                                                    </div>
                                                    <div className={style.personalDetails}>
                                                        <Image
                                                            src={degreeWhiteIcon}
                                                            className={style.professionIcon}
                                                        />
                                                        <p className={style.personaTtext}>{item.occupation}</p>
                                                        <p className={style.personaTtext}>{item.annual_income}</p>
                                                    </div>
                                                </> : ""}
                                                <div className={style.shortListIntrestContainer}>
                                                    <div className={style.buttonsContainer}>
                                                        <Image
                                                            onClick={() => handleShortlist(item.token, item?.shortlisted ? 0 : 1)}
                                                            src={item?.shortlisted ? sortlistStarIcon : unShortlistIcon}
                                                        />
                                                        <p className={style.shortlistText}>{item?.shortlisted ? "Shortlisted" : "Shortlist"}</p>
                                                    </div>
                                                    <div className={style.buttonsContainer}>
                                                        <Image
                                                            onClick={() => handleSendInterest(item.token, item?.sentInterest ? 0 : 1)}
                                                            src={item?.sentInterest ? intrestSentIcon : sendIntrestIcon}
                                                        />
                                                        <p className={style.shortlistText}>{item?.sentInterest ? "Interest Sent" : "Send Interest"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </>
                    ) : (
                        data?.map((item, index) => (
                            <div key={`looking_for_me-${index}`} className={style.data}>
                                <div onClick={() => navigation("/profile_details", { state: { token: item?.token } })}>
                                    <MaskImage
                                        imageUrl={process.env.REACT_APP_S3_CLOUD_FRONT_URL + item.image}
                                        watermarkText={`${item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}(${item?.user_id})`}
                                        width={210}
                                        height={219}
                                        side={true}
                                    />
                                </div>
                                <div className={style.detailsContainer}>
                                    <div className={style.idContainer}>
                                        <div className={style.idTypeContainer}>
                                            <p onClick={() => navigation("/profile_details", { state: { token: item?.token } })} className={style.id}>{item?.user_id}</p>
                                            <MembershipTypeCard type={item?.membership_type} />
                                        </div>
                                        <BlockReport refresh={refresh} setRefresh={setRefresh} id={item.user_id} token={item.token} />
                                    </div>
                                    <p onClick={() => navigation("/profile_details", { state: { token: item?.token } })} className={style.name}>{item.name.slice(0, 10) + `${item.name?.length > 10 ? "..." : ""}`}<span className={style.age}>({item?.age}yrs)</span></p>
                                    {item?.is_onboard_completed === 1 ?
                                        <>
                                            <div className={style.moreDetailsContainer}>
                                                <p className={style.detail}>{item?.height}</p>
                                                <p className={style.detail}>{item?.mother_tongue}</p>
                                                <p className={style.detail}>{item?.community}</p>
                                                <p className={style.detail}>{item?.city}</p>
                                            </div>
                                            <div className={style.workDetailsContainer}>

                                                <div className={style.iconValueContainer}><Image src={professionIcon} /><p className={style.work}>{item?.occupation}</p></div>
                                                <p className={style.work}>{item?.annual_income}</p>
                                            </div>
                                            <p className={style.aboutLabel}>About {item.name}</p>
                                            <p className={style.description}>{item?.about}</p>
                                        </>
                                        : <div className='non-onboarded-user'> </div>
                                    }
                                    {
                                        actionUi(
                                            item?.can_send_interest,
                                            item?.interest_received_status,
                                            item?.interest_sent_status,
                                            item?.shortlisted,
                                            item?.is_connected,
                                            item?.token,
                                            item?.name,
                                            item?.image,
                                            item?.user_id,

                                        )
                                    }
                                    {/* <div className={style.buttonsContainer}>
                                <Button 
                                    className={`primaryButton`}
                                    onClick={()=> handleSendInterest(item.token, item?.intrestButtonText === "Interest sent" ? 1 : 0)}
                                >
                                    {item?.intrestButtonText}
                                </Button>
                                <Button
                                    className={`${style.sortListedButton} ${item.shortlistButtonText === "Shortlisted" ? style.sortlisted : ""}`}
                                    onClick={()=> handleShortlist(item.token, item?.shortlistButtonText === "Shortlisted" ? 1 : 0 )}
                                >
                                    <Image
                                        src={item.shortlistButtonText === "Shortlisted" ? sortlistStarIcon : sortlistGrayStarIcon}
                                    />
                                    {item.shortlistButtonText}
                                </Button>
                            </div> */}
                                </div>
                            </div>
                        ))
                    )}
            </div>
        </div>
    )
}
