import React from 'react'
import BannerSection from '../../features/LandingPage/BannerSection/BannerSection'
import TrustedFeatures from '../../features/LandingPage/TrustedFeatures/TrustedFeatures'
import FindSoulMate from '../../features/LandingPage/FindSoulMate/FindSoulMate'
import WhyChoose from '../../features/LandingPage/WhyChoose/WhyChoose'
import CeleberateGift from '../../features/LandingPage/CeleberateGift/CeleberateGift'
import AppStore from '../../features/LandingPage/AppStore/AppStore'
import ShortVideo from '../../features/LandingPage/ShortVideo/ShortVideo'
import FaqSection from '../../features/LandingPage/FaqSection/FaqSection'
import Testimonials from '../../features/LandingPage/Testimonials/Testimonials'

export default function LandingPage() {
  return (
    <>
      <BannerSection />
      <TrustedFeatures />
      <FindSoulMate />
      <WhyChoose />
      <Testimonials />
      <ShortVideo />
      <CeleberateGift />
      <AppStore />
      <FaqSection />
    </>
  )
}
